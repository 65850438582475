<form (ngSubmit)="inviteNewMembers()">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="'dgOrgMembers_SendInvite' | translate"
    [isSubmitDisabled]="!canSubmit"
    [isSubmitPending]="isSubmitting"
    [useDefaultForm]="false"
  >
    <ng-container class="modal-header">
      {{ headerTitle }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="guts-m-h-1 guts-m-v-2">
        <h4 class="font-medium guts-m-b-half">
          {{ 'dgOrgMembers_InviteLabel' | translate }}
        </h4>
        <p
          *ngIf="emailSuffixList"
          class="color-ebony-a61 guts-m-t-half guts-m-b-1"
        >
          <span>{{ 'dgOrgMembers_EmailInstructions' | translate }}</span>
          <span
            *ngFor="let email of emailSuffixList; let i = index"
            data-dgat="invite-member-modal-f05"
          >
            {{ i > 0 ? this.orSeparator : '' }}@{{ email }}
          </span>
          <span>{{ 'dgOrgMembers_EmailDomainChangeFormat' | translate }}</span>
        </p>
        <dgx-invite-member-field
          [suffixList]="emailSuffixList"
          (inviteListModified)="updateInviteList($event)"
        ></dgx-invite-member-field>
        <div class="guts-m-t-1">
          <h4 class="font-medium">
            {{ 'dgOrgMembers_PersonalMessageLabel' | translate }}
          </h4>
          <textarea
            [placeholder]="'dgOrgMembers_AddAMessage' | translate"
            [attr.aria-label]="'dgOrgMembers_AddAMessage' | translate"
            [(ngModel)]="inviteMessage"
            name="inviteMessage"
            data-dgat="invite-member-modal-e63"
          ></textarea>
          <p class="guts-m-t-half par par--small font-medium color-ebony-a61">
            {{ personalMessageDescription }}
          </p>
        </div>
      </div>
      <div class="guts-p-t-2 guts-p-h-1 border-top">
        <dgx-org-invite-link
          [orgId]="org.organizationId"
          [inviteUrl]="inviteUrl"
        ></dgx-org-invite-link>
      </div>
    </ng-container>
  </dgx-modal>
</form>
