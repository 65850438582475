<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!videoGlobalAddForm"
    #formRef="ngForm"
    class="reactive-forms"
    [formGroup]="videoGlobalAddForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveVideo
      "
      [canCancel]="true"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      bodyClasses="guts-p-h-2 guts-p-v-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <!-- URL input-->
        <div
          *ngIf="videoGlobalAddForm.get('entryUrl')"
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label for="entryUrl" [isRequired]="true">
            {{ i18n.MediaFormCtrl_VideoUrl }}
          </dgx-reactive-forms-label>
          <input
              #entryUrl
              autocomplete="off"
              type="text"
              id="entryUrl"
              formControlName="entryUrl"
              placeholder="http://"
              [dgxAutofocus]="true"
              data-dgat="video-global-add-4dc"/>
          <dgx-validate-field
            class="block"
            *ngIf="
              videoGlobalAddForm.get('entryUrl')?.dirty &&
              videoGlobalAddForm.get('entryUrl').invalid
            "
            [message]="getUrlErrorMessage"
          >
          </dgx-validate-field>
        </div>

        <!-- Expanded Form -->
        <ng-container *ngIf="showExpandedForm">
          <!-- Title -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.dgFlexRow_EditSectionTitlePlaceholder"
              data-dgat="video-global-add-5d2"/>
            <dgx-validate-field
              class="block"
              *ngIf="
                videoGlobalAddForm.get('title').touched &&
                videoGlobalAddForm.get('title').invalid
              "
              [message]="i18n.MediaFormCtrl_TitleRequired"
            ></dgx-validate-field>
          </div>

          <!-- Source -->
          <ng-container *ngIf="vm.sourceName">
            <div class="reactive-forms__control reactive-forms__read-only">
              <dgx-reactive-forms-label for="sourceName">
                {{ i18n.Core_Source }}
              </dgx-reactive-forms-label>
              <input
                id="sourceName"
                formControlName="sourceName"
                readonly="true"
                data-dgat="video-global-add-2a1"/>
            </div>
          </ng-container>

          <!-- Description -->
          <div
            *ngIf="videoGlobalAddForm.get('description')"
            class="reactive-forms__control"
          >
            <dgx-reactive-forms-label for="description">
              {{ i18n.Core_Description }}
            </dgx-reactive-forms-label>
            <textarea
              id="description"
              formControlName="description"
              [attr.maxlength]="3000"
              [attr.aria-label]="i18n.Core_Description"
              data-dgat="video-global-add-40a"></textarea>
          </div>

          <!-- Comment -->
          <div
            *ngIf="videoGlobalAddForm.get('comment')"
            class="reactive-forms__control"
          >
            <dgx-reactive-forms-label
              for="comment"
              icon="info"
              [iconTooltip]="i18n.dgOrgInternalContentForm_CommentTooltip"
              [iconA11yText]="i18n.Core_MoreInfo"
            >
              {{ i18n.Core_Comment }}
            </dgx-reactive-forms-label>
            <textarea
              id="comment"
              formControlName="comment"
              [attr.placeholder]="
                'Core_InputCommentPlaceholder'
                  | translate: { inputType: 'video' }
              "
              [attr.maxlength]="3000"
              [attr.aria-label]="i18n.Core_Comment"
              data-dgat="video-global-add-1a3"></textarea>
          </div>

          <!-- Duration -->
          <div class="reactive-forms__control">
            <dgx-duration
              [formModel]="{
                durationHours: vm.durationHours,
                durationMinutes: vm.durationMinutes
              }"
            ></dgx-duration>
          </div>

          <!-- Content Owner -->
          <div class="form-wrap__group">
            <dgx-reactive-forms-label
              for="owner"
              icon="info"
              [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
              [iconA11yText]="i18n.Core_MoreInfo"
            >
              {{ i18n.dgOrgInternalContentForm_ContentOwner }}
            </dgx-reactive-forms-label>
            <dgx-user-search
              id="owner"
              [useSearchField]="false"
              [recipients]="!!vm.owner ? [vm.owner] : []"
              [search]="userGroupListService.loadGroupsUsersRecommendees"
              [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
              [allowRemoval]="true"
              (removeRecipient)="onContentOwnerChange(undefined)"
              (selectRecipient)="onContentOwnerChange($event)"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder
              "
            ></dgx-user-search>
            <dgx-validate-field
              class="block"
              *ngIf="
                videoGlobalAddForm.get('owner').touched &&
                videoGlobalAddForm.get('owner').invalid
              "
              [message]="i18n.dgOrgInternalContentForm_ContentOwnerRequired"
            ></dgx-validate-field>
          </div>

          <!-- Image -->
          <div class="reactive-forms__control">
            <dgx-upload-section
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.imageUploadAdapter"
              (uploadSuccessEvent)="onImageUploadSuccess($event)"
              (deleteEvent)="onDeleteImage()"
              [useCropper]="true"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [useBrowserReplace]="true"
            >
            </dgx-upload-section>
          </div>

          <!-- Skills -->
          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.mediumConfidenceInferredSkills"
              [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="
                vm.highConfidenceInferredSkills
                  ? i18n.dgOrgInternalContent_SkillsPlaceholderText
                  : ''
              "
              [allowExistingOnly]="false"
              [tags]="videoGlobalAddForm.get('skills').value"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                videoGlobalAddForm.get('skills').touched &&
                videoGlobalAddForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
        </ng-container>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer"></ng-container>
    </dgx-modal>
  </form>
</ng-container>
