<form class="form-wrap" (ngSubmit)="!showDeleteForm ? onNext() : onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [modalHeaderTitle]="modalHeaderTitle"
    [isSubmitDisabled]="disableSubmit"
    [submitButtonText]="submitButtonText"
    [submitButtonType]="submitButtonType"
    [useDefaultForm]="false"
  >
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <ng-container *ngIf="!showDeleteForm; else deleteForm">
        <p class="guts-p-h-half h3">
          {{ deleteNoticeSpecific }}
          {{ deleteNoticeGeneric }}
        </p>
        <ul
          *ngIf="items.length"
          class="bullets font-medium guts-p-l-2-half par--xsmall"
          [class.guts-m-b-1]="!!undeletableHtml"
        >
          <li
            *ngFor="let item of items; last as isLast"
            [class.guts-m-b-1]="!isLast"
            data-dgat="delete-multi-confirmation-modal-659"
          >
            {{ item }}
          </li>
        </ul>
        <df-local-notification
          *ngIf="undeletableHtml"
          [type]="notificationType"
          [text]="undeletableHtml"
          size="small"
          class="block guts-p-h-half"
        ></df-local-notification>
      </ng-container>
    </ng-container>
  </dgx-modal>

  <ng-template #deleteForm>
    <p class="guts-p-h-half h3">
      {{ deleteConfirmation }}
    </p>
    <df-form-root
      [form]="form"
      [model]="model"
      [fields]="fields"
      class="block guts-p-h-half"
    ></df-form-root>
  </ng-template>
</form>
