import { Pipe, PipeTransform } from '@angular/core';
import { ResourceType } from '@app/shared/models/core-api.model';

/** Transforms a {@link ResourceType} into a fresco icon name for supported types (see code for supported types) */
@Pipe({
  name: 'resourceTypeIcon',
})
export class ResourceTypeIconPipe implements PipeTransform {
  transform(value: ResourceType): any {
    switch (value) {
      case 'Article':
      case 'Video':
      case 'Book':
      case 'Event':
      case 'Course':
      case 'Assessment':
      case 'Position':
      case 'Episode':
      case 'Task':
      case 'Badge':
      case 'Degree':
      case 'Certificate':
      case 'Award':
      case 'Accomplishment':
      case 'Tag':
      case 'Opportunity':
        return value.toLowerCase();
      case 'Position':
        return 'experience';
      case 'Post':
        return 'author-note';
    }
    return undefined; // other types have no icons
  }
}
