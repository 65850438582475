import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  GetTargetResourcesBySectionResponse,
  Target,
  TargetCompatibility,
} from '@app/target/target-api.model';
import { TargetsService } from '@app/shared/services/targets.service';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

import {
  SectionDisplayType,
  SectionDisplayInfo,
  SectionInfoRecord,
} from '@app/flex-framework/flex-section-api.model';
import { TargetService } from '@app/target/services/target.service';
import { ModalService } from '@app/shared/services/modal.service';
import { LinkRowModal } from '@app/flex-framework/components/modals/link-row-modal/link-row-modal.component';
import { FreeTextRowModal } from '@app/flex-framework/components/modals/free-text-row-modal/free-text-row-modal.component';
import { MetaDataModal } from '@app/flex-framework/components/modals/meta-data-modal/meta-data-modal.component';
import {
  FlexRow,
  FlexRowSummary,
  FlexRowType,
  FreeformTextRow,
  LinkTextRow,
  OptionalResourceRow,
} from '@app/flex-framework/flex-api.model';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlexService {
  public authUser: AuthUser = this.authService.authUser;
  public i18n = this.translate.instant([
    'Core_LinkText',
    'Core_FreeformText',
    'Core_Skills',
    'Core_Pathways',
    'Core_Plans',
    'Core_Content',
    'Core_Groups',
    'Core_People',
    'Core_Directorys',
    'Core_Providers',
    'Core_FindContent',
    'Core_Opportunities',
    'dgTagRating_RemoveFromPlan',
    'TargetCtrl_AddDirectories',
    'TargetCtrl_AddLinkTexts',
    'TargetCtrl_AddFreeformTexts',
    'TargetCtrl_AddTags',
    'TargetCtrl_AddPathways',
    'TargetCtrl_AddInputs',
    'TargetCtrl_AddGroups',
    'TargetCtrl_AddUsers',
    'TargetCtrl_AddOpportunities',
    'TargetCtrl_AddProviders',
    'OrgPlans_CustomTitlesAndDescriptionsNotLocalized',
    'OrgPlans_CustomTitlesAndTextNotLocalized',
    'FlexSvc_TextAndImage',
    'FlexSvc_MixedResources',
    'FlexSvc_MixedResourcesDescription',
    'FlexSvc_AddResources',
    'FlexSvc_FreeformTextDescription',
    'FlexSvc_AddLinkTextsDescription',
    'FlexSvc_OpportunitiesDescription',
    'Core_Skills',
    'OrgSkills_AddSkill',
    'SkillStd_Segments',
    'BusinessRules_AddSegments',
  ]);
  public readonly anySectionInfo: SectionDisplayInfo = {
    displayType: this.i18n.FlexSvc_MixedResources,
    displayTypeDescription: this.i18n.FlexSvc_MixedResourcesDescription,
    addTileName: this.i18n.FlexSvc_AddResources,
    gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
    isRequiredTitle: true,
    isVisible: true,
    layoutConfig: { displayType: SectionDisplayType.Single },
    flexRowType: 'Any',
    sectionDefaultTitle: 'Combined Resources',
  };

  // The order of this object determines the Order of items in the Add Section menu for plans
  public readonly simplifiedPlanMenuDisplayInfo: SectionInfoRecord<SectionDisplayInfo> =
    {
      Any: {
        ...this.anySectionInfo,
        includedInAddSection: true,
      },
      FreeformText: {
        displayType: this.i18n.FlexSvc_TextAndImage,
        displayTypeDescription: this.i18n.FlexSvc_FreeformTextDescription,
        addTileName: this.i18n.TargetCtrl_AddFreeformTexts,
        gridClass: 'grid__col-xs-12',
        isRequiredTitle: false,
        isVisible: true,
        layoutConfig: { displayType: SectionDisplayType.None },
        flexRowType: 'FreeformText',
        sectionDefaultTitle: 'Text Area',
        includedInAddSection: true,
      },
      LinkText: {
        displayType: this.i18n.Core_LinkText,
        displayTypeDescription: this.i18n.FlexSvc_AddLinkTextsDescription,
        addTileName: this.i18n.TargetCtrl_AddLinkTexts,
        gridClass: 'grid__col-xs-12',
        isRequiredTitle: false,
        isVisible: true,
        layoutConfig: { displayType: SectionDisplayType.None },
        flexRowType: 'LinkText',
        sectionDefaultTitle: 'Link',
        includedInAddSection: true,
      },
      Opportunity: {
        displayType: this.i18n.Core_Opportunities,
        displayTypeDescription: this.i18n.FlexSvc_OpportunitiesDescription,
        addTileName: this.i18n.TargetCtrl_AddOpportunities,
        gridClass: 'grid__col-xs-6 grid__col-sm-4',
        isRequiredTitle: true,
        isVisible: this.authUser.hasCareerMobility,
        layoutConfig: { displayType: SectionDisplayType.Single },
        flexRowType: 'Opportunity',
        sectionDefaultTitle: 'Opportunities',
        includedInAddSection: this.authUser.hasCareerMobility,
      },
      SkillStandardsSkill: {
        displayType: this.i18n.Core_Skills,
        addTileName: this.i18n.OrgSkills_AddSkill,
        gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
        isRequiredTitle: true,
        isVisible: false,
        layoutConfig: { displayType: SectionDisplayType.Single },
        flexRowType: 'SkillStandardsSkill',
        sectionDefaultTitle: 'SkillStandardsSkill',
      },
      SkillStandardsSegment: {
        displayType: this.i18n.SkillStd_Segments,
        addTileName: this.i18n.BusinessRules_AddSegments,
        gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
        isRequiredTitle: true,
        isVisible: false,
        layoutConfig: { displayType: SectionDisplayType.Single },
        flexRowType: 'SkillStandardsSegment',
        sectionDefaultTitle: 'Segment',
      },
      // for backward compatibility to display the right header for old sections
      // but they are treated like "flexRowType=Any"
      Tag: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_Skills,
        sectionDefaultTitle: 'Skills',
      },
      Pathway: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_Pathways,
        sectionDefaultTitle: 'Pathways',
      },
      Target: {
        ...this.anySectionInfo,
        displayType: this.translate.instant(
          this.targetsService.composeTargetResourceStringKey({
            baseKey: 'TargetCtrl_',
            primaryResourceType: 'Target',
            plural: true,
          })
        ),
        sectionDefaultTitle: 'Plans',
      },
      Input: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_Content,
        sectionDefaultTitle: 'Content',
      },
      Group: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_Groups,
        sectionDefaultTitle: 'Groups',
      },
      User: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_People,
        sectionDefaultTitle: 'People',
      },
      Provider: {
        ...this.anySectionInfo,
        displayType: this.i18n.Core_Providers,
        sectionDefaultTitle: 'Providers',
      },
    };

  public readonly displayInfo: SectionInfoRecord<SectionDisplayInfo> = {
    LinkText: {
      displayType: this.i18n.Core_LinkText,
      addTileName: this.i18n.TargetCtrl_AddLinkTexts,
      gridClass: 'grid__col-xs-12',
      isRequiredTitle: false,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.None },
      flexRowType: 'LinkText',
      sectionDefaultTitle: 'Link',
      includedInAddSection: true,
    },
    FreeformText: {
      displayType: this.i18n.Core_FreeformText,
      addTileName: this.i18n.TargetCtrl_AddFreeformTexts,
      gridClass: 'grid__col-xs-12',
      isRequiredTitle: false,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.None },
      flexRowType: 'FreeformText',
      sectionDefaultTitle: 'Text Area',
      includedInAddSection: true,
    },
    Tag: {
      displayType: this.i18n.Core_Skills,
      addTileName: this.i18n.TargetCtrl_AddTags,
      gridClass: 'grid__col-xs-6 grid__col-sm-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Tag',
      sectionDefaultTitle: 'Skills',
      includedInAddSection: true,
    },
    Pathway: {
      displayType: this.i18n.Core_Pathways,
      addTileName: this.i18n.TargetCtrl_AddPathways,
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Pathway',
      sectionDefaultTitle: 'Pathways',
      includedInAddSection: true,
    },
    Target: {
      displayType: this.translate.instant(
        this.targetsService.composeTargetResourceStringKey({
          baseKey: 'TargetCtrl_',
          primaryResourceType: 'Target',
          plural: true,
        })
      ),
      addTileName: this.translate.instant(
        this.targetsService.composeTargetResourceStringKey({
          baseKey: 'TargetCtrl_Add',
          primaryResourceType: 'Target',
          plural: true,
        })
      ),
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Target',
      sectionDefaultTitle: 'Plans',
      includedInAddSection: true,
    },
    Input: {
      displayType: this.i18n.Core_Content,
      addTileName: this.i18n.TargetCtrl_AddInputs,
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Input',
      sectionDefaultTitle: 'Content',
      includedInAddSection: true,
    },
    Group: {
      displayType: this.i18n.Core_Groups,
      addTileName: this.i18n.TargetCtrl_AddGroups,
      gridClass: 'grid__col-xs-6 grid__col-sm-4',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Group',
      sectionDefaultTitle: 'Groups',
      includedInAddSection: true,
    },
    User: {
      displayType: this.i18n.Core_People,
      addTileName: this.i18n.TargetCtrl_AddUsers,
      gridClass: 'grid__col-xs-6 grid__col-sm-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'User',
      sectionDefaultTitle: 'People',
      includedInAddSection: true,
    },
    Opportunity: {
      displayType: this.i18n.Core_Opportunities,
      addTileName: this.i18n.TargetCtrl_AddOpportunities,
      gridClass: 'grid__col-xs-6 grid__col-sm-4',
      isRequiredTitle: true,
      isVisible: this.authUser.hasCareerMobility,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Opportunity',
      sectionDefaultTitle: 'Opportunities',
      includedInAddSection: this.authUser.hasCareerMobility,
    },
    Provider: {
      displayType: this.i18n.Core_Providers,
      addTileName: this.i18n.TargetCtrl_AddProviders,
      gridClass: 'grid__col-xs-6 grid__col-sm-3 grid__col-md-2',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Provider',
      sectionDefaultTitle: 'Providers',
      includedInAddSection: true,
    },
    SkillStandardsSkill: {
      displayType: this.i18n.Core_Skills,
      addTileName: this.i18n.OrgSkills_AddSkill,
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: false,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'SkillStandardsSkill',
      sectionDefaultTitle: 'SkillStandardsSkill',
      includedInAddSection: false,
    },
    SkillStandardsSegment: {
      displayType: this.i18n.SkillStd_Segments,
      addTileName: this.i18n.BusinessRules_AddSegments,
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: false,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'SkillStandardsSegment',
      sectionDefaultTitle: 'Segment',
      includedInAddSection: false,
    },
    Any: {
      displayType: this.i18n.FlexSvc_MixedResources,
      addTileName: this.i18n.FlexSvc_AddResources,
      gridClass: 'grid__col-xs-6 grid__col-sm-4 grid__col-md-3',
      isRequiredTitle: true,
      isVisible: true,
      layoutConfig: { displayType: SectionDisplayType.Single },
      flexRowType: 'Any',
      sectionDefaultTitle: 'Combined Resources',
      includedInAddSection: true,
    },
  };
  public readonly defaultCompatibilityInfo: SectionInfoRecord<TargetCompatibility> =
    {
      LinkText: TargetCompatibility.All,
      FreeformText: TargetCompatibility.All,
      Tag: TargetCompatibility.Plan,
      Pathway: TargetCompatibility.Plan,
      Target: TargetCompatibility.All,
      Input: TargetCompatibility.Plan,
      Group: TargetCompatibility.All,
      User: TargetCompatibility.All,
      Opportunity: TargetCompatibility.All,
      Provider: TargetCompatibility.All,
      SkillStandardsSkill: TargetCompatibility.None,
      SkillStandardsSegment: TargetCompatibility.None,
      Any: TargetCompatibility.All,
    };

  public isEditing = new BehaviorSubject<boolean>(false);
  private _flexRows: FlexRow[];

  constructor(
    private authService: AuthService,
    private ldFlagsService: LDFlagsService,
    private modalService: ModalService,
    private targetsService: TargetsService,
    private targetService: TargetService,
    private translate: TranslateService
  ) {}

  public get flexRows() {
    return this._flexRows;
  }
  public set flexRows(rows) {
    this._flexRows = rows;
  }

  public createFlexRow(
    flexRowSummary: FlexRowSummary,
    legacyDisplayInfo = false
  ): FlexRow {
    const { layoutConfig, ...sectionDisplay }: SectionDisplayInfo =
      this.getRowDisplayInfo(flexRowSummary.resourceType, legacyDisplayInfo);
    const row: FlexRow = {
      ...sectionDisplay,
      section: flexRowSummary,
      loading: true,
    };

    return row;
  }

  public getRowDisplayInfo(
    displayType: FlexRowType,
    legacyDisplayInfo = false
  ) {
    if (!legacyDisplayInfo) {
      return this.simplifiedPlanMenuDisplayInfo[displayType];
    }
    return this.displayInfo[displayType];
  }

  // Get the name of the section and fall back to the default i18n string if none is set.
  public getSectionName(section: FlexRowSummary) {
    if (section.name) {
      return section.name;
    }

    return this.displayInfo[section.resourceType || 'Input'].displayType;
  }

  public getResourceTileType(resource): string {
    const type =
      resource.reference?.targetType ||
      resource.resourceType ||
      resource.referenceType;
    switch (type) {
      case 'Article':
      case 'Assessment':
      case 'Book':
      case 'Course':
      case 'Episode':
      case 'Event':
      case 'Video':
        return 'UserContent';
      case 'Role':
        return 'Target';
      default:
        return type;
    }
  }

  public configureFlexRows(): FlexRow[] {
    const sections = this.targetService.targetResponse.sections;

    const rows = sections.map((flexRowSummary: FlexRowSummary) => {
      return this.createFlexRow(flexRowSummary);
    });

    this.flexRows = rows; // = this.setupRowResources(rows);

    return this.flexRows;
  }

  public setupRowResources(
    rows: FlexRow[]
  ): Observable<GetTargetResourcesBySectionResponse> {
    // List all row nodes for resources to match with
    const nodes = rows.map((row: FlexRow) => row.section.parentNode);

    // TODO: Blindly fetches the first 100 resources belonging to a target regardless of row.
    // Problem: Old logic ignored how a row is displayed (Single, Double, All).
    // Only the count allowed in the different display options are loaded and rendered,
    // do we want to do API calls per row, or can we safely receive/fetch all resources belonging to a target?
    // Check with BE how many resources a target can have to see impact.
    // Side note: For old FlexRows caused rendering issues if you remove a resource in a row,
    // the old logic was not smart enough to handle that since only enough resources to fill the
    // displayType was fetched.
    // TODO: For now, assume 100 resources is enough to fully display a target.
    return this.targetsService.getTargetResourcesBySection(
      this.targetService.getCurrentTargetId(),
      nodes,
      0,
      100
    );
  }

  public addNewRow(
    target: Target,
    flexRowSummary: FlexRowSummary,
    location: 'top' | 'bottom',
    optionalResource?: LinkTextRow | FreeformTextRow,
    resourceImageId?: number
  ): Observable<FlexRowSummary> {
    const leftNode = location === 'bottom' ? flexRowSummary.parentNode : null;
    const rightNode = location === 'top' ? flexRowSummary.parentNode : null;

    return this.targetsService.addTargetRow(
      target.targetId,
      flexRowSummary.name || (optionalResource as LinkTextRow)?.label,
      leftNode,
      rightNode,
      flexRowSummary.resourceType,
      flexRowSummary.layoutConfig.displayType,
      optionalResource,
      resourceImageId
    );
  }

  public createSpecialRowModal(
    flexRowType: FlexRowType,
    flexRowSummary: FlexRowSummary
  ): Observable<OptionalResourceRow> {
    let modal;
    if (flexRowType === 'LinkText') {
      modal = LinkRowModal;
    } else if (flexRowType === 'FreeformText') {
      modal = FreeTextRowModal;
    }

    const modalOptions = {
      inputs: {
        flexRowSummary,
      },
      windowClass: 'lg-modal',
    };

    return this.modalService.show(modal, modalOptions);
  }

  public metadataModal(target: Target): Observable<Target> {
    const modalOptions = {
      inputs: {
        target: target,
      },
      windowClass: 'lg-modal',
    };

    return this.modalService.show(MetaDataModal, modalOptions);
  }
}
