import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { finalize, first, take } from 'rxjs/operators';
import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { DfIconCalendar16, DfIconRecord16, DfIconRegistry } from '@lib/fresco';
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';

import { AuthorVideoModalComponent } from '@app/author/components/author-video-modal/author-video-modal.component';
import { AzureUserAuthoredResult } from '@app/content-hosting';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { isKey, Key } from '@app/shared/key';
import { ModalService } from '@app/shared/services/modal.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { jsDateToNgbDate } from '@app/shared/utils/time-utils';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  InputsFacadeBase,
  InputSubmissionResult,
} from '@app/user-content/services/inputs-facade-base';
import { lazySearch } from '@dg/shared-rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteItem } from '../course-api.model';
import { CourseFacade } from '../user-input/course-form/course.facade';
import { InputFormModel } from '../user-input/input-form.model';
import { AnyInputApiEntity } from '../user-input/user-input.model';
import { VideoConstraintsViewModel } from '../user-input/video-modal/video-form.model.model';
import { VideoModalFacade } from '../user-input/video-modal/video-modal.facade';
import { LDFlagsService } from '@dg/shared-services';

@Component({
  selector: 'dgx-modal-container',
  templateUrl: './modal-container.component.html',
  animations: [DF_COLLAPSE_EXPAND],
  // changeDetection: ChangeDetectionStrategy.OnPush, // disabled for scorm uploader, fix tbd
})
export class ModalContainerComponent implements AfterViewInit {
  /**
   * This is a hack to fix a linter issue with assigning the
   * vm as uiState.viewModel || {}. The {} gives linter errors but
   * removing it makes the uploader and potentially other areas fail.
   * Unit we can figure out how to make the form work without the {}
   * this seems like the best option.
   */
  public asAny = (item: any): any => item;

  @Input() public facade: InputsFacadeBase<InputFormModel, AnyInputApiEntity>;
  @Input() public isHeaderBorderless = false;
  @Input() public isPendingWithOverlay = true;
  @Input() public useDefaultForm = false;
  @Input() public useDefaultSubmitButton = false;
  @Input() public eventLengthOptions: any;
  @Input() public involvementLevel: any;
  // Optional title for the modal which overrides the default 'Add *' or 'Edit *' strings. Can be a translation key.
  @Input() public modalTitle?: string;

  @ViewChild('creator') public creatorRef: TemplateRef<any>;
  @ViewChild('readonlyField') public readonlyFieldRef: TemplateRef<any>;
  @ViewChild('date')
  public dateViewRef: TemplateRef<any>;
  @ViewChild('groupsEditor') public groupsEditorRef: TemplateRef<any>;
  @ViewChild('contentUploader')
  public contentUploaderRef: TemplateRef<any>;
  @ViewChild('urlCustomHelp') public urlCustomHelpRef: TemplateRef<any>;
  @ViewChild('addToCatalogDupsHelp')
  public addToCatalogDupsHelpRef: TemplateRef<any>;
  @ViewChild('addToCatalogDupsHelpCatalog')
  public addToCatalogDupsHelpCatalogRef: TemplateRef<any>;
  @ViewChild('advancedExpander') public advancedExpanderRef: TemplateRef<any>;
  @ViewChild('eventLength')
  public eventLengthRef: TemplateRef<any>;
  @ViewChild('eventInvolvement')
  public eventInvolvementRef: TemplateRef<any>;
  @ViewChild('tagsEditor')
  public tagsEditorRef: TemplateRef<any>;
  @ViewChild('institutionSelection')
  public institutionSelectionRef: TemplateRef<any>;
  @ViewChild('institutionSearch')
  public institutionSearchRef: TemplateRef<any>;
  @ViewChild('institutionResult')
  public institutionResultRef: TemplateRef<any>;
  @ViewChild('courseLevel')
  public courseLevelRef: TemplateRef<any>;
  @ViewChild('courseGrade')
  public courseGradeRef: TemplateRef<any>;
  @ViewChild('countrySearch')
  public countrySearchRef: TemplateRef<any>;
  @ViewChild('countrySearchResult')
  public countrySearchResultRef: TemplateRef<any>;
  @ViewChild('courseTitleSearch')
  public courseTitleSearchRef: TemplateRef<any>;
  @ViewChild('courseTitleResult')
  public courseTitleResultRef: TemplateRef<any>;
  @ViewChild('providerSearch')
  public providerSearchRef: TemplateRef<any>;
  @ViewChild('providerResult')
  public providerResultRef: TemplateRef<any>;
  @ViewChild('assessmentContentCatalogInitialView')
  public assessmentContentCatalogInitialViewRef: TemplateRef<any>;
  @ViewChild('assessmentQuestionsCorrect')
  public assessmentQuestionsCorrectRef: TemplateRef<any>;
  @ViewChild('assessmentManageCredSpark')
  public assessmentManageCredSparkRef: TemplateRef<any>;
  @ViewChild('imageSize')
  public imageSizeRef: TemplateRef<any>;
  @ViewChild('episodeInitialization')
  public episodeInitializationRef: TemplateRef<any>;
  @ViewChild('episodeSelection')
  public episodeSelectionRef: TemplateRef<any>;
  @ViewChild('episodeDuration')
  public episodeDurationRef: TemplateRef<any>;
  @ViewChild('videoRecordButton')
  public videoRecordButtonRef: TemplateRef<any>;
  @ViewChild('urlBrokenValidation')
  public urlBrokenValidationRef: TemplateRef<any>;
  @ViewChild('videoCompatibleAndDupsHelp')
  public videoCompatibleAndDupsHelpRef: TemplateRef<any>;
  @ViewChild('bookTitleSuggest')
  public bookTitleSuggestRef: TemplateRef<any>;
  @ViewChild('bookInfo')
  public bookInfoRef: TemplateRef<any>;
  @ViewChild('spinner')
  public spinnerRef: TemplateRef<any>;
  @ViewChild('errorMessage')
  public errorMessageRef: TemplateRef<any>;
  @ViewChild('horizontalDivider')
  public horizontalDividerRef: TemplateRef<any>;

  public options = {};
  public model: any;
  public form: FormGroup = new FormGroup({});
  public result: InputSubmissionResult;
  public i18n = this.translate.instant([
    'Core_SelectDate',
    'Core_AdvancedSettings',
    'Core_Delete',
    'Core_Cancel',
    'Core_EditItemHelper',
    // The following have to be provided here due to lack of context for field help templates in fresco presently.  TODO: see https://degreedjira.atlassian.net/browse/PD-76255
    'MediaFormCtrl_ErrorUnaccessibleArticle',
    'MediaFormCtrl_ErrorUnaccessibleVideo',
    'dgOrgInternalContentForm_VideoSourceSupported',
    'OrgInternalContent_DeleteSingleContentFormat',
  ]);
  // shouldSpinSubmitButton$ isn't actually working; it updates to NULL instead of TRUE. No idea why.
  // TODO: Figure this out, remove `isSaving`, and update [isSubmitPending]="isSaving" back to
  // [isSubmitPending]="shouldSpinSubmitButton$ | async"
  public isSaving = false;
  public isNewbUser = false;
  public isBrokenLinksFlagOn: boolean;
  private _today: NgbDateStruct;

  public get assessmentQuestionsCorrectLabel(): string {
    return (this.facade as any).assessmentQuestionsCorrectLabel;
  }

  public videoConstraints = (item: unknown): VideoConstraintsViewModel =>
    item as VideoConstraintsViewModel;
  public HTMLInputElement = (item: unknown): HTMLInputElement =>
    item as HTMLInputElement;

  constructor(
    @Inject(WindowToken) public windowRef: Window,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    tipService: TipService,
    private iconRegistry: DfIconRegistry,
    private modalService: ModalService,
    private userInputsService: UserInputsService,
    private ldFlagsService: LDFlagsService
  ) {
    this.model = {};
    tipService.onboardHistory$.pipe(take(1)).subscribe((v) => {
      // is new user if there is no initial input recorded for the profile yet
      this.isNewbUser = v.indexOf('firstinput') === -1;
    });
    this.iconRegistry.registerIcons([DfIconCalendar16]);
    this.iconRegistry.registerIcons([DfIconRecord16]);
    this.isBrokenLinksFlagOn = this.ldFlagsService.showBrokenLinksManagement;
  }

  /** Helper property for limiting datepickers to current date */
  public get today(): NgbDateStruct {
    if (!this._today) {
      // lazy init since some modals won't use
      const now = new Date();
      this._today = jsDateToNgbDate(now);
    }
    return this._today;
  }

  public ngAfterViewInit() {
    // initialize domain service with templates now that they're assigned
    this.facade.onModalInit({
      // HACK: The need to pass these templates should largely go away as we create first class field components
      // either in fresco or as foreign fields in the lxp instead

      // shared
      skills: this.tagsEditorRef,
      advancedExpander: this.advancedExpanderRef,
      addToCatalogDupsHelp: this.addToCatalogDupsHelpRef,
      addToCatalogDupsHelpCatalog: this.addToCatalogDupsHelpCatalogRef,
      groups: this.groupsEditorRef,
      contentUploader: this.contentUploaderRef,
      creator: this.creatorRef,
      spinner: this.spinnerRef,
      errorMessage: this.errorMessageRef,
      urlBrokenValidation: this.urlBrokenValidationRef,
      // event
      eventInvolvement: this.eventInvolvementRef,
      eventLength: this.eventLengthRef,
      institutionSelection: this.institutionSelectionRef,
      // course
      courseLevel: this.courseLevelRef,
      countrySearch: this.countrySearchRef,
      countrySearchResult: this.countrySearchResultRef,
      courseGrade: this.courseGradeRef,
      institutionSearch: this.institutionSearchRef,
      institutionResult: this.institutionResultRef,
      courseTitleSearch: this.courseTitleSearchRef,
      courseTitleResult: this.courseTitleResultRef,
      // article
      urlCustomHelp: this.urlCustomHelpRef,
      readonlyField: this.readonlyFieldRef,
      // assessment
      assessmentContentCatalogInitialView:
        this.assessmentContentCatalogInitialViewRef,
      assessmentQuestionsCorrect: this.assessmentQuestionsCorrectRef,
      assessmentManageCredSpark: this.assessmentManageCredSparkRef,
      imageSize: this.imageSizeRef,
      // episode
      episodeInitialization: this.episodeInitializationRef,
      episodeSelection: this.episodeSelectionRef,
      // video
      videoRecordButton: this.videoRecordButtonRef,
      videoCompatibleAndDupsHelp: this.videoCompatibleAndDupsHelpRef,
      // book
      bookTitleSuggest: this.bookTitleSuggestRef,
      bookInfo: this.bookInfoRef,
      horizontalDivider: this.horizontalDividerRef,
    });
  }

  public onDismiss(shouldShowResults: Observable<boolean> = of(false)) {
    shouldShowResults.pipe(first()).subscribe((isComplete = false) => {
      // If this value is true, the user has attempted to manually close the second phase
      // of an already-submitted modal. We should treat that as a closure rather than a
      // dismissal, so that the modal data will be passed back.
      if (isComplete && this.result?.entity) {
        return this.activeModal.close(this.result.entity);
      }
      // Otherwise, the modal was actually canceled and no work has been done. Dismiss.
      this.activeModal.dismiss();
    });
  }

  public onDelete() {
    this.modalService
      .showAlert({
        title: this.i18n.Core_Delete,
        description: this.translate.instant(
          'OrgInternalContent_DeleteSingleContentFormat',
          { title: '' }
        ),
        cancelButtonText: this.i18n.Core_Cancel,
        confirmButtonText: this.i18n.Core_Delete,
      })
      .subscribe(() => {
        this.facade.delete().subscribe(() => this.activeModal.close());
      });
  }

  public onKeyDown(
    event: KeyboardEvent,
    shouldShowResults: Observable<boolean> = of(false)
  ) {
    // Handler for ESCAPE key should be global.
    if (isKey(event, Key.Escape)) {
      return this.onDismiss(shouldShowResults);
    } else if (isKey(event, Key.Enter)) {
      if (event.srcElement['href']) {
        // allow to redirect
      } else {
        // Other handlers.
        this.facade.onKeyDown(event);
      }
    } else {
      // Other handlers.
      this.facade.onKeyDown(event);
    }
  }

  // TODO: Several of the following handlers should be refactored to become viewmodel methods instead
  public onTagsChanged(formControl: FormControl, tags: TagsApi.Tag[]) {
    this.facade.setCustomFieldValue(formControl, tags);
  }

  public onQuestionsCorrectFieldChange(formControl: FormControl, value) {
    this.facade.setCustomFieldValue(formControl, value);
    this.manualValidateField('questions');
  }

  // Called for duplicates found to support adding content to hold for later bin
  public onViewDuplicates() {
    this.facade.viewDuplicates();
  }

  public onSubmit() {
    const formUrl =
      this.form.controls.mediaUrl ??
      this.form.controls.courseUrl ??
      this.form.controls.eventUrl ??
      this.form.controls.url;
    const nonBrokenUrlErrorExists =
      !formUrl?.errors?.urlBrokenValidation ||
      (formUrl?.errors?.urlBrokenValidation &&
        Object.values(this.form.controls).filter(
          (control) => control.status === 'INVALID'
        ).length > 1);

    this.isSaving = true;

    // We want to allow form submission even if there is a broken url,
    // while still informing the user that the url is broken via the urlBrokenValidation error
    if (!this.form.valid && nonBrokenUrlErrorExists) {
      this.form.markAllAsTouched();
      this.userInputsService.markChildFormsAsTouched();
      this.isSaving = false;
      return;
    }
    this.facade
      .onSubmit()
      // set `isSaving` to false here instead of in subscribe, in case of *errors*.
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe((result) => {
        this.result = result;
        if (!this.facade.isCompleting || this.facade.isEditing) {
          // For consistency and symmetry, the modal both receives and produces an entity model
          this.activeModal.close({
            ...result.entity,
            overrideScrapedData: result.overrideScrapedData
          });
        }
        // else if completing, the animation will play, then we'll close
      });
  }

  /** Call to request the modal to close, optionally with navigation to a profile collection containing the new user input. */
  public onNavigateToCollection(collectionUrl?: string) {
    // close the modal with the entity as result before navigating
    this.activeModal.close(this.result.entity); // input should be assumed to be submitted successfully if we're here
    // TODO: We probably don't need this now that all the profile stuff is ngx -- test it!
    const isProfilePage =
      this.windowRef.location.pathname.indexOf('index/1') > -1;
    if (isProfilePage) {
      // we're already on the profile page so no need to formally navigate
      if (this.isNewbUser) {
        // and it's a new user.
        // collection tab doesn't exist yet, so refresh the page.
        // TODO: make tabs dynamic so page refresh isn't necessary
        this.windowRef.location.reload();
      }
    } else if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  public updateBrokenUrl($event, formControl: FormControl) {
    this.facade.setCustomFieldValue(formControl, $event.srcElement.value, true);
    formControl.updateValueAndValidity();
  }

  public updateAndValidateComponent(
    $event,
    formControl: FormControl,
    markAsTouched?: boolean
  ) {
    this.facade.setCustomFieldValue(
      formControl,
      $event.srcElement.value,
      markAsTouched
    );
    // this doesn't seem to be working but the intention is to have the fields validations message show
    formControl.updateValueAndValidity();
  }

  public onCourseTypeChange(formControl: FormControl, item: any) {
    this.facade.setCustomFieldValue(formControl, item.id);
  }

  public onProviderSelection($event, formControl) {
    (this.facade as CourseFacade).onProviderSelected($event.item.id);
    this.facade.setCustomFieldValue(formControl, $event.item.label);
  }

  /**
   * Handler for Provider Typeahead event
   */
  public onProviderSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> => {
    return term.pipe(
      lazySearch((t: string) => (this.facade as CourseFacade).loadProviders(t))
    );
  };

  public labelFormatter(result) {
    return result.label;
  }

  public onCountrySelection(
    event: NgbTypeaheadSelectItemEvent,
    formControl: FormControl
  ): void {
    const { item } = event;
    this.facade.setCustomFieldValue(formControl, item);
  }

  public onCountrySearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly any[]> => {
    return term.pipe(
      lazySearch((t: string) => (this.facade as CourseFacade).loadCountries(t))
    );
  };

  public onCourseTitleSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly unknown[]> => {
    return (this.facade as CourseFacade).onCourseTitleSearch(term);
  };

  public onSelectCourseFromList(
    event: NgbTypeaheadSelectItemEvent,
    formControl: FormControl
  ): void {
    const { item } = event;
    this.facade.setCustomFieldValue(formControl, item);
    (this.facade as CourseFacade).onSelectCourse(item.id);
  }

  public onImageSizeChange(sizeId: number, formControl: FormControl): void {
    this.facade.setCustomFieldValue(formControl, sizeId);
  }

  /**
   * Loads recorded video modal and update the form's mediaUrl
   */
  public loadRecordedVideoModal(): void {
    this.modalService
      .show(AuthorVideoModalComponent, {
        backdropClickClose: true,
        windowClass: 'xlg-modal',
      })
      .subscribe((data: AzureUserAuthoredResult) => {
        const formControl = this.form.get('mediaUrl') as FormControl;
        (this.facade as VideoModalFacade).recordedVideoUploaded(
          formControl,
          data
        );
      });
  }

  public validatePositiveNumericValue(event: any) {
    const validateNumericValue =
      this.userInputsService.validateNumericValueOnKeyDown(event);
    if (!validateNumericValue) {
      event.preventDefault();
    }
  }

  public getInputUrlErrorMessage(
    formControlValue: string,
    params: {
      label: string;
      invalidUrlMessage: string;
      brokenUrlMessage: string;
      originalUrl: string;
    },
    hasBrokenUrl: boolean
  ) {
    const urlIsBroken = params.originalUrl === formControlValue && hasBrokenUrl;
    return urlIsBroken ? params.brokenUrlMessage : params.invalidUrlMessage;
  }

  private manualValidateField(fieldName: string) {
    const formControl = this.form.get(fieldName);
    if (formControl) {
      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }
}
