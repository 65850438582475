import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from '@nubebytes/angular-archwizard';
import { differenceBy } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

// types
import { GroupIdentifier, GroupPrivacy } from '@app/groups/group-api';
import { JobRolesItem } from '@app/opportunities/components/job-roles/job-role.model';
import { Opportunity, Skill } from '@app/opportunities/opportunities-api.model';
import { OpportunityDurationType } from '@app/opportunities/opportunities.enums';
import { OpportunityType } from '@app/orgs/org-opportunity-types';
import {
  VisibilityItem,
  VisibilityOption,
} from '@app/shared/components/visibility/visibility.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { UserProfile, UserSearchItem } from '@app/user/user-api.model';

// misc
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { getNestedObject } from '@app/shared/utils/property';
import { getISODateString, isTypeMissing, mapSkills } from '../../../utils';

// services
import { OpportunityModalService } from '@app/opportunities/components/modals/opportunity-modal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { CustomAdapterService } from '@app/shared/services/date-picker/custom-adapter.service';
import { CustomDateParserFormatterService } from '@app/shared/services/date-picker/custom-date-parser-formatter.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '@app/shared/components/select/select.component';

interface TabDefinition {
  tabId: number;
  dgat: string;
  label: string;
  templateName: string;
}

type UserSearchItemLike = Partial<UserProfile> | UserSearchItem;

const durationValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let errors: ValidationErrors | null = null;
  // duration units and type
  const durationControl = control.get('duration');
  const durationUnitsControl = durationControl.get('durationUnits');
  const durationUnitTypeControl = durationControl.get('durationUnitType');
  const units = durationUnitsControl.value;
  const unitType = durationUnitTypeControl.value;
  // dates
  const startDateControl = control.get('opportunityStartDate');
  const endDateControl = durationControl.get('opportunityEndDate');
  const startDate = getISODateString(startDateControl.value);
  const endDate = getISODateString(endDateControl.value);
  // Revalidate our controls, clearing errors that are no longer appropriate.
  durationControl.updateValueAndValidity({ onlySelf: true });
  startDateControl.updateValueAndValidity({ onlySelf: true });
  endDateControl.updateValueAndValidity({ onlySelf: true });
  durationUnitsControl.updateValueAndValidity({ onlySelf: true });
  durationUnitTypeControl.updateValueAndValidity({ onlySelf: true });

  switch (unitType) {
    // Unit type is either not set or is set to NotSpecified.
    case undefined:
    case null:
    case '':
    case 'NotSpecified':
      break;
    // Unit type is end date. Validate endDate value.
    case 'EndDate':
      if (!endDate || endDateControl.invalid) {
        durationControl.setErrors({
          invalidDate: true,
          message: 'OrgManage_Opportunities_Valid_Date',
        });
        errors = { invalid: true };
      }
      break;
    // Unit type is Hours/Days/Weeks/Months. Validate durationUnit value.
    default:
      if (!units || durationUnitsControl.invalid) {
        durationControl.setErrors({
          invalidDuration: true,
          message: 'OrgManage_Opportunities_Valid_Duration',
        });
        errors = { invalid: true };
      }
  }

  if (startDate && startDateControl.invalid) {
    // set a custom, translatable message
    startDateControl.setErrors({
      invalid: true,
      message: 'OrgManage_Opportunities_Valid_Date',
    });
  }

  // only need cross validation when have both start date and end date
  if (startDate && endDate && startDate.localeCompare(endDate) === 1) {
    // set start date error
    startDateControl.setErrors({
      dateCompare: true,
      message: 'OrgManage_Opportunities_DateCompare',
    });
    durationControl.setErrors({
      dateCompare: true,
      message: 'OrgManage_Opportunities_DateCompare',
    });
    errors = { invalid: true };
  }

  return errors;
};

const jobRoleValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.get('targetId').invalid || control.get('targetName').invalid) {
    return { invalid: true };
  }
};

@Component({
  selector: 'dgx-opportunity-modal',
  templateUrl: './opportunity-modal.component.html',
  styleUrls: ['./opportunity-modal.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapterService },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatterService,
    },
    WizardComponent,
  ],
})
export class OpportunityModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  // bindings
  @Input() public activeTabId = 1;
  @Input() public fetchExtraData = false;
  @Input() public headerText: string;
  @Input() public isCloning = false;
  @Input() public isEditing = false;
  @Input() public opportunity: Opportunity;
  @Input() public skillSuggestions: Skill[] = [];
  @Input() public visibility: VisibilityOption[] = [];
  @ViewChild('detailsSection') public detailsSectionRef: TemplateRef<any>;
  @ViewChild('descriptionSection')
  public descriptionSectionRef: TemplateRef<any>;
  @ViewChild('skillsSection') public skillsSectionRef: TemplateRef<any>;
  @ViewChild(WizardComponent) public wizard: WizardComponent;

  // locals
  public readonly departmentLength = 255;
  public readonly descLength = 10000;
  public readonly titleLength = 255;

  public readonly locationNameMinLength = 2;
  public readonly locationNameMaxLength = 255;

  public collaborators: UserSearchItemLike[] = [];
  public confirmModalRef: { closed: boolean };
  public descLengthHint: string;
  public durationTitle = '';
  public durationType = [
    {
      name: OpportunityDurationType.NotSpecified,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.NotSpecified}`
      ),
    },
    {
      name: OpportunityDurationType.EndDate,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.EndDate}`
      ),
    },
    {
      name: OpportunityDurationType.Hours,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.Hours}`
      ),
    },
    {
      name: OpportunityDurationType.Days,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.Days}`
      ),
    },
    {
      name: OpportunityDurationType.Weeks,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.Weeks}`
      ),
    },
    {
      name: OpportunityDurationType.Months,
      i18n: this.translateService.instant(
        `Core_${OpportunityDurationType.Months}`
      ),
    },
  ];
  public groups: GroupIdentifier[] = [];
  public hasInternalJobSkills = false;
  public hasProvider = false;
  public jobRoleTarget: JobRolesItem;
  public i18n = this.translateService.instant(
    'Opportunities_Skills_AddTargetLevel'
  );
  // Binds to the AngularJS `dgImageUploadSection` component.
  public imageUrl: string;
  // Default to loading true, as we know we need to load some data on init
  public isLoading = true;
  // Show pending spinner for submit button
  public isSubmitPending = false;
  public jobRolesSearch: TypeaheadSearchFunction<string, JobRolesItem> =
    this.opportunityModalService.search;
  // Used by Edit Modal
  public modalTabs: TabDefinition[] = [
    {
      tabId: 1,
      dgat: 'opportunity-modal-d40',
      label: 'OrgManage_Opportunities_Tab_Details',
      templateName: 'details',
    },
    {
      tabId: 2,
      dgat: 'opportunity-modal-9ce',
      label: 'OrgManage_Opportunities_Tab_Description',
      templateName: 'description',
    },
    {
      tabId: 3,
      dgat: 'opportunity-modal-7ca',
      label: 'OrgManage_Opportunities_Tab_Skills',
      templateName: 'skills',
    },
  ];
  // map this to the BE OpportunityVM
  public opportunityForm = this.formBuilder.group({
    // make sure opportunity includes job roles
    // new state
    opportunityId: [],
    step0: this.formBuilder.group(
      {
        authors: [[]], // collaborators -- can't be edited from this modal
        imageUrl: [''], // image url -- can't currently be added from this modal
        title: [
          undefined,
          [
            Validators.required,
            Validators.pattern(/.*\S.*/), // invalid empty string
            Validators.maxLength(this.titleLength),
          ],
        ],
        type: [undefined, Validators.required],
        // validate the job role as a single item!
        jobRole: this.formBuilder.group(
          {
            targetId: [undefined, Validators.pattern('^[0-9]*$')], // job role id (must be a number)
            targetName: [undefined], // job role name
          },
          { validators: jobRoleValidator }
        ),
        department: [undefined, [Validators.maxLength(this.departmentLength)]],
        opportunityStartDate: [undefined],
        // validate the duration as a single item!
        duration: this.formBuilder.group({
          opportunityEndDate: [undefined],
          durationUnits: [
            undefined,
            [
              Validators.pattern(/^\d{0,19}\.?\d{0,2}$/),
              Validators.min(0.01), // 0 hours/days/weeks/months not valid
              Validators.max(999999999999999), // Max value BE accepts, this is very large and un-realistic anyway
            ],
          ],
          durationUnitType: [undefined],
        }),
        locationName: [
          undefined,
          [
            Validators.pattern(/.*\S.*/), // invalid empty string
            Validators.minLength(this.locationNameMinLength),
            Validators.maxLength(this.locationNameMaxLength),
          ],
        ],
        privacyId: [undefined, Validators.required],
        // this only has value if the privacy is visible-to-groups
        groupIds: [[]],
      },
      {
        validators: durationValidator,
      }
    ),
    step1: this.formBuilder.group({
      description: [
        undefined,
        [
          Validators.required,
          Validators.pattern(/.*\S.*/),
          Validators.maxLength(this.descLength),
        ],
      ],
    }),
    step2: this.formBuilder.group({
      tags: [[], Validators.required],
    }),
  });
  public opportunityType: OpportunityType;
  // opportunityTypes binds to the AngularJS `dgSelect` component
  public opportunityTypes: OpportunityType[] = [];
  // Get a list of opportunity types from the service as an observable
  public opportunityTypes$ = this.opportunityModalService.opportunityTypes$;
  public showErrors = false;
  // This keeps the final list of skills, which are used in form and passed to the skills components
  public skills: Skill[] = [];
  public splitDuration = false;
  // This is used to track the skills manually entered via skills searching
  // TODO: this can just be a string array to track user added skills by name as we match by name
  public userInputSkills: Skill[] = [];
  public wizardBackText = this.translateService.instant('Core_Back');
  public wizardNextText = this.translateService.instant('Core_Next');

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private opportunityModalService: OpportunityModalService,
    private translateService: TranslateService,
    private notifierService: NotifierService
  ) {
    super();
  }

  /**
   * Hide admin groups
   */
  public get excludedGroupPrivacyLevels(): GroupPrivacy[] {
    return [];
  }

  public get canGoBack(): boolean {
    return !this.isEditing && this.wizard?.currentStepIndex > 0;
  }

  public get currentStepIndex(): number {
    if (!this.wizard) {
      return 0;
    }

    return this.wizard.currentStepIndex;
  }

  /**
   * Whether to disable the submit button.
   */
  public get isSubmitDisabled(): boolean {
    return (
      (!this.opportunityForm.valid && !this.isEditing) || this.isSubmitPending
    );
  }

  public get submitButtonText(): string {
    if (this.isEditing) {
      return this.translateService.instant('Core_SaveChanges');
    }

    if (
      !this.wizard ||
      this.wizard.currentStepIndex === this.wizard.wizardSteps.length - 1
    ) {
      return this.translateService.instant('Core_Save');
    }

    return this.translateService.instant('Core_Next');
  }

  public get canManageOpportunities(): boolean {
    return this.authService.authUser.canManageOpportunities;
  }

  public get canCreateOpportunities(): boolean {
    return this.authService.authUser.canCreateOpportunities;
  }

  public ngOnInit(): void {
    // ensure job role field is only visible when skills taxonomy is enabled
    this.hasInternalJobSkills =
      !!this.authService.authUser.hasInternalJobSkills;
    this.getStepForm(1)
      .get('description')
      .valueChanges.pipe(debounceTime(300), this.takeUntilDestroyed())
      .subscribe();

    // Load required data from back end prior to initializing modal and displaying content
    // TODO: This has to be `any` due to our `tags` property.
    const opportunityDataObservables$: Observable<
      OpportunityType[] | Opportunity | any
    >[] = [];

    // Get our types. The initial return may occasionally be an empty array (until the
    // service has been called for the first time), before filling in later.
    opportunityDataObservables$.push(
      this.opportunityModalService.opportunityTypes$.pipe(
        // Format our types with the help of the opportunity-modal service
        map((opportunityTypes) =>
          opportunityTypes.sort((a, b) => a.i18n.localeCompare(b.i18n))
        ),
        this.takeUntilDestroyed(),
        tap((opportunityTypes: OpportunityType[]) => {
          this.opportunityTypes = this.formatTypes(opportunityTypes);
          if (this.isCloning || this.isEditing) {
            this.updateFormValue('type', this.opportunityType);
          }
        })
      )
    );

    // No observable for this at all unless it's either cloning or editing
    if (this.isCloning || this.isEditing) {
      // TODO: This has to be `any` due to our `tags` property.
      let opportunityDetailsObservable$: Observable<any>;
      if (this.fetchExtraData) {
        // TODO: This method doesn't belong on the opportunityModalService. We should be
        // injecting the regular opportunities service instead.
        opportunityDetailsObservable$ =
          this.opportunityModalService.getOpportunity(
            this.opportunity.opportunityId
          );
      } else {
        opportunityDetailsObservable$ = of(this.opportunity);
      }

      // in either case, we pipe off of it to populate our form...
      // add our details observable to the array
      opportunityDataObservables$.push(
        opportunityDetailsObservable$.pipe(
          this.takeUntilDestroyed(),
          tap((opportunityDetails) => {
            if (this.isCloning || this.isEditing) {
              let canSetVisibility = true;
              // clone-only pre-patch adjustments
              let opportunityTitle = this.opportunity.title;
              if (this.isCloning) {
                // check to make sure the user can clone the current visibility
                canSetVisibility =
                  this.visibility.filter(
                    (v) => v.item.visibility === this.opportunity.privacyId
                  ).length > 0;
                // title is prefixed by [Clone of] by default (the user can edit this)
                opportunityTitle = this.translateService.instant(
                  'Core_CloneOfFormat',
                  {
                    title: this.opportunity.title,
                  }
                );
              }

              // copy the fields over to the form
              this.updateFormValues(
                {
                  ...this.opportunity,
                  title: opportunityTitle,
                  type: this.opportunityType,
                  jobRole: {
                    targetId: this.opportunity.targetId,
                    targetName: this.opportunity.targetName,
                  },
                  duration: {
                    durationUnitType: this.opportunity.durationUnitType,
                    durationUnits: this.opportunity.durationUnits,
                  },
                  privacyId: canSetVisibility
                    ? this.opportunity.privacyId
                    : null,
                  // if cloning, clear out authors (we'll add the current user in later)
                  authors: this.isCloning ? [] : this.opportunity.authors,
                },
                [0, 1, 2]
              );

              // update the start date value alone
              if (this.opportunity.opportunityStartDate) {
                this.updateFormDateValue(
                  'opportunityStartDate',
                  new Date(this.opportunity.opportunityStartDate)
                );
              }

              // update the end date value alone
              if (this.opportunity.opportunityEndDate) {
                this.updateFormDateValue(
                  'duration.opportunityEndDate',
                  new Date(this.opportunity.opportunityEndDate)
                );
              }

              // The tags with ratings are from opportunity details
              // When we display the edit/clone form, all skills that are already on the
              // opportunity are *treated* as user-input skills, because the BE can't
              // distinguish between them. Better safe than sorry!
              this.skills = this.userInputSkills = mapSkills(
                opportunityDetails.tags
              );
              this.updateFormValue('tags', this.skills, [2]);

              // make sure the collaborators are set correctly
              this.collaborators = this.opportunity.authors.map((a) =>
                this.createUserSearchItem(a)
              );

              this.groups =
                this.opportunity.groupIds?.map((g) =>
                  this.createGroupSearchItem(g)
                ) || [];

              // set job role object
              this.jobRoleTarget = {
                id: this.opportunity.targetId,
                name: this.opportunity.targetName,
              };

              this.imageUrl = this.opportunity.imageUrl;

              // If duration units or end date have a value, we need to split duration and update title
              if (
                this.getStepForm(0).get('duration.durationUnits').value ||
                this.getStepForm(0).get('duration.opportunityEndDate').value
              ) {
                this.splitDuration = true;
                this.updateDurationTitle(
                  this.getStepForm(0).get('duration.durationUnitType').value
                );
              }
            }
          })
        )
      );
    }

    // listen for both our Observables
    combineLatest(opportunityDataObservables$)
      // once everything's completed...
      .subscribe(() => {
        if (!this.isEditing && !this.isCloning) {
          // this is a new opportunity so there is no description yet
          this.updateDescLengthHint('');
          // Visibility should also default to private
          this.updateFormValue('privacyId', Visibility.private);
        }
        // Add author as a collaborator if collaborators are still empty
        // (for Cloning and Add)
        if (!this.collaborators.length || this.isCloning) {
          const userAuthor = this.createUserSearchItem(
            this.authService.authUser.viewerProfile
          );
          if (!this.collaborators.length) {
            // if the opportunity is being edited and there are NOT any collaborators, we want to add the current user.
            this.collaborators = [{ ...userAuthor }];
          }
          this.updateFormValue('authors', [userAuthor]);
        }
        // And disable fields for third-party opportunities that shouldn't
        // be editable.
        if (this.opportunity?.provider) {
          this.getStepForm(0).get('title').disable();

          this.getStepForm(0).get('locationName').disable();
          this.hasProvider = true;
        }
        // Finally done loading.
        this.isLoading = false;
      });
  }

  public getStepForm(step: number) {
    return this.opportunityForm?.get(`step${step}`);
  }

  public getTabTemplate(index: number) {
    return this[this.modalTabs[index].templateName + 'SectionRef'];
  }

  public goToNextStep() {
    // Reset form errors before validation
    this.preStepChange();
    // Check validation
    if (this.getStepForm(this.wizard?.currentStepIndex)?.invalid) {
      // Show errors if our current step is invalid
      this.showErrors = true;
      // Cancel next step
      return false;
    }
    // If no errors, good to go to next step
    this.wizard.goToNextStep();
  }

  public goToPreviousStep() {
    this.wizard.goToPreviousStep();
  }

  // TODO: Change this name to match our pattern, once VisibilityComponent
  // has been updated to no longer use `on`-prefixed @Outputs.
  public handleGroupRemove($event: GroupIdentifier): void {
    this.groups = this.groups.filter(
      ({ groupId }) => $event.groupId !== groupId
    );

    this.updateFormValue('groupIds', [...this.groups]);
  }

  // TODO: Change this name to match our pattern, once VisibilityComponent
  // has been updated to no longer use `on`-prefixed @Outputs.
  public handleGroupSelection($event: GroupIdentifier): void {
    const exists = this.groups.some((g) => g.groupId === $event.groupId);

    if (exists) {
      return;
    }

    this.groups = [...this.groups, { ...$event }];
    this.updateFormValue('groupIds', [...this.groups]);
  }

  // TODO: Change this name to match our pattern, once VisibilityComponent
  // has been updated to no longer use `on`-prefixed @Outputs.
  public handleVisibilitySelection($event: VisibilityItem): void {
    this.updateFormValue('privacyId', $event.visibility);
    this.getStepForm(0).markAsDirty();
  }

  /**
   * Determine whether the current tab is active.
   *
   * @param tabId - ID of the current tab, zero-indexed.
   */
  public isActiveTab(tabId: number): boolean {
    // Check against the current active tab ID + 1 when editing.
    if (this.isEditing) {
      return tabId + 1 === this.activeTabId;
    }
    // And against the current step index otherwise
    return this.currentStepIndex === tabId;
  }

  /**
   * Combine our form with our original opportunity and format it appropriately
   * for the BE.
   *
   * @param opportunity - the Opportunity to return.
   */
  public mergeFormIntoOpportunity(opportunity: Opportunity): Opportunity {
    // Combine the form value with the original opportunity,
    // to preserve fields like `OpportunityPublishDate` that
    // would otherwise be missing when the view is refreshed.
    // (This is not typed as an opportunity as `duration` and `jobRole`
    // are not fields the Opportunity has.)
    const updatedOpportunity = {
      ...opportunity,
      // add all our form steps
      ...(this.getStepForm(0).value as Object), // TODO NGX 14 UPGRADE: Fix Object type (CCO)
      ...(this.getStepForm(1).value as Object), // TODO NGX 14 UPGRADE: Fix Object type (CCO)
      ...(this.getStepForm(2).value as Object), // TODO NGX 14 UPGRADE: Fix Object type (CCO)
    };
    // handle our nested properties (the BE will disregard undefined values)
    // duration units should be coerced into a number, *if* they are not undefined
    updatedOpportunity.durationUnits = updatedOpportunity.duration
      ?.durationUnits
      ? +updatedOpportunity.duration.durationUnits
      : undefined;
    updatedOpportunity.durationUnitType =
      updatedOpportunity.duration?.durationUnitType ?? undefined;
    updatedOpportunity.targetId = updatedOpportunity.jobRole?.targetId ?? 0;
    updatedOpportunity.targetName = updatedOpportunity.jobRole?.targetName;
    // format our dates, *if* they are not undefined
    updatedOpportunity.opportunityEndDate = getISODateString(
      updatedOpportunity.duration?.opportunityEndDate
    );
    updatedOpportunity.opportunityStartDate = getISODateString(
      updatedOpportunity.opportunityStartDate
    );
    // remove properties we no longer need!
    delete updatedOpportunity.duration;
    delete updatedOpportunity.jobRole;
    // return the completed opportunity
    return updatedOpportunity as Opportunity;
  }

  /**
   * Fires when description is updated for markdown content change.
   *
   * @param description - The updated field.
   */
  public onDescriptionChange(description: string) {
    this.updateFormValue('description', description, [1]);
    this.getStepForm(1).markAsDirty();
  }

  /**
   * Fires when dgSelect emits its value.
   *
   * @param $event - This contains an $event object which contains selectedItem.
   */
  public onDurationTypeSelection(option: Option) {
    const selectedItem = option.name;
    this.updateDurationTitle(selectedItem);

    switch (selectedItem) {
      case undefined:
      case null:
      case '':
      case 'NotSpecified':
        this.splitDuration = false;
        this.updateFormValues({
          duration: {
            durationUnits: undefined,
            opportunityEndDate: undefined,
          },
        });
        break;
      case 'EndDate':
        this.updateFormValue('duration.durationUnits', undefined);
        this.splitDuration = true;
        break;
      default:
        this.updateFormValue('duration.opportunityEndDate', undefined);
        this.splitDuration = true;
        break;
    }

    this.updateFormValue('duration.durationUnitType', selectedItem);
    this.getStepForm(0).markAsDirty();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onDismiss() {
    if (!this.opportunityForm.dirty) {
      this.activeModal.dismiss();
      return;
    }

    if (!this.confirmModalRef || this.confirmModalRef.closed) {
      this.confirmModalRef = this.opportunityModalService
        .showCloseConfirmModal()
        .subscribe(() => {
          this.activeModal.dismiss();
        });
    }
  }

  public onJobRoleSelection($event: JobRolesItem): void {
    const { name, id } = $event;
    const skillsFromJobRole = $event?.tags || [];

    // Create a simple string array of (lowercased) user-input skill names
    const userInputSkillNames = this.userInputSkills.map(({ name }) =>
      name.toLowerCase()
    );

    // Get the new skills we want to add to the opportunity, being careful
    // not to re-add any skills that are already on the opportunity
    const jobRoleSkillsToAdd = mapSkills(
      skillsFromJobRole.filter(
        ({ name }) => !userInputSkillNames.includes(name.toLowerCase())
      )
    );

    // But also strip out all skills from the skills array that *aren't*
    // user-input, so that we can flush out any skills added (during this
    // session) by a previous Job Role.
    const skillsToKeep = this.skills.filter(({ name }) =>
      userInputSkillNames.includes(name.toLowerCase())
    );

    // Now combine skillsToKeep with jobRoleSkillsToAdd
    // i.e., if the user is making a new Opportunity and they first choose "QA Engineer"
    // as its Job Role, but then change their mind to use "Developer", the QA-only skills
    // should be removed.
    this.skills = [...jobRoleSkillsToAdd, ...skillsToKeep];

    this.jobRoleTarget = {
      id,
      name,
    };

    this.updateFormValues(
      {
        tags: this.skills,
        jobRole: {
          targetId: id,
          targetName: name,
        },
      },
      [0, 2]
    );

    this.clearFieldsInvalidError([
      'jobRole',
      'jobRole.targetId',
      'jobRole.targetName',
    ]);

    // notify the user of any successful additions to the skills
    if (jobRoleSkillsToAdd.length) {
      const toastMessage = this.translateService.instant(
        'OrgManage_Opportunities_JobRoles_SkillAdded',
        {
          numberOfSkills: jobRoleSkillsToAdd.length,
          jobRoleName: name,
        }
      );
      this.notifierService.showSuccess(toastMessage);
    }
    this.getStepForm(0).markAsDirty();
  }

  public onJobRoleInvalidated(): void {
    this.clearJobRoleDetails();
    this.setFieldsInvalidError([
      'jobRole',
      'jobRole.targetId',
      'jobRole.targetName',
    ]);
  }

  public onJobRoleCleared(): void {
    this.clearJobRoleDetails();
    this.clearFieldsInvalidError([
      'jobRole',
      'jobRole.targetId',
      'jobRole.targetName',
    ]);
  }

  public onSubmit(): void {
    if (!this.opportunityForm.valid) {
      this.showErrors = true;
      return;
    }

    this.isSubmitPending = true;

    const observable = this.isEditing
      ? this.opportunityModalService.editOpportunity(
          this.mergeFormIntoOpportunity(this.opportunity)
        )
      : this.opportunityModalService.addOpportunity(
          this.mergeFormIntoOpportunity(this.opportunity),
          this.isCloning
        );

    observable.subscribe({
      next: (opportunity) => {
        this.activeModal.close(opportunity);
      },
      error: () => {
        this.activeModal.close();
      },
      complete: () => {
        this.isSubmitPending = false;
      },
    });
  }

  public onTypeSelection($event: Option) {
    // this is how the dgSelect.selection/onUpdate emit its values.
    const value = $event.name;
    this.updateFormValue('type', value);

    this.getStepForm(0).markAsDirty();
  }

  /**
   * To be used ONLY as call back when skills added via skills view component, which sends all skills.
   * There is logic in here to determine manually added skills so we can keep the skills updated when job role is used.
   *
   * @param skills List of skills
   */
  public onUpdateSkills({ skills }: { skills: Skill[] }) {
    // Keep the user-inputed skills distinct from the ones that
    // were already on the opportunity when we opened it for edit/
    // were added by a job role.
    // A skill was removed
    if (skills.length < this.skills.length) {
      const skillsRemoved = differenceBy(this.skills, skills, 'name');
      this.userInputSkills = this.userInputSkills.filter(
        (skill) =>
          !skillsRemoved.find(
            (removedSkill) => removedSkill.name === skill.name
          )
      );
    } else {
      // A skill was added or no change
      const newSkillsAdded = differenceBy(skills, this.skills, 'name');
      this.userInputSkills = [
        ...this.userInputSkills,
        ...mapSkills(newSkillsAdded),
      ];
    }

    this.skills = mapSkills(skills);
    this.updateFormValue('tags', this.skills, [2]);
    this.getStepForm(2).markAsDirty();
  }

  /**
   * Called by the Angular Archwizard. Sanity check.
   */
  public preStepChange() {
    // Reset form errors before validation
    this.showErrors = false;
  }

  /**
   * Will be true if the current tab has errors *and* those
   * errors should be displayed. *Only used by the Edit/Clone
   * Opportunity modals.*
   *
   * @param tabId - ID of the current tab, zero-indexed.
   */
  public tabHasError(tabId: number): boolean {
    // Hide if we're on the given step and `showErrors` is set to false,
    // *unless* the current step is the Skills tab, because that one doesn't
    // have the same obvious red "error" messaging when the Save button is clicked.
    if (tabId !== 2 && this.isActiveTab(tabId) && !this.showErrors) {
      return false;
    }
    // Otherwise, show if not loading and if the current step is invalid
    return !this.isLoading && !!this.getStepForm(tabId).invalid;
  }

  /**
   * Translate our errors for use with ValidateField.
   */
  public translateError(error: {
    required?: boolean;
    pattern?: boolean;
    minlength?: { requiredLength: number; actualLength: number };
    maxlength?: { requiredLength: number; actualLength: number };
  }) {
    if (!error) {
      return '';
    }

    if (error.required) {
      return this.translateService.instant(
        'OrgManage_Opportunities_Required_Field'
      );
    }

    if (error.pattern) {
      return this.translateService.instant(
        'OrgManage_Opportunities_Invalid_Field'
      );
    }

    if (error.minlength) {
      return this.translateService.instant(
        'OrgManage_Opportunities_Valid_MinLength',
        {
          number: error.minlength.requiredLength - error.minlength.actualLength,
        }
      );
    }

    if (error.maxlength) {
      return this.translateService.instant(
        'OrgManage_Opportunities_Valid_MaxLength',
        {
          number: error.maxlength.actualLength - error.maxlength.requiredLength,
        }
      );
    }
  }

  private clearFieldsInvalidError(fieldNames: string[], step = 0) {
    fieldNames.forEach((fieldName) =>
      this.getStepForm(step).get(fieldName).setErrors(null)
    );
  }

  private clearJobRoleDetails(): void {
    // Create a simple string array of (lowercased) user-input skill names
    const userInputSkillNames = this.userInputSkills.map(({ name }) =>
      name.toLowerCase()
    );

    // Now strip out all skills from the skills array that *aren't*
    // user-input, so that we can flush out any skills added (during this
    // session) by the now-removed Job Role.
    const skillsToKeep = this.skills.filter(({ name }) =>
      userInputSkillNames.includes(name.toLowerCase())
    );

    // i.e., if the user is making a new Opportunity and they first choose "QA Engineer"
    // as its Job Role, but then empty out that field, the QA-only skills should be
    // removed.
    this.skills = [...skillsToKeep];
    this.jobRoleTarget = undefined;

    this.updateFormValues(
      {
        tags: this.skills,
        jobRole: {
          targetId: undefined,
          targetName: undefined,
        },
      },
      [0, 2]
    );
  }

  private createGroupSearchItem(item) {
    return { ...item, groupId: item.id };
  }

  private createUserSearchItem(
    item: UserProfile | UserSearchItem
  ): UserSearchItemLike {
    return {
      userProfileKey: item.userProfileKey,
      name: item.name,
      picture: item.picture,
      email: item.email,
      vanityUrl: item.vanityUrl,
      organizationEmail: (item as UserSearchItem)?.organizationEmail,
    };
  }

  private formatType(opportunity: Opportunity): OpportunityType {
    return {
      // Whether this is one of our types for translation or a custom type,
      // the 'name' property should be our raw input
      name: opportunity.type,
      // This will be what we display to the end-user; a custom type, if
      // custom, or else a translated type
      i18n: this.opportunityModalService.getDisplayOpportunityType(opportunity),
    };
  }

  private formatTypes(opportunityTypes: OpportunityType[]): OpportunityType[] {
    // Check if an opportunity already has a type and check if type
    // already exists in opportunity type array to prevent duplicate types
    if (isTypeMissing(this.opportunity, opportunityTypes)) {
      // If we are editing, we want to *add* the type to the list,
      // both grandfathering in old custom types *and* allowing users
      // to edit opportunities of types they wouldn't be able to create

      // This also caters for case where user has no manage or create permissions, and they are added as
      // a collaborator. Which means they don't have access to any types, but they are able to edit, and
      // when editing the opportunity type should be available.
      // The UI restricts editing of the opportunity type in this case, but we still need it available in the
      // options for normal rendering of the option to happen.
      if (this.isEditing) {
        // copy to opportunityType
        this.opportunityType = this.opportunity.type;
        // handle an *array* of opportunity types appropriately
        if (Array.isArray(this.opportunity.type)) {
          return [
            ...opportunityTypes,
            // additional spread here to split the array into multiple objects
            ...this.opportunity.type.map((type: string) =>
              this.formatType({
                ...this.opportunity,
                type,
              })
            ),
          ];
        }
        // otherwise, handle a simple type
        return [...opportunityTypes, this.formatType(this.opportunity)];
      }
      // If we are instead *cloning*, a missing type means that our user
      // is trying to "create" an opportunity of a type they don't have
      // permission to. Empty out the type so that they will be forced to
      // select a different type from the drop-down before they can save.
      this.opportunityType = undefined;
    }
    // Type isn't missing -- this might be hit *after* originally having
    // a missing type, when we were calling the service for the first time.
    // TODO: Fix this. There has to be a better way!
    else if (this.isCloning || this.isEditing) {
      this.opportunityType = this.opportunity.type;
    }
    // Return our (unchanged) opportunityTypes to update this.opportunityTypes
    return opportunityTypes;
  }

  private setFieldsInvalidError(fieldNames: string[], step = 0) {
    fieldNames.forEach((fieldName) =>
      this.getStepForm(step).get(fieldName).setErrors({ invalid: true })
    );
  }

  // TODO: Restore this to the component? It's no longer being used.
  // But we do now have a more helpful error message...
  private updateDescLengthHint(description: string): void {
    this.descLengthHint = this.translateService.instant(
      'OrgManage_Opportunities_DescMaxChars',
      { limit: this.descLength - description.length }
    );
  }

  private updateDurationTitle(title: string) {
    switch (title) {
      case 'Hours':
        this.durationTitle = 'OrgManage_Opportunities_OfHours';
        break;
      case 'Days':
        this.durationTitle = 'OrgManage_Opportunities_OfDays';
        break;
      case 'Weeks':
        this.durationTitle = 'OrgManage_Opportunities_OfWeeks';
        break;
      case 'Months':
        this.durationTitle = 'OrgManage_Opportunities_OfMonths';
        break;
      case 'EndDate':
        this.durationTitle = 'Core_EndDate';
        break;
      default:
        break;
    }
  }

  private updateFormDateValue(dateField: string, parsedDate: Date) {
    this.updateFormValue(dateField, {
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    });
  }

  private updateFormValue(fieldName: string, newValue: any, steps = [0]): void {
    let valueObj = {
      // computed property syntax for dynamic property name
      // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Object_initializer)
      [fieldName]: newValue,
    };
    // handle dot notation
    if (fieldName.includes('.')) {
      valueObj = getNestedObject(fieldName, newValue);
    }
    this.updateFormValues(valueObj, steps);
  }

  // We need to patch all the steps when editing/cloning
  private updateFormValues(valueObj: any, steps = [0]): void {
    steps.forEach((step) => {
      // TODO NGX 14 UPGRADE: Fix any type (CCO)
      (this.getStepForm(step) as any).patchValue(valueObj);
    });
  }
}
