import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthUser } from '@app/account/account-api.model';
import { Mentorship, MentorshipType } from '@app/mentoring/mentoring-api.model';
import { MentorshipTypeEnum } from '@app/mentoring/mentoring.enums';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { AuthService } from '@app/shared/services/auth.service';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export interface PeerRatingModal {
  raterProfileKey: number;
  comment: string;
  tagIds: number[];
  requestIsFromMentor: boolean;
}

@Component({
  selector: 'dgx-peer-rating-modal',
  templateUrl: './peer-rating-modal.component.html',
  styleUrls: ['./peer-rating-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeerRatingModalComponent implements OnInit {
  public authUser: AuthUser;
  public fields: DfFormFieldConfig[] = [];
  public i18n = this.translate.instant([
    'Core_To',
    'Core_Message',
    'Core_Skills',
    'Core_Suggestions',
    'RequestRating_SendRequest',
    'Mentoring_PeerRatingRequest_Placeholder',
    'Mentoring_PeerRatingRequest',
    'OrgSkills_SkillPlaceholder',
  ]);
  public model: PeerRatingModal = {
    raterProfileKey: null,
    comment: '',
    tagIds: [],
    requestIsFromMentor: false, // the value for this is dynamically set in the mentor api service
  };
  public options: FormlyFormOptions = {};
  public form: FormGroup = new FormGroup({});
  public selectedTags: TagsApi.Tag[] = [];
  public suggestedTags: TagsApi.Tag[] = [];

  @Input() public mentorship: Mentorship;
  @Input() public mentorshipType: MentorshipType;

  constructor(
    protected activeModal: NgbActiveModal,
    private authService: AuthService,
    private builder: DfFormFieldBuilder,
    protected cdr: ChangeDetectorRef,
    private mentoringTrackingService: MentoringTrackingService,
    private translate: TranslateService
  ) {}

  public get peer(): UserProfileSummary {
    return this.mentorshipType === MentorshipTypeEnum.Mentor
      ? this.mentorship.mentor
      : this.mentorship.mentee;
  }

  public get disableSubmitButton(): boolean {
    return !this.selectedTags.length || !this.model.comment;
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;

    this.setupMentoringType();
    this.fields = this.buildFormFields();

    this.cdr.markForCheck();
  }

  public onRequest() {
    this.model.tagIds = this.getTagIdsFromSelected();
    // Trim the values before submitting them.
    // Formly also has parsers, but they don't appear to work any better
    // than simply trimming once here.
    this.model.comment.trim();

    this.mentoringTrackingService.peerRatingRequested(
      this.mentorship,
      this.mentorshipType,
      this.model
    );

    switch (this.mentorshipType) {
      case MentorshipTypeEnum.Mentee:
        this.mentorship.requestedMenteePeerReview = true;
        break;
      case MentorshipTypeEnum.Mentor:
        this.mentorship.requestedMentorPeerReview = true;
        break;
    }
    this.activeModal.close({
      updatedMentorship: this.mentorship,
      modalResponse: this.model,
    });
  }

  public addReviewTag(addedTag: Partial<UserInterest>): void {
    // check for duplicates just incase
    const duplicate = this.selectedTags.some(
      (tag) => tag.title.toLowerCase() === addedTag.title.toLowerCase()
    );
    if (!duplicate) {
      // normalize the tag here, search returns a weird item and just in case anyways
      this.selectedTags.push(this.normalizeTag(addedTag));
    }
    // then remove the tag from suggested
    this.suggestedTags = this.suggestedTags.filter(
      (tag) => tag.title.toLowerCase() !== addedTag.title.toLowerCase()
    );

    this.cdr.markForCheck();
  }

  public removeReviewTag(removedTag: Partial<UserInterest>): void {
    this.selectedTags = this.selectedTags.filter(
      // assume that we're dealing with tags with correct data here
      (tag) => tag.tagId !== removedTag.tagId
    );

    this.cdr.markForCheck();
  }

  private setupMentoringType() {
    switch (this.mentorshipType) {
      // when the request is coming from a row on the mentee table (viewer is a mentor)
      case MentorshipTypeEnum.Mentee:
        this.model.raterProfileKey = this.mentorship.menteeUserProfileKey;
        this.selectedTags = this.mentorship.tagsForMentorRating;
        // Without making another API call we cant tell from the mentee object which are the focus skills,
        // therefor try to find the matching skills with the mentor, but if there are none (there should be)
        this.suggestedTags = this.authUser.viewerInterests.filter(
          (tag: UserInterest) => {
            this.mentorship.mentee.tagNames.find(
              (tagName: string) =>
                tag.title.toLocaleLowerCase() === tagName.toLocaleLowerCase()
            );
          }
        );
        // Fallback on the mentors focus skills as suggestions. It's still ofc possible to search for skills.
        if (!!this.suggestedTags) {
          this.suggestedTags = this.authUser.viewerInterests.filter(
            (tag: UserInterest) => tag.isMentoring || tag.isFocused
          );
        }
        break;
      // when the request is coming from a row on the mentor table (viewer is a mentee)
      case MentorshipTypeEnum.Mentor:
        this.model.raterProfileKey = this.mentorship.mentorUserProfileKey;
        this.authUser.viewerInterests?.forEach((tag: UserInterest) => {
          tag.isFocused
            ? this.selectedTags.push(tag)
            : this.suggestedTags.push(tag);
        });
        break;
    }
  }

  private buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    return [
      this.builder
        .charCountedTextarea('comment', 'Core_Message', 2000)
        .asRequired()
        .withPlaceholder(this.i18n.Mentoring_PeerRatingRequest_Placeholder)
        .validatedBy(isEmptyValidator)
        .updatedOn('change')
        .build(),
    ];
  }

  private getTagIdsFromSelected(): number[] {
    const tagIds = this.selectedTags.map((tag) => tag.tagId);
    return tagIds;
  }

  private normalizeTag(newTag): TagsApi.Tag {
    // Assume the existing data is valid, so we fetch
    const tagId = newTag.tagId || newTag.id || newTag.resourceId;

    const normalizedTag = {
      name: null,
      tagId,
      title: newTag.title,
    };

    return normalizedTag;
  }
}
