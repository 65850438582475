<form (ngSubmit)="savePathwaySummary()">
  <dgx-modal
    [canCancel]="true"
    [isLoading]="isLoading"
    [isSubmitDisabled]="isSubmitDisabled"
    [isSubmitPending]="isSaving"
    [submitButtonText]="i18n.pathwayAddEditModal_SavePathway"
    [useDefaultForm]="false"
    (dismiss)="onDismiss()"
    bodyClasses="guts-p-h-2 form-wrap"
    trapFocus
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ addEditHeading }}
    </ng-container>
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="guts-p-t-1">
        <p *ngIf="!isConsumerUser" class="par par--small">
          {{
            (isChannel
              ? 'pathwayAddEditModal_PathwaySettingsChannelDesc'
              : 'pathwayAddEditModal_PathwaySettingsDesc'
            ) | translate
          }}
        </p>
        <label
          for="pathName"
          class="pathway-name-label form-label"
          id="pathName-label"
          >{{ i18n.pathwayAddEditModal_PathwayName
          }}<df-label-marker></df-label-marker>
        </label>
        <p class="form-label-description" id="pathName-desc">
          {{ i18n.pathwayAddEditModal_TitleofPathway }}
          <span class="a11y-hide-text">{{
            i18n.pathwayAddEditModal_PathwayNameEg
          }}</span>
        </p>
        <input
          #pathNameRef
          id="pathName"
          name="title"
          type="text"
          [(ngModel)]="title"
          placeholder="{{ i18n.pathwayAddEditModal_PathwayNameEg }}"
          aria-labelledby="pathName-label"
          aria-describedby="pathName-desc"
          (change)="validateTitle()"
          maxlength="256"
          data-dgat="pathwayAddEditForm-835"
          autocomplete="off"
        />
        <dgx-validate-field [message]="titleMessage" class="guts-m-v-1">
        </dgx-validate-field>
      </div>
      <div class="guts-p-v-1">
        <label for="pathDescription">{{
          i18n.pathwayAddEditModal_Description
        }}</label>
        <p class="form-label-description" id="pathDescription-desc">
          {{ i18n.pathwayAddEditModal_DescriptionDetail }}
        </p>
        <textarea
          #descriptionTextArea="ngModel"
          id="pathDescription"
          name="description"
          [(ngModel)]="description"
          (change)="validateDescription()"
          aria-describedby="pathDescription-desc"
          maxlength="2001"
          data-dgat="pathwayAddEditForm-6d8"
        ></textarea>
        <dgx-validate-field [message]="descriptionMessage" class="guts-m-v-1">
        </dgx-validate-field>
      </div>
      <div class="guts-m-b-1" *ngIf="showPathwayOwnership">
        <label
          for="primaryContact"
          class="guts-m-b-half primary-contact-label form-label"
          >{{ i18n.pathwayAddEditModal_PathwayPrimaryContact
          }}<df-label-marker></df-label-marker>
        </label>
        <dgx-user-search
          labelKey="primaryContact"
          [recipients]="!!primaryContact ? [primaryContact] : []"
          [search]="loadGroupsUsersRecommendees"
          [ariaLabel]="i18n.pathwayAddEditModal_PathwayPrimaryContact"
          [allowRemoval]="true"
          (removeRecipient)="onRemovePrimaryContact($event)"
          (selectRecipient)="onAddPrimaryContact($event)"
          [placeholder]="i18n.pathwayAddEditModal_SearchPeopleGroupsPlaceHolder"
          class="block"
          data-dgat="pathwayAddEditForm-primaryContact-231"
        >
        </dgx-user-search>
        <dgx-validate-field
          *ngIf="showPrimaryContactErrors"
          [message]="i18n.pathwayAddEditModal_PrimaryContactRequired"
        ></dgx-validate-field>
      </div>
      <div *ngIf="userPermissions.canUploadPathwayImage && !isLoading">
        <div class="guts-m-v-1">
          <dgx-upload-section
            [imageUrl]="displayImage"
            [uploadAdapter]="uploadAdapter"
            [isImageUrlFallbackImage]="pathwaySummary.isImageUrlFallbackImage"
            (imageParsedEvent)="onImageParsed($event)"
            (uploadSuccessEvent)="onImageUploadSuccess($event)"
            (deleteEvent)="onDeleteImage()"
            [useCropper]="true"
            [aspectRatio]="16 / 9"
          >
          </dgx-upload-section>
        </div>
      </div>
      <div
        *ngIf="userPermissions.canUploadPathwayImage && !!imageUrl"
        class="guts-m-b-1"
        [@dfCollapseExpand]
      >
        <div class="l_flex m-l_flex">
          <div class="guts-p-r-half">
            <input
              type="checkbox"
              name="headerImageDisabled"
              id="headerImageDisabled"
              [(ngModel)]="headerImageDisabled"
              data-dgat="pathwayAddEditForm-a87"
            />
          </div>
          <div class="l_flex-grow">
            <label
              for="headerImageDisabled"
              aria-describedby="displayHeaderDescription"
              >{{ i18n.pathwayAddEditModal_DoNotDisplayHeaderImage }}</label
            >
            <p id="displayHeaderDescription" class="par par--small par--light">
              {{ i18n.pathwayAddEditModal_DoNotDisplayHeaderImageNote }}
            </p>
          </div>
        </div>
      </div>
      <div class="guts-m-b-1">
        <div class="l_flex m-l_flex">
          <div class="guts-p-r-half">
            <input
              type="checkbox"
              name="durationDisplayDisabled"
              id="durationDisplayDisabled"
              [(ngModel)]="durationDisplayDisabled"
              data-dgat="pathwayAddEditForm-5ff"
            />
          </div>
          <div class="l_flex-grow">
            <label
              for="durationDisplayDisabled"
              aria-describedby="displayDurationDescription"
              >{{ i18n.pathwayAddEditModal_DoNotDisplayDuration }}</label
            >
            <p
              id="durationDisplayDescription"
              class="par par--small par--light"
            >
              {{ i18n.pathwayAddEditModal_DoNotDisplayDurationNote }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="parsedMetadata && showPathwayBadgeTrigger">
        <div class="guts-m-b-1">
          <input
            type="checkbox"
            name="credlyBadgeEligibility"
            id="credlyBadgeEligibility"
            class="guts-m-r-half"
            [(ngModel)]="parsedMetadata.CredlyBadgeEligible"
            (change)="credlyBadgeEligibleToggled()"
            data-dgat="pathway-add-edit-form-modal-cf5"
          />
          <label for="credlyBadgeEligibility">{{
            i18n.pathwayAddEditModal_BadgeEligibilityLabel
          }}</label>
        </div>
        <div
          class="guts-m-b-1 l_flexbar"
          *ngIf="parsedMetadata.CredlyBadgeEligible"
        >
          <input
            type="text"
            name="externalBadgeId"
            id="externalBadgeId"
            class="guts-m-l-2"
            [(ngModel)]="parsedMetadata.ExternalBadgeId"
            attr.aria-label="{{ i18n.pathwayAddEditModal_BadgeUuid }}"
            placeholder="{{ i18n.pathwayAddEditModal_BadgeUuid }}"
            data-dgat="pathway-add-edit-form-modal-b41"
          />
        </div>
      </div>
      <div *ngIf="showEndorsePathway" class="guts-m-b-1">
        <div class="l_flex l_flex-middle">
          <div class="l_flex-grow guts-m-r-1">
            <label
              class="block font-semibold guts-m-b-quart"
              [textContent]="i18n.pathwayAddEditModal_EndorsedByOrg"
              for="IsEndorsedCheckbox"
            ></label>
            <span
              class="par par--small par--light"
              [textContent]="i18n.pathwayAddEditModal_EndorsedContentByOrgNote"
            ></span>
          </div>

          <dgx-toggle-switch
            [isChecked]="isEndorsed"
            (toggled)="handleToggle($event)"
            id="IsEndorsedCheckbox"
            data-dgat="pathwayAddEditForm-cbf"
          ></dgx-toggle-switch>
        </div>
      </div>
      <div class="guts-m-b-1" *ngIf="showPathwayOwnership">
        <dgx-visibility
          [groups]="groups"
          [options]="visibilityOptions"
          [selected]="selectedVisibility"
          labelText="orgInternalContentVisibilitySelector_Title"
          (onSelection)="handleVisibilitySelection($event)"
          (onGroupSelection)="handleGroupSelection($event)"
          (onGroupRemove)="handleGroupRemove($event)"
        ></dgx-visibility>
        <dgx-validate-field
          *ngIf="showErrors"
          [message]="i18n.PathwayPrivacyModal_SelectAGroupError"
        ></dgx-validate-field>
      </div>
      <div class="guts-m-b-1 item">
        <dgx-tags-edit
          [tags]="tags"
          (tagsChange)="handleTagsChange($event)"
          [required]="false"
          [suggestSkills]="isSkillRegistryEnabled"
        ></dgx-tags-edit>
      </div>
      <div class="guts-m-b-1" *ngIf="!isConsumerUser">
        <label class="guts-m-b-half font-strong ib">{{
          i18n.pathwayAddEditModal_Authors
        }}</label>

        <dgx-user-search
          [recipients]="authors"
          [preventUserRemovalKey]="preventAuthorRemovalKey"
          [search]="loadIncludingPrivateUsers"
          [ariaLabel]="i18n.Core_Collaborator"
          (removeRecipient)="onRemoveCollaborator($event)"
          (selectRecipient)="onAddCollaborator($event)"
          class="block guts-m-b-1"
        >
        </dgx-user-search>

        <div
          class="l_flex m-l_flex guts-m-b-1"
          *ngIf="userPermissions.canUpdateShareAuthorPermission"
        >
          <div class="guts-p-r-half">
            <input
              type="checkbox"
              id="shareAuthorPermission"
              name="shareAuthorPermission"
              [(ngModel)]="shareAuthorPermission"
              data-dgat="pathwayAddEditForm-268"
            />
          </div>
          <div class="l_flex-grow">
            <label for="shareAuthorPermission">{{
              i18n.pathwayAddEditModal_VisibleToOrg
            }}</label>
            <p
              id="durationDisplayDescription"
              class="par par--small par--light"
            >
              {{ i18n.pathwayAddEditModal_VisibleToOrgWarning }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>
