import { ColorService } from '@app/shared/services/color.service';
// TODO: This file will be shared with the bookmarklet/extensions
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export const enum TextSizeWCAG {
  Large = 'Large',
  Small = 'Small',
}

@Injectable({
  providedIn: 'root',
})
export class A11yService {
  private announceTimeout: Subscription;

  constructor(
    private translate: TranslateService,
    private colorService: ColorService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Announce and the derivatives are used to programmatically trigger a screenreader
   * to read text to the user.  We should prefer using native supported options where
   * possible (e.g. aria-live regions or ensuring proper roles and types being set)
   * but in some cases, particularly to work around limitations of a 3rd party library,
   * we are using this service to announce text.
   *
   * It's recommended to use announcePolite or announceAssertive as a shorthand instead
   * of passing in the type.
   *
   * @param announcement
   * @param type
   */
  public announce(announcement: string, type: 'Polite' | 'Assertive') {
    const a11yLiveRegionEl = document.querySelector(`#AriaLive${type}`);
    a11yLiveRegionEl?.removeAttribute('aria-hidden');
    if (this.announceTimeout) {
      this.announceTimeout.unsubscribe();
    }
    const announceEl = document.createElement('span');
    announceEl.classList.add('js-announce-text');
    announceEl.classList.add('a11y-hide-text');
    announceEl.textContent = announcement;
    a11yLiveRegionEl.appendChild(announceEl);

    this.announceTimeout = timer(1000).subscribe(() => {
      a11yLiveRegionEl.innerHTML = '';
      a11yLiveRegionEl.setAttribute('aria-hidden', 'true');
    });
  }

  /**
   * Announces text to screen readers.
   * The screen reader will speak changes whenever the user is idle
   *
   * Use this for non-urgent messages, which should be most use cases.
   *
   * @param announcement text to be read
   */
  public announcePolite(announcement: string): void {
    this.announce(announcement, 'Polite');
  }

  /**
   * Announces text to screen readers
   * The screen reader will interupt the user to speak changes
   *
   * Should only be used for error messages that require immediate attention.
   *
   * @param announcement text to be read
   */
  public announceAssertive(announcement: string): void {
    this.announce(announcement, 'Assertive');
  }

  /**
   * Announces number of options to screen readers
   */
  public announceOptionCount(count: number) {
    this.announcePolite(
      this.translate.instant('Core_OptionsAvailableFormat', { count })
    );
  }

  /**
   * Announces number of search results to screen readers
   */
  public announceResultsCount(count: number) {
    this.announcePolite(
      this.translate.instant('Core_ResultsFoundFormat', { count })
    );
  }

  /**
   * Gives keyboard focus to the next valid element that is part of the tab
   * ring within a given container
   * @param el DOM element container for searching within
   * @param includeFocusable (optional) include items that are not tabbable but are focusable (tabindex = -1)
   */
  public focusNextTabbable(el: HTMLElement, includeFocusable = false) {
    const tabbableEl = this.getNextTabbable(el, includeFocusable);
    tabbableEl?.focus();
  }

  /**
   * Gives keyboard focus to the next valid element that can receive focus
   * within a given container
   * @param el DOM element container for searching within
   */
  public focusNextFocusable(el: HTMLElement) {
    return this.focusNextTabbable(el, true);
  }

  /**
   * Returns the next valid element that is part of the tab ring
   * within a given container
   * @param el DOM element container for searching within
   * @param includeFocusable (optional) include items that are not tabbable but are focusable (tabindex = -1)
   */
  public getNextTabbable(
    container: HTMLElement,
    includeFocusable = false
  ): HTMLElement {
    const innards = container.getElementsByTagName('*');
    for (let i = 0; i < innards.length; i++) {
      const child: any = innards.item(i);
      if (includeFocusable) {
        if (this.isFocusable(child)) {
          return child;
        }
      } else {
        if (this.isTabbable(child)) {
          return child;
        }
      }
    }
  }

  /**
   * Returns the next valid element that can receive focus within a given container
   * @param el DOM element container for searching within
   */
  public getNextFocusable(el: HTMLElement) {
    return this.getNextTabbable(el, true);
  }

  /**
   * Checks if the element has tabindex set or is otherwise a focusable element.
   * TODO: Refactor isTabbable and isFocusable so there is less
   * duplication around some checks like tabindex.
   *
   * @param element
   */
  public isTabbable(element: HTMLElement) {
    // extends isFocusable to check tabindex also on focusable types (e.g. button, input, etc.)
    // and that the tabindex value is set to a valid number
    // check to see if element can receive focus by keyboard only
    // don't use Number, or an unset tabindex will become 0 which is a valid tabindex
    const tabIndex = parseInt(element.getAttribute('tabindex'));
    const isTabIndexNaN = tabIndex === null || isNaN(tabIndex);
    const hasValidTabIndex = !isTabIndexNaN && tabIndex >= 0;
    const isFocusable = this.isFocusable(element);
    return hasValidTabIndex && isFocusable;
  }

  /**
   * Checks if an element has a tabindex set, is a typically focusable type,
   * and that it's not disabled.
   *
   * @param element
   */
  public isFocusable(element: HTMLElement) {
    // check to see if element can receive focus by keyboard or programatically
    const nodeName = element.nodeName.toLowerCase();
    // don't use Number, or an unset tabindex will become 0 which is a valid tabindex
    const tabIndex = parseInt(element.getAttribute('tabindex'));
    const isTabIndexNaN = tabIndex === null || isNaN(tabIndex);
    const isFocusableType = /^(input|select|textarea|button|object)$/.test(
      nodeName
    );
    const isDisabled = element.hasAttribute('disabled');
    if (isFocusableType) {
      return !isDisabled;
    } else if (nodeName === 'a') {
      return element.hasAttribute('href') || !isTabIndexNaN;
    } else {
      return !isTabIndexNaN;
    }
  }

  public meetsContrastRequirements(
    hex1: string,
    hex2: string,
    textSize: TextSizeWCAG
  ) {
    const contrastRatio = this.colorService.contrastRatio(hex1, hex2);

    return textSize === TextSizeWCAG.Large
      ? contrastRatio < 1 / 3
      : contrastRatio < 1 / 4.5;
  }
  public addMouseFocus(): void {
    this.document.querySelector('body').classList.add('mouse-focus');
  }

  public removeMouseFocus(): void {
    this.document.querySelector('body').classList.remove('mouse-focus');
  }
}
