import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { CareerPathNamingService } from '@app/shared/services/career-path-naming.service';
import { ModalService } from '@app/shared/services/modal.service';
import { WindowToken } from '@app/shared/window.token';

import { TranslateService } from '@ngx-translate/core';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { AuthService } from '@app/shared/services/auth.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { ResourceCardMenuService } from './resource-card-menu.service';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { LiveEventsService } from '../live-events/live-events.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { PathwayEnrollmentService } from '@app/pathways/services/pathway-enrollment.service';
import {
  Expanded,
  LearningResourceCardService,
} from './learning-resource-card.service';

import { merge, throwError, BehaviorSubject, Subject } from 'rxjs';
import { FollowTargetModal } from '@app/target/components/modals/follow-target-modal/follow-target-modal.component';
import { PathwayDetailsModel } from '@app/pathways/pathway-api.model';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { EndorseContentService } from '../services/endorse-content.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { Target } from '@app/target/target-api.model';
import { MetaData } from '@app/inputs/models/learning-resource.view-model';
import { LDFlagsService } from '@dg/shared-services';

@Component({
  selector: 'dgx-learning-resource-card',
  templateUrl: './learning-resource-card.component.html',
  styleUrls: ['./learning-resource-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearningResourceCardComponent
  implements OnInit, AfterViewChecked, OnChanges, OnDestroy
{
  @Output()
  public inputSelected = new EventEmitter<LearningResourceViewModel>();
  @Input() public resource: LearningResourceViewModel;
  @Input() public isNewInput: boolean;
  @Input() public isLoading: boolean;
  @Input() public shouldTrackItemClicked = true;
  @Input() public cardStyle: 'box' | 'stack' = 'box';
  @Input() public searchTerm: string;
  @Input() public menuConfig: MenuViewModel[];
  @Input() public hideCompleteButton = false;
  @Input() public recommendationInfo?: RecommendationInfo;
  @Input() public hideIsViewed?: boolean = false;
  @Input() public disableProviderLink: boolean = false;

  // For custom Pathway Steps
  // Avoids deep watching this.resource for updates
  @Input() public durationDisplay: string;
  @Input() public durationHours: number;
  @Input() public durationMinutes: number;
  @Input() public displayTitle: string;
  @Input() public displaySummary: string;
  @Input() public displayImageUrl: string;

  @ViewChild('title') public title: ElementRef<HTMLElement>;
  @ViewChild('summary') public summary: ElementRef<HTMLElement>;

  public isCompletionInFlight = false;
  public masteryPoints: number;
  public newlyCompleted = false;
  public hidePlanImage: boolean;
  public showMoreDetails: boolean;

  public i18n = this.translate.instant([
    'A11y_LinkOpensNewWindow',
    'A11y_ExpandForTitle',
    'A11y_ExpandForSummary',
    'Core_YesSure',
    'Core_Follow',
    'Core_Following',
    'dgPathwayTile_UnfollowConfirm',
    'dgPathwayTile_WithdrawExplanation',
    'dgTargetTile_UnfollowConfirm',
    'dgTargetTile_UnfollowExplanation',
    'dgInputStatistics_Tags',
    'TargetsSvc_TypeJobRole',
    'Core_ViewDetails',
    'Core_Viewed',
    'Core_EndorsedTooltip',
    'Core_New',
  ]);
  public url = '';
  public isImageBroken: boolean = false;

  public showEllipsesButton$: BehaviorSubject<{
    title: boolean;
    summary: boolean;
  }> = new BehaviorSubject<{ title: boolean; summary: boolean }>(undefined);
  public expanded$: BehaviorSubject<{ title: boolean; summary: boolean }> =
    new BehaviorSubject<{ title: boolean; summary: boolean }>(undefined);
  public showPathwayNotificationOwnership: boolean;
  public displayNewPathwayInputNotification: boolean;

  private authUser: AuthUser;
  private destroy$ = new Subject();

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private careerPathNamingService: CareerPathNamingService,
    private detailsModalService: DetailsModalService,
    private liveEventsService: LiveEventsService,
    private modalService: ModalService,
    private orgEndorsedService: OrgEndorsedService,
    private pathwayEnrollmentService: PathwayEnrollmentService,
    private searchTracker: SearchTrackerService,
    private sharedTargetService: SharedTargetService,
    private resourceCardMenuService: ResourceCardMenuService,
    private translate: TranslateService,
    private trackerService: TrackerService,
    private focusStackService: FocusStackService,
    private endorseContentService: EndorseContentService,
    private learningResourceCardService: LearningResourceCardService,
    private ldFlagsService: LDFlagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.learningResourceCardService.expanded$.subscribe(
      ({ id, expandedSections }) => {
        this.expanded$.next({
          title:
            id === this.resource?.id
              ? expandedSections.includes('title')
              : false,
          summary:
            id === this.resource?.id
              ? expandedSections.includes('summary')
              : false,
        });
      }
    );
    this.showPathwayNotificationOwnership =
      this.ldFlagsService.pathwayNotificationOwnership;
  }

  public get titleExpanded(): Expanded {
    return Expanded.title;
  }

  public get summaryExpanded(): Expanded {
    return Expanded.summary;
  }

  public get shouldOpenContentInModal(): boolean {
    return (
      this.resource.canPlayVideo ||
      this.resource.resourceType === 'Post' ||
      this.resource.resourceType === 'Task' ||
      !!this.resource.liveSessions
    );
  }

  // Open content in new window with exceptions
  public get shouldOpenNewWindow(): boolean {
    return !(
      this.resource.isPathway ||
      this.resource.isTarget ||
      this.shouldOpenContentInModal
    );
  }

  public get isCompleted() {
    return (
      this.resource.completionInfo?.isCompleted || this.isCompletionInFlight
    );
  }

  public get isEnrolled() {
    return this.resource.isEnrolled;
  }

  public get isFollowing() {
    return this.resource.isFollowing;
  }

  public get isPathwayStep() {
    return !!this.resource.pathwayStepDetails;
  }

  public get shouldShowImage(): boolean {
    if (this.hidePlanImage || this.isImageBroken) {
      return false;
    }

    return true;
  }

  public get showViewedTag() {
    return (
      !this.hideIsViewed &&
      this.isPathwayStep &&
      !this.resource.completionInfo.isCompleted &&
      this.resource.isViewed
    );
  }

  public get endorsedImageUrl() {
    return this.resource.isEndorsed
      ? this.orgEndorsedService.getEndorsedSrc(this.resource.organizationId)
      : undefined;
  }

  public ngOnInit(): void {
    // This should only display in view mode on pathways
    this.displayNewPathwayInputNotification =
      this.isPathwayStep &&
      this.isNewInput &&
      this.showPathwayNotificationOwnership;
    this.authUser = this.authService.authUser;
    merge(
      this.resourceCardMenuService.onUpdate$,
      this.endorseContentService.onUpdate$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((resourceUpdated: LearningResourceViewModel) => {
        if (
          resourceUpdated.resourceId === this.resource.resourceId &&
          resourceUpdated.resourceType === this.resource.resourceType
        )
          this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngAfterViewChecked(): void {
    this.showEllipsesButton$.next({
      title:
        this.title?.nativeElement.clientHeight <
        this.title?.nativeElement.scrollHeight,
      summary:
        this.summary?.nativeElement.clientHeight <
        this.summary?.nativeElement.scrollHeight,
    });
    this.cdr.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Set data once resource has been passed by parent
    if (changes.resource && changes.resource.currentValue) {
      this.url = this.resource.externalUrl || this.resource.url;
      if (this.resource.isLive) {
        // we know a session is live, but now we need to figure out which one and grab its URL
        const liveSession = this.resource.liveSessions.filter((session) => {
          return session.isLive === true;
        });
        this.url = liveSession[0].locationUrl;
      }

      if (this.resource.isTarget) {
        this.updateTargeti18nStringValues();

        const settings = this.authService.authUser?.defaultOrgInfo?.settings;

        this.hidePlanImage = settings?.disablePlanImage;
      }

      // reconcile non-custom Pathway Step scenarios
      this.handlePathwaySteps();
      this.hideCompleteButton =
        !this.resource.isInput ||
        this.resource.isLive ||
        this.resource.isAcademy
          ? true
          : this.hideCompleteButton;

      this.showMoreDetails =
        this.resource.completionInfo?.externalCompletionOnly &&
        !this.hideCompleteButton;
    }

    // Check for changes in displayTitle, displaySummary and displayImageUrl for pathway authoring
    if (this.hasDisplayChanges(changes)) {
      this.handlePathwaySteps();
    }

    if (changes.durationDisplay) {
      const newMetaData = { ...this.resource.metaData } as MetaData;

      newMetaData.durationDisplay = changes.durationDisplay.currentValue;

      this.resource.metaData = { ...newMetaData };
    }

    if (changes.durationHours) {
      const newMetaData = { ...this.resource.metaData } as MetaData;

      newMetaData.durationHours = changes.durationHours.currentValue;

      this.resource.metaData = { ...newMetaData };
    }

    if (changes.durationMinutes) {
      const newMetaData = { ...this.resource.metaData } as MetaData;

      newMetaData.durationMinutes = changes.durationMinutes.currentValue;

      this.resource.metaData = { ...newMetaData };
    }
  }

  public expandCard(expandedSection: Expanded) {
    this.learningResourceCardService.updateExpanded(
      this.resource.id,
      expandedSection
    );
  }

  public getMenuConfig() {
    if (this.authUser && (!this.menuConfig || this.menuConfig.length === 0)) {
      this.menuConfig = this.resourceCardMenuService.getResourceMenu(
        this.resource,
        false,
        this.getLocationForReporting()
      );
      if (this.menuConfig) {
        const detailsMenuItem: MenuViewModel = {
          title: this.translate.instant('Core_MoreDetails'),
          isSeparated: true,
          preventRefocus: true,
          isHidden: () => this.resource.isTarget || this.resource.isPathway,
          defaultAction: (_, target) => {
            this.focusStackService.push(target?.nativeElement);
            this.openDetails();
          },
        };
        this.menuConfig.push(detailsMenuItem);
      }
    }
    return this.menuConfig;
  }

  public onLinkClick(e: Event): void {
    // Open modal for playable videos, Posts, and Tasks.
    if (this.shouldOpenContentInModal) {
      e.preventDefault();
      this.detailsModalService.openDetails(this.resource);
    }

    this.inputSelected.next(this.resource);

    // This is a shared component and will have clicks tracked directly
    // or if used on search see following comment:
    // This check is so search can track the item clicked with more search related properties.
    // Search tracks the event in the inputSelected output.
    if (this.shouldTrackItemClicked) {
      this.searchTracker.contentClicked(this.resource, {
        sessionCount: this.resource.sessionCount,
      });
    }
  }

  public onImageClick(e: MouseEvent): void {
    e.stopPropagation();
    this.title.nativeElement.click();
  }

  public onEnrollClick(_: Event) {
    this.resource.isEnrolled ? this.unenroll() : this.enroll();
  }

  public onFollowClick() {
    this.isFollowing ? this.unfollowTarget() : this.followTarget();
  }

  public openLiveSession() {
    // we know a session is live, but now we need to figure out which one
    const currentLiveSession = this.liveEventsService.getLiveSession(
      this.resource.liveSessions
    );
    // now open the URL in a new tab
    this.windowRef.open(
      currentLiveSession.locationUrl,
      '_blank',
      'noopener,noreferrer'
    );
    const trackingProperties = this.liveEventsService.getTrackingProperties(
      this.resource,
      currentLiveSession
    );
    this.trackerService.trackEventData({
      action: 'JoinSession',
      properties: trackingProperties,
    });
  }

  public onBrokenImage(isBroken: boolean) {
    this.isImageBroken = isBroken;
    this.cdr.detectChanges();
  }

  public openDetails() {
    this.detailsModalService
      .openDetails(this.resource)
      .subscribe(({ resource }) => {
        if (resource) {
          // update the content item from any changes that occurred in the more details modal
          this.resource = resource;
          this.cdr.detectChanges();
        }
      });
  }

  public addNewQueryParam(href: string, queryParam: string): string {
    return href.indexOf('?') > -1
      ? `${href}&${queryParam}`
      : `${href}?${queryParam}`;
  }

  private getLocationForReporting() {
    if (!this.resource?.pathwayStepDetails?.node) {
      return '';
    }

    const [section, lesson, step] = this.resource.pathwayStepDetails.node
      .split('/')
      .slice(1);
    return `In-pathway location: Section ${section} Lesson ${lesson} Step ${step}`;
  }

  private handlePathwaySteps() {
    this.resource.displayTitle = this.displayTitle ?? this.resource.title;
    this.resource.displaySummary =
      this.displaySummary ?? this.resource.plainTextSummary;
    this.resource.displayImageUrl =
      this.displayImageUrl ?? this.resource.imageUrl;
  }

  private updateTargeti18nStringValues() {
    this.i18n.dgTargetTile_UnfollowConfirm = this.translate.instant(
      'dgTargetTile_UnfollowConfirm',
      {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.resource.targetType
        ),
      }
    );
    this.i18n.dgTargetTile_UnfollowExplanation = this.translate.instant(
      'dgTargetTile_UnfollowExplanation',
      {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.resource.targetType
        ),
      }
    );

    if (this.authUser && this.authUser.hasCareerPathing) {
      const customNames = this.careerPathNamingService.careerPathNames;
      if (this.resource.targetIsDirectory) {
        this.resource.metaData.resourceLabel =
          customNames.customDirectoryName || this.resource.targetType;
      }
      if (this.resource.targetIsRole) {
        this.resource.metaData.resourceLabel =
          customNames.customPlanName || this.resource.targetType;
      }
    }
  }

  private enroll() {
    const pathway: PathwayDetailsModel = { ...this.resource } as any;
    pathway.id = this.resource.resourceId;
    this.resource.isEnrolled = true;
    this.pathwayEnrollmentService.enroll(pathway).subscribe({
      error: () => {
        this.resource.isEnrolled = false;
      },
    });
  }

  private unenroll() {
    const pathway: PathwayDetailsModel = { ...this.resource } as any;
    pathway.id = this.resource.resourceId;
    this.resource.isEnrolled = false;
    this.pathwayEnrollmentService
      .unenrollConfirmation(pathway)
      .pipe(
        catchError((e) => {
          this.resource.isEnrolled = true;
          return throwError(e);
        })
      )
      .subscribe();
  }

  private followTarget() {
    const inputs = {
      target: this.resource,
    };

    return this.modalService
      .show<boolean>(FollowTargetModal, { inputs })
      .subscribe((followPlan) => {
        if (followPlan) {
          this.resource.isFollowing = true;
          this.cdr.markForCheck();
        }
      });
  }

  private unfollowTarget() {
    const inputs = {
      bodyText: this.i18n.dgTargetTile_UnfollowExplanation,
      canCancel: true,
      headerText: this.i18n.dgTargetTile_UnfollowConfirm,
      submitButtonText: this.i18n.Core_YesSure,
    };
    this.modalService
      .show<void>(SimpleModalComponent, { inputs })
      .pipe(
        switchMap(() => {
          return this.sharedTargetService.unfollowTarget(
            this.resource as unknown as Target
          );
        })
      )
      .subscribe(() => {
        this.resource.isFollowing = false;
        this.cdr.markForCheck();
      });
  }

  // Checks for changes on display fields for pathway authoring allowing '' for displayImageUrl
  private hasDisplayChanges(changes: SimpleChanges) {
    return (
      (changes.displayTitle && changes.displayTitle.currentValue) ||
      (changes.displaySummary && changes.displaySummary.currentValue) ||
      (changes.displayImageUrl &&
        (changes.displayImageUrl.currentValue ||
          changes.displayImageUrl.currentValue === ''))
    );
  }
  // TODO: Add onviewed logic for Pathways
}
