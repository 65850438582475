import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthUser } from '@app/account/account-api.model';
import { MentorshipStatusEnum } from '@app/mentoring/mentoring.enums';
import { UserProfileSummary } from '@app/user/user-api.model';
import { DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseMenteeModalComponent,
  BaseMenteeModel,
  CustomMaxLengthValidator,
} from './../base-mentee-modal/base-mentee-modal.component';

export interface RejectMenteeModel extends BaseMenteeModel {
  description?: string;
}

@Component({
  selector: 'dgx-reject-mentee-modal',
  templateUrl: '../base-mentee-modal/base-mentee-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectMenteeModalComponent extends BaseMenteeModalComponent {
  // input
  @Input() public mentee: UserProfileSummary;
  @Input() public mentor: AuthUser;
  // local
  public i18n = this.translate.instant([
    'Opportunities_AcceptMenteeModal_AvailabilityLabel',
    'Opportunities_AcceptMenteeModal_AvailabilityDescription',
    'Opportunities_DeclineMenteeModal_Description',
    'Opportunities_DeclineMenteeModal_ExplanationLabel',
    'Opportunities_DeclineMenteeModal_ExplanationPlaceholder',
    'Opportunities_DeclineMenteeModal_SubmitButtonText',
  ]);
  public descriptionText =
    this.i18n.Opportunities_DeclineMenteeModal_Description;
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public headerText =
    this.i18n.Opportunities_DeclineMenteeModal_SubmitButtonText;
  public model: RejectMenteeModel = {
    availability: true,
    mentorshipStatusName: MentorshipStatusEnum.Declined,
    description: undefined,
  };
  public descMaxLength = 255;
  public options: FormlyFormOptions = {};
  public submitButtonText =
    this.i18n.Opportunities_DeclineMenteeModal_SubmitButtonText;

  constructor(
    activeModal: NgbActiveModal,
    cdr: ChangeDetectorRef,
    translate: TranslateService
  ) {
    super(activeModal, cdr, translate);
  }

  public ngOnInit(): void {
    // call the base mentee modal ngOnInit
    super.ngOnInit();
    // set the header text
    this.headerText = this.translate.instant(
      'Opportunities_DeclineMenteeModal_DeclineFormatted',
      { name: this.mentee.name }
    );
    // set the fields
    this.fields = [
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          dfLabel: 'Opportunities_DeclineMenteeModal_ExplanationLabel',
          // pad our max length a little to allow entry of more than the absolute limit,
          // making it easier for the user to delete down to the appropriate length.
          maxLength: this.descMaxLength + 500,
          placeholder:
            'Opportunities_DeclineMenteeModal_ExplanationPlaceholder',
          shouldAutofocus: true,
        },
        // TODO: Extract this into Fresco.
        parsers: [(value) => (value || '').trim()],
        validators: {
          validation: [
            (control) => CustomMaxLengthValidator(control, this.descMaxLength),
          ],
        },
        validation: {
          messages: {
            max: (_, field: FormlyFieldConfig) =>
              this.translate.instant(
                'OrgManage_Opportunities_Valid_MaxLength',
                {
                  number:
                    (field.formControl.value?.length || 0) - this.descMaxLength,
                }
              ),
          },
        },
      },
      // add our base field(s) in, at the end
      ...this.fields,
    ];
    // update view
    this.cdr.markForCheck();
  }
}
