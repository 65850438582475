<dgx-modal
  [bodyStyle]="{ 'max-height': '300px' }"
  [canCancel]="false"
  [canCloseHeader]="false"
  [item]="skills"
  [submitButtonText]="i18n.Core_Close"
  bodyClasses="guts-p-h-0 guts-p-t-0"
>
  <!-- Modal header -->
  <ng-container class="modal-header">
    {{ i18n.Opportunities_UserRateSkillsModal_Header }}
  </ng-container>
  <!-- Modal body -->
  <ng-container class="modal-body">
    <!-- Skills view -->
    <div class="guts-p-l-1 guts-p-r-1 user-rate-skills-modal__body">
      <dgx-skills-view
        [addTargetModalHeight]="300"
        [addRatingButtonText]="i18n.Opportunities_Skills_AddSkillRating"
        [isOwnSkills]="true"
        [skills]="skills"
        (ratingViewed)="onRatingViewed($event)"
        (updateSkills)="onUpdateSkills($event)"
      ></dgx-skills-view>
    </div>
  </ng-container>
</dgx-modal>
