import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Mentorship, MentorshipType } from '@app/mentoring/mentoring-api.model';
import { MentorshipTypeEnum } from '@app/mentoring/mentoring.enums';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-give-feedback-modal',
  templateUrl: './give-feedback-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiveFeedbackModalComponent implements OnInit {
  public i18n = this.translate.instant([
    'Core_Message',
    'Mentoring_GiveFeedback',
    'Mentoring_ShareFeedback',
    'Mentoring_PlaceholderShareMessage',
  ]);
  public fields: DfFormFieldConfig[] = [];
  public options: FormlyFormOptions = {};
  public form: FormGroup = new FormGroup({});
  public model = {
    feedbackMessage: '',
  };

  @Input() public mentorship: Mentorship;
  @Input() public mentorshipType: MentorshipType;
  constructor(
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private builder: DfFormFieldBuilder,
    private mentoringTrackingService: MentoringTrackingService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.fields = this.buildFormFields();
    this.cdr.markForCheck();
  }

  public onShare() {
    // Trim the values before submitting them.
    // Formly also has parsers, but they don't appear to work any better
    // than simply trimming once here.
    this.model.feedbackMessage.trim();

    this.mentoringTrackingService.feedbackShared(
      this.mentorship,
      this.mentorshipType
    );

    switch (this.mentorshipType) {
      case MentorshipTypeEnum.Mentee:
        this.mentorship.toMenteeFeedbackMessage = this.model.feedbackMessage;
        break;
      case MentorshipTypeEnum.Mentor:
        this.mentorship.toMentorFeedbackMessage = this.model.feedbackMessage;
        break;
    }

    this.activeModal.close({
      updatedMentorship: this.mentorship,
      message: this.model.feedbackMessage,
    });
  }

  private buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    return [
      this.builder
        .charCountedTextarea('feedbackMessage', 'Core_Message', 2000)
        .withPlaceholder(this.i18n.Mentoring_PlaceholderShareMessage)
        .asRequired()
        .validatedBy(isEmptyValidator)
        .updatedOn('change')
        .build(),
    ];
  }
}
