import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isKey, Key } from '@app/shared/key';
import { TranslateService } from '@ngx-translate/core';
import { MenuViewModel } from '../menu/menu.component';
import { PerPageOptions } from './pagination.model';

@Component({
  selector: 'dgx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  // Bindings
  @Input() public boundaryLinks = false;
  @Input() public className = '';
  @Input() public collectionSize: number;
  @Input() public directionLinks = true;
  @Input() public disabled = false;
  @Input() public ellipses = true;
  @Input() public maxSize = 0;
  @Input() public page = 1;
  @Input() public pageSize = 10;
  @Input() public perPageOptions: PerPageOptions[];
  @Input() public rotate = false;
  @Input() public showPerPage = false;
  @Output() public pageChange = new EventEmitter<number>();
  @Output() public perPageChange = new EventEmitter<number>();

  // Local
  public readonly i18n = this.translateService.instant([
    'Core_Show',
    'Pagination_OfYResultsFormat',
    'Pagination_OfYFormat',
  ]);
  public menuConfig: MenuViewModel[] = [];

  constructor(private translateService: TranslateService) {}

  // Angular
  public ngOnInit(): void {
    if (!this.perPageOptions?.length) {
      this.perPageOptions = [
        {
          value: 20,
          display: '20',
        },
        {
          value: 40,
          display: '40',
        },
        {
          value: 60,
          display: '60',
        },
        {
          value: 80,
          display: '80',
        },
        {
          value: 100,
          display: '100',
        },
      ];
    }
    this.formatStrings();
    if (this.showPerPage) {
      this.updatePerPageOptions();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Update strings which may have become out-of-date by latest
    if (changes.collectionSize && !changes.collectionSize.firstChange) {
      this.formatStrings();
      if (this.showPerPage) {
        this.updatePerPageOptions();
      }
    }
  }

  // Public
  public keydownHandler(event: KeyboardEvent, perPageValue): void {
    if (isKey(event, Key.Enter, Key.Space)) {
      // prevent spacebar from scrolling screen
      event.preventDefault();
      this.onPerPageChange(perPageValue);
    }
  }

  public onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  public onPerPageChange(perPage: number): void {
    this.pageSize = perPage;
    this.perPageChange.emit(perPage);
  }

  public updatePerPageOptions(): void {
    const opts = [];
    const shouldShowPerPageOptions =
      this.collectionSize > this.perPageOptions[0].value;
    this.showPerPage = shouldShowPerPageOptions;
    if (shouldShowPerPageOptions) {
      let viewAllSet = false;

      this.perPageOptions.forEach((opt) => {
        if (this.collectionSize > opt.value) {
          opts.push(opt);
        } else if (!viewAllSet) {
          // If there's an excess (not equal) and if the remaining
          // collection size is within the options we have, use the
          // very next option value as a "View All" option.
          opts.push({
            value: opt.value,
            display: this.translateService.instant('Core_ViewAll'),
          });

          viewAllSet = true;
        }
      });

      this.perPageOptions = opts;

      this.menuConfig = opts.map((option) => ({
        title: option.display,
        defaultAction: (_) => this.onPerPageChange(option.value),
        preventRefocus: true,
      }));
    }
  }

  // Private
  private formatStrings(): void {
    this.i18n.Pagination_OfYResultsFormat = this.translateService.instant(
      'Pagination_OfYResultsFormat',
      {
        totalCount: this.collectionSize,
      }
    );

    this.i18n.Pagination_OfYFormat = this.translateService.instant(
      'Pagination_OfYFormat',
      {
        totalCount: this.collectionSize,
      }
    );
  }
}
