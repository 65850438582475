<form
  [formGroup]="form"
  (ngSubmit)="onSubmit($event)"
  class="form-wrap"
  role="form"
>
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="submitButtonText"
    [useDefaultForm]="false"
    headerClasses="h3 l_flexbar"
    headerWrapperClasses="guts-p-h-2"
    bodyClasses="guts-m-h-1 guts-m-t-1"
  >
    <!-- Header -->
    <ng-container class="modal-header">
      <dgx-profile-pic
        [profile]="mentee"
        class="block"
        size="tiny"
      ></dgx-profile-pic>
      <div class="guts-m-l-3-quart">{{ headerText }}</div>
    </ng-container>

    <!-- Content -->
    <ng-container class="modal-body">
      <p
        id="mentoringdesc"
        class="par par--small par--light"
        dgxAutofocus
        tabindex="0"
      >
        {{ descriptionText }}
      </p>
      <df-form-root
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="fields"
        class="block guts-p-t-half"
      >
      </df-form-root>
    </ng-container>
  </dgx-modal>
</form>

<!-- custom formly select template -->
<ng-template #formlySelect let-formControl="formControl" let-params="params">
  <div class="guts-m-t-half">
    <dgx-select
      [ariaLabel]="params.label"
      [hasError]="showErrors(formControl)"
      [isRequired]="params.required"
      [options]="params.options"
      [placeholder]="params.placeholder"
      [selectedItem]="params.model"
      (selection)="params.onSelect(formControl, $event)"
      class="full-width"
      dgatInput="base-mentee-modal-ce5"
    >
    </dgx-select>
  </div>
</ng-template>
