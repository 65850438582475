<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  headerWrapperClasses="guts-p-h-2 guts-p-v-1-half"
  (dismiss)="closeModal(true)"
  bodyClasses="guts-p-full-2"
>
  <!-- Header -->
  <ng-container class="modal-header">
    {{ modalHeader }}
  </ng-container>

  <!-- Content -->
  <ng-container class="modal-body">
    <ng-container *ngIf="!isLoading; else showSkeleton">
      <div
        *ngFor="let rating of ratingSections; last as last"
        [ngClass]="{ 'guts-m-b-1': !last }"
        data-dgat="review-mentorship-modal-027"
      >
        <div
          class="tile guts-p-h-2 guts-p-t-2"
          [ngClass]="{ 'tag-tile--horizontal__new': !rating.actionCompleted }"
        >
          <div class="l_flex m-l_flex">
            <div
              class="mentorship__icon guts-m-r-1"
              [ngClass]="{
                'mentorship__icon--selected': rating.actionCompleted
              }"
            >
              <df-icon
                [icon]="rating.icon"
                [ngClass]="
                  rating.actionCompleted ? 'color-white' : 'color-ebony'
                "
              ></df-icon>
            </div>

            <div class="l_flex-grow">
              <h4 class="par par--small font-semibold cursor-default">
                {{ rating.header }}
              </h4>
              <p
                class="
                  par par--small par--light
                  cursor-default
                  font-medium
                  guts-p-t-half guts-m-b-1
                "
              >
                {{ rating.subheader }}
              </p>

              <button
                *ngIf="!rating.actionCompleted"
                df-button
                dfButtonType="clear"
                (click)="rating.defaultAction(rating)"
                class="
                  mentorship__actionButton
                  semi-bold
                  guts-p-full-half guts-m-b-1
                  color-blue
                "
                data-dgat="review-mentorship-modal-531"
              >
                <div
                  *ngIf="rating.actionIsLoading"
                  class="mentorship__btn-spinner-wrapper"
                >
                  <df-spinner spinnerContext="button"></df-spinner>
                </div>
                <df-icon
                  *ngIf="!rating.actionIsLoading"
                  icon="plus-circle"
                  class="guts-p-r-half color-blue"
                ></df-icon>
                {{ rating.actionButtonText }}
              </button>
            </div>
          </div>
          <div *ngIf="rating.actionCompleted" class="guts-p-t-1 guts-p-b-2">
            <df-local-notification
              icon="checkmark-circle"
              [type]="NotificationType.success"
              [text]="rating.actionCompletedText"
              size="small"
            ></df-local-notification>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Footer -->
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      (click)="closeModal()"
      data-dgat="review-mentorship-modal-f23"
    >
      {{ submitButtonText }}
    </button>
  </ng-container>
</dgx-modal>

<ng-template #showSkeleton>
  <dgx-skeleton type="ratingButton"></dgx-skeleton>
  <dgx-skeleton type="ratingButton"></dgx-skeleton>
  <dgx-skeleton type="ratingButton"></dgx-skeleton>
  <dgx-skeleton type="ratingButton"></dgx-skeleton>
</ng-template>
