import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { VideoPathwaysPlansInputFacade } from '../inputs/video/services/pathways-and-plans/video-pathways-plans.facade';
import { ArticlePathwaysPlansInputFacade } from '../inputs/article/services/pathways-and-plans/article-pathways-plans.facade';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';

/**
 * If the user submits and there are invalid fields, move the focus to the first invalid field
 */
export const focusOnFirstInvalidField = (
  formControlObjectEntries: [string, AbstractControl][],
  durationFormEntry?: [string, AbstractControl]
): void => {
  const firstInvalidFieldName: string = formControlObjectEntries?.reduce(
    (acc, [key, value]) => {
      if (value.status === 'INVALID') {
        acc.push(key);
      }
      return acc;
    },
    []
  )[0];

  // Some fields get special treatment for focus because the inputs are deeply nested with different ids than the form field key
  let inputIdForFocus = firstInvalidFieldName;
  if (firstInvalidFieldName === 'owner') {
    inputIdForFocus = 'user-search';
  } else if (firstInvalidFieldName === 'advancedSettings') {
    inputIdForFocus = 'group-search-input';
  } else if (firstInvalidFieldName === 'durationForm') {
    if (
      (durationFormEntry[1] as FormGroup)?.controls.durationHours.status ===
      'INVALID'
    ) {
      inputIdForFocus = 'durationHours';
    } else {
      inputIdForFocus = 'durationMinutes';
    }
  } else if (firstInvalidFieldName === 'skills') {
    inputIdForFocus = 'tag-search-input';
  }
  document.getElementById(inputIdForFocus)?.focus();
};

export const onChangeOfAddToCatalogValue = async (
  isAddingToCatalog: boolean,
  planPathForm: FormGroup,
  facade: VideoPathwaysPlansInputFacade | ArticlePathwaysPlansInputFacade,
  defaultOrgId?: number
) => {
  const contentOwnerControl = planPathForm.get('owner');
  if (isAddingToCatalog) {
    contentOwnerControl.setValidators([Validators.required]);

    await facade.fetchUrlDuplicates(facade.snapshot.entryUrl, defaultOrgId);
  } else {
    contentOwnerControl.clearValidators();
  }

  contentOwnerControl.updateValueAndValidity();
};

/**
 * When editing a content item from a pathway or plan,
 * hide the url field IF the content is from the catalog AND is hosted content
 * @param isContentFromCatalog
 * @param isEditing
 * @param hostedContentDetails
 * @returns boolean
 */
export const pathPlanHideUrlField = (
  isContentFromCatalog: boolean,
  isEditing: boolean,
  hostedContentDetails?: HostedContentMetadata
): boolean => isEditing && isContentFromCatalog && !!hostedContentDetails;
