<nav
  [(activeId)]="defaultActiveTabId"
  #nav="ngbNav"
  ngbNav
  class="nav-tabs tabnav wrapper"
  [attr.aria-label]="i18n.A11y_SecondaryPagesNavType"
  [ngClass]="wrapperClasses"
>
  <div
    *ngFor="let tab of tabList; let i = index"
    [ngbNavItem]="tab.nameId"
    attr.data-dgat="tab-navigation-routerless-5c8-{{ i }}"
    class="tabnav__item"
    routerLinkActive=""
    [ngClass]="{ is_active: tab.nameId === defaultActiveTabId }"
  >
    <a
      ngbNavLink
      attr.data-dgat="tab-navigation-routerless-716-{{ i }}"
      (click)="onTabSwitch($event, tab)"
      class="tabnav__link"
    >
      {{ tab.name }}
      <ng-container *ngIf="tab.headerTemplate">
        <ng-container [ngTemplateOutlet]="tab.headerTemplate"> </ng-container>
      </ng-container>
    </a>
    <ng-template ngbNavContent *ngIf="currentTemplate">
      <ng-container [ngTemplateOutlet]="currentTemplate"> </ng-container>
    </ng-template>
  </div>
</nav>
<div [ngbNavOutlet]="nav" class="guts-mt-half"></div>
