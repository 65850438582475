<ng-container *ngIf="!isShowQuiz; else assessments">
  <div
    class="input-detail-modal"
    [class.input-detail-modal--clickable]="isClickable"
    [class.guts-p-h-0]="noSidePadding"
    *ngIf="!isLoading; else skeleton"
  >
    <div [class.input-card]="!isDetailModal">
      <!-- Hero Image -->
      <div
        *ngIf=""
        [ngSwitch]="previewType"
        class="input-detail-modal__{{ previewType }}"
      >
        <ng-container *ngIf="!isPost">
          <dgx-image-layout
            *ngSwitchCase="'image'"
            [resourceId]="resource.resourceId"
            [resourceType]="resource.resourceType"
            format="details-modal"
            [imageSrc]="resource.displayImageUrl || resource.imageUrl"
            [videoUrl]="resource.videoInfo?.videoUrl"
            [endorsedSrc]="
              resource.isEndorsed && !showSearchLayoutRedesign
                ? endorsedSrc
                : null
            "
            [providerImage]="resource.providerSummary?.image"
            (click)="onImageClick($event)"
          ></dgx-image-layout>
        </ng-container>
        <dgx-video-player
          *ngSwitchCase="'video'"
          [resourceId]="resource.resourceId"
          [resourceUrl]="resource.videoInfo.videoUrl"
          [resourceTitle]="resource.title"
          [videoType]="resource.videoInfo.videoType"
        ></dgx-video-player>
      </div>

      <!-- Metadata -->
      <ng-container *ngIf="showSearchLayoutRedesign">
        <div
          class="l_flexbar guts-p-t-1-half guts-m-b-1-half"
          [ngClass]="{
            'guts-m-l-2': !isDetailModal
          }"
        >
          <img
            *ngIf="resource.isEndorsed"
            class="input-detail-modal__endorsed-icon guts-m-r-1 guts-m-b-half"
            [src]="endorsedSrc + '?brandlogo'"
            [alt]="i18n.Core_Endorsed"
            [ngbTooltip]="i18n.Core_EndorsedTooltip"
          />
          <div>
            <ng-container *ngTemplateOutlet="metadata"></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showSearchLayoutRedesign">
        <ng-container *ngTemplateOutlet="metadata"></ng-container>
      </ng-container>
      <div [class.guts-p-h-2]="!isDetailModal">
        <!-- Title -->
        <ng-container *ngTemplateOutlet="modalTitle"></ng-container>

        <ng-container *ngTemplateOutlet="detailsTemp"></ng-container>
      </div>
    </div>

    <button
      type="button"
      *ngIf="isCompleted && isShowQuizBtn"
      class="btn btn-sm btn-primary quiz-btn"
      (click)="openQuiz()"
    >
      <!-- <img src="/content/img/public/ai-quiz-btn.png" class="" alt="AI Quiz" /> -->
      {{ isQuizLoading ? 'Degreed Assistant is working...' : 'Complete Quiz' }}
    </button>
  </div>
</ng-container>

<ng-template #detailsTemp>
  <!-- Social Counts (deprecated) -->
  <ng-container *ngIf="!showSearchLayoutRedesign">
    <div class="guts-m-t-1-half">
      <ng-container *ngTemplateOutlet="socialcounts"></ng-container>
    </div>
  </ng-container>

  <!-- Summary -->
  <div class="guts-m-t-1-half">
    <ng-container *ngIf="showSearchLayoutRedesign">
      <ng-container>
        <div class="l_flex l_flex-row">
          <div class="l_flex-grow" [class.guts-p-r-1]="hasTags">
            <div class="guts-p-b-1">
              <ng-container *ngTemplateOutlet="summarytext"></ng-container>
            </div>
          </div>
          <div *ngIf="hasTags" class="input-detail-modal__skills guts-p-l-1">
            <p class="h4 guts-m-b-1">{{ i18n.Core_Skills }}</p>
            <div class="l_flex l_flex-column guts-p-b-half">
              <!-- User assigned skills -->
              <dgx-action-button
                *ngFor="let userTag of userTags"
                size="xs"
                design="square"
                class="guts-m-b-half"
                (click)="searchTag(userTag)"
                dgatInput="input-card-ad9"
              >
                {{ userTag }}
              </dgx-action-button>
              <!-- Org assigned skills -->
              <dgx-action-button
                *ngFor="let orgTag of uniqueOrgTags"
                size="xs"
                design="square"
                class="guts-m-b-half"
                [secondary]="true"
                (click)="searchTag(orgTag)"
                dgatInput="input-card-ad9"
              >
                {{ orgTag }}
              </dgx-action-button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!showSearchLayoutRedesign">
      <ng-container *ngTemplateOutlet="summarytext"></ng-container>
    </ng-container>
  </div>

  <!-- Live Sessions -->
  <div
    class="input-detail-modal__live-session"
    *ngIf="liveSessions?.length > 0"
  >
    <h4 class="h4">{{ sessionLabel }}</h4>
    <div
      *ngFor="let session of liveSessions"
      data-dgat="live-event-details-761"
      class="box guts-m-v-1-quart guts-p-v-1 guts-p-h-1-half"
    >
      <dgx-live-event-session
        [session]="session"
        (trackClick)="onLinkClick($event)"
      ></dgx-live-event-session>
    </div>
    <div *ngIf="canAddToQueue" class="guts-m-t-1-half">
      <label
        for="saveSessionForLater"
        class="input-detail-modal__session-checkbox"
      >
        <input
          class="input-detail-modal__session-checkbox"
          id="saveSessionForLater"
          [checked]="resource.isQueued"
          type="checkbox"
          name="saveSessionForLater"
          (click)="queue()"
          data-dgat="input-card-4ef"
        />
        <span class="par par--small guts-m-l-half"
          ><b>{{ saveSessionCheckboxLabel }}</b>
          <df-icon
            [ngbTooltip]="saveSessionCheckboxTooltip"
            placement="top"
            attr.aria-label="{{ saveSessionCheckboxTooltip }}"
            class="color-shuttle guts-p-h-quart"
            icon="info"
            size="small"
          ></df-icon
        ></span>
      </label>
    </div>
  </div>

  <!-- Social Counts -->
  <ng-container *ngIf="showSearchLayoutRedesign">
    <div class="input-detail-modal__social-counts">
      <ng-container
        *ngTemplateOutlet="socialcounts; context: { useBadgeLayout: true }"
      ></ng-container>
    </div>
  </ng-container>

  <!-- Footer -->
  <footer
    class="input-detail-modal__footer"
    [class.guts-m-t-1-half]="showSearchLayoutRedesign"
    [class.guts-m-t-2]="!showSearchLayoutRedesign"
  >
    <div
      *ngIf="!isPreview && !!authUser"
      class="l_flexbar guts-p-h-2 action-buttons"
      [class.guts-p-v-1-half]="showSearchLayoutRedesign"
      [class.guts-p-v-2]="!showSearchLayoutRedesign"
    >
      <!-- Primary Actions -->
      <div class="l_flex-grow l_flexbar">
        <ng-container *ngIf="showSearchLayoutRedesign">
          <ng-container *ngTemplateOutlet="primaryactions"></ng-container>
        </ng-container>
        <ng-container *ngIf="!showSearchLayoutRedesign">
          <ng-container *ngTemplateOutlet="deprecatedactions"></ng-container>
        </ng-container>
      </div>

      <!-- Secondary Actions-->
      <ng-container *ngIf="showSearchLayoutRedesign">
        <ng-container *ngTemplateOutlet="secondaryactions"></ng-container>
      </ng-container>

      <!-- Menu -->
      <ng-container *ngIf="!showSearchLayoutRedesign">
        <ng-container *ngTemplateOutlet="menu"></ng-container>
      </ng-container>
    </div>
  </footer>

  <!-- Comments -->
  <dgx-comment-thread
    *ngIf="showComments"
    class="input-detail-modal__comments"
    [resource]="resource"
    [isExpanded]="commentThreadExpanded"
    (commentAdded)="increaseCommentCount()"
    (commentDeleted)="decreaseCommentCount()"
  ></dgx-comment-thread>
</ng-template>

<ng-template #modalTitle>
  <!-- Title -->
  <div class="input-detail-modal__title-container">
    <a
      *ngIf="isClickable"
      #link
      [href]="url"
      [attr.aria-describedby]="'a11yNewWindowDescription'"
      [attr.target]="'_blank'"
      [attr.rel]="'noopener noreferrer'"
      class="clamp"
      [ngClass]="{
        'input-detail-modal__title-deprecated': !showSearchLayoutRedesign,
        'input-detail-modal__title input-detail-modal__title-clickable':
          showSearchLayoutRedesign
      }"
      [attr.title]="resource.title | decodeHtml"
      [attr.aria-label]="resource.title | decodeHtml"
      data-dgat="input-detail-modal-9e1"
      (click)="onContentClicked()"
      >{{ resource.title | decodeHtml }}
      <df-icon
        *ngIf="url && !showSearchLayoutRedesign"
        class="guts-m-l-half input-detail-modal__title-icon"
        size="small"
        icon="arrow-up-right-out"
      ></df-icon>
    </a>
    <span *ngIf="!isClickable" class="input-detail-modal__title clamp">
      {{ resource.title | decodeHtml }}
    </span>
  </div>
</ng-template>

<!-- Summary Template -->
<ng-template #summarytext>
  <div
    [ngClass]="{
      'input-detail-modal__description': !showSearchLayoutRedesign,
      'input-detail-modal__summary': showSearchLayoutRedesign
    }"
    class="markdown"
    [innerHTML]="summary"
  ></div>
</ng-template>

<!-- Metadata Template -->
<ng-template #metadata>
  <dgx-learning-resource-metadata
    *ngIf="!isPreview"
    [liveSessions]="resource.liveSessions"
    [isMoreDetailsView]="isDetailModal"
    [metaData]="resource.metaData"
    [resourceType]="resource.resourceType"
    [assignmentInfo]="resource.recommendationInfo"
    [assignmentCssClass]="'up-me badge-pill--compact'"
    [config]="{
      compact: !showSearchLayoutRedesign,
      percentComplete: resource.percentComplete,
      isAcademy: resource.isAcademy
    }"
    class="guts-m-b-half"
    [ngClass]="{
      'guts-p-h-2': !isDetailModal && !showSearchLayoutRedesign,
      'guts-p-t-1-half': !showSearchLayoutRedesign
    }"
  ></dgx-learning-resource-metadata>
</ng-template>

<!-- Social Counts Template -->
<ng-template let-useBadgeLayout="useBadgeLayout" #socialcounts>
  <dgx-social-counts
    *ngIf="showSocialCounts"
    [item]="socialCountsModel"
    [config]="{
      disableSocialClick: disableSocialClick,
      hideSkills: useBadgeLayout
    }"
    [useBadgeLayout]="useBadgeLayout"
    (toggleComments)="toggleCommentThread()"
  ></dgx-social-counts>
</ng-template>

<!-- Primary Actions Template-->
<ng-template #primaryactions>
  <!-- View Academy -->
  <dgx-view-academy-button
    *ngIf="resource.isAcademy"
    [url]="resource.url"
    size="xs"
    class="guts-m-r-half"
  ></dgx-view-academy-button>

  <!-- Completion -->
  <dgx-input-completion
    *ngIf="showCompletion"
    [class.guts-m-r-half]="!showMoreDetails"
    [isCompleted]="isCompleted"
    [recommendationInfo]="userRecHeaderItem"
    [input]="viewerInput || resource"
    size="xs"
  ></dgx-input-completion>

  <!-- View Details -->
  <dgx-action-button
    *ngIf="showMoreDetails"
    (click)="openInputPage()"
    [autoToggleState]="false"
    [class.guts-m-l-half]="!isCompleted"
    class="guts-m-r-half"
    data-dgprop-item-clicked="ViewDetails"
    dgatInput="input-detail-modal-1b9"
    size="xs"
  >
    {{ i18n.Core_ViewDetails }}
  </dgx-action-button>

  <!-- Open -->
  <dgx-action-button
    *ngIf="showOpenButton"
    (click)="goToUrl()"
    [autoToggleState]="false"
    class="guts-m-r-half"
    data-dgprop-item-clicked="Open"
    dgatInput="input-detail-modal-2c4"
    size="xs"
  >
    {{ i18n.Core_Open }}
  </dgx-action-button>
</ng-template>

<!-- Secondary Actions -->
<ng-template #secondaryactions>
  <!-- Like -->
  <dgx-action-button
    *ngIf="isCompleted"
    class="guts-m-r-half"
    dgatInput="input-card-988"
    (click)="toggleLike()"
    [isActive]="resource.requestingUserRating === 1"
    [tooltip]="i18n.Core_Like"
    [attr.aria-label]="i18n.Core_MoreOptions"
    [autoToggleState]="true"
    size="xs"
    icon="thumbs-up"
    design="square"
  ></dgx-action-button>

  <!-- Share -->
  <dgx-action-button
    *ngIf="showShare"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-505"
    (click)="recommend($event)"
    [tooltip]="i18n.Core_Recommend"
    [attr.aria-label]="i18n.Core_Recommend"
    [autoToggleState]="false"
    size="xs"
    icon="arrow-forward"
    design="square"
  ></dgx-action-button>

  <!-- Save -->
  <dgx-action-button
    *ngIf="canAddToQueue"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-7fc"
    (click)="queue()"
    [tooltip]="saveIconTooltip"
    [attr.aria-label]="saveIconTooltip"
    size="xs"
    icon="bookmark"
    design="square"
  ></dgx-action-button>

  <!-- Add Skills -->
  <dgx-action-button
    *ngIf="canAddSkills"
    class="guts-m-r-half"
    dgatInput="input-detail-modal-e97"
    (click)="tagResource($event)"
    [tooltip]="i18n.Core_AddTags"
    [attr.aria-label]="i18n.Core_AddTags"
    [autoToggleState]="false"
    size="xs"
    icon="tag"
    design="square"
  ></dgx-action-button>

  <!-- Add to... -->
  <dgx-menu
    *ngIf="showAddButton"
    placement="bottom-right"
    [autoCloseOnItemSelect]="true"
    [menuConfig]="addToMenuConfig"
    [placementAdjustLeftRem]="0.5"
    [placementAdjustTopRem]="-0.5"
    [autoCloseOnItemSelect]="true"
  >
    <dgx-action-button
      dgatInput="input-card-6f3"
      class="guts-m-r-half"
      icon="plus"
      design="square"
      size="xs"
      [isIconOnly]="true"
      [autoToggleState]="false"
      [tooltip]="this.i18n.Core_AddTo"
      [attr.aria-label]="this.i18n.Core_AddTo"
    ></dgx-action-button>
  </dgx-menu>

  <!-- Menu -->
  <ng-container *ngTemplateOutlet="menu"></ng-container>
</ng-template>

<!-- Actions (deprecated) Template -->
<ng-template #deprecatedactions>
  <!-- View Academy (deprecated) -->
  <dgx-view-academy-button
    *ngIf="resource.isAcademy"
    [url]="resource.url"
    size="xs"
    class="guts-m-r-half"
  ></dgx-view-academy-button>

  <!-- Completion (deprecated) -->
  <dgx-input-completion
    *ngIf="!resource.isAcademy"
    [class.guts-m-r-half]="!showMoreDetails"
    [isCompleted]="isCompleted"
    [recommendationInfo]="userRecHeaderItem"
    [input]="viewerInput || resource"
    size="xs"
  ></dgx-input-completion>

  <!-- View Details (deprecated) -->
  <dgx-action-button
    *ngIf="showMoreDetails"
    (click)="openInputPage()"
    [autoToggleState]="false"
    [class.guts-m-l-half]="!isCompleted"
    class="guts-m-r-half"
    data-dgprop-item-clicked="ViewDetails"
    dgatInput="input-detail-modal-1b9"
    size="xs"
  >
    {{ i18n.Core_ViewDetails }}
  </dgx-action-button>

  <!-- Rate (deprecated) -->
  <dgx-thumbs
    *ngIf="isCompleted"
    class="guts-m-r-half"
    (rate)="rate($event)"
    [currentRating]="resource.requestingUserRating"
    [inputTitle]="resource.title"
  ></dgx-thumbs>

  <!-- Share (deprecated) -->
  <dgx-action-button
    *ngIf="authUser?.canRecommendItems"
    class="guts-m-r-half"
    (click)="recommend($event)"
    [autoToggleState]="false"
    iconSize="medium"
    [isIconOnly]="true"
    [tooltip]="i18n.Core_Recommend"
    [attr.aria-label]="i18n.Core_Recommend"
    dgatInput="input-detail-modal-505"
    icon="arrow-forward"
    size="xs"
  ></dgx-action-button>

  <!-- Save (deprecated) -->
  <dgx-action-button
    *ngIf="canAddToQueue"
    class="guts-m-r-half"
    (click)="queue()"
    [isActive]="resource.isQueued"
    iconSize="medium"
    [isIconOnly]="true"
    [tooltip]="saveIconTooltip"
    [attr.aria-label]="saveIconTooltip"
    icon="bookmark"
    size="xs"
    dgatInput="input-detail-modal-7fc"
  ></dgx-action-button>

  <!-- Add Skills (deprecated) -->
  <dgx-action-button
    *ngIf="canAddSkills"
    (click)="tagResource($event)"
    [autoToggleState]="false"
    iconSize="medium"
    [isIconOnly]="true"
    [tooltip]="i18n.Core_AddTags"
    [attr.aria-label]="i18n.Core_AddTags"
    icon="tag"
    size="xs"
    dgatInput="input-detail-modal-e97"
  ></dgx-action-button>
</ng-template>

<!-- Menu Template -->
<ng-template #menu>
  <dgx-menu
    *ngIf="hasMenu"
    placement="bottom-right"
    [autoCloseOnItemSelect]="true"
    [menuConfig]="menuConfig"
    [placementAdjustLeftRem]="0.5"
    [placementAdjustTopRem]="-0.5"
    [autoCloseOnItemSelect]="true"
  >
    <dgx-action-button
      *ngIf="showSearchLayoutRedesign"
      dgatInput="input-detail-modal-c95"
      icon="dots"
      design="square"
      size="xs"
      [isIconOnly]="true"
      [autoToggleState]="false"
      [attr.aria-label]="i18n.Core_MoreOptions"
      [tooltip]="i18n.Core_MoreOptions"
      [attr.aria-label]="i18n.Core_MoreOptions"
    ></dgx-action-button>

    <dgx-action-button
      *ngIf="!showSearchLayoutRedesign"
      dgatInput="input-detail-modal-c95"
      class="guts-p-v-quart guts-p-h-half"
      icon="dots"
      design="square"
      size="xs"
      [isIconOnly]="true"
      [autoToggleState]="false"
      [attr.aria-label]="i18n.Core_MoreOptions"
      [tooltip]="i18n.Core_MoreOptions"
    ></dgx-action-button>
  </dgx-menu>
</ng-template>

<!-- Loading skeleton Template -->
<ng-template #skeleton>
  <div class="guts-p-full-2">
    <dgx-skeleton type="card" layout="random"></dgx-skeleton>
  </div>
</ng-template>

<ng-template #assessments>
  <div
    class="input-detail-modal"
    [ngClass]="{ 'guts-p-b-quart': selectedTab !== 'details' }"
  >
    <ng-container *ngTemplateOutlet="metadata"></ng-container>
    <ng-container *ngTemplateOutlet="modalTitle"></ng-container>

    <dgx-tab-navigation-routerless
      [tabList]="tabList"
      [defaultActiveTabId]="selectedTab"
      (updateSelectedTab)="switchTab($event)"
    ></dgx-tab-navigation-routerless>
  </div>
</ng-template>

<ng-template #quizSummary>
  <div class="guts-m-b-half quiz-body quiz-summary">
    <p class="font-medium par par--small guts-mb-half">
      Summary generated by Degreed Assistant
      <br />
      Here is a summary of the book "{{ resource.title }}":
    </p>
    <p class="font-medium par par--small" [innerHTML]="generativeSummary"></p>
  </div>
  <div class="input-detail-modal__footer">
    <button type="button" class="btn btn-sm btn-cancel" (click)="cancelQuiz()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="switchTab('quiz')">
      Start Quiz
    </button>
  </div>
</ng-template>

<ng-template #quiz>
  <div class="quiz-body">
    <div
      class="guts-m-b-1-half"
      *ngFor="let question of questions; index as index"
    >
      <p class="pills qs-pills font-semibold par guts-m-b-quart">
        {{ index + 1 }} of {{ questions.length }}
      </p>
      <p class="font-medium par guts-p-b-half">{{ question.label }}</p>
      <ul class="option-list">
        <li class="guts-m-b-half" *ngFor="let option of question.options">
          <label class="font-medium par par--small">
            <input
              name="{{ question.id }}"
              type="radio"
              [(ngModel)]="question.selectedAnswer"
              [value]="option.id"
            />
            {{ option.label }}
          </label>
        </li>
      </ul>
    </div>
  </div>
  <div class="input-detail-modal__footer">
    <button type="button" class="btn btn-sm btn-cancel" (click)="cancelQuiz()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="submitQuiz()">
      Submit Quiz
    </button>
  </div>
</ng-template>