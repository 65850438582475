<ng-container
  *ngTemplateOutlet="!isSearchResultCard ? actionButtons : searchResultButtons"
></ng-container>

<ng-template #actionButtons>
  <dgx-action-button
    icon="checkmark"
    [size]="size"
    (click)="onCompleteClick()"
    [isCompletionButton]="true"
    [isActive]="isCompleted"
    [isDisabled]="isCompletionInFlight"
    [isIconOnly]="iconOnly"
    [tooltip]="completionTooltip"
    [metaData]="input.metaData"
    [a11yContext]="input.title | decodeHtml"
    *ngIf="input.canComplete && !isRequiredContent"
    dgatInput="input-completion-button"
  >
    <!--Custom completion and points animated layers-->
    <div class="l_flexbar">
      <div
        [attr.aria-hidden]="!isCompleted"
        class="input-completion__slide input-completion__slide--down"
        [class.input-completion__slide--show]="isCompleted"
      >
        {{ completeButtonLabel }}
      </div>
      <div
        [attr.aria-hidden]="isCompleted"
        class="input-completion__slide input-completion__slide--up"
        [class.input-completion__slide--show]="!isCompleted"
      >
        {{ completeButtonLabel }}
      </div>
      <div
        *ngIf="isCompleted && !iconOnly"
        class="input-completion__points"
        [class.input-completion__points--show]="isNewlyCompleted"
      >
        +{{ masteryPoints | dgxPoints }} {{ i18n.Core_Pts }}
      </div>
    </div>
  </dgx-action-button>
  <dgx-action-button
    icon="arrow-up-right-out"
    [size]="size"
    (click)="viewExternalContent()"
    [autoToggleState]="false"
    *ngIf="(!input.canComplete && !isCompleted) || isRequiredContent"
    dgatInput="input-visit-button"
    >{{ i18n.Core_VisitProvider }}
  </dgx-action-button>
</ng-template>

<ng-template #searchResultButtons>
  <button
    *ngIf="input.canComplete && !isRequiredContent"
    df-button
    df-button-square
    [dfButtonType]="isCompleted ? 'primary' : 'passive'"
    [ngbTooltip]="completionTooltip"
    [disabled]="isCompletionInFlight"
    class="
      input-completion__search-result-button
      input-completion__search-result-button--newly-completed
    "
    (click)="onCompleteClick()"
    data-dgat="learning-resource-card-656"
  >
    <div [class.l_flexbar]="isNewlyCompleted">
      <df-icon
        icon="checkmark"
        size="small"
        [a11yText]="completeButtonLabel"
      ></df-icon>
      <div
        *ngIf="isCompleted && isNewlyCompleted"
        class="input-completion__points"
        [class.input-completion__points--show]="isNewlyCompleted"
      >
        +{{ masteryPoints | dgxPoints }} {{ i18n.Core_Pts }}
      </div>
    </div>
  </button>
</ng-template>
