import { Component, Input, OnInit } from '@angular/core';
import { InputSession } from '@app/inputs/inputs-api.model';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { TranslateService } from '@ngx-translate/core';
import { LiveEventsService } from '../live-events.service';

export interface MetadataConfig {
  compact: boolean;
}

/**
 * This component is similar to LearningResourceMetadataComponent but rather than add a bunch of complex *ngIf statements to that component, it made more sense to split the 2 and just keep metaData and liveSession separate.
 *
 * @param liveSessions - provided by server as part of the Input object for Events and Courses if any session (live event) info is available.
 * @param config? - extra helper properties, such as (eg. `compact` for tiles vs cards)
 */
@Component({
  selector: 'dgx-live-event-metadata',
  templateUrl: './live-event-metadata.component.html',
  styleUrls: [
    './live-event-metadata.component.scss',
    '../../learning-resource-metadata/learning-resource-metadata.component.scss',
  ],
})
export class LiveEventMetadataComponent implements OnInit {
  @Input() public liveSessions: InputSession[];
  @Input() public isRegistered: boolean;
  @Input() public config?: MetadataConfig;
  @Input() public assignmentInfo?: RecommendationInfo;
  @Input() public isVerified?: boolean;
  @Input() public isOptional?: boolean = false;

  public viewModel;
  public isMultiSession: boolean = false;
  public liveEvent: InputSession;
  public isExpired: boolean = false;
  public hasLiveSession: boolean = false;
  public location: string;
  public translatedInputType: string;

  constructor(
    private liveEventsService: LiveEventsService,
    private translate: TranslateService
  ) {}

  private get vm() {
    const isCompact = !!this.config?.compact;
    const showLiveBadge = !!this.hasLiveSession;
    const showAssignementBadge =
      !showLiveBadge &&
      !!this.assignmentInfo &&
      !this.assignmentInfo.alreadyCompleted;
    const showRegisteredBadge =
      !showLiveBadge &&
      !this.isExpired &&
      (!showAssignementBadge || !isCompact) &&
      !!this.isRegistered;
    const showInputType = !showLiveBadge && !!this.liveEvent.inputType;
    const showDate =
      !showLiveBadge &&
      (!this.isMultiSession || this.isExpired) &&
      !!this.liveEvent.startDateFormatted;
    const showLocation =
      !showLiveBadge &&
      (!this.isMultiSession || this.isExpired) &&
      (!this.isRegistered || !isCompact) &&
      !!this.location;
    const showMultiSession =
      !showLiveBadge &&
      !this.isExpired &&
      (!this.isRegistered || !isCompact) &&
      this.isMultiSession;
    return {
      showLiveBadge,
      showAssignementBadge,
      showRegisteredBadge,
      showInputType,
      showDate,
      showLocation,
      showMultiSession,
    };
  }

  public ngOnInit(): void {
    if (!this.liveSessions || this.liveSessions.length === 0) {
      console.error('invalid live events data');
      return;
    }
    this.isMultiSession = this.liveSessions.length > 1;
    this.isExpired = this.liveEventsService.isSessionExpired(
      this.liveSessions[this.liveSessions.length - 1]
    );

    this.hasLiveSession = !!this.liveEventsService.getLiveSession(
      this.liveSessions
    );

    if (this.isExpired) {
      /**
       * Given the item has multiple sessions, but no current or upcoming
       * sessions (it is in the past), then show info for the the most recently
       * ended session.
       */
      this.liveEvent = this.liveSessions[this.liveSessions.length - 1];
    } else {
      /**
       * Given the item has only one session (handled in the vm) and the session has not occurred
       * yet (before it has started) and the event has registration then
       * show info for that one session.
       */
      this.liveEvent = this.liveSessions[0];
    }
    this.location = this.liveEventsService.getLocation(
      this.liveEvent,
      this.config?.compact ? 'short' : 'medium'
    );
    this.viewModel = this.vm;

    this.translatedInputType = this.translate.instant(
      `Core_${this.liveEvent.inputType}`
    );
  }
}
