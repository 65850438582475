<form [formGroup]="opportunityForm" (ngSubmit)="onSubmit()" class="form-wrap">
  <dgx-modal
    bodyClasses="guts-p-t-0"
    [canCancel]="isEditing || !canGoBack"
    [isHeaderBorderless]="isEditing"
    [isLoading]="isLoading"
    [isPendingWithOverlay]="true"
    [isSubmitDisabled]="isSubmitDisabled"
    [isSubmitPending]="isSubmitPending"
    [modalHeaderTitle]="headerText"
    [submitButtonText]="submitButtonText"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="isEditing || currentStepIndex === 2"
    (dismiss)="onDismiss()"
  >
    <!-- Add opportunity modal body -->
    <ng-container *ngIf="!isEditing; else editingModal" class="modal-body">
      <aw-wizard
        [awNavigationMode]
        [navigateForward]="
          getStepForm(currentStepIndex)?.valid ? 'allow' : 'deny'
        "
        navigateBackward="allow"
      >
        <aw-wizard-step (stepEnter)="preStepChange()">
          <ng-template awWizardStepTitle>
            <dgx-wizard-step-label
              [currentIndex]="currentStepIndex"
              [stepIndex]="0"
              [stepIsValid]="getStepForm(0)?.valid"
              [stepTitle]="'OrgManage_Opportunities_Tab_Details' | translate"
            ></dgx-wizard-step-label>
          </ng-template>
          <ng-container *ngTemplateOutlet="detailsSection"></ng-container>
        </aw-wizard-step>

        <aw-wizard-step (stepEnter)="preStepChange()">
          <ng-template awWizardStepTitle>
            <dgx-wizard-step-label
              [currentIndex]="currentStepIndex"
              [stepIndex]="1"
              [stepIsValid]="getStepForm(1)?.valid"
              [stepTitle]="
                'OrgManage_Opportunities_Tab_Description' | translate
              "
            ></dgx-wizard-step-label>
          </ng-template>
          <ng-container *ngTemplateOutlet="descriptionSection"></ng-container>
        </aw-wizard-step>

        <aw-wizard-step (stepEnter)="preStepChange()">
          <ng-template awWizardStepTitle>
            <dgx-wizard-step-label
              [currentIndex]="currentStepIndex"
              [stepIndex]="2"
              [stepIsValid]="getStepForm(2)?.valid"
              [stepTitle]="'OrgManage_Opportunities_Tab_Skills' | translate"
            ></dgx-wizard-step-label>
          </ng-template>
          <ng-container *ngTemplateOutlet="skillsSection"></ng-container>
        </aw-wizard-step>
      </aw-wizard>
    </ng-container>

    <!-- Edit opportunity modal body -->
    <ng-template #editingModal class="modal-body">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
        <li
          *ngFor="let tab of modalTabs; index as index"
          [ngbNavItem]="tab.tabId"
          [class.has_error]="tabHasError(index)"
          [class.is_active]="tab.tabId === activeTabId"
          data-dgat="opportunity-modal-3d8"
        >
          <a ngbNavLink class="nav-item__link" [attr.data-dgat]="tab.dgat"
            ><span class="block">{{ tab.label | translate }}</span
            ><df-icon
              *ngIf="tabHasError(index)"
              [ngbTooltip]="tabErrorTooltip"
              class="color-error guts-m-l-quart"
              icon="exclamation-circle-outline"
              size="medium"
            ></df-icon>
          </a>
          <ng-template ngbNavContent>
            <ng-container
              *ngTemplateOutlet="getTabTemplate(index)"
            ></ng-container>
          </ng-template>
        </li>
      </ul>
      <ng-template #tabErrorTooltip>
        <!-- no-wrap on a block element prevents this from becoming a skinny unreadable tooltip -->
        <div class="no-wrap">
          {{ 'Opportunities_Errors_OpportunityIsInvalid_Tab' | translate }}
        </div>
      </ng-template>

      <div [ngbNavOutlet]="nav" class="m-offset"></div>
    </ng-template>

    <!-- Add opportunity modal footer -->
    <ng-container *ngIf="!isEditing" class="modal-footer">
      <div
        class="l_flexbar l_flex-grow l_flex-center text-left"
        [class.l_flex-justify]="canGoBack"
      >
        <div *ngIf="canGoBack">
          <button
            (click)="goToPreviousStep()"
            [disabled]="isSubmitPending"
            class="font-regular js-modal-cancel-btn"
            df-button
            dfButtonType="clear"
            df-button-small
            data-dgat="opportunity-modal-d27"
          >
            {{ wizardBackText }}
          </button>
        </div>
        <div *ngIf="currentStepIndex !== 2" class="l_flex-grow">
          <button
            class="dg-dg-modal__footer-content__submit"
            df-button
            dfButtonType="primary"
            (click)="goToNextStep()"
            data-dgat="opportunity-modal-ed9"
          >
            {{ wizardNextText }}
          </button>
        </div>
      </div>
    </ng-container>
  </dgx-modal>

  <!-- Below are reusable templates (must be within form, due to use of formGroupName) -->
  <ng-template #detailsSection formGroupName="step0">
    <div
      class="
        guts-p-l-1 guts-p-r-1
        opportunity-modal__tab opportunity-modal__tab--details
      "
    >
      <ng-container *ngIf="isActiveTab(0)">
        <div class="grid">
          <!-- TODO: reuse/upgrade/migrate dg-validate-field/message directive -->
          <!-- Title input -->
          <div class="grid__col-6">
            <div class="rel">
              <label for="title" class="form-label"
                >{{ 'Core_Title' | translate
                }}<df-label-marker></df-label-marker>
              </label>
              <input
                #title
                id="title"
                type="text"
                placeholder="{{
                  'OrgManage_Opportunities_TitlePlaceholder' | translate
                }}"
                autocomplete="off"
                formControlName="title"
                dgxAutofocus
                data-dgat="opportunity-modal-5f1"
              />
              <dgx-validate-field
                *ngIf="showErrors && getStepForm(0)?.get('title')?.errors"
                [message]="translateError(getStepForm(0).get('title').errors)"
              ></dgx-validate-field>
            </div>
          </div>

          <!-- Type input -->
          <div class="grid__col-6">
            <div class="rel">
              <ng-container *ngIf="opportunityTypes$ | async">
                <label for="type" class="form-label"
                  >{{ 'Core_Type' | translate
                  }}<df-label-marker></df-label-marker
                ></label>
                <dgx-select
                  id="type"
                  class="full-width"
                  trackBy="name"
                  [isDisabled]="
                    hasProvider ||
                    !(canCreateOpportunities || canManageOpportunities)
                  "
                  labelKey="i18n"
                  [selectedItem]="
                    getStepForm(0).get('type').value || opportunity?.type
                  "
                  [options]="opportunityTypes"
                  (selection)="onTypeSelection($event)"
                  dgatInput="opportunity-modal-494"
                >
                </dgx-select>
                <dgx-validate-field
                  *ngIf="showErrors && getStepForm(0)?.get('type')?.invalid"
                  [message]="
                    'OrgManage_Opportunities_Required_Field' | translate
                  "
                ></dgx-validate-field>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="grid">
          <!-- Job role input -->
          <div
            class="grid__col-6"
            *ngIf="hasInternalJobSkills && !isLoading"
            data-dgat="opportunity-modal-job-role"
          >
            <div class="rel">
              <label for="opportunity-job-role">{{
                'Core_Opportunity_JobRole' | translate
              }}</label>
              <dgx-job-roles
                [search]="jobRolesSearch"
                [selected]="jobRoleTarget"
                (cleared)="onJobRoleCleared()"
                (invalidated)="onJobRoleInvalidated()"
                (selection)="onJobRoleSelection($event)"
              ></dgx-job-roles>
              <dgx-validate-field
                *ngIf="showErrors && getStepForm(0)?.get('jobRole')?.invalid"
                [message]="'OrgManage_Opportunities_Valid_Job_Role' | translate"
              ></dgx-validate-field>
            </div>
          </div>

          <!-- Department input -->
          <div
            [ngClass]="hasInternalJobSkills ? 'grid__col-6' : 'grid__col-12'"
          >
            <div class="rel">
              <label for="department">{{
                'Core_Department' | translate
              }}</label>
              <input
                id="department"
                type="text"
                placeholder="{{
                  'OrgManage_Opportunities_DepartmentPlaceholder' | translate
                }}"
                autocomplete="off"
                formControlName="department"
                data-dgat="opportunity-modal-731"
              />
              <dgx-validate-field
                *ngIf="showErrors && getStepForm(0)?.get('department')?.errors"
                [message]="
                  translateError(getStepForm(0).get('department').errors)
                "
              ></dgx-validate-field>
            </div>
          </div>
        </div>

        <div class="grid">
          <!-- Start date input -->
          <div class="grid__col-12 grid__col-md-6">
            <div class="rel">
              <label for="opportunityStartDate">{{
                'Core_StartDate' | translate
              }}</label>
              <div class="field-group">
                <span class="field-group__input field-group__input--tag">
                  <df-icon
                    icon="calendar"
                    class="guts-m-r-1 color-ebony-a61"
                  ></df-icon>
                  <input
                    id="opportunityStartDate"
                    formControlName="opportunityStartDate"
                    type="text"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    autocomplete="off"
                    [firstDayOfWeek]="7"
                    [minDate]="{ day: 1, month: 1, year: 2000 }"
                    [maxDate]="{ day: 31, month: 12, year: 2049 }"
                    (click)="d.toggle()"
                    placeholder="mm-dd-yyyy"
                    data-dgat="opportunity-modal-678"
                  />
                </span>
              </div>
              <dgx-validate-field
                *ngIf="
                  showErrors &&
                  (getStepForm(0)?.get('opportunityStartDate')?.errors ||
                    getStepForm(0)?.get('opportunityStartDate')?.invalid)
                "
                [message]="
                  getStepForm(0).get('opportunityStartDate').errors?.message ||
                    'OrgManage_Opportunities_Valid_Date' | translate
                "
              ></dgx-validate-field>
            </div>
          </div>

          <ng-container formGroupName="duration">
            <!-- Duration type input -->
            <div
              [ngClass]="
                splitDuration
                  ? 'grid__col-6 grid__col-md-3 guts-p-r-1'
                  : 'grid__col-12 grid__col-md-6'
              "
            >
              <div class="rel">
                <label for="durationUnitType">{{
                  'Core_Duration' | translate
                }}</label>
                <dgx-select
                  [ariaLabel]="'Core_DurationType' | translate"
                  class="full-width"
                  trackBy="name"
                  labelKey="i18n"
                  placeholder="{{
                    'OrgManage_Opportunities_ChooseDurationType' | translate
                  }}"
                  [options]="durationType"
                  id="durationUnitType"
                  [selectedItem]="
                    getStepForm(0)?.get('duration.durationUnitType')?.value
                  "
                  (selection)="onDurationTypeSelection($event)"
                  dgatInput="opportunity-modal-3b1"
                >
                </dgx-select>
              </div>
            </div>

            <!-- Duration units/End date input -->
            <div
              *ngIf="splitDuration"
              class="grid__col-6 grid__col-md-3 guts-p-l-1"
            >
              <div class="rel">
                <label for="durationUnits">{{
                  durationTitle | translate
                }}</label>
                <div
                  class="field-group"
                  *ngIf="durationTitle === 'Core_EndDate'; else durationInput"
                >
                  <!-- End date input-->
                  <span class="field-group__input field-group__input--tag">
                    <df-icon
                      icon="calendar"
                      class="guts-m-r-1 color-ebony-a61"
                    ></df-icon>
                    <input
                      class="date-picker__input--small"
                      id="opportunityEndDate"
                      formControlName="opportunityEndDate"
                      type="text"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      autocomplete="off"
                      [firstDayOfWeek]="7"
                      [minDate]="{ day: 1, month: 1, year: 2000 }"
                      [maxDate]="{ day: 31, month: 12, year: 2049 }"
                      (click)="d.toggle()"
                      placeholder="mm-dd-yyyy"
                      data-dgat="opportunity-modal-20c"
                    />
                  </span>
                </div>
                <ng-template #durationInput>
                  <!-- Duration input -->
                  <input
                    id="durationUnits"
                    autocomplete="off"
                    formControlName="durationUnits"
                    placeholder="0"
                    data-dgat="opportunity-modal-4e0"
                  />
                </ng-template>
                <dgx-validate-field
                  *ngIf="showErrors && getStepForm(0)?.get('duration')?.errors"
                  [message]="
                    getStepForm(0).get('duration').errors.message | translate
                  "
                ></dgx-validate-field>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Location input -->
        <div class="guts-p-full-1-half">
          <div class="rel">
            <label for="location">{{ 'User_LocationLabel' | translate }}</label>
            <input
              id="location"
              type="text"
              autocomplete="off"
              formControlName="locationName"
              data-dgat="opportunity-modal-aeb"
            />
            <dgx-validate-field
              *ngIf="showErrors && getStepForm(0)?.get('locationName')?.errors"
              [message]="
                translateError(getStepForm(0).get('locationName').errors)
              "
            ></dgx-validate-field>
          </div>
        </div>

        <!-- Visibility input -->
        <div class="guts-p-full-1-half" *ngIf="!isLoading">
          <dgx-visibility
            [groups]="groups"
            [selected]="getStepForm(0).get('privacyId').value"
            [options]="visibility"
            [required]="true"
            (onSelection)="handleVisibilitySelection($event)"
            (onGroupSelection)="handleGroupSelection($event)"
            (onGroupRemove)="handleGroupRemove($event)"
            [excludedPrivacyLevels]="excludedGroupPrivacyLevels"
          ></dgx-visibility>
          <dgx-validate-field
            *ngIf="showErrors && getStepForm(0)?.get('privacyId')?.invalid"
            [message]="'OrgManage_Opportunities_Required_Field' | translate"
          ></dgx-validate-field>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #descriptionSection formGroupName="step1">
    <div
      class="
        guts-p-l-1 guts-p-r-1
        opportunity-modal__tab opportunity-modal__tab--description
      "
    >
      <ng-container *ngIf="isActiveTab(1)">
        <div class="guts-p-full-1-half">
          <div class="rel">
            <label
              id="markdownDescriptionLabel"
              for="markdownDescription"
              class="form-label"
              >{{ 'Core_Description' | translate
              }}<df-label-marker></df-label-marker>
            </label>
            <dgx-markdown-editor
              *ngIf="!isLoading"
              formControlName="description"
              [minHeight]="'342px'"
              [textareaId]="'markdownDescription'"
              [ngDisabled]="hasProvider"
              [placeholder]="
                'OrgManage_Opportunities_DescPlaceholder' | translate
              "
              [shouldHaveFocus]="true"
              [showValidationErrors]="false"
              [textareaLabel]="'markdownDescription'"
              [maxLength]="descLength + 500"
            ></dgx-markdown-editor>
            <dgx-validate-field
              *ngIf="showErrors && getStepForm(1)?.get('description')?.errors"
              [message]="
                translateError(getStepForm(1).get('description').errors)
              "
            ></dgx-validate-field>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #skillsSection formGroupName="step2">
    <div
      class="
        guts-p-l-1 guts-p-r-1
        opportunity-modal__tab opportunity-modal__tab--skills
      "
    >
      <ng-container *ngIf="isActiveTab(2)">
        <dgx-skills-view
          [canAddSkills]="true"
          [canRemoveSkills]="true"
          [isMenuAlwaysVisible]="true"
          [skills]="skills"
          (updateSkills)="onUpdateSkills($event)"
        ></dgx-skills-view>
      </ng-container>
    </div>
  </ng-template>
</form>
