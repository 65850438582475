import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { handleExperienceFocusOnSubmit } from '../../services/experience.utils';
import {
  ExperienceModel,
  ExperienceTypes,
  GlobalAddExperienceField,
} from '@app/user-content/user-input-v2/inputs/experience/experience.model';
import { ExperienceGlobalAddInputFacade } from '../../services/global-add/experience-global-add.facade';
import { EXPERIENCE_GLOBAL_ADD_DEFAULTS } from '../../services/experience-facade-base.service';

import {
  maxValidator,
  minValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import {
  ExperienceMapperService,
  ExperienceNotificationService,
  ExperienceService,
  ExperienceTrackerService,
} from '@app/user-content/user-input-v2/inputs/experience/services';

@Component({
  selector: 'dgx-experience-global-add',
  templateUrl: './experience-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExperienceGlobalAddInputFacade,
    ExperienceMapperService,
    ExperienceNotificationService,
    ExperienceService,
    ExperienceTrackerService,
  ],
})
export class ExperienceGlobalAddComponent implements OnInit {
  @Input() public context: InputContext;
  @Input() public initialModel: any;

  public experienceGlobalAddForm: FormGroup;

  public i18n = this.translate.instant([
    'PositionFormCtrl_SaveExperience',
    'Core_Type',
    'Core_JobRoleLiteral',
    'Opportunities_TypeProject',
    'Opportunities_TypeMentorship',
    'Opportunities_TypeMenteeship',
    'Opportunities_TypeShadowing',
    'Opportunities_TypeStretchAssignment',
    'Opportunities_TypeOther',
    'Core_ExperienceType',
    'PositionFormCtrl_PositionTitle',
    'PositionFormCtrl_CompanyOrg',
    'PositionFormCtrl_CurrentPosition',
    'PositionFormCtrl_Seniority',
    'PositionFormCtrl_HoursPerWeek',
    'Core_AddExperience',
    'Core_Mentee',
    'Core_Mentor',
    'Core_Description',
    'Core_Skills',
    'Core_Title',
    'MediaFormCtrl_TitleRequired',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'Core_Name',
    'PositionFormCtrl_SelectType',
    'PositionFormCtrl_ExperienceTitle',
    'PositionFormCtrl_DescribeExperience',
    'PositionFormCtrl_MenteeName',
    'PositionFormCtrl_MentorName',
  ]);
  public heading = this.i18n.Core_AddExperience;
  public vm$: Observable<ExperienceModel>;

  public markSubFormsAsTouched = false;

  public get experienceTypes() {
    return ExperienceTypes;
  }

  constructor(
    public facade: ExperienceGlobalAddInputFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      if (this.initialModel?.inputSubType) {
        await this.facade.initializeViewModelWithExperienceType(
          this.context,
          this.initialModel.inputSubType
        );
      } else {
        await this.facade.initializeViewModel(this.context);
      }
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    // Set up the form
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: GlobalAddExperienceField, value: any) {
    this.facade.onFormControlUpdate(this.experienceGlobalAddForm, field, value);
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.experienceGlobalAddForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.experienceGlobalAddForm.invalid) {
      handleExperienceFocusOnSubmit(this.experienceGlobalAddForm.controls);
      return;
    }

    try {
      await this.facade.onSubmit(this.experienceGlobalAddForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }

    this.activeModal.close(this.facade.snapshot);
  }

  public getHoursPerWeekValidationMessage(): string {
    const field = this.experienceGlobalAddForm.get('hoursPerWeek');
    const minError = field.getError('minError');
    const maxError = field.getError('maxError');
    const requiredError = field.getError('required');
    return requiredError
      ? this.translate.instant('Core_FieldRequiredFormat', {
          fieldName: this.i18n.PositionFormCtrl_HoursPerWeek,
        })
      : minError ?? maxError;
  }

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.experienceGlobalAddForm.get('title').value || '',
      this.experienceGlobalAddForm.get('description').value || ''
    );
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.experienceGlobalAddForm = this.formBuilder.group({
      isCurrent: [],
      experienceType: [this.initialModel?.inputSubType ?? ''],
      title: ['', Validators.required],
      orgName: ['', Validators.required],
      hoursPerWeek: [
        EXPERIENCE_GLOBAL_ADD_DEFAULTS.hoursPerWeek,
        [
          Validators.required,
          minValidator(0, this.translate),
          maxValidator(168, this.translate),
        ],
      ],
      level: [EXPERIENCE_GLOBAL_ADD_DEFAULTS.level],
      description: [],
      skills: [],
      menteeName: [],
      mentorName: [],
      dateRange: [],
    });
  }
}
