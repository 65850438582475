<!-- Skeleton (appears outside of other wrappers) -->
<dgx-skeleton
  *ngIf="useSkeleton && isLoading"
  [columns]="columns.length"
  type="table"
  [rows]="numResultsPerPage || 20"
></dgx-skeleton>

<div [ngClass]="outerWrapperClass">
  <div [ngClass]="componentWrapperClass">
    <!-- Use Pagination -->
    <ng-container *ngIf="!useInfiniteScroll; else infinite">
      <div class="rel" [ngClass]="tableWrapperClass">
        <!-- Data Table Insert -->
        <ng-container *ngTemplateOutlet="table"></ng-container>
        <!-- Loading Indicator -->
        <ng-container *ngTemplateOutlet="loading"></ng-container>
        <!-- Pagination -->
        <div class="border-top center-text" *ngIf="showPagination">
          <dgx-pagination
            [(page)]="pageNum"
            [boundaryLinks]="true"
            [collectionSize]="totalNumResults"
            [ellipses]="false"
            [maxSize]="8"
            [pageSize]="numResultsPerPage"
            [perPageOptions]="perPageOptions"
            [rotate]="true"
            [showPerPage]="showPerPage"
            (pageChange)="onPageChange($event)"
            (perPageChange)="onPerPageChange($event)"
          >
          </dgx-pagination>
        </div>
      </div>
      <!-- Messaging (No Results, No Items Added) -->
      <ng-container *ngTemplateOutlet="messages"></ng-container>
    </ng-container>
    <!-- Use Infinite Scroll -->
    <ng-template #infinite>
      <div
        infiniteScroll
        [infiniteScrollDisabled]="disableInfiniteScroll"
        [infiniteScrollDistance]="0"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        class="rel data-table--sortable-wrapper"
      >
        <!-- Data Table Insert -->
        <ng-container *ngTemplateOutlet="table"></ng-container>
      </div>
      <!-- Loading Indicator -->
      <ng-container *ngTemplateOutlet="loading"></ng-container>
      <!-- Messaging (No Results, No Items Added) -->
      <ng-container *ngTemplateOutlet="messages"></ng-container>
    </ng-template>
  </div>
</div>

<!-- Messaging for No Search Results/No Items Added -->
<ng-template #messages>
  <ng-container *ngIf="!isLoading && (hasNoResults || hasNoItems)">
    <div class="center-text guts-p-t-6">
      <img
        *ngIf="noItemsImage"
        [src]="noItemsImage"
        alt="{{ emptyImageAlt | translate }}"
        class="image-layout--empty"
      />
      <!-- no items at all -->
      <ng-container *ngIf="hasNoResults && hasNoItems">
        <h3
          *ngIf="noItemsAddedActionHeadline"
          class="par--light guts-p-t-2 guts-p-b-1 font-semibold"
        >
          {{ noItemsAddedActionHeadline | translate }}
        </h3>
        <p
          *ngIf="noItemsAddedActionDescription"
          class="par par--small guts-p-b-2"
        >
          {{ noItemsAddedActionDescription | translate }}
        </p>
        <button
          *ngIf="noItemsAddedActionButtonText"
          #noItemsAddedActionTrigger
          (click)="onNoItemsAddedAction()"
          df-button
          dfButtonType="primary"
          data-dgat="data-table-77d"
        >
          {{ noItemsAddedActionButtonText | translate }}
        </button>
      </ng-container>
      <!-- has items but no search results -->
      <ng-container *ngIf="!hasNoItems && hasNoResults">
        <p class="color-ebony-a45 font-semibold guts-p-t-2">
          <ng-container *ngIf="searchTerm; else customMessage">{{
            noSearchResultText | translate: { searchTerm: searchTerm }
          }}</ng-container>
          <ng-template #customMessage>{{
            noItemsCustomMessage | translate
          }}</ng-template>
        </p>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Loading Indicator -->
<ng-template #loading>
  <div
    class="guts-p-t-6"
    *ngIf="(isLoading && !useSkeleton) || isSorting"
    [ngClass]="{
      rel: !useInfiniteScroll || isSorting
    }"
  >
    <div
      class="guts-p-t-1"
      [ngClass]="{
        'bg-color-white_a50 stretch': !useInfiniteScroll || isSorting
      }"
    >
      <df-spinner></df-spinner>
    </div>
  </div>
</ng-template>

<!-- Data Table -->
<ng-template #table>
  <table
    class="data-table data-table--sortable"
    *ngIf="!(useSkeleton && isLoading) && !hasNoResults"
  >
    <caption
      tabindex="0"
      *ngIf="captionConfig?.label"
      [ngClass]="captionConfig?.classes"
    >
      <span>{{ captionConfig?.label }} </span>
      <span class="guts-p-h-quart" *ngIf="captionConfig?.tooltip">
        <df-icon
          [attr.aria-label]="captionConfig?.tooltip"
          [attr.data-dgat]="captionConfig?.tooltipDgat"
          [ngbTooltip]="captionConfig?.tooltip"
          icon="question-mark-circle"
          tabindex="0"
          class="color-ebony-a45 text-top"
          role="text"
          placement="bottom"
        ></df-icon>
      </span>
      <div
        class="par--light par--small font-medium"
        *ngIf="captionConfig.subLabel"
      >
        {{ captionConfig.subLabel }}
      </div>
    </caption>
    <!-- Head (shows with results) -->
    <thead>
      <tr>
        <!-- Optional checkbox column -->
        <th *ngIf="canSelect" class="l_w-min" scope="col">
          <label class="listbox--option guts-p-full-half">
            <input
              type="checkbox"
              class="guts-m-r-half"
              class="center-text guts-m-r-1"
              [checked]="allSelected"
              [indeterminate]="someSelected"
              (change)="selectAll($event)"
              data-dgat="data-table-47a"
            />
          </label>
        </th>
        <!-- Columns -->
        <!-- TODO: There is probably a better way to do this aria-sort thing.-->
        <th
          *ngFor="let column of columns"
          [ngClass]="column.headClasses"
          [ngStyle]="column.headStyles"
          [attr.aria-sort]="getAriaSort(column.sortName)"
          scope="col"
          data-dgat="data-table-e57"
        >
          <span
            [ngbTooltip]="
              column.tooltip && !column.icon ? (column.tooltip | translate) : ''
            "
            [attr.aria-label]="
              column.tooltip && !column.icon ? (column.tooltip | translate) : ''
            "
            [attr.tabindex]="
              (column.tooltip || column.label) &&
              !column.icon &&
              !column.canSort
                ? 0
                : undefined
            "
          >
            <dgx-th-sort
              *ngIf="column.canSort; else plain"
              [buttonLabel]="column.label | translate"
              [isDescending]="getIsDescending(column.sortName)"
              (onSort)="sort(column.sortName)"
            ></dgx-th-sort>
            <ng-template #plain>
              {{ column.label | translate }}
              <df-icon
                *ngIf="column.icon"
                class="{{ column.icon.iconClass }}"
                icon="{{ column.icon.icon }}"
                [ngbTooltip]="
                  column.tooltip ? (column.tooltip | translate) : ''
                "
                [attr.aria-label]="
                  column.tooltip ? (column.tooltip | translate) : ''
                "
                role="text"
              ></df-icon>
            </ng-template>
          </span>
        </th>
        <!-- Optional meatball menu column -->
        <th
          *ngIf="menuSettings?.getMenuConfig"
          [ngClass]="menuSettings?.headClasses"
          [class.l_w-min]="!menuSettings?.headClasses"
          scope="col"
        ></th>
      </tr>
    </thead>
    <!-- Body (shows with results) -->
    <tbody>
      <ng-container
        *ngFor="let item of items; index as i; trackBy: trackByIdentity"
        data-dgat="data-table-f2c"
      >
        <tr
          *ngIf="!collapsed.has(item.parent)"
          data-dgat="data-table-f16"
          (click)="clickHandler($event, item)"
          (keydown)="keydownHandler($event, item)"
          [attr.tabindex]="getIsClickable(item) ? 0 : undefined"
          [attr.role]="getIsClickable(item) ? 'button' : undefined"
          [class.cta-row]="getIsClickable(item)"
          [ngClass]="{ parent: item.parentId, child: item.parent }"
        >
          <!-- Optional checkbox column -->
          <td *ngIf="canSelect">
            <label
              class="listbox--option guts-p-full-half"
              *ngIf="!item.hideSelect"
            >
              <input
                type="checkbox"
                class="guts-m-r-half"
                [checked]="item.isSelected"
                (change)="selectOne(item, $event)"
                data-dgat="data-table-c70"
              />
            </label>
          </td>
          <!-- Columns -->
          <td
            *ngFor="let column of getColumns(item); last as last"
            [ngClass]="column.bodyClasses"
            [ngStyle]="column.bodyStyles"
            data-dgat="data-table-b1e"
            [attr.colspan]="
              item.type === 'groupedDescription' ? columns.length : 1
            "
          >
            <!-- if grouped, make the last column a toggle for the parent -->
            <ng-container
              *ngIf="
                !(last && !menuSettings?.getMenuConfig && item.parentId);
                else firstParentToggle
              "
            >
              <ng-container [ngSwitch]="column?.type">
                <!-- Progress Bar -->
                <ng-container *ngSwitchCase="'progressBar'">
                  <ng-container
                    *ngTemplateOutlet="
                      progressBarColumn;
                      context: { column: asBarColumn(column) }
                    "
                  ></ng-container>
                </ng-container>
                <!-- Date -->

                <ng-container *ngSwitchCase="'date'">
                  <ng-container
                    *ngTemplateOutlet="
                      dateColumn;
                      context: { column: asDateColumn(column) }
                    "
                  ></ng-container>
                </ng-container>

                <!-- Highlight-->
                <ng-container *ngSwitchCase="'highlight'">
                  <ng-container
                    *ngTemplateOutlet="
                      highlightColumn;
                      context: { column: asHighlightColumn(column) }
                    "
                  ></ng-container>
                </ng-container>

                <!-- List -->
                <ng-container *ngSwitchCase="'list'">
                  <ng-container
                    *ngTemplateOutlet="
                      listColumn;
                      context: { column: asListColumn(column) }
                    "
                  ></ng-container>
                </ng-container>

                <!-- Person -->
                <ng-container *ngSwitchCase="'person'">
                  <ng-container
                    *ngTemplateOutlet="
                      personColumn;
                      context: { column: asPersonColumn(column) }
                    "
                  ></ng-container>
                </ng-container>

                <!-- Visibility column (number -> translated string) -->
                <ng-template #visibilityColumn let-column="column">
                  <dgx-data-column-visibility
                    *ngSwitchCase="'visibility'"
                    [groups]="item.groupIds"
                    [privacy]="item[column.prop]"
                  ></dgx-data-column-visibility>
                </ng-template>

                <!-- Any other kind of column -->
                <ng-container *ngSwitchDefault>
                  <ng-container *ngIf="column.template; else default">
                    <ng-container
                      *ngTemplateOutlet="
                        column.template;
                        context: {
                          $implicit: column.prop ? item[column.prop] : item
                        }
                      "
                    >
                    </ng-container>
                  </ng-container>
                  <ng-template #default>
                    <span [title]="column.prop ? item[column.prop] : item">{{
                      column.prop
                        ? translateProperty(column.prop, item[column.prop])
                        : item
                    }}</span>
                  </ng-template>
                </ng-container>

                <ng-template #progressBarColumn let-column="column">
                  <div
                    class="completion-progress-bar"
                    [ngStyle]="column.progressBarStyling"
                    (click)="clickHandler($event, item, 'progressBar', column)"
                    (keydown)="keydownHandler($event, item, 'progressBar')"
                    [attr.tabindex]="
                      column.propActionOnBarClick ? 0 : undefined
                    "
                    [attr.role]="
                      column.propActionOnBarClick ? 'button' : undefined
                    "
                  >
                    <ngb-progressbar
                      [ngClass]="{
                        'cursor-pointer': column.propActionOnBarClick
                      }"
                      role="progressbar"
                      data-dgat="progressbar"
                      [attr.aria-valuenow]="item[column.fromValueProp]"
                      aria-valuemin="0"
                      [attr.aria-valuemax]="
                        item[column.toValueProp] + item[column.fromValueProp]
                      "
                      [attr.aria-label]="
                        getTranslatedProgressTooltip(item, column)
                      "
                      [ngbTooltip]="getTranslatedProgressTooltip(item, column)"
                      [disableTooltip]="disableProgressBarToolTip(column)"
                      type="{{ item[column.progressBarType] }}"
                      placement="top"
                      tabindex="0"
                      [max]="
                        item[column.toValueProp] + item[column.fromValueProp]
                      "
                      [value]="item[column.fromValueProp]"
                    >
                    </ngb-progressbar>
                  </div>
                </ng-template>

                <!-- Date column template-->
                <ng-template #dateColumn let-column="column">
                  <dgx-data-column-date
                    [date]="item[column.prop]"
                    [format]="column.dateFormat"
                  ></dgx-data-column-date>
                </ng-template>

                <!-- Highlight column template-->
                <ng-template #highlightColumn let-column="column">
                  <dgx-data-column-highlight
                    [highlighted]="item[column.prop]"
                    [highlightLevel]="item[column.propHighlightLevel]"
                  ></dgx-data-column-highlight>
                </ng-template>

                <!-- List column template (optionally linked items with optional popover) -->
                <ng-template #listColumn let-column="column">
                  <dgx-data-column-list
                    [limit]="column.limit"
                    [list]="item[column.prop]"
                    [listItemClasses]="column.listItemClasses"
                    [listItemLinkClasses]="column.listItemLinkClasses"
                    [popoverButtonClasses]="column.popoverButtonClasses"
                    [popoverConfig]="column.popoverConfig"
                    [popoverListClasses]="column.popoverListClasses"
                    [prop]="column.propItem"
                    [propHref]="column.propHref"
                    [usePopover]="column.usePopover && column.usePopover(item)"
                    [hideValue]="column.hideValue && column.hideValue(item)"
                    [numberOnly]="column.numberOnly"
                    [popoverListItemClasses]="column.popoverListItemClasses"
                  ></dgx-data-column-list>
                </ng-template>

                <!-- Person column template (person with profile pic and optional email address) -->
                <ng-template #personColumn let-column="column">
                  <dgx-data-column-person
                    [person]="item[column?.prop] || item"
                    [profile]="item[column?.propProfile] || null"
                    [picFallback]="column.picFallBack"
                    [picSize]="column.picSize"
                    [picUseAlt]="column.picUseAlt"
                    [propEmail]="column.propEmail"
                    [picHideMentorIcon]="column.picHideMentorIcon"
                    [picMentorIconSize]="column.picMentorIconSize"
                    [propHref]="column.propHref"
                    [propIsAdmin]="column.propIsAdmin"
                    [propName]="column.propName"
                    [propPic]="column.propPic"
                  ></dgx-data-column-person>
                </ng-template>
              </ng-container>
            </ng-container>
            <!-- Replace last column with parent toggle if row is group parent -->
            <ng-template #firstParentToggle>
              <ng-container
                *ngTemplateOutlet="collapseToggle; context: { item: item }"
              ></ng-container
            ></ng-template>
          </td>
          <ng-container *ngIf="menuSettings?.getMenuConfig">
            <!-- Optional meatball menu column -->
            <td [ngClass]="menuSettings.bodyClasses">
              <dgx-menu
                *ngIf="!item.parentId; else secondParentToggle"
                [autoCloseOnItemSelect]="false"
                [menuConfig]="getMenuConfig(item, i) | async"
                [placement]="menuSettings.placement || 'bottom'"
                (click)="$event.stopPropagation()"
              >
                <button
                  type="button"
                  data-dgat="data-table-070"
                  title="{{ 'Core_MoreOptions' | translate }}"
                >
                  <df-icon
                    icon="dots"
                    a11yText="{{ 'Core_MoreOptions' | translate }}"
                    class="color-ebony-a61"
                  ></df-icon>
                </button>
              </dgx-menu>
              <!-- Replace meatball menu with parent toggle if row is group parent -->
              <ng-template #secondParentToggle>
                <ng-container
                  *ngTemplateOutlet="collapseToggle; context: { item: item }"
                ></ng-container
              ></ng-template>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>
<!-- Template for expand/collapse toggle -->
<ng-template #collapseToggle let-item="item">
  <button
    type="button"
    *ngIf="!collapsed.has(item.parentId)"
    title="{{ 'Core_Collapse' | translate }}"
    (click)="addRemoveCollapsed('add', item.parentId)"
    data-dgat="data-table-b5a"
  >
    <df-icon
      icon="chevron-up"
      a11yText="{{ 'Core_Collapse' | translate }}"
      class="color-ebony-a61"
    ></df-icon>
  </button>
  <button
    type="button"
    *ngIf="collapsed.has(item.parentId)"
    title="{{ 'Core_Expand' | translate }}"
    (click)="addRemoveCollapsed('remove', item.parentId)"
    data-dgat="data-table-763"
  >
    <df-icon
      icon="chevron-down"
      a11yText="{{ 'Core_Expand' | translate }}"
      class="color-ebony-a61"
    ></df-icon>
  </button>
</ng-template>
