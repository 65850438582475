import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Role } from '@app/orgs/taxonomy-api.model';

import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { OrgSkillsParams } from './org-skills.service';
import { ModalService } from '@app/shared/services/modal.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrgRolesService {
  private readonly baseUrl = '/taxonomy';

  constructor(
    private http: NgxHttpClient,
    private modalService: ModalService
  ) {}

  /**
   * Get roles for an org
   */
  public getOrgRoles({
    term = '',
    count = 10,
    orderBy = 'name',
    organizationId,
    skip = 0,
    sortDescending = false,
  }: OrgSkillsParams = {}): Observable<any> {
    return this.http.get(`${this.baseUrl}/orgroles`, {
      params: { term, count, orderBy, organizationId, skip, sortDescending },
    });
  }

  /**
   * Send a new role to the api
   * @param role {Role} new role's content
   */
  public saveNewRole(role: Role): Observable<any> {
    return this.http.post(`${this.baseUrl}/addrole`, {
      ...role,
    });
  }

  /**
   * Save changes to an existing role
   * @param role {Role} new role's content
   */
  public saveUpdatedRole(role: Role): Observable<any> {
    return this.http.put(`${this.baseUrl}/updaterole`, {
      ...role,
    });
  }

  /**
   * Change a role to a plan
   * @param role {Role} role's content
   */
  public convertOrgRole(role: Role): Observable<any> {
    return this.http.post<number>(`${this.baseUrl}/convert`, {
      ...role,
      SketchToRole: true,
    });
  }

  /**
   * Toggle a role's featured status
   * @param role {Role} role's current content
   */
  public featureOrgRole(role: Role) {
    const newFeaturedValue = !role.isFeatured;
    return this.http.post<number>(`${this.baseUrl}/featureorgrole`, {
      ...role,
      newFeaturedValue,
    });
  }

  /**
   * Shows delete modal and handles delete call
   * @param role {Role} role's current content
   */
  public showDeleteModal(role: Role, inputs): Observable<any> {
    return this.modalService.showDeleteConfirmation(inputs).pipe(
      switchMap(() =>
        this.http.delete(`${this.baseUrl}/orgroles`, {
          params: { id: role.id },
        })
      )
    );
  }
}
