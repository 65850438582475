// NOTE: must be imported first to configure the environment
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import './environments/overrides';

if (environment.isProduction) {
  enableProdMode();
}

platformBrowserDynamic([]).bootstrapModule(AppModule);
