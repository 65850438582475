import { Inject, Injectable } from '@angular/core';
import { MicrosoftTeamsToken } from '@app/ms-teams/msteams.token';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MSTeamsService {
  constructor(@Inject(MicrosoftTeamsToken) private microsoftTeams) {}

  getAuthToken(): Observable<string> {
    return new Observable<string>((observer) => {
      const idTokenRequest = {
        successCallback: (token) => {
          observer.next(token);
          observer.complete();
        },
        errorCallback: (error) => {
          observer.error(error);
          observer.complete();
        }
      };

      this.microsoftTeams.authentication.getAuthToken(idTokenRequest);
    });
  }

  getHostApp(): Observable<microsoftTeams.HostClientType> {
    return new Observable<microsoftTeams.HostClientType>((observer) => {
      this.microsoftTeams.getContext((context: microsoftTeams.Context) => {
        observer.next(context.hostClientType);
        observer.complete();
      });
    });
  }

  getLocale(): Observable<string> {
    return new Observable<string>((observer) => {
      this.microsoftTeams.getContext((context: microsoftTeams.Context) => {
        observer.next(context.locale);
        observer.complete();
      });
    });
  }
}