import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';
import {
  AnalyticsAppLocation,
  WebEnvironmentInfo,
  XSRFCache,
} from '../web-environment-info';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class WebEnvironmentService {
  public xsrfCache: XSRFCache = {
    lastCookieString: '',
    lastToken: null,
  };

  private info: WebEnvironmentInfo;

  constructor(private http: NgxHttpClient, private router: Router) {}
  public get authCookieName() {
    return this.info.authCookieName;
  }

  public get xsrfTokenName() {
    return this.info.xsrfTokenName;
  }

  public get cookieSuffix() {
    return this.info.cookieSuffix;
  }

  public get analyticsAppLocation() {
    return this.info.analytics.appLocation;
  }

  public set analyticsAppLocation(location: AnalyticsAppLocation) {
    this.info.analytics.appLocation = location;
  }

  public get analyticsAnonymousUserId() {
    return this.info.analytics.anonymousUserId;
  }

  public get analyticsUrl() {
    return this.info.analytics.url;
  }

  public get clientSideFeatureFlagSdkKey() {
    return this.info.clientSideFeatureFlagSdkKey;
  }

  public get environment() {
    return this.info.environment;
  }

  public get isProduction() {
    return (
      this.info.environment === 'Production' ||
      this.info.environment === 'Europe' ||
      this.info.environment === 'Canada'
    );
  }

  public initialize(forceRefresh: boolean = false): Observable<any> {
    const webEnvInfoApiUrl = forceRefresh
      ? `/webenvironment/info?cb=${Date.now()}`
      : '/webenvironment/info';
    return this.http.get<WebEnvironmentInfo>(webEnvInfoApiUrl).pipe(
      tap((info) => {
        this.info = info;
      })
    );
  }

  public getBlobBaseUrl(): string {
    return this.info.blobBaseUrl;
  }

  public getBlobUrl(url: string, isStatic?: boolean) {
    // set staticUrl to true if you want to exclude blob during debug.
    // staticUrl is used for images we add to the /img directory rather than something that got uploaded by the user.

    if (typeof url !== 'string') {
      return this.info.blobBaseUrl;
    } else if (url.lastIndexOf('http', 0) === 0) {
      return url;
    } else {
      if (url.lastIndexOf('~/', 0) === 0) {
        url = url.replace('~/', '/');
      }
      if (isStatic && this.info.honorStaticBlobUrls) {
        return url;
      }
    }

    let blobUrl = this.info.blobBaseUrl + url;
    if (this.info.cacheBuster) {
      blobUrl += '?v=' + this.info.cacheBuster;
    }

    return blobUrl;
  }

  public getZendeskUrl(articlePath: string) {
    if (!articlePath.startsWith('/')) {
      articlePath = `/${articlePath}`;
    }
    const url = encodeURIComponent(
      `https://degreed.zendesk.com/hc/en-us${articlePath}`
    );
    return `/HelpdeskSAML/ZenDeskSSOInitiator?url=${url}`;
  }

  public validateOrigin(originToValidate) {
    let isValidOrigin: boolean;

    const regex = new RegExp(/(.*\.|^)degreed\.com$/i);
    const originParts = originToValidate.split('//');

    if (originParts[0] !== 'https:') {
      return false;
    }

    if (
      originParts[1] === 'localhost:44300' ||
      originParts[1].indexOf('ngrok') > -1
    ) {
      return true;
    }

    isValidOrigin = regex.test(originParts[1]);

    return isValidOrigin;
  }

  /**
   * Reloads the current routed component without reinitializing the entire app
   *
   * If you're building something new, please don't use this. Instead use reactive principles
   * to update the component state by reacting to changes from the data source observables. This
   * just gives us a slightly less awful way to "refresh" a routed component without
   * reinitializing the entire app via window.location.reload()
   *
   * @deprecated
   */
  public reloadRoute() {
    const url = this.router.url;

    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
}
