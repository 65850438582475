import {
  PaginationProps,
  PaginationApi,
  StoreState,
  Entity,
} from '@app/shared/rsm';
import {
  OrganizationModel,
  OrganizationModelIdentifier,
} from '@app/orgs/services/orgs.model';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import {
  SearchFacet,
  SearchFacetValue,
  SearchFilterFilter,
} from '@app/search/search-api.model';
import { PaginationData } from '@ngneat/elf-pagination';
import {
  RelatedSkill,
  SearchFlagsService,
  SearchTypeaheadInitLocations,
} from '@app/search/services';
import { GroupsMatchViewModel } from '@app/search/components/search-groups/search-groups.component';
import { PeopleMatchViewModel } from '@app/search/components/search-people/search-people.component';
import { SkillsMatchViewModel } from '@app/search/components/search-skills/search-skills.component';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { FeaturedItemsMatchViewModel } from '@app/search/components/search-view';

/**
 * Filter/facet interfaces
 */
export type SearchFilter = ParentItemViewModel<
  SearchFacet,
  SimpleItemViewModel<SearchFacetValue>
>;

export interface AppliedSearchFacet {
  id: string;
  name: string;
  values: string[] | number[];
}

/**
 * All search params that may be passed from the URL to the state
 * Note: keep in sync with KNOWN_URL_PARAMS
 */
export interface QueryUrlParams {
  term: string;
  orgId: number;
  pageNum: number;
  filters: string;
  sort: SearchSort;
  isExternalCat: boolean;
  isMarketplaceCat: boolean;
  viewLearningId: number;
}

/**
 * These params may be added to the URL but are tracked in other services
 * Note: keep in sync with KNOWN_URL_PARAMS
 */
export interface ServiceUrlParams {
  ignorePreferredLanguage: boolean;
  is_msteams: boolean;
}

/**
 * Search API response
 */
export interface SearchLearningResponse {
  term: string;
  results: LearningResourceViewModel[];
  filters: SearchFilterFilter[];
  pagination?: PaginationData;
  suggestedTerm: string;
  previousTerm: string;
  typoAutoSearched: boolean;
}

export interface UpdateOptions {
  results: LearningResource[];
  filters: SearchFilter[];
  pagination: Partial<PaginationProps>;
  term: string;
  suggestedTerm: string;
  typoAutoSearched: boolean;
  previousTerm: string;
  sort?: SearchSort;
  orgId?: number;
  isExternalCat?: boolean;
  isMarketplaceCat?: boolean;
  appliedFacets?: AppliedSearchFacet[];
}

export type LearningResource = LearningResourceViewModel;
export type SearchSort = 'relevant' | 'popular' | 'recent';

// ******************************************
// State
// ******************************************

export interface SearchState extends StoreState {
  term: string;
  filters: SearchFilter[];
  appliedFacets: AppliedSearchFacet[];
  results: LearningResource[];
  pagination: Partial<PaginationData & PaginationApi>;
  sort: SearchSort;
  isExternalCat: boolean;
  isMarketplaceCat: boolean;
  viewLearningId: number;
  defaultOrgId: number;
  orgId: number;
  suggestedTerm: string;
  typoAutoSearched: boolean;
  previousTerm: string;
  isCatalogSearch: boolean;
  changeTrigger?: SearchChangeTrigger;
}

export enum SearchChangeTrigger {
  Term = 'term',
  Filters = 'appliedFacets',
  Sort = 'sort',
  Organization = 'orgId',
  Page = 'pagination',
}

// ******************************************
// Search API Interfaces
// ******************************************

export interface SearchLearningOptions {
  orgId: number;
  term: string;
  options: Partial<SearchOptions>;
}

export interface SearchOptions {
  pageNum: number;
  boostRecent: boolean;
  boostPopular: boolean;
  numResultsToSkip: number; // default to 0
  appliedFacets: AppliedSearchFacet[];
  isMarketplace?: boolean;
  persistFilter?: boolean;
}

// ******************************************
// View Model Interfaces
// ******************************************

export interface SearchAPI {
  updateTerm: (term: string) => Promise<boolean>;
  updateSortBy: (sortBy: string) => Promise<boolean>;
  updateFilters: (filters: SearchFilter[]) => Promise<boolean>;
  updateOrganization: (
    selected: OrganizationModelIdentifier
  ) => Promise<boolean>;
  loadEndorsedContent: () => void;
}

export interface SearchComputedState {
  i18n: Record<string, string>;
  isClientProvider: boolean;
  organizations: OrganizationModel[];
  showOrgSelector: boolean;
  selectedOrganization: OrganizationModel;
  defaultOrgId: number;
  showTypoSuggestion: boolean;
  hasCareerPathing: boolean;
  endorsedFilterSelected: boolean;
  canViewOpportunities: boolean;
  searchInitiationLocation: SearchTypeaheadInitLocations;
  transformedSearchFilters: SearchFilter[];
  showFilterReset: boolean;
  showNoResults: boolean;
  ldFlags: SearchFlagsService;
  showPrioritizedEndorsedContent: boolean;
  isPhone: boolean;
  isMobile: boolean;
  showFeaturedCarousel: boolean;
  showSearchFiltersSidebar: boolean;
  isMarketplaceEnabled: boolean;
  trackingLocation: string;
}

export type SearchViewModel = SearchAPI & SearchState & SearchComputedState;

// ******************************************
// Related data view models - override `id` to `string` to enforce `Entity` type compliance
// ******************************************

export type OpportunityResult = Omit<Opportunity, 'id'> & Entity;
export type SkillResult = Omit<SkillsMatchViewModel, 'id'> & Entity;
export type PeopleResult = Omit<PeopleMatchViewModel, 'id'> & Entity;
export type GroupResult = Omit<GroupsMatchViewModel, 'id'> & Entity;
export type RelatedSkillResult = Omit<RelatedSkill, 'id'> & Entity;
export type EndorsedResult = Omit<LearningResourceViewModel, 'id'> & Entity;
export type FeaturedItemResult = Omit<FeaturedItemsMatchViewModel, 'id'> &
  Entity;
