import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GroupPrivacy } from '@app/groups/group-api';

import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-orgs-internal-content-visibility',
  templateUrl: './orgs-internal-content-visibility.component.html',
  styleUrls: ['./orgs-internal-content-visibility.component.scss'],
})
export class OrgsInternalContentVisibilityComponent implements OnInit {
  public activeIds: string[] = [];
  public previousVisibleToOrg: boolean;
  public visibleToGroup: boolean;
  public isVisibleToOrgExpanded: boolean;
  public isVisibleToGroupsExpanded: boolean;

  @Input() public orgId: number;
  @Input() public hideTitle: boolean;
  @Input() public groupIds = [];
  @Input() public groupNames = [];
  @Input() public visibleToOrg: boolean;

  @Output()
  public setVisibilityValues: EventEmitter<{
    groupIds: number[];
    groupNames: string[];
    visibleToOrg: boolean;
  }> = new EventEmitter();

  @ViewChild('accordian')
  public accordian: NgbAccordion;

  public i18n = this.translate.instant([
    'Core_Open',
    'Core_Close',
    'orgInternalContentVisibilitySelector_GroupVisibilityPlaceholder',
    'orgInternalContentVisibilitySelector_OrgVisibilityPlaceholder',
    'orgInternalContentVisibilitySelector_TargetGroupsLabel',
    'orgInternalContentVisibilitySelector_Title',
    'orgInternalContentVisibilitySelector_VisibleToGroups',
    'orgInternalContentVisibilitySelector_VisibleToOrganization',
  ]);

  constructor(private translate: TranslateService) {}

  /**
   * Hide admin groups
   */
  public get excludedGroupPrivacyLevels(): GroupPrivacy[] {
    return ['Administrative'];
  }

  @Input() public setDirtyValidation = (
    newValue: boolean,
    oldValue: boolean
  ) => {};

  public ngOnInit(): void {
    this.visibleToGroup = !this.visibleToOrg;
    this.activeIds = this.visibleToOrg
      ? ['ngb-panel-org']
      : ['ngb-panel-group'];
    this.isVisibleToOrgExpanded = this.visibleToOrg;
    this.isVisibleToGroupsExpanded = !this.visibleToOrg;
  }

  public handleVisibilityChange(visibleToOrg: boolean) {
    if (this.previousVisibleToOrg !== visibleToOrg) {
      this.visibleToOrg = visibleToOrg;
      this.visibleToGroup = !visibleToOrg;
      this.resetGroups();
      this.previousVisibleToOrg = visibleToOrg;
      this.isVisibleToOrgExpanded = visibleToOrg;
      this.isVisibleToGroupsExpanded = !visibleToOrg;
      this.accordian.expand(visibleToOrg ? 'ngb-panel-org' : 'ngb-panel-group');
      this.validate();
    }
  }

  public handlePanelChange(ev: NgbPanelChangeEvent) {
    const initialValue = this.visibleToOrg;

    this.isVisibleToOrgExpanded =
      ev.panelId === 'ngb-panel-org' && ev.nextState;
    this.isVisibleToGroupsExpanded =
      ev.panelId === 'ngb-panel-group' && ev.nextState;

    this.visibleToOrg = this.isVisibleToOrgExpanded;

    // Handle case where selected panel and selected input don't match
    if (this.visibleToOrg !== initialValue) {
      this.handleVisibilityChange(this.visibleToOrg);
    }
  }

  public handleGroupsAdd = ({ model }) => {
    // Duplicate check should be able to be removed after child component migrations
    if (model.GroupId && this.groupIds.indexOf(model.GroupId) === -1) {
      this.groupIds.push(model.GroupId);
    }
    if (this.groupNames.indexOf(model.Name) === -1) {
      this.groupNames.push(model.Name);
    }
    this.validate();
  };

  public handleGroupsRemove = ({ group }) => {
    this.groupNames = this.groupNames.filter((name) => name !== group.Name);
    if (group.GroupId) {
      this.groupIds = this.groupIds.filter((id) => id !== group.GroupId);
    }

    this.validate();
  };

  public resetGroups() {
    this.setVisibilityValues.emit({
      groupIds: [],
      groupNames: [],
      visibleToOrg: this.visibleToOrg,
    });
    this.groupIds = [];
    this.groupNames = [];
    this.setDirty();
  }

  public setDirty() {
    this.setDirtyValidation(false, true);
  }

  public validate() {
    this.setVisibilityValues.emit({
      groupIds: this.groupIds,
      groupNames: this.groupNames,
      visibleToOrg: this.visibleToOrg,
    });
    this.setDirty();
  }

  public handleInputBlur(ev: Event) {
    console.log('blurred ' + ev.target);
  }
}
