<dgx-modal-header
  (dismiss)="dismiss()"
  [attr.aria-label]="heading"
  [isBorderless]="canManage"
  >{{ heading }}</dgx-modal-header
>
<div>
  <ng-container *ngIf="canManage; then nav; else share"></ng-container>
  <ng-template #nav>
    <dgx-tab-navigation-routerless
      [tabList]="tabList"
      wrapperClasses="guts-p-h-2"
    >
    </dgx-tab-navigation-routerless>
  </ng-template>

  <ng-template #trackable>
    <dgx-tracking-link-form [resolve]="resolve" [hideHeader]="true">
    </dgx-tracking-link-form>
  </ng-template>
  <ng-template #share>
      <dgx-recommendation-form
        [item]="resolve.item"
        (closeModal)="close($event)"
        (cancelModal)="dismiss()"
      ></dgx-recommendation-form>
  </ng-template>
</div>
