<div
  class="rating-dialog"
  data-dgprop-initiation-location="Skill Modal"
  [attr.data-dgprop-location]="trackingLocation"
>
  <!-- Modal Header -->
  <dgx-modal-header *ngIf="vm$ | async as vm" (dismiss)="close()">
    {{ tag.title }}
    <div
      class="badge badge-pill badge-neutral guts-m-l-1 test"
      *ngIf="vm.linkedTags?.length > 0"
      [innerHTML]="
        'dgTagRating_LinkedSkillsNumber'
          | translate: { amount: vm.linkedTags.length }
      "
    ></div>
  </dgx-modal-header>

  <!-- Modal Body -->
  <div *ngIf="vm$ | async as vm; else resourcesLoading" class="guts-p-full-2">
    <!-- Skill description -->
    <dgx-text-expander
      *ngIf="vm.tagDescription"
      class="markdown--small"
      [content]="vm.tagDescription"
      [maxCollapsedLines]="1"
      [useReadMore]="true"
      [isHtml]="true"
    ></dgx-text-expander>

    <!-- Rating buttons -->
    <div
      *ngFor="let type of vm.enabledRatingTypes; trackBy: trackByFn"
      data-dgat="tag-rating-overview-608"
      class="guts-m-t-1"
    >
      <dgx-tag-rating-button
        [type]="type"
        [ownerIsViewing]="true"
        [tag]="vm.tag"
        [tagRatingDetails]="vm.ratingDetails"
        [availableRatingTypes]="vm.availableRatings"
        [trackingLocation]="trackingLocation"
        [allCheckpoints]="vm.allCheckpoints"
        [isEvaluable]="vm.isEvaluable"
      ></dgx-tag-rating-button>
    </div>
  </div>

  <!-- Modal Footer (More complicated than just a submit button, so we use this directly) -->
  <dgx-modal-footer [useDefaultSubmitButton]="false">
    <div class="l_flex-grow left-text">
      <a
        *ngIf="
          !isSkillInventoryClient && !isSkillAnalyticsClient && searchLink.label
        "
        [href]="searchLink.url"
        class="btn btn-passive"
        (click)="search()"
        data-dgat="tagRatingsOverviewModal-33f"
      >
        <span [textContent]="searchLink.label" class="ib-v-middle"></span>
        <df-icon
          icon="chevron-right"
          size="small"
          class="guts-m-l-half"
        ></df-icon>
      </a>
    </div>
    <div>
      <button
        type="submit"
        df-button
        dfButtonType="primary"
        (click)="close()"
        [textContent]="i18n.Core_Done"
        data-dgat="tagRatingsOverviewModal-904"
      ></button>
    </div>
  </dgx-modal-footer>
</div>

<!-- Resources loading -->
<ng-template #resourcesLoading>
  <ul class="guts-p-full-2 guts-m-b-1">
    <dgx-skeleton type="ratingButton"></dgx-skeleton>
    <dgx-skeleton type="ratingButton"></dgx-skeleton>
  </ul>
</ng-template>
