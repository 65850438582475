<button
  [ngClass]="buttonClasses"
  [dfButtonType]="buttonType"
  [disabled]="buttonType !== 'passive'"
  df-button
  #connectWith
  (click)="
    buttonType === 'passive'
      ? openConnectWithMentorModal($event, mentor)
      : undefined
  "
  data-dgat="connect-with-mentor-button-d5f"
>
  {{ buttonLabel }}
</button>
