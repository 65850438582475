<fieldset class="visibility-selection">
  <div class="form-wrap__group">
    <label *ngIf="withLabel" for="visibility" class="form-label">
      {{ labelText | translate
      }}<df-label-marker *ngIf="required"></df-label-marker>
    </label>
    <dgx-select
      id="visibility"
      class="full-width"
      trackBy="name"
      labelKey="name"
      [isDisabled]="selectedOption?.disabled"
      [selectedItem]="selectedOption?.item"
      [placeholder]="'TargetForm_SelectVisibility' | translate"
      [options]="optionItems"
      (selection)="handleSelect($event)"
      dgatInput="visibility-1cc"
    >
    </dgx-select>
  </div>

  <div
    class="form-wrap__group"
    *ngIf="selectedOption?.canExpand"
    [@dfCollapseExpand]
  >
    <div
      class="guts-m-t-2 m-guts-p-t-1"
      *ngIf="selectedOption.urlOptions; else groupSearch"
    >
      <dgx-copy-link
        [url]="selectedOption.urlOptions.url"
        [trackingLabel]="selectedOption.urlOptions.trackingLabel"
        [trackingCategory]="selectedOption.urlOptions.trackingCategory"
      ></dgx-copy-link>
    </div>
  </div>
</fieldset>

<ng-template #groupSearch>
  <label
    for="group-search-input"
    *ngIf="withLabel"
    class="visibility__group-search-label"
  >
    <span>
      {{
        !!selectedOption.expandedGroupText
          ? (selectedOption.expandedGroupText | translate)
          : ('TargetForm_VisibilityLimitToGroup' | translate)
      }}

      <df-icon
        *ngIf="!!selectedOption.customGroupTooltip"
        aria-label="customGroupTooltip"
        [ngbTooltip]="selectedOption.customGroupTooltip"
        tabindex="0"
        tooltip-trigger="'focus mouseenter'"
        tooltip-append-to-body="true"
        size="small"
        icon="question-mark-circle"
        class="v-top guts-m-l-quart par--light"
      ></df-icon>
    </span>

    <span
      *ngIf="selectedOption.expandedGroupRequired"
      class="visibility__label-required"
      >{{ 'Core_Required' | translate }}</span
    >
  </label>

  <div class="m-guts-p-t-1">
    <div *ngIf="!selectedOption?.disabled; else readOnlyTargetedGroups">
      <dgx-group-search
        (onGroupSelection)="handleGroupSelection($event)"
        (onGroupRemove)="handleGroupRemove($event)"
        (onGroupBlur)="handleGroupBlur()"
        [groups]="groups"
        [groupPrivacyLevel]="groupPrivacyLevel"
        [excludedPrivacyLevels]="excludedPrivacyLevels"
      ></dgx-group-search>
    </div>

    <ng-template #readOnlyTargetedGroups>
      <div class="field-group">
        <span class="field-group__input field-group__input--tag">
          <span *ngFor="let group of groups" data-dgat="visibility-2ec">
            <button
              [disabled]="true"
              class="
                btn btn-sm
                bg-color-ebony-a18
                color-ebony
                guts-m-l-quart guts-m-v-half guts-m-r-half guts-p-r-half
              "
              [attr.aria-label]="group.name"
              data-dgat="visibility-6d1"
            >
              <span>{{ group.name }}</span>
            </button>
          </span>
        </span>
      </div>
    </ng-template>
  </div>
</ng-template>
