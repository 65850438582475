import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { WebEnvironmentService } from '../services/web-environment.service';

export type HttpHandlerFn = (
  req: HttpRequest<unknown>
) => Observable<HttpEvent<unknown>>;

@Injectable()
export class XSRFTokenInterceptor implements HttpInterceptor {
  private XSRF_DEFAULT_HEADER_NAME: string = 'X-XSRF-TOKEN';

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.xsrfInterceptorFn(request, (downstreamRequest) =>
      next.handle(downstreamRequest)
    );
  }

  private xsrfInterceptorFn(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
  ): Observable<HttpEvent<unknown>> {
    const lcUrl = req.url.toLowerCase();
    // Skip both non-mutating requests and absolute URLs.
    // Non-mutating requests don't require a token, and absolute URLs require special handling
    // anyway as the cookie set
    // on our origin is not the same as the token expected by another origin.
    if (
      req.method === 'GET' ||
      req.method === 'HEAD' ||
      lcUrl.startsWith('http://') ||
      lcUrl.startsWith('https://')
    ) {
      return next(req);
    }

    const token = this.getToken();
    const headerName = this.XSRF_DEFAULT_HEADER_NAME;

    // Be careful not to overwrite an existing header of the same name.
    if (token != null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next(req);
  }

  private getToken(): string | null {
    const cookieName = this.webEnvironmentService.xsrfTokenName;

    if (!cookieName) {
      throw new Error(
        'XSRF token name is not defined in the environment file.'
      );
    }

    const cookieString = this.doc.cookie || '';

    if (!cookieString) {
      throw new Error('No cookie found when trying to get XSRF token.');
    }

    if (
      cookieString !== this.webEnvironmentService.xsrfCache.lastCookieString
    ) {
      this.webEnvironmentService.xsrfCache.lastToken = this.parseCookieValue(
        cookieString,
        cookieName
      );

      this.webEnvironmentService.xsrfCache.lastCookieString = cookieString;
    }
    return this.webEnvironmentService.xsrfCache.lastToken;
  }

  private parseCookieValue(cookieStr: string, name: string): string | null {
    name = encodeURIComponent(name);
    for (const cookie of cookieStr.split(';')) {
      const eqIndex = cookie.indexOf('=');
      const [cookieName, cookieValue]: string[] =
        eqIndex == -1
          ? [cookie, '']
          : [cookie.slice(0, eqIndex), cookie.slice(eqIndex + 1)];
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }
}
