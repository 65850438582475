import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { AuthService } from '@app/shared/services';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';
import { ArticleFacadeBase } from '../article-facade-base.service';
import { ArticleMapperService } from '../article-mapper.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';

// misc
import { MediaParseType } from '@app/shared/models/core.enums';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import {
  ArticleApiInputEdit,
  ArticleModel,
} from '@app/user-content/user-input-v2/inputs/article/article.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleService } from '../article.service';
import { FormGroup } from '@angular/forms';
import { ArticleNotificationService } from '../article-notification.service';
import { ArticleTrackerService } from '../article-tracker.service';
import { PathwayNode, PathwayStep } from '@dg/pathways-rsm';
import { SubmissionStatus } from '@app/inputs/inputs.model';

@Injectable({ providedIn: 'root' })
export class ArticlePathwaysPlansInputFacade extends ArticleFacadeBase {
  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public authService: AuthService,
    public articleMapperService: ArticleMapperService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public articleService: ArticleService,
    private articleNotificationService: ArticleNotificationService,
    private articleTrackerService: ArticleTrackerService
  ) {
    super(
      inputsService,
      authService,
      translate,
      articleMapperService,
      orgInternalContentService,
      inputImageUploadAdapterService,
      articleService
    );
  }
  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): ArticleModel {
    return readFirst(this.viewModel$);
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  // *******************************************************
  // Overwrites
  // *******************************************************

  /**
   * Overwrite the on Next form the base facade for catalog
   * When the next button has been selected
   * @param url
   * @returns
   */
  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

      // Update the view model with the url and set loading
      this.viewModel = { ...this.viewModel, entryUrl: url };

      // Parse url (diffbot)
      const result = await this.inputsService.getMediaMetadataAsPromise(
        url,
        this.viewModel.inputContext.inputType,
        MediaParseType.None
      );

      // Update the viewModel with the results
      const updatedView = this.articleMapperService.toViewModel(
        result,
        this.viewModel
      );

      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
        owner: undefined,
      };

      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      return;
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
  }

  /**
   * Override onSubmit if submitting article edits locally/for the pathway/plan only
   * @param form
   * @param isEditingContentLocally
   * @param pathwayStep
   */
  public async onSubmit(
    form: FormGroup,
    isEditingContentLocally: boolean,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    try {
      if (isEditingContentLocally && pathwayStep) {
        const formData = form.value;
        // update the View model with the form data
        super.updateViewWithFormData(formData);

        this.submissionStatus$.next(SubmissionStatus.Submitting);

        const updatedStepNode: PathwayNode = {
          ...pathwayStep,
          description: this.viewModel.summary,
          imageUrl: this.viewModel.imageUrl,
          title: this.viewModel.title,
        };
        await this.inputsService.updatePathwayNode(updatedStepNode);
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      } else {
        await super.onSubmit(form, false);
      }
      this.performSuccessSideEffects();
    } catch {
      this.performFailureSideEffects(isEditingContentLocally, pathwayStep);
    }
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
    };
  }

  /**
   * Override initializeEdit if editing article locally/for the pathway/plan only
   * @param isEditingInternalContent
   * @param pathwayStep
   */
  public async initializeEdit(
    isEditingContentLocally?: boolean,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    if (isEditingContentLocally && pathwayStep) {
      const inputOwner = await this.inputsService.getInputOwner(
        pathwayStep.reference.primaryContactResourceId
      );
      // TODO: If we add the ability to locally edit input properties other than title, summary, and imageUrl
      // Then, we will need to update the properties below to get their values directly from pathwayStep rather than pathwayStep.reference
      const editEntry: ArticleApiInputEdit = {
        entryUrl: pathwayStep.reference.url,
        title: pathwayStep.reference.title,
        sourceName: pathwayStep.reference.source,
        summary: pathwayStep.reference.summary,
        durationHours: pathwayStep.reference.durationHours,
        durationMinutes: pathwayStep.reference.durationMinutes,
        primaryContactResourceId:
          pathwayStep.reference.primaryContactResourceId,
        primaryContactResourceType:
          pathwayStep.reference.primaryContactResourceType,
        owner: inputOwner,
        imageUrl: pathwayStep.reference.imageUrl,
      } as unknown as ArticleApiInputEdit;

      // Map response to view model
      const updatedView = this.articleMapperService.toViewModel(
        editEntry,
        this.viewModel
      );
      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
      };
      return;
    }
    await super.initializeEdit();
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreated(
        this.viewModel.title
      );

      this.articleTrackerService.trackContentSkillInferred(this.viewModel);
    }
  }

  /** Performs any side effects required following failed creation/update of an Article */
  protected performFailureSideEffects(
    isEditingContentLocally?: boolean,
    pathwayStep?: PathwayStep
  ) {
    if (isEditingContentLocally && pathwayStep) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in ArticlePathwaysPlansFacade');
    }
    this.articleNotificationService.notifyArticleInputCreateFailed();
  }
}
