<div class="abs-center center-text absolute">
  <ng-content *ngIf="!isComplete"></ng-content>
  <ng-container *ngIf="isComplete && config.checkmark">
    <df-icon
      icon="checkmark"
      [size]="config.checkmark"
      [style.color]="config.color"
      [a11yText]="'Core_Completed' | translate"
    ></df-icon>
  </ng-container>
</div>
<!-- round-progress is from third party npm package
     called angular-svg-round-progressbar -->
<round-progress
  [attr.aria-label]="i18n.Core_Completion_Progress"
  [current]="percent"
  [max]="100"
  [stroke]="config.stroke"
  [radius]="config.radius"
  [color]="config.color"
  [background]="config.bgcolor"
  [duration]="config.duration"
  [responsive]="config.responsive"
  [style.padding]="config.padding + 'px'"
></round-progress>
