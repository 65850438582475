import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { AuthService } from '@app/shared/services';
import { InputsService } from '@app/inputs/services/inputs.service';
import { VideoFacadeBase } from '../video-facade-base.service';
import { VideoMapperService } from '../video-mapper.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';

// misc
import {
  VideoApiInput,
  VideoApiInputEdit,
  VideoModel,
} from '@app/user-content/user-input-v2/inputs/video/video.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { FormGroup } from '@angular/forms';
import { VideoService } from '../video.service';
import { MediaMetadataStatus } from '@app/user-content/user-input-v2/services/inputs-facade-base';
import { MediaParseType } from '@app/shared/models/core.enums';
import { VideoNotificationService } from '../video-notification.service';
import { VideoTrackerService } from '../video-tracker.service';
import { PathwayNode, PathwayStep } from '@dg/pathways-rsm';
import { SubmissionStatus } from '@app/inputs/inputs.model';

@Injectable({ providedIn: 'root' })
export class VideoPathwaysPlansInputFacade extends VideoFacadeBase {
  constructor(
    public inputsService: InputsService,
    public authService: AuthService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public videoMapperService: VideoMapperService,
    public videoService: VideoService,
    private videoNotificationService: VideoNotificationService,
    private videoTrackerService: VideoTrackerService
  ) {
    super(
      inputsService,
      authService,
      inputImageUploadAdapterService,
      orgInternalContentService,
      videoMapperService,
      videoService
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] VideoModel
   * ...
   */
  public get snapshot(): VideoModel {
    return readFirst(this.viewModel$);
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

      // Update the view model with the url
      this.viewModel = { ...this.viewModel, entryUrl: url };
      // Parse url (diffbot)
      const result: VideoApiInput =
        (await this.inputsService.getMediaMetadataAsPromise(
          url,
          this.viewModel.inputContext.inputType,
          MediaParseType.None
        )) as VideoApiInput;

      // Update the viewModel with the results
      const updatedView = this.videoMapperService.toViewModel(
        result,
        this.viewModel
      );

      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
        owner: undefined,
      };

      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      return;
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
    return;
  }

  /**
   * Override onSubmit if submitting video edits locally/for the pathway/plan only
   * @param form
   * @param isEditingContentLocally
   * @param pathwayStep
   */
  public async onSubmit(
    form: FormGroup,
    isEditingContentLocally: boolean,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    try {
      if (isEditingContentLocally && pathwayStep) {
        const formData = form.value;
        // update the View model with the form data
        super.updateViewWithFormData(formData);

        this.submissionStatus$.next(SubmissionStatus.Submitting);

        const updatedStepNode: PathwayNode = {
          ...pathwayStep,
          description: this.viewModel.summary,
          imageUrl: this.viewModel.imageUrl,
          title: this.viewModel.title,
        };
        await this.inputsService.updatePathwayNode(updatedStepNode);
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      } else {
        await super.onSubmit(form);
      }
      this.performSuccessSideEffects();
    } catch {
      this.performFailureSideEffects(isEditingContentLocally, pathwayStep);
    }
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: !!this.viewModel.inputContext.isCmsContent,
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: !!this.viewModel.inputContext.isCmsContent,
      },
    };
  }

  /**
   * Override initializeEdit if editing video locally/for the pathway/plan only
   * @param isEditingInternalContent
   * @param pathwayStep
   */
  public async initializeEdit(
    isEditingContentLocally?: boolean,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    if (isEditingContentLocally && pathwayStep) {
      const inputOwner = await this.inputsService.getInputOwner(
        pathwayStep.reference.primaryContactResourceId
      );
      // TODO: If we add the ability to locally edit input properties other than title, summary, and imageUrl
      // Then, we will need to update the properties below to get their values directly from pathwayStep rather than pathwayStep.reference
      const editEntry: VideoApiInputEdit = {
        entryUrl: pathwayStep.reference.url,
        title: pathwayStep.reference.title,
        sourceName: pathwayStep.reference.source,
        summary: pathwayStep.reference.summary,
        durationHours: pathwayStep.reference.durationHours,
        durationMinutes: pathwayStep.reference.durationMinutes,
        primaryContactResourceId:
          pathwayStep.reference.primaryContactResourceId,
        primaryContactResourceType:
          pathwayStep.reference.primaryContactResourceType,
        owner: inputOwner,
        imageUrl: pathwayStep.reference.imageUrl,
      } as unknown as VideoApiInputEdit;

      // Map response to view model
      const updatedView = this.videoMapperService.toViewModel(
        editEntry,
        this.viewModel
      );
      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
      };
      return;
    }
    await super.initializeEdit();
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.videoNotificationService.notifyVideoInputCreated(
        this.viewModel.title
      );

      this.videoTrackerService.trackContentSkillInferred(this.viewModel);
    }

    if (this.viewModel.orgContentMetadata.hideFromCatalog) {
      const apiParameters = this.videoMapperService.toApiParameters(
        this.viewModel as VideoModel
      );

      this.videoNotificationService.notifyVideoInputUpdated();
      this.videoTrackerService.trackContentCatalogUpdate(apiParameters);
    }
  }

  /** Performs any side effects required following failed creation/update of a Video */
  protected performFailureSideEffects(
    isEditingContentLocally?: boolean,
    pathwayStep?: PathwayStep
  ) {
    if (isEditingContentLocally && pathwayStep) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in VideoFacadeBase');
    }
    this.videoNotificationService.notifyVideoInputCreateFailed();
  }
}
