<div
  [ngSwitch]="type"
  class="skeleton-container"
  [attr.aria-label]="i18n.Core_Loading"
  role="img"
>
  <div *ngSwitchCase="'card'">
    <div class="skeleton" [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'basic'">
        <div class="l_flex l_flex-middle guts-m-b-2">
          <div
            class="skeleton__circle bg-animate m-guts-m-b-1 guts-m-r-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-9 guts-p-full-0"
          ></div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-7
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            grid grid--bleed grid--direction-column grid--no-wrap
            guts-m-b-2
          "
        >
          <div
            class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-1"
          ></div>
          <div class="skeleton__line bg-animate grid__col-xs-9"></div>
        </div>
        <div class="grid grid--bleed guts-m-b-1">
          <div class="skeleton__line bg-animate grid__col-xs-3"></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'video'">
        <div class="l_flex l_flex-middle guts-m-b-2">
          <div
            class="skeleton__circle bg-animate m-guts-m-b-1 guts-m-r-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-9 guts-p-full-0"
          ></div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-7
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div class="guts-m-b-2">
          <div
            class="
              skeleton__block
              bg-animate
              skeleton__block--large
              rounded--large
            "
          ></div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-9
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div class="grid grid--bleed guts-m-b-1">
          <div class="skeleton__line bg-animate grid__col-xs-3"></div></div
      ></ng-container>

      <ng-container *ngSwitchCase="'image'">
        <div class="l_flex l_flex-middle guts-m-b-2">
          <div
            class="skeleton__circle bg-animate m-guts-m-b-1 guts-m-r-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-9 guts-p-full-0"
          ></div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-7
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div class="l_flex guts-m-b-2">
          <div
            class="
              skeleton__block
              bg-animate
              skeleton__block--small
              grid__col-xs-4
              guts-m-r-1
            "
          ></div>
          <div
            class="
              grid grid--bleed grid--direction-column grid--no-wrap
              guts-m-b-2
            "
          >
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-7 guts-m-b-2"
            ></div>
            <div class="skeleton__line bg-animate grid__col-xs-4"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'no-meta'"
        ><div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-7
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div class="l_flex guts-m-b-2">
          <div
            class="
              skeleton__block
              bg-animate
              skeleton__block--small
              grid__col-xs-4
              guts-m-r-1
            "
          ></div>
          <div
            class="
              grid grid--bleed grid--direction-column grid--no-wrap
              guts-m-b-2
            "
          >
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-7 guts-m-b-2"
            ></div>
            <div class="skeleton__line bg-animate grid__col-xs-4"></div>
          </div></div
      ></ng-container>
    </div>
  </div>

  <div *ngSwitchCase="'post'">
    <div class="skeleton">
      <div class="l_flex l_flex-middle guts-m-b-2">
        <div class="skeleton__circle bg-animate m-guts-m-b-1 guts-m-r-1"></div>
        <div
          class="skeleton__line bg-animate grid__col-xs-9 guts-p-full-0"
        ></div>
      </div>
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-7
          guts-m-b-2 guts-p-full-0
        "
      ></div>
      <div class="l_flex guts-m-b-2">
        <div
          class="
            grid grid--bleed grid--direction-column grid--no-wrap
            guts-m-b-2
          "
        >
          <div
            class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-10 guts-m-b-1"
          ></div>
          <div
            class="skeleton__line bg-animate grid__col-xs-7 guts-m-b-2"
          ></div>
          <div class="skeleton__line bg-animate grid__col-xs-4"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'tile'">
    <ng-container [ngSwitch]="layout">
      <div
        class="
          skeleton skeleton--tile
          border border-animate
          rounded--large
          oh
          guts-p-h-1 guts-p-t-1
        "
        *ngSwitchCase="'basic'"
      >
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-2
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          id="testId"
          class="
            skeleton__line
            bg-animate
            grid__col-xs-9
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-6
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-7
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-4
            guts-m-b-2 guts-p-full-0
          "
        ></div>
      </div>

      <div
        class="skeleton skeleton--tile border border-animate rounded--large oh"
        *ngSwitchCase="'video'"
      >
        <div class="guts-p-full-1">
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-2
              guts-m-b-2 guts-p-full-0
            "
          ></div>
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-9
              guts-m-b-1 guts-p-full-0
            "
          ></div>
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-6
              guts-m-b-1 guts-p-full-0
            "
          ></div>
        </div>
        <div class="guts-m-b-2">
          <div
            class="
              skeleton--tile__block
              bg-animate
              skeleton--tile__block--large
            "
          ></div>
        </div>
        <div class="guts-p-h-1 guts-p-b-1">
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-4
              guts-m-b-1 guts-p-full-0
            "
          ></div>
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-3
              guts-m-b-2 guts-p-full-0
            "
          ></div>
        </div>
      </div>

      <div
        class="
          skeleton skeleton--tile
          border border-animate
          rounded--large
          oh
          guts-p-full-1
        "
        *ngSwitchCase="'image'"
      >
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-2
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-9
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-6
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div class="l_flex">
          <div
            class="
              skeleton__block
              bg-animate
              skeleton--tile__block skeleton--tile__block--small
              grid__col-xs-5
              guts-m-r-1
            "
          ></div>
          <div
            class="
              grid--bleed
              grid
              grid__col-xs-7
              grid--direction-column
              grid--no-wrap
              guts-p-full-0
            "
          >
            <div
              class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"
            ></div>
          </div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-11
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-11
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-6
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="skeleton__line bg-animate grid__col-xs-3 guts-p-full-0"
        ></div>
      </div>

      <div
        class="
          skeleton skeleton--provider skeleton--provider-tile
          border border-animate
          rounded--large
          oh
          guts-p-full-2
        "
        *ngSwitchCase="'provider'"
      >
        <div
          class="
            skeleton__circle
            bg-animate
            skeleton__circle--large
            center-item
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            center-item
            grid__col-xs-6
            guts-p-full-0 guts-m-t-1 guts-m-b-1-half
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            center-item
            grid__col-xs-6
            guts-p-full-1-half
          "
        ></div>
      </div>
    </ng-container>
  </div>

  <div
    *ngSwitchCase="'tag'"
    class="
      skeleton skeleton--tag
      border border-animate
      guts-p-h-1 guts-p-t-1
      rounded--large
      center-text
    "
  >
    <div class="guts-m-v-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74"
        height="74"
        viewBox="0 0 74 74"
      >
        <rect fill="transparent" width="74" height="74" rx="0" ry="0" />
        <path fill="none" d="M0 0h74v74H0z" />
        <path fill="none" d="M0 0h74v74H0z" />
        <g class="highcharts-series-group">
          <g
            class="highcharts-series highcharts-series-0 highcharts-pie-series"
            [attr.fill]="skeletonColor"
            stroke="#fff"
            stroke-width="3"
            stroke-linejoin="round"
          >
            <path
              d="M37 0a37 37 0 0 1 26.15 10.82l-4.95 4.95A30 30 0 0 0 37 7z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M63.17 10.85A37 37 0 0 1 74 36.95l-7 .02a30 30 0 0 0-8.78-21.17z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M74 37a37 37 0 0 1-10.8 26.13l-4.96-4.95A30 30 0 0 0 67 37z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M63.17 63.15A37 37 0 0 1 37.03 74v-7a30 30 0 0 0 21.2-8.8z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M37 74a37 37 0 0 1-26.13-10.8l4.94-4.96A30 30 0 0 0 37 67z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M10.84 63.17A37 37 0 0 1 0 37.02h7a30 30 0 0 0 8.8 21.2z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M0 36.98a37 37 0 0 1 10.8-26.12l4.97 4.95A30 30 0 0 0 7 37z"
              class="skeleton--tag__segment fill-animate"
            />
            <path
              d="M10.84 10.84A37 37 0 0 1 36.94 0l.02 7a30 30 0 0 0-21.17 8.8z"
              class="skeleton--tag__segment fill-animate"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="grid grid--justify-center">
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-8
          guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div class="skeleton__line bg-animate grid__col-xs-5 guts-p-full-0"></div>
    </div>
  </div>

  <div *ngSwitchCase="'input-card'">
    <div class="box box guts-m-b-1 guts-p-full-2">
      <div class="l_flex l_flex-justify-center m-l_flex m-l_flex-wrap-reverse">
        <div class="l_flex-column l_flex-grow guts-m-r-2 m-no-guts">
          <div class="l_flex guts-m-b-1">
            <div
              class="
                skeleton__line--thin
                skeleton--input-card__line--thin
                bg-animate
                guts-m-r-2
              "
            ></div>
            <div
              class="
                skeleton__line--thin
                skeleton--input-card__line--thin
                bg-animate
                guts-m-r-2
              "
            ></div>
            <div
              class="
                skeleton__line--thin
                skeleton--input-card__line--thin
                bg-animate
              "
            ></div>
          </div>
          <div class="skeleton__line bg-animate guts-m-b-1"></div>
          <div class="skeleton__line bg-animate guts-m-b-half"></div>
          <div class="skeleton__line bg-animate"></div>
        </div>
        <div class="l_flex-bottom">
          <div class="skeleton--input-card__block bg-animate"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'search-result-card'">
    <div class="guts-p-v-1">
      <div class="guts-m-t-1 guts-m-b-1-quart guts-m-r-2">
        <div class="l_flexbar">
          <div class="l_flex l_flex-center">
            <div class="skeleton--search-result-card__block bg-animate"></div>
          </div>
          <div class="l_flex-column l_flex-grow guts-m-l-2">
            <div class="l_flexbar guts-m-b-half">
              <div
                class="skeleton--search-result-card__line--meta bg-animate"
              ></div>
              <div
                class="
                  skeleton--search-result-card__line--meta
                  guts-m-l-1-half
                  bg-animate
                "
              ></div>
            </div>
            <div
              class="
                skeleton--search-result-card__line--thick
                full-width
                guts-m-b-half
                bg-animate
              "
            ></div>
            <div
              class="
                skeleton--search-result-card__line--thin
                full-width
                guts-m-b-half
                bg-animate
              "
            ></div>
            <div
              class="
                skeleton--search-result-card__line--thin
                skeleton--search-result-card__line--thin-short
                bg-animate
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'search-filter-sidebar'">
    <div class="l_flex-column l_flex-grow guts-m-t-1-half">
      <div *ngFor="let _ of [].constructor(4)" data-dgat="skeleton-110">
        <div
          class="
            skeleton--search-results-sidebar__line--thin
            full-width
            guts-m-b-1
            bg-animate
          "
        ></div>
        <div
          class="
            skeleton--search-results-sidebar__line--thin
            skeleton--search-results-sidebar__line--thin-short
            guts-m-b-1
            bg-animate
          "
        ></div>
      </div>
      <div
        class="
          skeleton--search-results-sidebar__line--thin
          full-width
          bg-animate
        "
      ></div>
    </div>
  </div>

  <div *ngSwitchCase="'pathway'">
    <ng-container [ngSwitch]="layout">
      <div
        class="
          skeleton skeleton--pathway-tile
          border border-animate
          rounded--large
          oh
        "
        *ngSwitchCase="'tile'"
      >
        <div class="guts-m-b-1-half">
          <div class="skeleton--pathway-tile__block bg-animate"></div>
        </div>
        <div class="guts-p-h-1 guts-p-b-1">
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-11
              guts-m-b-1 guts-p-full-0
            "
          ></div>
          <div
            class="
              skeleton__line
              bg-animate
              grid__col-xs-8
              guts-m-b-2 guts-p-full-0
            "
          ></div>
        </div>
      </div>

      <div class="skeleton" *ngSwitchCase="'section'">
        <div class="grid">
          <div class="grid__col-sm-3">
            <div class="grid grid--bleed grid--direction-column grid--no-wrap">
              <div
                class="skeleton__line bg-animate grid__col-xs-4 guts-m-b-1-half"
              ></div>
              <div
                class="
                  skeleton__line
                  bg-animate
                  grid__col-xs-12
                  guts-p-v-1 guts-m-b-2
                "
              ></div>
              <div
                class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-half"
              ></div>
              <div
                class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-half"
              ></div>
              <div
                class="skeleton__line bg-animate grid__col-xs-12 guts-m-b-half"
              ></div>
            </div>
          </div>
          <div class="grid__col-sm-9 guts-p-l-1 m-guts-p-l-0">
            <div class="grid skeleton--pathway-subsection">
              <div class="grid__col-lg-8">
                <div class="l_flex guts-m-b-2 guts-p-full-2">
                  <div
                    class="
                      skeleton__block
                      skeleton--pathway-subsection__block
                      bg-animate
                      rounded--large
                      grid__col-xs-4
                      guts-m-r-2
                    "
                  ></div>
                  <div
                    class="
                      grid grid--bleed grid--direction-column grid--no-wrap
                    "
                  >
                    <div
                      class="
                        skeleton__line
                        bg-animate
                        grid__col-xs-4
                        guts-m-b-1-half
                      "
                    ></div>
                    <div
                      class="
                        skeleton__line
                        bg-animate
                        grid__col-xs-12
                        guts-p-b-1-half guts-m-b-1-half
                      "
                    ></div>
                    <div
                      class="
                        skeleton__line
                        bg-animate
                        grid__col-xs-12
                        guts-m-b-half
                      "
                    ></div>
                    <div
                      class="
                        skeleton__line
                        bg-animate
                        grid__col-xs-12
                        guts-m-b-half
                      "
                    ></div>
                    <div
                      class="
                        skeleton__line
                        bg-animate
                        grid__col-xs-12
                        guts-m-b-half
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="skeleton" *ngSwitchCase="'lesson'">
        <div class="grid skeleton--pathway-subsection">
          <div class="grid__col-lg-8">
            <div class="l_flex guts-m-b-2 guts-p-full-2">
              <div
                class="
                  skeleton__block
                  skeleton--pathway-subsection__block
                  bg-animate
                  grid__col-xs-4
                  guts-m-r-2
                "
              ></div>
              <div
                class="grid grid--bleed grid--direction-column grid--no-wrap"
              >
                <div
                  class="
                    skeleton__line
                    bg-animate
                    grid__col-xs-4
                    guts-m-b-1-half
                  "
                ></div>
                <div
                  class="
                    skeleton__line
                    bg-animate
                    grid__col-xs-12
                    guts-p-b-1-half guts-m-b-1-half
                  "
                ></div>
                <div
                  class="
                    skeleton__line
                    bg-animate
                    grid__col-xs-12
                    guts-m-b-half
                  "
                ></div>
                <div
                  class="
                    skeleton__line
                    bg-animate
                    grid__col-xs-12
                    guts-m-b-half
                  "
                ></div>
                <div
                  class="
                    skeleton__line
                    bg-animate
                    grid__col-xs-12
                    guts-m-b-half
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    *ngSwitchCase="'user'"
    class="skeleton skeleton--user border border-animate rounded--large"
  >
    <div class="guts-m-v-2">
      <div
        class="skeleton__circle bg-animate skeleton__circle--large center-item"
      ></div>
    </div>
    <div class="grid grid--justify-center guts-p-h-1 guts-p-b-1">
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-9
          guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-7
          guts-m-b-2 guts-p-full-0
        "
      ></div>
    </div>
  </div>

  <div *ngSwitchCase="'avatar'" class="skeleton skeleton--avatar">
    <div
      class="
        skeleton__circle skeleton__circle--medium
        bg-animate
        skeleton__circle
        center-item
      "
    ></div>
  </div>

  <div *ngSwitchCase="'avatarLarge'" class="skeleton skeleton--avatar">
    <div
      class="
        skeleton__circle skeleton__circle--large
        bg-animate
        skeleton__circle
        center-item
      "
    ></div>
  </div>

  <div
    *ngSwitchCase="'group'"
    class="
      skeleton skeleton--group
      border border-animate
      rounded--large
      center-text
    "
  >
    <div class="grid grid--justify-center guts-p-h-1 guts-p-t-2">
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-9
          guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div class="skeleton__line bg-animate grid__col-xs-7 guts-p-full-0"></div>
    </div>
    <div class="guts-m-v-2">
      <svg
        width="190"
        height="40"
        viewBox="0 0 190 40"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="a"
            cx="18"
            cy="18"
            r="18"
          />
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="b"
            cx="48"
            cy="18"
            r="18"
          />
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="c"
            cx="78"
            cy="18"
            r="18"
          />
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="d"
            cx="108"
            cy="18"
            r="18"
          />
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="e"
            cx="138"
            cy="18"
            r="18"
          />
          <circle
            class="fill-animate"
            [attr.fill]="skeletonColor"
            id="f"
            cx="168"
            cy="18"
            r="18"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="18"
              cy="18"
              r="19"
            />
          </g>
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="48"
              cy="18"
              r="19"
            />
          </g>
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="78"
              cy="18"
              r="19"
            />
          </g>
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="108"
              cy="18"
              r="19"
            />
          </g>
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="138"
              cy="18"
              r="19"
            />
          </g>
          <g transform="translate(2 2)">
            <circle
              class="fill-animate"
              [attr.fill]="skeletonColor"
              stroke="#FFF"
              stroke-width="2"
              cx="168"
              cy="18"
              r="19"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div
    *ngSwitchCase="'target'"
    class="
      skeleton skeleton--target
      border border-animate
      rounded--large
      oh
      center-text
    "
  >
    <div
      class="
        grid grid--direction-column grid--align-center
        guts-p-h-1 guts-p-t-2
      "
    >
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-9
          guts-m-b-1-half guts-p-full-0
        "
      ></div>
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-4
          guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div class="skeleton__line bg-animate grid__col-xs-7 guts-p-full-0"></div>
    </div>
  </div>

  <div
    *ngSwitchCase="'directory'"
    class="
      skeleton skeleton--directory
      border border-animate
      rounded--large
      oh
      center-text
    "
  >
    <div class="guts-m-b-1-half">
      <div class="skeleton--directory__block bg-animate"></div>
    </div>
    <div
      class="
        grid grid--direction-column grid--align-center
        guts-p-h-1 guts-p-t-2
      "
    >
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-9
          guts-m-b-1-half guts-p-full-0
        "
      ></div>
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-4
          guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div class="skeleton__line bg-animate grid__col-xs-7 guts-p-full-0"></div>
    </div>
  </div>

  <div
    *ngSwitchCase="'provider'"
    class="
      skeleton skeleton--provider
      border border-animate
      rounded--large
      oh
      center-text
    "
  >
    <div
      class="skeleton--provider__block bg-animate guts-m-b-1 guts-p-full-0"
    ></div>
    <div class="grid grid--direction-column grid--align-center guts-p-h-1">
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-4
          guts-m-b-1 guts-p-full-0
        "
      ></div>
    </div>
  </div>

  <div *ngSwitchCase="'hbarchart'" class="skeleton">
    <div class="grid grid--align-center">
      <div class="grid__col-2 grid__col--bleed">
        <div class="skeleton__line bg-animate guts-m-b-quart"></div>
        <div class="skeleton__line bg-animate guts-m-r-2"></div>
      </div>
      <div class="grid__col-10">
        <div class="skeleton__line skeleton__line--thin bg-animate"></div>
      </div>
    </div>
  </div>

  <div
    *ngSwitchCase="'kpi'"
    class="
      skeleton skeleton--kpi
      border border-animate
      rounded--large
      center-text
    "
  >
    <div
      class="
        grid grid--align-content-center grid--justify-center
        guts-p-full-1
        full-height
      "
    >
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-4
          guts-m-t-1 guts-m-b-1 guts-p-full-0
        "
      ></div>
      <div
        class="
          skeleton__line
          bg-animate
          grid__col-xs-9
          guts-m-b-1 guts-p-full-0
        "
      ></div>
    </div>
  </div>

  <div
    *ngSwitchCase="'dashboardNav'"
    class="skeleton skeleton--dashboard-nav l_flexbar"
  >
    <div class="guts-m-b-2 l_flex-top">
      <div
        class="
          skeleton__circle skeleton__circle--small
          bg-animate
          m-guts-m-b-1
          guts-m-r-1
        "
      ></div>
    </div>
    <div
      class="grid grid--bleed grid--direction-column grid--no-wrap guts-m-b-2"
    >
      <div class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"></div>
      <div class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"></div>
    </div>
  </div>

  <div
    *ngSwitchCase="'ratingButton'"
    class="
      skeleton skeleton--rating-button
      border border-animate
      rounded--large
      l_flexbar
      guts-m-t-1 guts-p-full-2
    "
  >
    <div class="guts-m-b-2 l_flex-top">
      <div
        class="
          skeleton__circle skeleton__circle
          bg-animate
          m-guts-m-b-1
          guts-m-r-1
        "
      ></div>
    </div>
    <div class="grid grid--bleed grid--direction-column grid--no-wrap">
      <div class="skeleton__line bg-animate grid__col-xs-4 guts-m-b-1"></div>
      <div class="skeleton__line bg-animate grid__col-xs-8 guts-m-b-1"></div>
      <div class="skeleton__line bg-animate grid__col-xs-6"></div>
    </div>
  </div>

  <div *ngSwitchCase="'table'" class="skeleton">
    <div
      *ngFor="let row of rowArray; let $first = first; let $last = last"
      data-dgat="skeleton-f0c"
      class="l_flexbar"
    >
      <div *ngIf="layout === 'image'">
        <div
          class="
            skeleton__circle skeleton__circle--small
            bg-animate
            m-guts-m-b-1
            guts-m-r-1
          "
        ></div>
      </div>
      <div class="grid grid--no-wrap">
        <div
          [ngStyle]="{ width: gridColWidthPercent }"
          class="grid__col-{{ gridColWidth }}"
          [ngClass]="{
            'grid__col--bleed guts-p-v-1': layout === 'thick',
            'guts-p-l-half': !$first && layout === 'thick',
            'guts-p-r-half': !$last && layout === 'thick'
          }"
          *ngFor="let col of colArray"
          data-dgat="skeleton-5f8"
        >
          <div
            class="skeleton__line"
            [ngClass]="{
              'skeleton__line--thick': layout === 'thick',
              'skeleton__line--medium': layout === 'medium'
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'permission'" class="skeleton guts-m-v-1">
    <div
      class="grid grid--bleed"
      *ngFor="let row of rowArray"
      data-dgat="skeleton-df6"
    >
      <div class="grid__col-sm-4">
        <div
          class="
            skeleton__line skeleton__line--thick
            bg-animate
            guts-m-r-1 guts-m-b-1
          "
        ></div>
      </div>
      <div
        *ngFor="let col of colArray; let $last = last"
        class="grid__col-sm-auto"
        data-dgat="skeleton-345"
      >
        <div
          class="skeleton__line skeleton__line--thick bg-animate guts-m-b-1"
          [ngClass]="{ 'guts-m-r-1': !$last }"
        ></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'list'" class="skeleton guts-m-t-1">
    <div *ngFor="let row of rowArray" data-dgat="skeleton-572">
      <div class="skeleton__line bg-animate guts-m-b-1"></div>
    </div>
  </div>

  <div *ngSwitchCase="'linechart'" class="skeleton skeleton--linechart">
    <div class="linechart-yAxis">
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
      <div class="skeleton__line bg-animate"></div>
    </div>
    <div class="linechart-container">
      <div class="linechart-body"></div>
      <div class="linechart-xAxis">
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
        <div class="skeleton__line bg-animate linechart-xAxis-label"></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'embeddedLearning'" class="skeleton">
    <div class="guts-p-h-half">
      <div class="grid grid--bleed grid--direction-column grid--no-wrap">
        <div class="skeleton__line bg-animate grid__col-xs-4 guts-m-b-1"></div>
        <div class="skeleton__line bg-animate grid__col-xs-8 guts-m-b-1"></div>
        <div class="skeleton__line bg-animate grid__col-xs-6 guts-m-b-1"></div>
      </div>
      <div class="skeleton--iframe bg-animate rounded--large"></div>
    </div>
  </div>

  <div *ngSwitchCase="'tagRating'" class="skeleton l_flexbar">
    <div class="l_flex-top">
      <div
        class="
          skeleton__circle skeleton__circle--small
          bg-animate
          m-guts-m-b-1
          guts-m-r-1
        "
      ></div>
    </div>
    <div class="grid grid--bleed grid--direction-column grid--no-wrap">
      <div class="skeleton__line bg-animate grid__col-xs-11 guts-m-b-1"></div>
      <div class="skeleton__line bg-animate grid__col-xs-11"></div>
    </div>
  </div>

  <div *ngSwitchCase="'formfield'" class="skeleton">
    <div class="skeleton__formfield bg-animate"></div>
  </div>

  <div *ngSwitchCase="'opportunity'" class="skeleton skeleton--opportunity">
    <div class="border border-animate rounded--large">
      <div class="grid grid--direction-column guts-p-h-1 guts-p-v-2">
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-9
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-4
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-10
            guts-m-b-2 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-3
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="skeleton__line bg-animate grid__col-xs-4 guts-p-full-0"
        ></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'search-filters'" class="skeleton--search-filters">
    <div class="l_flex">
      <div
        *ngFor="let column of [].constructor(columns)"
        class="skeleton__block bg-animate guts-m-r-1 rounded"
        data-dgat="skeleton-72f"
      ></div>
    </div>
  </div>

  <div *ngSwitchCase="'faq'">
    <div class="box guts-p-full-2">
      <div class="grid__col-12">
        <div class="skeleton__line bg-animate guts-m-b-1-half"></div>
        <div class="skeleton__line bg-animate guts-m-b-1"></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'spiderchart'" class="skeleton--spiderchart">
    <div
      class="skeleton__line bg-animate grid__col-2 guts-m-full-2 guts-p-full-0"
    ></div>
    <div class="grid l_flex">
      <div class="grid__col-7 oh">
        <div class="guts-p-l-12 guts-p-t-3 guts-p-b-5">
          <div class="chart bg-animate"></div>
        </div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-6
            guts-m-full-1 guts-p-full-0
          "
        ></div>
      </div>
      <div class="grid__col-1"></div>
      <div class="grid__col-3 guts-p-r-5-half guts-p-t-3">
        <div *ngFor="let i of [1, 2, 3, 4, 5]" data-dgat="skeleton-113">
          <div class="l_flex l_flex-middle guts-m-b-2">
            <div
              class="skeleton__circle bg-animate m-guts-m-b-1 guts-m-r-1"
            ></div>
            <div
              class="skeleton__line bg-animate grid__col-xs-9 guts-p-full-0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'flexEdView'" class="skeleton">
    <div class="grid grid--bleed guts-m-b-1">
      <div class="grid__col-6">
        <div
          class="skeleton__line bg-animate grid__col-lg-3 grid__col-sm-12"
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-lg-3 grid__col-sm-12
            guts-m-t-quart
          "
        ></div>
      </div>
      <div class="grid__col-6 grid--align-end guts-m-b-1">
        <div
          class="grid__col-3 skeleton__line bg-animate guts-m-b-1 guts-p-full-0"
        ></div>
      </div>
      <div
        class="grid guts-m-t-1 guts-p-b-1"
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]"
        data-dgat="skeleton-117"
      >
        <div class="grid__col-auto">
          <div class="skeleton__line bg-animate guts-m-r-4"></div>
        </div>
        <div class="grid__col-auto">
          <div class="skeleton__line bg-animate guts-m-r-4"></div>
        </div>
        <div class="grid__col-auto">
          <div class="skeleton__line bg-animate"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'title'" class="skeleton--title">
    <h1 class="h1 skeleton__line skeleton__line--thick bg-animate"></h1>
  </div>

  <div *ngSwitchCase="'mediumTitle'" class="skeleton--title-medium">
    <h1 class="h1 skeleton__line skeleton__line--medium bg-animate"></h1>
  </div>

  <div *ngSwitchCase="'search-input'">
    <div class="skeleton__line skeleton--search-input bg-animate"></div>
  </div>

  <div *ngSwitchCase="'userOutcome'">
    <div
      class="
        skeleton skeleton--user-outcome
        border border-animate
        rounded--large
        oh
        guts-p-h-1 guts-p-t-1
      "
    >
      <div class="guts-m-b-1-half">
        <div class="skeleton--pathway-tile__block bg-animate"></div>
      </div>

      <div class="guts-p-b-1">
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-5
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-12
            guts-m-b-1 guts-p-full-0
          "
        ></div>
        <div
          class="
            skeleton__line
            bg-animate
            grid__col-xs-11
            guts-m-b-1 guts-p-full-0
          "
        ></div>
      </div>
      <div class="grid l-flex-left">
        <div class="skeleton__oval bg-animate m-guts-m-b-1 guts-m-r-1"></div>
        <div class="skeleton__oval bg-animate m-guts-m-b-1 guts-m-r-1"></div>
        <div class="skeleton__oval bg-animate m-guts-m-b-1 guts-m-r-1"></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'experiencesTable'" class="skeleton--experiences-table">
    <div
      class="
        skeleton
        border border-animate
        rounded--large
        oh
        guts-p-h-1 guts-p-v-2
      "
    >
      <div
        *ngFor="let i of [1, 2, 3, 4, 5]"
        data-dgat="skeleton-2cf"
        class="guts-p-b-3 grid"
      >
        <div class="l_flex m-l_flex">
          <div
            class="
              skeleton__circle skeleton__circle--medium
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
              grid__col-md-1
            "
          ></div>

          <div class="grid__col-md-4 guts-p-full-0">
            <div
              class="
                h1
                skeleton__line skeleton__line--medium
                bg-animate
                grid__col-xs-12
                guts-m-b-half guts-p-full-0
              "
            ></div>
            <div
              class="
                skeleton__line
                bg-animate
                grid__col-xs-12
                guts-m-b-half guts-p-full-0
              "
            ></div>
          </div>
        </div>

        <div
          class="
            grid
            l_flex-justify-right
            grid__col-md-7
            l_flex-row
            guts-p-full-0
          "
        >
          <div
            class="
              skeleton__oval skeleton__oval--long
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
            "
          ></div>
          <div class="skeleton__oval bg-animate m-guts-m-b-1 guts-m-r-1"></div>
          <div class="skeleton__oval bg-animate m-guts-m-b-1 guts-m-r-1"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'skillsTable'" class="skeleton--skills-table">
    <div
      class="
        skeleton
        border border-animate
        rounded--large
        oh
        guts-p-h-1 guts-p-t-1
      "
    >
      <div class="grid l_flex-row">
        <div
          class="
            nav
            skeleton__line skeleton__line--medium
            bg-animate
            grid__col-xs-12
            guts-m-b-half guts-p-full-0 guts-m-r-1
          "
        ></div>
        <div
          class="
            nav
            skeleton__line skeleton__line--medium
            bg-animate
            grid__col-xs-12
            guts-m-b-half guts-p-full-0
          "
        ></div>
      </div>

      <div class="l_flex l_flex-row">
        <div class="grid__col-md-6">
          <svg
            width="245"
            height="245"
            viewBox="0 0 245 245"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Overview-Tab---Phase-3"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Profile---Loading"
                transform="translate(-96.000000, -443.000000)"
              >
                <g
                  id="Section---Skill-summary"
                  transform="translate(72.000000, 354.000000)"
                >
                  <g
                    id="DONUT-32-SIGNALS"
                    transform="translate(24.000000, 89.000000)"
                  >
                    <g id="Group-15">
                      <g id="Group-8" fill="#0F1F2C" fill-opacity="0.08">
                        <path
                          d="M122,0 C189.378739,0 244,54.6212605 244,122 C244,189.378739 189.378739,244 122,244 C54.6212605,244 0,189.378739 0,122 C0,54.6212605 54.6212605,0 122,0 Z M122,41.6303318 C77.6130579,41.6303318 41.6303318,77.6130579 41.6303318,122 C41.6303318,166.386942 77.6130579,202.369668 122,202.369668 C166.386942,202.369668 202.369668,166.386942 202.369668,122 C202.369668,77.6130579 166.386942,41.6303318 122,41.6303318 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                      <rect
                        id="Rectangle"
                        fill="#FFFFFF"
                        x="120.305556"
                        y="181.305556"
                        width="3.38888889"
                        height="62.6944444"
                      ></rect>
                      <rect
                        id="Rectangle"
                        fill="#FFFFFF"
                        transform="translate(212.694444, 122.347222) rotate(89.000000) translate(-212.694444, -122.347222) "
                        x="211"
                        y="91"
                        width="3.38888889"
                        height="62.6944444"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-3"
                        fill="#FFFFFF"
                        x="122"
                        y="3.97903932e-13"
                        width="3.38888889"
                        height="62.6944444"
                      ></rect>
                      <polygon
                        id="Rectangle-Copy-4"
                        fill="#FFFFFF"
                        transform="translate(191.472222, 56.763889) rotate(44.000000) translate(-191.472222, -56.763889) "
                        points="189.777778 25.4166667 193.166667 25.4166667 193.166667 88.1111111 189.777778 88.1111111"
                      ></polygon>
                      <polygon
                        id="Rectangle-Copy-4"
                        fill="#FFFFFF"
                        transform="translate(189.694444, 197.347222) rotate(-44.000000) translate(-189.694444, -197.347222) "
                        points="188 166 191.388889 166 191.388889 228.694444 188 228.694444"
                      ></polygon>
                      <polygon
                        id="Rectangle-Copy-4"
                        fill="#FFFFFF"
                        transform="translate(54.694444, 197.347222) scale(-1, 1) rotate(-44.000000) translate(-54.694444, -197.347222) "
                        points="53 166 56.3888889 166 56.3888889 228.694444 53 228.694444"
                      ></polygon>
                    </g>
                    <rect
                      id="Rectangle"
                      fill-opacity="0.08"
                      fill="#0F1F2C"
                      x="106"
                      y="102"
                      width="32"
                      height="18"
                      rx="3"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-6"
                      fill-opacity="0.08"
                      fill="#0F1F2C"
                      x="93"
                      y="132"
                      width="58"
                      height="12"
                      rx="3"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="grid__col-md-6 l_flex-middle guts-m-r-6 no-mobile">
          <svg
            width="400"
            height="245"
            viewBox="0 0 400 245"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <rect
                id="path-1"
                x="0"
                y="0"
                width="410"
                height="251"
                rx="3"
              ></rect>
            </defs>
            <g
              id="Overview-Tab---Phase-3"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Profile---Loading"
                transform="translate(-409.000000, -430.000000)"
              >
                <g
                  id="Section---Skill-summary"
                  transform="translate(72.000000, 354.000000)"
                >
                  <g id="Mask" transform="translate(337.000000, 76.000000)">
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                    </mask>
                    <g></g>
                    <polygon
                      id="Path"
                      fill-opacity="0.08"
                      fill="#0F1F2C"
                      mask="url(#mask-2)"
                      points="0 191 164 150 281 71 410 41 410 251 0 251"
                    ></polygon>
                    <polygon
                      id="Path"
                      fill-opacity="0.04"
                      fill="#0F1F2C"
                      mask="url(#mask-2)"
                      points="0 237 122 194 281 129 410 91 410 251 0 251"
                    ></polygon>
                    <polygon
                      id="Path-4"
                      fill-opacity="0.03"
                      fill="#0F1F2C"
                      mask="url(#mask-2)"
                      points="0 251 91 221 196 204 270 162 410 131 410 251"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div
        *ngFor="let i of [1, 2, 3, 4, 5]"
        data-dgat="skeleton-089"
        class="guts-p-b-3 grid grid--align-center"
      >
        <div
          class="
            l_flex-justify-left
            grid__col-md-3
            l_flex-row l_flex-center-children
          "
        >
          <div
            class="
              skeleton__square skeleton__square--small
              rounded
              bg-animate
              grid__col-md-1
              guts-m-r-1 guts-p-full-half
            "
          ></div>

          <div class="grid__col-md-2 guts-p-full-0 l_flex-justify-center">
            <div
              class="
                skeleton__line skeleton__line--medium
                bg-animate
                grid__col-xs-12
                guts-p-full-0
              "
            ></div>
          </div>
        </div>

        <div class="grid l_flex-justify-right grid__col-md-9 l_flex-row">
          <div
            class="
              skeleton__circle skeleton__circle--medium
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
              l_flex-center
            "
          ></div>
          <div
            class="
              skeleton__oval
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
              l_flex-center
            "
          ></div>
          <div
            class="
              skeleton__oval skeleton__oval--long
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
              l_flex-center
            "
          ></div>

          <div
            class="
              skeleton__oval
              bg-animate
              m-guts-m-b-1
              guts-m-r-1
              l_flex-center
            "
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'buildYourProfile'" class="skeleton--build-your-profile">
    <div class="guts-p-b-6">
      <div
        class="
          h1
          skeleton__line skeleton__line--medium
          bg-animate
          guts-m-b-half guts-p-full-0
        "
      ></div>

      <div
        class="
          skill-suggestions
          skeleton__line skeleton__line--medium
          bg-animate
          guts-m-b-half guts-p-full-0
        "
      ></div>
    </div>
    <div
      *ngFor="let i of [1, 2, 3, 4, 5]"
      class="guts-p-b-3 l_flex"
      data-dgat="skeleton-bd1"
    >
      <div class="l_flex l_flex_row">
        <div
          class="
            skeleton__circle skeleton__circle--small
            bg-animate
            m-guts-m-b-1
            guts-m-r-1
          "
        ></div>

        <div
          class="
            skeleton__line skeleton__line--medium
            bg-animate
            guts-m-b-half guts-p-full-0
          "
        ></div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'signalPageHeader'" class="skeleton--signal-page-header">
    <h1
      class="h1 skeleton__line skeleton__line--medium bg-animate guts-m-b-2"
    ></h1>

    <div class="l_flex m-l_flex guts-m-b-half">
      <h1
        class="h1 page-title skeleton__line skeleton__line--medium bg-animate"
      ></h1>
      <div class="l_flex m-l_flex guts-m-l-auto">
        <div
          class="skeleton__square bg-animate rounded--large guts-m-r-3-quart"
        ></div>
        <div class="skeleton__square bg-animate rounded--large"></div>
      </div>
    </div>

    <h1 class="h1 skeleton__line skeleton__line--medium bg-animate"></h1>

    <h1
      class="h1 nav skeleton__line skeleton__line--medium bg-animate guts-m-b-1"
    ></h1>
  </div>

  <div *ngSwitchCase="'barChart'" class="skeleton--bar-chart">
    <div class="bg-animate rounded--large">
      <h1
        class="
          h1
          skeleton__line skeleton__line--regular
          bg-animate
          guts-h-3-quart guts-m-t-0 guts-m-r-2 guts-m-b-2-half guts-m-l-2
        "
      ></h1>
      <div class="l_flex m-l_flex">
        <h1
          *ngFor="let i of [1, 2, 3, 4, 5, 6]"
          class="
            h1
            barChartLine
            skeleton__line skeleton__line--regular
            bg-animate
            guts-m-l-2
            m-guts-m-l-half
          "
          data-dgat="skeleton-6a1"
        ></h1>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'horizontalBlock'" class="skeleton--horizontal-block">
    <div
      class="skeleton__block skeleton__block--medium bg-animate rounded--large"
    ></div>
  </div>

  <div
    *ngSwitchCase="'smallHorizontalBlock'"
    class="skeleton--small-horizontal-block"
  >
    <div
      class="skeleton__block skeleton__block--small bg-animate rounded--large"
    ></div>
  </div>

  <div
    *ngSwitchCase="'largeHorizontalBlock'"
    class="skeleton--large-horizontal-block"
  >
    <div class="guts-m-b-1-half">
      <div
        class="skeleton__block skeleton__block--large bg-animate rounded--large"
      ></div>
    </div>
  </div>

  <div *ngSwitchCase="'verticalBlock'" class="skeleton--vertical-block">
    <div
      class="
        skeleton__block skeleton__block-vertical--medium
        bg-animate
        rounded--large
      "
    ></div>
  </div>

  <div
    *ngSwitchCase="'smallVerticalBlock'"
    class="skeleton--large-vertical-block"
  >
    <div
      class="
        skeleton__block skeleton__block-vertical--small
        bg-animate
        rounded--large
      "
    ></div>
  </div>

  <div
    *ngSwitchCase="'largeVerticalBlock'"
    class="skeleton--large-vertical-block"
  >
    <div
      class="
        skeleton__block skeleton__block-vertical--large
        bg-animate
        rounded--large
      "
    ></div>
  </div>

  <div *ngSwitchCase="'breadcrumb'" class="skeleton--breadcrumb">
    <h1 class="h1 bg-animate"></h1>
  </div>
</div>
