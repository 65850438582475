import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

const APP_ROUTES = [
  {
    path: 'account',
    loadChildren: () =>
      import('../account/account-layout.module').then(
        (m) => m.AccountLayoutModule
      ),
  },
  {
    path: 'orgs',
    loadChildren: () => import('../orgs/orgs.module').then((m) => m.OrgsModule),
  },
  {
    path: 'channel',
    loadChildren: () =>
      import('../channel/channel.module').then((m) => m.ChannelModule),
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('../analytics/analytics.module').then((m) => m.AnalyticsModule),
  },
  {
    path: 'degreed-button/app',
    loadChildren: () =>
      import('../browser-extension/browser-extension.module').then(
        (m) => m.BrowserExtensionModule
      ),
  },
  {
    path: 'degreed-ms-teams/app',
    loadChildren: () =>
      import('../ms-teams/ms-teams.module').then((m) => {
        return m.MsTeamsModule;
      }),
  },
  {
    path: '',
    loadChildren: () =>
      import('../learner-experience/learner-experience.module').then(
        (m) => m.LearnerExperienceModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../error-handling/error-handling.module').then((m) => {
        return m.ErrorHandlingModule;
      }),
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
