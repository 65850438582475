import { Params } from '@angular/router';
import { isString } from '@ngneat/elf';
import {
  AppliedSearchFacet,
  QueryUrlParams,
  SearchState,
  ServiceUrlParams,
} from '../models/search.model';

/**
 * These params are tracked by state management. An error will be thrown to notify
 * developers of new params that aren't added here as a reminder
 *
 * Note: keep in in sync w/QueryUrlParams & ServiceUrlParams
 * & see application in `verifyUrlParams` function
 */
export const KNOWN_URL_PARAMS = [
  'term',
  'pageNum',
  'orgId',
  'filters',
  'sort',
  'ignorePreferredLanguage',
  'isExternalCat',
  'isMarketplaceCat',
  'viewLearningId',
  'is_msteams',
];

/**
 * Map store state to search URL params
 *
 * Note: `ignorePreferredLanguage` and `is_msteams` are tracked in other services
 * so they're not available on the SearchState
 */
export function stateToUrlParams(
  state: Partial<SearchState>
): Partial<QueryUrlParams> {
  const {
    term,
    orgId,
    pagination,
    appliedFacets,
    sort,
    isExternalCat,
    isMarketplaceCat,
    viewLearningId,
  } = state;

  const params = {
    term,
    orgId,
    pageNum: pagination?.currentPage,
    filters: appliedFacetsToUrlFilters(appliedFacets),
    sort,
    isExternalCat,
    isMarketplaceCat,
    viewLearningId,
  };

  return params;
}

/**
 * Map search URL parameters to store state
 *
 * Note: `ignorePreferredLanguage` and `is_msteams` are tracked in other services
 * so they're not saved to the SearchState
 */
export function urlParamsToState(
  params: Partial<QueryUrlParams>
): Partial<SearchState> {
  const {
    term,
    orgId,
    pageNum: currentPage,
    filters,
    sort,
    isExternalCat,
    isMarketplaceCat,
    viewLearningId,
  } = params;

  return {
    term,
    orgId,
    appliedFacets: urlFiltersToAppliedFacets(filters),
    sort: sort || 'relevant',
    pagination: {
      currentPage,
    },
    isExternalCat,
    isMarketplaceCat,
    viewLearningId,
  };
}

/**
 * Coerce url param strings to correct types
 */
export function coerceUrlParams({
  term,
  pageNum,
  orgId,
  filters,
  sort,
  ignorePreferredLanguage,
  isExternalCat,
  isMarketplaceCat,
  viewLearningId,
  is_msteams,
}: Params): Partial<QueryUrlParams & ServiceUrlParams> {
  orgId = stringToInteger(orgId);
  pageNum = stringToInteger(pageNum);
  ignorePreferredLanguage = stringToBoolean(ignorePreferredLanguage);
  isExternalCat = stringToBoolean(isExternalCat);
  isMarketplaceCat = stringToBoolean(isMarketplaceCat);
  viewLearningId = stringToInteger(viewLearningId);
  is_msteams = stringToBoolean(is_msteams);

  return {
    term,
    pageNum,
    orgId,
    filters,
    sort,
    ignorePreferredLanguage,
    isExternalCat,
    isMarketplaceCat,
    viewLearningId,
    is_msteams,
  };
}

/**
 * Notify developer of new url parameters that are not being
 * propertly tracked by state management
 */
export function verifyUrlParams(params: Params) {
  Object.keys(params).forEach((param) => {
    if (!KNOWN_URL_PARAMS.includes(param)) {
      console.error(
        `WARNING: SearchBookmarkService is likely not tracking the "${param}" parameter`
      );
    }
  });
}

/**
 * Convert URL param string "integer" values to true integer
 */
export function stringToInteger(input: string): number | undefined {
  const value = parseInt(input, 10);
  return isNaN(value) ? undefined : value;
}

/**
 * Convert URL param string "boolean" values to true boolean
 */
export function stringToBoolean(input: string): boolean | undefined {
  const value = `${input}`.toLowerCase();
  return value === 'true' ? true : value === 'false' ? false : undefined;
}

/**
 * Convert convert URL `filter` string to array of facets for API
 *
 * Note that values are decoded
 *
 * @example
 * urlFiltersToAppliedFacets('Type:article;assessment|Location:Colorado%3B%7C%3A')
 * // returns
 * [
 *   {id: 'Type', name: 'Type', values: ['article', 'assessment']},
 *   {id: 'Location', name: 'Location', values: ['Colorado;|:']}
 * ]
 */
export function urlFiltersToAppliedFacets(
  filters: string
): AppliedSearchFacet[] {
  return filters
    ? filters.split('|').map((filter) => {
        const decodeValues = (values: string): string[] =>
          values.split(';').map(decodeURIComponent);
        const [id, values] = filter.split(':');
        return {
          id,
          name: id,
          values: decodeValues(values),
        };
      })
    : [];
}

/**
 * Convert facet array to a string for URL `filter` param
 *
 * Note that values are encoded
 *
 * @example
 * appliedFacetsToUrlFilters([
 *   {id: 'Type', name: 'Type', values: ['article', 'assessment']},
 *   {id: 'Location', name: 'Location', values: ['Colorado;|:']}
 * ])
 * // returns
 * 'Type:article;assessment|Location:Colorado%3B%7C%3A'
 */
export function appliedFacetsToUrlFilters(
  facets: AppliedSearchFacet[]
): string {
  const encodeValues = (values: string[] | number[]): string =>
    values.map(encodeURIComponent).join(';');
  const convertToParams = (facets: AppliedSearchFacet[]): string => {
    return facets
      .map(({ id, values }) => `${id}:${encodeValues(values)}`)
      .join('|');
  };

  return !Array.isArray(facets)
    ? undefined
    : facets.length > 0
    ? convertToParams(facets)
    : undefined;
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function coerce<T>(
  source: string | boolean | null | undefined,
  fallback: T
): T {
  if (isString(source)) {
    return (source as unknown as T) || fallback;
  } else if (isBoolean(source)) {
    return (source as unknown as T) || fallback;
  } else return fallback;
}
