<div class="guts-p-b-2">
  <!-- TODO: Will need to include [internalUrl] to link header to assigned item -->
  <dgx-modal-header
    [headingMetaConfig]="headingMetaConfig"
    [attr.aria-label]="title"
    (dismiss)="onClose()"
    tabindex="0"
    dgxAutofocus
    data-dgat="modal-header"
  >
    {{ title | ellipsis: titleEllipsisLimit }}
    <div
      class="par--small color-ebony-a61"
      [innerHTML]="recommendation.displayType"
    ></div>
  </dgx-modal-header>
  <div class="guts-p-h-2 guts-p-t-2 guts-p-b-0">
    <dgx-shared-with-user-list
      [assignedUsersInfo]="assignedUsersInfo"
      [isSkillCoach]="isSkillCoach"
      (loadUsers)="loadUsers($event)"
    ></dgx-shared-with-user-list>
  </div>
  <div
    *ngIf="showAnonymous"
    class="l_flexbar border-top guts-m-t-0 guts-m-h-2 guts-p-t-half guts-p-h-0"
  >
    <div class="guts-p-r-1">
      <ul class="social-pics social-pics-small">
        <li class="social-pic__item social-pic__item-small">
          <span
            class="social-pic__count social-pic__count-small par par--small"
          >
            +{{ assignedUsersInfo?.totalAnonymousUsers }}
          </span>
        </li>
      </ul>
    </div>

    <div
      class="l_flex-grow"
      [attr.aria-label]="i18n.Core_AnonymousLearners"
      tabindex="0"
    >
      <p class="par par--small font-medium">
        {{
          assignedUsersInfo?.totalAnonymousUsers > 1
            ? i18n.Core_AnonymousLearners
            : i18n.Core_AnonymousLearner
        }}
      </p>
    </div>
  </div>
</div>
