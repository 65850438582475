import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { Router } from '@angular/router';
import { AuthUser } from '@app/account/account-api.model';
import {
  InputIdentifier,
  InputNotification,
} from '@app/inputs/inputs-api.model';
import { InputActions } from '@app/inputs/inputs.enums';
import { TabNavigationService } from '@app/navigation/components/tab-navigation/tab-navigation.service';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import {
  Pathway,
  PathwayDetailsModel,
  PathwayPermissionsModel,
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
} from '@app/pathways/rsm/pathway-api.model';
import { AuthoringAction, PathwayLevel } from '@app/pathways/rsm/pathway.model';
import {
  ACTIONS,
  getTrackingParams,
  isFauxlike,
} from '@app/pathways/rsm/utils';
import { ProfilePathsService } from '@app/routing/services/profile-paths.service';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { NotifierService, TrackerService } from '@app/shared/services';
import { getDeepCopy } from '@app/shared/utils/property';
import { TranslateService } from '@ngx-translate/core';

export interface ProcessPathwayResults {
  pathway: Pathway;
  exclusionList: InputIdentifier[];
}

/*
 * Handles completion notifications: tracking + success message
 * @param command
 */
export function announceCompletion(
  command: AuthoringAction,
  tracker: TrackerService,
  notifier: NotifierService,
  translate: TranslateService
): void {
  const {
    track,
    trackBatch,
    message: { done, doneParams },
  } = command.notifications;
  if (track) {
    const params = getTrackingParams(track, command.payload);
    tracker.trackEventData(params);
  } else if (trackBatch) {
    tracker.trackBatchEvents(trackBatch);
  }
  if (done) {
    notifier.showSuccess(translate.instant(done, doneParams));
  }
}

export function preProcessPathway(
  pathway: Pathway,
  onMissingReference: (step: PathwayStep) => void
): ProcessPathwayResults {
  const exclusionList = [];
  for (const section of pathway.levels as PathwaySection[]) {
    section.totalLessons = section.lessons.length;
    for (const subsection of section.lessons as PathwaySubsection[]) {
      for (let i = subsection.steps.length - 1; i >= 0; i--) {
        const step: PathwayStep = subsection.steps[i];
        if (!step.complete && !pathway.nextStepToComplete) {
          pathway.nextStepToComplete = step.id;
          section.autoExpand = true;
          subsection.autoExpand = true;
        }
        if (!!step.reference) {
          step.edited = 0; // has been edited 0 times (helps trackby)
          step.reference = normalizeStepReference(step, pathway.id);

          // TODO: pass exclusion list to the Add to Pathway modal>search
          exclusionList.push({
            inputId: step.reference.inputId,
            inputType: step.reference.inputType,
          });
        } else {
          onMissingReference(step);
          subsection.steps.splice(i, 1);
        }
      }
      subsection.visibleSteps = [];
    }
  }
  pathway.privacyLevel = convertServerPrivacy(pathway.privacyLevel as any);

  return { pathway: setPathIds(pathway), exclusionList };
}

/**
 * Navigate the user to next screen after deletion of pathway
 * @param router
 * @param isChannel
 */
export function navigateFromDelete(
  router: Router,
  profilePathsService: ProfilePathsService,
  isChannel: boolean
): void {
  if (isChannel) {
    router.navigateByUrl('/channel/catalog/pathways');
  } else {
    const url = `/${profilePathsService.paths.vanityUrl}/dashboard/pathways`;
    router.navigateByUrl(url);
  }
}

/**
 * Added to fix the issue (https://degreedjira.atlassian.net/browse/PD-80654)
 * Currently BE logic is set so that non-admins can not add content to Tenant pathways.
 *
 * TODO: Work on a better solution for handling tenant pathway users who are collaborators
 * on the BE (maybe through the canAuthorPathway property).
 */
export function canAuthorPathway(
  pathway: Pathway,
  permissions: PathwayPermissionsModel,
  authUser: AuthUser
): boolean {
  // Default to true - Tenant org pathways will always have orgIds
  if (!pathway || Object.keys(pathway).length <= 0) return false;
  if (!pathway.organizationId) return true;

  const canAuthorPathway = permissions.canAuthorPathway;
  const firstOrgId = authUser.orgInfo[0].organizationId;
  const isTenantOrgPathway = firstOrgId !== pathway.organizationId;

  return isTenantOrgPathway ? canAuthorPathway : true;
}

/**
 * Calculate the scroll ID for the first section in that is in-progress
 * @param pathway
 * @returns scrollID string or empty string
 */
export function getFirstSectionInProgress(pathway: Pathway): string {
  if (isInProgress(pathway?.progress)) {
    for (const section of pathway.levels) {
      if (!section.complete) {
        for (const subsection of section.lessons) {
          if (!subsection.complete && hasAtLeastOneStep(subsection)) {
            return `section-${subsection.levelNumber}-${subsection.number}`;
          }
        }
      }
    }
  }
  return '';
}

/**
 * Provide aria-label to describe/voice section title numbering and progress(when present) due to styled section numbering & progress circle
 *
 * @param sectionNumber
 * @param sectionTitle
 * @param completionProgress
 */
export function sectionTitleToAriaLabel(
  sectionNumber: number,
  sectionTitle: string,
  completionProgress: string = ''
): string {
  return `${sectionNumber}. ${sectionTitle}. ${completionProgress}`;
}

/**
 * Get multiple indexes from a given node at once, returned in order of section/
 * subsection/step. Safer than relying on the `number` property, especially when
 * authoring.
 *
 * @param pathway - The pathway to check.
 * @param node - The node to parse.
 */
export function toIndexes(pathway: Pathway, node: string): number[] {
  // Must ensure parent node is not brand new, i.e., it has levels. Otherwise, return 0.
  const sectionIndex = !!pathway.levels
    ? toSectionIndex(pathway.levels, node)
    : 0;
  // Must ensure parent node is not brand new, e.g. it exists and has lessons. If there's
  // no children in our level, return 0. If, on the other hand, there's no section index
  // match (so we can't even find the right section to check for subsections)... return
  // undefined.
  const subsectionIndex =
    sectionIndex !== undefined
      ? !!pathway.levels[sectionIndex]?.lessons
        ? toSubsectionIndex(pathway.levels[sectionIndex].lessons, node)
        : 0
      : undefined;
  // Must ensure parent node is not brand new, e.g. it exists and has steps.  If there's
  // no children in our subsection(lesson), return 0. If, on the other hand, there's no subsection index
  // match (so we can't even find the right subsection to check for steps)... return undefined.
  const stepIndex =
    subsectionIndex !== undefined
      ? !!pathway.levels[sectionIndex]?.lessons[subsectionIndex]?.steps
        ? toStepIndex(
            pathway.levels[sectionIndex].lessons[subsectionIndex].steps,
            node
          )
        : 0
      : undefined;
  // Strip undefined values. (Zeroes are fine.)
  return [sectionIndex, subsectionIndex, stepIndex].filter(
    (index) => index !== undefined
  );
}

/**
 * Split a given node into an array of numbers. Returns an empty array if
 * node is undefined.
 *
 * @param node - The node to parse.
 */
export function toNumbers(node: string): number[] {
  return !node
    ? []
    : node
        .split('/')
        .filter((item) => item !== '')
        .map((num) => parseInt(num));
}

/**
 * Get a section's *index* from a given node. Returns undefined if node itself
 * is falsy, and length of sections if there is no current match for the provided
 * node number.
 *
 * @param sections - The sections array to check.
 * @param node - The node to parse.
 */
export function toSectionIndex(
  sections: PathwaySection[],
  node: string
): number {
  return !node
    ? undefined
    : toChildIndex<PathwaySection>(sections, node, toSectionNumber);
}

/**
 * Get a subsection's *index* from a given node. Returns undefined if node does not
 * contain a subsection value, and length of subsections if there is no current match
 * for the provided node number.
 *
 * @param subsections - The subsections array to check.
 * @param node - The node to parse.
 */
export function toSubsectionIndex(
  subsections: PathwaySubsection[],
  node: string
): number {
  return !node
    ? undefined
    : toChildIndex<PathwaySubsection>(subsections, node, toSubsectionNumber);
}

/**
 * Get a step's *index* from a given node. Returns undefined if node does not
 * contain a step value, and length of steps if there is no current match for the
 * provided node number.
 *
 * @param steps - The steps array to check.
 * @param node - The node to parse.
 */
export function toStepIndex(steps: PathwayStep[], node: string): number {
  return !node
    ? undefined
    : toChildIndex<PathwayStep>(steps, node, toStepNumber);
}

/**
 * Update a given step, possibly more than one, then return the updated pathway.
 *
 * @param input
 * @param pathway
 */
export function updateStep(
  input: InputNotification,
  pathway: PathwayDetailsModel
): PathwayDetailsModel {
  // If input.action is undefined, don't do anything else; just return
  // the original pathway.
  if (!input?.action) {
    return pathway;
  }

  // Peel off the properties.
  const { action, inputId, userInputId } = input;

  // Otherwise...
  let totalCompletedStepsChange = 0;
  let updatedIndex: number;
  let updatedPathway = getDeepCopy<PathwayDetailsModel>(pathway || {});
  let updatedNodes: { node: string; completedStepsChange: number }[] = [];

  // Loop through, updating pathway as appropriate.
  updatedPathway.levels.forEach((section) =>
    section.lessons.forEach((subsection) =>
      subsection.steps.forEach((step) => {
        // Skip to check the next step where our referenceId doesn't match.
        if (step.referenceId !== inputId) {
          return;
        }

        // Used to maintain complete/uncomplete state on resource cards.
        // Do this with *all* steps, optional or required.
        step.reference.userInputId = userInputId;

        // If this step isn't required, we can *now* skip to the next step.
        if (!step.isRequired) {
          return;
        }

        // Otherwise, we need to update our progress / completed steps.
        // Check our updates array if it isn't empty.
        updatedIndex = updatedNodes.length
          ? updatedNodes.findIndex(({ node }) => node === section.node)
          : -1;

        // Add a new entry for sections that don't yet exist...
        if (updatedIndex === -1) {
          updatedNodes.push({
            node: section.node,
            completedStepsChange: 1,
          });
        }
        // ...or increment the stepValueChange on an existing one.
        else {
          updatedNodes[updatedIndex].completedStepsChange += 1;
        }

        // Add a new entry for the subsection regardless.
        updatedNodes.push({
          node: subsection.node,
          completedStepsChange: 1,
        });

        // Finally, increment our *total* step value change for our pathway.
        ++totalCompletedStepsChange;
      })
    )
  );

  // Only update our pathway where the totalStepValueChange is greater than 0.
  if (totalCompletedStepsChange > 0) {
    updatedPathway = updateStepTotals(
      updatedPathway,
      updatedNodes,
      totalCompletedStepsChange,
      action === InputActions.completed
    );
  }

  // Return the updated pathway, which may or may not have been altered.
  return updatedPathway;
}

// ***************************
// Content Checks
// ***************************

/**
 * @see {PathwaySectionMetaDataComponent}
 *
 * @param subsections
 */
export function hasFauxUserSubsection(
  subsections: PathwaySubsection[]
): boolean {
  return isFauxlike(subsections);
}

/**
 * Check if a pathway has more than one section (level). Since we aren't
 * in authoring mode, we can check totalLevelsWithItems safely.
 *
 * @param pathway - Pathway to check.
 */
export function hasSections(pathway: Pathway): boolean {
  return pathway.totalLevelsWithItems > 1;
}

/**
 * Check if a section has more than one subsection (lesson). Since we aren't
 * in authoring mode, we can check totalLessonsWithItems safely.
 *
 * @param section - Section to check.
 */
export function hasSubsections(section: PathwaySection): boolean {
  return section.totalLessonsWithItems > 1;
}

/**
 * Whether a pathway has *at least* one subsection (lesson) with steps.
 *
 * @param pathway
 */
export function hasAtLeastOneSubsection(section: PathwaySection): boolean {
  return !!section.totalLessonsWithItems;
}

/**
 * Check if a pathway/section/subsection has exactly one step. Since we aren't
 * in authoring mode, we can check totalSteps safely.
 *
 * @param parent - Parent to check. Might be a pathway, subsection, or section, as all
 * have the `totalSteps` property and both are worth checking in different contexts.
 */
export function hasStep(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return parent?.totalSteps === 1;
}

/**
 * Check if a pathway/section/subsection has more than one step. Since we aren't
 * in authoring mode, we can check totalSteps safely.
 *
 * @param parent - Parent to check. Might be a pathway, subsection, or section, as all
 * have the `totalSteps` property and both are worth checking in different contexts.
 */
export function hasSteps(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return parent?.totalSteps > 1;
}

/**
 * Check if a pathway/section/subsection has *at least* one step. Since we aren't
 * in authoring mode, we can check totalSteps safely.
 *
 * @param parent - Parent to check. Might be a pathway, subsection, or section, as all
 * have the `totalSteps` property and both are worth checking in different contexts.
 */
export function hasAtLeastOneStep(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return !!parent?.totalSteps;
}

/**
 * Whether a given container has a title. Useful for determining whether to display
 * it in our navigation component, among other things.
 *
 * @param container
 */
export function isUntitled(
  container: PathwaySection | PathwaySubsection
): boolean {
  return !container?.title;
}

// ***************************
// Showing and Hiding
// ***************************

/**
 * Whether to show the nav component (user only).
 */
export function shouldShowNav(pathway: Pathway): boolean {
  return (
    hasSections(pathway) ||
    pathway.levels.some((section) => hasSubsections(section))
  );
}

/**
 * Whether to show a given section within the nav component (user only).
 *
 * @param section
 */
export function shouldShowNavSection(section: PathwaySection): boolean {
  return shouldShowSection(section);
}

/**
 * Whether to show a given subsection within the nav component (user only).
 *
 * @param section
 */
export function shouldShowNavSubsection(
  subsection: PathwaySubsection
): boolean {
  return shouldShowSubsection(subsection) && !isUntitled(subsection);
}

/**
 * Whether to show a given section's subsections within the nav component (user).
 *
 * @param section
 */
export function shouldShowNavSubsections(
  subsections: PathwaySubsection[]
): boolean {
  let subsectionsWithSteps = 0;

  // Array.some() stops as soon as it returns true,
  // so this is effectively a break call.
  subsections.some(({ totalSteps }) => {
    if (!!totalSteps) {
      ++subsectionsWithSteps;
    }
    return subsectionsWithSteps === 2;
  });

  return (
    subsectionsWithSteps > 1 ||
    subsections.some((subsection) => shouldShowNavSubsection(subsection))
  );
}

/**
 * Whether to show a given section on the main pathway (user only).
 *
 * @param section
 */
export function shouldShowSection(section: PathwaySection): boolean {
  return !!section.totalLessonsWithItems;
}

/**
 * Whether to show a given section on the main pathway (user only).
 *
 * @param section
 */
export function shouldShowSubsection(subsection: PathwaySubsection): boolean {
  return !!subsection.totalSteps;
}

// NOTE: Coming from the server the privacyLevel is always a string
// if something else gets passed in here it will return undefined
export function convertServerPrivacy(serverPrivacyString: string) {
  let clientPrivacyNumber;
  const pathwayPrivacyMap = {
    Author: Visibility.private,
    ProfileVisible: Visibility.profile,
    Group: Visibility.groups,
    Public: Visibility.public,
  };
  clientPrivacyNumber = pathwayPrivacyMap[serverPrivacyString];
  return clientPrivacyNumber;
}

/**
 * This return the tabs for the pathway view navigation
 * @returns TabNavigationItem[]
 */
export function getTabList(
  canViewEnrolleesTab: boolean,
  canViewInsightsTab: boolean,
  translate: TranslateService,
  tabNavigationService: TabNavigationService
): TabNavigationItem[] {
  const tabI18n = translate.instant([
    'Pathways_Pathway',
    'Pathways_Followers',
    'Pathways_Insights',
  ]);

  return tabNavigationService.formatTabs([
    {
      label: tabI18n.Pathways_Pathway,
      routerLink: 'pathway',
      dgat: 'pathwayLayout-7a6',
      queryParamsHandling: 'merge',
    },
    {
      label: tabI18n.Pathways_Followers,
      routerLink: 'enrollees',
      dgat: 'pathwayLayout-a8f',
      isAuthorized: canViewEnrolleesTab,
      queryParamsHandling: 'merge',
    },
    {
      label: tabI18n.Pathways_Insights,
      routerLink: 'insights',
      dgat: 'pathwayLayout-4f2',
      isAuthorized: canViewInsightsTab,
      queryParamsHandling: 'merge',
    },
    {
      label:
        'Assessment<sup class="assess-beta rounded border par par--small font-semibold">Beta</sup>',
      routerLink: 'assessments',
      dgat: 'pathwayLayout-4f2',
      isAuthorized: true,
      queryParamsHandling: 'merge',
    },
  ]);
}

/**
 * Creates the hero image source to display in the pathway header
 *
 * @param newImageUrl image to be updated with the source
 * @returns string of image src
 */
export function getHeroImageSrc(
  resourceImageService: ResourceImageService,
  thumbnailService: ThumbnailService,
  newImageUrl: string
): string {
  const { imageUrl, cropperCoordinates } =
    resourceImageService.parseImageUrl(newImageUrl);

  return cropperCoordinates
    ? thumbnailService.fetchProxyImageSrcset({
        imageSrc: imageUrl,
        imageHeight: 300, // height of the dgx-hero-image
        method: 'fetch',
        crop: 'crop',
        gravity: null,
        cropCoordinates: cropperCoordinates,
      })?.orig
    : imageUrl;
}

// ****************************
// Internal Utils
// ***************************

function normalizeStepReference(step: PathwayStep, pathwayId: number) {
  step.reference.pathwayStepDetails = {
    pathwayId,
    isOptional: !step.isRequired,
    node: step.node,
  };
  step.reference.hasUserDescription =
    !!step.description && step.description !== step.reference.summary;
  step.reference.title =
    typeof step.title !== 'undefined' && step.title !== null
      ? step.title
      : step.reference.title;
  step.reference.summary =
    typeof step.description !== 'undefined' && step.description !== null
      ? step.description
      : step.reference.summary;
  step.reference.imageUrl =
    typeof step.imageUrl !== 'undefined' && step.imageUrl !== null
      ? step.imageUrl
      : step.reference.imageUrl;
  return step.reference;
}

// Note: anything updated here should probably also be updated in the Degreed Button (ebb) version of PathwaySvc
function setPathIds(pathway: Pathway) {
  for (const section of pathway.levels as PathwaySection[]) {
    section.pathId = pathway.id;
    for (const subsection of section.lessons as PathwaySubsection[]) {
      subsection.pathId = pathway.id;
    }
  }
  return pathway;
}

// Check if the state is in progress
function isInProgress(progress): boolean {
  return progress > 0 && progress < 100;
}

/**
 * Increment or decrement step totals on pathway, section(s), and subsection(s), then return
 * updated pathway.
 *
 * @param pathway - Pathway to update. **Already a deep copy.**
 * @param updatedSteps - Array of step updates for section(s) and subsections(s).
 * @param totalStepValueChange - Number of steps to add or subtract from the whole pathway.
 * @param increment - Whether to increment or decrement the value.
 */
function updateStepTotals(
  pathway: PathwayDetailsModel,
  updatedNodes: {
    node: string;
    completedStepsChange: number;
  }[],
  totalStepValueChange: number,
  increment = false
): PathwayDetailsModel {
  // Swap method.
  const updateStepTotal = increment ? incrementStepTotal : decrementStepTotal;

  // Update Pathway Completed & Progress
  pathway = updateStepTotal<PathwayDetailsModel>(pathway, totalStepValueChange);

  // Update Sections/Subsections Completed & Progress
  // -- minimal updates!
  updatedNodes.forEach(({ node, completedStepsChange }) => {
    const [sectionIndex, subsectionIndex] = toIndexes(pathway, node);

    // sectionIndex will always be defined, but for section updates subsection won't be.
    if (subsectionIndex === undefined) {
      pathway.levels[sectionIndex] = updateStepTotal<PathwaySection>(
        pathway.levels[sectionIndex],
        completedStepsChange
      );
    } else {
      pathway.levels[sectionIndex].lessons[subsectionIndex] =
        updateStepTotal<PathwaySubsection>(
          pathway.levels[sectionIndex].lessons[subsectionIndex],
          completedStepsChange
        );
    }
  });

  // Return updated pathway
  return pathway;
}

/**
 * Increase the completed step count on a given pathway/section/subsection.
 *
 * @param parent - Pathway/section/subsection to update.
 */
function incrementStepTotal<
  T = PathwayDetailsModel | PathwaySection | PathwaySubsection
>(
  parent: PathwayDetailsModel | PathwaySection | PathwaySubsection,
  completedStepsChange = 1
): T {
  parent.completedSteps += completedStepsChange;
  return updateStepProgress<T>(parent);
}

/**
 * Reduce the completed step count on a given pathway/section/subsection.
 *
 * @param parent - Pathway/section/subsection to update.
 */
function decrementStepTotal<
  T = PathwayDetailsModel | PathwaySection | PathwaySubsection
>(
  parent: PathwayDetailsModel | PathwaySection | PathwaySubsection,
  completedStepsChange = 1
): T {
  parent.completedSteps -= completedStepsChange;
  // SANITY CHECK: Prevent negative numbers before we continue with our math.
  if (parent.completedSteps < 0) {
    parent.completedSteps = 0;
  }
  return updateStepProgress<T>(parent);
}

/**
 * Update the progress and complete values on a given pathway/section/subsection.
 *
 * @param parent - Pathway/section/subsection to update.
 */
function updateStepProgress<
  T = PathwayDetailsModel | PathwaySection | PathwaySubsection
>(parent: PathwayDetailsModel | PathwaySection | PathwaySubsection): T {
  parent.progress = Math.floor(
    (parent.completedSteps / (parent.totalSteps - parent.optionalSteps)) * 100
  );
  parent.complete = parent.progress === 100 ? true : false;
  return parent as unknown as T;
}

/**
 * For use with @see {toSectionIndex} @see {toSubsectionIndex} @see {toStepIndex}
 *
 * @param parent
 * @param node
 * @param iterator
 */
function toChildIndex<T extends { node: string }>(
  parent: T[],
  node: string,
  iterator: (node: string) => number
): number {
  // Return 0 if parent is falsy (it must be brand-new).
  if (!parent) {
    return 0;
  }
  const num = iterator(node);
  // If undefined at this point, return it
  if (num === undefined) {
    return num;
  }
  // Otherwise...
  const index = parent.findIndex((child) => iterator(child?.node) === num);
  // -1 return here = no match, so we can assume it's a *new* child
  // and return the legnth of our parent instead of the -1.
  return index !== -1 ? index : parent.length;
}

/**
 * Get a section's node segment as a number.
 *
 * @param node - The node to parse.
 */
function toSectionNumber(node: string): number {
  const numbers = toNumbers(node);
  return numbers.length > 0 ? toNumbers(node)[0] : undefined;
}

/**
 * Get a subsection's node segment as a number.
 *
 * @param node - The node to parse.
 */
function toSubsectionNumber(node: string): number {
  const numbers = toNumbers(node);
  return numbers.length > 1 ? toNumbers(node)[1] : undefined;
}

/**
 * Get a step's node segment as a number.
 *
 * @param node - The node to parse.
 */
function toStepNumber(node: string): number {
  const numbers = toNumbers(node);
  return numbers.length > 2 ? toNumbers(node)[2] : undefined;
}
