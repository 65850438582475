<ul *ngIf="!isLoading" class="guts-m-t-half">
  <li
    *ngFor="let tag of suggestedTags.slice(0, 10); trackBy: trackByTitle"
    class="ib"
    data-dgat="add-suggested-tags-modal-4f5"
  >
    <button
      df-button
      dfButtonType="passive"
      df-button-small
      (click)="addSuggestedTag(tag)"
      class="guts-m-r-half guts-m-b-half suggested-tag-button"
      [ngClass]="{ 'color-white bg-color-blue': isTagSelected(tag) }"
      [attr.aria-label]="
        'dgTagsEdit_AddTagFormat' | translate: { tag: tag.title }
      "
      data-dgat="add-suggested-tags-modal-061"
    >
      <div class="l_flexbar gap-full-half">
        <df-icon [icon]="isTagSelected(tag) ? 'checkmark' : 'plus'"></df-icon>
        <div>{{ tag.title | titlecase }}</div>
      </div>
    </button>
  </li>
</ul>
