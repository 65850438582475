import { Injectable } from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { CropperCoordinates } from '@app/uploader/image-cropper-modal/image-cropper-modal.model';
import { ImagePosition } from '@app/uploader/uploader-api.model';
import { WebEnvironmentService } from '../web-environment.service';
import { ResourceImage, ResourceImageJson } from './resource-image.model';

/**
 * Service to interact with resource images across the application.
 * The API will send back a JSON string in the imageUrl/pictureUrl representing a
 * {@see ResourceImage} when the `useResourceImages` is true on an API request.
 * The JSON object will contain crop data, an original image url, and a legacy image url.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceImageService {
  /** Transforms .NET tilde-style server URLS into rooted URLs */
  public static transformLocalResourceUrl(url: string) {
    if (url.lastIndexOf('~/', 0) === 0) {
      url = url.replace('~/', '/');
    }
    return url;
  }
  private readonly jsonRegex = new RegExp(/\{.*\:.*\}/);

  constructor(private webEnvironmentService: WebEnvironmentService) {}

  public isResourceImage(imageUrl: string): boolean {
    return this.jsonRegex.test(imageUrl);
  }

  /**
   * Parse an image url(string or JSON) into a {@see ResourceImage}
   *
   * @param imageUrl the imageUrl to parse
   * @param asHostUrl set true to interpret the URL as a resource hosted on our domain; false, to transform to a Blob URL. TODO: remove once https://degreedjira.atlassian.net/browse/PD-62342 is implemented
   */
  public parseImageUrl(imageUrl: string, asHostUrl = false): ResourceImage {
    if (this.isResourceImage(imageUrl)) {
      try {
        const parsedJson = JSON.parse(imageUrl);
        const { resourceImage, legacyPictureUrl }: ResourceImageJson =
          camelCaseKeys(Array.isArray(parsedJson) ? parsedJson[0] : parsedJson);

        const primaryCrop = resourceImage.imageProperties.crops.find(
          (crop) => crop.cropName === 'Primary'
        );
        const secondaryCrop = resourceImage.imageProperties.crops.find(
          (crop) => crop.cropName === 'Secondary'
        );
        const cropperCoordinates: CropperCoordinates = {
          width: primaryCrop.width,
          height: primaryCrop.height,
          pointX: primaryCrop.pointX,
          pointY: primaryCrop.pointY,
        };

        const position: ImagePosition =
          resourceImage.imageProperties.positionDetails?.position || 'Top';

        const altText = resourceImage.description || '';

        if (secondaryCrop) {
          cropperCoordinates.secondaryPointY = secondaryCrop.pointY;
          cropperCoordinates.secondaryHeight = secondaryCrop.height;
        }

        return {
          imageUrl: asHostUrl
            ? ResourceImageService.transformLocalResourceUrl(
                resourceImage.image
              )
            : this.webEnvironmentService.getBlobUrl(resourceImage.image),
          legacyPictureUrl: legacyPictureUrl,
          cropperCoordinates,
          resourceImageId: resourceImage.resourceImageId,
          position,
          altText,
        };
      } catch {
        // if the JSON parse fails, return a null imageUrl and allow the fallback image to show
        return { imageUrl: null };
      }
    } else {
      // legacy and non user-uploaded images will return an image url, no need to parse.
      return {
        imageUrl: imageUrl
          ? asHostUrl
            ? ResourceImageService.transformLocalResourceUrl(imageUrl)
            : this.webEnvironmentService.getBlobUrl(imageUrl)
          : undefined,
      };
    }
  }
}
