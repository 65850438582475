import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchFlagsService } from '@app/search/services/search-flags.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFiltersStateService {
  // ignores the users preferred language in global search api 'api/search/findlearningresources'
  private _ignorePreferredLanguage$ = new BehaviorSubject<boolean>(false);
  // extracts the language filter out of the 'More Filters' filter group
  private _extractLanguageFilter$ = new BehaviorSubject<boolean>(false);

  constructor(private searchFlagsService: SearchFlagsService) {}

  public get extractLanguageFilter$(): Observable<boolean> {
    return this._extractLanguageFilter$.asObservable();
  }

  public get ignorePreferredLanguage$(): Observable<boolean> {
    return this._ignorePreferredLanguage$.asObservable();
  }

  public get ignorePreferredLanguage(): boolean {
    return this._ignorePreferredLanguage$.getValue();
  }

  public get extractLanguageFilter(): boolean {
    return (
      this.searchFlagsService.showSearchPreferredLanguageResults &&
      this._extractLanguageFilter$.getValue()
    );
  }

  public setIgnorePreferredLanguage(value: boolean): void {
    this._ignorePreferredLanguage$.next(value);
  }

  public setExtractLanguageFilter(value: boolean): void {
    this._extractLanguageFilter$.next(value);
  }
}
