import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FlexRow,
  FlexRowSummary,
  LinkTextRow,
  OptionalResourceRow,
} from '@app/flex-framework/flex-api.model';
import { FlexRowService } from '@app/flex-framework/services/flex-row.service';
import {
  markUnchangedStringAsPristine,
  warnCharPercentLimited,
} from '@app/shared/utils';
import { isCharLimitedValidator } from '@app/shared/validators/is-char-limited.validator';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { isUrlValidator } from '@app/shared/validators/is-url.validator';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  NotificationType,
} from '@lib/fresco';
import { LDFlagsService } from '@dg/shared-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dgx-link-row-modal',
  templateUrl: './link-row-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkRowModal implements OnInit {
  public i18n = this.translate.instant([
    'dgFlexRow_DeleteConfirmSubmit',
    'dgFlexRow_DeleteSuccess',
    'dgFlexRow_LinkSectionTitleHelperText',
    'dgFlexRow_LinkTitle',
    'dgFlexRow_LinkTitleHelperText',
    'dgFlexRow_Link',
    'dgFlexRow_LinkDescription',
    'dgFlexRow_YesDeleteButton',
    'Core_Delete',
    'Core_Save',
    'dgFlexRow_AddLinkSection',
    'dgFlexRow_EditLinkSection',
    'dgFlexRow_FieldRequired',
    'dgFlexRow_EditSectionDetails',
    'OrgPlans_CustomTitlesAndDescriptionsNotLocalized',
    'Core_UrlRequired',
  ]);

  public readonly NotificationType = NotificationType;

  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[];
  public model = {
    sectionTitle: undefined,
    label: undefined,
    linkTextValue: undefined,
    link: undefined,
  };
  public linkTextRow: LinkTextRow;
  public headerText: string = this.i18n.dgFlexRow_AddLinkSection;
  public isSubmitting: boolean;
  public linkTextCharLimit = 2000;
  public showPlanSimplification = this.ldFlagsService.showPlanSimplification;

  @Input() public flexRowSummary: FlexRowSummary;
  @Input() public row: FlexRow;
  @Input() public isEditing: boolean;

  @ViewChild('removeButton') public removeButtonRef: TemplateRef<any>;

  constructor(
    private activeModal: NgbActiveModal,
    private builder: DfFormFieldBuilder,
    private cdr: ChangeDetectorRef,
    private flexRowService: FlexRowService,
    private ldFlagsService: LDFlagsService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    if (this.isEditing) {
      this.headerText = this.i18n.dgFlexRow_EditSectionDetails;

      // LinkTextRows only have one target resource
      this.linkTextRow = this.row.targetResources[0]
        .reference as unknown as LinkTextRow;

      this.model = { sectionTitle: this.row.section.name, ...this.linkTextRow };
    }
  }

  public ngAfterViewInit() {
    this.fields = this.showPlanSimplification
      ? [
          this.builder
            .requiredTextInput('sectionTitle', 'dgFlexRow_LinkSectionTitle')
            .withMaxLength(255)
            .withHelp(this.i18n.dgFlexRow_LinkSectionTitleHelperText)
            .validatedBy(isEmptyValidator)
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.row.section.name,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .withDgatId('addEditLinkModal-5ba')
            .updatedOn('change')
            .autofocused()
            .build(),
          this.builder
            .optionalTextarea('linkTextValue', 'dgFlexRow_LinkDescription')
            // A max length of 20% *more* than our actual limit prevents character
            // entry beyond this limit, but the form is invalid the second the user
            // is over the limit by 1 character.
            .withMaxLength(
              this.linkTextCharLimit + this.linkTextCharLimit * 0.2
            )
            .withHelp((context) =>
              // Print 'Characters remaining: 200' warning once character count
              // approaches 80% of the limit.
              warnCharPercentLimited(
                this.linkTextCharLimit,
                this.translate.instant('Core_CharsRemainingFormat', {
                  count:
                    this.linkTextCharLimit -
                    (context.formControl.value ?? '').toString().length,
                }),
                context
              )
            )
            .validatedBy((control) =>
              // Print 'Characters remaining: -1' error once character count is
              // over the limit.
              isCharLimitedValidator(
                control,
                this.linkTextCharLimit,
                this.translate.instant('Core_CharsRemainingFormat', {
                  count:
                    this.linkTextCharLimit -
                    (control.value ?? '').toString().length,
                })
              )
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.linkTextValue,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('addEditLinkModal-15e')
            .build(),
          this.builder
            .requiredTextInput('label', 'dgFlexRow_LinkTitle')
            .withMaxLength(255)
            .withHelp(this.i18n.dgFlexRow_LinkTitleHelperText)
            .validatedBy(isEmptyValidator)
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.label,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .withDgatId('addEditLinkModal-eae')
            .updatedOn('change')
            .build(),
          this.builder
            .requiredTextInput('link', 'dgFlexRow_Link')
            .withMaxLength(2000)
            .ofType('url')
            .validatedBy(isEmptyValidator, (control) =>
              isUrlValidator(control, this.i18n.Core_UrlRequired)
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.link,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('addEditLinkModal-87a')
            .build(),
          this.builder
            .customField('', '', this.removeButtonRef)
            .styledBy('form__col-1')
            .hiddenWhen(() => !this.isEditing)
            .build(),
        ]
      : // TODO: remove once ShowPlanSimplification is true in all environments
        [
          this.builder
            .requiredTextInput('sectionTitle', 'dgFlexRow_LinkSectionTitle')
            .withMaxLength(255)
            .withHelp(this.i18n.dgFlexRow_LinkSectionTitleHelperText)
            .validatedBy(isEmptyValidator)
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.row.section.name,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .withDgatId('addEditLinkModal-5ba')
            .updatedOn('change')
            .autofocused()
            .build(),
          this.builder
            .optionalTextarea('linkTextValue', 'dgFlexRow_LinkDescription')
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.linkTextValue,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('addEditLinkModal-15e')
            .build(),
          this.builder
            .requiredTextInput('label', 'dgFlexRow_LinkTitle')
            .withMaxLength(255)
            .withHelp(this.i18n.dgFlexRow_LinkTitleHelperText)
            .validatedBy(isEmptyValidator)
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.label,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .withDgatId('addEditLinkModal-eae')
            .updatedOn('change')
            .build(),
          this.builder
            .requiredTextInput('link', 'dgFlexRow_Link')
            .withMaxLength(2000)
            .ofType('url')
            .validatedBy(isEmptyValidator, (control) =>
              isUrlValidator(control, this.i18n.Core_UrlRequired)
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.linkTextRow.link,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('addEditLinkModal-87a')
            .build(),
          this.builder
            .customField('', '', this.removeButtonRef)
            .styledBy('form__col-1')
            .hiddenWhen(() => !this.isEditing)
            .build(),
        ];

    this.cdr.detectChanges();
  }

  public dismiss(): void {
    return this.activeModal.dismiss();
  }
  /**
   * Opens a custom sub-modal to confirm the user intends to delete
   * this row.
   */
  public removeRow(): Subscription {
    return this.flexRowService.removeRow(
      this.row.section,
      this.row.sectionDefaultTitle,
      this.activeModal
    );
  }

  public submit() {
    // Prevent duplicate submissions.
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    this.form.markAsDirty();
    // Prevent invalid submissions.
    if (this.form.invalid) {
      this.isSubmitting = false;
      this.cdr.markForCheck();
      return;
    }

    const flexRowSummary = this.flexRowSummary || this.row?.section;

    // Trim the values before submitting them.
    // Formly also has parsers, but they don't appear to work any better
    // than simply trimming once here.
    this.activeModal.close({
      section: { ...flexRowSummary, name: this.model.sectionTitle.trim() },
      resource: {
        label: this.model.label?.trim(),
        linkTextValue: this.model.linkTextValue?.trim(),
        link: this.model.link?.trim(),
        // Those three properties are for mapping current
        // link-text rows with an existing
        linkTextId: this.linkTextRow?.linkTextId,
        resourceId: this.linkTextRow?.resourceId,
        resourceType: this.linkTextRow?.resourceType,
      },
    } as OptionalResourceRow);
  }
}
