import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthUser } from '@app/account/account-api.model';
import { MentorshipStatusEnum } from '@app/mentoring/mentoring.enums';
import { SelectOption } from '@app/shared/components/select/select.component';
import { UserProfileSummary } from '@app/user/user-api.model';
import { DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseMenteeModalComponent,
  BaseMenteeModel,
  CustomMaxLengthValidator,
} from '../base-mentee-modal/base-mentee-modal.component';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

export interface AcceptMenteeModel extends BaseMenteeModel {
  contact: string;
  description?: string;
}

@Component({
  selector: 'dgx-accept-mentee-modal',
  templateUrl: '../base-mentee-modal/base-mentee-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptMenteeModalComponent extends BaseMenteeModalComponent {
  // input
  @Input() public mentee: UserProfileSummary;
  @Input() public mentor: AuthUser;
  @ViewChild('formlySelect', { static: true })
  public formlySelectRef: TemplateRef<any>;
  // local
  public i18n = this.translate.instant([
    'Core_Chat',
    'Core_Email',
    'Core_Meeting',
    'Core_Other',
    'Core_Phone',
    'Opportunities_AcceptMenteeModal_ConnectLabel',
    'Opportunities_AcceptMenteeModal_ConnectLabel_Error',
    'Opportunities_AcceptMenteeModal_ConnectPlaceholder',
    'Opportunities_AcceptMenteeModal_Description',
    'Opportunities_AcceptMenteeModal_SubmitButtonText',
  ]);
  public descriptionText =
    this.i18n.Opportunities_AcceptMenteeModal_Description;
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public headerText =
    this.i18n.Opportunities_AcceptMenteeModal_SubmitButtonText;
  public model: AcceptMenteeModel = {
    availability: true,
    contact: undefined,
    mentorshipStatusName: MentorshipStatusEnum.Accepted,
  };
  public options: FormlyFormOptions = {};
  public submitButtonText =
    this.i18n.Opportunities_AcceptMenteeModal_SubmitButtonText;
  public descMaxLength = 100;

  constructor(
    activeModal: NgbActiveModal,
    cdr: ChangeDetectorRef,
    translate: TranslateService
  ) {
    super(activeModal, cdr, translate);
  }

  public ngOnInit(): void {
    // call the base mentee modal ngOnInit
    super.ngOnInit();
    // set the header text
    this.headerText = this.translate.instant(
      'Opportunities_AcceptMenteeModal_AcceptFormatted',
      { name: this.mentee.name }
    );
    // set the fields
    this.fields = [
      {
        key: 'contact',
        type: 'custom',
        validation: {
          messages: {
            // This is a replica of the required message within fresco/formly,
            // but in this case our label is ill-suited to being printed in a
            // "<label> is required" interpolation.
            required: () =>
              this.translate.instant('Core_FieldRequiredFormat', {
                fieldName:
                  this.i18n.Opportunities_AcceptMenteeModal_ConnectLabel_Error,
              }),
          },
        },
        templateOptions: {
          dfLabel: this.i18n.Opportunities_AcceptMenteeModal_ConnectLabel,
          fieldTemplate: this.formlySelectRef,
          required: true,
          // params are passed to the template ref -- these must be translated
          // here, while most other fields can be translated within fresco/formly.
          params: {
            label: this.i18n.Opportunities_AcceptMenteeModal_ConnectLabel,
            model: this.model.contact,
            options: [
              {
                id: 'Email',
                title: this.i18n.Core_Email,
              },
              {
                id: 'Phone',
                title: this.i18n.Core_Phone,
              },
              {
                id: 'Chat',
                title: this.i18n.Core_Chat,
              },
              {
                id: 'Meeting',
                title: this.i18n.Core_Meeting,
              },
              {
                id: 'Other',
                title: this.i18n.Core_Other,
              },
            ],
            placeholder:
              this.i18n.Opportunities_AcceptMenteeModal_ConnectPlaceholder,
            required: true,
            onSelect: (formControl: FormControl, option: SelectOption) =>
              this.onConnectSelect(formControl, option),
          },
        },
      },
      {
        key: 'description',
        type: 'input',
        hideExpression: 'model.contact !== "Other"',
        templateOptions: {
          dfLabel: 'Core_Other',
          // pad our max length a little to allow entry of more than the absolute limit,
          // making it easier for the user to delete down to the appropriate length.
          maxLength: this.descMaxLength + 200,
          required: true,
        },
        // TODO: Extract this into Fresco.
        parsers: [(value) => (value || '').trim()],
        validators: {
          validation: [
            (control) => CustomMaxLengthValidator(control, this.descMaxLength),
            (control) => isEmptyValidator(control),
          ],
        },
        validation: {
          messages: {
            max: (_, field: FormlyFieldConfig) =>
              this.translate.instant(
                'OrgManage_Opportunities_Valid_MaxLength',
                {
                  number:
                    (field.formControl.value?.length || 0) - this.descMaxLength,
                }
              ),
          },
        },
      },
      // add our base field(s) in, at the end
      ...this.fields,
    ];
    // update view
    this.cdr.markForCheck();
  }

  /**
   * Triggered when a selection is made via the dropdown. Takes our
   * dropdown option value and updates both the model and the formControl.
   * This is only necessary because this is a *custom* field; otherwise,
   * Formly would handle all of this for us.
   *
   * @param option - Selected option.
   */
  public onConnectSelect(formControl: FormControl, option: SelectOption) {
    // manually update the model
    this.model.contact = option.id;
    // and the underlying form
    formControl.setValue(option.id);
  }

  /**
   * Local onSubmit, needed to do a little bit of extra work for
   * our hidden field validation after the BaseMenteeModalComponent
   * onSubmit is fired.
   *
   * @param event - The submission event.
   */
  public onSubmit(event: Event) {
    // call our parent submit event, which will submit valid forms
    super.onSubmit(event);
    // if we're still going and haven't stopped, check description
    const description = this.form.get('description');
    // if description field exists, mark as touched/dirty and redo
    // validation to show error message if needed.
    if (description) {
      description.markAsTouched();
      description.updateValueAndValidity();
    }
  }
}
