<dgx-modal
  [canCancel]="false"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
  [isHeaderBorderless]="true"
  bodyClasses="guts-p-full-0"
>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <!-- Old Design -->
    <div *ngIf="!showPeerRatings">
      <div class="guts-p-b-1 v-middle-children center-text">
        <dgx-profile-pic
          [profile]="ratingInfo.rater"
          size="medium"
          class="ib rel"
          style="left: 15px"
        ></dgx-profile-pic>
        <dgx-tag-rating-donut
          size="lg"
          [level]="ratingInfo.level"
          [background]="true"
          class="ib rel"
          style="left: -15px"
        ></dgx-tag-rating-donut>
      </div>
      <div class="guts-p-b-1 v-middle-children center-text">
        <h4
          *ngIf="levelName"
          class="epsilon no-wrap badge badge-pill badge-neutral guts-m-t-half"
          [textContent]="levelName"
          [ngbTooltip]="i18n.dgTagRating_ManagerAssessment"
          container="body"
        ></h4>
      </div>
      <div
        *ngIf="ratingLevelMessage as message"
        [innerHtml]="message"
        class="par par--small par--light center-text guts-p-b-3"
      ></div>
      <p class="par guts-p-h-3 guts-p-b-3 break" *ngIf="ratingInfo.comment">
        "<span [innerHTML]="ratingInfo.comment"></span>"
      </p>
    </div>

    <!-- New Design -->
    <div *ngIf="showPeerRatings">
      <div
        class="guts-p-b-1 v-middle-children center-text"
        style="margin-top: -0.75rem"
      >
        <dgx-profile-pic
          [profile]="ratingInfo.rater"
          size="medium"
          class="ib rel"
          style="left: 0.75rem"
        ></dgx-profile-pic>
        <dgx-tag-rating-donut
          size="lg"
          [level]="ratingInfo.level"
          [background]="true"
          class="ib rel"
          style="left: -0.75rem"
        ></dgx-tag-rating-donut>
      </div>
      <div class="l_flex l_flex-column l_flex-middle">
        <h3
          class="guts-p-b-half color-ebony font-semibold"
          [textContent]="ratingInfo.rater?.name"
        ></h3>
        <p
          class="par--small par--light_a guts-p-b-3"
          [textContent]="
            ratingInfo.dateCompleted | prettyTimeElapsed: 'minutes'
          "
        ></p>
        <p *ngIf="ratingInfo.comment" class="par guts-p-h-3 guts-p-b-4">
          "<span [innerHTML]="ratingInfo.comment"></span>"
        </p>
      </div>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <div class="l_flex-grow l_flex-center left-text">
      <button
        *ngIf="!allowRatingRequest"
        df-button
        dfButtonType="passive"
        class="l_flex--align-left"
        (click)="onViewTagDetails()"
        [textContent]="i18n.dgTagRating_ViewSkill"
        data-dgat="completedAssociateRatingModal-1f5"
        data-dgprop-render-method="Manager Rating Modal"
      ></button>
    </div>
    <div>
      <button
        *ngIf="!allowRatingRequest"
        df-button
        dfButtonType="passive"
        (click)="onViewLevelDescriptions()"
        [textContent]="i18n.dgTagRating_ViewLevelDescription"
        data-dgat="completedAssociateRatingModal-ccf"
      ></button>
      <button
        *ngIf="allowRatingRequest"
        df-button
        dfButtonType="passive"
        (click)="onRequestNewRating($event)"
        [textContent]="i18n.TagRating_AskForNewRating"
        data-dgat="completedAssociateRatingModal-ccf"
      ></button>
    </div>
  </ng-container>
</dgx-modal>
