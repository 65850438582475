import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  SelectFieldComponent,
  SelectFieldParams,
} from '@app/form-fields/wrappers/select-field/select-field.component';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UserProfileExperienceRenderer implements FormRenderer {
  constructor(
    private builder: DfFormFieldBuilder,
    private ldFlagService: LDFlagsService,
    private translate: TranslateService
  ) {}
  render(
    context: RendererContext<any>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return [
      this.builder
        .foreignField<SelectFieldParams>(
          'inputSubType',
          'Core_Type',
          SelectFieldComponent.REGISTERED_FIELD_TYPE,
          {
            optionLabelKey: 'inputSubType',
            optionTrackByKey: 'id',
            options$: of([
              {
                id: null,
                inputSubType: '',
              },
              {
                id: 'JobRole',
                inputSubType: context.translationKeys.Core_JobRoleLiteral,
              },
              {
                id: 'Project',
                inputSubType: context.translationKeys.Opportunities_TypeProject,
              },
              {
                id: 'Mentorship',
                inputSubType:
                  context.translationKeys.Opportunities_TypeMentorship,
              },
              {
                id: 'Menteeship',
                inputSubType:
                  context.translationKeys.Opportunities_TypeMenteeship, // TODO PD-93075 need to add i18n for this
              },
              {
                id: 'Shadowing',
                inputSubType:
                  context.translationKeys.Opportunities_TypeShadowing,
              },
              {
                id: 'StretchAssignment',
                inputSubType:
                  context.translationKeys.Opportunities_TypeStretchAssignment,
              },
              {
                id: 'Other',
                inputSubType: context.translationKeys.Opportunities_TypeOther,
              },
            ]),
          }
        )
        .hiddenWhen(() => !this.ldFlagService.showExperienceTypes)
        .build(),

      this.builder
        .customField('divider', '', context.templates.horizontalDivider)
        .hiddenWhen(() => !this.ldFlagService.showExperienceTypes)
        .build(),

      this.builder
        .requiredTextInput('title', 'Core_Title')
        .withPlaceholder('PositionFormCtrl_PositionTitle')
        .autofocused()
        .onBlur((event, model, control) => {
          control.setValue(control.value?.trim());
          control.updateValueAndValidity();
        })
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant('Core_Title'),
            }),
          },
        })
        .build(),

      this.builder
        .optionalTextInput('inputSubTypePeerName', 'Core_Mentee')
        .hiddenWhen(() => context.state().inputSubType !== 'Mentorship')
        .onBlur((event, model, control) => {
          control.setValue(control.value?.trim());
          control.updateValueAndValidity();
        })
        .build(),

      this.builder
        .optionalTextInput('inputSubTypePeerName', 'Core_Mentor')
        .hiddenWhen(() => context.state().inputSubType !== 'Menteeship')
        .onBlur((event, model, control) => {
          control.setValue(control.value?.trim());
          control.updateValueAndValidity();
        })
        .build(),

      this.builder
        .requiredTextInput('organizationName', 'PositionFormCtrl_CompanyOrg')
        .onBlur((event, model, control) => {
          control.setValue(control.value?.trim());
          control.updateValueAndValidity();
        })
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant('PositionFormCtrl_CompanyOrg'),
            }),
          },
        })
        .build(),
      this.builder
        .checkbox('isCurrent', 'PositionFormCtrl_CurrentPosition')
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'startDate',
                'PositionFormCtrl_StartDate',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: context.translationKeys.Core_SelectStartDate,
                }
              )
              .asRequired()
              .onControlValueChanges((value, model, formControl) => {
                const endDateFormControl =
                  formControl.parent.controls['endDate'];
                if (endDateFormControl.value) {
                  formControl.parent.controls[
                    'endDate'
                  ].updateValueAndValidity();
                  formControl.parent.controls['endDate'].markAsTouched();
                }
              })
              .styledBy('df-form__col-half')
              .build(),

            this.builder
              .foreignField<MonthPickerFieldParams>(
                'endDate',
                'PositionFormCtrl_EndDate',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: context.translationKeys.Core_SelectEndDate,
                }
              )
              .asRequired()
              .validatedByIndexed({
                endDateError: {
                  expression: (control) => {
                    const vm = context.state();
                    // before or the day of (one day experience) is valid
                    return control.value >= vm.extent.startDate;
                  },
                  message:
                    context.translationKeys.PositionFormCtrl_EndAfterStart,
                },
              })
              .styledBy('df-form__col-half')
              .hiddenWhen(() => context.state().isCurrent)
              .build(),
            this.builder
              .requiredTextInput(
                'hoursPerWeek',
                'PositionFormCtrl_HoursPerWeek'
              )
              .ofType('number')
              .withMax(199)
              .withMin(1)
              .updatedOn('change')
              .onBlur((event, model, formControl) => {
                formControl.setValue(
                  Math.round(
                    parseInt((event.target as HTMLInputElement).value, 0)
                  )
                );
              })
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .build(),
      this.builder
        .foreignField<SelectFieldParams>(
          'level',
          'PositionFormCtrl_Seniority',
          SelectFieldComponent.REGISTERED_FIELD_TYPE,
          {
            optionLabelKey: 'level',
            optionTrackByKey: 'id',
            options$: of([
              {
                id: 'Junior',
                level: context.translationKeys.PositionFormCtrl_JuniorPosition,
              },
              {
                id: 'Intermediate',
                level:
                  context.translationKeys.PositionFormCtrl_IntermediatePosition,
              },
              {
                id: 'Senior',
                level: context.translationKeys.PositionFormCtrl_SeniorPosition,
              },
            ]),
          }
        )
        .build(),
      this.builder
        .optionalTextarea('description', 'PositionFormCtrl_Description')
        .build(),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
    ];
  }
}
