import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { OrganizationSupportInfo } from './orgs.model';
import { LocalizationService } from '@app/shared/localization.service';

@Injectable({
  providedIn: 'root',
})
export class OrgHelpMenuService {
  public orgSupportInfoSubject = new BehaviorSubject<OrganizationSupportInfo>(
    null
  );

  constructor(
    private http: NgxHttpClient,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {}

  public get orgSupportInfo$(): Observable<OrganizationSupportInfo> {
    return this.orgSupportInfoSubject.asObservable();
  }

  public getSupportInfo(orgId: number, resetCache: boolean = false) {
    const authUser = this.authService.authUser;
    const canViewKnowledgeCenter =
      authUser.defaultOrgInfo?.permissions.viewKnowledgeCenter;
    const localeId = this.localizationService.userLocale;

    const options: {
      params: any;
      headers?: any;
    } = {
      params: {
        orgId,
        localeId,
        includeKC: canViewKnowledgeCenter,
      },
    };
    if (resetCache) {
      options.headers = {
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      };
    }

    this.http
      .get('/organizations/getsupportinfo', options)
      .subscribe((response) => {
        this.orgSupportInfoSubject.next(response);
      });
  }
}
