<!-- PD-69780 - remove after migration -->
<ng-container *dgxFeatureToggle="'sc-update-privacy'; hidden: true">
  <button
    type="button"
    class="par par--small par--light link-passive no-wrap guts-m-r-1"
    (click)="stopPropagation($event)"
    [dfPopoverTrigger]="privacyPopover"
    data-dgat="tagRatingsOverviewModal-810"
    [attr.aria-label]="
      ratingType +
      ', ' +
      privacyMenuLabel +
      ': ' +
      i18n.UserInfoPartial_ChangePrivacySettings
    "
  >
    <span [textContent]="privacyMenuLabel" class="ib-v-middle"></span>
    <df-icon icon="chevron-down" size="small" class="guts-m-l-half"></df-icon>
  </button>
  <df-popover
    #privacyPopover
    [isOpen]="isPopoverOpen"
    (isOpenChange)="popoverToggled($event)"
    placement="bottom-left"
  >
    <div>
      <div role="listbox">
        <!-- role="option" elements must be immediate children of the role="listbox" element -->
        <button
          role="option"
          df-button
          dfButtonType="passive"
          (click)="setPrivacy($event, 0)"
          class="block btn-passive--no-border"
          data-dgat="tagRatingsOverviewModal-c8f"
        >
          <span class="par par--small par--light">
            {{ i18n.dgTagRating_VisibleToYou }}
          </span>
        </button>
        <button
          role="option"
          df-button
          dfButtonType="passive"
          (click)="setPrivacy($event, 1)"
          class="block btn-passive--no-border par par--small"
          data-dgat="tagRatingsOverviewModal-adf"
        >
          <span class="par par--small par--light">
            {{ i18n.dgTagRating_VisibleToPublic }}
          </span>
        </button>
      </div>
    </div>
  </df-popover>
</ng-container>
<!-- PD-69780 - keep after migration -->
<ng-container *dgxFeatureToggle="'sc-update-privacy'; hidden: false">
  <button
    class="tag-privacy-dropdown-button"
    df-button
    dfButtonType="ghost"
    df-button-small
    (click)="stopPropagation($event)"
    [ngbTooltip]="getToolTipForMobile()"
    [disableTooltip]="disableTooltip"
    [dfPopoverTrigger]="privacyPopover"
    data-dgat="tagRatingsOverviewModal-810"
    [attr.aria-label]="
      ratingType +
      ', ' +
      privacyMenuLabel +
      ': ' +
      i18n.UserInfoPartial_ChangePrivacySettings
    "
  >
    <df-icon
      icon="{{ privacyIcon }}"
      size="small"
      class="guts-m-r-half"
    ></df-icon>
    <span
      [textContent]="privacyMenuLabel"
      class="ib-v-middle no-mobile condensed-rating-tile"
    ></span>
    <df-icon
      icon="caret-down"
      size="small"
      class="m-guts-m-l-0 guts-m-l-half"
    ></df-icon>
  </button>
  <df-popover
    #privacyPopover
    [isOpen]="isPopoverOpen"
    (isOpenChange)="popoverToggled($event)"
    placement="bottom-right"
  >
    <div class="guts-p-h-2 guts-p-t-2" id="privacy_option_group_label">
      <h4 class="font-semibold">{{ privacyTitle }}</h4>
      <p class="par par--light par--small guts-m-b-1">{{ privacyDesc }}</p>
    </div>

    <ul
      #privacyOptionRadioList
      role="radiogroup"
      aria-labelledby="privacy_option_group_label"
      tabindex="0"
      class="guts-p-h-2 guts-p-b-2"
      [attr.aria-activedescendant]="'visibility-' + selectedPrivacyId"
      (keydown)="handleKeyDown($event)"
    >
      <!-- radio buttons -->
      <li
        (click)="setPrivacy($event, 0)"
        id="visibility-0"
        role="radio"
        class="guts-p-full-0"
        [attr.aria-checked]="selectedPrivacyId === 0"
      >
        <div class="l_flex-top">
          <df-icon
            icon="eye-closed"
            size="small"
            class="guts-m-r-half"
          ></df-icon>
          <span
            role="presentation"
            class="par font-semibold par--small ib-v-middle"
            >{{ getPrivateStatusLabel() }}</span
          >
          <p
            class="par par--small par--light guts-m-t-half"
            role="presentation"
            style="max-width: 28rem"
          >
            {{ getPrivateStatusDesc() }}
          </p>
          <a
            *ngIf="isOptedIn"
            href="/me/settings/profile"
            data-dgat="tag-privacy-dropdown-311"
            class="link par--small ib guts-m-t-half"
            target="_blank"
          >
            {{ i18n.dgIntegrationsCard_ManageSettings }}
            <df-icon
              icon="arrow-up-right-out"
              size="small"
              class="guts-m-l-quart"
            ></df-icon>
          </a>
        </div>
      </li>
      <li
        (click)="setPrivacy($event, 1)"
        id="visibility-1"
        role="radio"
        class="guts-m-t-1 guts-p-b-0 guts-p-h-0"
        [attr.aria-checked]="selectedPrivacyId === 1"
      >
        <div>
          <df-icon icon="eye-open" size="small" class="guts-m-r-half"></df-icon>
          <span
            class="par font-semibold par--small ib-v-middle"
            role="presentation"
            >{{ i18n.dgUserProfilePrivacy_VisibleToPublicLabel }}</span
          >
          <p
            class="par par--small par--light guts-m-t-half"
            role="presentation"
          >
            {{ i18n.dgTagRating_VisibleToPublic }}
          </p>
        </div>
      </li>
    </ul>
  </df-popover>
</ng-container>
