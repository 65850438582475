import { SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { TranslateService } from '@ngx-translate/core';
import { DfIconSize } from '@lib/fresco';

/** An Action Button with customizable icon, design (pill, square), and content. */
@Component({
  selector: 'dgx-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent implements OnInit, OnChanges {
  /** Whether button should be immediately set to 'active' after being clicked, rather than waiting for the server to update. */
  @Input() public autoToggleState = true;
  /** Set the button type submit|reset|button */
  @Input() btnType: string = 'submit'; // default value is 'submit' which is the same as not having anything
  /** Dgat to pass onto inner button. Falls back to 'actionButton'. */
  @Input() public dgatInput: string = 'actionButton';
  /** Affects border shape. */
  @Input() public design: Design = 'pill';
  /** Value for `dfIcon`. Leave off to hide icon. */
  @Input() public icon: string;
  /** Whether button should be active. */
  @Input() public isActive = false;
  /** Whether button should be disabled. */
  @Input() public isDisabled = false;
  /** Show only the icon, no text. *Must be used with `tooltip`.* */
  @Input() public isIconOnly = false;
  /** Button and icon size. */
  @Input() public size: ActionButtonSize = 'xs';
  /** Override icon size  */
  @Input() public iconSize?: 'small' | 'medium';
  /** Add a (translated) tooltip. */
  @Input() public tooltip = '';
  /** Use minimal padding */
  @Input() public compact = false;
  /** Use secondary button style (light gray background) */
  @Input() public secondary = false;
  /**
   * Set to 'body' to attach the tooltip to the body instead of its immediate parent.
   * Useful for tooltips inside of overflow: hidden containers, or in sticky headers.
   */
  @Input() public tooltipContainer = '';

  /*
   * If isIconOnly use with tooltip text to give additional information in aria-label
   * Else will show as entire aria-label
   * This is to allow for labeling icons requiring custom handling of colors or styles over and above
   *   the icon input provided in this component
   */
  @Input() public a11yContext = '';
  @Input() public metaData: LearningResourceViewModel['metaData'];
  @Input() public isCompletionButton: boolean = false;

  public ariaLabel: string;
  public ariaPressed: boolean;

  public style: {
    buttonClass: string;
    iconSize: DfIconSize;
  } = {
    buttonClass: 'action-button--xs',
    iconSize: 'small',
  };

  private i18n = this.translateService.instant([
    'Core_Follow',
    'Core_Following',
    'Core_Completed',
    'Core_MarkAsComplete',
  ]);

  constructor(private translateService: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltip?.currentValue && !changes.tooltip?.firstChange) {
      this.ariaLabel = this.getAriaLabel();
    }

    this.updateStyle();
  }

  public ngOnInit(): void {
    if (this.isIconOnly && !this.tooltip) {
      throw new Error(
        `'isIconOnly' requires 'tooltip' to be set for accessibility.`
      );
    }

    this.ariaLabel = this.getAriaLabel();
  }

  public onClick() {
    if (this.autoToggleState) {
      this.isActive = !this.isActive;
    }
  }

  private getAriaLabel(): string {
    const pathwayAndPlanResourceLabels = ['Pathway', 'Plan', 'Role'];
    const isPathwayOrPlanButton = pathwayAndPlanResourceLabels.includes(
      this.metaData?.resourceLabel
    );

    if (this.isIconOnly && isPathwayOrPlanButton) {
      return this.getAriaLabelForPathwayPlanButton();
    } else if (this.isCompletionButton) {
      return this.getAriaLabelForCompletionButton();
    } else if (this.isIconOnly) {
      return this.tooltip + ' - ' + this.a11yContext;
    } else if (this.a11yContext) {
      return this.a11yContext;
    }
  }

  private getAriaLabelForPathwayPlanButton(): string {
    if (this.tooltip === this.i18n.Core_Following) {
      this.ariaPressed = true;
      return this.metaData?.resourceLabel !== 'Role'
        ? this.translateService.instant(
            `A11y_Following${this.metaData.resourceLabel}Button`,
            {
              title: this.a11yContext,
            }
          )
        : this.translateService.instant(`A11y_FollowingPlanButton`, {
            title: this.a11yContext,
          });
    } else if (this.tooltip === this.i18n.Core_Follow) {
      this.ariaPressed = false;
      return this.metaData?.resourceLabel !== 'Role'
        ? this.translateService.instant(
            `A11y_NotFollowing${this.metaData.resourceLabel}Button`,
            {
              title: this.a11yContext,
            }
          )
        : this.translateService.instant(`A11y_NotFollowingPlanButton`, {
            title: this.a11yContext,
          });
    }
  }

  private getAriaLabelForCompletionButton(): string {
    if (this.isActive) {
      this.ariaPressed = true;
      return this.translateService.instant('A11y_ContentCompletedButton', {
        title: this.a11yContext,
      });
    } else if (!this.isActive) {
      this.ariaPressed = false;
      return this.translateService.instant('A11y_ContentNotCompletedButton', {
        title: this.a11yContext,
      });
    }
  }

  private updateStyle() {
    switch (this.size) {
      case 'md':
        this.style = {
          buttonClass: 'action-button--md',
          iconSize: 'medium',
        };
        break;
      case 'sm':
        this.style = {
          buttonClass: 'action-button--sm',
          iconSize: 'small',
        };
        break;
      case 'xs':
        this.style = {
          buttonClass: 'action-button--xs',
          iconSize: 'small',
        };
        break;
      default: {
        // eslint-disable-next-line no-console
        console.info(
          `ActionButtonSize ${this.size} is not valid. Defaulting to size 'xs'.`
        );
        this.style = {
          buttonClass: 'action-button--xs',
          iconSize: 'small',
        };
      }
    }
    if (this.iconSize) {
      this.style.iconSize = this.iconSize;
    }
  }
}

type Design = 'pill' | 'square' | 'none';
export type ActionButtonSize = 'md' | 'sm' | 'xs';
