import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { handleArticleFocusOnSubmit } from '../../services/article.utils';
import { NotificationType } from '@lib/fresco';
import { ArticleGlobalAddInputFacade } from '@app/user-content/user-input-v2/inputs/article/services/global-add/article-global-add.facade';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

type GlobalAddArticleField =
  | 'title'
  | 'sourceName'
  | 'format'
  | 'description'
  | 'comment'
  | 'durationHours'
  | 'durationMinutes'
  | 'owner'
  | 'image'
  | 'skills';

@Component({
  selector: 'dgx-article-global-add',
  templateUrl: './article-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleGlobalAddComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;

  public articleGlobalAddForm: FormGroup;
  public showExpandedForm: boolean = false;
  public readonly NotificationType = NotificationType;
  public i18n = this.translate.instant([
    'Core_Description',
    'Core_Comment',
    'Core_GeneralErrorMessage',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Required',
    'Core_Skills',
    'Core_Source',
    'Core_Title',
    'MediaFormCtrl_AddArticle',
    'MediaFormCtrl_ArticleUrl',
    'MediaFormCtrl_SaveArticle',
    'MediaFormCtrl_UrlRequired',
    'MediaFormCtrl_TitleRequired',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'Core_InputCommentPlaceholder',
    'dgOrgInternalContentForm_CommentTooltip',
    'dgOrgInternalContentForm_ArticleDescriptionPlaceholder',
  ]);
  public heading = this.i18n.MediaFormCtrl_AddArticle;
  public vm$: Observable<ArticleModel>;

  constructor(
    public userGroupListService: UserGroupListService,
    private cdr: ChangeDetectorRef,
    private facade: ArticleGlobalAddInputFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.facade.initializeViewModel(this.context);
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    // Setup the form
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: GlobalAddArticleField, value: any) {
    this.facade.onFormControlUpdate(this.articleGlobalAddForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  public onDeleteImage() {
    this.onFormControlUpdate('image', '');
    this.facade.onDeleteImage();
  }

  public onImageUploadSuccess({ pictureUrl }) {
    this.onFormControlUpdate('image', pictureUrl);
    this.facade.onImageUploadSuccess(pictureUrl);
  }

  /****************************************************
   * On form page submissions
   ****************************************************/
  public async onNext(form: FormGroupDirective): Promise<void> {
    this.facade.markFormAsTouched(this.articleGlobalAddForm);
    if (this.articleGlobalAddForm.invalid) {
      return;
    }

    try {
      await this.onEntryUrlNext();
    } catch (error) {
      console.error(this.i18n.Core_GeneralErrorMessage, error);
    }

    // reset the submitted state on the form
    form.resetForm(this.articleGlobalAddForm.value);
    this.initializeExpandedForm();
    this.loadExpandedForm();
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.articleGlobalAddForm);

    if (this.articleGlobalAddForm.invalid) {
      handleArticleFocusOnSubmit(this.articleGlobalAddForm.controls);
      return;
    }

    try {
      await this.facade.onSubmit(this.articleGlobalAddForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }

    this.activeModal.close(this.facade.snapshot);
  }

  /******************************************************************
   * Private utils
   ******************************************************************/
  /**
   * On selecting next from the initial form
   */
  private async onEntryUrlNext() {
    try {
      await this.facade.onNext(this.articleGlobalAddForm.get('entryUrl').value);
    } catch (error) {
      console.error(this.i18n.Core_GeneralErrorMessage, error);
    }
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.articleGlobalAddForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
    });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeExpandedForm() {
    const ownerValidation = this.facade.snapshot.inputContext.isEditing
      ? Validators.required
      : '';

    this.articleGlobalAddForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
      title: ['', [Validators.required, isEmptyValidator]],
      sourceName: [],
      format: [],
      description: [],
      comment: [],
      owner: ['', ownerValidation],
      image: [],
      skills: [],
    });
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm() {
    this.showExpandedForm = true;

    // Patch values from the collector
    this.articleGlobalAddForm.patchValue({
      entryUrl: this.facade.snapshot.entryUrl,
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      content: this.facade.snapshot.comment,
      owner: this.facade.snapshot.owner,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills,
    });

    this.cdr.detectChanges();
  }
}
