import { Injectable } from '@angular/core';
import { LaunchDarkly } from '../launch-darkly';
import { LDFlagsService } from '@dg/shared-services';

/**
 * This service exposes global search related LD flags via a cache to avoid
 * excessive requests to the LD service
 */
@Injectable({ providedIn: 'root' })
export class TeamFlagsService {
  constructor(private ldFlagsService: LDFlagsService) {}

  /** LD flag for showing the "Skill Insights" tab. Some EU orgs have disabled this tab via this LD flag. */
  public get showLearningInsights() {
    return this.ldFlagsService.getFlag(LaunchDarkly.LEARNING_INSIGHTS, false);
  }

  /** LD flag for removing the points column */
  public get removePointsGroups() {
    return this.ldFlagsService.getFlag(
      LaunchDarkly.POINTS_DECOMISSION_GROUPS,
      false
    );
  }

  /** LD flag for allowing organizations to choose which managers see Skill Coach by adding them to a group */
  public get teamSpaceEnabled() {
    return this.ldFlagsService.getFlag(LaunchDarkly.TEAM_SPACE_ENABLED, false);
  }
}
