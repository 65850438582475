<div *ngIf="metaData !== {}">
  <ul
    *ngIf="metaData"
    class="
      l_flex
      m-l_flex
      font-medium
      par par--light_a par--small
      guts-m-b-quart
    "
    [class.resource-meta--compact]="config?.compact"
  >
    <li
      *ngIf="isOptional"
      class="resource-meta__item"
      [title]="i18n.Core_Optional"
    >
      <span class="no-wrap badge badge-pill badge-pill--compact badge-neutral">
        {{ i18n.Core_Optional }}
      </span>
    </li>
    <li
      *ngIf="metaData.isVerified"
      data-dgprop-item-clicked="Verified"
      class="guts-m-r-half resource-meta__item"
      [title]="i18n.dgContentTile_Verified"
    >
      <df-icon
        icon="checkmark-circle"
        size="small"
        class="color-success-dark guts-m-r-quart verified-icon"
      ></df-icon>
      <span class="color-success-dark">{{ i18n.dgContentTile_Verified }}</span>
    </li>
    <li
      *ngIf="showPathwayProgress"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <span class="badge badge-pill badge-pill--compact badge-emphasize">{{
        'DashboardNav_PathwayPercentComplete'
          | translate: { percent: config.percentComplete }
      }}</span>
    </li>
    <li
      *ngIf="isCompletedPathway || isCompletedAcademy"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <span
        class="badge badge-pill badge-success"
        [ngClass]="{
          'badge-pill--compact': config.compact
        }"
        >{{ 'Core_Completed' | translate }}</span
      >
    </li>
    <li
      *ngIf="showAssignmentBadge"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <dgx-assignment-badge
        [assignmentInfo]="assignmentInfo"
        [resourceType]="resourceType"
      ></dgx-assignment-badge>
    </li>
  </ul>

  <ul
    *ngIf="metaData"
    class="l_flex m-l_flex font-medium par par--light_a par--small"
    [class.resource-meta--compact]="config?.compact"
  >
    <li
      *ngIf="showResourceLabel"
      class="resource-meta__item l_flex-shrink skip-delimiter guts-p-l-0"
      data-dgat="learning-resource-metadata-resourceLabel-8dde"
      [title]="translatedResourceLabel"
    >
      <span>{{ translatedResourceLabel }}</span>
    </li>
    <ng-container
      *ngIf="
        !(
          liveSessions?.length > 0 &&
          translatedResourceLabel === i18n.Core_Event
        )
      "
    >
      <li
        *ngIf="showLegacyDurationDisplay && metaData.durationDisplay"
        class="resource-meta__item"
        data-dgat="learning-resource-metadata-duration-8de8"
        [title]="metaData.durationDisplay"
      >
        {{ metaData.durationDisplay }}
      </li>
      <ng-container
        *dgxFeatureToggle="[
          'add-edit-content-duration',
          'content_duration_course_event_other'
        ]"
      >
        <li
          *ngIf="hoursToDisplay"
          class="resource-meta__item"
          data-dgat="learning-resource-metadata-hours-123e"
          [title]="hoursToDisplay"
        >
          {{ hoursToDisplay }}
        </li>
      </ng-container>
      <ng-container
        *dgxFeatureToggle="[
          'add-edit-content-duration',
          'content_duration_course_event_other'
        ]"
      >
        <li
          *ngIf="minutesToDisplay"
          class="resource-meta__item"
          data-dgat="learning-resource-metadata-minutes-123e"
          [title]="minutesToDisplay"
        >
          {{ minutesToDisplay }}
        </li>
      </ng-container>
    </ng-container>
    <li
      *ngIf="
        metaData.providerSummaryName && (!config?.isAcademy || isMarketplace)
      "
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-provider-8de"
      [title]="metaData.providerSummaryName"
    >
      <a
        *ngIf="providerLinkEnabled"
        [href]="getProviderUrl(metaData.providerId)"
        class="resource-meta__link"
        (click)="trackLinkClick()"
        data-dgat="learning-resource-metadata-84d"
        >{{ metaData.providerSummaryName }}</a
      >
      <span *ngIf="!providerLinkEnabled">
        {{ metaData.providerSummaryName }}
      </span>
    </li>

    <li
      *ngIf="metaData.programLength && config?.isAcademy"
      class="resource-meta__item"
      [title]="metaData.programLength + ' ' + metaData.programLengthUnit"
    >
      <span
        >{{ metaData.programLength + ' ' + metaData.programLengthUnit }}
      </span>
    </li>

    <li
      *ngIf="metaData.cost && !config?.isAcademy"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-cost-8de"
      [title]="metaData.cost"
    >
      <span>{{ metaData.cost }}</span>
    </li>

    <li
      *ngIf="metaData.tagsCountDisplay"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-tagscount-8de"
      [title]="metaData.tagsCountDisplay"
    >
      <span>{{ metaData.tagsCountDisplay }}</span>
    </li>

    <li
      *ngIf="metaData.subtitle"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-subtitle-8de"
      [title]="metaData.subtitle"
    >
      <span>{{ metaData.subtitle }}</span>
    </li>

    <!-- Format -->
    <li
      *ngIf="metaData.format"
      class="resource-meta__item"
      [title]="metaData.format"
    >
      {{ metaData.format }}
    </li>

    <!-- Difficulty -->
    <li
      *ngIf="metaData.difficulty"
      class="resource-meta__item"
      [title]="metaData.difficulty"
    >
      {{ metaData.difficulty }}
    </li>

    <!-- Continuing Education Units -->
    <li
      *ngIf="metaData.continuingEducationUnits"
      class="resource-meta__item"
      [title]="metaData.continuingEducationUnits"
    >
      {{ metaData.continuingEducationUnits }}
    </li>
  </ul>
</div>
