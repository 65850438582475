import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { AuthService } from '@app/shared/services';
import { InputsService } from '@app/inputs/services/inputs.service';
import { VideoFacadeBase } from '../video-facade-base.service';
import { VideoMapperService } from '../video-mapper.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';

// misc
import {
  VideoApiInput,
  VideoModel,
} from '@app/user-content/user-input-v2/inputs/video/video.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { FormGroup } from '@angular/forms';
import { VideoService } from '../video.service';
import { MediaMetadataStatus } from '@app/user-content/user-input-v2/services/inputs-facade-base';
import { MediaParseType } from '@app/shared/models/core.enums';
import { VideoNotificationService } from '../video-notification.service';
import { VideoTrackerService } from '../video-tracker.service';
import { CommentsApiService } from '@app/comments/comments-api.service';

@Injectable({ providedIn: 'root' })
export class VideoGlobalAddInputFacade extends VideoFacadeBase {
  constructor(
    public inputsService: InputsService,
    public authService: AuthService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public videoMapperService: VideoMapperService,
    public videoService: VideoService,
    private videoNotificationService: VideoNotificationService,
    private videoTrackerService: VideoTrackerService,
    private commentsApiService: CommentsApiService
  ) {
    super(
      inputsService,
      authService,
      inputImageUploadAdapterService,
      orgInternalContentService,
      videoMapperService,
      videoService
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] VideoModel
   * ...
   */
  public get snapshot(): VideoModel {
    return readFirst(this.viewModel$);
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

      // Update the view model with the url
      this.viewModel = { ...this.viewModel, entryUrl: url };

      const result: VideoApiInput =
        (await this.inputsService.getMediaMetadataAsPromise(
          url,
          this.viewModel.inputContext.inputType,
          MediaParseType.None
        )) as VideoApiInput;

      // Update the viewModel with the results
      const updatedView = this.videoMapperService.toViewModel(
        result,
        this.viewModel
      );

      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
        owner: undefined,
      };

      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      return;
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
    return;
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      await super.onSubmit(form, true);
      this.performSuccessSideEffects();
    } catch {
      this.performFailureSideEffects();
    }
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: false,
    };
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects(): void {
    if (this.viewModel.comment) {
      const resource = {
        resourceId: this.viewModel.inputId,
        resourceType: 'Video',
        title: this.viewModel.title,
      };
      this.commentsApiService
        .addComment(resource, this.viewModel.comment)
        .subscribe();
    }

    const apiParameters = this.videoMapperService.toApiParameters(
      this.viewModel as VideoModel
    );
    this.videoTrackerService.trackContentCompleted(apiParameters);
    // using this over the videoNotificationService as we already have multiple pages linked to the events from
    // the inputsService and userOutcomesService
    this.inputsService.notifyInputModified('Video');
  }

  /** Performs any side effects required following failed creation of an Input */
  protected performFailureSideEffects() {
    this.videoNotificationService.notifyVideoInputCreateFailed();
  }
}
