<dgx-modal
  [canCancel]="false"
  [submitButtonText]="'Core_Close' | translate"
  bodyClasses="guts-p-1"
>
  <ng-container class="modal-header">
    {{ 'Core_Collaborators' | translate }}
  </ng-container>
  <ng-container class="modal-body">
    <div class="guts-p-h-1 guts-p-t-1">
      <div class="rel">
        <div
          class="l_flexbar m-h-neg1"
          *ngFor="let user of users"
          data-dgat="view-collaborators-modal-418"
        >
          <div class="l_flexbar guts-p-full-1">
            <div class="guts-m-r-1">
              <df-icon
                class="thumb-smaller color-ebony-a18"
                *ngIf="!user.picture"
                icon="person-double"
                size="large"
              ></df-icon>
              <dgx-profile-pic
                *ngIf="user.picture"
                size="small"
                [profile]="user"
                [profileClickable]="true"
              ></dgx-profile-pic>
            </div>
            <div class="l_flex-grow l_flex__ellipsis-wrapper">
              <ul class="par par--small left-text">
                <li class="no-wrap ellipsis font-semibold" *ngIf="user.name">
                  {{ user.name }}
                </li>
                <li
                  class="no-wrap ellipsis par--light"
                  *ngIf="user.organizationEmail || user.email"
                >
                  {{ user.organizationEmail || user.email }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</dgx-modal>
