import { Injectable } from '@angular/core';
import { NormalizedRecommendation } from '@app/recommendations/recommendations.api';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import {
  ProfileFacet,
  ProfileFacetValue,
  ProfileFilter,
} from '../components/profile-filter-bar/profile-filter-bar.component';

/**
 * This service will provide shared facet values and search functions used in the various profile tab filters
 */

@Injectable({
  providedIn: 'root',
})
export class ProfileFacetsService {
  public i18n = this.translate.instant([
    'Core_EventsDisplayName',
    'Core_CoursesDisplayName',
    'Core_BooksDisplayName',
    'Core_ArticlesDisplayName',
    'Core_VideosDisplayName',
    'Core_AssessmentsDisplayName',
    'Core_PodcastsDisplayName',
    'Core_PostsDisplayName',
    'Core_Skills',
    'Core_Plans',
    'Core_Pathways',
  ]);
  private _contentTypeFacetValues: ProfileFacetValue[] = [
    {
      id: 'Course',
      name: this.i18n.Core_CoursesDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Article',
      name: this.i18n.Core_ArticlesDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Book',
      name: this.i18n.Core_BooksDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Video',
      name: this.i18n.Core_VideosDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Posts',
      name: this.i18n.Core_PostsDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Event',
      name: this.i18n.Core_EventsDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Assessment',
      name: this.i18n.Core_AssessmentsDisplayName,
      count: 0,
      filter: false,
    },
    {
      id: 'Episode',
      name: this.i18n.Core_PodcastsDisplayName,
      count: 0,
      filter: false,
    },
    { id: 'Target', name: this.i18n.Core_Plans, count: 0, filter: false },
    { id: 'Pathway', name: this.i18n.Core_Pathways, count: 0, filter: false },
    {
      id: 'Tag',
      name: this.i18n.Core_Skills,
      count: 0,
      filter: false,
    },
  ];
  constructor(private translate: TranslateService) {}
  /** Facet values for content type */
  get contentTypeFacetValues() {
    return cloneDeep(this._contentTypeFacetValues);
  }

  public filterByType(
    item: NormalizedRecommendation,
    typeFacet: ProfileFacet
  ): boolean {
    const activeFilters = typeFacet.values.filter((val) => val.filter);
    return activeFilters.length
      ? activeFilters.some(
          (val) => val.id.toLowerCase() === item.referenceType.toLowerCase()
        )
      : true;
  }

  public filterBySearchTerm(
    item: NormalizedRecommendation,
    searchTerm: string
  ): boolean {
    if (searchTerm) {
      return item.reference.title
        ? item.reference.title
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1
        : false;
    } else {
      return true;
    }
  }
  /**
   * Perform a local search on the recommendations passed in with the filter object.
   * This will return the filtered recommendations and an updated filter with the current facet counts
   * @param recommendations recommendations to filter
   * @param filter filter to apply
   */
  public doRecommendationsSearch(
    recommendations: NormalizedRecommendation[],
    filter: ProfileFilter
  ): { data: NormalizedRecommendation[]; filter: ProfileFilter } {
    let data;

    if (filter.isActive) {
      const typeFacet = filter.facets.find((facet) => facet.id === 'Type');
      data = recommendations.filter((item) =>
        this.filterBySearchTerm(item, filter.searchTerm)
      );
      // set facet counts before applying facets so the count represents
      // the options left after filtering by the search term
      filter.facets.forEach((facet) => this.setFacetCounts(facet, data));

      if (typeFacet) {
        data = data.filter((item) => this.filterByType(item, typeFacet));
      }
    } else {
      data = [];
      filter.facets.forEach((facet) =>
        this.setFacetCounts(facet, recommendations)
      );
    }

    return { data, filter };
  }

  /**
   * Update the counts on the facet using the recommendations passed in.
   * SIDE EFFECT - this will directly update the facet passed in
   * @param facet facet to update
   * @param recommendations recommendations list to use to update the count
   */
  public setFacetCounts(
    facet: ProfileFacet,
    recommendations: NormalizedRecommendation[]
  ) {
    // reset counts
    facet.values.forEach((val) => (val.count = 0));

    recommendations.forEach((recommendation) => {
      facet.values.forEach((val) => {
        if (facet.id === 'Type') {
          if (recommendation.referenceType === val.id) {
            val.count++;
          }
        }
      });
    });
  }
}
