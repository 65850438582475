import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { ngbDateToJsDate } from '@app/shared/utils/time-utils';

import { MediumDateParserFormatterService } from '@app/shared/services/date-picker/medium-date-parser-formatter.service';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { isKey, Key } from '@app/shared/key';
import { LDFlagsService } from '@dg/shared-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dgx-date-picker',
  templateUrl: './date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: MediumDateParserFormatterService,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public date: Date;
  @Input() public disabled: boolean = false;
  @Input() public required: boolean = false;
  @Input() public placeholder: string;
  @Input() public customDgat: string;
  @Input() public isMinDateToday: boolean = false;
  @Input() public isMaxDateToday: boolean = false;
  /** Sets the ID on our input field, connecting it to an external label. */
  @Input() public labelKey = '';

  @Output() public dateSelect = new EventEmitter<Date>();
  @Output() public onBlur = new EventEmitter<any>();

  @ViewChild('ngbDatepicker')
  public ngbDatepicker: NgbInputDatepicker;

  // Note: the datepickers UI will be updated together with the inferredSkillsContent flag
  public showUpdatedDatePicker = this.ldFlagsService.inferredSkillsContent;

  public i18n = this.translateService.instant([
    'Core_Clear',
    'Core_OpenDatePicker',
  ]);

  public onChange: any = () => {};
  public onTouched: any = () => {};

  constructor(
    private ldFlagsService: LDFlagsService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    if (this.isMinDateToday && !this.minDate) {
      this.minDate = new Date();
    }

    if (this.isMaxDateToday && !this.maxDate) {
      this.maxDate = new Date();
    }
  }

  public clearDate(): void {
    this.date = undefined;
    this.ngbDatepicker.close();
    this.onChange(this.date);
    this.dateSelect.emit();
  }

  public handleBlur(): void {
    this.onBlur.emit();
  }

  public selectDate(selectedDate: NgbDate): void {
    const jsDate = ngbDateToJsDate(selectedDate);
    this.onChange(jsDate);
    this.dateSelect.emit(jsDate);
  }

  public onKeyDown(event: KeyboardEvent) {
    if (isKey(event, Key.Enter, Key.Space)) {
      this.ngbDatepicker.toggle();
    }
  }

  public writeValue(date: Date): void {
    this.date = date;
    this.onChange(this.date);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
