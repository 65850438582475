import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import { TranslateService } from '@ngx-translate/core';

interface ResultItem extends LearningResourceViewModel {
  resourceType: ResourceType;
  defaultAction?: (event: Event, returnFocusElement?: ElementRef<any>) => void;
}

@Component({
  selector: 'dgx-resource-result',
  templateUrl: './resource-result.component.html',
  styleUrls: ['./resource-result.component.scss'],
})
export class ResourceResultComponent implements OnInit {
  @Input() public item: ResultItem;
  @Input() public endorsedSrc: string;

  @Output() public remove? = new EventEmitter<LearningResourceViewModel>();

  public icon: string;
  public listItemModel: SimpleItemViewModel<LearningResourceViewModel>;
  public removable: boolean = false;
  public ariaLabelForRemoveContent: string;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    this.listItemModel = {
      title: this.item.title,
      description: this.translate.instant(`Core_${this.item.resourceType}`),
      defaultAction: this.item.defaultAction,
    };
    this.ariaLabelForRemoveContent = this.translate.instant(
      'A11y_RemoveContent',
      {
        contentType: this.listItemModel.description,
        contentTitle: this.listItemModel.title,
      }
    );
    this.removable = this.remove.observers.length > 0;

    // set icon
    switch (this.item.resourceType) {
      case 'User': {
        this.icon = 'person';
        break;
      }
      case 'Tag': {
        this.icon = 'tag';
        break;
      }
      case 'Group': {
        this.icon = 'person-triple';
        break;
      }
      case 'Provider': {
        // the provider icon is purely a fallback option
        this.icon = this.item.providerSummary?.image ? undefined : 'experience';
        break;
      }
    }
  }
}
