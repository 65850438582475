import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { Observable } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { VideoModel } from '@app/user-content/user-input-v2/inputs/video/video.model';
import { maxFifteenSkillsValidator } from '@app/user-content/user-input-v2/utils/validators';
import { DgError } from '@app/shared/models/dg-error';
import { handleVideoFocusOnSubmit } from '../../services/video.utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { NotificationType } from '@lib/fresco';
import { VideoGlobalAddInputFacade } from '@app/user-content/user-input-v2/inputs/video/services/global-add/video-global-add.facade';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

@Component({
  selector: 'dgx-video-global-add',
  templateUrl: './video-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoGlobalAddComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @ViewChild('entryUrl')
  public entryUrlInput: ElementRef<HTMLElement>;
  @ViewChild('form') public form: NgForm;

  public videoGlobalAddForm: FormGroup;
  public heading: string;
  public vm$: Observable<VideoModel>;
  public showExpandedForm: boolean = false;
  public readonly NotificationType = NotificationType;

  public i18n = this.translate.instant([
    'Core_Description',
    'Core_Comment',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Skills',
    'Core_Source',
    'Core_Title',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'MediaFormCtrl_AddVideo',
    'MediaFormCtrl_VideoUrl',
    'MediaFormCtrl_SaveVideo',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_UrlRequired',
    'Core_GeneralErrorMessage',
    'dgOrgInternalContentForm_VideoFormat',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContentForm_CommentTooltip',
  ]);

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: VideoGlobalAddInputFacade,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal
  ) {
    super();
    this.vm$ = this.facade.viewModel$ as any as Observable<VideoModel>;
  }

  /**
   * Get the url required error message to display
   *
   * @returns string translation for URL error message to be shown
   */
  public get getUrlErrorMessage(): string {
    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.facade.initializeViewModel(this.context);
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    this.heading = this.i18n.MediaFormCtrl_AddVideo;

    this.initializeForm();
  }

  /****************************************************
   * On form page submissions
   ****************************************************/

  public async onNext(form: FormGroupDirective): Promise<void> {
    this.facade.markFormAsTouched(this.videoGlobalAddForm);
    if (this.videoGlobalAddForm.invalid) {
      return;
    }
    try {
      await this.onEntryUrlNext();
      // reset the submitted state on the form
      form.resetForm(this.videoGlobalAddForm.value);
    } catch (error) {
      throw new DgError(this.i18n.Core_GeneralErrorMessage, error);
    }

    this.loadExpandedForm();
  }

  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.videoGlobalAddForm);

    if (this.videoGlobalAddForm.invalid) {
      handleVideoFocusOnSubmit(this.videoGlobalAddForm.controls);
      return;
    }

    try {
      await this.facade.onSubmit(this.videoGlobalAddForm);
    } catch (error) {
      throw new DgError(this.i18n.Core_GeneralErrorMessage, error);
    }

    this.activeModal.close(this.facade.snapshot);
  }

  public onFormControlUpdate(field: string, value: any): void {
    this.facade.onFormControlUpdate(this.videoGlobalAddForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  public onImageUploadSuccess({ pictureUrl }): void {
    this.onFormControlUpdate('image', pictureUrl);
    this.facade.onImageUploadSuccess(pictureUrl);
  }

  public onDeleteImage(): void {
    this.onFormControlUpdate('image', '');
    this.facade.onDeleteImage();
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.videoGlobalAddForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
    });
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    this.showExpandedForm = true;

    this.videoGlobalAddForm = this.formBuilder.group({
      entryUrl: [
        this.videoGlobalAddForm.get('entryUrl'),
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
      title: ['', [Validators.required, isEmptyValidator]],
      sourceName: [],
      format: [],
      description: [],
      comment: [],
      owner: [''],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
      // Advanced Setting added by child component
    });

    this.videoGlobalAddForm.patchValue({
      entryUrl: this.facade.snapshot.entryUrl,
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      comment: this.facade.snapshot.comment,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills ?? [],
    });

    // When loading the full view reset the focus on the url input
    this.entryUrlInput?.nativeElement.focus();

    this.cdr.detectChanges();
  }

  /**
   * On selecting next from the initial form
   */
  private async onEntryUrlNext(): Promise<void> {
    try {
      // On next we should not be checking for duplications until the catalog checkbox is checked
      await this.facade.onNext(this.videoGlobalAddForm.get('entryUrl').value);
    } catch (error) {
      throw new DgError(this.i18n.Core_GeneralErrorMessage, error);
    }
  }
}
