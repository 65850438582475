import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { UserRecommendationHeaderItem } from '@app/shared/components/user-recommendation-header/user-recommendation-header.component';
import { DetailsModalConfig } from '@app/shared/services/content/details-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LearningResourceViewModel } from '../../models/learning-resource.view-model';
import { InputCardComponent } from '../input-card/input-card.component';

@Component({
  selector: 'dgx-input-details',
  templateUrl: './input-details.component.html',
})
export class InputDetailsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public resource: LearningResourceViewModel;
  @Input() public config: DetailsModalConfig;
  @Input() public isDetailModal: boolean;
  @Input() public expandComments: boolean;

  @ViewChild(InputCardComponent) public inputCard: InputCardComponent;

  public userRecHeaderItem: UserRecommendationHeaderItem;
  public statisticsLoaded: boolean = false;
  public isPreview: boolean;
  public previewHeader: string;

  public isHackathon: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private inputsService: InputsService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isPreview = this.config?.preview;

    const inputIdentifier = {
      inputId: this.resource.resourceId,
      inputType: this.resource.resourceType as any,
    };

    this.inputsService
      .getInputStatistics([inputIdentifier])
      .pipe(this.takeUntilDestroyed())
      .subscribe((stats) => {
        // Always use stats returned from getInputStatistics
        if (stats.length === 0) {
          this.resource.statistics = undefined;
        } else {
          this.inputsService.mapInputStatistics(
            [this.resource],
            stats,
            this.mapInputStatisticsComparer
          );
        }
        this.statisticsLoaded = true;
        this.initModal();
      });
  }

  public initModal() {
    const recInfo = this.resource.recommendationInfo;
    if (this.isPreview) {
      this.previewHeader = this.translateService.instant(
        'InputCard_PreviewInputFormat',
        { inputType: this.resource.metaData.resourceLabel }
      );
    }
    if (recInfo) {
      this.userRecHeaderItem = {
        ...(recInfo as any),
        contentType: this.resource.resourceType,
        group: recInfo.groupId
          ? {
              groupId: recInfo.groupId,
              groupName: recInfo.groupName,
            }
          : undefined,
      };
    }
    this.cdr.detectChanges();
  }

  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  public onClose(): void {
    // grab the resource from the child component to pass along any changes
    // i.e.  completion status
    this.activeModal.close({ resource: this.inputCard?.resource });
  }

  private mapInputStatisticsComparer(input, stat) {
    const type = input.resourceType || input.inputType;
    const id = input.resourceId || input.inputId;

    return type === stat.inputType && id === stat.inputId;
  }
}
