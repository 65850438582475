<dgx-card [cardAriaLabel]="tag.title">
  <ng-container class="card-content">
    <div
      *ngIf="tag"
      class="
        guts-p-t-2
        l_flex l_flex-wrap l_flex-justify-center l_flex-grow
        guts-m-b-1
      "
      data-dgprop-item-clicked="Skill Tile"
      data-dgat="tagTile-div"
    >
      <!-- visual cue for Skills that have an endorsement. -->
      <img
        class="tag-endorsed-img"
        *ngIf="endorsedSrc"
        [src]="endorsedSrc"
        [attr.alt]="i18n.Core_Endorsed"
        [attr.aria-label]="i18n.OrgSkills_EndorsedSkillTooltip"
        [ngbTooltip]="i18n.OrgSkills_EndorsedSkillTooltip"
        data-dgprop-item-clicked="Skill Tile Endorsed Icon"
      />

      <!-- Certificate seal -->
      <div
        *ngIf="(tag.rating?.privacyId !== 0 || isOwner) && isCertified"
        class="tag-tile__certificate"
      >
        <div
          class="tag-tile__certificate-signatures"
          *ngIf="isCertified && !targetAuthoring"
        >
          <img
            [src]="signaturesUrl"
            [attr.alt]="i18n.Cred_Certificate_SignaturesAlt"
            class="tag-tile__certificate-signatures-image"
          />
        </div>
      </div>

      <!-- Rating Donut -->
      <dgx-tag-rating-donut
        size="lg"
        tabindex="0"
        [attr.aria-label]="ariaLabel"
        role="button"
        [level]="level"
        [isTagTile]="true"
        [targetLevel]="targetLevel"
        [isTargetAuthoring]="targetAuthoring"
        [highlight]="targetLevel && targetAuthoring"
        [certified]="isCertified"
        [evaluated]="isEvaluated"
        [evaluating]="isEvaluating"
        [showPending]="showPendingRatingNotification"
        (click)="determineModalType($event)"
        data-dgprop-item-clicked="Skill Tile Rating Donut"
      ></dgx-tag-rating-donut>

      <!-- Tag TITLE  -->
      <h3
        class="h3 guts-m-t-1-half full-width center-text break"
        data-dgprop-item-clicked="Skill Tile Name"
        data-dgat="tag-tile-1d0"
        tabindex="0"
      >
        <!--clamp the tag title if there is a Credential/Evaluation in progress to keep the card height from expanding too much-->
        <div class="break" [ngClass]="{ clamp: inProgress }">{{ tag.title }}</div>
      </h3>

      <!-- Tag rating -->
      <dgx-tag-tile-rating-description
        class="full-width center-text"
        [tag]="tag"
        [targetAuthoring]="targetAuthoring"
        [isOwner]="isOwner"
        [showUpdateLink]="showUpdateLink"
        (click)="determineModalType($event)"
      ></dgx-tag-tile-rating-description>
      <dgx-tag-tile-target-rating
        *ngIf="!isSkillStandard"
        class="full-width center-text"
        [tag]="tag"
        [targetAuthoring]="targetAuthoring"
        [showAddTarget]="showAddTarget"
        [targetLevel]="targetLevel"
        (click)="determineModalType($event)"
      >
      </dgx-tag-tile-target-rating>
      <dgx-tag-rating-signal
        class="full-width center-text"
        [tag]="tag"
        [showVisibility]="!targetAuthoring"
        (popoverViewed)="popoverViewed(tag)"
        data-dgprop-item-clicked="Skill Tile Ratings Signal"
      ></dgx-tag-rating-signal>
    </div>
  </ng-container>

  <!-- Tag action footer -->
  <ng-container class="card-footer">
    <dgx-tag-tile-footer
      (tagEditedEmitter)="updateTagValue($event)"
      (removeFromPlan)="removeFromPlanFn($event)"
      [hideFollowing]="hideFollowing"
      [tag]="tag"
      [targetAuthoring]="targetAuthoring"
      [actionsContext]="actionsContext"
      [isSkillStandard]="isSkillStandard"
      [modifyOptionsFn]="modifyOptionsFn"
      data-dgprop-item-clicked="Skill Tile Footer"
    ></dgx-tag-tile-footer>
  </ng-container>
</dgx-card>
