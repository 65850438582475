<!-- for modals that need only the simplest form wrap -->
<form
  class="form-wrap"
  *ngIf="useDefaultForm; else noFormWrap"
  (ngSubmit)="onSubmit($event)"
>
  <ng-container *ngTemplateOutlet="modalContent"></ng-container>
</form>

<!-- no form wrap included, wrap in your own form -->
<ng-template #noFormWrap>
  <ng-container *ngTemplateOutlet="modalContent"></ng-container>
</ng-template>

<ng-template #modalContent>
  <!-- Modal Header -->
  <dgx-modal-header
    [canCancel]="canCloseHeader"
    [headerClasses]="headerClasses"
    [headerWrapperClasses]="headerWrapperClasses"
    [isBorderless]="isHeaderBorderless"
    [isCentered]="isHeaderCentered"
    [isLoading]="isLoading"
    [isPendingWithOverlay]="isPendingWithOverlay"
    [isSubmitPending]="isSubmitPending"
    (dismiss)="onDismiss($event)"
  >
    {{ modalHeaderTitle }}
    <ng-content select=".modal-header"></ng-content>
  </dgx-modal-header>

  <!-- Modal Content -->
  <div
    *ngIf="!isLoading; else loading"
    [ngClass]="bodyClasses"
    [ngStyle]="bodyStyle"
    class="dg-modal__body"
  >
    <div
      *ngIf="isSubmitPending && isPendingWithOverlay"
      class="dg-modal__body--overlay"
    ></div>
    <!-- Modal Body -->
    <ng-content select=".modal-body"></ng-content>
  </div>
  <ng-template #loading>
    <div class="dg-modal__body--loading">
      <df-spinner [isSpinning]="isLoading"></df-spinner>
    </div>
  </ng-template>

  <!-- Modal Footer -->
  <dgx-modal-footer
    *ngIf="!isLoading && !isFooterless"
    [canCancel]="canCancel"
    [cancelButtonDgat]="cancelButtonDgat"
    [cancelButtonText]="cancelButtonText"
    [isSubmitDisabled]="isSubmitDisabled"
    [isSubmitPending]="isSubmitPending"
    [submitButtonDgat]="submitButtonDgat"
    [submitButtonText]="submitButtonText"
    [submitButtonType]="submitButtonType"
    [useDefaultSubmitButton]="useDefaultSubmitButton"
    (dismiss)="onDismiss($event)"
  >
    <ng-content select=".modal-footer"></ng-content>
  </dgx-modal-footer>
</ng-template>
