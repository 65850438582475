import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManagerOfManagerService } from '@app/team/services/manager-of-manager.service';
import { AuthService } from '@app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dgx-tag-privacy-dropdown',
  templateUrl: './tag-privacy-dropdown.component.html',
  styleUrls: ['./tag-privacy-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagPrivacyDropdownComponent implements OnInit {
  @Input() public privacyId: number;
  @Input() public ratingType: string;
  @Input() public condensedTile: boolean = false;
  @Input() public ratingTypeLabel?: string;

  @Output() public setTagPrivacy: EventEmitter<{
    privacyId: number;
  }> = new EventEmitter();
  radioGroup: HTMLElement;
  radioButtons: HTMLElement[] = [];
  @ViewChild('privacyOptionRadioList', { read: ElementRef }) public set privacyOptionRadioList(element: ElementRef) {
    if (!element) return;
    this.radioGroup = element.nativeElement;
    this.radioButtons = Array.from((element.nativeElement as HTMLElement).querySelectorAll('[role="radio"]'));
  }

  public i18n: { [key: string]: string } = this.translate.instant([
    'dgTagRating_VisibleToPublic',
    'dgTagRating_VisibleToYou',
    'dgUserProfilePrivacy_VisibleToPublicLabel',
    'dgIntegrationsCard_ManageSettings',
    'Team_Skills_VisibilityTooltip',
    'Core_Private',
    'Core_LimitedVisibility',
    'Team_Skills_VisibilityTooltip_NoMoManager',
    'UserInfoPartial_ChangePrivacySettings',
  ]);
  public privacyMenuLabel: string;
  public privacyMenuOpen: boolean = false;
  public isPopoverOpen: boolean = false;
  public selectedPrivacyId: number;
  public hasManagerOfManager: boolean = false;
  public isOptedIn: boolean = false;
  public isMobile: boolean = false;


  constructor(
    private translate: TranslateService,
    private managerOfManagerService: ManagerOfManagerService,
    private auth: AuthService,
    private router: Router,
  ) {}

  public get privacyIcon() {
    return this.selectedPrivacyId === 0 ? 'eye-closed' : 'eye-open';
  }

  public get privacyTitle() {
    return this.translate.instant('dgTagRating_PrivacyPopoverTitle', {
      ratingType: this.ratingType,
    });
  }

  public get privacyDesc() {
    return this.translate.instant('dgTagRating_PrivacyPopoverDesc', {
      ratingType: this.ratingType,
    });
  }

  public get disableTooltip() {
    // show only on signal page
    return !this.router.url.includes('signal');
  }

  public ngOnInit() {
    this.isOptedIn = this.auth.authUser.participateInTeamProgram;
    this.selectedPrivacyId = this.privacyId;
    this.setPrivacyMenuLabel(this.privacyId);
    this.managerOfManagerService
      .getHasManager(this.auth.authUser.defaultOrgInfo.organizationId)
      .subscribe((data: any) => {
        this.hasManagerOfManager = data.hasManagerOfManager;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.privacyId) {
      this.setPrivacyMenuLabel(this.privacyId);
    }
  }

  public getPrivateStatusDesc() {
    if (!this.isOptedIn) {
      return this.i18n.dgTagRating_VisibleToYou;
    } else {
      return this.hasManagerOfManager
        ? this.i18n.Team_Skills_VisibilityTooltip
        : this.i18n.Team_Skills_VisibilityTooltip_NoMoManager;
    }
  }

  public getPrivateStatusLabel() {
    return !this.isOptedIn
      ? this.i18n.Core_Private
      : this.i18n.Core_LimitedVisibility;
  }

  public getToolTipForMobile() {
    switch (this.selectedPrivacyId) {
      case 0:
        return !this.isOptedIn
          ? this.i18n.Core_Private
          : this.i18n.Core_LimitedVisibility;
        break;
      case 1:
        return this.i18n.dgUserProfilePrivacy_VisibleToPublicLabel;
        break;
    }
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public popoverToggled(popoverState) {
    this.isPopoverOpen = popoverState;
  }

  public setPrivacy(event: Event, privacyId: number): void {
    event.stopPropagation();
    this.isPopoverOpen = false;
    this.setTagPrivacy.emit({ privacyId });
    this.selectedPrivacyId = privacyId;
    // This is redundant since the emitter should change the privacyId in the parent, but is needed for accessibility reasons.
    // The post to the BE usually takes too long which makes the screen reader read out the old privacy label when there is a change.
    this.setPrivacyMenuLabel(privacyId);
  }

  private setPrivacyMenuLabel(privacyId: number): void {
    // 0 Visible to you only
    // 1 Visible to Public
    switch (privacyId) {
      case 0:
        this.privacyMenuLabel = !this.isOptedIn
          ? this.i18n.Core_Private
          : this.i18n.Core_LimitedVisibility;
        break;
      case 1:
        this.privacyMenuLabel = this.i18n.dgUserProfilePrivacy_VisibleToPublicLabel;
        break;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    let flag = false;

    const currentItem = this.getCurrentSelected();
    switch (event.key) {
      case 'Enter':
        return this.setPrivacy(event, this.selectedPrivacyId);
      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.selectPreviousButton(currentItem);
        flag = true;
        break;

      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.selectNextButton(currentItem);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  selectNextButton(button: HTMLElement) {
    const currentIndex = this.radioButtons.indexOf(button);
    const isLastIndex = currentIndex === (this.radioButtons.length - 1);
    // Select the first ITEM if user is at last button
    const buttonToBeSelected = isLastIndex ? this.radioButtons[0] : this.radioButtons[currentIndex + 1];
    this.selectRadioButton(buttonToBeSelected);
  }

  selectPreviousButton(button: HTMLElement) {
    const currentIndex = this.radioButtons.indexOf(button);
    const isFirstIndex = currentIndex === 0;
    // Select the last ITEM if user is at first button
    const buttonToBeSelected = isFirstIndex ? this.radioButtons[this.radioButtons.length - 1] : this.radioButtons[currentIndex - 1];
    this.selectRadioButton(buttonToBeSelected);
  }

  selectRadioButton(button: HTMLElement) {
    this.selectedPrivacyId = Number(button.id.split('-').pop());
    this.radioButtons.forEach((rButton) => rButton.classList.remove('focus'));
    button.classList.add('focus');
    this.radioGroup.focus();
    this.setPrivacyMenuLabel(this.selectedPrivacyId);
  }

  /**
   * Returns the selected radio button by default
   */
  getCurrentSelected(): HTMLElement {
    return this.radioButtons.find((button) => button.id === `visibility-${this.selectedPrivacyId}`);
  }
}
