/* eslint-disable max-classes-per-file */
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export class CachedHttpRequest<T> extends HttpRequest<T> {}

/** An HTTP interceptor that redirects the user to the login page if a 401 response is received
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    // NOTE: this allows individual http requests to ignore this interceptor
    if (req.headers.get('Dg-Skip-Intercept')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.logout(true, true);
        }
        return throwError(err);
      })
    );
  }
}
