import { Injectable } from '@angular/core';
import { CollectionDetails } from '@app/notifications/notification-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { sortBy } from '@app/shared/utils/common-utils';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  ConnectWithMentorRequest,
  GetUserMentorshipsRequest,
  Mentorship,
  MentorshipResponse,
  MentorshipType,
} from '../mentoring-api.model';
import { MentorshipTypeEnum } from '../mentoring.enums';
import { MentoringHelperService } from './mentoring-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MentoringApiService {
  private i18n = this.translateService.instant([
    'Core_Error',
    'Opportunities_ConnectWithModal_Success',
    'Opportunities_ConnectWithModal_Success_Updated',
    'Opportunities_ConnectWithModal_Success_Mentors_Removed',
    'Opportunities_Error_GetMentors',
  ]);
  private readonly baseUrl: string = '/mentorships';

  constructor(
    private http: NgxHttpClient,
    private authService: AuthService,
    private mentoringHelperService: MentoringHelperService,
    private notifierService: NotifierService,
    private translateService: TranslateService
  ) {}

  /**
   * Create a new user mentorship.
   *
   * @param request - The UserMentorship to create.
   */
  public addUserMentorships(
    request: ConnectWithMentorRequest
  ): Observable<ConnectWithMentorRequest> {
    return this.http
      .post<void>(`${this.baseUrl}/AddUserMentorships`, request)
      .pipe(
        // tap to display our success toast
        tap(() => {
          this.notifierService.showSuccess(
            this.i18n.Opportunities_ConnectWithModal_Success
          );
        }),
        // send the original request back, since our HTTP request doesn't
        // return any data.
        mapTo(request)
      );
  }

  /**
   * Get an array of mentors for the current logged-in user.
   *
   * @param take - The number of mentors to return.
   * @param pageNum - The number of mentors to skip.
   * @param excludeCurrentMentors - Whether to exclude existing mentors.
   * @param term - Search term that will search and filter the term against users fullName and interests
   */
  public getMentors(
    {
      take,
      pageNum,
      excludeCurrentMentors,
      term,
    }: {
      take?: number;
      pageNum?: number;
      excludeCurrentMentors?: boolean;
      term?: string;
    } = {
      take: 10,
      pageNum: 0,
      excludeCurrentMentors: false,
    }
  ): Observable<CollectionDetails<UserProfileSummary>> {
    return this.http
      .get<CollectionDetails<UserProfileSummary>>(
        `${this.baseUrl}/SearchForMentors`,
        {
          params: {
            take,
            pageNum,
            excludeCurrentMentors,
            term,
          },
        }
      )
      .pipe(
        catchAndSurfaceError<CollectionDetails<UserProfileSummary>>(
          this.i18n.Opportunities_Error_GetMentors
        )
      );
  }

  /**
   * Get given user's mentorship data.
   *
   * @param userKeys - The user profile key.
   */
  public getUserMentorships(
    userKeys?: GetUserMentorshipsRequest
  ): Observable<{ mentees: Mentorship[]; mentors: Mentorship[] }> {
    const { userProfileKey } = this.authService.authUser.viewerProfile;
    const menteeProfileKey = userKeys?.menteeProfileKey ?? userProfileKey;
    const mentorProfileKey = userKeys?.mentorProfileKey ?? userProfileKey;

    return this.http
      .get<MentorshipResponse>(`${this.baseUrl}/GetUserMentorships`, {
        params: { mentorProfileKey, menteeProfileKey },
      })
      .pipe(
        map(
          ({
            mentorships,
            tagsForMentorRating,
          }: {
            mentorships: Mentorship[];
            tagsForMentorRating: UserInterest[];
          }) => {
            const mentees: Mentorship[] = [];
            const mentors: Mentorship[] = [];

            for (const currentMentorship of mentorships) {
              // Flatten our mentee and mentorship data, ensuring that all
              // properties are available from the top-level for consistent
              // data table display.
              const { mentee, mentor, ...mentorship } = currentMentorship;
              // Filter out location being passed by the backend on the MentorshipResponse that causes
              // overriding of mentor/mentee's empty locations. Should be removed from backend!
              const { location, ...excludedLocationMentorship } = mentorship;
              // Some of the mentorships that come back are also both mentees
              // and mentors, so this lets us split them into two different
              // objects in our two different arrays.
              // At the same time: we need to preserve the separate object for
              // our mentee/mentor accept/request/decline modals.
              if (currentMentorship.menteeUserProfileKey === menteeProfileKey) {
                mentors.push({
                  ...mentor,
                  ...excludedLocationMentorship,
                  mentor,
                });
              }
              if (currentMentorship.mentorUserProfileKey === mentorProfileKey) {
                mentees.push({
                  ...mentee,
                  ...excludedLocationMentorship,
                  mentee,
                  tagsForMentorRating,
                });
              }
            }

            return {
              // Ideally, we should not sort name in FE, remove it once the sorting is implemented in BE
              mentees: mentees.sort((a, b) => sortBy(a, b, 'name')),
              mentors: mentors.sort((a, b) => sortBy(a, b, 'name')),
            };
          }
        )
      );
  }

  /**
   * Archive an existing user mentorship. The BE gets the
   * current user from their AuthUser object.
   *
   * @param mentorProfileKey - The mentor's UPK.
   * @param menteeProfileKey - The mentee's UPK.
   * @param requestingUserKey - UPK of the user initiating the removal.
   * @param successMessage - The message to display to the user on success.
   */
  public archiveUserMentorships(
    mentorProfileKey: number,
    menteeProfileKey: number,
    successMessage = this.i18n
      .Opportunities_ConnectWithModal_Success_Mentors_Removed
  ): Observable<unknown> {
    const { userProfileKey } = this.authService.authUser.viewerProfile;
    return this.http
      .put<void>(`${this.baseUrl}/ArchiveUserMentorships`, null, {
        params: {
          mentorProfileKey,
          menteeProfileKey,
          requestingUserKey: userProfileKey,
        },
      })
      .pipe(
        // tap to display our success toast
        tap(() => {
          this.notifierService.showSuccess(successMessage);
        })
      );
  }

  /**
   * Update the current logged-in user's IsMentoring setting.
   *
   * @param isMentoring - The new IsMentoring value.
   */
  public updateUserIsMentoring(
    mentorship: Mentorship,
    isMentoring: boolean
  ): Observable<unknown> {
    return this.http
      .put<void>(`${this.baseUrl}/UpdateIsMentoringSetting`, {
        isMentoring,
      })
      .pipe(
        // clear the auth service's cache
        switchMap(() => this.authService.fetchAuthenticatedUser(true))
      );
  }

  /**
   * Update an existing user mentorship.
   *
   * @param mentorship the updated mentorship with mentor UPK, mentee UPK and mentorship status
   * @param successMessage the message to display to the user on success
   */
  public updateUserMentorships(
    updatedMentorship: Mentorship,
    successMessage = this.i18n.Opportunities_ConnectWithModal_Success_Updated
  ): Observable<Mentorship> {
    const params = {
      mentorshipStatusName: updatedMentorship.mentorshipStatusName,
      contact: this.mentoringHelperService.getMentorContactMethod(
        updatedMentorship.mentorContactMethodId
      ),
      menteeUserProfileKey: updatedMentorship.menteeUserProfileKey,
      mentorUserProfileKey: updatedMentorship.mentorUserProfileKey,
      description: updatedMentorship.mentorContactMethodDescription,
    };
    return this.http
      .put<void>(`${this.baseUrl}/UpdateUserMentorships`, params)
      .pipe(
        // tap to display our success toast
        tap(() => {
          this.notifierService.showSuccess(successMessage);
        }),
        // send the original request back, since our HTTP request doesn't
        // return any data.
        mapTo(updatedMentorship)
      );
  }

  /**
   * Request a peer rating from a completed mentorship.
   *
   * @param mentorship the mentorship who to request rating from
   * @param comment the mentorship with mentor UPK, mentee UPK and mentorship status
   * @param tagIds an array of the skills to request to be rated in
   * @param mentorshipType the mentorship type
   */
  public requestTagPeerRatingMentorships(
    raterProfileKey: number | string,
    comment: string,
    tagIds: number[],
    mentorshipType: MentorshipType
  ): Observable<any> {
    const params = {
      raterProfileKey,
      comment,
      tagIds,
      requestIsFromMentor: mentorshipType === MentorshipTypeEnum.Mentee,
    };

    return this.http
      .post<void>(`${this.baseUrl}/RequestPeerRatings`, params)
      .pipe(catchAndSurfaceError(this.i18n.Core_Error));
  }

  public mentoringGiveFeedback(
    receiverUserProfileKey: string | number,
    isFromMentor: boolean,
    message: string
  ): Observable<boolean> {
    const params = {
      submitFeedbackForUserProfileKey: receiverUserProfileKey,
      submissionIsFromMentor: isFromMentor,
      feedbackMessage: message,
    };

    return this.http.post<void>(`${this.baseUrl}/SubmitFeedback`, params).pipe(
      // return success
      catchAndSurfaceError(this.i18n.Core_Error),
      // if no error, bubble up that we submitted feedback successfully
      mapTo(true)
    );
  }

  public requestFeedback(
    requestFeedbackFromUserProfileKey: number,
    requestIsFromMentor: boolean
  ): Observable<void> {
    const params = { requestFeedbackFromUserProfileKey, requestIsFromMentor };
    return this.http
      .post<void>(`${this.baseUrl}/RequestFeedback`, params)
      .pipe(catchAndSurfaceError(this.i18n.Core_Error));
  }
}
