import {
  Component,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  Output,
  SimpleChanges,
} from '@angular/core';
// services
import { TranslateService } from '@ngx-translate/core';
import { A11yService } from '@app/shared/services/a11y.service';
import { TabRouterLessNavigationItem } from '@app/navigation/navigation.model';
/**
 * For use with tab navigation in places that should not use Angular routes. EX: Modals.
 *
 * @example
 * ```
 * <dgx-tab-navigation-routerless
 *   [tabList]="tabs"
 *   wrapperClasses="guts-p-t-0"
 *   [defaultActiveTabId]="name"  //otherwise defaults to first
 *   (updateSelectedTab)="selectedTab($event)"
 * >
 *   <ng-template #templateRef>content</ng-templatte
 *   <ng-template #templateRef2>content</ng-template>
 *   <ng-template #additionalHeaderinfo>content</ng-template>
 * </dgx-tab-navigation>
 * ```
 */
@Component({
  selector: 'dgx-tab-navigation-routerless',
  templateUrl: './tab-navigation-routerless.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavigationRouterlessComponent {
  @Input() public wrapperClasses: string;
  @Input() public tabList: TabRouterLessNavigationItem[];
  @Input() public defaultActiveTabId: string;
  @Output() public updateSelectedTab? = new EventEmitter<string>();

  public i18n = this.translate.instant(['A11y_SecondaryPagesNavType']);
  public currentTemplate;

  constructor(
    private translate: TranslateService,
    private a11yService: A11yService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultActiveTabId && !changes.defaultActiveTabId.firstChange) {
      const tab = this.tabList.filter(
        (item) => item.nameId === this.defaultActiveTabId
      )[0];
      this.onTabSwitch(new Event('', {}), tab);
    }
    // console.log('current Id', changes, this.defaultActiveTabId);
  }

  ngAfterContentInit() {
    // if there is a default tab select, otherwiss default will be the first item
    this.currentTemplate = this.defaultActiveTabId
      ? this.tabList.find((item) => item.nameId === this.defaultActiveTabId)
          .template
      : this.tabList[0].template;
  }

  /**
   * Update the current template. When tab is switched, use a11yService to announce it.
   */
  public onTabSwitch(_: Event, tab: TabRouterLessNavigationItem): void {
    this.currentTemplate = this.tabList.find(
      (item) => item.nameId === tab.nameId
    ).template;
    if (!!this.updateSelectedTab) {
      this.updateSelectedTab.emit(tab.nameId);
    }

    this.a11yService.announcePolite(
      this.translate.instant('A11y_TabChangeAnnouncement', {
        newPageTitle: tab.name,
      })
    );
  }
}
