<dgx-modal [canCancel]="true" [useDefaultSubmitButton]="false">
  <ng-container class="modal-header">
    <h3 class="guts-p-l-half guts-p-t-1-half font-semibold">
      {{ i18n.Core_AddTags }}
    </h3>
  </ng-container>

  <ng-container class="modal-body">
    <div
      class="l_flex l_flex-justify-center l_flex-column guts-m-l-1 guts-m-r-1"
    >
      <dgx-tags-edit
        class="form-wrap guts-m-t-1"
        [tags]="tags"
        [hideTopTags]="true"
        [hideTooltip]="true"
        (addTag)="onTagAdded($event)"
        (removeTag)="onTagRemoved($event)"
        [useSuggestedSkillsView]="true"
      ></dgx-tags-edit>
      <div class="guts-m-t-1 guts-m-l-quart">
        <span *ngIf="isLoading" class="par par--light par--small">{{
          i18n.TagsCtrl_SuggestedSkillsLoading
        }}</span>

        <span
          *ngIf="!isLoading && !currentTag"
          class="par par--light par--small"
          >{{ i18n.TagsCtrl_SuggestedSkillsTitle }}</span
        >

        <span
          *ngIf="!isLoading && currentTag"
          class="par par--light par--small"
          >{{
            'TagsCtrl_SuggestedSkillsForSkill'
              | translate: { skillName: currentTag.name }
          }}</span
        >
        <dgx-tag-suggestions
          [isLoading]="isLoading"
          [suggestedTags]="suggestedTags"
          (onAddSuggestedTag)="addSuggestedTag($event)"
        ></dgx-tag-suggestions>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer">
    <div class="l_flex">
      <button
        type="button"
        df-button
        dfButtonType="primary"
        class="btn btn-primary no-border"
        [disabled]="isSaving || tags.length === 0"
        (click)="save()"
        data-dgat="add-suggested-tags-modal-12a"
      >
        {{ i18n.Core_AddTags }}
      </button>
    </div>
  </ng-container>
</dgx-modal>
