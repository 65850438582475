<div
  role="dialog"
  aria-labelledby="dialogTitle"
  aria-describedby="dialogDescription"
  class="rel"
>
  <dgx-modal-header
    [canCancel]="true"
    [isBorderless]="true"
    (dismiss)="dismiss()"
  >
    {{ i18n.Ext_AddTopicsOrSkills }}
  </dgx-modal-header>

  <div id="dialogDescription" class="guts-p-full-2">
    <dgx-tags-edit
      [tags]="tags"
      [topTags]="userTags"
      (tagsChange)="handleTagsChange($event)"
      [required]="true"
    ></dgx-tags-edit>
  </div>
  <dgx-modal-footer
    [canCancel]="true"
    (dismiss)="dismiss()"
    [useDefaultSubmitButton]="false"
  >
    <button
      df-button
      dfButtonType="primary"
      [attr.aria-label]="i18n.Core_Save"
      class="btn btn-primary"
      (click)="saveTagsChange()"
      data-dgat="bulkUpdateModal-80a"
    >
      <df-spinner-button [isSpinning]="isSaving">
        {{ i18n.Core_Save }}
      </df-spinner-button>
    </button>
  </dgx-modal-footer>
</div>
