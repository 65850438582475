<dgx-modal-header (dismiss)="onDismiss()" [canCancel]="!isEbb">
  {{ i18n.addToPathwayModal_AddToPathway }}
</dgx-modal-header>
<div aria-live="polite">
  <!-- ^^^ Lots of dynamic changes in this modal. Let's read them all out -->
  <form
    class="form-wrap"
    *ngIf="displayArea === 'selectionForm'"
    (ngSubmit)="onFormSubmit()"
  >
    <div [ngClass]="isEbb ? 'guts-p-v-2 guts-p-h-1-half' : 'guts-m-full-2'">
      <dgx-org-managed-notification
        class="guts-m-t-2 guts-m-b-2 block"
        *ngIf="showOrgManagedNotification"
        resourceType="Pathway"
      ></dgx-org-managed-notification>
      <div class="guts-p-b-1">
        <label for="pathway-input">{{ i18n.addToPathwayModal_Pathway }}</label>
        <dgx-combobox
          *ngIf="!loadingPathways"
          class="full-width"
          (selection)="onPathwaySelected($event)"
          [options]="pathwayOptions"
          labelKey="name"
          placeholder="{{ i18n.addToPathwayModal_SelectPathway }}"
          hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
          dgatInput="addToPathway-4d1"
        ></dgx-combobox>
        <dgx-skeleton *ngIf="loadingPathways" type="formfield"></dgx-skeleton>
      </div>
      <div
        *ngIf="sectionOptions.length > 0 || loadingSections"
        class="guts-p-b-1"
      >
        <label for="section-input">{{ i18n.addToPathwayModal_Section }}</label>
        <dgx-combobox
          *ngIf="!loadingSections"
          class="full-width"
          (selection)="onSectionSelected($event)"
          [options]="sectionOptions"
          labelKey="name"
          [selectedOption]="autoSelectedSection?.name"
          placeholder="{{ i18n.addToPathwayModal_SelectSection }}"
          hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
          dgatInput="addToPathway-681"
        ></dgx-combobox>
        <dgx-skeleton *ngIf="loadingSections" type="formfield"></dgx-skeleton>
      </div>
      <div *ngIf="subsectionOptions.length > 0" class="guts-p-b-1">
        <label for="section-input">{{ i18n.addToPathwayModal_Lesson }}</label>
        <dgx-combobox
          class="full-width"
          (selection)="onSubsectionSelected($event)"
          [options]="subsectionOptions"
          labelKey="name"
          placeholder="{{ i18n.addToPathwayModal_SelectLesson }}"
          hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
          dgatInput="addToPathway-9e9"
        ></dgx-combobox>
      </div>
      <div
        *ngIf="isEbb && displayAddToCatalogOption && isValid"
        class="guts-p-t-1 v-middle-children"
        style="line-height: 1.5rem"
      >
        <input
          type="checkbox"
          id="addToCatalog"
          name="addToCatalog"
          [checked]="addToCatalog"
          (change)="toggleAddToCatalog()"
          data-dgat="addToPathway-d36"
        />
        <label for="addToCatalog" class="guts-m-l-half">{{
          i18n.addToPathwayModal_AddToCatalog
        }}</label>
      </div>
    </div>
    <dgx-modal-footer
      [canCancel]="true"
      [useDefaultSubmitButton]="false"
      (dismiss)="onDismiss()"
    >
      <button
        df-button
        dfButtonType="primary"
        type="submit"
        [disabled]="!isValid"
        data-dgat="addToPathway-c23"
      >
        <df-spinner-button [isSpinning]="isSubmitting | async">
          {{ i18n.addToPathwayModal_AddToPathway }}
        </df-spinner-button>
      </button>
    </dgx-modal-footer>
  </form>
  <!-- no pathways -->
  <div *ngIf="displayArea === 'noPathways'">
    <div class="guts-p-full-2 center-text">
      <h3 class="h3">{{ i18n.addToPathwayModal_CreateYourPathway }}</h3>
      <p class="par par--small">
        {{ i18n.addToPathwayModal_CreatePathwayExplanation }}
      </p>
    </div>
    <dgx-modal-footer
      [canCancel]="true"
      [useDefaultSubmitButton]="false"
      (dismiss)="onDismiss()"
    >
      <button
        df-button
        dfButtonType="primary"
        (click)="openCreatePathway()"
        data-dgat="addToPathway-cdf"
      >
        {{ i18n.addToPathwayModal_AddToPathway }}
      </button>
    </dgx-modal-footer>
  </div>
  <!-- success message -->
  <div *ngIf="displayArea === 'successMessage'">
    <div class="guts-p-full-2 center-text">
      <h3 class="h3">{{ i18n.addToPathwayModal_AddedSuccessfully }}</h3>
      <p class="par par--small">
        {{ i18n.addToPathwayModal_VisitPathwayEditPage }}
      </p>
    </div>

    <dgx-modal-footer [useDefaultSubmitButton]="false">
      <a
        *ngIf="pathwayAuthoringUrl"
        df-button
        dfButtonType="passive"
        class="guts-m-r-1 ib"
        href="{{ pathwayAuthoringUrl }}"
        (click)="isEbb || isIframe ? closeModal() : undefined"
        attr.target="{{ isEbb || isIframe ? '_blank' : undefined }}"
        data-dgat="addToPathway-af2"
        >{{ i18n.addToPathwayModal_EditPathway }}</a
      >
      <button
        df-button
        dfButtonType="primary"
        (click)="closeModal()"
        data-dgat="addToPathway-7fe"
      >
        {{ i18n.Core_Done }}
      </button>
    </dgx-modal-footer>
  </div>

  <!-- Open Create New Pathway for Ebb -->
  <div *ngIf="displayArea === 'ebbCreatePathway'">
    <div class="guts-p-full-2 center-text">
      <p class="h4">{{ i18n.dgManagePathways_CreatePathway }}</p>
    </div>

    <dgx-modal-footer [useDefaultSubmitButton]="false">
      <button
        df-button
        dfButtonType="primary"
        (click)="openCreatePathway()"
        data-dgat="addToPathway-7fr"
      >
        {{ i18n.OnboardingFooter_NextStep }}
      </button>
    </dgx-modal-footer>
  </div>
</div>
