import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';
import { lastValueFrom, map } from 'rxjs';
import { FormGroup } from '@angular/forms';
// Services
import { VideoFacadeBase } from '../video-facade-base.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { VideoApiInput, VideoModel } from '../../video.model';
import { InputContext } from '@app/user-content/user-input/user-input.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, TrackerService } from '@app/shared/services';
import {
  AzureUserAuthoredResult,
  CHUploadService,
} from '@degreed/content-hosting-data-access';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { VideoMapperService } from '../video-mapper.service';
import { VideoUploadAdapterV2 } from '../video-upload.adapter';
import { VideoService } from '../video.service';
import { VideoNotificationService } from '../video-notification.service';
import { VideoTrackerService } from '../video-tracker.service';
// Misc
import { RenderMode } from '@app/user-content/user-input/user-input.model';
import { getHostedContentDetails } from '@app/user-content/user-input-v2/inputs/video/services/video.utils'; // TODO:
import { DgError } from '@app/shared/models/dg-error';
import { AuthUser } from '@app/account/account-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import { MediaParseType } from '@app/shared/models/core.enums';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VideoCatalogFacade extends VideoFacadeBase {
  constructor(
    public translate: TranslateService,
    protected inputsService: InputsService,
    protected authService: AuthService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected orgInternalContentService: OrgInternalContentService,
    protected videoMapperService: VideoMapperService,
    protected videoService: VideoService,
    private contentHostingUploadService: CHUploadService,
    private tracker: TrackerService,
    private webEnvironmentService: WebEnvironmentService,
    private uploader: UploaderService,
    private uploadAdapter: VideoUploadAdapterV2,
    private videoNotificationService: VideoNotificationService,
    private videoTrackerService: VideoTrackerService
  ) {
    super(
      inputsService,
      authService,
      inputImageUploadAdapterService,
      orgInternalContentService,
      videoMapperService,
      videoService
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  protected get orgId(): number {
    return this.authService.authUser.defaultOrgId;
  }
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): VideoModel {
    return readFirst(this.viewModel$);
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      await super.onSubmit(form);
      this.performSuccessSideEffects();
    } catch (e) {
      this.performFailureSideEffects(e);
    }
    return;
  }

  public async onNext(url: string): Promise<void> {
    // TODO: part of this can go into the base facade
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);
      // 1. update the view model with the  url
      this.viewModel = { ...this.viewModel, entryUrl: url };
      // 2. Check and fetch any duplicates for the url
      // Note: We are going to continue on to the full form page even with a duplicate
      // https://degreedjira.atlassian.net/browse/PD-92403
      await this.fetchUrlDuplicates(url);
      // 3.  Parse url (diffbot)
      const result: VideoApiInput =
        (await this.inputsService.getMediaMetadataAsPromise(
          url,
          this.viewModel.inputContext.inputType,
          MediaParseType.None
        )) as VideoApiInput;
      // TODO: PD-92821
      // 4. Update the viewModel with the results
      const updatedView = this.videoMapperService.toViewModel(
        result,
        this.viewModel
      );

      this.viewModel = {
        ...this.viewModel,
        ...updatedView,
        isInitialForm: false,
        organizationId: this.orgId,
        owner: undefined,
      };

      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      return;
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentDelete: this.onContentDelete.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      addToCatalog: true, // always true since we are in catalog
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: false,
      },
      errorMessages: {
        invalidFileType: this.translate.instant(
          'dgContentHosting_InvalidFileType',
          {
            startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
              '/articles/4408914250514'
            )}">`,
            endAnchor: '</a>',
          }
        ),
      },
    };

    // Check and initial content hosting properties
    const authUser = this.authService.authUser;
    await this.checkForContentHostingSupport(authUser);
  }
  /********************************************************
   * Content hosting
   ********************************************************/
  public onContentDelete(formGroup: FormGroup) {
    this.viewModel = {
      ...this.viewModel,
      hostedContentDetails: null,
    };
    formGroup.patchValue({
      contentUploader: '',
    });
  }
  /**
   * Handle updating the UI form after uploading a recorded video
   * @param formControl - mediaUrl form control
   * @param recordedFileResult - result returned from uploading a recorded video
   */
  public recordedVideoUploaded(
    formGroup: FormGroup,
    fieldName: string,
    recordedFileResult: AzureUserAuthoredResult
  ): void {
    this.onContentUploadSuccess(formGroup, fieldName, recordedFileResult, true);
  }

  /**
   * Check if we can display the content hosting uploader
   * @param authUser
   */
  private async checkForContentHostingSupport(authUser: AuthUser) {
    const request$ = this.contentHostingUploadService.canUploadHostedFile({
      uploadType: 'Video',
      renderMode: this.viewModel.inputContext.renderMode,
    });
    const response = await lastValueFrom(request$);
    const [supportsContentHosting, fileRestrictions] = response;

    this.viewModel = {
      ...this.viewModel,
      // determines to show the record a video
      shouldShowRecordVideoButton:
        !authUser.defaultOrgInfo?.settings?.disableUserGeneratedContent &&
        authUser.canRecordVideo &&
        this.viewModel.inputContext.renderMode === RenderMode.ContentCatalog,
      // determines to load upload-section component in modal container
      shouldShowContentUploader:
        supportsContentHosting ||
        (!authUser.canUploadContentFiles &&
          this.viewModel.inputContext.isEditing &&
          this.viewModel.inputContext.renderMode === RenderMode.ContentCatalog),
      // the upload-section component should be displayed but disabled
      shouldDisableContentUploader:
        !supportsContentHosting &&
        this.viewModel.inputContext.renderMode === RenderMode.ContentCatalog &&
        this.viewModel.inputContext.isEditing,
      fileRestrictions: fileRestrictions,
      uploadAdapter: this.uploadAdapter,
    };
    if (this.viewModel.shouldShowRecordVideoButton) {
      const request2 = this.uploader.getUploadSettings().pipe(
        map((s) => ({
          settings: s,
          dgOrgInternalContentForm_VideoTimeLimit: this.translate.instant(
            'dgOrgInternalContentForm_VideoTimeLimit',
            {
              timeLimit: s.maxVideoLengthMinutes,
            }
          ),
        }))
      );
      const response2 = await lastValueFrom(request2);
      this.viewModel = {
        ...this.viewModel,
        videoConstraints: response2,
      };
    }
  }
  protected onContentFileChange(file: File) {
    super.onContentFileChange(file);
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: AzureUserAuthoredResult,
    isRecordedVideo?: boolean
  ) {
    super.onFormControlUpdate(
      formGroup,
      fieldName,
      isRecordedVideo ? response.result.fileUrl : response
    );
    const details = getHostedContentDetails(response);
    if (!details) {
      throw new DgError(
        this.translate.instant('dgFileUploadButton_UploadErrorMessage')
      );
    }
    super.onContentUploadSuccess(formGroup, fieldName, details);
    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      hostedContentDetails: details,
      entryUrl: details.url,
    };
    this.tracker.trackEventData({
      action: 'File Uploaded',
      properties: {
        actionLocation: 'Manage Org File Upload',
        contentType: isRecordedVideo ? 'Recorded Video' : 'Video',
        contentId: this.viewModel.inputId,
      },
    });
  }
  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.videoNotificationService.notifyVideoInputCreated(
        this.viewModel.title
      );

      this.videoTrackerService.trackContentCatalogAdd(this.viewModel);
      return;
    }
    const apiParameters = this.videoMapperService.toApiParameters(
      this.viewModel as VideoModel
    );

    this.videoNotificationService.notifyVideoInputUpdated();
    this.videoTrackerService.trackContentCatalogUpdate(apiParameters);
  }

  /** Performs any side effects required following failed creation of an Input */
  protected performFailureSideEffects(error?: HttpErrorResponse) {
    if (!this.viewModel.inputContext.isEditing) {
      this.videoNotificationService.notifyVideoInputCreateFailed();
      return;
    }

    this.videoNotificationService.notifyContentCatalogInputUpdateFailed(error);
  }
}
