<div class="l_flex" *ngIf="!isLoading">
  <dgx-search-filter
    *ngFor="let searchFilter of searchFilters | keyvalue: originalOrder"
    [searchFilter]="searchFilter.value"
    (filtersChange)="applyFilters($event)"
    data-dgat="search-filters-9e0"
  ></dgx-search-filter>

  <div
    class="guts-m-l-auto guts-m-v-auto guts-m-r-half"
    *ngIf="showResetFilters"
  >
    <button
      (click)="clearFilters()"
      class="link block color-blue par--small font-medium uhover"
      data-dgat="search-filters-374"
    >
      <span class="ib-v-middle">
        {{ i18n.Core_ResetFilters }}
      </span>
    </button>
  </div>
</div>

<div *ngIf="isLoading">
  <dgx-skeleton
    type="search-filters"
    [columns]="numberOfSkeletonColumns"
  ></dgx-skeleton>
</div>
