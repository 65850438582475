<label *ngIf="!hideLabel" for="tag-search-input">{{
  i18n.ContentTabs_RelatedSkills
}}</label>

<div class="field-group" data-dgat="searchTags-e1a">
  <dgx-search-field-wrapper
    *ngIf="useSearchStyle; else useInputStyle"
    class="full-width"
    [ngClass]="{
      'bg-color-ebony-a03': ngDisabled
    }"
  >
    <ng-container *ngTemplateOutlet="searchField"></ng-container>
  </dgx-search-field-wrapper>

  <ng-template #useInputStyle>
    <span
      class="field-group__input field-group__input--tag guts-p-full-0"
      [ngClass]="{
        'bg-color-ebony-a03': ngDisabled,
        'suggested-skills-view-container': useSuggestedSkillsView
      }"
    >
      <ng-container *ngTemplateOutlet="searchField"></ng-container>
    </span>
  </ng-template>
</div>

<div *ngIf="!hideTopTags" class="top-tags">
  <div *ngIf="hint" class="micro explanation guts-m-v-half">
    {{ hint }}
  </div>

  <p class="hidden" id="tag-search-top-tags-desc-{{ instanceId }}">
    {{ i18n.TagsSearch_A11yTopTagsHint }}
  </p>

  <ul [ngClass]="{ 'guts-m-t-half': !hint }">
    <li
      *ngFor="let tag of topTags; trackBy: trackByTitle"
      class="ib"
      data-dgat="tagsEdit-f54"
    >
      <button
        type="button"
        (click)="selected(tag)"
        class="guts-m-r-half break guts-m-b-half btn btn-passive btn-sm"
        data-dgat="tagsEdit-fb2"
        [attr.aria-label]="
          'dgTagsEdit_AddTagFormat' | translate: { tag: tag.title }
        "
      >
        <div class="l_flexbar gap-full-half">
          <df-icon icon="plus"></df-icon>
          <div>{{ tag.title | titlecase }}</div>
        </div>
      </button>
    </li>
  </ul>
</div>

<div
  *ngIf="hideTopTags && showHintAlone"
  class="micro explanation guts-m-v-half"
>
  {{ hint }}
</div>

<p class="hidden" id="tag-search-input-desc-{{ instanceId }}">
  {{ i18n.dgGlobalSearch_A11ySearchDescription }}
</p>

<ng-template #searchField>
  <div class="guts-p-l-1 l_flex">
    <div *ngIf="!useNewTagsDesign" class="guts-p-t-1">
      <df-icon
        icon="tag"
        *ngIf="!useSearchStyle && !useSuggestedSkillsView"
        class="guts-m-r-1 color-ebony-a61"
      ></df-icon>
    </div>
    <div
      class="guts-m-v-quart"
      [ngClass]="{
        'field-group__input--full-width': useNewTagsDesign
      }"
    >
      <button
        type="button"
        *ngFor="let tag of tags; trackBy: trackByTitle"
        class="
          btn btn-sm
          break
          bg-color-ebony-a08
          color-ebony
          guts-m-r-half guts-m-v-quart
        "
        [ngClass]="{ 'selected-suggested-tag': useSuggestedSkillsView }"
        (click)="remove(tag)"
        data-dgat="editDetailsModal-a93"
      >
        <span [innerText]="tag.title | titlecase"></span>
        <df-icon
          icon="cross"
          size="small"
          class="guts-m-l-quart color-ebony-a61"
          [ngClass]="{ 'color-white': useSuggestedSkillsView }"
        ></df-icon>
      </button>
      <span
        [ngClass]="{
          'field-group__input-wrapper': useNewTagsDesign
        }"
      >
        <df-icon
          *ngIf="useNewTagsDesign"
          icon="magnifying-glass"
          class="guts-m-r-half color-ebony-a61"
        ></df-icon>
        <input
          #tagsInput
          attr.aria-describedby="
            tag-search-input-desc-{{ instanceId }}
            tag-search-top-tags-desc-{{ instanceId }}
          "
          attr.aria-owns="tag-search-popover-{{ instanceId }}"
          autocomplete="off"
          id="tag-search-input"
          [ngClass]="{
            'field-group__input--full-width': useNewTagsDesign
          }"
          name="tagSearch"
          role="combobox"
          type="text"
          [(ngModel)]="searchTerm"
          (input)="onInputChange($event)"
          (keypress)="inputKeyPress($event)"
          maxlength="255"
          [attr.aria-label]="
            searchInputLabel
              ? undefined
              : i18n.TargetResourcesForm_SearchPlaceHolder
          "
          [disabled]="ngDisabled"
          [placeholder]="placeholderText || i18n.Core_CategoryName"
          data-dgat="searchTags-d9b"
        />
      </span>
      <df-popover
        #popoverComponent
        id="tag-search-popover-{{ instanceId }}"
        (isOpenChange)="onIsPopoverOpenChange($event)"
        [(isOpen)]="shouldShowSuggestions"
        [popoverTrigger]="tagsInputRef"
        [isFocusFirstItem]="false"
        placement="bottom-left"
        [ngClass]="{ 'full-width-popover': useSuggestedSkillsView }"
      >
        <!--Search input auto suggest menu contents. role=menu and role=menuitem are both a11y attributes AND custom directives that enable keyboard navigation-->
        <div role="menu">
          <p
            *ngIf="suggestSkills"
            class="guts-p-l-1 guts-p-v-1 color-ebony-a61 par--small font-medium"
            [innerText]="
              'SkillRegistrySearch_SkillRegistrySuggestions' | translate
            "
          ></p>
          <ng-container
            *ngFor="
              let suggestion of suggestions;
              let first;
              let last;
              trackBy: trackByTitle
            "
            data-dgat="tags-search-622"
          >
            <button
              class="l_flexbar guts-p-v-1 guts-p-l-0 guts-p-r-1 listbox--option"
              data-dgat="tags-search-fe6"
              role="menuitem"
              (click)="selected(suggestion, $event)"
            >
              <div
                *ngIf="!useSuggestedSkillsView"
                class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1"
              >
                <df-icon icon="tag" class="color-ebony-a61"></df-icon>
              </div>
              <div
                class="par par--small l_flexbar left-text"
                [ngClass]="{ 'guts-m-l-2': useSuggestedSkillsView }"
              >
                <span
                  [innerHtml]="
                    suggestion.title
                      | encodeToEntities
                      | highlightSearchText: searchTerm
                  "
                ></span>
                <img
                  class="tag-endorsed-img v-bottom guts-p-l-quart"
                  *ngIf="suggestion.endorsedSrc"
                  [attr.alt]="i18n.Core_Endorsed"
                  src="{{ suggestion.endorsedSrc }}?brandlogo"
                />
              </div>
            </button>
          </ng-container>
        </div>
      </df-popover>
      <df-popover
        #popoverComponentCustomTag
        id="tag-search-popover-{{ instanceId }}"
        (isOpenChange)="onIsPopoverOpenChange($event)"
        [(isOpen)]="shouldShowCustomTag"
        [popoverTrigger]="tagsInputRef"
        [isFocusFirstItem]="false"
        placement="bottom-left"
        [ngClass]="{ 'full-width-popover': useSuggestedSkillsView }"
      >
        <div role="menu">
          <ul class="typeahead-list js-suggestion-list">
            <li class="typeahead-item guts-p-b-half">
              <p
                class="
                  guts-p-l-1 guts-p-v-1
                  color-ebony-a61
                  par--small
                  font-medium
                "
                [innerText]="i18n.TargetResourcesForm_NoResults"
              ></p>
              <button
                role="menuitem"
                class="guts-p-v-0 guts-p-l-0 guts-p-r-1 listbox--option"
                (click)="selected(customItem, $event)"
                data-dgat="searchTags-d22"
              >
                <div class="l_flexbar guts-p-v-1 guts-p-h-0">
                  <div
                    *ngIf="!useSuggestedSkillsView"
                    class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1"
                  >
                    <df-icon icon="tag" class="color-ebony-a61"></df-icon>
                  </div>
                  <div
                    class="l_flex-grow"
                    [ngClass]="{ 'guts-m-l-1': useSuggestedSkillsView }"
                  >
                    <h3
                      class="par par--small font-bold"
                      [innerText]="searchTerm"
                    ></h3>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </df-popover>
    </div>
  </div>
</ng-template>
