import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import {
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { ColorService } from '@app/shared/services/color.service';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { AssignmentBadgeComponent } from '../../assignment-badge/assignment-badge.component';
import { InputSession } from '@app/inputs/inputs-api.model';
import { ActivatedRoute } from '@angular/router';
import { ContentDurationService } from '@app/shared/services/content/content-duration.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileFlagsService } from '@app/profile/services/profile-flags.service';

export interface MetadataConfig {
  compact: boolean;
  percentComplete?: number;
  isEnrolledInPathway?: boolean;
  isAcademy?: boolean;
}

@Component({
  selector: 'dgx-learning-resource-metadata',
  templateUrl: './learning-resource-metadata.component.html',
  styleUrls: ['./learning-resource-metadata.component.scss'],
})
export class LearningResourceMetadataComponent
  implements OnInit, AfterViewInit
{
  @Input() public liveSessions: InputSession[];
  @Input() public isMoreDetailsView?: boolean;
  @Input() public isTileCardView?: boolean;
  @Input() public metaData: LearningResourceViewModel['metaData'];
  @Input() public config?: MetadataConfig;
  @Input() public isOptional?: boolean = false;
  @Input() public assignmentInfo?: RecommendationInfo;
  @Input() public assignmentCssClass?: string;
  @Input() public searchTerm?: string;
  @Input() public resourceType?;
  @Input() public isVerified = false;
  @Input() public disableProviderLink = false;
  @Input() public isCompleted?: boolean = false;

  @ViewChild(AssignmentBadgeComponent)
  public assignmentBadgeComponentRef: AssignmentBadgeComponent;
  public Object = Object;
  public progressCircleColor = this.ColorSvc.getColor('blue');
  public progressCircleTrailColor = this.ColorSvc.getColor('ebony-a08');
  public isAssignmentBadgeShowing: boolean;
  public minutesToDisplay: string;
  public hoursToDisplay: string;
  public showLegacyDurationDisplay: boolean;
  public translatedResourceLabel: string;
  public i18n: Record<string, string>;
  public showProgress: boolean;
  public isMarketplace: boolean;

  // public useNewAssignmentsCards =
  //   this.profileFlagsService.showProfileAssignmentsCards;

  constructor(
    private contentDurationService: ContentDurationService,
    private searchTrackerService: SearchTrackerService,
    private searchUrlService: SearchUrlService,
    private ColorSvc: ColorService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private profileFlagsService: ProfileFlagsService
  ) {}

  public get providerLinkEnabled() {
    return !!this.metaData?.providerId && !this.disableProviderLink;
  }

  public get pathwayCssClasses() {
    let pathwayClassList: string = '';

    if (this.config?.percentComplete && this.config?.percentComplete === 100) {
      pathwayClassList += ' badge-pill--compact badge-success';
    } else {
      pathwayClassList += ' badge-pill--compact badge-emphasize';
    }

    return pathwayClassList;
  }

  public get showResourceLabel() {
    return (
      (this.isMarketplace || !this.config?.isAcademy) &&
      this.metaData.resourceLabel &&
      this.resourceType !== 'Pathway' &&
      (!this.isAssignmentBadgeShowing || this.assignmentInfo.alreadyCompleted)
      // ||
      // this.useNewAssignmentsCards
    );
  }

  public get showPathwayProgress() {
    return (
      ((!this.isCompletedPathway &&
        this.resourceType === 'Pathway' &&
        (!this.assignmentInfo || this.assignmentInfo.alreadyCompleted)) ||
        this.showProgress) &&
      !this.showAssignmentBadge
    );
  }

  public get isCompletedPathway() {
    return (
      this.resourceType === 'Pathway' && this.config.percentComplete === 100
    );
  }

  public get isCompletedAcademy() {
    return this.config?.isAcademy && this.isCompleted;
  }

  public get showAssignmentBadge() {
    if (this.resourceType === 'Pathway') {
      return !!this.assignmentInfo && !this.isCompletedPathway;
    }
    return !!this.assignmentInfo && !this.assignmentInfo.alreadyCompleted;
  }

  public ngOnInit(): void {
    this.translatedResourceLabel = this.getTranslatedResourceLabel(
      this.metaData.resourceLabel
    );
    this.showLegacyDurationDisplay =
      this.contentDurationService.setShowLegacyDurationDisplay(
        this.isMoreDetailsView,
        this.isTileCardView,
        this.translatedResourceLabel
      );
    this.i18n = this.translateService.instant([
      'Core_Event',
      'Core_Optional',
      'dgContentTile_Verified',
      'Core_Completed',
    ]);
    this.showProgress =
      this.resourceType === 'Pathway' &&
      this.config?.percentComplete >= 0 &&
      this.config?.percentComplete < 100 &&
      this.config?.isEnrolledInPathway;
    this.isMarketplace = this.route.snapshot.queryParams.isMarketplaceCat;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.metaData && changes.metaData.currentValue) {
      this.translatedResourceLabel = this.getTranslatedResourceLabel(
        changes.metaData.currentValue.resourceLabel
      );

      const shouldDurationTimeBeCalculated =
        this.contentDurationService.shouldDurationTimeBeCalculated(
          this.translatedResourceLabel,
          this.isMoreDetailsView,
          this.isTileCardView
        );
      if (shouldDurationTimeBeCalculated) {
        const { hoursToDisplay, minutesToDisplay } =
          this.contentDurationService.calculateDurationTime(
            this.translatedResourceLabel,
            Number(changes.metaData.currentValue.durationMinutes),
            Number(changes.metaData.currentValue.durationHours),
            this.isTileCardView
          );
        this.hoursToDisplay = hoursToDisplay;
        this.minutesToDisplay = minutesToDisplay;
      } else {
        this.minutesToDisplay = null;
      }

      if (changes.config?.currentValue) {
        this.config.percentComplete = Math.round(
          changes.config.currentValue.percentComplete || 0
        );
      }
    }
  }

  public ngAfterViewInit() {
    this.isAssignmentBadgeShowing = this.assignmentBadgeComponentRef?.showBadge;
    this.cdr.detectChanges();
  }

  public getProviderUrl(providerId: number) {
    const isExternalCat = this.route.snapshot.queryParams.isExternalCat;

    return this.searchUrlService.getProviderSearchURL({
      providerId,
      isExternalCat,
    });
  }

  public trackLinkClick() {
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.providerLink,
    });
    this.searchTrackerService.searchResultViewChanged({
      linkClicked: SearchSubmitMethod.providerLink,
    });
  }

  private getTranslatedResourceLabel(resourceLabel: string): string {
    if (resourceLabel === 'Episode') {
      return this.translateService.instant('Core_Podcast');
    } else if (resourceLabel === 'Task' || resourceLabel === 'Post') {
      return this.translateService.instant(`Core_${resourceLabel}`);
    }
    return resourceLabel;
  }
}
