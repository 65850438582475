import { InputDetails, PositionLevel } from '@app/inputs/inputs-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { Observable } from 'rxjs';

/************************************************************************
 * EXPERIENCE SPECIFIC
 ***********************************************************************
 */
/**
 * Complete VM
 */
export type ExperienceModel = ExperienceFormDataModel &
  InputContext &
  ExperienceComputed &
  ExperienceApi;

/**
 * Properties we compute for the VM
 */
interface ExperienceTypeOptions {
  id: string;
  inputSubType: string;
}

export interface ExperienceComputed {
  inputContext: InputContext;
  experienceTypeOptions: ExperienceTypeOptions[];
  levelOptions: { id: string; level: string }[];
  isSubmitButtonDisabled: boolean;
  duplicates: InputDetails[];
  shouldSpinSubmitButton$: Observable<boolean>;
  inferredSkills$: Observable<TagsApi.Tag[]>;
}

interface ExperienceApi {
  loadInferredSkills: (title: string, description: string) => void;
}

/**
 * Mapping to what is returned to the API on save/edit
 */
export interface ExperienceMappingToAPI {
  title: string;
  startDate: string;
  endDate: string;
  hoursPerWeek: number;
  isCurrent: boolean;
  level: string;
  inputSubType: string; // this is the experience type, eg JobRole, Mentorship, etc
  organizationName: string;
  inputType: string;
  tags: TagsApi.Tag[];
  description: string;
  inputSubTypePeerName?: string; // this is the name of the mentor/mentee when the experience type is Mentorship/Menteeship
}

export interface ExperienceFormDataModel {
  isCurrent: boolean;
  experienceType: string;
  title: string;
  orgName: string;
  hoursPerWeek: number;
  level: PositionLevel;
  description: string;
  skills: TagsApi.Tag[];
  dateRangeForm: {
    startDate: Date;
    endDate: Date;
  };
  menteeName?: string;
  mentorName?: string;
}

export interface InferredSkillsModel {
  mediumConfidence: string[];
  highConfidence: string[];
}

export type GlobalAddExperienceField =
  | 'experienceType'
  | 'title'
  | 'orgName'
  | 'isCurrent'
  | 'startDate'
  | 'endDate'
  | 'hoursPerWeek'
  | 'level'
  | 'description'
  | 'skills'
  | 'mentorName'
  | 'menteeName';

export enum ExperienceTypes {
  JobRole = 'JobRole',
  Project = 'Project',
  Mentorship = 'Mentorship',
  Menteeship = 'Menteeship',
  Shadowing = 'Shadowing',
  StretchAssignment = 'StretchAssignment',
  Other = 'Other',
}
