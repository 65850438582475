<div
  class="dgx-pagination guts-p-h-1-half guts-p-v-1 {{ className }}"
  [ngClass]="{ hasBoundaryLinks: boundaryLinks }"
>
  <div class="grid grid--bleed">
    <div *ngIf="showPerPage" class="grid__col-3 no-tablet"></div>

    <div class="grid__col-auto dgx-pagination__paging-col">
      <ngb-pagination
        [(page)]="page"
        [boundaryLinks]="boundaryLinks"
        [collectionSize]="collectionSize"
        [directionLinks]="directionLinks"
        [disabled]="disabled"
        [ellipses]="ellipses"
        [maxSize]="maxSize"
        [pageSize]="pageSize"
        [rotate]="rotate"
        (pageChange)="onPageChange($event)"
      >
        <ng-template ngbPaginationFirst>
          <df-icon
            icon="chevron-double-left"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
        </ng-template>
        <ng-template ngbPaginationPrevious>
          <df-icon
            icon="chevron-left"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
        </ng-template>
        <ng-template ngbPaginationNext>
          <df-icon
            icon="chevron-right"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
        </ng-template>
      </ngb-pagination>
    </div>

    <div *ngIf="showPerPage" class="grid__col-4 grid__col-lg-3">
      <div class="grid__cell right-text par par--small color-ebony-a61">
        <span class="ib-v-middle no-tablet">
          {{ i18n.Core_Show }}
        </span>
        <dgx-menu
          [appendToBody]="true"
          [menuConfig]="menuConfig"
          [autoCloseOnItemSelect]="true"
          placement="bottom-left"
          class="
            ib
            guts-m-h-half
            l_flex--align-right l_flex--align-right--no-mobile
          "
        >
          <button
            df-button
            dfButtonType="passive"
            aria-haspopup="true"
            class="btn-dropdown btn-dropdown--sm"
            data-dgat="pagination-component-794"
          >
            <span class="guts-m-r-half">{{ pageSize }}</span>
            <df-icon
              size="small"
              class="btn-dropdown__icon btn-dropdown__icon--sm"
              icon="chevron-down"
            ></df-icon>
          </button>
        </dgx-menu>
        <span class="ib-v-middle">
          <span class="no-tablet">
            {{ i18n.Pagination_OfYResultsFormat }}
          </span>
          <span class="tablet-only">
            {{ i18n.Pagination_OfYFormat }}
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
