<div *ngIf="liveEvent">
  <ul
    class="l_flexbar m-l_flex font-medium par par--light_a par--small no-wrap"
    [class.resource-meta--compact]="config?.compact"
  >
    <!-- these can be assignments or collection items, collection items won't have assignment info-->
    <li
      *ngIf="isOptional"
      class="resource-meta__item"
      [title]="'Core_Optional' | translate"
    >
      <span class="no-wrap badge badge-pill badge-pill--compact badge-neutral">
        {{ 'Core_Optional' | translate }}
      </span>
    </li>
    <li
      *ngIf="assignmentInfo?.reference?.isVerified || isVerified"
      data-dgprop-item-clicked="Verified"
      class="guts-m-r-half resource-meta__item"
      [title]="'dgContentTile_Verified' | translate"
    >
      <df-icon
        icon="checkmark-circle"
        size="small"
        class="color-success-dark guts-m-r-quart verified-icon"
      ></df-icon>
      <span class="color-success-dark">{{
        'dgContentTile_Verified' | translate
      }}</span>
    </li>

    <!-- required/assigned badge -->
    <li
      class="resource-meta__item rel skip-delimiter"
      *ngIf="viewModel.showAssignementBadge"
    >
      <!--
        Linter Error:
        [resourceType]="resourceType"
        Property 'resourceType' does not exist on type 'LiveEventMetadataComponent'.
      -->

      <dgx-assignment-badge
        [assignmentInfo]="assignmentInfo"
      ></dgx-assignment-badge>
    </li>
    <!-- registered badge -->
    <li *ngIf="viewModel.showRegisteredBadge" class="resource-meta__item">
      <span class="badge badge-pill badge-pill--compact badge-emphasize">{{
        'Core_Registered' | translate
      }}</span>
    </li>

    <!-- live badge -->
    <li
      *ngIf="viewModel.showLiveBadge"
      class="resource-meta__item color-destructive"
    >
      <df-icon icon="broadcast" size="small" class="guts-m-r-quart"></df-icon>
      <span class="up-me font-semibold">{{ 'Core_Live' | translate }}</span>
    </li>

    <li
      *ngIf="viewModel.showInputType"
      class="resource-meta__item skip-delimiter"
    >
      {{ translatedInputType }}
    </li>

    <!-- date of single session -->
    <li *ngIf="viewModel.showDate" class="resource-meta__item">
      {{ liveEvent.startDateFormatted }}
    </li>

    <!-- date and city of single session -->
    <li *ngIf="viewModel.showLocation" class="resource-meta__item">
      {{ location }}
    </li>

    <!-- multiple sessions -->
    <li *ngIf="viewModel.showMultiSession" class="resource-meta__item">
      {{ 'Core_Multisession' | translate }}
    </li>
  </ul>
</div>
