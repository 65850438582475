import { Injectable } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { Observable } from 'rxjs';
import { GiveFeedbackModalComponent } from '../components/modals/give-feedback-modal/give-feedback-modal.component';
import {
  PeerRatingModal,
  PeerRatingModalComponent,
} from '../components/modals/peer-rating-modal/peer-rating-modal.component';
import { Mentorship, MentorshipType } from '../mentoring-api.model';

@Injectable({
  providedIn: 'root',
})
export class MentoringReviewModalService {
  constructor(private modalService: ModalService) {}

  public openRequestPeerRatingModal(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ): Observable<{
    updatedMentorship: Mentorship;
    modalResponse: PeerRatingModal;
  }> {
    const inputs = {
      mentorship: mentorship,
      mentorshipType: mentorshipType,
    };

    return this.modalService.show(PeerRatingModalComponent, {
      inputs,
    });
  }

  public openGiveFeedbackModal(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ): Observable<{ updatedMentorship: Mentorship; message: string }> {
    const inputs = {
      mentorship: mentorship,
      mentorshipType: mentorshipType,
    };

    return this.modalService.show(GiveFeedbackModalComponent, { inputs });
  }
}
