import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthUser } from '@app/account/account-api.model';
import { ConnectWithMentorRequest } from '@app/mentoring/mentoring-api.model';
import {
  MentorableTagType,
  MentorshipStatusEnum,
} from '@app/mentoring/mentoring.enums';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';
import { DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectWithMentorService } from './connect-with-mentor.service';

@Component({
  selector: 'dgx-connect-with-mentor-modal',
  templateUrl: './connect-with-mentor-modal.component.html',
  styleUrls: ['./connect-with-mentor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectWithMentorModalComponent implements OnInit {
  @Input() public mentor: UserProfileSummary;
  @Input() public mentee: AuthUser;
  public i18n = this.translate.instant([
    'Onboarding_SearchSkills_SearchForSkills',
    'Opportunities_ConnectWithModal_Description',
    'Opportunities_ConnectWithModal_Labels_FocusSkills',
    'Opportunities_ConnectWithModal_Labels_FocusSkills_Description',
    'Opportunities_ConnectWithModal_Labels_MatchingSkills',
    'Opportunities_ConnectWithModal_Labels_MatchingSkills_Description',
    'Opportunities_ConnectWithModal_Labels_Reason',
    'Opportunities_ConnectWithModal_Placeholders_Reason',
    'Opportunities_ConnectWithModal_SubmitButton',
  ]);
  public availableTags: UserInterest[] = [];
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public headerText = '';
  public mentorableTagType: MentorableTagType = MentorableTagType.HideTags;
  public model: ConnectWithMentorRequest = {
    mentorshipStatusName: MentorshipStatusEnum.Requested,
    description: '',
    tags: [],
  };
  public options: FormlyFormOptions = {};
  public selectedTags: UserInterest[] = [];
  public tagsHeaderText = '';
  public tagsDescriptionText = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private connectWithMentorService: ConnectWithMentorService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    // determine tags list type
    this.mentorableTagType = this.connectWithMentorService.getMentorableTagType(
      this.mentor,
      this.mentee
    );
    // failing that show message -> prompt user to add focus skills
    // --> open new tab? -> https://localhost:44300/profile/vanlocaldev/skills ?
    // get an array of available tags
    this.availableTags = this.connectWithMentorService.getMentorableTags(
      this.mentor,
      this.mentee,
      this.mentorableTagType
    );
    // set dynamic or interpolated translation strings
    this.setTranslatedText();
    // update the model
    this.model.tags = this.availableTags;
    this.model.mentorUserProfileKey = this.mentor.userProfileKey;
    // set the fields
    this.fields = [
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          label: this.i18n.Opportunities_ConnectWithModal_Labels_Reason,
          maxLength: 255,
          placeholder:
            this.i18n.Opportunities_ConnectWithModal_Placeholders_Reason,
        },
      },
    ];
    // update view
    this.cdr.markForCheck();
  }

  public setTranslatedText(): void {
    // set the modal header text
    this.headerText = this.translate.instant(
      'Opportunities_ConnectWithModal_ConnectWithFormatted',
      { name: this.mentor.name }
    );
    // set tags header / description text
    switch (this.mentorableTagType) {
      // return matching skills that the mentor has agreed to mentor
      case MentorableTagType.ShowMatchingTags:
        this.tagsHeaderText =
          this.i18n.Opportunities_ConnectWithModal_Labels_MatchingSkills;
        this.tagsDescriptionText =
          this.i18n.Opportunities_ConnectWithModal_Labels_MatchingSkills_Description;
        break;
      // return the user's own focused skills
      case MentorableTagType.ShowFocusedTags:
        this.tagsHeaderText =
          this.i18n.Opportunities_ConnectWithModal_Labels_FocusSkills;
        this.tagsDescriptionText =
          this.i18n.Opportunities_ConnectWithModal_Labels_FocusSkills_Description;
        break;
      // no tags for display, return an empty array
      default:
        this.tagsHeaderText =
          this.i18n.Opportunities_ConnectWithModal_Labels_FocusSkills;
        this.tagsDescriptionText = this.translate.instant(
          'Opportunities_ConnectWithModal_Labels_FocusSkills_Description_NoSkillsExplainer',
          {
            startAnchor: `<a href="/profile/${this.mentee.viewerProfile.vanityUrl}/skills" class="link" target="_blank" rel="noopener noreferrer" data-dgat="connect-with-mentor-modal-964">`,
            endAnchor: '</a>',
          }
        );
    }
  }

  public onSubmit(event: Event): void {
    event.preventDefault();
    this.activeModal.close({
      ...this.model,
      tags: this.connectWithMentorService.formatTagsForServer(this.model.tags),
    });
  }
}
