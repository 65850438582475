<div class="profile-pic__container">
  <div
    class="profile-pic"
    #profilePic
    [attr.role]="
      !isEngaged || hideTooltip || !engagedLearnerService.isEnabled
        ? 'presentation'
        : 'button'
    "
    [class.profile-pic--fallback]="useFallbackClass && fallbackLoaded"
    [ngStyle]="{
      width: sizeScalar / 12 + 'rem',
      height: sizeScalar / 12 + 'rem'
    }"
    [ngbTooltip]="engagedLearnerTooltip"
    [disableTooltip]="
      !isEngaged || hideTooltip || !engagedLearnerService.isEnabled
    "
    [autoClose]="false"
    triggers="manual"
    #tooltip="ngbTooltip"
    (mouseenter)="tooltip.open()"
    (mouseleave)="checkHover($event)"
    (focus)="tooltip.open()"
    (blur)="tooltip.close()"
    (click)="showEngagedLearnerDetails($event)"
    (keydown)="keydownHandler($event)"
    [tabindex]="profileClickable ? -1 : tabIndex"
  >
    <!-- Removed for early adopter testing -->
    <dgx-profile-engagement
      *ngIf="isEngaged"
      [size]="sizeScalar"
    ></dgx-profile-engagement>

    <ng-container *ngIf="profileClickable; else profileImage">
      <a [attr.href]="'/' + profile?.vanityUrl" data-dgat="profile-pic-519">
        <ng-template [ngTemplateOutlet]="profileImage"></ng-template>
      </a>
    </ng-container>
  </div>

  <div
    *ngIf="canViewMentorship"
    class="profile-pic__mentor-image"
    [ngClass]="baseMentorImageSizeClass + mentorImageSize"
  >
    <df-icon
      icon="mentor"
      class="profile-pic__mentor-icon color-blue"
      [size]="mentorImageSize"
    ></df-icon>
  </div>
</div>

<ng-template #engagedLearnerTooltip>
  <span
    *ngIf="isEngaged && !hideTooltip && engagedLearnerService.isEnabled"
    class="cursor-pointer no-wrap"
    (click)="showEngagedLearnerDetails($event)"
    (mouseleave)="closeTooltip()"
    (mouseenter)="openTooltip()"
    >{{ 'Core_ActiveLearner' | translate }}
    <df-icon icon="question-mark-circle"></df-icon
  ></span>
</ng-template>

<ng-template #profileImage>
  <img
    *ngIf="!isLoading"
    [alt]="altText"
    [attr.aria-label]="
      profile?.name ||
      profile?.title ||
      profile?.firstName + ' ' + profile?.lastName
    "
    [class]="'profile-pic__image ' + size"
    [src]="(fallbackLoaded ? fallbackImage : image) | safe: 'resourceUrl'"
    (error)="onFallback()"
    loading="auto"
  />
</ng-template>
