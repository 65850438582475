import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthUser,
  OrgSettings,
  OrganizationRole,
} from '@app/account/account-api.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';
import { booleanFromString } from '../utils/common-utils';
import { ReinterpretKeys, camelCaseKeys } from '../utils/property';
import { generateGuid } from '../utils/uuid';
import { LOCAL_STORAGE } from './storage';

// TODO: Convert additional service functionality below as needed
export const LOGIN_ROUTE_PATH = 'account/login'; // Declared here to avoid circular imports
export const UNAUTHORIZED_ROUTE_PATH = 'account/unauthorized';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
@Injectable({ providedIn: 'root' })
export class AuthService {
  public static readonly authStateKey = 'isLoggedIn';
  public static readonly cacheVersionKey = 'authUser:v';

  private static mapTypedOrgSettings(
    settings: ReinterpretKeys<OrgSettings, string>
  ): OrgSettings {
    // Convert org setting values, which arrive as strings over the wire, into their appropriate data types.
    return {
      learnInBalanceUrl: settings.learnInBalanceUrl,
      disableThirdPartyExperienceManagement: booleanFromString(
        settings.disableThirdPartyExperienceManagement
      ),
      disableAssignedLearning: booleanFromString(
        settings.disableAssignedLearning
      ),
      disableCollaboratorsHeader: booleanFromString(
        settings.disableCollaboratorsHeader
      ),
      organizationBrandColor: settings.organizationBrandColor,
      organizationUsesLightText: booleanFromString(
        settings.organizationUsesLightText
      ),
      disablePathwayHeaderImage: booleanFromString(
        settings.disablePathwayHeaderImage
      ),
      disableSearchRelatedSkills: booleanFromString(
        settings.disableSearchRelatedSkills
      ),
      isClientProvider: booleanFromString(settings.isClientProvider),
      lockSkillSelfRatingVisibility: booleanFromString(
        settings.lockSkillSelfRatingVisibility
      ),
      lockSkillManagerRatingVisibility: booleanFromString(
        settings.lockSkillManagerRatingVisibility
      ),
      lockSkillPeerRatingVisibility: booleanFromString(
        settings.lockSkillPeerRatingVisibility
      ),
      hideExternalCatalog: booleanFromString(settings.hideExternalCatalog),
      useOriginalProfileAvatar: booleanFromString(
        settings.useOriginalProfileAvatar
      ),
      disablePlanImage: booleanFromString(settings.disablePlanImage),
      disableRatingsDescriptions: booleanFromString(
        settings.disableRatingsDescriptions
      ),
      allowRestrictedImages: booleanFromString(settings.allowRestrictedImages),
      enableProvidersInUI: booleanFromString(settings.enableProvidersInUI),
      useZendeskWebWidget: booleanFromString(settings.useZendeskWebWidget),
      downloadableReportPermissionReadOnly: booleanFromString(
        settings.downloadableReportPermissionReadOnly
      ),
      hidePersonalIntegrationsInUserProfileSettings: booleanFromString(
        settings.hidePersonalIntegrationsInUserProfileSettings
      ),
      skillInventoryClient: booleanFromString(settings.skillInventoryClient),
      skillAnalyticsClient: booleanFromString(settings.skillAnalyticsClient),
      enableAdvancedSkillAnalytics: booleanFromString(
        settings.enableAdvancedSkillAnalytics
      ),
      lockProfilePrivacy: booleanFromString(settings.lockProfilePrivacy),
      degreedSurveys: booleanFromString(settings.degreedSurveys),
      inAppTips: booleanFromString(settings.inAppTips),
      enablePathwayBadgeTrigger: booleanFromString(
        settings.enablePathwayBadgeTrigger
      ),
      enableActiveUserInsights: booleanFromString(
        settings.enableActiveUserInsights
      ),
      enableCareerPathing: booleanFromString(settings.enableCareerPathing),
      enableManagerOfManager: booleanFromString(
        settings.enableManagerOfManager
      ),
      enableMentorshipOpportunities: booleanFromString(
        settings.enableMentorshipOpportunities
      ),
      enableReportingInApp: booleanFromString(settings.enableReportingInApp),
      enableTeamSpace: booleanFromString(settings.enableTeamSpace),
      skillCoachFullOrgAccess: booleanFromString(
        settings.skillCoachFullOrgAccess
      ),
      supportTargets: booleanFromString(settings.supportTargets),
      useInternalJobSkills: booleanFromString(settings.useInternalJobSkills),
      opportunitiesActivityStartDate: new Date(
        settings.opportunitiesActivityStartDate
      ),
      disableTopLearnerInsights: booleanFromString(
        settings.disableTopLearnerInsights
      ),
      hideMasterOrgSettings: booleanFromString(settings.hideMasterOrgSettings),
      disableRatingsInsights: booleanFromString(
        settings.disableRatingsInsights
      ),
      disablePopupMessages: booleanFromString(settings.disablePopupMessages),
      disableBulkUpload: booleanFromString(settings.disableBulkUpload),
      disableEngagedLearner: booleanFromString(settings.disableEngagedLearner),
      enableSkillInsightsDashboard: booleanFromString(
        settings.enableSkillInsightsDashboard
      ),
      disableUserGeneratedContent: booleanFromString(
        settings.disableUserGeneratedContent
      ),
      enableEngage: booleanFromString(settings.enableEngage),
      enableSkillsPlatform: booleanFromString(settings.enableSkillsPlatform),
      restrictExtension: booleanFromString(settings.restrictExtension),
      sendAllRapTicketsToClient: booleanFromString(
        settings.sendAllRapTicketsToClient
      ),
      disableEndorsedSearchResultsSection: booleanFromString(
        settings.disableEndorsedSearchResultsSection
      ),
      enableWorkdaySkillsIntegration: booleanFromString(
        settings.enableWorkdaySkillsIntegration
      ),
      showGroupMembershipSource: booleanFromString(
        settings.showGroupMembershipSource
      ),
      disableDegreedMarketplace: booleanFromString(
        settings.disableDegreedMarketplace
      ),
    };
  }

  private static mapOrgPermissions(orgInfo) {
    const permissionsObject: any = {};
    orgInfo.permissions.forEach((permission) => {
      permissionsObject[permission] = true;
    });
    orgInfo.permissions = camelCaseKeys(permissionsObject);
  }
  private _authUser$ = new BehaviorSubject<AuthUser>(undefined);
  private _router: Router;

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private http: NgxHttpClient,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /** Gets the data for an authenticated user, if any */
  public get authUser() {
    return this._authUser$.value;
  }

  /**
   * Gets an observable for monitoring auth state changes. Since this is a BehaviorSubject,
   * new subscribers pick up both current and future states automatically.
   */
  public get authUser$(): Observable<AuthUser> {
    return this._authUser$;
  }

  /** Gets the logged in state of the a user. */
  public get isLoggedIn(): boolean {
    return (
      !!this.authUser && !!this.localStorage.getItem(AuthService.authStateKey)
    );
  }

  /**
   * A user can manage a Learner organization if the canManageOrganization value is true,
   * and they don't belong to a skill analytics or skill inventory org.
   */
  public get userCanManageLearnerOrg() {
    const authUser = this.authUser;
    return (
      !!authUser?.canManageOrganization &&
      !authUser?.isSkillInventoryClient &&
      !authUser?.isSkillAnalyticsClient
    );
  }

  /**
   * A user can manage a skill inventory org if the canManageOrganization value is true,
   * and they belong to a skill inventory client, and have the Amin role.
   * We check the admin role because skill inventory clients only have admin and member roles.
   * This is not an ideal, as we normally don't check against a user having a certain Role, rather we check individual permissions
   */
  public get userCanManageSkillInventory() {
    const authUser = this.authUser;
    return (
      !!authUser?.canManageOrganization &&
      authUser?.isSkillInventoryClient &&
      authUser?.defaultOrgInfo?.orgRole === 'Admin'
    );
  }

  /**
   * A user can manage a skill analytics org if the canManageOrganization value is true,
   * and they belong to a skill analytics client, and have the Amin role.
   * We check the admin role because skill analytics clients only have admin and member roles.
   * This is not an ideal, as we normally don't check against a user having a certain Role, rather we check individual permissions
   */
  public get userCanManageSkillAnalytics() {
    const authUser = this.authUser;
    return (
      !!authUser?.canManageOrganization &&
      authUser?.isSkillAnalyticsClient &&
      authUser?.defaultOrgInfo?.orgRole === 'Admin'
    );
  }

  public get isConsumerUser(): boolean {
    return !this.authUser?.defaultOrgInfo;
  }

  public get isOrgUser(): boolean {
    return !!this.authUser?.defaultOrgInfo;
  }

  public get isAdminUser(): boolean {
    return this.authUser?.defaultOrgInfo?.orgRole === OrganizationRole.admin;
  }

  public get isTechnicalAdminUser(): boolean {
    return (
      this.authUser?.defaultOrgInfo?.orgRole === OrganizationRole.technicalAdmin
    );
  }

  /**
   * - All consumer users are allowed to add images (everywhere they are allowed to add content)
   * - Restricted users are not allowed to add any content at all, including images.
   *   (various clients have this turned on for various reasons: legal, compliance, etc.)
   * - Some clients don't let anyone upload images specifically unless they have permission
   *   to also manage content (this is the allowRestrictedImages and canManageContent relationship)
   * - Image restrictions apply to all files, not just images (for example, pdfs)
   * @see https://degreedjira.atlassian.net/wiki/spaces/TechDocs/pages/4342448619/Image+File+upload+permissions
   */
  public get userCanUploadFiles() {
    const authUser = this.authUser;
    if (!authUser) {
      return false;
    }
    if (this.isConsumerUser) {
      return true;
    }
    if (authUser.isRestrictedProfile) {
      return false;
    }
    if (
      // This would end up false for consumer users if we didn't put them at the top
      !authUser.defaultOrgInfo.settings.allowRestrictedImages &&
      !authUser.defaultOrgInfo.permissions.manageContent
    ) {
      return false;
    }
    return true; // true for anyone else logged in.
  }

  /**
   * - All consumer users are allowed to add images to pathways
   *   (everywhere they are allowed to add content)
   * - Restricted users are not allowed to add any content at all,
   *   including pathway images. (various clients have this turned on
   *   for various reasons: legal, compliance, etc.)
   * - Some clients don't let anyone upload images. In the case of pathways,
   *   this restriction is not applied if the user has permission
   *   to author or manage pathways
   * @see https://degreedjira.atlassian.net/wiki/spaces/TechDocs/pages/4342448619/Image+File+upload+permissions
   */
  public get userCanUploadPathwayImage() {
    const authUser = this.authUser;
    if (!authUser) {
      return false;
    }
    if (this.isConsumerUser) {
      return true;
    }
    if (authUser.isRestrictedProfile) {
      return false;
    }
    if (
      // This would end up false for consumer users if we didn't put them at the top
      !authUser.defaultOrgInfo.settings.allowRestrictedImages &&
      !(
        authUser.defaultOrgInfo.permissions.managePathways ||
        authUser.defaultOrgInfo.permissions.authorPathways
      )
    ) {
      return false;
    }
    return true; // true for anyone else logged in.
  }

  private get router() {
    if (!this._router) {
      this._router = this.injector.get(Router);
    }
    return this._router;
  }

  /**
   * Attempt to fetch and update the authUser with the current user's data. This is useful when an auth cookie is present
   * but we don't have the user data cached, which will be the case when switching between the old and new clients.
   * @param continueOnError Allow this call to fail gracefully - clear out any cached authUser and return
   */
  public fetchAuthenticatedUser(forceRefresh = false, continueOnError = false) {
    let cacheVersion: string = this.localStorage.getItem(
      AuthService.cacheVersionKey
    );
    if (forceRefresh || !cacheVersion) {
      cacheVersion = this.refreshCacheVersionToken();
    }

    // when the app enters a new context, we need to re-fetch the auth user, as there are some back end pieces that re-hyrdate properties.  See AccountOrchestrator.cs
    // currently only channel sets a context, but we'll allow flexibility for other contexts in the future.
    const url = new URL(this.document.location.href);
    let context = url.searchParams.get('context');
    if (!context) {
      // not all channel urls use a context param, so we also need to check the path for the channel route.
      // Note the check for equality against no closing slash; this is to avoid cases where a username starts with channel
      context =
        url.pathname.startsWith('/channel/') || url.pathname === '/channel'
          ? 'channel'
          : null;
    }

    return this.http
      .get<AuthUser>('/account/getauthenticateduser', {
        params: {
          v: cacheVersion,
          context: context,
        },
        observe: 'response',
        headers: {
          // Ensure that this call is ignored by the auth.intercept service
          'Dg-Skip-Intercept': 'true',
        },
      })
      .pipe(
        catchError((e) => of(e)),
        tap((r) => {
          if (r.status === 200) {
            this._authUser$.next(this.fixupAuthUser(r.body));
            this.storeAuthStatus();
          } else if (continueOnError) {
            this.clearAuth();
          } else {
            this.clearAuth();
            this.goToLogin(true); // auth ticket has likely expired
          }
        })
      );
  }

  public login(
    params: {
      username: string;
      password: string;
      rememberMe?: boolean;
    },
    redirectTo: string = '/'
  ) {
    return this.http
      .post<AuthUser>('/account/login', params, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        tap((r) => {
          if (r.status === 200) {
            this._authUser$.next(this.fixupAuthUser(r.body));
            this.storeAuthStatus();
            this.router.navigateByUrl(redirectTo);
          }
        })
      );
  }

  public logout(localOnly?: boolean, useReturnRedirect?: boolean) {
    useReturnRedirect = true;
    if (!localOnly) {
      return this.http
        .post('/account/logout', {})
        .pipe(tap(() => this.clearAuth()));
    } else {
      this.clearAuth();
      this.goToLogin(useReturnRedirect);
      return of();
    }
  }

  /**
   * NOTE: The login route is not managed by Angular @see HybridUrlHandlingStrategy, so any router navigation done here
   * is ignored. This should instead use straight up Angular routing once we have an Angular login route
   *
   * @param useReturnRedirect Should the user be returned to the current page after login?
   */
  public goToLogin(useReturnRedirect?: boolean) {
    // NOTE: Using `document.URL` for consistency with the ajs auth intercept.
    const returnUrl = this.document.URL;
    this.document.location.href =
      '/' +
      LOGIN_ROUTE_PATH +
      (useReturnRedirect ? '?returnUrl=' + encodeURIComponent(returnUrl) : '');
  }

  public appendReturnUrl(prefix = '', returnUrl = '') {
    return prefix + 'returnUrl=' + encodeURIComponent(returnUrl);
  }

  /**
   * Clears any locally stored auth state
   *
   * NOTE: ensure UserSvc.clearAuthUserCache() is updated to clear the same keys if this is changed
   */
  public clearAuth() {
    this.localStorage.removeItem(AuthService.authStateKey);
    this.localStorage.removeItem(AuthService.cacheVersionKey);
    this._authUser$.next(null);
  }

  /** Stores the auth/login state in browser storage */
  public storeAuthStatus() {
    this.localStorage.setItem(AuthService.authStateKey, new Date().toJSON());
  }

  public fixupAuthUser(rawAuthUser: AuthUser) {
    // Transform org settings from string values to their natural types
    // This should eventually be done on the back end via db-provided type info
    const orgInfo = rawAuthUser.orgInfo?.map((o) => ({
      ...o,
      settings: o.settings
        ? AuthService.mapTypedOrgSettings(o.settings as any)
        : undefined,
    }));

    const defaultOrgInfo = orgInfo?.find(
      (o) => o.organizationId === rawAuthUser.defaultOrgId
    );

    if (defaultOrgInfo) {
      AuthService.mapOrgPermissions(defaultOrgInfo);
    }

    return {
      ...rawAuthUser,
      viewerProfile: {
        ...rawAuthUser.viewerProfile,
        // duplicate isEngaged under viewerProfile object so we don't break the old angular component
        isEngaged: rawAuthUser.isEngaged,
      },
      orgInfo,
      // Provide default org info as dedicated property
      defaultOrgInfo,
    };
  }

  /** Updates the authUser 'version' token for client-side cache busting */
  public refreshCacheVersionToken(): string {
    const key = generateGuid();
    this.localStorage.setItem(AuthService.cacheVersionKey, key);
    return key;
  }
}
