import { LDFlagsService } from '@dg/shared-services';
import { Injectable } from '@angular/core';
import { InputDetailsComponent } from '@app/inputs/components/input-details/input-details.component';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { ModalService } from '@app/shared/services/modal.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { Observable } from 'rxjs';
import { TrackerService } from '../tracker.service';
import { SearchResultTrackingConfig } from '@app/search';

export interface DetailsModalConfig {
  preview?: any;
  permissionsOverride?: { displayComments: boolean };
  trackingElement?: HTMLElement;
  expandComments?: boolean;
  isMarketplaceProgram?: boolean;
  searchResultTrackingConfig?: SearchResultTrackingConfig;
}

@Injectable({
  providedIn: 'root',
})
export class DetailsModalService {
  constructor(
    private modalService: ModalService,
    private trackerService: TrackerService,
    private ldFlagsService: LDFlagsService,
    private inputToLearningPipe: InputToLearningResourcePipe
  ) {}

  /**
   * Determines which detail modal to open. Returns the updated resource when appropriate.
   *
   * @param resource - Only supports Input type resources for now
   * @param config - optional configuration for tracking, permissions, and context as needed
   *
   */
  public openDetails(
    resource: LearningResourceViewModel,
    config?: DetailsModalConfig
  ): Observable<{ resource: LearningResourceViewModel } | undefined> {
    this.trackerService.trackEventData({
      action: 'Content More Details Viewed',
      element: config?.trackingElement,
      properties: resource.model,
    });

    // TODO: ideally we create a Post component and conditionally display that here instead of rehashing the input details for Pathway Posts
    // TODO: ideally we create a Task component and conditionally display that here instead of rehashing the input details for Pathway Tasks

    let windowClass = this.ldFlagsService.showSearchLayoutRedesign
      ? `xlg-modal`
      : `lg-modal`;

    if (config?.preview) {
      windowClass += ' modal--tertiary';
    }

    // TEMP workaround when opening the modal from the content catalog and group feed. When that is updated
    // to use the LearningResourceViewModel, this can be removed.
    if (
      !(resource instanceof LearningResourceViewModel) &&
      (resource as any).model
    ) {
      resource = this.inputToLearningPipe.transform(
        camelCaseKeys((resource as any).model)
      );
    }

    return this.modalService.show<{ resource: LearningResourceViewModel }>(
      InputDetailsComponent,
      {
        inputs: {
          resource,
          config,
          isDetailModal: true,
          expandComments: config?.expandComments,
        },
        windowClass: windowClass,
      }
    );
  }
}
