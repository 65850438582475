import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { ArticlePathwaysPlansInputFacade } from '@app/user-content/user-input-v2/inputs/article/services/pathways-and-plans/article-pathways-plans.facade';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { handleArticleFocusOnSubmit } from '../../services/article.utils';
import { NotificationType } from '@lib/fresco';
import {
  onChangeOfAddToCatalogValue,
  pathPlanHideUrlField,
} from '@app/user-content/user-input-v2/utils/form-field-helper';
import { pathPlanEditContentNotification } from '@app/user-content/user-input-v2/utils/modal-helper';
import { PathwayStep } from '@dg/pathways-rsm';
import { AuthService } from '@dg/shared-services';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { maxFifteenSkillsValidator } from '@app/user-content/user-input-v2/utils/validators';

type ArticlePathwayPlansField =
  | 'title'
  | 'sourceName'
  | 'format'
  | 'description'
  | 'durationHours'
  | 'durationMinutes'
  | 'owner'
  | 'image'
  | 'skills'
  | 'advancedSettings';

@Component({
  selector: 'dgx-article-pathways-plans',
  templateUrl: './article-pathways-plans.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePathwaysPlansComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @Input() public pathwayStep?: PathwayStep;
  @ViewChild('entryUrl')
  public entryUrlInput: ElementRef<HTMLElement>;

  public articlePlanPathForm: FormGroup;
  public showExpandedForm: boolean = false;
  public readonly NotificationType = NotificationType;
  public i18n = this.translate.instant([
    'Core_Description',
    'Core_EditItemHelper',
    'Core_GeneralErrorMessage',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Required',
    'Core_Skills',
    'Core_Source',
    'Core_Title',
    'MediaFormCtrl_AddArticle',
    'MediaFormCtrl_ArticleUrl',
    'MediaFormCtrl_EditArticle',
    'MediaFormCtrl_SaveArticle',
    'MediaFormCtrl_UrlRequired',
    'MediaFormCtrl_TitleRequired',
    'addToPathwayModal_AddToCatalog',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContentForm_ArticleFormat',
    'dgOrgInternalContentForm_ArticleFormatSelectPlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContentForm_ArticleDescriptionPlaceholder',
    'Core_OrgManagedDocLinkLabel',
  ]);
  public heading: string;
  public vm$: Observable<ArticleModel>;

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private facade: ArticlePathwaysPlansInputFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private authService: AuthService
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public get hideUrlField(): boolean {
    return pathPlanHideUrlField(
      this.isCatalogContent,
      this.facade.snapshot.inputContext.isEditing,
      this.facade.snapshot.hostedContentDetails
    );
  }

  public get showExtraFields(): boolean {
    return (
      this.showExpandedForm &&
      (this.facade.snapshot.inputContext.isEditingInternalContent ||
        this.articlePlanPathForm.get('addToCatalog')?.value)
    );
  }

  public get editContentNotificationText(): string {
    return pathPlanEditContentNotification(
      this.isEditingInternalContent,
      this.translate
    );
  }

  public get addToCatalogCheckboxLabel(): string {
    return this.translate.instant('MediaFormCtrl_AddToCatalogFormat', {
      orgName: this.facade.orgName,
    });
  }

  public get isCatalogContent(): boolean {
    return this.facade.snapshot.inputContext.isEditing
      ? !!this.facade.snapshot.inputContext.isCmsContent
      : this.articlePlanPathForm.get('addToCatalog')?.value;
  }

  public get showEntryUrlDuplicateCheck(): boolean {
    return (
      !this.facade.snapshot.isInitialForm &&
      this.facade.snapshot.duplicateCount > 0 &&
      (this.articlePlanPathForm.get('addToCatalog')?.value ||
        (this.facade.snapshot.inputContext.isEditing && this.isCatalogContent))
    );
  }

  public get isEditingInternalContent(): boolean {
    return (
      this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isCmsContent
    );
  }

  /**
   * Currently for local edit there are properties that we can not edit(PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   * @returns true if the input field should be shown
   */
  public get shouldShowContentOwnerField(): boolean {
    return (
      !this.isEditingContentLocally ||
      !!this.articlePlanPathForm.get('owner').value
    );
  }

  /**
   * Currently for local edit there are properties that we can not edit(PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   * @returns true if the input field should be shown
   */
  public get shouldShowDurationField(): boolean {
    return (
      !this.isEditingContentLocally ||
      !!this.facade.snapshot.durationHours ||
      !!this.facade.snapshot.durationMinutes
    );
  }
  /**
   * Determines if the user is editing the pathway's input only
   * Edits to the input should not be applied to the catalog/internal input
   * @returns boolean
   */
  public get isEditingContentLocally(): boolean {
    return (
      !this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isEditing
    );
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.facade.initializeViewModel(this.context);
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.MediaFormCtrl_EditArticle
      : this.i18n.MediaFormCtrl_AddArticle;

    if (this.facade.snapshot.inputContext.isEditing) {
      await this.facade.initializeEdit(
        this.isEditingContentLocally,
        this.pathwayStep
      );
      this.initializeExpandedForm();
      this.loadExpandedForm();
      return;
    }

    // Setup the form
    this.initializeForm();
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: ArticlePathwayPlansField, value: any) {
    this.facade.onFormControlUpdate(this.articlePlanPathForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  public onDeleteImage() {
    this.onFormControlUpdate('image', '');
    this.facade.onDeleteImage();
  }
  public onImageUploadSuccess({ pictureUrl }) {
    this.onFormControlUpdate('image', pictureUrl);
    this.facade.onImageUploadSuccess(pictureUrl);
  }

  /****************************************************
   * On form page submissions
   ****************************************************/
  public async onNext(form: FormGroupDirective): Promise<void> {
    this.facade.markFormAsTouched(this.articlePlanPathForm);
    if (this.articlePlanPathForm.invalid) {
      return;
    }

    try {
      await this.onEntryUrlNext();
    } catch (error) {
      console.error(this.i18n.Core_GeneralErrorMessage, error);
    }

    // reset the submitted state on the form
    form.resetForm(this.articlePlanPathForm.value);
    this.initializeExpandedForm();
    this.loadExpandedForm();
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.articlePlanPathForm);

    if (this.articlePlanPathForm.invalid) {
      handleArticleFocusOnSubmit(this.articlePlanPathForm.controls);
      return;
    }

    try {
      const entryUrlValue = this.articlePlanPathForm.get('entryUrl')?.value;
      // Only check for duplicates if fetchUrl
      if (
        (this.isCatalogContent &&
          this.facade.snapshot.inputContext.isEditingInternalContent) ||
        (this.isCatalogContent && !this.facade.snapshot.inputContext.isEditing)
      ) {
        const defaultOrgId =
          this.facade.snapshot.inputContext.organizationId ??
          this.authService.authUser?.defaultOrgId;

        await this.facade.fetchUrlDuplicates(entryUrlValue, defaultOrgId);
      }
      // if we are adding to catalog, re-check the url for duplicates on submit
      if (this.isCatalogContent && this.facade.snapshot.duplicateCount > 0) {
        this.articlePlanPathForm.get('entryUrl').markAsTouched();
        this.entryUrlInput.nativeElement.focus();
        return;
      }

      await this.facade.onSubmit(
        this.articlePlanPathForm,
        this.isEditingContentLocally,
        this.pathwayStep
      );
    } catch (error) {
      console.error('Error during submit:', error);
    }

    this.activeModal.close(this.facade.snapshot);
  }

  /******************************************************************
   * Private utils
   ******************************************************************/
  /**
   * On selecting next from the initial form
   */
  private async onEntryUrlNext() {
    try {
      // On next we should not be checking for duplications until the catalog checkbox is checked
      await this.facade.onNext(this.articlePlanPathForm.get('entryUrl').value);

      // Check if the entry URL has duplicates, if it does the UI will display corresponding error
      if (this.facade.snapshot.duplicateCount > 0) {
        return;
      }
    } catch (error) {
      console.error(this.i18n.Core_GeneralErrorMessage, error);
    }
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.articlePlanPathForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
    });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeExpandedForm() {
    const ownerValidation =
      this.facade.snapshot.inputContext.isEditing &&
      this.facade.snapshot.inputContext.isEditingInternalContent
        ? Validators.required
        : '';

    this.articlePlanPathForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
      title: ['', [Validators.required, isEmptyValidator]],
      addToCatalog: [],
      sourceName: [],
      format: [],
      description: [],
      owner: ['', ownerValidation],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
      // Advanced Setting added by child component
    });
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm() {
    this.showExpandedForm = true;

    // Patch values from the collector
    this.articlePlanPathForm.patchValue({
      entryUrl: this.facade.snapshot.entryUrl,
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      owner: this.facade.snapshot.owner,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.inputContext.isEditing
        ? this.facade.snapshot.tags
        : this.facade.snapshot.highConfidenceInferredSkills,
    });

    // Subscribe to value changes on the entryURL to reset the duplicates error
    this.articlePlanPathForm
      .get('entryUrl')
      .valueChanges.subscribe(() => this.facade.resetDuplicates());

    this.articlePlanPathForm
      .get('addToCatalog')
      .valueChanges.subscribe((isAddingToCatalog) => {
        const defaultOrgId =
          this.facade.snapshot.inputContext.organizationId ??
          this.authService.authUser?.defaultOrgId;
        onChangeOfAddToCatalogValue(
          isAddingToCatalog,
          this.articlePlanPathForm,
          this.facade,
          defaultOrgId
        );
      });

    this.cdr.detectChanges();
  }
}
