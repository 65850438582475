import { Injectable } from '@angular/core';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { DurationConverterService } from '@dg/shared-services';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  VideoEntryApi,
  VideoModel,
  VideoMappingToAPI,
  VideoInput,
} from '../video.model';

@Injectable({
  providedIn: 'root',
})
export class VideoMapperService {
  constructor(
    private imageUrlService: ImageUrlService,
    private durationConverterService: DurationConverterService
  ) {}

  public toViewModel(
    source: VideoEntryApi,
    videoModel: VideoModel
  ): VideoInput {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    return {
      addToCatalog:
        !source.orgContentMetadata?.hideFromCatalog ?? videoModel.addToCatalog,
      accessible: !!source.accessible ?? !!videoModel.orgContentMetadata,
      createdBy: source.createdBy ?? videoModel.createdBy,
      durationHours: source.durationHours ?? videoModel.durationHours,
      durationMinutes: source.durationMinutes ?? videoModel.durationMinutes,
      entryUrl: source.entryUrl ?? videoModel.entryUrl,
      externalId: source.externalId ?? videoModel.externalId,
      file: source.hostedContentDetails
        ? {
            name: source.hostedContentDetails.fileName,
            size: source.hostedContentDetails.fileSize,
          }
        : undefined,
      fileManaged: source.fileManaged ?? videoModel.fileManaged,
      format: source.format ?? videoModel.format,
      groups,
      hasBrokenUrl: source.hasBrokenUrl,
      highConfidenceInferredSkills:
        this.getMappedInferredSkills(source?.highConfidenceInferredSkills) ??
        videoModel?.highConfidenceInferredSkills, // Note: BE returns a string[] that doesn't map to TagsApi.Tag[]
      hostedContentDetails: source.hostedContentDetails,
      // imageSizeId: source.imageSizeId,
      imageUrl: source.imageUrl,
      inputId: source.inputId,
      inputType: source.inputType,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      language: source.language,
      length: source.length,
      mediaLength: this.durationConverterService.fromSecondsToMinutes(
        source.mediaLength
      ),
      mediaType: source.mediaType,
      mediumConfidenceInferredSkills: this.getMappedInferredSkills(
        source.mediumConfidenceInferredSkills
      ), // Note: BE returns a string[] that doesn't map to TagsApi.Tag[]
      organizationId: source.organizationId,
      orgContentMetadata: {
        groupIds: groups,
        hideFromCatalog:
          source.orgContentMetadata?.hideFromCatalog ||
          !videoModel.addToCatalog,
      }, // for On edit visibility
      owner: source.owner,
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      sourceName: source.sourceName,
      summary: source.summary,
      tags: source.tags ?? [],
      title: source.title,
      // useQuickCheck: !!source.useQuickCheck,
      //userMediaId: source.userMediaId, // only valid for user completions
    } as any as VideoModel;
  }

  // TODO: mapping params for api
  public toApiParameters(source: VideoModel): VideoMappingToAPI {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);
    return {
      accessible: !!source.accessible,
      comment: source.comment,
      entryUrl: source.entryUrl,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      externalId: source.externalId,
      format: source.format,
      hostedContentDetails:
        source.hostedContentDetails as HostedContentMetadata,
      imageUrl,
      // imageSizeId: source.imageSizeId,
      inputId: source.inputId,
      inputType: source.inputType,
      language: source.language,
      mediaLength: this.durationConverterService.fromMinutesToSeconds(
        source.mediaLength
      ),
      organizationId: source.orgContentMetadata?.hideFromCatalog
        ? null
        : source.organizationId,
      orgContentMetadata: {
        hideFromCatalog: source.orgContentMetadata.hideFromCatalog,
        groupIds: source.orgContentMetadata.groupIds ?? [],
      },
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      resourceImageId: imageUrlData?.resourceImageId,
      sourceName: source.sourceName,
      summary: source.summary,
      tags: source.tags,
      title: source.title,
      useQuickCheck: false, // TODO: currently videos this is always false check if global needs this or just delete!!source.useQuickCheck,
      // userMediaId: source.userMediaId, // only valid for user completions
    };
  }

  /**
   * Convert the inferred string[] into a partial TagsApi.Tag object.
   * BE is only passing name, this is needed for the tag-rendered. This is later mapped back to name as the BE needs
   * at least name to construct a full Tag object once received.
   * @param skills
   * @returns
   */
  private getMappedInferredSkills(skills: string[]): TagsApi.Tag[] {
    if (!skills?.length) return [];
    return skills.map(
      (skillName) => ({ title: skillName, name: skillName } as TagsApi.Tag)
    );
  }
}
