import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TrackingEventArgs } from '@app/shared/services/tracking.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { AuthService } from '@app/shared/services/auth.service';
import { UserService } from '@app/user/services/user.service';
import { OpportunitySkillsModalService } from '@app/opportunities/services/opportunity-skills-modal.service';
import { AuthUser } from '@app/account/account-api.model';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'dgx-user-card-skills-modal',
  templateUrl: './user-card-skills-modal.component.html',
  styleUrls: ['./user-card-skills-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardSkillsModalComponent implements OnInit {
  public isLoading = true;
  public mentoringSkills: Skill[] = [];
  public usersOtherSkills: Skill[] = [];
  public i18n = this.translate.instant([
    'Opportunities_MentorSkills',
    'Opportunities_OtherSkills',
  ]);

  @Input() public peer: UserProfileSummary;

  public authUser: AuthUser;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private opportunitySkillsModalService: OpportunitySkillsModalService,
    private translate: TranslateService,
    private trackerService: TrackerService,
    private userService: UserService
  ) {}

  public getHasExternalSkillRating(skill: Skill) {
    return this.opportunitySkillsModalService.hasExternalSkillRating(skill);
  }
  public getIsCertified(skill: Skill) {
    return this.opportunitySkillsModalService.isCertified(skill);
  }
  public getIsEvaluated(skill: Skill) {
    return this.opportunitySkillsModalService.isEvaluated(skill);
  }
  public getCurrentSkillLevel(skill: Skill) {
    return this.opportunitySkillsModalService.getCurrentSkillLevel(skill);
  }
  public getSkillSignalsLabel(skill: Skill) {
    return this.opportunitySkillsModalService.getSkillSignalsLabel(
      skill.signalCount
    );
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.setupUserSkills();
  }

  private setupUserSkills() {
    return this.userService
      .getUserSkillsWithSignals(this.peer)
      .pipe(
        tap(() => {
          this.trackEvent();
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(({ userMentoringSkills, userOtherSkills }) => {
        this.mentoringSkills = userMentoringSkills;
        this.usersOtherSkills = userOtherSkills;
      });
  }

  private trackEvent(): void {
    const matchSkills = this.authUser.viewerInterests.filter((tag) =>
      this.peer.tagNames?.includes(tag.name)
    );

    const data: TrackingEventArgs = {
      action: 'User Skill Modal Summary Viewed',
      properties: {
        isActiveLearner: !!this.peer.isEngaged,
        skillOwnerUserId: this.peer.userProfileKey,
        matchedSkillsCount: matchSkills.length,
      },
    };

    // track event data
    this.trackerService.trackEventData(data);
  }
}
