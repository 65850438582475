import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ConnectWithMentorService } from '@app/mentoring/components/modals/connect-with-mentor-modal/connect-with-mentor.service';
import { MentorshipStatusEnum } from '@app/mentoring/mentoring.enums';
import { MentoringModalsService } from '@app/mentoring/services/mentoring-modals.service';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { getConnectWithMentorButtonData } from '@app/mentoring/utils';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dgx-connect-with-mentor-button',
  templateUrl: './connect-with-mentor-button.component.html',
  styleUrls: ['./connect-with-mentor-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectWithMentorButtonComponent implements OnInit {
  @Input() public buttonClasses = '';
  @Input() public mentor: UserProfileSummary;
  @ViewChild('connectWith') public connectWithRef: ElementRef<HTMLElement>;

  public i18n = this.translate.instant([
    'Opportunities_ConnectWithButton_Status_Requested',
    'Opportunities_ConnectWithButton_Status_Accepted',
    'Opportunities_ConnectWithButton_Status_None',
  ]);
  public buttonLabel: string;
  public buttonType: 'primary' | 'secondary' | 'passive';

  constructor(
    private cdr: ChangeDetectorRef,
    private connectWithMentorService: ConnectWithMentorService,
    private mentoringModalsService: MentoringModalsService,
    private mentoringTrackingService: MentoringTrackingService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.updateButtonData();
  }

  /**
   * Opens a modal to connect with the mentor.
   *
   * @param mentor
   */
  public openConnectWithMentorModal(
    event: Event,
    mentor: UserProfileSummary
  ): void {
    // prevent button click action from bubbling up
    event.preventDefault();

    // track the button click
    const { focusSkillsCount, skillsAddedCount } =
      this.connectWithMentorService.getMentorTagCounts(mentor);
    this.mentoringTrackingService.mentorshipRequestInitiated(
      mentor.userProfileKey,
      focusSkillsCount,
      skillsAddedCount
    );

    // open the modal
    this.mentoringModalsService
      .openConnectWithMentorModal(mentor, this.connectWithRef.nativeElement)
      .pipe(
        tap(() => {
          // update the mentorship status
          this.mentor.mentorshipStatus = MentorshipStatusEnum.Requested;
          // update the button data
          this.updateButtonData();
        })
      )
      .subscribe();
  }

  private updateButtonData(): void {
    const { type, label } = getConnectWithMentorButtonData(this.mentor);
    this.buttonType = type;
    this.buttonLabel = this.i18n[label];
    this.cdr.markForCheck();
  }
}
