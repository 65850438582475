import { Injectable } from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { TagFlagsService } from '@app/tags/services/tag-flags.service';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TagRatingButtonService {
  /** Validation methods keyed on rating type */
  private validate = {
    /** Determine Credential button visibility */
    [InternalTagRatingTypes.credential]: (
      state: TagRatingButtonState
    ): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.credential,
        state.userRatingDetails
      );
      return hasCompletedRatings;
    },

    /** Determine Evaluation button visibility */
    [InternalTagRatingTypes.evaluation]: (
      state: TagRatingButtonState
    ): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.evaluation,
        state.userRatingDetails
      );
      const hasIncompleteRatings = this.typeHasIncompleteRatings(
        InternalTagRatingTypes.evaluation,
        state.userRatingDetails
      );
      const isAvailable = this.getTypeIsAvailable(
        InternalTagRatingTypes.evaluation,
        state
      );
      return (
        (state.ownerIsViewing &&
          !this.isConsumerUser &&
          isAvailable &&
          ((state.isEvaluable && this.authUser?.canEvaluateSkills) ||
            hasIncompleteRatings)) ||
        hasCompletedRatings
      );
    },

    /** Determine Manager Rating button visibility */
    [InternalTagRatingTypes.manager]: (
      state: TagRatingButtonState
    ): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.manager,
        state.userRatingDetails
      );
      const hasIncompleteRatings = this.typeHasIncompleteRatings(
        InternalTagRatingTypes.manager,
        state.userRatingDetails
      );
      const isAvailable = this.getTypeIsAvailable(
        InternalTagRatingTypes.manager,
        state
      );
      return (
        (state.ownerIsViewing && !this.isConsumerUser && isAvailable) ||
        hasIncompleteRatings ||
        hasCompletedRatings
      );
    },

    /** Determine Peer Rating button visibility */
    [InternalTagRatingTypes.peer]: (state: TagRatingButtonState): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.peer,
        state.userRatingDetails
      );
      const hasIncompleteRatings = this.typeHasIncompleteRatings(
        InternalTagRatingTypes.peer,
        state.userRatingDetails
      );
      const isAvailable = this.getTypeIsAvailable(
        InternalTagRatingTypes.peer,
        state
      );
      return (
        (state.ownerIsViewing &&
          !this.isConsumerUser &&
          isAvailable &&
          this.tagFlagsService.showPeerRatings) ||
        hasIncompleteRatings ||
        hasCompletedRatings
      );
    },

    /** Determine Self Rating button visibility */
    [InternalTagRatingTypes.self]: (state: TagRatingButtonState): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.self,
        state.userRatingDetails
      );
      return state.ownerIsViewing || hasCompletedRatings;
    },

    /** Determine Checkpoint button visibility */
    [InternalTagRatingTypes.checkpoint]: (
      state: TagRatingButtonState
    ): boolean => {
      const hasCompletedRatings = this.typeHasCompletedRatings(
        InternalTagRatingTypes.checkpoint,
        state.userRatingDetails
      );
      return (
        this.tagFlagsService.showCheckpoints &&
        (hasCompletedRatings || !!state.allCheckpoints?.length)
      );
    },

    /** Determine External Rating button visibility (Pluralsight etc) */
    External: (type: string, state: TagRatingButtonState): boolean => {
      const hasUrl = !!state.availableTypes.find(
        (availableType) => availableType.name === type
      )?.url;
      const hasCompletedRatings = this.typeHasCompletedRatings(
        type,
        state.userRatingDetails
      );
      return hasCompletedRatings || hasUrl;
    },
  };

  constructor(
    private tagFlagsService: TagFlagsService,
    private ratingsForTypePipe: TagRatingsForTypePipe,
    private completedTagRatingsPipe: CompletedTagRatingsPipe,
    private incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    private authService: AuthService
  ) {}

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  private get isConsumerUser(): boolean {
    return this.authService.isConsumerUser;
  }

  /**
   * Get an array of unique rating types for dyanmic button generation
   *
   * @param userRatingDetails
   * @param sortByRank sort the ratings in descending order by rank (defaults to descending by date completed)
   * @param ownerIsViewing
   * @param availableTypes only applies to owner users
   * @param allCheckpoints only applies to owner users
   * @param isEvaluable only applies to owner users
   *
   * @returns `string[]`
   */
  public getEnabledRatingTypes(
    userRatingDetails: TagsApi.UserTagRatingDetails[],
    sortByRank: boolean = true,
    ownerIsViewing: boolean = false,
    availableTypes: TagsApi.RatingType[] = [],
    allCheckpoints: TagsApi.Checkpoint[] = [],
    isEvaluable: boolean = false
  ): string[] {
    const state: TagRatingButtonState = {
      userRatingDetails,
      availableTypes,
      ownerIsViewing,
      sortByRank,
      allCheckpoints,
      isEvaluable,
    };
    const completeOrInProgress = this.getCompleteOrInProgressTypes(
      userRatingDetails,
      !sortByRank
    );
    const availableExternalTypes = this.getExternalButtonTypes(availableTypes);

    // Build array of all potential rating types
    const types = ownerIsViewing
      ? [
          ...InternalRatingTypesByRank, // forces sort by rank
          ...completeOrInProgress,
          ...availableExternalTypes,
        ]
      : [...completeOrInProgress, ...availableExternalTypes];

    // Remove rating types that shouldn't be displayed
    return [...new Set(types)].filter((type) =>
      InternalRatingTypesByRank.includes(type)
        ? this.validate[type]?.(state)
        : this.validate.External(type, state)
    );
  }

  /** Get the completed/in-progress rating types */
  private getCompleteOrInProgressTypes(
    userRatingDetails: TagsApi.UserTagRatingDetails[],
    sortByDate: boolean = false
  ): string[] {
    if (!userRatingDetails) {
      return [];
    }
    const ratings = sortByDate
      ? userRatingDetails.sort((a, b) =>
          b.dateCompleted?.localeCompare(a.dateCompleted)
        )
      : userRatingDetails;
    return ratings.map((rating) => rating.type);
  }

  /** Get the external rating types */
  private getExternalButtonTypes(
    availableTypes: TagsApi.RatingType[]
  ): string[] {
    if (!availableTypes) {
      return [];
    }

    return availableTypes
      .filter(
        (rating) =>
          !rating.isInternal &&
          rating.name !== InternalTagRatingTypes.checkpoint
      )
      .map((rating) => rating.name)
      .sort();
  }

  private getTypeIsAvailable(
    type: string,
    state: TagRatingButtonState
  ): boolean {
    return !!state.availableTypes.find((_type) => _type.name === type);
  }

  private typeHasCompletedRatings(
    type: string,
    ratings: TagsApi.UserTagRatingDetails[]
  ) {
    const ratingsForType = this.ratingsForTypePipe.transform(ratings, type);
    return !!this.completedTagRatingsPipe.transform(ratingsForType).length;
  }

  private typeHasIncompleteRatings(
    type: string,
    ratings: TagsApi.UserTagRatingDetails[]
  ) {
    const ratingsForType = this.ratingsForTypePipe.transform(ratings, type);
    return !!this.incompleteTagRatingsPipe.transform(ratingsForType).length;
  }
}

export const InternalRatingTypesByRank = [
  'Credential',
  'Evaluation',
  'Manager',
  'Peer',
  'Self',
  'Checkpoint',
];

export interface TagRatingButtonState {
  userRatingDetails: TagsApi.UserTagRatingDetails[];
  availableTypes: TagsApi.RatingType[];
  sortByRank: boolean;
  ownerIsViewing: boolean;
  allCheckpoints: TagsApi.Checkpoint[];
  isEvaluable: boolean;
}
