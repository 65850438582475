import { LDFlagsService } from '../ld-flags.service';

export class InsightsLDFlags {
  // off in production - 2 years since last change
  public get minimumAggregatedLearningInsightsUserCount(): boolean {
    return this.ldFlagsService.getFlag(
      'minimum-aggregated-learning-insights-user-count',
      false
    );
  }

  public get insightsSkillAnalytics(): boolean {
    return this.ldFlagsService.getFlag('insights-skill-analytics', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
