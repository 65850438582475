<div
  [ngClass]="{
    l_flexbar: !wrap,
    'l_flex l_flex-wrap l_flex-center-children': wrap
  }"
>
  <div
    *ngFor="let user of users | slice: 0:limit; trackBy: trackByFn"
    class="guts-p-r-half"
    [ngClass]="{ 'profile-pic--active-learner': user.isEngaged }"
    data-dgat="usersPictures-c07"
  >
    <a
      *ngIf="!isEditing"
      (click)="userAvatarClicked(user)"
      class="ib"
      [class.guts-m-v-quart]="wrap"
      [ngbTooltip]="user.name"
      data-dgat="usersPictures-756"
    >
      <dgx-profile-pic
        [profile]="user"
        size="tiny"
        [hideTooltip]="true"
      ></dgx-profile-pic>
    </a>

    <span
      *ngIf="isEditing"
      class="ib"
      [ngClass]="{ 'guts-m-v-quart': wrap }"
      [ngbTooltip]="user.name"
      [attr.aria-label]="getAriaLabelForUser(user)"
      data-dgat="usersPictures-cac"
    >
      <dgx-profile-pic
        [profile]="user"
        size="tiny"
        [hideTooltip]="true"
      ></dgx-profile-pic>
    </span>
  </div>
  <div *ngIf="showConcatenatedUsers" class="guts-p-r-half ib">
    <button
      (click)="editClick($event)"
      class="profile-pic__overflow--tiny color-white bg-color-ebony-a61 hover-bg-color-ebony font-medium"
      [class.profile-pic__overflow--font-tiny]="maxLimitReached"
      ngbTooltip="{{ viewAllUsers | translate }}"
      attr.aria-label="{{ viewAllUsers | translate }}"
      data-dgat="users-pictures-10c"
    >
      <div class="l_flexbar l_flex-justify-center">
        <df-icon icon="plus" size="extra-small"></df-icon>
        <div>{{ users.length - limit }}</div>
      </div>
    </button>
  </div>

  <div *ngIf="isEditing" class="ib">
    <button
      (click)="editClick($event)"
      class="profile-pic__add--tiny color-ebony-a61 hover-color-ebony"
      ngbTooltip="{{ manageUsers | translate }}"
      attr.aria-label="{{ manageUsers | translate }}"
      data-dgat="usersPictures-7f1"
    >
      <df-icon icon="plus-circle" size="large"></df-icon>
    </button>
  </div>
</div>
