import { Injectable } from '@angular/core';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { AuthService } from '@app/shared/services/auth.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { catchAndSurfaceNonModalError } from '@app/shared/utils/modal-helpers';
import { TagsService } from '@app/tags/services/tags.service';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, mapTo, switchMap, tap } from 'rxjs/operators';
import { ConnectWithMentorModalComponent } from '../components/modals/connect-with-mentor-modal/connect-with-mentor-modal.component';
import {
  AcceptMenteeModalComponent,
  AcceptMenteeModel,
} from '../components/modals/mentee-modals/accept-mentee-modal/accept-mentee-modal.component';
import {
  RejectMenteeModalComponent,
  RejectMenteeModel,
} from '../components/modals/mentee-modals/reject-mentee-modal/reject-mentee-modal.component';
import { MoreDetailsMenteeModalComponent } from '../components/modals/more-details-modal/more-details-modal.component';
import { ReviewMentorshipModalComponent } from '../components/modals/review-mentorship-modal/review-mentorship-modal.component';
import {
  ConnectWithMentorRequest,
  Mentorship,
  MentorshipType,
} from '../mentoring-api.model';
import { MentoringApiService } from './mentoring-api.service';
import { MentoringHelperService } from './mentoring-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MentoringModalsService {
  public i18n = this.translate.instant([
    'Opportunities_AcceptMenteeModal_Error',
    'Opportunities_AcceptMenteeModal_Success',
    'Opportunities_AcceptMenteeModal_Success_Multi',
    'Opportunities_ConnectWithModal_Error',
    'Opportunities_ConnectWithModal_Success',
    'Opportunities_DeclineMenteeModal_Error',
    'Opportunities_DeclineMenteeModal_Success',
    'Opportunities_DeclineMenteeModal_Success_Multi',
    'TagsSvc_GetTagError',
  ]);

  constructor(
    private authService: AuthService,
    private focusStackService: FocusStackService,
    private modalService: ModalService,
    private mentoringApiService: MentoringApiService,
    private mentoringHelperService: MentoringHelperService,
    private mentoringTrackingService: MentoringTrackingService,
    private tagsService: TagsService,
    private translate: TranslateService
  ) {}

  /**
   * Accept a given mentee.
   *
   * @param mentorship - The selected mentorship to Accept
   * @param trigger - The element to return focus to afterwards.
   */
  public openAcceptMenteeModal(mentorship: Mentorship): Observable<{
    updatedMentorship: Mentorship;
    response: AcceptMenteeModel;
  }> {
    // grab current authUser as our mentor
    const mentor = this.authService.authUser;
    // show the accept modal
    return this.modalService
      .show<AcceptMenteeModel>(AcceptMenteeModalComponent, {
        inputs: {
          mentor,
          mentee: mentorship.mentee,
        },
      })
      .pipe(
        // first, accept the mentee
        switchMap((modalResponse) => {
          // update our mentorship, adding in contact and optionally description
          const updatedMentorship = {
            ...mentorship,
            mentorContactMethodDescription: modalResponse.description,
            mentorContactMethodId:
              this.mentoringHelperService.getMentorContactMethodId(
                modalResponse.contact
              ),
            mentorshipStatusName: modalResponse.mentorshipStatusName,
          };
          return (
            this.mentoringApiService
              .updateUserMentorships(
                updatedMentorship,
                modalResponse.availability !== mentor.viewerProfile.isMentoring
                  ? this.i18n.Opportunities_AcceptMenteeModal_Success_Multi
                  : this.i18n.Opportunities_AcceptMenteeModal_Success
              )
              // pass modalResponse to the next item in the chain!
              .pipe(
                mapTo({
                  updatedMentorship: updatedMentorship,
                  response: modalResponse,
                })
              )
          );
        }),
        // tap for modalResponse effects, like tracking
        tap(({ updatedMentorship, response }) => {
          // tracking
          return this.mentoringTrackingService.mentorshipAccepted(
            updatedMentorship,
            response
          );
        }),
        // then conditionally opt the mentor out of mentorship and then tap track
        switchMap(({ updatedMentorship, response }) =>
          // if the mentor updated their availability
          // (we're not using iif here because we don't want eager evaluation)
          response.availability !== mentor.viewerProfile.isMentoring
            ? // call the BE to update it
              this.mentoringApiService
                .updateUserIsMentoring(updatedMentorship, response.availability)
                // pass modalResponse to the next item in the chain!
                .pipe(mapTo({ updatedMentorship, response }))
            : // also return modalResponse even if we're not updating availability
              of({ updatedMentorship, response })
        ),
        tap(({ response }) => {
          if (response.availability !== mentor.viewerProfile.isMentoring) {
            this.mentoringTrackingService.mentorshipPreferenceUpdated(
              mentorship,
              response.availability
            );
          }
        }),
        // handle errors
        catchAndSurfaceNonModalError(
          this.i18n.Opportunities_AcceptMenteeModal_Error
        )
      );
  }

  /**
   * Create a new mentorship request with the logged-in user and a given
   * mentor.
   *
   * @param mentor - object for the mentor.
   * @param trigger - The element to return focus to afterwards.
   */
  public openConnectWithMentorModal(
    mentor: UserProfileSummary,
    element?: HTMLElement
  ): Observable<unknown> {
    // grab current authUser as our mentee
    const mentee = this.authService.authUser;
    // get our mentee tags
    return this.tagsService.getUserTopTags(false, false).pipe(
      // show modal
      switchMap((tags: UserInterest[]) =>
        this.modalService.show<ConnectWithMentorRequest>(
          ConnectWithMentorModalComponent,
          {
            inputs: {
              mentor,
              mentee: {
                ...mentee,
                viewerInterests: tags,
              },
            },
          }
        )
      ),
      // request the mentorship
      switchMap((mentorRequest) =>
        this.mentoringApiService.addUserMentorships(mentorRequest)
      ),
      // tap for side effects, like tracking
      tap((mentorRequest) => {
        // tracking
        this.mentoringTrackingService.mentorshipRequested(
          mentorRequest.tags?.length || 0,
          mentorRequest.tags.map((tag) => tag.name),
          !!mentorRequest.description,
          mentorRequest.mentorUserProfileKey
        );
      }),
      // handle focus
      finalize(() => {
        this.focusStackService.push(element);
      }),
      // handle errors
      catchAndSurfaceNonModalError(
        this.i18n.Opportunities_ConnectWithModal_Error
      )
    );
  }

  /**
   * Reject a given mentee.
   *
   * @param mentorship - The selected mentorship to pass to the BE.
   * @param trigger - The element to return focus to afterwards.
   */
  public openRejectMenteeModal(mentorship: Mentorship): Observable<{
    updatedMentorship: Mentorship;
    response: RejectMenteeModel;
  }> {
    // grab current authUser as our mentor
    const mentor = this.authService.authUser;

    // show the reject modal
    return this.modalService
      .show<RejectMenteeModel>(RejectMenteeModalComponent, {
        inputs: {
          mentor,
          mentee: mentorship.mentee,
        },
      })
      .pipe(
        // first, reject the mentee
        switchMap((modalResponse) => {
          const updatedMentorship = {
            ...mentorship,
            mentorshipStatusName: modalResponse.mentorshipStatusName,
          };

          return (
            this.mentoringApiService
              .updateUserMentorships(
                updatedMentorship,
                modalResponse.availability !== mentor.viewerProfile.isMentoring
                  ? this.i18n.Opportunities_DeclineMenteeModal_Success_Multi
                  : this.i18n.Opportunities_DeclineMenteeModal_Success
              )
              // pass modalResponse to the next item in the chain!
              .pipe(mapTo({ response: modalResponse, updatedMentorship }))
          );
        }),
        tap(({ response, updatedMentorship }) => {
          // tracking
          this.mentoringTrackingService.mentorshipDeclined(
            updatedMentorship,
            !!response.description
          );
        }),
        // then conditionally opt the mentor out of mentorship and then tap track
        switchMap(({ response, updatedMentorship }) => {
          // if the mentor updated their availability
          // (we're not using iif here because we don't want eager evaluation)
          return response.availability !== mentor.viewerProfile.isMentoring
            ? // call the BE to update it
              this.mentoringApiService
                .updateUserIsMentoring(updatedMentorship, response.availability)
                // pass response to the next item in the chain!
                .pipe(mapTo({ response, updatedMentorship }))
            : // also return response even if we're not updating availability
              of({ response, updatedMentorship });
        }),
        tap(({ response }) => {
          if (response.availability !== mentor.viewerProfile.isMentoring) {
            this.mentoringTrackingService.mentorshipPreferenceUpdated(
              mentorship,
              response.availability
            );
          }
        }),
        // handle errors
        catchAndSurfaceNonModalError(
          this.i18n.Opportunities_DeclineMenteeModal_Error
        )
      );
  }

  /**
   * Opens a modal to show more details of a mentorship,
   * including mentorship request-note and tags.
   *
   * @param mentorship - The mentorship object for the selected mentorship
   * @param mentorshipType - The mentorshipType of the selected mentorship
   * @param trigger - The element to return focus to afterwards.
   */
  public openMoreDetailsMentorshipModal(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ): Observable<{
    updatedMentorship: Mentorship;
    response: RejectMenteeModel | AcceptMenteeModel;
  }> {
    return this.modalService
      .show<MoreDetailsMenteeModalComponent>(MoreDetailsMenteeModalComponent, {
        inputs: {
          mentorship,
          mentorshipType,
        },
      })
      .pipe(
        switchMap((confirmMentorship) => {
          if (confirmMentorship) {
            return this.openAcceptMenteeModal(mentorship);
          } else {
            return this.openRejectMenteeModal(mentorship);
          }
        }),
        catchAndSurfaceNonModalError(this.i18n.TagsSvc_GetTagError)
      );
  }

  /**
   * Opens a modal to show more details of a mentorship, including note and tags
   *
   * @param mentorship - The current mentorship object
   * @param mentorshipType - The mentorship type
   */
  public openReviewMentorshipModal(
    mentorship: Mentorship,
    mentorshipType: MentorshipType,
    element: HTMLElement
  ) {
    const inputs = {
      mentorship,
      mentorshipType,
    };
    return this.modalService
      .show<{ updatedMentorship: Mentorship; dismissed: boolean }>(
        ReviewMentorshipModalComponent,
        { inputs }
      )
      .pipe(
        finalize(() => {
          this.focusStackService.push(element);
        })
      );
  }
}
