import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { DfIconModule } from '@lib/fresco';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

export interface ProgressCircleConfig {
  bgcolor?: string;
  checkmark?: 'large' | 'medium' | 'small' | 'extra-small';
  color?: string;
  duration?: number;
  padding?: number;
  radius?: number;
  responsive?: boolean;
  stroke?: number;
}

/**
 * A circular progressbar
 * @param percent - number between 0-100
 * @param config - configure the progressbar with the following optional properties:
 * - bgcolor: (default: degreed's ebony-a18) string (hex or rgb) for the color of the circle behind the progress indicator (note: not the fill color for inside the circle)
 * - checkmark: (default: undefined) string determining the size of center checkmark when progress reaches 100%. If undefined, no checkmark will appear.
 * - color: (default: degreed's blue) string (hex or rgb) for the color of the progress indicator. If no override color provided, default will automatically change to degreed's success color when progress reaches 100%;
 * - duration: (default: 300) number of miliseconds to perform the animation
 * - padding: (default: 0) number of pixels to pad the component which effectively adds a white circle outside of the progress circle
 * - radius: (default: 9) total number of pixels from center of circle to the outside, including the stroke
 * - responsive: (default: false) boolean which allows the progress circle to fill its container
 * - stroke: (default: 2) number of pixels for the width of the circle and indicator
 */
@Component({
  selector: 'dgx-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, RoundProgressModule, DfIconModule],
})
export class ProgressCircleComponent implements OnChanges {
  @Input() public config: ProgressCircleConfig;
  @Input() public percent: number = 0;

  public isComplete: boolean = false;
  public i18n = this.translate.instant(['Core_Completion_Progress']);

  private useDefaultColors: boolean = false;

  private readonly defaults: ProgressCircleConfig = {
    radius: 9,
    stroke: 2,
    padding: 0,
    duration: 300,
    bgcolor: this.defaultBackgroundColor,
    responsive: false,
  };

  constructor(
    private colorService: ColorService,
    private translate: TranslateService
  ) {
    this.config = this.defaults;
  }

  private get defaultBackgroundColor() {
    return this.colorService.getColor('ebony-a18');
  }

  private get defaultIncompleteColor() {
    return this.colorService.getColor('blue');
  }

  private get defaultCompleteColor() {
    return this.colorService.getColor('success');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.config.color) {
      this.useDefaultColors = true;
    }
    if (changes.config) {
      // set defaults
      this.config = { ...this.defaults, ...this.config };
    }
    if (changes.percent) {
      this.isComplete = this.percent === 100;
      if (this.useDefaultColors) {
        this.config.color = this.isComplete
          ? this.defaultCompleteColor
          : this.defaultIncompleteColor;
      }
    }
  }
}
