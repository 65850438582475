import {
  UpdateInputParameters,
  UpdateTaskParameters,
} from '@app/inputs/inputs-api.model';
import {
  AnyLearningResource,
  LearningResourceViewModel,
} from '@app/inputs/models/learning-resource.view-model';
import {
  EditStepDetailsUpdate,
  Pathway,
  PathwayDetailsModel,
  PathwayPermissionsModel,
  PathwayPrivacyOption,
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
} from '@app/pathways/rsm/pathway-api.model';
import { ReorderItem } from '@dg/pathways-rsm';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { JsonObject } from '@app/shared/models/core-api.model';
import { orderBy } from '@app/shared/utils';
import { getDeepCopy } from '@app/shared/utils/property';
import { AllInputApiEntities } from '@app/user-content/user-input/user-input.model';
import { TrackingEventData } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  AddContentNodes,
  NodeMap,
  PathwayActionSuccess,
  PathwayNode,
} from '../pathway.model';
import { PathwayBinItem } from './../pathway-api.model';
import {
  hasFauxSection,
  hasMockContainer,
  hasMockSubsection,
  isFauxlike,
} from './faux.utils';
import { toIndexes, toSectionIndex, toSubsectionIndex } from './pathway.utils';
import { PathwayTracking } from './tracking.utils';

// ***************************
// PUBLIC -------------------
// External Utils
// ***************************

export function preProcessSection(
  pathway: Pathway,
  section: PathwaySection
): PathwaySection {
  const sectionIndex = toSectionIndex(pathway.levels, section.node);
  const newNodeBaseProps = {
    pathId: pathway.id,
  };
  const newSection = {
    ...newNodeBaseProps,
    ...section,
    lessons: section.lessons ?? [],

    // passed by the BE but always as 0
    number: sectionIndex + 1,

    // ensure these are always present
    totalSteps: section.totalSteps ?? 0,
    completedSteps: section.completedSteps ?? 0,
    totalLessons: section.totalLessons ?? 1,
    optionalSteps: section.optionalSteps ?? 0,
  };

  newSection.lessons[0] = {
    ...newNodeBaseProps,
    ...newSection.lessons[0],
    visibleSteps: [],

    // passed by the BE but always as 0
    levelNumber: newSection.number,
    number: 1,

    // ensure these are always present
    totalSteps: newSection.lessons[0].totalSteps ?? 0,
    completedSteps: newSection.lessons[0].completedSteps ?? 0,
  };

  return newSection;
}

/**
 * Run when creating or modifying new subsections.
 *
 * @param pathway
 * @param subsection
 */
export function preProcessSubsection(
  pathway: Pathway,
  subsection: PathwaySubsection
): PathwaySubsection {
  const [sectionIndex, subsectionIndex] = toIndexes(pathway, subsection.node);
  const newSubsection = {
    ...subsection,

    pathId: pathway.id,

    // passed by the BE but always as 0
    levelNumber: sectionIndex + 1,
    number: subsectionIndex + 1,

    // ensure these are always present
    completedSteps: subsection.completedSteps ?? 0,
    totalSteps: subsection.totalSteps ?? 0,
  };

  return newSubsection;
}

/**
 * Creates a node map of items that were reordered by the user
 * @param reorderData
 * @param type
 * @returns NodeMap
 */
export function createReorderedNodeMap(
  reorderData: ReorderItem[],
  type: ActionType
): NodeMap {
  const nodeMap = {};
  reorderData.forEach((section, index) => {
    const newPosition = index + 1;
    nodeMap[section.node] = updateNodeString(
      getNodeIndex(type),
      reorderData[index].node,
      newPosition
    );
  });
  return nodeMap;
}

// TODO: Consolidate these methods with the ones in pathway.utils
function getNodeIndex(type: ActionType) {
  const indexMap = {
    section: 0,
    subsection: 1,
    step: 2,
  };
  return indexMap[type];
}
export function updateNodeString(
  nodeIndex: number,
  oldNode: string,
  newPosition: number
): string {
  const nodeParts = getNodeParts(oldNode);
  nodeParts.splice(nodeIndex, 1, newPosition.toString());
  let newNode = nodeParts.join('/');
  newNode = '/' + newNode + '/';
  return newNode;
}
// TODO: Consolidate these methods with the toNumbers method in pathway.utils
export function getNodeParts(node: string) {
  let nodeParts = node.split('/');
  nodeParts = nodeParts.filter((node) => {
    return node !== '';
  });
  return nodeParts;
}

/**
 * Reorders a cloned pathway to later be updated by the Pathway Store
 * @param pathway
 * @param reorderedPathway
 * @returns pathway
 */
export function reorderPathwaySections(
  pathway: PathwayDetailsModel,
  reorderedPathwayItems: ReorderItem[]
): PathwayDetailsModel {
  const _pathway: PathwayDetailsModel = getDeepCopy(pathway);

  reorderedPathwayItems.forEach((reorderedItem, index) => {
    let pathwaySection = findPathwaySectionByNode(reorderedItem.node, _pathway);

    pathwaySection = updateSectionIndex(pathwaySection, index + 1);
    pathwaySection.node = updateNodeString(
      0,
      reorderedItem.node,
      pathwaySection.number
    );
  });
  _pathway.levels.sort((a, b) => orderBy(a, b, ['number']));

  return _pathway;
}

/**
 * Update an existing pathway section with a new section
 * @param pathway
 * @param newSection
 * @returns pathway
 */
export function updatePathwaySection(
  pathway: PathwayDetailsModel,
  newSection: PathwaySection
): PathwayDetailsModel {
  const { node } = newSection;
  const _pathway: PathwayDetailsModel = getDeepCopy(pathway);
  _pathway.levels[toSectionIndex(_pathway.levels, node)] = newSection;
  return _pathway;
}

/**
 * Update pathway sections after a node was moved from a section to another
 *
 * @param pathway - Current pathway
 * @param srcSection - Section source that update was made from
 * @param destSection - Destination section that update was made to
 */
export function updatePathwayAfterNodeMove(
  pathway: PathwayDetailsModel,
  srcSection: PathwaySection,
  destSection: PathwaySection
): PathwayDetailsModel {
  const srcIndex = toSectionIndex(pathway.levels, srcSection.node);
  const destIndex = toSectionIndex(pathway.levels, destSection.node);
  const _pathway: PathwayDetailsModel = getDeepCopy(pathway);
  _pathway.levels[srcIndex] = srcSection;
  _pathway.levels[destIndex] = destSection;
  return _pathway;
}

/**
 * Creates reordered items for reorder modal
 * @param nodes
 * @param type
 * @param translate
 * @returns ReorderItem[]
 */
export function createReorderedItems(
  nodes: PathwayNode[],
  type: ActionType,
  translate: TranslateService
): ReorderItem[] {
  return nodes.map((_node: PathwayNode) => {
    const { node } = _node;
    const { subTitle, untitledTitle } = getReorderTitles(
      type,
      _node,
      translate
    );
    const title = _node.title ? _node.title : untitledTitle;
    const subText = subTitle;

    return {
      node,
      title,
      subText,
    };
  });
}

// ***************************
// Content Checks
// ***************************

/**
 * Check if a pathway has just one section (level).
 *
 * @param pathway - Pathway to check.
 */
export function hasAuthoringSection(pathway: Pathway): boolean {
  return pathway.levels?.length === 1;
}

/**
 * Check if a pathway has more than one section (level).
 *
 * @param pathway - Pathway to check.
 */
export function hasAuthoringSections(pathway: Pathway): boolean {
  return pathway.levels?.length > 1;
}

/**
 * Whether a pathway has *at least* one authoring section (level).
 *
 * @param pathway
 */
export function hasAtLeastOneAuthoringSection(pathway: Pathway): boolean {
  return !!pathway.levels?.length;
}

/**
 * Check if a pathway/section/subsection has exactly one step.
 *
 * @param parent - Parent to check.
 */
export function hasAuthoringStep(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return hasAuthoringStepOrSteps(parent, 1, 1);
}

/**
 * Check if a pathway/section/subsection has more than one step.
 *
 * @param parent - Parent to check.
 */
export function hasAuthoringSteps(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return hasAuthoringStepOrSteps(parent, 2);
}

/**
 * Check if a pathway/section/subsection has *at least* one step.
 *
 * @param parent - Parent to check.
 */
export function hasAtLeastOneAuthoringStep(
  parent: Pathway | PathwaySection | PathwaySubsection
): boolean {
  return hasAuthoringStepOrSteps(parent, 1);
}

// ***************************
// Showing and Hiding
// ***************************

/**
 * Whether to show *the pathway title* in the nav component (authoring only).
 */
export function shouldShowAuthoringNavTitle(
  pathway: Pathway,
  hasFaux: boolean
): boolean {
  // pathway has at least one real, non-faux section
  return (
    !!pathway &&
    // pathway has multiple sections
    (hasAuthoringSections(pathway) ||
      // pathway has only one section, but that section is real and not mock or faux
      (hasAuthoringSection(pathway) &&
        !hasMockContainer(pathway.levels) &&
        !hasFauxSection(pathway, hasFaux)))
  );
}

/**
 * Whether to show a given section's subsections within the nav component (authoring).
 *
 * @param section
 */
export function shouldShowAuthoringNavSubsections(
  subsections: PathwaySubsection[]
): boolean {
  return (
    (!isFauxlike(subsections) && !hasMockSubsection(subsections)) ||
    hasAtLeastOneAuthoringStep({ lessons: subsections } as PathwaySection)
  );
}

// ***************************
// Get And Format
// ***************************

/**
 * Gets add content modal nodes
 * @param subsections
 * @param subsectionNode
 * @param afterNode
 */
export function getAddContentNodes(
  subsections: PathwaySubsection[],
  subsectionNode: string,
  afterNode: string
): AddContentNodes {
  let beforeNode = null;
  const subsection =
    subsections[
      subsectionNode ? toSubsectionIndex(subsections, subsectionNode) : 0
    ];
  if (afterNode === 'first') {
    if (hasAtLeastOneAuthoringStep(subsection)) {
      beforeNode = subsection.steps[0].node;
    }
    afterNode = subsection.node;
  }
  return { beforeNode, afterNode };
}

/**
 * Get tracking action event for content added by add to Pathway modal
 * @param modalType
 */
export function getAddContentTracking(modalType: string): string {
  // TODO: straighten out this type; other modals (search, etc) are using string, but we were using TrackingType
  let action = 'Content Added ';
  switch (modalType) {
    case 'search':
      action += 'by Search';
      break;
    case 'manual':
      action += 'by Type';
      break;
    case 'bin':
      action += 'from Bin';
      break;
  }
  return action;
}

/**
 * Gets tracking for add to bin(hold for later)
 * @param pathId
 * @param contentItems
 * @returns add to bin tracking
 */
export function getAddToBinTracking(
  pathId: number,
  contentItems: LearningResourceViewModel[]
): TrackingEventData[] {
  const trackData = [];
  for (const input of contentItems) {
    //TODO: Check with analytics team on variables/properties needed.
    // In production we are passing the whole input and getting circular reference error so tracking is not currently being recorded.
    // The full input should not be needed for tracking but should confirm what is needed I added title, id etc. for now.
    const trackEventData = {
      eventName: PathwayTracking.ADDTOBIN_STEP,
      title: input?.title,
      id: input?.id,
      publicUrl: input?.publicUrl,
      internalUrl: input?.internalUrl,
      properties: {
        PathwayId: pathId,
      },
    };
    trackData.push(trackEventData);
  }
  return trackData;
}

/**
 * Helper function to get successful add content message
 * @param numContentItems
 * @returns string
 */
export function getAddContentDoneMsg(numContentItems: number) {
  return numContentItems > 1
    ? PathwayActionSuccess.ADDITEMS_SUBSECTION
    : PathwayActionSuccess.ADDITEM_SUBSECTION;
}

/**
 * @TODO: add tests to ported code
 */
export function updateStepDetails(
  newInput: AllInputApiEntities | Partial<PathwayStep>,
  originalStep: PathwayStep,
  pathway: Pathway,
  translate: TranslateService,
  isEditingLocalInputOnly: boolean
): EditStepDetailsUpdate {
  const anyInput = newInput as AllInputApiEntities;
  let newStep: PathwayStep;
  const { sectionDuration, pathwayDuration } = calculateNewPathwayDuration(
    originalStep,
    pathway,
    anyInput
  );

  let newStepReference: AnyLearningResource = {
    ...originalStep.reference,
    title: anyInput.title || anyInput.name,
    format: anyInput.format,
    summary: anyInput.summary || anyInput.description,
    imageUrl: anyInput.imageUrl,
    durationHours: anyInput.durationHours,
    durationMinutes: anyInput.durationMinutes,
  };

  if (anyInput.inputType === 'Course') {
    const durationDisplay =
      Number(anyInput.durationHours) === 1
        ? `${anyInput.durationHours} ${translate.instant(['Core_Hour'])}`
        : `${anyInput.durationHours} ${translate.instant(['Core_Hours'])}`;
    newStepReference = { ...newStepReference, durationDisplay };
  }

  newStep = {
    ...originalStep,
    durationHours: anyInput.durationHours,
    durationMinutes: anyInput.durationMinutes,
    reference: newStepReference,
  };

  if (isEditingLocalInputOnly) {
    newStep = {
      ...newStep,
      title: anyInput.title || anyInput.name,
      description: anyInput.summary || anyInput.description,
      imageUrl: anyInput.imageUrl,
    };
  }

  return { step: newStep, pathwayDuration, sectionDuration };
}

/**
 * @param originalStep - Original step.
 * @param originalPathway - Original pathway.
 * @param newStepData - New step data, *if* the step's duration is being updated.
 * @param isTogglingRequired - Whehter or not we are toggling between optional and required.
 * @returns Pathway and Section duration minutes/hours
 */

export function calculateNewPathwayDuration(
  originalStep: PathwayStep,
  originalPathway: Pathway,
  newStepData?:
    | AllInputApiEntities
    | Partial<UpdateTaskParameters & UpdateInputParameters<JsonObject>>,
  isTogglingRequired?: boolean
): Partial<EditStepDetailsUpdate> {
  const isOptional = originalStep.reference.pathwayStepDetails.isOptional;
  let originalSection =
    originalPathway.levels[
      toSectionIndex(originalPathway.levels, originalStep.node)
    ];
  // This is an update to a currently *optional* step that isn't being toggled
  // either from or to required. So don't need to do anything with pathway/section
  // durations here.
  if (!isTogglingRequired && isOptional) {
    return {
      pathwayDuration: {
        durationHours: originalPathway.durationHours ?? 0,
        durationMinutes: originalPathway.durationMinutes ?? 0,
      },
      sectionDuration: {
        node: originalStep.node,
        durationHours: originalSection.durationHours ?? 0,
        durationMinutes: originalSection.durationMinutes ?? 0,
      },
    };
  }
  // Otherwise, continue calculating differences.
  // First, convert our pathway and section times to minutes.
  const pathwayTimeInMinutes = timeToMinutes(
    originalPathway.durationHours,
    originalPathway.durationMinutes
  );
  const sectionTimeInMinutes = timeToMinutes(
    originalSection.durationHours,
    originalSection.durationMinutes
  );
  // Then get the original step time in minutes, making it negative if
  // we are both toggling required *and* isOptional is true.
  const originalStepTimeInMinutes = isTogglingRequired
    ? isOptional
      ? timeToMinutes(
          originalStep.durationHours,
          originalStep.durationMinutes
        ) * -1
      : timeToMinutes(originalStep.durationHours, originalStep.durationMinutes)
    : // If we aren't toggling required, newStepData must be truthy, and we will
      // want to grab the *reference* values rather than the top-level properties.
      timeToMinutes(
        originalStep.reference.durationHours,
        originalStep.reference.durationMinutes
      );
  // Initially set differenceInMinutes to be this value.
  let differenceInMinutes = originalStepTimeInMinutes;
  // But if newStepData is truthy, we have a bit more calculating to do.
  if (newStepData?.hasOwnProperty('durationMinutes')) {
    // Get our *new step data*'s duration in minutes.
    differenceInMinutes = timeToMinutes(
      newStepData.durationHours,
      newStepData.durationMinutes
    );
    // Original step shorter than the new one? Subtract original step time from the new step time.
    if (originalStepTimeInMinutes < differenceInMinutes) {
      differenceInMinutes = differenceInMinutes - originalStepTimeInMinutes;
    }
    // New step shorter than the original? Reverse them and make the number negative.
    else if (differenceInMinutes < originalStepTimeInMinutes) {
      differenceInMinutes = -(originalStepTimeInMinutes - differenceInMinutes);
    }
    // If they're exactly the same, the difference here should be 0.
    else {
      differenceInMinutes = 0;
    }
  }
  // Return updated section and pathway durations.
  return {
    sectionDuration: {
      ...minutesToTime(sectionTimeInMinutes + differenceInMinutes),
      node: originalStep.node,
    },
    pathwayDuration: minutesToTime(pathwayTimeInMinutes + differenceInMinutes),
  };
}

/**
 * Updates the the display options after editing details
 *
 * @param originalStep
 * @param newStep
 * @returns updated Step
 */
export function updateEditStepDisplay(
  originalStep: PathwayStep,
  newStep: Partial<PathwayStep>
): PathwayStep {
  let updatedStep = getDeepCopy(originalStep);

  updatedStep.title = newStep.title ?? originalStep.reference.title;

  // the property that represents the description for tasks is actually 'summary' rather than 'description'
  // 'description' is the property used for posts
  updatedStep.description =
    (newStep.summary || newStep.description) ??
    (originalStep.reference.summary || originalStep.reference.description);

  updatedStep.imageUrl = newStep.imageUrl ?? originalStep.reference.imageUrl;

  updatedStep.durationDisplay =
    newStep.durationDisplay ?? originalStep.reference.durationDisplay;

  updatedStep.durationHours =
    newStep.durationHours ?? originalStep.durationHours;
  updatedStep.durationMinutes =
    newStep.durationMinutes ?? originalStep.durationMinutes;

  updatedStep.reference.durationHours =
    newStep.durationHours ?? originalStep.reference.durationHours;
  updatedStep.reference.durationMinutes =
    newStep.durationMinutes ?? originalStep.reference.durationMinutes;

  updatedStep.isRequired = newStep.isRequired ?? originalStep.isRequired;

  return updatedStep;
}

export function getPrivacyOptions(
  belongsToOrg: boolean,
  translate: TranslateService,
  privacyLevel?: Visibility,
  onlyAuthor?: boolean,
  showOrgPublishOptions?: boolean,
  limitOrgPublish?: boolean
) {
  const privacyOptions: PathwayPrivacyOption[] = [];
  if (belongsToOrg) {
    if (onlyAuthor) {
      privacyOptions.push({
        name: translate.instant('dgPathwayPrivacy_ProfilePrivate'),
        id: 0,
      });
      privacyOptions.push({
        name: translate.instant('dgPathwayPrivacy_ProfileVisible'),
        id: 3,
      });
    } else if (!limitOrgPublish || !showOrgPublishOptions) {
      // If the collaborator is unable to publish to org/group, but the pathway is already at that level, do not allow that user to change the privacy setting at all.
      privacyOptions.push({
        name: translate.instant('dgPathwayPrivacy_CollaboratorProfilePrivate'),
        id: 0,
      });
      privacyOptions.push({
        name: translate.instant('dgPathwayPrivacy_CollaboratorProfileVisible'),
        id: 3,
      });
    }

    if (showOrgPublishOptions) {
      // If the collaborator is unable to publish to org/group, but the pathway is already at that level, show the option so they know it is selected, but not the other option.
      if (
        !limitOrgPublish ||
        (limitOrgPublish && privacyLevel === Visibility.groups)
      ) {
        privacyOptions.push({
          name: translate.instant('dgPathwayPrivacy_SpecificGroupsVisible'),
          id: 2,
        });
      }
      if (
        !limitOrgPublish ||
        (limitOrgPublish && privacyLevel === Visibility.public)
      ) {
        privacyOptions.push({
          name: translate.instant('dgPathwayPrivacy_OrgVisibleFormat'),
          id: 1,
        });
      }
    }
  } else {
    privacyOptions.push({
      name: translate.instant('dgPathwayPrivacy_Private'),
      id: 0,
    });
    privacyOptions.push({
      name: translate.instant('dgPathwayPrivacy_Public'),
      id: 3,
    });
  }
  return privacyOptions;
}

/**
 * Returns the values that should be updated in the store when pathway visibility/privacy changes
 */
export function getPrivacyParamsToUpdate(
  originalPermissions: PathwayPermissionsModel,
  updatedPermissions: PathwayPermissionsModel
): {
  canViewPathwayEnrollees: boolean;
  canViewInsightsTab: boolean;
  orgIdToUpdate?: number;
} {
  const hasViewEnrolleesPermissionChanged =
    updatedPermissions.canViewPathwayEnrollees &&
    originalPermissions.canViewEnrolleesTab !==
      updatedPermissions.canViewPathwayEnrollees;
  const hasViewInsightsPermissionChanged =
    updatedPermissions.canViewPathwayReports &&
    originalPermissions.canViewInsightsTab !==
      updatedPermissions.canViewPathwayReports;
  const havePermissionsChanged =
    hasViewEnrolleesPermissionChanged || hasViewInsightsPermissionChanged;
  let canViewPathwayEnrollees;
  let canViewInsightsTab;
  let orgIdToUpdate;

  if (havePermissionsChanged) {
    canViewPathwayEnrollees = hasViewEnrolleesPermissionChanged;
    canViewInsightsTab = hasViewInsightsPermissionChanged;
  }
  if (updatedPermissions.organizationId) {
    orgIdToUpdate = updatedPermissions.organizationId;
  }
  return { canViewPathwayEnrollees, canViewInsightsTab, orgIdToUpdate };
}

export function getVisibilityString(
  visibilityId: Visibility,
  translate: TranslateService,
  groupCount?: number | string
): string {
  const privacyGroupsType =
    visibilityId === 2 && typeof groupCount !== 'undefined'
      ? groupCount === 1
        ? 'A'
        : 'B'
      : '';
  const privacyLevelResource =
    'Core_PrivacyLevel' + visibilityId + privacyGroupsType;
  return translate.instant(privacyLevelResource, {
    groupCount: groupCount,
  });
}

/**
 * Helper function to get successful add content message
 * @param numContentItems
 * @returns string
 */
export function updateBinButtonText(
  savedItems: PathwayBinItem[],
  translate: TranslateService
): string {
  return savedItems?.length === 1
    ? translate.instant('Pathways_AddOneItem')
    : translate.instant('Pathways_AddXItems', {
        count: savedItems.length > 0 ? savedItems.length : '', // We don't want it to say "Add 0 Items" instead "Add Items"
      });
}

// ***************************
// PRIVATE -------------------
// Internal Utils
// ***************************

/**
 * Internal helper function.
 * @see {hasAuthoringStep} @see {hasAuthoringSteps} @see {hasAtLeastOneAuthoringStep}.
 *
 * @param parent - pathway/section/subsection to check.
 * @param min - Minimum number of steps to hit.
 * @param max - Maximum number of steps to avoid exceeding. May be undefined.
 */
function hasAuthoringStepOrSteps(
  parent: Pathway | PathwaySection | PathwaySubsection,
  min: number,
  max?: number
): boolean {
  if (!parent) {
    return false;
  }
  // Steps can be counted directly on subsections.
  if (parent.hasOwnProperty('steps')) {
    parent = <PathwaySubsection>parent;
    return atLastAndNotMoreThan(parent.steps?.length, min, max);
  }
  // For the other two checks, we have to loop through
  // and check their subsection children/grandchildren.
  // Array.some() will keep going as long as it returns false,
  // breaking as soon as it returns true, so we can use that
  // to break our loop and not check more than necessary.
  let totalSteps = 0;
  if (parent.hasOwnProperty('lessons')) {
    parent = <PathwaySection>parent;
    parent.lessons.some((lesson) => {
      totalSteps += lesson.steps?.length || 0;
      return stopCountingSteps(totalSteps, min, max);
    });
  } else {
    parent = <Pathway>parent;
    parent.levels.some((level) =>
      level.lessons.some((lesson) => {
        totalSteps += lesson.steps?.length || 0;
        return stopCountingSteps(totalSteps, min, max);
      })
    );
  }
  // Return our final evaluation. At least the minimum? Not more than
  // the maximum, if defined?
  return atLastAndNotMoreThan(totalSteps, min, max);
}

/**
 * Helper function for @see {hasAuthoringStepOrSteps}
 * Takes the total number of steps, the minimum value to hit, and an optional
 * maximum value to hit but not exceed.
 *
 * @param num - Value to check.
 * @param min - Minimum number to hit.
 * @param max - Maximum number to avoid exceeding. May be undefined.
 */
function atLastAndNotMoreThan(num: number, min: number, max?: number): boolean {
  return num >= min && (max === undefined || num <= max);
}

/**
 * Helper function for @see {hasAuthoringStepOrSteps}
 * Takes the total number of steps, the minimum value to hit, and an optional
 * maximum value to hit but not exceed.
 *
 * @param num - Value to check.
 * @param min - Minimum number to hit.
 * @param max - Maximum number to avoid exceeding. May be undefined.
 */
function stopCountingSteps(num: number, min: number, max?: number): boolean {
  // No max and we've hit the minimum.
  if (max === undefined && num === min) {
    return true;
  }
  // Max set we've exceeded the maximum.
  if (max && num > max) {
    return true;
  }
  // Otherwise, keep going.
  return false;
}

function findPathwaySectionByNode(
  node: string,
  pathway: PathwayDetailsModel
): PathwaySection {
  return pathway.levels.filter((section) => {
    return section.node === node;
  })[0];
}

/**
 * Updates section index which is  used for ui display and sorting pathway sections
 * @param section
 * @param index
 * @returns
 */
function updateSectionIndex(section: PathwaySection, index: number) {
  section.number = index;
  // Update section subsections(lessons) and steps with same index
  for (const subsection of section.lessons) {
    subsection.levelNumber = section.number;
    subsection.node = updateNodeString(0, subsection.node, section.number);
    for (const step of subsection.steps) {
      step.levelNumber = section.number;
      step.node = updateNodeString(0, step.node, section.number);
    }
  }
  return section;
}

function getReorderTitles(
  type: ActionType,
  node: Partial<PathwaySection & PathwaySubsection & PathwayStep>,
  translate: TranslateService
) {
  const mapConfig = {
    section: {
      untitledTitle: translate.instant('Pathways_UntitledSection'),
      subTitle: getTranslatedSubsectionCount(
        node.lessons?.length || 0,
        translate
      ),
    },
    subsection: {
      untitledTitle: translate.instant('Pathways_UntitledLesson'),
      subTitle: getTranslatedStepCount(node.steps?.length || 0, translate),
    },
    step: {
      untitledTitle: node.reference?.title
        ? node.reference.title
        : translate.instant('dgReorder_NoTitleText'),
      subTitle: node.referenceType,
    },
  };
  return mapConfig[type];
}

function getTranslatedSubsectionCount(
  totalSubsections: number,
  translate: TranslateService
) {
  return totalSubsections === 1
    ? translate.instant('Pathways_SubsectionCountSingular')
    : translate.instant('Pathways_SubsectionCountFormat', {
        count: totalSubsections,
      });
}

function getTranslatedStepCount(
  totalSteps: number,
  translate: TranslateService
) {
  return totalSteps === 1
    ? translate.instant('Pathways_ItemCountSingular')
    : translate.instant('Pathways_ItemCountFormat', {
        count: totalSteps,
      });
}

/**
 * Convert hours and minutes to minutes only for easy math.
 *
 * @param hours
 * @param minutes
 * @returns number
 */
function timeToMinutes(
  hours: number | string,
  minutes: number | string
): number {
  return (hours ? Number(hours) : 0) * 60 + (minutes ? Number(minutes) : 0);
}

/**
 * Convert minutes into hours and minutes.
 *
 * @param totalMinutes
 * @returns { durationHours: number, durationMinutes: number }
 */
function minutesToTime(totalMinutes: number): {
  durationHours: number;
  durationMinutes: number;
} {
  const durationHours = Math.floor(totalMinutes / 60);
  return {
    durationHours,
    durationMinutes: totalMinutes - durationHours * 60,
  };
}
