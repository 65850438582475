<fieldset>
  <legend *ngIf="!hideTitle" class="form__label font-semibold">
    {{ i18n.orgInternalContentVisibilitySelector_Title }}
  </legend>
  <div class="selection-box" (blur)="handleInputBlur($event)">
    <ngb-accordion
      #accordian
      [activeIds]="activeIds"
      [closeOthers]="true"
      (panelChange)="handlePanelChange($event)"
    >
      <ngb-panel id="ngb-panel-org">
        <ng-template ngbPanelHeader>
          <div class="l_flex m-l_flex">
            <label class="l_flex-grow guts-p-v-1">
              <input
                type="radio"
                class="selection-box__radio"
                name="visibility"
                [value]="true"
                [ngModel]="visibleToOrg"
                (ngModelChange)="handleVisibilityChange($event)"
                data-dgat="orgInternalContentVisibilitySelector-e15"
              />
              <span class="selection-box__text par--small">
                {{
                  i18n.orgInternalContentVisibilitySelector_VisibleToOrganization
                }}
              </span>
            </label>
            <div>
              <button
                ngbPanelToggle
                class="
                  color-ebony-a25
                  hover-bg-color-white hover-color-blue-dark
                  ftp-detail-row-list__trigger
                  guts-p-l-1-half
                "
                data-dgat="ftp-detail-row-list-592"
              >
                <df-icon
                  class="guts-m-full-1"
                  [icon]="
                    isVisibleToOrgExpanded ? 'chevron-up' : 'chevron-down'
                  "
                  size="medium"
                  [a11yText]="visibleToOrg ? i18n.Core_Close : i18n.Core_Open"
                ></df-icon>
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="bg-color-white guts-p-h-1-half guts-p-b-1-half">
            <dgx-group-visibility
              [includeUsersGroups]="true"
              [groupNames]="groupNames"
              [groupIds]="groupIds"
              [customTitle]="
                i18n.orgInternalContentVisibilitySelector_TargetGroupsLabel
              "
              [selectCallback]="handleGroupsAdd"
              [deselectCallback]="handleGroupsRemove"
              [groupPlaceholderText]="
                i18n.orgInternalContentVisibilitySelector_OrgVisibilityPlaceholder
              "
              [excludedPrivacyLevels]="excludedGroupPrivacyLevels"
            ></dgx-group-visibility>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-group">
        <ng-template ngbPanelHeader>
          <div class="l_flex m-l_flex">
            <label class="l_flex-grow guts-p-v-1">
              <input
                type="radio"
                class="selection-box__radio"
                name="visibility"
                [value]="false"
                [ngModel]="visibleToOrg"
                (ngModelChange)="handleVisibilityChange($event)"
                data-dgat="orgInternalContentVisibilitySelector-e5c"
              />
              <span class="selection-box__text par--small">{{
                i18n.orgInternalContentVisibilitySelector_VisibleToGroups
              }}</span>
            </label>
            <div>
              <button
                ngbPanelToggle
                class="
                  color-ebony-a25
                  hover-bg-color-white hover-color-blue-dark
                  ftp-detail-row-list__trigger
                  guts-p-l-1-half
                "
                data-dgat="ftp-detail-row-list-592"
              >
                <df-icon
                  class="guts-m-full-1"
                  [icon]="
                    isVisibleToGroupsExpanded ? 'chevron-up' : 'chevron-down'
                  "
                  size="medium"
                  [a11yText]="!visibleToOrg ? i18n.Core_Close : i18n.Core_Open"
                ></df-icon>
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="bg-color-white guts-p-h-1-half guts-p-b-1-half">
            <dgx-group-visibility
              [adminOnly]="true"
              [groupNames]="groupNames"
              [groupIds]="groupIds"
              [selectCallback]="handleGroupsAdd"
              [deselectCallback]="handleGroupsRemove"
              [hideTooltip]="true"
              [hideLabel]="true"
              [groupPlaceholderText]="
                i18n.orgInternalContentVisibilitySelector_GroupVisibilityPlaceholder
              "
            ></dgx-group-visibility>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</fieldset>
