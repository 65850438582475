import { MediaParseType } from '@app/shared/models/core.enums';
import { Injectable, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Comparator } from '@app/shared/ajs/core.model';
import {
  ImageSize,
  InputType,
  JsonObject,
} from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { UserProfileSummary, UserSearchItem } from '@app/user/user-api.model';
import { UserService } from '@app/user/services/user.service';
import {
  Book,
  CourseMetadata,
  Episode,
  Input,
  InputCreationFeedback,
  InputDetails,
  InputDuration,
  InputIdentifier,
  InputInfo,
  InputParameters,
  InputStatistics,
  InputStatisticsTuple,
  LearningInputModel,
  MediaEntry,
  MediaInput,
  MediaParameters,
  UpdateInputParameters,
  AssessmentParameters,
  BookParameters,
  CommentFeedItemModel,
  CourseParameters,
  EpisodeParameters,
  EventParameters,
  Task,
  TaskParameters,
  UpdateTaskParameters,
  InputTypeDetails,
} from '../inputs-api.model';
import {
  AnyInputOrUserInput,
  AnyInputUpdateResult,
  InputManager,
} from '../inputs.model';
import { CommentsService } from '@app/comments/comments.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { ModalService } from '@app/shared/services/modal.service';
import { ContentCatalogDuplicatesComponent } from '@app/content-catalog/components/modals/content-catalog-duplicates/content-catalog-duplicates.component';
import { PathwayAddContentService } from '@app/pathways/services/pathway-add-content.service';
import {
  LearnerHomeTrackerService,
  SectionTrackingProperties,
} from '@app/learner-home/services/learner-home-tracker.service';
import { ResourceSuggestionWithDetails } from '@app/learner-home/learner-home-api.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentHostingSource } from '@app/content-hosting';
import { catchAndRedirectError } from '@app/shared/utils';
import { CatalogSearchQueryOpts } from '@app/content-catalog/components/catalog-search-query-opts';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { GroupInfoCore } from '@app/groups/group-api';
import {
  MediaApiInput,
  MediaApiInputEdit,
} from '@app/user-content/user-input-v2/input.model';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { PathwayNode } from '@dg/pathways-rsm';
import { DgError } from '@app/shared/models/dg-error';
import { LDFlagsService } from '@dg/shared-services';

/**
 * Provides web API and helper methods for getting and manipulating Input (aka Content) objects.
 */
@Injectable({ providedIn: 'root' })
export class InputsService extends ApiServiceBase implements InputManager {
  /* eslint-disable @typescript-eslint/member-ordering */
  private _inputModify = new Subject<string>();

  public readonly inputModify = this._inputModify.asObservable();

  private i18n = this.translate.instant([
    'Core_DismissItemSuccess',
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_GetBookError',
    'InputsSvc_EditContentDuplicateError',
    'InputsSvc_GetEventError',
    'InputsSvc_GetEpisodeError',
    'InputsSvc_GetAssessmentError',
    'InputsSvc_GetCourseError',
    'InputsSvc_GetCourseMetadataError',
    'InputsSvc_GetMediaError',
    'InputsSvc_GetMediaMetadataError',
    'InputsSvc_GetSuggestedError',
    'InputsSvc_DismissSuggestedSuccess',
    'InputsSvc_DismissSuggestedError',
    'InputsSvc_QueueSuccess',
    'InputsSvc_QueueError',
    'InputsSvc_CompletedError',
    'InputsSvc_GetUserError',
    'InputsSvc_UsersTitle',
  ]);

  constructor(
    private learnerHomeTrackerService: LearnerHomeTrackerService,
    private translate: TranslateService,
    http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private userService: UserService,
    private commentsService: CommentsService,
    private dgxCommentsApiSvc: CommentsApiService,
    private modalService: ModalService,
    private pathwayAddContentService: PathwayAddContentService,
    private sanitizer: DomSanitizer,
    private userGroupListService: UserGroupListService,
    private ldFlagsService: LDFlagsService
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public notifyInputModified(contentType: string) {
    this._inputModify.next(contentType);
  }

  public cleanUrl(url: string) {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);

    // Special handling for Forbes, potentially also other sites?
    // TODO: Determine if this is actually still necessary. There are some Forbes articles with # that I found,
    // but the # portion didn't harm the link...?
    const modifyUrlsFrom = ['forbes.com'];
    for (const site of modifyUrlsFrom) {
      if (url.indexOf(site) > -1) {
        return sanitizedUrl.split('#')[0];
      }
    }

    return sanitizedUrl;
  }

  public canQuickParse(url: string) {
    // skip sites we know we can't quick parse in-app
    const skipQuick = ['forbes.com'];
    for (const site of skipQuick) {
      if (url.indexOf(site) > -1) {
        return false;
      }
    }
    return true;
  }

  public showCustomPostType(): boolean {
    return this.ldFlagsService.inputs.createPathwayPosts;
  }

  public showTopUsers({ inputType, inputId }: InputIdentifier, event: Event) {
    return this.get<UserProfileSummary[]>(
      `/Inputs/GetTopUsers/${inputId}`,
      { type: inputType, count: 10 },
      this.i18n.InputsSvc_GetUserError
    ).subscribe((users: UserProfileSummary[]) => {
      const count = users.length;
      const title =
        count === 1 ? 'InputsSvc_PersonCompleted' : 'InputsSvc_PeopleCompleted';
      this.userService.showUsersList(
        this.translate.instant(title, { count }),
        users,
        event
      );
    });
  }

  public showUsersWhoRated(
    { inputType, inputId }: InputIdentifier,
    rating: number,
    event: Event
  ) {
    return this.http
      .get<UserProfileSummary[]>('/inputs/getuserswhorated', {
        params: {
          inputId,
          inputType,
          rating,
        },
      })
      .pipe(
        catchError(() =>
          throwError(new Error(this.i18n.InputsSvc_GetUserError))
        )
      )
      .subscribe((summaries) => {
        if (summaries && summaries.length) {
          const count = summaries.length;
          const i18n =
            count === 1 ? 'InputsSvc_PersonLiked' : 'InputsSvc_PeopleLiked';
          const title = this.translate.instant(i18n, { count });
          this.userService.showUsersList(title, summaries, event);
        }
      });
  }

  public getCmsInputsByUrl(
    organizationId: number,
    url: string,
    identifier?: InputIdentifier
  ) {
    const facets = [
      { id: 'Url', values: [encodeURIComponent(url)] },
      { id: 'Internal', values: [organizationId] },
    ];
    const facetStrings = JSON.stringify(facets);
    let exclusionList;
    if (identifier?.inputId !== undefined && identifier.inputType !== null) {
      const exclusionlist = [identifier];
      exclusionList = JSON.stringify(exclusionlist);
    }
    return this.http
      .get<LearningInputModel>('/learning/findinputs', {
        params: {
          terms: '',
          tags: '',
          facets: facetStrings,
          count: 50,
          skip: 0,
          includeProviders: false,
          defaults: 'CMS',
          exclusionList,
          useResourceImages: true,
        },
      })
      .pipe(
        catchError(() =>
          throwError(new Error(this.i18n.InputsSvc_GeneralError))
        )
      );
  }

  public getInputStatistics(inputs: InputIdentifier[]) {
    return this.http
      .post<InputStatistics[]>('/inputs/GetInputStatistics', inputs)
      .pipe(
        catchError((e, o) => {
          // stats aren't critical enough for toast message
          console.error(e);
          return of([]);
        })
      );
  }

  public addNewInput(input: InputParameters) {
    // serialize details as embedded JSON field
    const normedInput: InputParameters<string> = {
      ...input,
      details: JSON.stringify(input.details),
    };
    return this.post<InputCreationFeedback>(
      '/inputs/addinput',
      normedInput,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateInput(input: UpdateInputParameters) {
    // serialize details as embedded JSON field
    const normedInputParams: UpdateInputParameters<string> = {
      ...input,
      details: JSON.stringify(input.details),
    };
    return this.put<AnyInputUpdateResult>(
      '/inputs/updateinput',
      normedInputParams
    );
  }

  /**
   * Updates pathway node used for updating title, description etc on a pathway
   * @param node
   * @returns void
   */
  public async updatePathwayNode(node: PathwayNode): Promise<void> {
    try {
      const request$ = this.http.post<void>(
        '/pathways/updatepathwaynode',
        node
      );
      await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_UpdateError'), e);
    }
  }

  /**
   * Returns newer generic Input object with Details property
   */
  public getInput(inputId: number, inputType: InputType, isCms = false) {
    return this.http
      .get<Input<string>>('/inputs/getinput', {
        params: {
          inputId,
          inputType,
          isCms,
          useResourceImages: true,
        },
      })
      .pipe(
        map((input) => ({
          ...input,
          details: input?.details
            ? (JSON.parse(input.details) as JsonObject)
            : {}, // parse embedded JSON field into details obj
        })),
        catchError((e) => throwError(this.i18n.InputsSvc_GeneralError))
      );
  }

  /**
   * Returns old InputInfo object based on Type
   */
  public getInputInfo(identifier: InputIdentifier, pathwayId?: number) {
    return this.get<InputInfo>('/inputs/getinputinfo', {
      ...identifier,
      useResourceImages: true,
      parentResourceId: pathwayId,
      parentResourceTypeName: pathwayId ? 'Pathway' : '',
    });
  }

  public getInputAndStatistics(
    identifier: InputIdentifier,
    useResourceImages: boolean = false
  ) {
    const params = { ...identifier, useResourceImages };

    return this.get<InputStatisticsTuple<string>>(
      '/inputs/getinputdetails',
      params
    );
  }

  public getHostedInputUrls(identifier: InputIdentifier) {
    return this.get<ContentHostingSource[]>(
      '/inputs/gethostedinputurls',
      identifier
    ).pipe(catchAndRedirectError('/error-handler/404'));
  }

  public addExternalInput(item: InputDetails) {
    return this.post(
      '/inputs/addexternalinput',
      item,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public addBook(params: BookParameters) {
    return this.post<InputCreationFeedback>(
      '/inputs/addbook',
      params,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateBook(identifier: InputIdentifier) {
    return this.put(
      '/inputs/updatebook',
      identifier,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public getBook(inputId: number, isCms = false) {
    return this.get<Book>(
      '/inputs/getbook',
      { inputId, isCms },
      this.i18n.InputsSvc_GetBookError
    );
  }

  public addEvent(params: Partial<EventParameters>) {
    return this.post<InputCreationFeedback>(
      '/inputs/addevent',
      params,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateEvent(identifier: InputIdentifier) {
    return this.put('/inputs/updateevent', identifier);
  }

  public getEvent(
    inputId: number,
    isCms = false
  ): Observable<AnyInputOrUserInput> {
    return this.get(
      '/inputs/getevent',
      {
        inputId,
        isCms,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetEventError
    );
  }

  public addTask(task: TaskParameters) {
    return this.post('/inputs/addTask', task, this.i18n.InputsSvc_AddItemError);
  }

  public updateTask(input: UpdateTaskParameters): Observable<void> {
    return this.put('/inputs/updateTask', input);
  }

  public getTask(inputId: number): Observable<Task> {
    return this.get('/inputs/getTask', {
      inputId,
      useResourceImages: true,
    });
  }

  // the only place this was being used, it was more used to determine whether or not
  // something existed in the catalog than to get the duration which was available elsewhere
  // we may want to refactor this or use a different method for that check if it's still needed
  public getInputDurationByUrl(inputType: string, inputUrl: string) {
    return this.http
      .get<InputDuration>('/inputs/getInputDurationByUrl', {
        params: {
          type: inputType,
          url: inputUrl,
        },
      })
      .pipe(
        catchError((e) => {
          if (e?.status !== 404) {
            console.error(e);
          }
          // server returns 404 when there's no match, so just swallow this
          // not relevant to the user to show an error that there was a 404
          return EMPTY;
        })
      );
  }

  public addEpisode(params: Partial<EpisodeParameters>) {
    return this.post<InputCreationFeedback>(
      '/inputs/addepisode',
      params,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateEpisode(identifier: InputIdentifier) {
    return this.http
      .put('/inputs/updateepisode', identifier)
      .pipe(
        catchError((e) =>
          throwError(
            new Error(
              e.data.isDuplicate
                ? this.i18n.InputsSvc_EditContentDuplicateError
                : this.i18n.InputsSvc_GeneralError
            )
          )
        )
      );
  }

  public getEpisode(inputId: number, isCms = false) {
    return this.get<Episode>(
      '/inputs/getepisode',
      {
        inputId,
        isCms,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetEpisodeError
    );
  }

  public addAssessment(params: Partial<AssessmentParameters>) {
    return this.post<InputCreationFeedback>(
      '/inputs/addassessment',
      params,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateAssessment(identifier: InputIdentifier) {
    return this.put('/inputs/updateassessment', identifier).pipe(
      catchError((e) =>
        throwError(
          new Error(
            e.data.isDuplicate
              ? this.i18n.InputsSvc_EditContentDuplicateError
              : this.i18n.InputsSvc_GeneralError
          )
        )
      )
    );
  }

  public getAssessment(
    inputId: number,
    isCms = false
  ): Observable<AnyInputOrUserInput> {
    return this.get(
      '/inputs/getassessment',
      {
        inputId,
        isCms,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetAssessmentError
    );
  }

  // TODO: Remove Input if unneeded after migrations are completed
  public addCourse(params: Partial<CourseParameters> | Input) {
    return this.post<InputCreationFeedback>(
      '/inputs/addcourse',
      params,
      this.i18n.InputsSvc_AddItemError
    );
  }

  public updateCourse(input) {
    return this.put(
      '/inputs/updatecourse',
      input,
      this.i18n.InputsSvc_GeneralError
    );
  }

  public getCourse(inputId: number, isCms = false): Observable<Input> {
    return this.get(
      '/inputs/getcourse',
      {
        inputId,
        isCms,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetCourseError
    );
  }

  public getCourseMetadata(url: string) {
    return this.http
      .get<CourseMetadata>('/inputs/getcoursemetadata', {
        params: { url },
      })
      .pipe(
        catchError((e, o) => {
          if (e.status !== 0) {
            this.notifier.showError(this.i18n.InputsSvc_GetCourseMetadataError);
          }
          return throwError(
            new Error(this.i18n.InputsSvc_GetCourseMetadataError)
          );
        })
      );
  }

  public addMedia(
    params: MediaParameters,
    useQuickCheck?: boolean,
    suppressErrors?: boolean
  ) {
    return this.post<InputCreationFeedback>('/inputs/addmedia', {
      ...params,
      useQuickCheck,
    }).pipe(
      catchError((e) =>
        suppressErrors
          ? EMPTY
          : throwError(new Error(this.i18n.InputsSvc_AddItemError))
      )
    );
  }

  public updateMedia(identifier: InputIdentifier) {
    return this.http
      .put('/inputs/updatemedia', identifier)
      .pipe(
        catchError((e) =>
          throwError(
            new Error(
              e.data.isDuplicate
                ? this.i18n.InputsSvc_EditContentDuplicateError
                : this.i18n.InputsSvc_GeneralError
            )
          )
        )
      );
  }

  public getMediaEntry(inputId: number, isCms?: boolean) {
    return this.get<MediaEntry>(
      '/inputs/getmediaentry',
      {
        inputId,
        isCms: isCms || false,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetMediaError
    );
  }

  /**
   * Currently just used in V2 articles
   * TODO: when we get to video this type will need to be updated to or
   *
   */
  public async getMediaEntryAsPromise(
    inputId: number,
    isCms?: boolean
  ): Promise<MediaApiInputEdit> {
    return firstValueFrom(
      this.getMediaEntry(inputId, isCms).pipe(
        switchMap((mediaEntry) => {
          const input = mediaEntry as any as MediaApiInputEdit;
          // NOTE: If the id is 0 and the type is set to user, it means the user is no longer in degreed.
          // So ignore getting getUserDetailsByKey and have the user reset the primary contact
          if (input.primaryContactResourceId) {
            if (input.primaryContactResourceType === 'User') {
              return this.userGroupListService
                .getUserDetailsByKey(input.primaryContactResourceId)
                .pipe(
                  map(
                    (user: UserSearchItem) =>
                      ({ ...input, owner: user } as any as MediaApiInputEdit)
                  )
                );
            } else if (input.primaryContactResourceType === 'Group') {
              const group: GroupInfoCore = {
                resourceType: input.primaryContactResourceType,
                resourceId: input.primaryContactResourceId,
                name: input.primaryContactName,
              };
              return of({ ...input, owner: group } as any as MediaApiInputEdit);
            }
          }
          return of(input);
        })
      )
    );
  }

  public getInputTypeSpecificDetails(
    inputId: string,
    inputType: string,
    recommendationId?: number
  ): Observable<InputTypeDetails> {
    return this.get(
      '/inputs/getinputtypespecificdetails',
      {
        inputId,
        inputType,
        r: recommendationId,
      },
      this.i18n.InputsSvc_GetMediaError
    ).pipe(map((data: any) => data.input));
  }

  /**
   * Get input type based on url
   * @param url
   * @private
   */
  public getInputType(url: string) {
    let type = 'Article';
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (
      sanitizedUrl.indexOf('vimeo') > -1 ||
      sanitizedUrl.indexOf('youtube') > -1 ||
      sanitizedUrl.indexOf('youtu.be') > -1 ||
      sanitizedUrl.indexOf('www.ted.com') > -1 ||
      sanitizedUrl.indexOf('tedxtalks.ted.com/video') > -1 ||
      sanitizedUrl.indexOf('channel9.msdn.com') > -1
    ) {
      type = 'Video';
    }
    return type;
  }

  public async getInputOwner(
    primaryContactResourceId: number
  ): Promise<AnyRecommendee> {
    if (primaryContactResourceId) {
      return firstValueFrom(
        this.userGroupListService.getUserDetailsByKey(primaryContactResourceId)
      );
    }
  }

  public getMediaMetadata(
    url: string,
    expectedType: InputType,
    parseType: MediaParseType
  ) {
    url = encodeURIComponent(url);
    // Note that this doesn't do the same data transformation as InputsSvc.GetMediaMetadata, some of which is now done by the server.
    return this.get<MediaInput>('/inputs/getmediametadata', {
      url,
      expectedType,
      parseType,
    });
  }

  /**
   * Currently just used in V2 articles
   * TODO: when we get to video this type will need to be updated to or
   *
   */
  public async getMediaMetadataAsPromise(
    url: string,
    expectedType: InputType,
    parseType: MediaParseType
  ): Promise<MediaApiInput> {
    return firstValueFrom(
      this.getMediaMetadata(url, expectedType, parseType)
    ) as any as MediaApiInput;
  }

  public find(params: CatalogSearchQueryOpts) {
    return this.http
      .get<LearningInputModel>('/learning/findinputs', {
        params: {
          terms: params.terms,
          tags: params.tags.join(),
          facets: JSON.stringify(params.facets),
          skip: params.skip,
          count: params.count,
          includeProviders: params.includeProviders,
          defaults: params.defaults,
          includeCompleted: params.includeCompleted,
          sortBy: params.sortBy,
          isAscending: params.isAscending,
          exclusionList: JSON.stringify(params.exclusionList),
          useResourceImages: true,
        },
      })
      .pipe(
        map((model: LearningInputModel) => {
          const excludeLanguageFacet = (facets) => {
            return facets.filter((obj) => {
              if (obj.id === 'Language' && obj.values.length === 1) {
                return false;
              } else {
                return true;
              }
            });
          };
          // Temporary exclusion to improve on language support, but not expose to user.
          return { ...model, facets: excludeLanguageFacet(model.facets) };
        }),
        catchError((e) =>
          throwError(new Error(this.i18n.InputsSvc_GeneralError))
        )
      );
  }

  public getSuggestedInputs(
    skip: number,
    take: number,
    enrolledPathwayCount: number
  ) {
    return this.get(
      '/inputs/getsuggestedinputs',
      {
        skip,
        take,
        enrolledPathwayCount,
      },
      this.i18n.InputsSvc_GetSuggestedError
    );
  }
  public dismissSuggestedInput(
    resource,
    suggestion: ResourceSuggestionWithDetails,
    sectionTrackingProperties: SectionTrackingProperties,
    element?: HTMLElement
  ) {
    return this.http
      .post('/inputs/dismisssuggestedinput', {
        userSuggestionId: suggestion.userSuggestionId,
      })
      .pipe(
        tap(() => {
          if (!resource.isModal) {
            this.notifier.showSuccess(
              suggestion.referenceType === 'Opportunity'
                ? this.i18n.Core_DismissItemSuccess
                : this.i18n.InputsSvc_DismissSuggestedSuccess
            );

            this.learnerHomeTrackerService.itemDismissed(element, {
              ...sectionTrackingProperties,
              userSuggestionId: suggestion.userSuggestionId,
              itemType: suggestion.referenceType,
              isEndorsed: suggestion.reference.isEndorsed,
            });
          }
        }),
        catchError((e) =>
          throwError(new Error(this.i18n.InputsSvc_DismissSuggestedError))
        )
      );
  }

  public completeSuggestedInput(userSuggestion, location: string) {
    return this.http
      .post('/inputs/completesuggestedinput', {
        userSuggestionId: userSuggestion.userSuggestionId,
      })
      .pipe(
        catchError((e) =>
          throwError(new Error(this.i18n.InputsSvc_CompletedError))
        )
      )
      .subscribe();
  }

  /**
   * Retrieves the embedded content URL for an input. The url contains the required SSO authorization configuration
   * to allow us to access the content from an iframe within the LXP.
   *
   * @param inputId - The ID of the input.
   * @param inputType - The type of the input.
   * @returns An observable that emits the embedded content URL as a string.
   */
  public getHMMEmbeddedContentUrl(
    inputId: number,
    inputType: string
  ): Observable<string> {
    return this.http
      .get<string>(
        `/inputs/gethmmembeddedurl?inputId=${inputId}&inputType=${inputType}`
      )
      .pipe(
        catchError((e) =>
          throwError(new Error(this.i18n.InputsSvc_CompletedError))
        )
      );
  }

  public updateInputComment(
    inputType: any,
    inputId: number,
    comment: string | any,
    inputTitle: string
  ): Observable<CommentFeedItemModel | null> {
    // some older implementations are passing an object of comment.Comment
    // not sure if we'll break something by taking it out so leaving it this way
    // continue to support that for now, but also support just passing a string
    const commentText =
      typeof comment === 'string' ? comment : comment?.Comment;
    if (commentText) {
      const resource = {
        /** Here we need to map the resourceId from the Resource interface  {@link comments.d.ts } to the old referenceId */
        resourceId: inputId,
        resourceType: inputType,
        title: inputTitle,
      };
      return this.dgxCommentsApiSvc.addComment(resource, commentText);
    }
    return of(null);
  }

  public mapInputStatistics(
    inputs: any[],
    statistics: InputStatistics[],
    comparer?: Comparator<any>,
    isAjs?: boolean
  ) {
    // handles mapping to pascalcase if its ajs
    if (isAjs) {
      return this.ajsMapping(inputs, statistics, comparer);
    }

    if (!statistics) {
      return;
    }
    if (!comparer) {
      comparer = (input, stat) => {
        const type =
          input.inputType || input.referenceType || input.contentType;
        const id = input.inputId || input.referenceId || input.contentId;

        return type === stat.inputType && id === stat.inputId;
      };
    }

    for (const input of inputs) {
      for (const stat of statistics) {
        if (comparer(input, stat)) {
          input.statistics = stat;
        }
      }
    }
  }

  /**
   * Handles mapping ajs casing for legacy code
   * @param inputs
   * @param statistics
   * @param comparer
   */
  private ajsMapping(
    inputs: any[],
    statistics: InputStatistics[],
    comparer?: Comparator<any>
  ) {
    if (!statistics) {
      return;
    }
    if (!comparer) {
      comparer = (input, stat) => {
        const type =
          input.InputType || input.ReferenceType || input.ContentType;
        const id = input.InputId || input.ReferenceId || input.ContentId;

        return type === stat.InputType && id === stat.InputId;
      };
    }

    for (const input of inputs) {
      for (const stat of statistics) {
        if (comparer(input, stat)) {
          input.Statistics = stat;
        }
      }
    }
  }

  public mapInputComments(inputs: InputIdentifier[], comments) {
    return this.commentsService.mapObjectComments(inputs, comments, (i, c) => {
      return i.inputId === c.objectId && i.inputType === c.objectType;
    });
  }

  public getImageSizes() {
    return this.get<ImageSize[]>('/inputs/getimagesizes');
  }

  public reportBrokenImage({ inputId, inputType }: InputIdentifier) {
    this.tracker.trackEventData({
      action: 'Broken 3rd-Party Image',
      category: inputType,
      label: inputId.toString(),
    });
  }

  public trackInput(
    action: string,
    category: string,
    label?: string,
    data?: any,
    element?: HTMLElement
  ) {
    return this.tracker.trackEventData({
      action,
      category,
      element,
      label,
      properties: data,
    });
  }

  public reportUserInputView(
    inputId: number,
    inputType: string,
    userProfileKey: number,
    properties,
    currentUrl: string
  ): Observable<null> {
    const queryParams = `?inputId=${inputId}&inputType=${inputType}&userProfileKey=${userProfileKey}&currentUrl=${currentUrl}`;
    return this.http.put<null>(
      `/inputs/reportuserinputview${queryParams}`,
      properties
    );
  }

  public viewDuplicates(
    duplicates: InputDetails[],
    addFn?: (duplicate: InputDetails) => any,
    pathwayId?: number
  ) {
    const addToBin = !!addFn || !!pathwayId;
    this.modalService
      .show(ContentCatalogDuplicatesComponent, {
        inputs: {
          duplicates,
          addToBin,
        },
      })
      .pipe(
        switchMap((duplicate: InputDetails) => {
          if (duplicate && addFn) {
            return addFn(duplicate);
          } else if (duplicates && pathwayId) {
            return this.pathwayAddContentService.addInputToBin(
              pathwayId,
              duplicate.inputType,
              duplicate.inputId
            );
          }
        })
      )
      .subscribe();
  }

  ////// POC - Related Content Recommendations
  public getRelatedContentRecommendations({
    inputId,
    inputType,
    userProfileKey,
    take,
  }): Observable<any> {
    const queryParams = `?userProfileKey=${userProfileKey}&inputId=${inputId}&inputType=${inputType}&take=${take}`;
    return this.post(`/RelatedContent/GetRelatedContent${queryParams}`, {
      inputId,
      inputType,
      userProfileKey,
      take,
    });
  }
}
