import { Injectable } from '@angular/core';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import {
  InputContext,
  InputFormsModel,
} from '@app/user-content/user-input-v2/input.model';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { VideoModel } from '@app/user-content/user-input-v2/inputs/video/video.model';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';

export enum MediaMetadataStatus {
  None,
  Parsing,
  QuickParsed,
  FullyParsed,
}

// TODO: continue to refine and define what should be included in all input types
/**
 * Base Input service to define common functionality, with the ability to overwrite if it is different.
 */
@Injectable()
export abstract class InputsFacadeBaseV2<
  TViewModel extends InputFormsModel = InputFormsModel
> {
  /**
   * Provides notifications to the attached form that either the form view model, the the form UI configuration
   * or both have changed
   */
  public viewModel$ = new BehaviorSubject<TViewModel>(undefined);
  /**
   * TruncationLength for Summary/Description
   */
  public readonly truncationLength = 500; // number of characters

  /**
   * Provides notifications indicating the media status of the form data
   */
  protected mediaMetadataStatus$ = new BehaviorSubject(
    MediaMetadataStatus.None
  );

  /**
   * Provides notifications indicating the submission status of the form data
   */
  protected submissionStatus$ = new BehaviorSubject<SubmissionStatus>(
    SubmissionStatus.None
  );

  constructor(protected inputsService: InputsService) {
    // if we have other states we want to send out
    // Show the Next/Submit button as spinning whenever we're parsing, waiting to submit on dependent data (such as a file upload or media parse), or actually submitting
  }

  /** Gets the latest view model */
  protected get viewModel(): TViewModel {
    return this.viewModel$.value;
  }

  protected set viewModel(viewModel: TViewModel) {
    this.viewModel$.next(viewModel);
  }

  /********************************************************
   * Form Updates
   ********************************************************/

  /**
   * When a form control is updated, patch the form group value and mark field as dirty and touched.
   * @param formGroup
   * @param field
   * @param value
   */
  public onFormControlUpdate(
    formGroup: FormGroup,
    fieldName: string,
    value: any
  ): void {
    formGroup.patchValue({
      [fieldName]: value,
    });
    const field = formGroup.get(fieldName);
    field.markAsDirty();
    field.markAsTouched();
  }

  /**
   * Recursively mark all controls as touched
   * @param formGroup
   */
  public markFormAsTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormAsTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }

  /********************************************************
   * DUPLICATES
   ********************************************************/

  /**
   * Reset view models duplicate values
   */
  public resetDuplicates() {
    this.viewModel = { ...this.viewModel, duplicateCount: 0, duplicates: [] };
  }

  /** Shows duplicate inputs modal already in the catalog */
  public viewDuplicates() {
    this.inputsService.viewDuplicates(
      this.viewModel.duplicates,
      undefined,
      this.viewModel.inputContext.pathwayId // pathway ID will be undefined in catalog context
    );
  }

  /**
   * Get the duplicates of the URL in the catalog manager
   * (this will be used in catalog and in plans/pathway when the checkbox is checked)
   * @param url to check
   */
  public async fetchUrlDuplicates(
    url: string,
    defaultOrgId?: number
  ): Promise<void> {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputContext.inputType as InputType,
    };

    const request$ = this.inputsService.getCmsInputsByUrl(
      this.viewModel.inputContext.organizationId ?? defaultOrgId,
      url,
      inputIdentifier
    );
    const response = await lastValueFrom(request$);
    if (response.inputs) {
      this.viewModel = {
        ...this.viewModel,
        duplicates: response.inputs,
        duplicateCount: response.inputs.length,
      };
    }
  }

  /******************************************************************
   * Image Uploading
   ******************************************************************/

  /**
   * On Image upload success update the Image URl on the view model.
   * @param event
   */
  public onImageUploadSuccess(pictureUrl: string) {
    this.viewModel = {
      ...this.viewModel,
      imageUrl: pictureUrl,
    };
  }

  /**
   * On Image deletion remove the imageURL from the view model.
   */
  public onDeleteImage() {
    this.viewModel = {
      ...this.viewModel,
      imageUrl: '',
    };
  }

  /**
   * On add or change of content owner field, update the view model.
   */
  public onContentOwnerChange(contentOwner: AnyRecommendee): void {
    this.viewModel = {
      ...this.viewModel,
      owner: contentOwner,
    };
  }

  public abstract onSubmit(form: FormGroup): Promise<void>;

  public abstract onNext(url: string): Promise<void>;

  protected abstract performSuccessSideEffects();

  protected abstract performFailureSideEffects();

  // Initialize the view model with the passed in context, default properties, and computed properties
  protected initializeContextViewModel(inputContext: InputContext) {
    // On initialization of context make sure to start with a new reset model
    this.resetModel();

    // initialize context
    this.viewModel = {
      ...this.viewModel,
      inputContext: inputContext,
      inputType: inputContext.inputType,
      isInitialForm: inputContext.isEditing ? false : true,
      organizationId: inputContext.organizationId,
    };
  }

  /******************************************************************
   * Content Hosting
   ******************************************************************/
  // NOTE: We are adding the content uploader to plans|Pathways & catalog
  // for the uploader when in progress we will disable the button
  // But after succeeded we will re-enable the submit button, but we will nit
  // automatically send the user to the next screen giving the user control to look over the upload first.
  // NOTE: article format is only catalog
  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {}

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
  }

  protected onContentFileChange(file: File) {
    this.viewModel.isSubmitButtonDisabled = true;
  }

  private resetModel(): void {
    this.viewModel = undefined;
  }
}
