import { Component } from '@angular/core';
import { completeIconSet, DfIconRegistry } from '@lib/fresco';

// TODO:
// The icons really should have been registered individually, but due to the migration
// we setup this bulk registry for ajs and now there are a lot of instances where we
// would need to fix the registration in the ngx.  So instead we're keeping this component
// which will just register all icons for the ngx app.
@Component({
  selector: 'dgx-bulk-icon-registry',
  template: '<ng-content></ng-content>',
})
export class BulkIconRegistryComponent {
  // Constructor occurs before ngOnChanges which is where the icon
  // is being set on the DfIconComponent, so keep this here or
  // there will be a lot of console.warn-ings about unregistered
  // icon usage
  constructor(private iconRegistry: DfIconRegistry) {
    const icons = completeIconSet;
    this.iconRegistry.registerIcons(icons);
  }
}
