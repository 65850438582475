import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Mentorship, MentorshipType } from '@app/mentoring/mentoring-api.model';
import {
  MentorshipStatusEnum,
  MentorshipTypeEnum,
} from '@app/mentoring/mentoring.enums';
import { MentoringApiService } from '@app/mentoring/services/mentoring-api.service';
import { MentoringReviewModalService } from '@app/mentoring/services/mentoring-review-modal.service';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { NotificationType } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize, switchMap, tap } from 'rxjs/operators';

export interface MentorReviewSections {
  header: string;
  subheader: string;
  icon: string;
  actionButtonText: string;
  defaultAction: (rating: MentorReviewSections) => any;
  actionIsLoading: boolean;
  actionCompleted: boolean;
  actionCompletedText: string;
}

@Component({
  selector: 'dgx-review-mentorship-modal',
  templateUrl: './review-mentorship-modal.component.html',
  styleUrls: ['./review-mentorship-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewMentorshipModalComponent implements OnInit {
  public i18n = this.translate.instant([
    'Core_Complete',
    'Core_Done',
    'Mentoring_ReviewMentorship',
    'Mentoring_ReviewMenteeship',
    'Mentoring_PeerRating',
    'Mentoring_PeerRating_MenteeSubNote',
    'Mentoring_PeerRating_MentorSubNote',
    'dgTagRating_RequestPeerRating',
    'dgTagRating_AddRating',
    'Mentoring_PeerRating_Requested',
    'Mentoring_GiveFeedback',
    'Mentoring_GiveFeedback_MentorSubNote',
    'Mentoring_GiveFeedback_MenteeSubNote',
    'Mentoring_GiveFeedback_FeedbackShared',
    'Mentoring_GiveFeedback_MenteeActionButton',
    'Mentoring_RequestFeedback',
    'Mentoring_RequestFeedback_MentorSubNote',
    'Mentoring_RequestFeedback_MenteeSubNote',
    'Mentoring_RequestFeedback_Requested',
  ]);

  public readonly NotificationType = NotificationType;

  public ratingSections: MentorReviewSections[] = [];
  public modalHeader: string = '';

  @Input() public mentorship: Mentorship;
  @Input() public mentorshipType: MentorshipType;
  public isLoading: boolean = true;
  public submitButtonText: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private mentoringApiService: MentoringApiService,
    private mentoringReviewModalService: MentoringReviewModalService,
    private mentoringTrackingService: MentoringTrackingService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.mentoringTrackingService.reviewModalOpened(
      this.mentorship,
      this.mentorshipType
    );

    this.setupI18nStrings();

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  public closeModal(dismissed?: boolean) {
    this.activeModal.close({
      updatedMentorship: this.mentorship,
      dismissed,
    });
  }

  private setupI18nStrings() {
    switch (this.mentorshipType) {
      case MentorshipTypeEnum.Mentor:
        this.modalHeader = this.i18n.Mentoring_ReviewMentorship;
        this.ratingSections = this.mentorRatingsSetup();
        break;
      case MentorshipTypeEnum.Mentee:
        this.modalHeader = this.i18n.Mentoring_ReviewMenteeship;
        this.ratingSections = this.menteeRatingsSetup();
        break;
    }

    switch (this.mentorship.mentorshipStatusName) {
      case MentorshipStatusEnum.Accepted: // if the mentorship is Accepted = In progress
        this.submitButtonText = this.i18n.Core_Complete;
        break;
      default:
        // else the user went to the review modal after completing
        this.submitButtonText = this.i18n.Core_Done;
        break;
    }
  }

  private menteeRatingsSetup() {
    return [
      {
        header: this.i18n.Mentoring_PeerRating,
        subheader: this.i18n.Mentoring_PeerRating_MenteeSubNote,
        icon: 'person',
        actionButtonText: this.i18n.dgTagRating_RequestPeerRating,
        defaultAction: (section: MentorReviewSections) =>
          this.openRequestPeerRatingModal(section),
        actionCompleted: this.mentorship.requestedMenteePeerReview,
        actionCompletedText: this.i18n.Mentoring_PeerRating_Requested,
        actionIsLoading: false,
      },
      {
        header: this.i18n.Mentoring_GiveFeedback,
        subheader: this.i18n.Mentoring_GiveFeedback_MenteeSubNote,
        icon: 'author-note',
        actionButtonText: this.i18n.Mentoring_GiveFeedback,
        defaultAction: (section: MentorReviewSections) =>
          this.openGiveFeedbackModal(section),
        actionCompleted: !!this.mentorship.toMenteeFeedbackMessage,
        actionCompletedText: this.i18n.Mentoring_GiveFeedback_FeedbackShared,
        actionIsLoading: false,
      },
      {
        header: this.i18n.Mentoring_RequestFeedback,
        subheader: this.i18n.Mentoring_RequestFeedback_MenteeSubNote,
        icon: 'author-note',
        actionButtonText: this.i18n.Mentoring_RequestFeedback,
        defaultAction: (section: MentorReviewSections) =>
          this.requestFeedback(section),
        actionCompleted: this.mentorship.requestedMenteeFeedback,
        actionCompletedText: this.i18n.Mentoring_RequestFeedback_Requested,
        actionIsLoading: false,
      },
    ];
  }

  private mentorRatingsSetup() {
    return [
      {
        header: this.i18n.Mentoring_PeerRating,
        subheader: this.i18n.Mentoring_PeerRating_MentorSubNote,
        icon: 'person',
        actionButtonText: this.i18n.dgTagRating_AddRating,
        defaultAction: (section: MentorReviewSections) =>
          this.openRequestPeerRatingModal(section),
        actionCompleted: this.mentorship.requestedMentorPeerReview,
        actionCompletedText: this.i18n.Mentoring_PeerRating_Requested,
        actionIsLoading: false,
      },
      {
        header: this.i18n.Mentoring_GiveFeedback,
        subheader: this.i18n.Mentoring_GiveFeedback_MentorSubNote,
        icon: 'author-note',
        actionButtonText: this.i18n.Mentoring_GiveFeedback_MenteeActionButton,
        defaultAction: (section: MentorReviewSections) =>
          this.openGiveFeedbackModal(section),
        actionCompleted: !!this.mentorship.toMentorFeedbackMessage,
        actionCompletedText: this.i18n.Mentoring_GiveFeedback_FeedbackShared,
        actionIsLoading: false,
      },
      {
        header: this.i18n.Mentoring_RequestFeedback,
        subheader: this.i18n.Mentoring_RequestFeedback_MentorSubNote,
        icon: 'author-note',
        actionButtonText: this.i18n.Mentoring_RequestFeedback,
        defaultAction: (section: MentorReviewSections) =>
          this.requestFeedback(section),
        actionCompleted: this.mentorship.requestedMentorFeedback,
        actionCompletedText: this.i18n.Mentoring_RequestFeedback_Requested,
        actionIsLoading: false,
      },
    ];
  }

  private openRequestPeerRatingModal(section: MentorReviewSections) {
    section.actionIsLoading = true;
    return this.mentoringReviewModalService
      .openRequestPeerRatingModal(this.mentorship, this.mentorshipType)
      .pipe(
        switchMap((response) => {
          if (response) {
            const { updatedMentorship, modalResponse } = response;
            this.mentorship = updatedMentorship;

            return this.mentoringApiService.requestTagPeerRatingMentorships(
              modalResponse.raterProfileKey,
              modalResponse.comment,
              modalResponse.tagIds,
              this.mentorshipType
            );
          }
        }),
        finalize(() => {
          this.setupI18nStrings();
          section.actionIsLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  private openGiveFeedbackModal(section: MentorReviewSections) {
    section.actionIsLoading = true;

    return this.mentoringReviewModalService
      .openGiveFeedbackModal(this.mentorship, this.mentorshipType)
      .pipe(
        switchMap(({ updatedMentorship, message }) => {
          this.mentorship = updatedMentorship;
          return this.mentoringApiService.mentoringGiveFeedback(
            updatedMentorship.mentee?.userProfileKey ??
              updatedMentorship.mentor?.userProfileKey,
            this.mentorshipType === MentorshipTypeEnum.Mentee,
            message
          );
        }),
        tap((gaveFeedback: boolean) => {
          // if the user gave feedback, update the modal UI
          if (gaveFeedback) {
            this.setupI18nStrings();
            this.cdr.markForCheck();
          }
        }),
        finalize(() => {
          section.actionIsLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  private requestFeedback(section: MentorReviewSections) {
    section.actionIsLoading = true;
    const requestIsFromMentor =
      this.mentorshipType === MentorshipTypeEnum.Mentee;
    const requestFeedbackFromUserProfileKey = requestIsFromMentor
      ? this.mentorship.menteeUserProfileKey
      : this.mentorship.mentorUserProfileKey;

    this.mentoringApiService
      .requestFeedback(requestFeedbackFromUserProfileKey, requestIsFromMentor)
      .pipe(
        tap(() => {
          this.mentoringTrackingService.feedbackRequested(
            this.mentorship,
            this.mentorshipType
          );
        }),
        finalize(() => (section.actionIsLoading = false))
      )
      .subscribe(() => {
        const requestedMenteeFeedback = true;
        const requestedMentorFeedback = true;
        const updatedMentorship = requestIsFromMentor
          ? { ...this.mentorship, requestedMenteeFeedback }
          : { ...this.mentorship, requestedMentorFeedback };
        this.mentorship = updatedMentorship;
        this.ratingSections = requestIsFromMentor
          ? this.menteeRatingsSetup()
          : this.mentorRatingsSetup();
        this.cdr.markForCheck();
      });
  }
}
