import { SearchUrlService } from '@app/shared/services/search-url.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { A11yService } from '@app/shared/services/a11y.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { SearchSuggestion, SearchSuggestionSource } from '../search-api.model';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { UserInterest } from '@app/user/user-api.model';

import { SearchService } from '../services/search.service';
import { SearchFlagsService } from './search-flags.service';

export interface GroupedSuggestionType {
  title: string;
  types: string[];
  count: number;
  allowNavigation?: boolean;
}

const learningTypes = [
  'Article',
  'Assessment',
  'Book',
  'Course',
  'Episode',
  'Event',
  'Video',
];

/** A high-level service for sharing search suggestion presentation data between global and local search inputs */
@Injectable({ providedIn: 'root' })
export class SearchSuggestionPresenterService {
  private static readonly maxSuggestionsPerTopic = 4;
  private static readonly suggestionTypes: ResourceType[] = [
    'Tag',
    'Pathway',
    'Target',
    'Group',
    'User',
    'Opportunity',
  ];
  public static readonly learningTypes = learningTypes;
  // Order is explicit
  private static readonly groupedSuggestionTypes: GroupedSuggestionType[] = [
    {
      title: 'LearningSearch_PathwaysAndPlans',
      types: ['Pathway', 'Target'],
      allowNavigation: true,
      count: 2,
    },
    {
      title: 'LearningSearch_Learning',
      types: learningTypes,
      allowNavigation: true,
      count: 4,
    },
    {
      title: 'LearningSearch_People',
      types: ['User'],
      allowNavigation: true,
      count: 4,
    },
    {
      title: 'LearningSearch_Groups',
      types: ['Group'],
      allowNavigation: true,
      count: 4,
    },
    {
      title: 'Core_Opportunities',
      types: ['Opportunity'],
      allowNavigation: true,
      count: 4,
    },
    {
      title: 'Core_Skills',
      types: ['Tag'],
      allowNavigation: true,
      count: 4,
    },
  ];
  private static readonly typeTitleTranslation = {
    Pathway: 'Core_Pathway',
    Target: 'Core_Target',
    Article: 'Core_Article',
    Assessment: 'Core_Assessment',
    Book: 'Core_Book',
    Course: 'Core_Course',
    Episode: 'Core_Episode',
    Event: 'Core_Event',
    Video: 'Core_Video',
  };

  constructor(
    private authService: AuthService,
    private searchService: SearchService,
    private a11yService: A11yService,
    private translateService: TranslateService,
    private searchUrlService: SearchUrlService,
    private searchFlagsService: SearchFlagsService
  ) {}

  /** Gets a hierarchical tree of suggestion view models by type; either the default user interest suggestions
   * or, if a search term is provided, the search suggestions
   */
  public getSmartSuggestions(searchTerm: string) {
    return !searchTerm || !searchTerm.trim()
      ? this.getDefaultSuggestions()
      : this.getSearchSuggestions(searchTerm);
  }

  /** Gets a hierarchical tree of user interest view models as default search suggestions grouped under a single user skills root node. */
  public getDefaultSuggestions(): Observable<ParentItemViewModel[]> {
    const skillsRoot: ParentItemViewModel[] = [];
    // By default, display a single topic containing the user's interests as suggestions
    // Mapping to abstract viewmodel lets us use the same mechanism for both user interests and real search suggestions
    const interestVms = this.authService.authUser?.viewerInterests.map<
      SimpleItemViewModel<UserInterest>
    >((i) => ({
      trackingKey: i.userInterestId,
      model: i,
      title: i.title,
      linkUrl: this.searchUrlService.getGlobalSearchURL(i.title),
    }));
    skillsRoot.push({
      model: 'UserTags',
      trackingKey: 'UserTags',
      title: 'dgGlobalSearch_TopicsLabel',
      subitems: interestVms,
    });
    return of(skillsRoot);
  }

  // TODO: remove with search-initiation-autocomplete flag removal
  /** Gets a hierarchical tree of search suggestion view models, with suggestions grouped by suggestion type, that match the search term. */
  public getSearchSuggestions(
    searchTerm: string,
    source?: SearchSuggestionSource
  ): Observable<ParentItemViewModel[]> {
    return this.searchService
      .getSuggestions(
        searchTerm,
        SearchSuggestionPresenterService.maxSuggestionsPerTopic,
        source
      )
      .pipe(
        map((s: SearchSuggestion[]) => {
          const suggestionsByType: ParentItemViewModel[] = [];
          for (const type of SearchSuggestionPresenterService.suggestionTypes) {
            // Map suggestions filtered by topic to view models
            const suggestionVms = s
              .filter((x) => x.type === type)
              .map((x) => ({
                model: x,
                trackingKey: x.id,
                title: x.name,
                // Provide default search url where missing
                linkUrl:
                  x.url || this.searchUrlService.getGlobalSearchURL(x.name),
              }));
            // Map topics to view model with child suggestions
            const typeVm: ParentItemViewModel<
              string,
              SimpleItemViewModel<SearchSuggestion>
            > = {
              model: type,
              trackingKey: type,
              title:
                type === 'Opportunity'
                  ? 'Core_Opportunities'
                  : 'Core_' + type + 's',
              subitems: suggestionVms,
            };
            suggestionsByType.push(typeVm);
          }
          return suggestionsByType;
        })
      );
  }

  /** Gets a hierarchical tree of search suggestion view models, with suggestions grouped by suggestion type, that match the search term. */
  public getGroupedSearchSuggestions(
    searchTerm: string,
    source?: SearchSuggestionSource
  ): Observable<ParentItemViewModel[]> {
    return this.searchService
      .getSuggestions(
        searchTerm,
        SearchSuggestionPresenterService.maxSuggestionsPerTopic,
        source
      )
      .pipe(
        map((suggestions: SearchSuggestion[]) =>
          SearchSuggestionPresenterService.groupedSuggestionTypes.reduce(
            (arr, suggestionType) => {
              const typeVm = this.buildTypeVm(suggestionType, suggestions);

              // Prepend autosuggest options
              if (typeVm.types.includes('Tag')) {
                const autoSuggestVm = this.buildAutoSuggestVm(typeVm.subitems);
                return [autoSuggestVm, ...arr, typeVm];
              }

              return [...arr, typeVm];
            },
            []
          )
        )
      );
  }

  ///// POC Natural Language Catalog Search
  public getNaturalLanguageSearch(query: string): Observable<any> {
    return this.searchService.getNaturalLanguageSearchResult(query);
  }
  ///// POC Natural Language Catalog Search
  public streamNaturalLanguageSearch(query: string): Observable<string> {
    return this.searchService.streamNaturalLanguageSearchResult(query);
  }

  public announceSuggestionCount(n: number) {
    const announcement: string = this.translateService.instant(
      'A11y_NumberSuggestionsAvailableFormat',
      { n }
    );
    this.a11yService.announcePolite(announcement);
  }

  private buildTypeVm(
    { title, types, allowNavigation, count }: GroupedSuggestionType,
    response: SearchSuggestion[]
  ) {
    const subitems = types.reduce((arr, type) => {
      const suggestions = response
        .filter((suggestion) => suggestion.type === type)
        .slice(0, count)
        .map(this.buildSuggestionVm.bind(this));
      return [...arr, ...suggestions];
    }, []);

    // Map topics to view model with child suggestions
    return {
      model: title,
      trackingKey: title,
      title,
      allowNavigation: [
        'LearningSearch_People',
        'LearningSearch_Groups',
        'Core_Opportunities',
        'Core_Skills',
      ].includes(title)
        ? this.searchFlagsService.showSearchLayoutRedesign && allowNavigation
        : allowNavigation,
      types,
      subitems,
    } as ParentItemViewModel<string, SimpleItemViewModel<SearchSuggestion>>;
  }

  private buildSuggestionVm(suggestion: SearchSuggestion) {
    return {
      model: suggestion,
      trackingKey: suggestion.id,
      typeTitleTranslation:
        SearchSuggestionPresenterService.typeTitleTranslation[suggestion.type],
      title: suggestion.name,
    };
  }

  private buildAutoSuggestVm(subitems: SimpleItemViewModel[]) {
    return {
      model: 'Autosuggest',
      trackingKey: 'Autosuggest',
      title: 'Autosuggest',
      subitems,
    };
  }
}
