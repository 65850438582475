import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

// Utils
import { TranslateService } from '@ngx-translate/core';

import { BusinessRule } from '@app/business-rules/model';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';

import { SkillsStandardsApi } from '@app/skill-standards/skill-standards-api.model';
import { SkillStandardsService } from '@app/skill-standards/services/skill-standards.service';

@Component({
  selector: 'dgx-skill-standards-segment-card',
  templateUrl: './skill-standards-segment-card.component.html',
  styleUrls: ['./skill-standards-segment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillStandardsSegmentCardComponent implements OnInit {
  // Bindings
  @Input() public segment: SkillsStandardsApi.SkillStandardsSegmentCard;
  @Input() public modifyOptionsFn?: ModifyOptionsFnType<any>;

  public i18n = this.translate.instant([
    'Core_MoreOptions',
    'OrgManage_Manage',
  ]);

  public loading: boolean = true;
  public menuConfig: MenuViewModel[];
  public segmentBusinessRule: BusinessRule;
  public businessRuleAttributes = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private skillStandardsService: SkillStandardsService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.menuConfig = this.modifyOptionsFn(this.segment, []);
    if (!this.segment) {
      return;
    }

    // TODO: Pass business rule attributes in with segment vs api calls from cards
    this.skillStandardsService
      .getSegment(this.segment.organizationId, this.segment.segmentId)
      .subscribe((segmentBusinessRule) => {
        this.segmentBusinessRule = segmentBusinessRule;
        if (this.segmentBusinessRule.predicates.length > 0) {
          this.businessRuleAttributes = this.buildPredicateList();
        }
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  public formatBusinessRuleList(
    items: string[],
    numOfItemsToDisplay: number = 5,
    charLimitOfItem: number = 35
  ) {
    let truncatedList;
    if (items) {
      truncatedList = items.map((item: string) => ({
        title: item,
        value: this.truncateRule(item, charLimitOfItem),
      }));
    }
    if (truncatedList.length > numOfItemsToDisplay) {
      truncatedList = truncatedList.slice(0, numOfItemsToDisplay);
      truncatedList.push({
        value: `+ ${items.length - numOfItemsToDisplay}`,
      });
    }
    return truncatedList;
  }

  public manageSegment() {
    this.router.navigateByUrl(
      // `/orgs/${this.segment.organizationId}/skillstandards/${this.segment.skillStandardId}/segments/${this.segment.segmentId}`
      `/skillstandards/${this.segment.skillStandardId}/segments/${this.segment.segmentId}`
    ).then(() => {
      window.scrollTo(0,0);
    });
  }

  private buildPredicateList() {
    const list = [];
    const predicates = this.segmentBusinessRule.predicates;
    const operators = this.segmentBusinessRule.predicateOperators;
    if (operators.length > 0) {
      operators.map((operator, i) => {
        let attribute: string;
        if (i === 0) {
          attribute = `${operator.firstPredicate.fieldName} ${operator.firstPredicate.comparisonOperator} ${operator.firstPredicate.comparisonValue} ${operator.operator} ${operator.secondPredicate.fieldName} ${operator.secondPredicate.comparisonOperator} ${operator.secondPredicate.comparisonValue}`;
        } else {
          attribute = `${operator.operator} ${operator.secondPredicate.fieldName} ${operator.secondPredicate.comparisonOperator} ${operator.secondPredicate.comparisonValue}`;
        }
        list.push(attribute);
      });
    } else {
      list.push(
        `${predicates[0].fieldName} ${predicates[0].comparisonOperator} ${predicates[0].comparisonValue} `
      );
    }
    return list;
  }

  private truncateRule(rule: string, lengthLimit: number = 0) {
    if (lengthLimit === 0) {
      return rule;
    }
    return rule.length > lengthLimit
      ? rule.substring(0, lengthLimit) + '...'
      : rule;
  }
}
