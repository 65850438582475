import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TrackingEventArgs } from '@app/shared/services/tracking.model';

import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '@app/profile/services/profile.service';
import { WindowToken } from '@app/shared/window.token';
@Component({
  selector: 'dgx-users-pictures',
  templateUrl: './users-pictures.component.html',
  styleUrls: ['./users-pictures.component.scss'],
})
export class UsersPicturesComponent {
  /** Boolean to set to template edit mode */
  @Input() public isEditing = false;
  @Input() public viewAllUsers = 'Opportunities_ViewAllCollaborators';
  @Input() public manageUsers = 'TargetAuthorsForm_Title';
  /** The number of user pictures to display before truncating to a 'counting' icon. If 0, all will be displayed. *Defaults 0.* */
  @Input() public limit: number = undefined;
  /** Whether or not to wrap the user pictures into separate lines. *Defaults false.* */
  @Input() public wrap = false;
  @Input() public users: UserSearchItem[];

  @Output() public onEdit: EventEmitter<Event> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private trackerService: TrackerService,
    private profileService: ProfileService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get maxLimitReached(): boolean {
    return this.users.length - this.limit > 99;
  }

  /** Show a concatenated blob with the count for additional users in the list when the limit is reached */
  public get showConcatenatedUsers(): boolean {
    return this.limit && this.users.length > this.limit;
  }

  public getAriaLabelForUser(user): string {
    return this.translate.instant('TargetAuthorsForm_A11yAuthorDescription', {
      authorname: user.name,
    });
  }

  public editClick($event: Event) {
    this.onEdit.emit($event);
    // prevent the anchor from navigating away to a blank route when in edit mode
    $event.preventDefault();
  }

  public trackByFn(_: number, user: UserSearchItem): number {
    return user.userProfileKey;
  }

  public userAvatarClicked(user: UserSearchItem) {
    const data: TrackingEventArgs = {
      action: 'Avatar Clicked',
      properties: {
        userProfileKey: user.userProfileKey,
        ProfileType: this.profileService.getProfileType(),
      },
    };

    this.trackerService.trackEventData(data);
    this.windowRef.open(`/profile/${user.vanityUrl}`, '_self');
  }
}
