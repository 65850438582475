<ng-container *ngIf="statisticsLoaded">
  <dgx-modal-header
    [isBorderless]="true"
    (dismiss)="onClose()"
    [canCancel]="isDetailModal"
  >
    <ng-container *ngIf="!isPreview">
      <!-- Recommendation Header -->
      <dgx-user-recommendation-header
        *ngIf="userRecHeaderItem"
        [item]="this.userRecHeaderItem"
        [maxLines]="2"
        [showComment]="true"
      >
      </dgx-user-recommendation-header>
      <dgx-input-statistics
        *ngIf="!userRecHeaderItem && resource.statistics?.topUser"
        [input]="resource"
      ></dgx-input-statistics>
    </ng-container>
    <h3 *ngIf="isPreview" class="h3">
      {{ previewHeader }}
    </h3>
  </dgx-modal-header>

  <!-- Learning Content -->
  <dgx-input-card
    [resource]="resource"
    [isDetailModal]="isDetailModal"
    [isPreview]="isPreview"
    [expandComments]="expandComments"
    [isMarketplaceProgram]="config?.isMarketplaceProgram"
    [searchResultTrackingConfig]="config?.searchResultTrackingConfig"
  >
  </dgx-input-card>
</ng-container>
