<dgx-modal
  [canCancel]="false"
  [canCloseHeader]="true"
  [useDefaultForm]="false"
  [isSubmitDisabled]="false"
  [isHeaderBorderless]="true"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
  headerWrapperClasses="guts-p-b-0"
>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div class="engaged-learner-modal guts-p-h-5">
      <div class="l_flex m-l_flex">
        <dgx-profile-pic
          [profile]="profile"
          [hideTooltip]="true"
          size="large"
        ></dgx-profile-pic>
      </div>
      <h1 class="engaged-learner-modal__title h2 crap guts-m-t-2">
        {{ i18n.ActiveLearner_ModalTitle }}
      </h1>
      <p class="engaged-learner-modal__how-to-get guts-m-t-2">
        {{ i18n.ActiveLearner_HowToGetRecognized }}
      </p>
      <ul class="engaged-learner-modal__conditions">
        <li>{{ i18n.ActiveLearner_Condition1 }}</li>
        <li>{{ i18n.ActiveLearner_Condition2 }}</li>
        <li>{{ i18n.ActiveLearner_Condition3 }}</li>
        <li>{{ i18n.ActiveLearner_Condition4 }}</li>
      </ul>
      <p class="engaged-learner-modal__fine-print guts-m-t-1-half">
        {{ i18n.ActiveLearner_FinePrint }}
      </p>
    </div>
  </ng-container>
</dgx-modal>
