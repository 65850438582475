<form role="form" [formGroup]="form" (ngSubmit)="onRequest()">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="i18n.RequestRating_SendRequest"
    [isSubmitDisabled]="disableSubmitButton"
    [useDefaultForm]="false"
    headerClasses="h3"
    headerWrapperClasses="guts-p-h-2 guts-p-v-1-half"
    bodyClasses="guts-p-full-2"
  >
    <ng-container class="modal-header">
      {{ i18n.Mentoring_PeerRatingRequest }}
    </ng-container>

    <ng-container class="modal-body">
      <div class="l_flexbar guts-m-b-2">
        <span
          class="form__label guts-m-r-1 par par--light_a par--small font-medium"
        >
          {{ i18n.Core_To }}:
        </span>

        <div class="user-badge bg-color-ebony-a08">
          <dgx-profile-pic [profile]="peer" size="tiny"> </dgx-profile-pic>
          <span class="user-badge__name guts-p-h-1 par par--small"
            >{{ peer.name }}
          </span>
        </div>
      </div>

      <span class="form__label">
        {{ i18n.Core_Skills }}
      </span>
      <dgx-tags-search
        class="guts-m-b-half"
        [tags]="selectedTags"
        [topTags]="suggestedTags"
        [allowExistingOnly]="true"
        [placeholderText]="i18n.OrgSkills_SkillPlaceholder"
        [showHintAlone]="false"
        [hint]="i18n.Core_Suggestions"
        (addTag)="addReviewTag($event)"
        (removeTag)="removeReviewTag($event)"
      ></dgx-tags-search>

      <df-form-root
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="fields"
        class="block guts-p-t-half"
      >
      </df-form-root>
    </ng-container>
  </dgx-modal>
</form>
