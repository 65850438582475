import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'dgx-expanding-title-section',
  templateUrl: './expanding-title-section.component.html',
  styleUrls: ['./expanding-title-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandingTitleSectionComponent {
  @Input() public title: string;
  @Input() public rightText: string;
}
