import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InputsService } from '@app/inputs/services/inputs.service';
import { AuthService, LDFlagsService } from '@dg/shared-services';
import { InputType } from '@app/shared/models/core-api.model';

/***
 * While we're migrating input pages out of the cshtml file we'll need to mimic the wrapped styles. Eventually these should be able to be moved into the input page and
 * we can get rid of this wrapper.
 *
 * We can also keep it if we feel like it's doing enough to keep it separate.
 */

@Component({
  selector: 'dgx-input-page-wrapper',
  templateUrl: './input-page-wrapper.component.html',
})
export class InputPageWrapper {
  private readonly IS_MOBILE = '1';

  public get isNativeApp(): boolean {
    return this.route.snapshot.queryParams?.mobile === this.IS_MOBILE;
  }

  public get canComment(): boolean {
    return this.authService.isLoggedIn;
  }

  public get inputContainerWidthClass(): string {
    let widthClass = this.ldFlagsService.showSearchLayoutRedesign
      ? 'grid__col-sm-8'
      : 'grid__col-sm-7';

    // credspark (type of assessment) will end up being 'grid__col_sm-11'
    // note if we need to know credpark at this level then we'll either have to get inputs at the wrapper
    // and pass it into the input-page component or this wrapper will need to be refactored out.

    return widthClass;
  }

  public get inputType(): string {
    return this.route.snapshot.data?.type;
  }

  public get inputId(): number {
    return this.route.snapshot.queryParams.d;
  }

  public get isHostedContent(): boolean {
    // Course can have the isHosted flag for SCORM but even though it's hosted we don't want to show
    // the hosted content preview UI.
    // TODO: Potential refactor for how to differentiate preview hosted content vs non preview hosted content
    if (this.inputType === 'course') {
      return false;
    }

    return !!this.route.snapshot.queryParams?.hosted;
  }

  public get isHMMEmbeddedContent(): boolean {
    return !!this.route.snapshot.queryParams?.HMMEmbeddedUrl;
  }

  public get HMMEmbeddedURL(): string {
    return atob(this.route.snapshot.queryParams?.HMMEmbeddedUrl);
  }

  public get displayType(): 'hosted' | 'hmm' | 'default' {
    if (this.isHostedContent) {
      return 'hosted';
    }

    if (this.isHMMEmbeddedContent) {
      return 'hmm';
    }

    return 'default';
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ldFlagsService: LDFlagsService,
    private inputsService: InputsService
  ) {}

  ngOnInit() {
    this.reportUserInputView();
  }

  // temporary solution while we work towards getting of InputType
  public castedInputType(): InputType {
    return this.inputType as InputType;
  }

  private reportUserInputView() {
    try {
      const userProfileKey = this.authService.isLoggedIn
        ? this.authService.authUser.viewerProfile.userProfileKey
        : null;
      this.inputsService
        .reportUserInputView(
          this.inputId,
          this.inputType,
          userProfileKey,
          this.buildProperties(),
          window.location.href
        )
        .subscribe();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('report user input view failed', e);
    }
  }

  private buildProperties() {
    const queryParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'contentSource',
      'contentSourceId',
      'mobile',
      'Location',
    ];

    const properties = {};

    queryParams.forEach((key) => {
      const value = this.route.snapshot.queryParams[key];
      if (value) {
        properties[key] = value;
      }
    });

    return properties;
  }
}
