import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { MentorableTagType } from '@app/mentoring/mentoring.enums';
import { UserInterest, UserProfileSummary } from '@app/user/user-api.model';

@Injectable({
  providedIn: 'root',
})
export class ConnectWithMentorService {
  constructor() {}

  /**
   * Returns a tag, formatted for the server request.
   *
   * @param tag - The tag to format for the server.
   */
  public formatTagForServer(tag: any): Partial<UserInterest> {
    return {
      tagId: tag.tagId || tag.id,
      name: tag.name,
    } as unknown as Partial<UserInterest>;
  }

  /**
   * Returns an array of tags, formatted for the server request.
   *
   * @param tags - The tags to format for the server.
   */
  public formatTagsForServer(
    tags: Partial<UserInterest>[]
  ): Partial<UserInterest>[] {
    return tags.map((tag) => this.formatTagForServer(tag));
  }

  /**
   * Return an array of mentorable tags that the mentee is focusing.
   *
   * @param mentor - The user offering mentoring.
   * @param mentee - The user seeking mentoring.
   */
  public getMentorableTags(
    mentor: UserProfileSummary,
    mentee: AuthUser,
    mentoringTagType: MentorableTagType
  ): UserInterest[] {
    switch (mentoringTagType) {
      // return matching skills that the mentor has agreed to mentor
      case MentorableTagType.ShowMatchingTags:
        const mentorTagNames = this.getMentoredTagNames(mentor);
        return mentee.viewerInterests.filter((tag) =>
          this.isFocusedAndMentored(tag, mentorTagNames)
        );
      // return the user's own focused skills
      case MentorableTagType.ShowFocusedTags:
        return mentee.viewerInterests.filter((tag) => !!tag.isFocused);
      // no tags for display, return an empty array
      default:
        return [];
    }
  }

  /**
   * Get the type of mentoring tags we're displaying.
   *
   * @param mentor - The mentor/user (aka UserProfileSummary, as returned from API) offering mentoring.
   * @param mentee - The mentee/user (aka AuthUser) seeking mentoring.
   */
  public getMentorableTagType(
    mentor: UserProfileSummary,
    mentee: AuthUser
  ): MentorableTagType {
    const menteeHasSkills = !!mentee.viewerInterests?.length;
    const mentorHasSkills = this.hasSkills(mentor);
    // no tags available for mentorship at all, display message about needing
    // to add skills
    if (!menteeHasSkills) {
      return MentorableTagType.HideTags;
    }
    // mentor at least has skills
    if (mentorHasSkills) {
      const mentorTagNames = this.getMentoredTagNames(mentor);
      if (
        mentee.viewerInterests.some((tag) =>
          this.isFocusedAndMentored(tag, mentorTagNames)
        )
      ) {
        // mentee has at least one matching skill, display matching skills
        return MentorableTagType.ShowMatchingTags;
      }
    }
    // mentee has some focus skills, display focus skills
    if (mentee.viewerInterests.some((tag) => !!tag.isFocused)) {
      return MentorableTagType.ShowFocusedTags;
    }
    // sanity check
    return MentorableTagType.HideTags;
  }

  /**
   * Get the number of focus skills and overall skills
   * for tracking purposes.
   *
   * @param mentor - The mentor.
   */
  public getMentorTagCounts(mentor: UserProfileSummary): {
    focusSkillsCount: number;
    skillsAddedCount: number;
  } {
    if (mentor.tagNames?.length) {
      return {
        // we don't have this information for this view
        focusSkillsCount: undefined,
        // but we do have this
        skillsAddedCount: mentor.tagNames.length,
      };
    }
    // we have no way of getting this data
    return {
      focusSkillsCount: undefined,
      skillsAddedCount: undefined,
    };
  }

  /**
   * Get the mentor tags as an array of names.
   *
   * @param mentor - The mentor.
   */
  private getMentoredTagNames(mentor: UserProfileSummary) {
    return mentor.mentoringTags ?? [];
  }

  /**
   * Whether a given mentor has skills or not.
   *
   * @param mentor - The mentor.
   */
  private hasSkills(mentor: UserProfileSummary): boolean {
    // check mentoringTags length if it's set
    if (mentor && mentor.mentoringTags) {
      return !!mentor.mentoringTags.length;
    }
    return !!mentor.tags?.split(',')?.length;
  }

  /**
   * Whether a given tag is both focused by the mentee and being mentored.
   *
   * @param tag - The tag to check.
   * @param mentorableTagNames - An array of the mentor's mentored tags as names.
   */
  private isFocusedAndMentored(
    tag: UserInterest,
    mentorableTagNames: string[]
  ): boolean {
    return !!tag.isFocused && mentorableTagNames.includes(tag.name);
  }
}
