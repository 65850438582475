import { Injectable } from '@angular/core';
import {
  LearningResourceType,
  ResourceType,
} from '@app/shared/models/core-api.model';
import { ColorService } from '@app/shared/services/color.service';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { CropperCoordinates } from '@app/uploader/image-cropper-modal/image-cropper-modal.model';
import {
  ImageSize,
  ImageSourceSet,
  ProxyImageLinkConfig,
  ProxyImageOptions,
  ResourceFormat,
} from './thumbnail.model';

/** A utility service for retrieving and formatting thumbnail images for various @see LearningResourceType resources */
/** TODO: For the future, consider implementing https://cloudinary.com/documentation/angular_integration (if compatible)? */
@Injectable({ providedIn: 'root' })
export class ThumbnailService {
  /** When the new image uploader is turned on, crop data can be used to create thumbnails */
  public readonly useNewImageUploader: boolean = true;
  private fallbackPatternTypes = [
    'article',
    'video',
    'course',
    'book',
    'episode',
    'task',
    'event',
    'assessment',
    'position',
    'post',
  ];

  constructor(
    private webEnvironmentService: WebEnvironmentService,
    private configurationService: ConfigurationService,
    private colorService: ColorService
  ) {}

  public shouldCenterImage(type: ResourceType): boolean {
    const centeredTypes: ResourceType[] = [
      'Book',
      'Episode',
      'User',
      'Group',
      'Tag',
    ];
    return centeredTypes.indexOf(type) > -1;
  }

  public getProxyImageLink({
    method = '/fetch',
    gifCorrection = '',
    width = '',
    height = '',
    crop = '',
    format = '',
    gravity = '',
    dpr = '1',
    src,
  }: ProxyImageLinkConfig): string {
    return `https://img.degreed.com/image${method}${gifCorrection}${width}${height}${crop}${gravity}${format},q_auto,dpr_${dpr}.0/${src}`;
  }

  /**
   * Cropped images use chained transformations in the following order:
   * 1) crop (c_crop) the image using the original image height/width and the saved crop coordinates (x/y)
   * 2) resize (c_fill) the image based on the resourceFormat being requested (see setImageSize)
   * Note - the resize is important, with out it the requested image would be the original sized image which can exceed a MB
   *
   * https://cloudinary.com/documentation/image_transformations#chained_transformations
   */
  public getCroppedProxyImageLink({
    method = '/fetch',
    gifCorrection = '',
    width = '',
    height = '',
    crop = '',
    format = '',
    dpr = '1',
    src,
    cropCoordinates,
    options,
  }: ProxyImageLinkConfig): string {
    // Note: c_fill was not used correctly in the previous url creation.
    // Since we are creating a cropped url here we want to show the image with the constraints we provide, not auto fill.
    // Reference: https://cloudinary.com/documentation/transformation_reference#c_fill
    return `https://img.degreed.com/image${method}${gifCorrection}/w_${cropCoordinates.width},h_${cropCoordinates.height}${crop}${width}${height}${format}${options},q_auto,dpr_${dpr}.0/${src}`;
  }

  public fetchProxyImageSrcset(options: ProxyImageOptions): ImageSourceSet {
    // Don't run SVGs through Cloudinary unless they are insecure (http).
    // SVG's are converted to a raster image by Cloudinary so we end up with a poorer quality image with larger file size.
    if (
      this.isSvgAsset(options.imageSrc) &&
      options.imageSrc.indexOf('http:') !== 0
    ) {
      return {
        retina: options.imageSrc,
        orig: options.imageSrc,
        width: options.imageWidth,
        height: options.imageHeight,
      };
    }
    let crop = options.crop ? `,c_${options.crop}` : ',c_fill';
    if (options.crop === 'pad') {
      const backgroundPadColor: string = this.colorService.getColor(
        'ebony-a18',
        true
      );
      crop = `${crop},b_rgb:${backgroundPadColor}`;
    } else if (options.crop === 'crop') {
      crop = `${crop},x_${options.cropCoordinates.pointX},y_${options.cropCoordinates.pointY}`;
    }

    // Use g_faces:center by default as it will center the image if a face isn't found.
    let gravity = options.gravity ? `,g_${options.gravity}` : ',g_faces:center';
    if (options.crop === 'fit' || options.crop === 'crop') {
      // if crop is fit or using custom crop coordinates, remove gravity, otherwise errors ensue
      gravity = '';
    }

    const gifCorrection = '/pg_1', // turn off animated gifs for WCAG 2.2.2 (also see AB#23262)
      method = options.method ? `/${options.method}` : '/fetch',
      width = options.imageWidth ? `/w_${options.imageWidth}` : '',
      height = options.imageHeight
        ? `${width ? ',' : '/'}h_${options.imageHeight}`
        : '',
      format = options.format ? `,f_${options.format}` : ',f_auto',
      gFaces = options.gravity ? `,g_${options.gravity}` : ',g_faces:center',
      src = encodeURIComponent(options.imageSrc),
      imageOpts: ProxyImageLinkConfig = {
        gifCorrection,
        method,
        width,
        height,
        crop,
        format,
        gravity,
        src,
        options: options.options || '',
        cropCoordinates: options.cropCoordinates,
      };

    const retina =
      options.crop === 'crop'
        ? this.getCroppedProxyImageLink({
            ...{ dpr: '2' },
            ...imageOpts,
          })
        : this.getProxyImageLink({
            ...{ dpr: '2' },
            ...imageOpts,
          });
    const orig =
      options.crop === 'crop'
        ? this.getCroppedProxyImageLink({ ...{ dpr: '1' }, ...imageOpts })
        : this.getProxyImageLink({ ...{ dpr: '1' }, ...imageOpts });

    const imageProxy = {
      retina: retina,
      orig: orig,
      width: options.imageWidth,
      height: options.imageHeight,
    };
    return imageProxy;
  }

  public getFallbackPattern(
    resourceType: string = '',
    resourceId = 0 /* but actually required for pathways and pages */
  ): string | false {
    // pathways, directories and targets have different fallback patterns from other inputs
    if (resourceType === 'Pathway' || resourceType === 'Target') {
      const num = (resourceId + 773) % 24;
      return this.webEnvironmentService.getBlobUrl(
        `/content/img/app/skills/dg-${num}.svg`,
        true
      );
    } else if (
      this.fallbackPatternTypes.indexOf(resourceType.toLowerCase()) > -1
    ) {
      return this.webEnvironmentService.getBlobUrl(
        `/content/img/svg-patterns/${resourceType.toLowerCase()}-tile.svg`,
        true
      );
    }
    return false;
  }

  // this function is called setImageSize, but it really creates or gets
  // an image size
  public setImageSize(
    resourceFormat: ResourceFormat,
    resourceType: LearningResourceType = 'Article',
    cropperCoordinates?: CropperCoordinates
  ): ImageSize {
    const imageSize: ImageSize = { w: undefined, h: undefined };
    if (resourceFormat === 'tile') {
      switch (resourceType) {
        case 'Book':
        case 'Episode':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 96;
          break;
        default:
          imageSize.w = 348;
          imageSize.h = 144;
          break;
      }
    } else if (
      resourceFormat === 'card' ||
      resourceFormat === 'details-modal'
    ) {
      switch (resourceType) {
        case 'Book':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 134;
          break;
        case 'Episode':
          imageSize.w = 'auto'; // most podcasts are square already but we don't want to cut off CMS podcasts
          imageSize.h = 127;
          break;
        default:
          imageSize.w = 564;
          imageSize.h = 175;
          break;
      }
      // used for resource lists on Pages/Plans
    } else if (resourceFormat === 'list') {
      switch (resourceType) {
        case 'Book':
        case 'Episode':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 27;
          break;
        default:
          imageSize.w = 48;
          imageSize.h = 27;
          break;
      }
      // used for quick add and share modal
    } else if (resourceFormat === 'preview') {
      switch (resourceType) {
        case 'Book':
        case 'Episode':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 50;
          break;
        default:
          imageSize.w = 120;
          imageSize.h = 74;
          break;
      }
      // used for related content on "Client Provider" sidebar
    } else if (resourceFormat === 'related') {
      switch (resourceType) {
        case 'Book':
        case 'Episode':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 60;
          break;
        default:
          imageSize.w = 151;
          imageSize.h = 85;
          break;
      }
      // used for square thumbnails on pathway catalog search
    } else if (resourceFormat === 'thumbnail') {
      switch (resourceType) {
        default:
          imageSize.label = 'sm';
          imageSize.w = 32;
          imageSize.h = 32;
          break;
      }
      // used for directory tiles
    } else if (resourceFormat === 'directory') {
      switch (resourceType) {
        // for the plan card (dgTargetTile), which
        // is treated the same as the directoryTile.
        case 'Target':
          imageSize.w = 348;
          imageSize.h = 194;
          break;
      }
      // used for pathway tiles
    } else if (resourceFormat === 'pathway-tile') {
      switch (resourceType) {
        default:
          imageSize.w = 252;
          imageSize.h = 149;
          break;
      }
    } else if (resourceFormat === 'resource-card') {
      const imgHeight = 58;
      switch (resourceType) {
        case 'Book':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = imgHeight;
          break;
        default:
          imageSize.w = 103;
          imageSize.h = imgHeight;
          break;
      }
    } else if (resourceFormat === 'content-card') {
      switch (resourceType) {
        case 'Book':
          imageSize.w = 'auto'; // since books vary and look bad cropped
          imageSize.h = 96;
          break;
        default:
          imageSize.w = 228;
          imageSize.h = 128;
          break;
      }
    }
    return imageSize;
  }

  public isSvgAsset(url: string): boolean {
    // Cut off the query string params first, if any
    const qpos = url.indexOf('?');
    url = qpos === -1 ? url : url.substr(0, qpos);
    return url.substr(-4, 4).toLowerCase() === '.svg';
  }

  public isClientUploadedImg(url: string): boolean {
    return this.configurationService.cdnDomains.some(
      (domain) => url.indexOf(domain, 0) !== -1 || url.indexOf('~', 0) === 0
    );
  }
}
