<form
  [formGroup]="form"
  (ngSubmit)="onSubmit($event)"
  class="form-wrap"
  role="form"
>
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="i18n.Opportunities_ConnectWithModal_SubmitButton"
    [useDefaultForm]="false"
    headerClasses="h3 l_flexbar"
    headerWrapperClasses="guts-p-h-2"
    bodyClasses="guts-m-h-1 guts-m-t-1"
  >
    <!-- Header -->
    <ng-container class="modal-header">
      <dgx-profile-pic
        [profile]="mentor"
        class="block"
        size="tiny"
      ></dgx-profile-pic>
      <div class="guts-m-l-3-quart">{{ headerText }}</div>
    </ng-container>

    <!-- Content -->
    <ng-container class="modal-body">
      <p class="par par--small par--light guts-p-b-1-half">
        {{ i18n.Opportunities_ConnectWithModal_Description }}
      </p>
      <label>
        {{ tagsHeaderText }}
      </label>
      <p
        [innerHTML]="tagsDescriptionText"
        [ngClass]="!!availableTags.length ? 'guts-p-b-quart' : 'guts-p-b-0'"
        class="par par--small par--light"
      ></p>
      <!-- tag pill font size needs to be 12px, not 14px; -->
      <div *ngIf="!!availableTags.length">
        <dgx-tag-pill
          *ngFor="let tag of availableTags"
          [disableTooltip]="true"
          [tag]="tag"
          data-dgat="connect-with-mentor-modal-2ec"
        ></dgx-tag-pill>
      </div>
      <df-form-root
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="fields"
        class="block guts-p-t-half"
      >
      </df-form-root>
    </ng-container>
  </dgx-modal>
</form>
