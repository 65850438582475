import { Injectable, Inject } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TagsApi } from '../tag-api.model';
import { Observable, throwError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDClientSDK } from '@app/shared/services/ldclientsdk.token';

@Injectable({
  providedIn: 'root',
})
export class CheckpointService {
  constructor(
    private http: NgxHttpClient,
    @Inject(LDClientSDK) public ldClientSDK: LDClient
  ) {}

  public getAllCheckpoints(
    tagId: number,
    userKey: number
  ): Observable<TagsApi.Checkpoint[]> {
    const launchDarklyShowCheckpointsFlag = 'degreed-checkpoints';

    const showCheckpoints: boolean = this.ldClientSDK.variation(
      launchDarklyShowCheckpointsFlag,
      false
    );

    if (showCheckpoints && tagId && userKey) {
      return this.http
        .get<TagsApi.Checkpoint[]>('/tag/GetUserCheckpointsForTag', {
          params: { tagId, userKey },
        })
        .pipe(
          map((data) => {
            // sort data
            const completed = data.filter(
              (item) => item.level && item.completedDate
            );
            // Right now we do not record in progress status
            const toTake = data.filter(
              (item) => !item.completedDate && !item.level
            );

            return [...completed, ...toTake];
          }),
          catchError((error: Error) => throwError(error))
        );
    }
    return of([]);
  }
}
