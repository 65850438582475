import { FormControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function maxFifteenSkillsValidator(
  control: FormControl
): ValidationErrors | null {
  if (control.value?.length > 15) {
    return { maxSkillsReached: true };
  }
  return null;
}

export function durationHoursValidator(translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    const minZeroError = translate.instant('Core_InvalidMinValue', {
      min: 0,
    });
    return parseInt(control.value) < 0 ? { minZeroError } : null;
  };
}

export function durationMinuteValidator(translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    const parsedControl = parseInt(control.value);
    const parsedHours = parseInt(control.parent?.get('hours')?.value);

    if (parsedControl <= 0 && (!parsedHours || parsedHours < 0)) {
      return {
        minOneError: translate.instant('Core_InvalidMinValue', { min: 1 }),
      };
    } else if (parsedControl < 0) {
      return {
        minZeroError: translate.instant('Core_InvalidMinValue', { min: 0 }),
      };
    }
    return null;
  };
}

// TODO: add in more than 2 fields tests
export function atLeastOneRequiredValidator(fieldNames: string[]) {
  return (formGroup: FormControl): ValidationErrors | null => {
    const controls = fieldNames.map((name) => formGroup.get(name));

    const hasValue = controls?.some((control) => {
      return control.value !== '';
    });

    if (!hasValue) {
      return { atLeastOneRequired: true };
    }

    return null;
  };
}

/**
 * Validates if the url is a broken link
 */
export function hasBrokenUrlValidator(
  translate: TranslateService,
  hasBrokenUrl: boolean,
  originalUrl: string
) {
  return (control: FormControl): ValidationErrors | null => {
    const hasUserEdited = originalUrl !== control?.value;
    return control.value && hasBrokenUrl && !hasUserEdited
      ? {
          urlBrokenValidation: translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
        }
      : null;
  };
}

export function endDateValidator(translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    const startAfterEndError = translate.instant(
      'PositionFormCtrl_EndAfterStart'
    );
    const startDate = control.parent?.get('startDate')?.value;
    return startDate >= control.value ? { startAfterEndError } : null;
  };
}

export function maxValidator(max: number, translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    return parseInt(control.value) > max
      ? { maxError: translate.instant('Core_InvalidMaxValue', { max }) }
      : null;
  };
}

export function minValidator(min: number, translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    return parseInt(control.value) < min
      ? { minError: translate.instant('Core_InvalidMinValue', { min }) }
      : null;
  };
}
