import { Injectable } from '@angular/core';
import { ArticleMappingToAPI, ArticleModel } from '../article.model';
import { TrackerService, WebEnvironmentService } from '@dg/shared-services';
import { TagsApi } from '@app/tags/tag-api.model';

@Injectable({
  providedIn: 'root',
})
export class ArticleTrackerService {
  constructor(
    private tracker: TrackerService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public trackContentCatalogAdd(source: ArticleModel) {
    const {
      durationHours,
      durationMinutes,
      inputId,
      inputType,
      mediaLength,
      externalId,
      hostedContentDetails,
      imageUrl,
      title,
      tags,
      mediumConfidenceInferredSkills,
      highConfidenceInferredSkills,
    } = source;

    const eventDataToTrack = {
      action: 'Content Added to Catalog',
      properties: {
        contentId: inputId,
        contentType: inputType,
        dateModified: Date(),
        suggestedDuration: mediaLength,
        externalId: externalId,
        hostedType: hostedContentDetails?.hostType,
        imageUrl: imageUrl,
        title: title,
        skillsAdded: this.getDSSkillsAdded(
          tags,
          mediumConfidenceInferredSkills
        ),
        skillsRemoved: this.getDSSkillsRemoved(
          tags,
          highConfidenceInferredSkills
        ),
        tagsCount: tags.length,
        ...{
          updatedDuration: this.getUpdatedDuration(
            durationHours,
            durationMinutes
          ),
        },
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentCatalogUpdate(source: ArticleMappingToAPI) {
    const { inputType, mediaLength } = source;

    let eventDataToTrack = {
      action: 'CMS Item Updated',
      location: 'Manage Org Catalog',
      category: inputType,
      properties: {
        ...source,
        suggestedDuration: mediaLength ?? 'None',
      }
    };
    
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentSkillInferred(source: ArticleModel): void {
    const appLocation = this.webEnvironmentService.analyticsAppLocation;

    const eventDataToTrack = {
      action: `${appLocation} Content Skill Inferred`,
      properties: {
        skillsAdded: this.getDSSkillsAdded(
          source.tags,
          source.mediumConfidenceInferredSkills
        ),
        skillsRemoved: this.getDSSkillsRemoved(
          source.tags,
          source.highConfidenceInferredSkills
        ),
        tagsCount: source.tags.length,
        ...{
          updatedDuration: this.getUpdatedDuration(
            source.durationHours,
            source.durationMinutes
          ),
        },
      },
    };

    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentCompleted(source: ArticleMappingToAPI) {
    const {
      inputType,
      mediaLength,
      externalId,
      imageUrl,
      title,
      tags,
      sourceName,
      summary,
      entryUrl,
      inputId,
    } = source;

    const eventDataToTrack = {
      action: 'Content Completed',
      category: inputType,
      properties: {
        title: title,
        url: entryUrl,
        imageUrl: imageUrl,
        inputId: inputId,
        inputType: inputType,
        externalId: externalId,
        length: mediaLength,
        inputSource: sourceName,
        summary: summary,
        tags: tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  private getUpdatedDuration(durationMinutes, durationHours): number | string {
    const hours = durationHours?.toString();
    const minutes = durationMinutes?.toString();
    if (hours && minutes) {
      durationHours = parseInt(hours);
      durationMinutes = parseInt(minutes);
      return durationHours * 60 + durationMinutes;
    } else if (hours) {
      durationHours = parseInt(hours);
      return durationHours * 60;
    } else if (minutes) {
      durationMinutes = parseInt(minutes);
      return durationMinutes;
    }
    return 'None';
  }

  /**
   *
   * @param tags List of tags selected
   * @param mediumConfidence medium Confidence DS Skills
   * @returns list of skills that have been added from the DS Medium confidence skills
   */
  private getDSSkillsAdded(
    tags: TagsApi.Tag[],
    mediumConfidence: TagsApi.Tag[]
  ): string[] {
    const itemsAdded = !tags.length
      ? []
      : mediumConfidence.filter((item1) =>
          tags.some((item2) => item1.title === item2.title)
        );
    return itemsAdded.map((item) => item.name);
  }

  /**
   *
   * @param tags List of tags selected
   * @param highConfidence high Confidence DS Skills
   * @returns list of pre selected highConfidence skills that have been removed from skills
   */
  private getDSSkillsRemoved(
    tags: TagsApi.Tag[],
    highConfidence: TagsApi.Tag[]
  ): string[] {
    const itemsRemoved = tags.length
      ? highConfidence?.filter(
          (item1) => !tags.some((item2) => item1.title === item2.title)
        )
      : highConfidence.length
      ? highConfidence
      : [];
    return itemsRemoved.map((item) => item.name);
  }
}
