<dgx-modal-header
  [isLoading]="false"
  (dismiss)="handleClose()"
  headerClasses="h3"
>
</dgx-modal-header>

<div class="center-text">
  <div class="guts-p-b-1-half">
    <dgx-profile-pic [profile]="recommender" size="smallish"></dgx-profile-pic>
  </div>
  <h3 class="h3 guts-p-b-half">{{ recommender.userFullName }}</h3>
  <span
    class="par par--small color-ebony-a61"
    [innerHTML]="i18n.recommendationForm_HasRecommendedSkills"
  ></span>
</div>
<div class="grid__col-12" *ngIf="tags.length">
  <ul>
    <li *ngFor="let tag of tags" data-dgat="tag-recommendation-view-modal-9f4">
      <div class="l_flexbar border-top">
        <div class="l_flex-grow">
          <div class="l_flexbar guts-p-v-1 guts-p-h-0">
            <div class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1">
              <df-icon icon="tag" class="color-ebony-a61"></df-icon>
            </div>
            <div class="l_flex-grow">
              <h3 class="par par--small font-bold">
                <h3 class="par par--small font-bold">
                  {{ tag.reference.name }}
                </h3>
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div class="l_flex--align-right">
            <button
              class="btn btn-sm btn--square"
              [ngClass]="{
                'btn-passive': !tag.reference.isFollowing,
                'btn-primary': tag.reference.isFollowing
              }"
              (click)="toggleAdd(tag)"
              placement="auto top"
              [ngbTooltip]="
                tag.reference.isFollowing
                  ? i18n.dgTagRating_RemoveFromProfile
                  : i18n.dgTagRating_AddToProfile
              "
              tooltip-trigger="'focus mouseenter'"
              tooltip-popup-delay="1000"
              tooltip-placement="auto top"
              [attr.aria-label]="
                'recommendationForm_AddToProfileFormat'
                  | translate: { itemName: tag.reference.name }
              "
              data-dgat="tag-recommendation-view-modal-7a8"
            >
              <df-icon
                a11yText="i18n.Core_Follow"
                *ngIf="!tag.reference.isFollowing"
                icon="plus"
                size="small"
                class="center-text"
              ></df-icon>
              <df-icon
                a11yText="i18n.Core_UnFollow"
                *ngIf="tag.reference.isFollowing"
                icon="checkmark"
                size="small"
                class="center-text"
              ></df-icon>
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
<dgx-modal-footer
  [canCancel]="true"
  [cancelButtonText]="i18n.Core_Close"
  [useDefaultSubmitButton]="false"
  (dismiss)="handleClose()"
>
  <button
    [disabled]="!numSelected"
    [class.is_disabled]="!numSelected"
    (click)="goToProfile()"
    df-button
    dfButtonType="primary"
    data-dgat="tag-recommendation-view-modal-2d0"
  >
    {{ 'recommendationForm_ViewSkillsOnProfile' | translate }}
  </button>
</dgx-modal-footer>
