import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-cancel-tag-rating-request-modal',
  templateUrl: './cancel-tag-rating-request-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelTagRatingRequestModalComponent {
  @Input() public cancelButtonClasses? = 'guts-p-h-1-half';
  @Input() public cancelButtonText: string;
  @Input() public bodyText: string;
  @Input() public headerClasses? = 'guts-p-h-1 h3';
  @Input() public headerText: string;
  @Input() public submitButtonText: string;

  constructor(private activeModal: NgbActiveModal) {}

  /**
   * Dismiss modal
   */
  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  /**
   * Submit modal
   */
  public onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.activeModal.close();
  }
}
