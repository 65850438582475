import { ResourceImageService } from './../../../shared/services/resource-image/resource-image.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageUrlService {
  constructor(private resourceImageService: ResourceImageService) {}

  public getImageUrl(imageUrlData: any): string {
    const isResourceImage =
      this.resourceImageService.isResourceImage(imageUrlData);

    // When going to the more details page for a user uploaded video, we are not getting the legacyPictureUrl in the correct format
    // This removes the preface of the environment url (ex: https://staging.degreed.com/44300/) from the imageUrlData so that this.resourceImageService.parseImageUrl() can parse correctly
    if (isResourceImage) {
      imageUrlData = imageUrlData.substring(imageUrlData.indexOf('{'));
    }

    const imageUrl = isResourceImage
      ? this.resourceImageService.parseImageUrl(imageUrlData).legacyPictureUrl
      : imageUrlData?.pictureUrl
      ? imageUrlData.pictureUrl
      : imageUrlData;

    return imageUrl;
  }
}
