/**** DEPRECATED
 *
 * New Flags should use LDFlagsService (search-ldflags.ts)
 *  - When these flags are cleaned up, this file should be cleaned up
 */

import { Injectable } from '@angular/core';
import { LDFlagsService } from '@dg/shared-services';

/**
 * This service exposes global search related LD flags via a cache to avoid
 * excessive requests to the LD service
 *
 * @deprecated - Use LDFlagsService for new flags (search-ldflags.ts)
 */
@Injectable({ providedIn: 'root' })
export class SearchFlagsService {
  public static GLOBAL_SEARCH_TYPO_SUGGESTIONS = 'search-typo-handler';
  public static GLOBAL_SEARCH_PREFERRED_LANGUAGE_RESULTS =
    'search-preferred-language-results';
  public static PRIORITIZED_ENDORSED_CONTENT =
    'prioritize-endorsed-content-search';
  public static SEARCH_INITIATION_AUTOCOMPLETE =
    'search-initiation-autocomplete';
  public static SEARCH_LAYOUT_REDESIGN = 'search-layout-redesign';
  public static SEARCH_MARKETPLACE_PRICE_RANGE_FILTERS =
    'marketplace-price-filter';

  constructor(private ldFlagService: LDFlagsService) {}

  /** LD flag for showing global search typo suggestion */
  public get showTypoSuggestions() {
    return this.getFlag(
      SearchFlagsService.GLOBAL_SEARCH_TYPO_SUGGESTIONS,
      false
    );
  }

  /** LD flag for showing global search preferred language results */
  public get showSearchPreferredLanguageResults() {
    return this.getFlag(
      SearchFlagsService.GLOBAL_SEARCH_PREFERRED_LANGUAGE_RESULTS,
      false
    );
  }

  /** LD flag for showing prioritized endorsed content in global search */
  public get showPrioritizedEndorsedContent() {
    return this.getFlag(SearchFlagsService.PRIORITIZED_ENDORSED_CONTENT, false);
  }

  /** LD flag for showing search initiation autocomplete in global search bars */
  public get showSearchInitiationAutocomplete() {
    return this.getFlag(
      SearchFlagsService.SEARCH_INITIATION_AUTOCOMPLETE,
      false
    );
  }

  /** LD flag for showing search layout redesign in global search */
  public get showSearchLayoutRedesign() {
    return this.getFlag(SearchFlagsService.SEARCH_LAYOUT_REDESIGN, false);
  }

  /** LD flag for showing the price range filters in the search marketplace, while they are still in development */
  public get showMarketplacePriceRangeFilters() {
    return this.getFlag(
      SearchFlagsService.SEARCH_MARKETPLACE_PRICE_RANGE_FILTERS,
      false
    );
  }

  private getFlag(ldFlag: string, defaultValue: boolean): boolean {
    return this.ldFlagService.getFlag(ldFlag, defaultValue);
  }
}
