import { Injectable } from '@angular/core';
import { ExperienceMappingToAPI } from '../experience.model';
import { TrackerService } from '@dg/shared-services';

@Injectable()
export class ExperienceTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackContentCompleted(source: ExperienceMappingToAPI) {
    const {
      inputType,
      title,
      tags,
      description,
      startDate,
      endDate,
      inputSubTypePeerName,
      inputSubType,
      hoursPerWeek,
      isCurrent,
      level,
      organizationName,
    } = source;

    const eventDataToTrack = {
      action: 'Content Completed',
      category: inputType,
      properties: {
        title: title,
        startDate,
        endDate,
        tags,
        skillTagCount: tags?.length,
        description,
        inputSubTypePeerName,
        inputSubType,
        hoursPerWeek,
        isCurrent,
        level,
        organizationName,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
