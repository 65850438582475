<div>
  <dgx-modal-header (dismiss)="activeModal.dismiss()">
    {{ title }}
  </dgx-modal-header>
  <div class="guts-p-full-2">
    <ul *ngIf="groups">
      <li
        *ngFor="let group of groups; let last = last"
        class="guts-p-b-half border-bottom not-last"
        [ngClass]="{ 'guts-m-b-half': !last }"
        data-dgat="sharedWithGroupsModal-22a"
      >
        <div class="l_flexbar">
          <div class="guts-p-r-1">
            <div class="icon-spot bg-color-ebony-a08 center-item">
              <df-icon icon="person-triple" class="color-ebony-a61"></df-icon>
            </div>
          </div>
          <div class="l_flex-grow">
            <a
              [href]="group.groupUrl"
              data-dgat="sharedWithGroupsModal-19d"
              class="par par--small font-medium"
              >{{ group.groupName }}</a
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
