import { Injectable } from '@angular/core';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { catchAndSurfaceError } from '@app/shared/utils';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
} from 'rxjs';

@Injectable()
export class UserGroupListService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Load recommendations of groups and users for pathway primary contact or catalog content owner
   *
   * @param searchTerm
   * @returns
   */
  public loadGroupsUsersRecommendees: TypeaheadSearchFunction<
    string,
    AnyRecommendee
  > = (searchTerm: Observable<string>): Observable<readonly AnyRecommendee[]> =>
    searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((searchTerm) => searchTerm.length >= 2),
      switchMap((searchTerm: string) =>
        this.getUserAndGroupSuggestions(searchTerm)
      ),
      catchError(() => of([] as AnyRecommendee[]))
    );

  /**
   * Get a user or group based on the userProfileKey or primaryContactResourceId
   */
  public getUserDetailsByKey(userKey: number): Observable<AnyRecommendee> {
    return (
      this.http
        // NOTE: This endpoint can be used to fetch a group in addition to a user
        // in the context of fetching a content/input's owner/primary contact
        // TODO: Update the name of this endpoint and this method
        .get<UserSearchItem>('/users/getUserIgnorePrivacy', {
          params: {
            userKey,
          },
        })
        .pipe(
          map(
            (item: UserSearchItem) =>
              ({
                userProfileKey: item.userProfileKey,
                resourceId: item.userProfileKey,
                resourceType: item.resourceType,
                name: item.name,
                picture: item.picture,
                vanityUrl: item.vanityUrl,
                email: item.email,
                organizationId: item.organizationId,
                organizationEmail: item.organizationEmail,
              } as AnyRecommendee)
          ),
          catchAndSurfaceError(
            this.translate.instant('Core_GeneralErrorMessage')
          )
        )
    );
  }

  /**
   * Get a list of possible pathway primary contacts OR possible content owners from a search term
   * @param searchTerm
   * @returns
   */
  private getUserAndGroupSuggestions(
    searchTerm: string
  ): Observable<AnyRecommendee[]> {
    return this.http
      .get<AnyRecommendee[]>('/search/findUserGroupResources', {
        params: {
          term: searchTerm,
        },
      })
      .pipe(
        map((item: any) => {
          if (item.resourceType === 'User') {
            return {
              ...item,
              userProfileKey: item.resourceId,
            };
          }
          return item;
        })
      );
  }
}
