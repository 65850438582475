/** @deprecated We no longer send enum integer values over the wire. Use ../inputs-api.d.ts/InputType string union type instead */
export const enum InputType {
  None = -1,
  Article = 0,
  Video = 1,
  Book = 2,
  Event = 3,
  Course = 4,
  Assessment = 7,
  Position = 8,
  Episode = 9,
  Task = 10,
  Post = 11,
  /** @deprecated Please avoid using this value. A pathway is not an input and this will be phased out over time. */
  Pathway = 16,
}

/** @deprecated Use ../inputs-api.d.ts/InputType string union type instead */
export enum InputResourceType {
  None = 'None',
  Article = 'Article',
  Video = 'Video',
  Book = 'Book',
  Event = 'Event',
  Course = 'Course',
  Assessment = 'Assessment',
  Position = 'Position',
  Episode = 'Episode',
  Task = 'Task',
  Post = 'Post',
}

export const enum InputActions {
  completed = 'completed',
  uncompleted = 'uncompleted',
}
