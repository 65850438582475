import { DfPopoverService } from '@lib/fresco';
import {
  ContentChildren,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { SubscriberBaseDirective } from '../../components/subscriber-base/subscriber-base.directive';
import { isKey, Key } from '../../key';
import { SimpleItemViewModel } from '../../models/core-view.model';
import {
  ClosePopoverResult,
  PopoverService,
} from '../../services/popover.service';
import { ItemRoleDirective } from './item-role.directive';

/** Apply to any container element containing menu or list items to get a11y-compliant keyboard navigation and item selection behavior. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[role=menu],[role=listbox]',
})
export class ItemContainerRoleDirective extends SubscriberBaseDirective {
  @Input() public role: string;
  @Input() public closeOnSelect = true;
  @Input() public closeOnTab = true;
  @Output() public itemSelect = new EventEmitter<number>();

  @ContentChildren(ItemRoleDirective, { descendants: true })
  public items: QueryList<ItemRoleDirective>;

  private focusedIndex: number = -1;

  constructor(
    private dgxPopoverService: PopoverService,
    private popoverService: DfPopoverService
  ) {
    super();
  }
  public get isListBox() {
    return this.role === 'listbox';
  }

  @HostListener('keydown', ['$event'])
  public onKeyEvent(e: KeyboardEvent) {
    // ADA keyboard interaction for menus.
    // see https://www.w3.org/TR/wai-aria-practices/#menu
    if (
      this.items.length === 0 ||
      !isKey(e, Key.Down, Key.Up, Key.Tab, Key.Escape)
    ) {
      return;
    }
    if (isKey(e, Key.Down)) {
      e.preventDefault();
      this.focusNextItem(false);
    } else if (isKey(e, Key.Up)) {
      e.preventDefault();
      this.focusNextItem(true);
    } else if (isKey(e, Key.Tab) && this.closeOnTab) {
      this.closeMenuPopover({ preventRefocus: true });
    } else if (isKey(e, Key.Escape)) {
      // popover already closes from escape key, but we still need to reset the focusedIndex
      this.focusedIndex = -1;
    }
  }

  public onItemClick(item: SimpleItemViewModel) {
    this.itemSelect.emit(this.focusedIndex);
    this.onCommitSelection(item);
  }

  public closeMenuPopover(result?: ClosePopoverResult) {
    this.focusedIndex = -1;
    // TODO: remove this when the dgx-popover is removed
    this.dgxPopoverService.close(result);
    // close the fresco popovers
    this.popoverService.close(result);
  }

  private onCommitSelection(selectedItem: SimpleItemViewModel) {
    if (this.closeOnSelect && !this.isListBox) {
      // If a popover is open (ie, the menu or list case only) and we're here, we must be a menu in the popover. Close it with the current selection.
      // Listbox selection shouldn't close a popover
      this.closeMenuPopover({ itemViewModel: selectedItem });
    }
  }

  /**
   * Handles being able to use keyboard arrows on a menu to navigate items.
   *
   * @param decrement
   */
  private focusNextItem(decrement: boolean) {
    const items = this.items.toArray();
    const offset = decrement ? -1 : 1;

    const setFocus = () => {
      const item = items[this.focusedIndex];
      item.element.nativeElement.focus();
    };

    const moveFocus = () => {
      this.focusedIndex += offset;
      // wrap around if necessary
      if (this.focusedIndex < 0) {
        this.focusedIndex = items.length - 1;
      } else if (this.focusedIndex >= items.length) {
        this.focusedIndex = 0;
      }
      setFocus();
    };

    if (document.activeElement === items[0].element.nativeElement) {
      // handles scenarios where first item has been autofocused
      this.focusedIndex = 0;
    }

    if (this.focusedIndex === -1) {
      if (!decrement && items.length > 0) {
        this.focusedIndex = 0; // set initial focus
        setFocus();
      }
    } else {
      moveFocus();
    }
  }
}
