import { DF_SLIDE_FADE_IN_LEFT } from '@lib/fresco';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { A11yService } from '@app/shared/services/a11y.service';

@Component({
  selector: 'dgx-validate-field',
  templateUrl: './validate-field.component.html',
  animations: [DF_SLIDE_FADE_IN_LEFT],
})
export class ValidateFieldComponent implements OnInit, OnChanges {
  @Input() public message = '';
  @Input() public pad = false;
  @Input() public formSubmitting = false;

  public isMutedMessage = false;
  public mutedMessage = false;

  constructor(private a11yService: A11yService) {}

  public ngOnInit() {
    if (this.message) {
      this.a11yService.announcePolite(this.message);
    }
  }

  public ngOnChanges() {
    if (this.formSubmitting) {
      this.mutedMessage = false;
    }
  }

  public muteMessage() {
    this.mutedMessage = !this.mutedMessage;
    this.isMutedMessage = true;
    this.message = '';
  }
}
