<footer class="modal-footer">
  <button
    *ngIf="canCancel && dismiss.observers.length"
    [attr.data-dgat]="cancelButtonDgat"
    [ngClass]="cancelButtonClasses"
    (click)="onDismiss($event)"
    df-button
    dfButtonType="clear"
    df-button-small
  >
    {{ cancelButtonText }}
  </button>
  <div class="modal-footer__content">
    <ng-content></ng-content>
    <button
      *ngIf="useDefaultSubmitButton"
      [dfButtonType]="submitButtonType"
      [disabled]="isSubmitDisabled ? true : undefined"
      [attr.aria-disabled]="isSubmitDisabled"
      [attr.data-dgat]="submitButtonDgat"
      df-button
      type="submit"
    >
      <df-spinner-button [isSpinning]="isSubmitPending">
        {{ submitButtonText }}
      </df-spinner-button>
    </button>
  </div>
</footer>
