<div class="toggle-switch">
  <button
    #toggleSwitch
    type="button"
    class="toggle-switch-btn"
    [disabled]="disabled"
    (click)="toggleInput()"
    [class.is_on]="isChecked"
    [class.is_disabled]="ariaDisabled"
    role="checkbox"
    [attr.aria-checked]="isChecked"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.aria-label]="ariaLabel"
    [attr.aria-disabled]="ariaDisabled"
    data-dgat="toggle-switch-028"
  >
    <df-icon
      icon="checkmark"
      class="color-white guts-m-b-quart"
      size="small"
    ></df-icon>
    <span class="ib-v-middle"></span>
    <df-icon
      icon="cross"
      class="color-ebony-a45 guts-m-b-quart guts-m-l-quart"
      size="small"
    ></df-icon>
    <span class="toggle-switch-thumb"></span>
  </button>
</div>
