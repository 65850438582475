import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthUser } from '@app/account/account-api.model';
import { PathwayAuthoringService } from '@app/pathways/services/pathway-authoring.service';
import { PathwayAddContentService } from '@app/pathways/services/pathway-add-content.service';
import { PathwaySettingsService } from '@app/pathways/services/pathway-settings.service';
import {
  PathwayAddEditFormOptions,
  PathwaySummaryConfig,
  PathwayMetadata,
  PathwaySummaryModel,
  PathwayDetailsModel,
} from '@dg/pathways-rsm';
import {
  AnyRecommendee,
  RecommendeeType,
} from '@app/recommendations/recommendations.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { camelCaseKeys, pascalCaseKeys } from '@app/shared/utils';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { InputImageUpload } from '@app/uploader/upload-section/adapters/input-image-upload.adapter';
import { UploaderService } from '@app/uploader/uploader.service';
import { UserService } from '@app/user/services/user.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { EventBus } from '@dg/shared-services';
import { ACTIONS, getVisibilityString } from '@app/pathways/rsm/utils';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { GroupIdentifier, GroupItem } from '@app/groups/group-api';
import {
  VisibilityItem,
  VisibilityOption,
} from '@app/shared/components/visibility/visibility.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';

@Component({
  selector: 'dgx-pathway-add-edit-form-modal',
  templateUrl: './pathway-add-edit-form-modal.component.html',
  styleUrls: ['./pathway-add-edit-form-modal.component.scss'],
  animations: [DF_COLLAPSE_EXPAND],
})

// TODO: Once we are ready to decommission the V1 this file needs to be tested and possibly update
// to be able to share with inside pathway and outside of pathways.
export class PathwayAddEditFormModalComponent implements OnInit {
  @Input() public options: PathwayAddEditFormOptions;
  @Input() public pathway?: PathwayDetailsModel;

  @ViewChild('descriptionTextArea') private descriptionTextArea: NgModel;
  @ViewChild('pathNameRef') private pathNameRef: ElementRef;

  public isSaving: boolean = false;
  public descriptionMessage: string = '';
  public isChannel: boolean = false;
  public isCloning: boolean = false;
  public isConfirmingDelete: boolean = false;
  public isEditing: boolean = false;
  public isLoading: boolean = true;
  public titleMessage: string = '';
  public pathwaySummary: PathwaySummaryConfig;
  public removedSelf: boolean = false;
  public showEndorsePathway: boolean = false;
  public preventAuthorRemovalKey: number;
  public userPermissions: {
    canAuthorPathways: boolean;
    canManagePathways?: boolean;
    canUpdateShareAuthorPermission?: boolean;
    disableOrgPathwayHeaderImage?: boolean;
    canUploadPathwayImage?: boolean;
  };
  public title = '';
  public description = '';
  public imageUrl = '';
  public displayImage = '';
  public id: number;
  public isSubmitPending = false;
  public headerImageDisabled: boolean;
  public durationDisplayDisabled: boolean;
  public isEndorsed: boolean;
  public tags: TagsApi.Tag[] = [];
  public shareAuthorPermission: boolean;
  public authors: UserSearchItem[];
  public titleHasInitialText = false;
  public isConsumerUser: boolean;

  public parsedMetadata: PathwayMetadata = {
    CredlyBadgeEligible: false,
    ExternalBadgeId: '',
  };
  public showPathwayBadgeTrigger = false;
  public uploadAdapter: InputImageUpload;
  // Visibility selection defaults to private
  public selectedVisibility: number = Visibility.private;
  // selected groups for visibility
  public groups: GroupItem[] = [];
  // current privacy level? isn't this just the selected?
  public privacyLevel = Visibility.private;
  // visibility options
  public visibilityOptions: VisibilityOption[] = [];
  // LD flag for pathway Ownership Notification
  public showPathwayOwnership =
    this.ldFlagsService.pathwayNotificationOwnership;
  // show visibility errors
  public showErrors = false;
  public primaryContact: AnyRecommendee = undefined;
  // show required primary contact error
  public showPrimaryContactErrors = false;
  public readonly i18n = this.translate.instant([
    'Core_Collaborator',
    'Core_Required',
    'pathwayAddEditModal_DescriptionMissing',
    'pathwayAddEditModal_DescriptionLength',
    'pathwayAddEditModal_VisibleToOrg',
    'pathwayAddEditModal_VisibleToOrgWarning',
    'pathwayAddEditModal_DoNotDisplayHeaderImage',
    'pathwayAddEditModal_DoNotDisplayHeaderImageNote',
    'pathwayAddEditModal_DoNotDisplayDuration',
    'pathwayAddEditModal_DoNotDisplayDurationNote',
    'pathwayAddEditModal_EndorsedByOrg',
    'pathwayAddEditModal_EndorsedByOrgNote',
    'pathwayAddEditModal_SavePathway',
    'pathwayAddEditModal_PathwayName',
    'pathwayAddEditModal_TitleofPathway',
    'pathwayAddEditModal_PathwayNameEg',
    'pathwayAddEditModal_Description',
    'pathwayAddEditModal_DescriptionDetail',
    'pathwayAddEditModal_EndorsedContentByOrgNote',
    'pathwayAddEditModal_Authors',
    'pathwayAddEditModal_PathwayPrimaryContact',
    'pathwayAddEditModal_PrimaryContactRequired',
    'PathwayPrivacyModal_SelectAGroupError',
    'pathwayAddEditModal_SearchPeopleGroupsPlaceHolder',
    'dgImageUpload_ImageTypesSize',
    'dgImageUpload_ImagePreview',
    'dgImageUpload_UploadImage',
    'dgImageUpload_DropImage',
    'Core_Or',
    'Core_SaveChanges',
    'pathwayAddEditModal_CloneOfPathwayFormat',
    'pathwayAddEditModal_BadgeEligibilityLabel',
    'pathwayAddEditModal_BadgeUuid',
  ]);
  private authUser: AuthUser;
  private disableLearnerSkillsRegistry: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private pathwayAddContentService: PathwayAddContentService,
    private pathwayAuthoringService: PathwayAuthoringService,
    private pathwaySettingsService: PathwaySettingsService,
    private translate: TranslateService,
    private uploadService: UploaderService,
    private userService: UserService,
    private windowLayoutService: WindowLayoutService,
    private router: Router,
    private route: ActivatedRoute,
    private eventBus: EventBus,
    private ldFlagsService: LDFlagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get addEditHeading(): string {
    const headingKey = this.isEditing
      ? 'pathwayAddEditModal_EditPathway'
      : 'pathwayAddEditModal_CreatePathway';
    return this.translate.instant(headingKey);
  }

  public get isSubmitDisabled(): boolean {
    return this.isLoading || this.isSaving || !this.validatePathwaySummary();
  }

  public get isNewPathway(): boolean {
    return !(this.options.pathId && this.options.pathId > 0);
  }

  public get isSkillRegistryEnabled(): boolean {
    return !this.disableLearnerSkillsRegistry;
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.disableLearnerSkillsRegistry =
      this.options.disableLearnerSkillsRegistry;
    this.showPathwayBadgeTrigger = this.options.showPathwayBadgeTrigger;
    this.isConsumerUser = this.options.isConsumerUser;
    // LD check to initialize the new visibility in the settings modal
    if (this.showPathwayOwnership) {
      this.initVisibility();
    }
    this.initializeFormFields();
  }

  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  public initializeFormFields() {
    if (this.isConsumerUser) {
      this.userPermissions = {
        canAuthorPathways: true,
        canUploadPathwayImage: true,
      };
    } else {
      const userOrgPermissions = this.authUser.defaultOrgInfo?.permissions;
      const userOrgSettings = this.authUser.defaultOrgInfo?.settings;

      this.isChannel = this.options && this.options.isChannel;

      this.showEndorsePathway =
        this.options &&
        !this.options.disableEndorsedOption &&
        userOrgPermissions.endorseContent;

      this.userPermissions = {
        canAuthorPathways: !!userOrgPermissions.authorPathways,
        canManagePathways: !!userOrgPermissions.managePathways,
        canUpdateShareAuthorPermission:
          (userOrgPermissions.managePathways ||
            userOrgPermissions.authorPathways) &&
          !this.isChannel,
        disableOrgPathwayHeaderImage:
          !!userOrgSettings.disablePathwayHeaderImage,
        canUploadPathwayImage: this.authService.userCanUploadPathwayImage,
      };
    }

    this.isEditing = !!this.options.pathId && !this.options.forCloning;
    this.uploadAdapter = new InputImageUpload(
      this.uploadService,
      'Pathway',
      this.options.pathId
    );

    return this.pathwaySettingsService
      .getPathwayAuthors(
        !this.options.forCloning && this.options.pathId
          ? this.options.pathId
          : null
      )
      .subscribe((result: UserSearchItem[]) => {
        this.authors = result;
        return this.loadPathway();
      });
  }

  /**
   * Remove the pathway primary contact and validate the form field
   * @param event
   */
  public onRemovePrimaryContact(event: AnyRecommendee): void {
    this.primaryContact = undefined;
    this.validatePathwayPrimaryContact();
  }

  /**
   * Add a pathway primary contact, only 1 primary contact, if a new primary contact is chosen it will replace the existing
   *
   * @param event
   */
  public onAddPrimaryContact(primaryContact: AnyRecommendee): void {
    this.primaryContact = primaryContact;
    this.validatePathwayPrimaryContact();
  }

  public onRemoveCollaborator(event: AnyRecommendee): void {
    const author = event as UserSearchItem;

    const idx = this.authors.indexOf(author);
    if (idx !== -1) {
      this.authors.splice(idx, 1);
    }

    this.removedSelf =
      author.userProfileKey === this.authUser?.viewerProfile.userProfileKey;
  }

  public onAddCollaborator(event: AnyRecommendee): void {
    const author = event as UserSearchItem;
    const exists = this.authors.some(
      (c) => c.userProfileKey === author.userProfileKey
    );

    if (!exists) {
      this.authors.push(author);
    }
  }

  /**
   * Load recommendations of group and users for pathway primary contact
   *
   * @param term
   * @returns
   */
  public loadGroupsUsersRecommendees: TypeaheadSearchFunction<
    string,
    AnyRecommendee
  > = (term: Observable<string>): Observable<readonly AnyRecommendee[]> =>
    term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      switchMap((term: string) =>
        this.pathwaySettingsService.getPrimaryContactSuggestions(term)
      ),
      catchError(() => of([] as AnyRecommendee[]))
    );
  public savePathwaySummary() {
    // Camel-case needed to handle creating pathways through the Degreed Button / Profile Pathway
    // TODO: Remove camel-case here once the profile pathways has been migrated and using camel-case keys
    const itemToAdd = this.options?.itemToAdd
      ? camelCaseKeys(this.options.itemToAdd)
      : {};
    // NOTE: currently description/title are disabling the submit, for group visibility we want to show the error
    // on submit instead of disabling. Future update the description/title errors so they are consistent
    // On submit validate pathway primary contact ownership
    this.validatePathwayPrimaryContact();

    if (
      !this.validatePathwaySummary() ||
      (this.showPathwayOwnership &&
        (this.showPrimaryContactErrors || !this.validateGroupVisibility()))
    ) {
      return;
    }
    this.isSaving = true;
    if (this.options.isChannel) {
      this.pathwaySummary.organizationId =
        this.authUser.defaultOrgInfo?.organizationId;
    }

    if (this.title) {
      // strip any extra spaces (see https://degreedjira.atlassian.net/browse/PD-43637)
      this.pathwaySummary.title = this.title.replace(/\s+/g, ' ').trim();
    }

    let endorsementAdded: boolean;
    if (this.pathwaySummary.isEndorsed !== this.isEndorsed) {
      endorsementAdded = !this.pathwaySummary.isEndorsed;
    }
    this.pathwaySummary.description = this.description;
    this.pathwaySummary.headerImageDisabled = this.headerImageDisabled;
    this.pathwaySummary.durationDisplayDisabled = this.durationDisplayDisabled;
    this.pathwaySummary.isEndorsed = this.isEndorsed;
    this.pathwaySummary.shareAuthorPermission = this.shareAuthorPermission;
    this.pathwaySummary.authors = this.authors;
    this.pathwaySummary.tags = camelCaseKeys(this.tags);
    if (this.showPathwayBadgeTrigger) {
      this.pathwaySummary.metadata = JSON.stringify(
        this.parsedMetadata || '{}'
      );
    }
    if (this.showPathwayOwnership) {
      this.pathwaySummary.visibility = this.selectedVisibility;
      this.pathwaySummary.visibilityGroupIds = this.groups;

      // will always only have one primary contact
      this.pathwaySummary.primaryContactResourceType = this.primaryContact
        .resourceType as RecommendeeType;
      this.pathwaySummary.primaryContactResourceId =
        this.primaryContact.resourceId;
    }

    this.pathwaySettingsService
      .savePathwaySummary(
        this.pathwaySummary as any,
        endorsementAdded,
        this.options.trackingArea
      )
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe({
        next: (data) => {
          setTimeout(() => {
            const pathId = data;
            // NOTE: coming from the settings modal in the pathway this is always null and does not get called.
            // When we decommission the v1 version this needs to be tested with the outside pathway edit/creations
            if (this.options.itemToAdd) {
              // add new item to newly-created pathway
              return this.pathwayAddContentService
                .addInputToBin(pathId, itemToAdd.inputType, itemToAdd.inputId)
                .subscribe(() => {
                  this.closeAndLoad(pathId);
                });
            } else {
              this.closeAndLoad(pathId);
            }
          }, 1000);
        },
      });
  }

  public handleToggle(isEndorsed) {
    this.isEndorsed = isEndorsed;
  }

  public handleTagsChange = (updatedTags: TagsApi.Tag[]) => {
    this.tags = updatedTags;
  };

  /**
   * Handle changes from the visibility selection
   *
   * @param event: VisibilityItem
   */
  public handleVisibilitySelection(event: VisibilityItem): void {
    // set the form visibility
    this.selectedVisibility = event.visibility;
    this.groups = [];
    this.showErrors = false;
  }

  /**
   * Handle group visibility selection changes
   *
   * @param event: GroupIdentifier
   */
  public handleGroupSelection(event: GroupIdentifier): void {
    const groupId = event.groupId;
    const groupName = event.name;
    this.groups.push({
      id: groupId,
      name: groupName,
      isRestricted: this.privacyLevel === Visibility.groups,
    });
    this.validateGroupVisibility();
  }

  /**
   * Handle group visibility removal changes
   *
   * @param event: GroupIdentifier
   */
  public handleGroupRemove(event: GroupIdentifier): void {
    const groupName = event.name;
    this.groups = this.groups.filter((visGroup) => groupName !== visGroup.name);
    this.validateGroupVisibility();
  }

  public validatePathwaySummary(): boolean {
    let valid = true;
    if (!this.validateTitle()) {
      valid = false;
    }
    if (!this.validateDescription()) {
      valid = false;
    }
    return valid;
  }

  public onImageParsed({ legacyPictureUrl }: ResourceImage) {
    if (legacyPictureUrl) {
      this.pathwaySummary.legacyPictureUrl = legacyPictureUrl;
    }
  }

  public onImageUploadSuccess({
    resourceImageId,
    pictureUrl,
    legacyPictureUrl,
  }) {
    if (pictureUrl) {
      this.imageUrl = pictureUrl;
      this.pathwaySummary.imageUrl = pictureUrl;
      this.pathwaySummary.legacyPictureUrl = legacyPictureUrl;
      this.pathwaySummary.resourceImageId = resourceImageId;
    }
  }

  public onDeleteImage() {
    this.imageUrl = undefined;
    this.pathwaySummary.imageUrl = undefined;
    this.pathwaySummary.legacyPictureUrl = undefined;
    this.pathwaySummary.resourceImageId = '';
  }

  public validateTitle(): boolean {
    this.titleMessage = '';
    if (this.title?.length > 0) {
      this.titleHasInitialText = true;
    }

    if (this.title?.trim().length === 0 && this.titleHasInitialText) {
      this.titleMessage = this.translate.instant(
        'pathwayAddEditModal_NameRequired'
      );
      return false;
    } else if (!this.title) {
      // Don't show initial title warning on first load if empty as it already denotes field is required
      return false;
    }
    if (this.title.length > 255) {
      this.titleMessage = this.translate.instant(
        'pathwayAddEditModal_NameLength'
      );
      return false;
    }
    return true;
  }

  public validateDescription(): boolean {
    this.descriptionMessage = '';
    if (this.description?.length > 2000) {
      this.descriptionMessage = this.i18n.pathwayAddEditModal_DescriptionLength;
      return false;
    } else if (
      this.description?.trim().length === 0 &&
      this.descriptionTextArea.touched
    ) {
      this.descriptionMessage =
        this.i18n.pathwayAddEditModal_DescriptionMissing;
    }
    return true;
  }

  public credlyBadgeEligibleToggled() {
    if (!this.parsedMetadata.CredlyBadgeEligible) {
      // when credly badge eligible is toggled off, clear out the ExternalBadgeId
      this.parsedMetadata.ExternalBadgeId = null;
    }
  }

  /**
   * TODO: Check @type {UserSearchComponent} and @type {UserService}. Now that
   * the options we're passing to findNetworkMembers are available on the component,
   * can we just use the default search?
   * Key difference: the UserService maps its @type {UserProfileSummaries} to
   * simpler @type {UserSearchItems}. Also note that excludeSelf is false by default
   * on UserSearchComponent, but true by default on findNetWorkMembers.
   */
  public loadIncludingPrivateUsers: TypeaheadSearchFunction<
    string,
    UserSearchItem
  > = (term: Observable<string>): Observable<readonly UserSearchItem[]> =>
    term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      switchMap((term: string) =>
        this.userService.findNetworkMembers(term, false, 20, true)
      ),
      catchError(() => of([] as UserSearchItem[]))
    );

  /**
   *  Check group visibility selection.
   *
   * @returns false if should show error
   *          true if should not show error
   */
  private validateGroupVisibility(): boolean {
    if (
      this.selectedVisibility === Visibility.groups &&
      this.groups.length === 0
    ) {
      this.showErrors = true;
      return false;
    }
    this.showErrors = false;
    return true;
  }
  /**
   *  Check if primary contact is set.
   */
  private validatePathwayPrimaryContact(): void {
    this.showPrimaryContactErrors = !this.primaryContact; //this.primaryContact.length === 0;
  }

  private closeAndLoad = (pathId: PathwaySummaryConfig['id']) => {
    // this is all skipped in pathways
    // NOTE: coming from the settings modal in the pathway this is always null and does not get called.
    // When we decommission the v1 version this needs to be tested with the outside pathway edit/creations
    if (!this.options.preventRedirect) {
      return this.pathwaySettingsService
        .getPathSummary(pathId)
        .subscribe((response) => {
          this.activeModal.close(this.pathwaySummary);

          const authoringUrl = this.pathwayAuthoringService.getAuthoringUrl(
            response.publicUrl,
            this.options.isChannel ? 'channel' : null
          );
          if (this.windowLayoutService.isIframe) {
            this.windowRef.open(authoringUrl, '_blank');
          } else {
            this.windowRef.location.href = authoringUrl;
          }

          if (this.removedSelf) {
            // if the user removed themselves, we have no choice but to refresh permissions by reloading the page.
            this.windowRef.location.reload();
          }
        });
    }
    this.activeModal.close(this.pathwaySummary);

    if (this.removedSelf) {
      // NOTE: Need to update the permissions of the user reload the store on removal of self.
      // unfortunately just updating the pathway store does not update the url so routing here to
      // update edit mode.
      this.router.navigate([`pathway/${pathId}`], {
        relativeTo: this.route,
        queryParams: {
          editMode: undefined,
        },
        queryParamsHandling: 'merge',
      });
      // Also need to update the store permissions
      this.eventBus.announce(ACTIONS.refreshPathway());
    }
  };

  private loadPathway = () => {
    let pathId: number;

    if (!this.isNewPathway) {
      pathId = this.options.pathId;
    }

    if (this.isNewPathway) {
      const newPathway = {} as Partial<PathwaySummaryConfig>;
      newPathway.authors = this.authors;
      newPathway.isEndorsed = false;
      newPathway.headerImageDisabled =
        this.userPermissions.disableOrgPathwayHeaderImage;
      this.isEndorsed = false;
      this.pathwaySummary = newPathway as PathwaySummaryConfig;
      this.preventAuthorRemovalKey =
        this.authUser?.viewerProfile.userProfileKey;
      this.isLoading = false;
      setTimeout(() => this.pathNameRef?.nativeElement.focus(), 0);
    } else {
      return this.pathwaySettingsService
        .getPathSummary(pathId)
        .subscribe((response: PathwaySummaryModel) => {
          this.pathwaySummary = response as PathwaySummaryConfig;
          if (this.showPathwayBadgeTrigger) {
            this.parsedMetadata = this.parsePathwayMetadata(
              this.pathwaySummary.metadata
            );
          }
          this.pathwaySummary.authors = this.authors;
          this.title = this.pathwaySummary.title;
          this.description = this.pathwaySummary.description;
          this.imageUrl = this.pathwaySummary.imageUrl;
          this.displayImage = this.pathwaySummary.displayImage;
          this.id = this.pathwaySummary.id;
          this.headerImageDisabled = this.pathwaySummary.headerImageDisabled;
          this.durationDisplayDisabled =
            this.pathwaySummary.durationDisplayDisabled;
          this.isEndorsed = this.pathwaySummary.isEndorsed;
          this.tags = pascalCaseKeys(this.pathwaySummary.tags);
          this.shareAuthorPermission =
            this.pathwaySummary.shareAuthorPermission;
          // Get the pathway primary contact
          // NOTE: check for at primaryContactResourceId, first there will be pathways that do not have
          // contacts yet when opening edit mode, for that we want the primary contact to be empty
          if (
            !this.isNewPathway &&
            this.showPathwayOwnership &&
            this.pathwaySummary.primaryContactResourceId
          ) {
            if (this.pathwaySummary.primaryContactResourceType === 'User') {
              this.pathwaySettingsService
                .getUserDetailsByKey(
                  this.pathwaySummary.primaryContactResourceId
                )
                .subscribe((user) => {
                  this.primaryContact = user;
                });
            } else {
              this.primaryContact = {
                resourceType: this.pathwaySummary
                  .primaryContactResourceType as RecommendeeType,
                resourceId: this.pathwaySummary.primaryContactResourceId,
                name: this.pathwaySummary.primaryContactName,
              };
            }
          }
          if (!this.options.forCloning) {
            const isCollaborator =
              this.pathwaySummary.authorProfileKeys.indexOf(
                this.authUser?.viewerProfile.userProfileKey
              ) !== -1;

            if (
              this.pathwaySummary.organizationId ||
              this.pathwaySummary.organizationType === 'Channel'
            ) {
              this.userPermissions.canUpdateShareAuthorPermission =
                (this.userPermissions.canManagePathways ||
                  (isCollaborator && this.userPermissions.canAuthorPathways)) &&
                this.pathwaySummary.organizationType !== 'Channel';
            }
          } else {
            this.isCloning = true;

            const isEndorsedInitialValue = this.showEndorsePathway
              ? this.pathwaySummary.isEndorsed
              : false;

            const resetValuesForCloning = {
              authors: this.authors,
              clonedFromPathId: pathId,
              id: 0,
              isEndorsed: isEndorsedInitialValue,
              title: this.translate.instant(
                'pathwayAddEditModal_CloneOfPathwayFormat',
                { title: this.pathwaySummary.title }
              ),
              description: this.pathwaySummary.description,
              tags: this.pathwaySummary.tags,
              displayImage: this.pathwaySummary.displayImage,
              imageUrl: this.pathwaySummary.imageUrl,
              prevImageFilename: this.pathwaySummary.prevImageFilename,
            };

            this.pathwaySummary = resetValuesForCloning as any;
            this.title = this.pathwaySummary.title;
          }
          this.isLoading = false;
          return;
        });
    }
  };

  private parsePathwayMetadata(metadata: string): PathwayMetadata {
    const parsedMetadata: PathwayMetadata = JSON.parse(metadata || '{}');
    const isCloning = this.options.forCloning;

    parsedMetadata.CredlyBadgeEligible = isCloning
      ? false
      : parsedMetadata.CredlyBadgeEligible ?? false;
    parsedMetadata.ExternalBadgeId = isCloning
      ? null
      : parsedMetadata.ExternalBadgeId ?? null;

    return parsedMetadata;
  }

  /**
   * Initiate the visibility options
   */
  private initVisibility() {
    // initialize privacy level and groups from pathway
    // if there is no privacy level on the pathway yet default to private
    this.privacyLevel = !!this.pathway?.privacyLevel
      ? this.pathway.privacyLevel
      : Visibility.private;

    this.selectedVisibility = this.privacyLevel;

    // if there are not groups default to empty array
    this.groups = !!this.pathway?.groupIds ? [...this.pathway?.groupIds] : [];
    // Does the user belong to an organization

    const userBelongsToOrg =
      this.authUser.orgInfo && this.authUser.orgInfo.length > 0;

    // is there only one author
    const onlyAuthor: boolean = !!this.pathway
      ? this.pathway.authorProfileKeys &&
        this.pathway.authorProfileKeys.length === 1
      : true;

    const pathwayIsGroupOrgVisible: boolean =
      this.privacyLevel === Visibility.public ||
      this.privacyLevel === Visibility.groups;

    const managePathwayPermissions: boolean =
      this.authUser.defaultOrgInfo.permissions.authorPathways ||
      this.authUser.defaultOrgInfo.permissions.managePathways ||
      (!!this.pathway ? this.pathway.shareAuthorPermission : false);

    const showOrgPublishOptions: boolean =
      managePathwayPermissions || pathwayIsGroupOrgVisible;

    const limitOrgPublish =
      pathwayIsGroupOrgVisible && !managePathwayPermissions;
    // Get the visibility options
    this.visibilityOptions = this.getPrivacyOptions(
      userBelongsToOrg,
      this.privacyLevel,
      onlyAuthor,
      showOrgPublishOptions,
      limitOrgPublish
    );
  }

  /**
   *
   * Figure out what type of privacy levels to display in the form.
   * NOTE: need to update the wording on the org visibility with groups
   * @param belongsToOrg
   * @param privacyLevel
   * @param onlyAuthor
   * @param showOrgPublishOptions
   * @param limitOrgPublish
   * @returns
   */
  private getPrivacyOptions(
    belongsToOrg: boolean,
    privacyLevel: Visibility,
    onlyAuthor: boolean,
    showOrgPublishOptions: boolean,
    limitOrgPublish: boolean
  ) {
    const privacyOptions: VisibilityOption[] = [];
    if (belongsToOrg) {
      if (onlyAuthor) {
        privacyOptions.push({
          canExpand: false,
          item: {
            name: getVisibilityString(Visibility.private, this.translate),
            visibility: Visibility.private,
          },
        });
        if (!!this.pathway) {
          privacyOptions.push({
            canExpand: true,
            item: {
              name: getVisibilityString(Visibility.profile, this.translate),
              visibility: Visibility.profile,
            },
            urlOptions: {
              url: this.pathway.inviteUrl,
              trackingLabel: 'Pathway Shared',
              trackingCategory: this.pathway.id + '',
            },
          });
        }
      } else if (!limitOrgPublish || !showOrgPublishOptions) {
        // If the collaborator is unable to publish to org/group, but the pathway is already at that level, do not allow that user to change the privacy setting at all.
        privacyOptions.push(
          {
            canExpand: false,
            item: {
              name: getVisibilityString(Visibility.private, this.translate),
              visibility: Visibility.private,
            },
          },
          {
            canExpand: true,
            item: {
              name: getVisibilityString(Visibility.profile, this.translate),
              visibility: Visibility.profile,
            },
          }
        );
      }

      if (showOrgPublishOptions) {
        // If the collaborator is unable to publish to org/group, but the pathway is already at that level, show the option so they know it is selected, but not the other option.
        if (
          !limitOrgPublish ||
          (limitOrgPublish && privacyLevel === Visibility.groups)
        ) {
          privacyOptions.push({
            canExpand: true,
            item: {
              name: getVisibilityString(Visibility.groups, this.translate, ''),
              visibility: Visibility.groups,
            },
          });
        }
        if (
          !limitOrgPublish ||
          (limitOrgPublish && privacyLevel === Visibility.public)
        ) {
          privacyOptions.push({
            canExpand: true,
            item: {
              name: getVisibilityString(Visibility.public, this.translate),
              visibility: Visibility.public,
            },
            expandedGroupText: 'TargetForm_VisibilityBoostToGroup',
            customGroupTooltip: this.translate.instant(
              'dgPathwayPrivacy_VisibilityBoostToGroupTooltip'
            ),
          });
        }
      }
    } else {
      // IF the user does not belong to an organization only show these options
      privacyOptions.push(
        {
          canExpand: true,
          item: {
            name: getVisibilityString(Visibility.private, this.translate),
            visibility: Visibility.private,
          },
        },
        {
          canExpand: true,
          item: {
            name: getVisibilityString(Visibility.profile, this.translate),
            visibility: Visibility.profile,
          },
        }
      );
    }
    return privacyOptions;
  }
}
