<article
  class="
    content-card
    user-card
    box
    guts-p-full-1
    m-l_flex
    l_flex l_flex-middle l_flex-column l_flex-justify
    full-height
  "
  [attr.aria-label]="user.name"
  data-dgprop-item-clicked="User Tile"
>
  <div class="full-width l_flex l_flex-middle l_flex-column guts-p-t-2-half">
    <!-- Icons -->
    <div class="user-card__icons l_flexbar" *ngIf="showSearchLayoutRedesign">
      <!-- Active Learner icon -->
      <df-icon
        *ngIf="user.isEngaged"
        [ngbTooltip]="i18n.Core_ActiveLearner"
        icon="accomplishment-circle"
        size="medium"
        class="color-orange"
      ></df-icon>
      <!-- Mentor icon -->
      <df-icon
        *ngIf="user.isMentoring"
        [ngbTooltip]="i18n.Core_Mentor"
        icon="mentor"
        size="medium"
        class="color-blue"
      ></df-icon>
    </div>

    <!-- Profile picture -->
    <dgx-profile-pic
      class="guts-m-b-1-half"
      [profile]="user"
      [hideMentorIcon]="showSearchLayoutRedesign"
      (click)="imageClick($event)"
      size="large"
      data-dgprop-item-clicked="Picture"
    ></dgx-profile-pic>

    <div class="user-card__content center-text oh guts-p-h-1 guts-m-b-1">
      <!-- User name (deprecated) -->
      <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
      <h3 class="h3 ellipsis" *ngIf="!showSearchLayoutRedesign">
        <a
          #link
          class="user-card__title no-wrap"
          [href]="user.internalUrl"
          [attr.title]="user.name"
          data-dgat="user-card-aed"
          >{{ user.name }}</a
        >
      </h3>

      <!-- User name -->
      <h3 class="h3 user-card__clamp" *ngIf="showSearchLayoutRedesign">
        <a
          #link
          class="user-card__title"
          [href]="user.internalUrl"
          [attr.title]="user.name"
          data-dgat="user-card-aed"
          >{{ user.name }}</a
        >
      </h3>

      <!-- Job role (deprecated)-->
      <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
      <p
        class="par par--small par--light_a ellipsis"
        *ngIf="!showSearchLayoutRedesign"
      >
        {{ user.jobRole }}
      </p>

      <!-- Job Role • Location -->
      <p
        class="par par--small par--light_a ellipsis"
        *ngIf="showSearchLayoutRedesign && userAndLocation"
      >
        {{ userAndLocation }}
      </p>
    </div>

    <!-- Skills (deprecated) -->
    <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
    <button
      *ngIf="user.tags && !showSearchLayoutRedesign"
      class="badge badge-pill user-card__pill"
      (click)="showUserCardSkillsModal()"
      data-dgat="user-card-755"
    >
      {{ tagsCount }}
    </button>

    <!-- Skills & Signals -->
    <div class="l_flexbar guts-m-b-1-half" *ngIf="showSearchLayoutRedesign">
      <button
        *ngIf="user.tags"
        class="badge badge-pill user-card__pill"
        (click)="showUserCardSkillsModal()"
        data-dgat="user-card-755"
      >
        {{ tagsCount }}
      </button>
      <dgx-signal-popover
        *ngIf="signalCount > 0"
        [class.guts-m-l-half]="user.tags"
        [signalCount]="signalCount"
        [signalSummary]="user.tagSignals"
      ></dgx-signal-popover>
    </div>
  </div>

  <footer
    class="user-card__footer l_flex l_flex-middle l_flex-justify"
    *ngIf="showCardFooter"
  >
    <!-- Follow (deprecated) -->
    <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
    <button
      *ngIf="
        !isViewerFollowing &&
        !showMentorOptions &&
        !isMyTile &&
        !showSearchLayoutRedesign
      "
      class="action-link"
      (click)="followUser(user)"
      data-dgat="userTile-71c"
      [attr.aria-label]="getUserLabel(user.name)"
    >
      <div class="l_flexbar gap-full-half">
        <df-icon icon="plus"></df-icon>
        <div [attr.aria-label]="getUserLabel(user.name)">
          {{ i18n.Core_Follow }}
        </div>
      </div>
    </button>

    <!-- Follow -->
    <dgx-action-button
      *ngIf="showFollowButton && showSearchLayoutRedesign"
      (click)="followUser(user)"
      [autoToggleState]="false"
      [isActive]="false"
      icon="plus"
      dgatInput="userTile-71c"
      size="xs"
    >
      {{ i18n.Core_Follow }}
    </dgx-action-button>

    <!-- Following (deprecated) -->
    <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
    <button
      *ngIf="
        isViewerFollowing &&
        !showMentorOptions &&
        !isMyTile &&
        !showSearchLayoutRedesign
      "
      class="action-link is_active hover-btn"
      (click)="unfollowUser(user)"
      [attr.aria-label]="'Core_FollowingButtonLabel' | translate"
      data-dgat="userTile-558"
    >
      <span class="color-white l_flexbar">
        <df-icon icon="checkmark" size="medium" class="guts-m-r-half"></df-icon>
        <span>{{ i18n.Core_Following }}</span></span
      >
    </button>

    <!-- Following -->
    <dgx-action-button
      *ngIf="showUnfollowButton && showSearchLayoutRedesign"
      (click)="unfollowUser(user)"
      [autoToggleState]="false"
      [isActive]="true"
      icon="checkmark"
      dgatInput="userTile-558"
      size="xs"
    >
      {{ i18n.Core_Following }}
    </dgx-action-button>

    <!-- Request Mentor (deprecated) -->
    <!-- remove with showSearchLayoutRedesign / `search-layout-redesign` feature flag -->
    <dgx-connect-with-mentor-button
      *ngIf="showMentorOptions && !showSearchLayoutRedesign"
      [mentor]="user"
      buttonClasses="action-link"
    ></dgx-connect-with-mentor-button>

    <dgx-menu
      [appendToBody]="true"
      [menuConfig]="menuConfig"
      placement="bottom-right"
      class="user-card__footer__menu"
    >
      <button
        df-button
        df-button-square
        dfButtonType="clear"
        class="user-card__footer__menu-button"
        [attr.aria-label]="'Core_Options' | translate"
        type="button"
        data-dgat="user-card-ebd"
      >
        <df-icon icon="dots" class="color-ebony-a61"></df-icon>
      </button>
    </dgx-menu>
  </footer>
</article>
