<button
  type="button"
  *ngIf="searchFilter?.isCheckboxFilter; else popoverFilter"
  class="filter-btn btn guts-m-r-1 guts-p-h-1"
  [class.btn-passive]="!toggleCheckbox.checked"
  [class.btn-primary]="toggleCheckbox.checked"
  (click)="
    toggleSelectedFilterItem({
      filterItemId: searchFilter?.checkboxFilterId,
      filterName: searchFilter?.id
    });
    applyFilter()
  "
  [disabled]="!hasFilterItems"
  [class.is_disabled]="!hasFilterItems"
  [attr.aria-pressed]="toggleCheckbox.checked"
  data-dgat="search-filter-274"
>
  <input
    tabindex="-1"
    readonly="readonly"
    #toggleCheckbox
    type="checkbox"
    data-dgat="search-filter-2ae"
    [checked]="isFilterItemSelected(searchFilter?.checkboxFilterId)"
    [disabled]="!hasFilterItems"
    [class.is_disabled]="!hasFilterItems"
  />
  <span
    class="guts-p-l-half"
    [textContent]="
      searchFilter?.title
        | translate: { orgName: searchFilter?.organizationName }
    "
  >
  </span>
</button>

<ng-template #popoverFilter>
  <div
    class="rel"
    *ngIf="!(searchFilter?.isFilterCollection && !hasFilterItems)"
  >
    <button
      [dgxPopoverTrigger]="filterPopover"
      data-dgat="search-filter-575"
      type="button"
      class="filter-btn btn guts-m-r-1 guts-p-h-1"
      [class.btn-passive]="!isPopoverOpen && numSelectedFilterItems === 0"
      [class.btn-primary]="isPopoverOpen || numSelectedFilterItems > 0"
      [disabled]="!hasFilterItems"
      [class.is_disabled]="!hasFilterItems"
    >
      {{ searchFilter?.title | translate
      }}<span
        class="num-selected-filter-items guts-p-l-half"
        *ngIf="numSelectedFilterItems > 0"
        >{{ numSelectedFilterItems }}</span
      ><df-icon
        icon="chevron-down"
        size="small"
        class="guts-p-l-quart guts-m-l-half"
      ></df-icon>
    </button>
    <dgx-popover
      #filterPopover
      [isOpen]="isPopoverOpen"
      (isOpenChange)="popoverToggled($event, searchFilter)"
      [popoverArrowHidden]="true"
      [placementOffsetLeftPixels]="placementOffsetLeftPixels"
      placement="bottom-left"
    >
      <div class="overflow-scroll-gradient">
        <div
          #scroller
          class="
            guts-p-h-1-half guts-p-b-2
            overflow-scroll-gradient__scroller
            rel
            l_flex
          "
        >
          <div
            class="filter-items full-height guts-p-v-1 no-wrap"
            [ngClass]="
              searchFilter.isFilterCollection || searchFilter.isSegmentedFilter
                ? 'guts-p-h-1-half'
                : 'guts-p-h-1'
            "
            *ngFor="let filter of filtersWithItems"
            data-dgat="search-filter-fdd"
          >
            <div
              *ngIf="searchFilter.isFilterCollection"
              class="guts-p-v-half par--small font-semibold"
            >
              {{ 'Core_' + filter.title | translate }}
            </div>
            <ng-container
              *ngTemplateOutlet="
                filter.config.isSegmentedFilter ? segmentedFilter : filterItems;
                context: { $implicit: filter, items: filter.subitems }
              "
            ></ng-container>
          </div>
        </div>
      </div>

      <div
        class="filter-actions guts-p-full-2 border-top l_flex"
        [class.l_flex-column]="!searchFilter.isFilterCollection"
        [class.l_flex--align-right]="searchFilter.isFilterCollection"
      >
        <button
          df-button
          dfButtonType="primary"
          [class.full-width]="!searchFilter.isFilterCollection"
          (click)="applyFilter()"
          data-dgat="search-filter-e75"
        >
          {{ 'Core_Apply' | translate }}
        </button>
        <button
          df-button
          dfButtonType="clear"
          [class.guts-m-t-1]="!searchFilter.isFilterCollection"
          [class.guts-m-r-3]="searchFilter.isFilterCollection"
          (click)="resetSelectedFilterItems()"
          data-dgat="search-filter-6af"
          *ngIf="numSelectedFilterItems > 0"
        >
          {{ 'LearningSearch_Clear' | translate }}
          {{ numSelectedFilterItems }}
        </button>
      </div>
    </dgx-popover>
  </div>
</ng-template>

<!-- filter items -->
<ng-template #filterItems let-filter let-items="items" let-index="index">
  <dgx-search-filter-item
    [attr.aria-activedescendant]="'searchFilterItem' + index"
    [containerId]="'searchFilterItem' + index"
    [items]="items"
    [filter]="filter.title"
    [isSegmentedFilter]="filter.config.isSegmentedFilter"
    [selectedFilterItems]="selectedFilterItemIds"
    [careerPathingNames]="
      hasCareerPathing ? careerPathNamingService.careerPathNames : null
    "
    (filterItemClicked)="toggleSelectedFilterItem($event)"
    data-dgat="search-filter-bef"
  ></dgx-search-filter-item>
</ng-template>

<ng-template #segmentedFilter let-filter let-items="items">
  <!-- Using this manually because our isOpen state seemed to get lost
  in ToggleElement. -->
  <ng-container
    *ngFor="let segment of searchFilter.segments; let i = index"
    data-dgat="search-filter-7b7"
  >
    <button
      (click)="segment.isCollapsed = !segment.isCollapsed"
      [id]="'js-animate-toggle-permission-trigger-' + i"
      [attr.aria-controls]="'js-animate-toggle-permission-panel-' + i"
      [attr.aria-expanded]="!segment.isCollapsed"
      type="button"
      class="left-text l_flexbar l_flex-justify full-width guts-p-v-half"
      data-dgat="search-filter-37b"
    >
      <span class="block par--small font-semibold">{{ segment.title }}</span>
      <df-icon
        [icon]="!segment.isCollapsed ? 'chevron-up' : 'chevron-down'"
        size="medium"
        class="block color-ebony-a61 guts-m-l-auto"
      ></df-icon>
    </button>
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="segment.isCollapsed"
      [id]="'js-animate-toggle-permission-panel-' + i"
      [attr.aria-labelledby]="'js-animate-toggle-permission-trigger-' + i"
      aria-live="polite"
      class="rel oh"
    >
      <ng-container
        *ngTemplateOutlet="
          filterItems;
          context: {
            $implicit: filter,
            items: filterBySegment(items, i),
            index: i
          }
        "
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
