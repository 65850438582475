<div class="family-accounts">
  <h2
    id="a11yFamilyAccountsSettingsTableDescription"
    class="h3 family-accounts__heading"
  >
    {{ 'User_FamilyAccountsHeader' | translate }}
  </h2>
  <form>
    <fieldset
      *ngFor="
        let account of accounts;
        index as i;
        trackBy: trackByUserProfileKey
      "
      [@fadeIn]
      data-dgat="settings-family-accounts-f8b"
    >
      <legend class="a11y-hide">
        {{ 'User_FamilyLegendFormat' | translate: { memberIndex: i + 1 } }}
      </legend>
      <div class="grid grid--bleed family-accounts__row">
        <div class="grid__col-md-4 grid__col-12 family-accounts__cell">
          <label class="form__label" for="userName{{ i }}">{{
            'User_FamilyUsernameLabelFormat' | translate: { memberIndex: i + 1 }
          }}</label>
          <input
            type="text"
            readonly
            id="userName{{ i }}"
            name="userName{{ i }}"
            [ngModel]="account.userName"
            data-dgat="settings-family-accounts-1c2"
          />
        </div>
        <div
          class="grid__col-md-4 grid__col-12 family-accounts__cell"
          [ngClass]="{ 'password-margin': !account.hasPendingRole }"
        >
          <label
            *ngIf="account.hasPendingRole"
            class="form__label"
            for="defaultFamilyAccountPassword{{ i }}"
            >{{ 'User_FamilyPasswordLabel' | translate }}</label
          >
          <input
            *ngIf="!account.hasPendingRole"
            type="text"
            readonly
            id="defaultFamilyAccountPassword{{ i }}"
            name="defaultFamilyAccountPassword{{ i }}"
            [attr.value]="unknownPassword"
            data-dgat="settings-family-accounts-4df"
          />

          <df-input-decorator
            *ngIf="account.hasPendingRole"
            appendageType="default"
            style="max-width: 300px"
          >
            <input
              class="df-text-input"
              [attr.type]="
                currentRevealedField === 'defaultFamilyAccountPassword' + i
                  ? 'text'
                  : 'password'
              "
              readonly
              id="defaultFamilyAccountPassword{{ i }}"
              name="defaultFamilyAccountPassword{{ i }}"
              [attr.value]="
                currentRevealedField === 'defaultFamilyAccountPassword' + i
                  ? account.defaultFamilyAccountPassword
                  : '********'
              "
              data-dgat="settings-family-accounts-241"
            />
            <df-icon
              role="button"
              (click)="showPassword('defaultFamilyAccountPassword' + i)"
              df-append
              [icon]="
                currentRevealedField === 'defaultFamilyAccountPassword' + i
                  ? 'eye-closed'
                  : 'eye-open'
              "
            ></df-icon>
          </df-input-decorator>
        </div>
        <div class="grid__col-md-4 grid__col-12 family-accounts__cell">
          <label class="form__label" for="organizationName{{ i }}">{{
            'User_FamilyProgramLabel' | translate
          }}</label>
          <input
            type="text"
            readonly
            id="organizationName{{ i }}"
            name="organizationName{{ i }}"
            [ngModel]="account.organizationName"
            data-dgat="settings-family-accounts-f57"
          />
        </div>
      </div>
    </fieldset>
    <div *ngIf="isAdding">
      <dgx-skeleton
        type="table"
        [columns]="3"
        [rows]="1"
        layout="thick"
      ></dgx-skeleton>
    </div>
  </form>
</div>
