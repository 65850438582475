import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';

import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  AllRecommendees,
  AnyRecommendee,
} from '@app/recommendations/recommendations.model';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { UserService } from '@app/user/services/user.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { Router } from '@angular/router';
import { GroupInfoCore } from '@app/groups/group-api';

/**
 * Used wherever user searches are needed. Some options will be passed directly
 * to `findNetworkMembers`, but *only* if using the default search function.
 *
 * @example
 * ```
 * // A basic user search with event emitters.
 * <dgx-user-search
 *   [users]="collaborators"
 *   (selectRecipient)="onSelect($event)"
 *   (removeRecipient)="onRemove($event)"
 * ></dgx-user-search>
 *
 * // Pass in a custom search function. Usually unnecesssary.
 * <dgx-user-search
 *  [users]="collaborators"
 *  [search]="loadUsers"
 *  (selectRecipient)="onSelect($event)"
 *  (removeRecipient)="onRemove($event)"
 *  ></dgx-user-search>
 * ```
 */

// TODO: This could benefit from refactoring to use the SimpleItemViewModel so we can stop caring about whether we have users or groups
@Component({
  selector: 'dgx-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSearchComponent implements OnChanges {
  /** Use search field styling */
  @Input() public useSearchField: boolean = true;
  /**
   * **Used by the default search function.** The number of users to return with a search.
   * *Defaults to 20.*
   */
  @Input() public count: number = 20;
  /**
   * **Used by the default search function.** Whether to exclude the logged-in user from the
   * search results. *Defaults to false.*
   */
  @Input() public excludeSelf: boolean = false;
  /**
   * **Used by the default search function.** Whether to filter the currently-selected users
   * out of results. *Defaults to false.
   */
  @Input() public excludeSelectedRecipients: boolean = false;
  /**
   * **Used by the default search function.** Whether to include private users in the search
   * results. *Defaults to false.*
   */
  @Input() public includePrivateUsers: boolean = false;
  @Input() public usersOnly: boolean = false;
  @Input() public autofocus: boolean = false;

  /** Whether to populate and show the drop-down initially or when otherwise empty and focused. *Defaults to false.* */
  @Input() public showSuggestions?: boolean = false;
  /** i18n string for placeholder text for the search input. *Defaults to 'Search for people...'* */
  @Input() public placeholder? = 'TargetAuthorsForm_SearchPlaceHolder';
  /** Option to hide the selected user list in case this is handled by parent component. */
  @Input() public hideResults?: boolean = false;
  @Input() public preventUserRemovalKey?: number;
  /** Option to always allow removal */
  @Input() public allowRemoval?: boolean = false;
  /**
   * Optional: Provide a search function if you want to search something other
   * than the default user endpoint. Usually unnecessary.
   */
  @Input() public search?: TypeaheadSearchFunction<string, AnyRecommendee>;
  /** The currently-selected recipient users and groups, optionally displayed beneath the search input. */
  @Input() public recipients?: AnyRecommendee[] = [];
  /** Set to true when loading results; false otherwise */
  @Input() public isLoading: boolean = false;
  /** In case we need to disabled the search */
  @Input() public isDisabled: boolean = false;
  @Input() public ariaLabel?: string;
  /** Sets the ID on our input field, connecting it to an external label. */
  @Input() public labelKey = '';
  @Input() public userListTitle?: string;
  @Input() public isReadOnly?: boolean;

  /** The event emitted upon selection. */
  @Output()
  public selectRecipient: EventEmitter<AnyRecommendee> = new EventEmitter();
  /** The event emitted upon removal. */
  @Output()
  public removeRecipient: EventEmitter<AnyRecommendee> = new EventEmitter();

  /** The search ElementRef. Used for focus management. */
  @ViewChild('userSearch') public userSearch: ElementRef<HTMLElement>;

  public model = '';

  public isLoadingInternal: boolean = false;

  private stateChange = new Subject<string>();
  private suggestedInvitees: Observable<AnyRecommendee[]>;

  constructor(
    private router: Router,
    private userService: UserService,
    private recommendationsService: RecommendationsService
  ) {}

  /**
   * Search for users
   *
   * This function is intentionally defined as a function property to support ngb-typeahead
   * searching where `this` is undefined.
   *
   * @param term - The string term, as an Observable.
   */
  public onSearch: TypeaheadSearchFunction<string, AnyRecommendee> = (
    term: Observable<string>
  ): Observable<readonly AnyRecommendee[]> => {
    let coreSearch: Observable<readonly AnyRecommendee[]>;
    // Override our handling here with whatever search function was
    // passed in, if any.
    if (typeof this.search === 'function') {
      coreSearch = this.search(term);
    } else {
      // for plan only includePrivateUsers is true
      if (this.router.url && this.router.url.includes('plan')) {
        this.includePrivateUsers = true;
      }

      // Otherwise, get the results back from userService
      coreSearch = this.userService
        .search(term.pipe(tap(() => (this.isLoadingInternal = true))), {
          count: this.count,
          excludeSelf: this.excludeSelf,
          includePrivateUsers: this.includePrivateUsers,
        })
        .pipe(tap(() => (this.isLoadingInternal = false)));
    }

    // show suggested users when input focused or changed or the recipients should be modified, showSuggestions is true AND the
    // search term is empty.
    const suggest = merge(term, this.stateChange).pipe(
      filter(() => this.showSuggestions),
      distinctUntilChanged(),
      tap(() => (this.isLoadingInternal = true)), // start loading whenever we get focus or text input
      switchMap((searchTerm) => {
        // only provide suggestions for empty search term;
        // otherwise provide empty results to clear drop-down when no search results match or when single character present
        if (searchTerm) {
          return of([]);
        }
        if (!this.suggestedInvitees) {
          // get and cache the recent recommendees for this component's lifetime
          this.suggestedInvitees = this.recommendationsService
            .getRecentRecommendees(!this.usersOnly)
            .pipe(shareReplay(1));
        }
        return this.suggestedInvitees;
      })
    );

    // provide and display results in popup for both suggestions and actual search results
    return merge(suggest, coreSearch).pipe(
      tap(() => (this.isLoadingInternal = false)),
      map((invitees: AnyRecommendee[]) =>
        this.excludeSelectedRecipients
          ? this.filterSelectedFromRecipients(invitees)
          : invitees
      )
    );
  };

  public ngOnChanges({ recipients }: SimpleChanges) {
    if (recipients) {
      this.onStateChange(); // fire state change to supply suggestions if enabled
    }
  }

  public onSelect(event: NgbTypeaheadSelectItemEvent): void {
    // clear the input
    this.model = '';
    // fire the selection event
    this.selectRecipient.emit(event.item);
    // prevent the actual typeahead selection
    event.preventDefault();
  }

  /** Call to fire the stateChange subject to kick off loading/presenting suggestions if enabled
   * @param event The input event containing the current search text to write to the model, when
   * called from the template. Othewise, omit to use the current value from the model.
   */
  public onStateChange(event?: any) {
    if (event) {
      this.model = event.target.value;
    }
    this.stateChange.next(this.model);
  }

  public onRemove(event: AnyRecommendee): void {
    this.removeRecipient.emit(event);
  }

  public resultFormatter(item: AnyRecommendee): string {
    return item?.name || '';
  }

  /** Allow parent component to set focus */
  public focus(): void {
    this.userSearch?.nativeElement.focus();
  }

  public trackBy(_: number, item: AnyRecommendee): string {
    return item?.name;
  }

  public asUserSearchItem(item: AnyRecommendee): UserSearchItem {
    return item as UserSearchItem;
  }

  /** Scrolls ngbTypeahead container to active list item when using up and down arrows */
  // linter fix - use any (keyboardEvent doesn't have nextElementSibling)
  // it's likely this doesn't work anymore since we got rid of jquery!
  // TODO: Look into
  public scrollToElement(event: any) {
    const arrowUp = event.key === 'ArrowUp';
    const arrowDown = event.key === 'ArrowDown';
    if (arrowUp || arrowDown) {
      if (
        event.target.nextElementSibling &&
        event.target.nextElementSibling.nodeName === 'NGB-TYPEAHEAD-WINDOW'
      ) {
        let activeDropdownEle = arrowDown
          ? event.target.nextElementSibling.querySelector('.active')
              .nextElementSibling
          : event.target.nextElementSibling.querySelector('.active')
              .previousElementSibling;
        if (!activeDropdownEle) {
          const allDropdownElems =
            event.target.nextElementSibling.querySelectorAll('.dropdown-item');
          activeDropdownEle = arrowUp
            ? allDropdownElems[allDropdownElems.length - 1]
            : allDropdownElems[0];
        }
        if (
          !this.isElementInViewport(activeDropdownEle, event.target) &&
          arrowDown
        ) {
          activeDropdownEle.scrollIntoView(false);
        }
        if (
          !this.isElementInViewport(activeDropdownEle, event.target) &&
          arrowUp
        ) {
          activeDropdownEle.scrollIntoView(true);
        }
      }
    }
  }

  private isElementInViewport(el, inputElem) {
    const rect = el.getBoundingClientRect();
    const rectElem = inputElem.getBoundingClientRect();
    return (
      rect.top >= rectElem.bottom &&
      rect.left >= 0 &&
      rect.bottom <= rectElem.bottom + rect.offsetHeight &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private filterSelectedFromRecipients(potentialRecipients: AnyRecommendee[]) {
    return (potentialRecipients as AllRecommendees[]).filter((invitee) => {
      const selectedInvitees = this.recipients as AllRecommendees[];

      return !(
        selectedInvitees.findIndex(
          ({ userProfileKey, groupId }) =>
            userProfileKey === invitee.userProfileKey &&
            groupId === invitee.groupId
        ) >= 0
      );
    }) as AnyRecommendee[];
  }
}
