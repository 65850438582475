import { Inject, Injectable } from '@angular/core';
import { LDClientSDK } from '@app/shared/services/ldclientsdk.token';
import { LDClient } from 'launchdarkly-js-client-sdk';

/**
 * This service exposes profile related LD flags via a cache to avoid
 * excessive requests to the LD service
 */
@Injectable({ providedIn: 'root' })
export class ProfileFlagsService {
  public static MULTI_PREF_CONTENT_LANGS =
    'multiple-preferred-content-languages';

  constructor(@Inject(LDClientSDK) public ldClientSDK: LDClient) {}

  /** LD flag for showing multiple additional content languages in user profile settings and profile global menu (dgx-profile-menu) */
  public get showAdditionalContentLanguages() {
    return this.getFlag(ProfileFlagsService.MULTI_PREF_CONTENT_LANGS, false);
  }

  private getFlag(ldFlag: string, defaultValue: boolean): boolean {
    // Get the LD flag via the client API if not already cached
    if (this[ldFlag] === undefined) {
      return (this[ldFlag] = this.ldClientSDK.variation(ldFlag, defaultValue));
    }
    // Else return the cached value
    return this[ldFlag];
  }
}
