<form [formGroup]="deleteForm" (ngSubmit)="onSubmit()" class="form-wrap">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="deleteForm.invalid"
    [submitButtonText]="confirmButtonText"
    [useDefaultForm]="false"
    submitButtonType="destructive"
    [modalHeaderTitle]="modalHeaderTitle"
  >
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <p
        class="guts-p-h-2 guts-p-b-2 h3 center-text"
        [ngClass]="descriptionClasses"
      >
        <span class="break">{{ title }}</span>
        <br *ngIf="description" />
        <span *ngIf="description" class="break">{{ description }}</span>
      </p>

      <div class="guts-p-h-5 guts-p-b-3">
        <label for="deleteConfirm" class="form-label">
          <span class="break">{{ deleteInstructions }}</span
          ><df-label-marker></df-label-marker>
        </label>
        <input
          id="deleteConfirm"
          name="deleteConfirm"
          type="text"
          dgxAutofocus
          autocomplete="off"
          formControlName="deleteConfirm"
          data-dgat="deleteModal-7ff"
        />
      </div>
    </ng-container>
  </dgx-modal>
</form>
