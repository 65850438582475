import { HttpErrorResponse } from '@angular/common/http';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
// include both the API feedback (for new items) and the updated entityimport { EventEmitter, TemplateRef, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CommentsApiService } from '@app/comments/comments-api.service';
import {
  CommentFeedItemModel,
  InputCreationFeedback,
  InputDetails,
  LearningInputModel,
} from '@app/inputs/inputs-api.model';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { AuthService } from '@app/shared/services/auth.service';
import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { MapperFactoryService } from '@app/user-content/user-input/services/mapper-factory.service';
import { RepositoryFactoryService } from '@app/user-content/user-input/services/repository-factory.service';
import {
  AllInputApiEntities,
  AnyCreationFeedback,
  AnyInputApiEntity,
  InputContext,
  RenderMode,
} from '@app/user-content/user-input/user-input.model';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { FormRenderAction } from '../user-input/form-renderer.model';
import { InputFormModel } from '../user-input/input-form.model';
import { InputNotificationService } from './input-notification.service';
import { InputTrackingService } from './input-tracking.service';

// TODO: Move these to a type defs file
export interface InputModalTemplateMap {
  // common
  urlBrokenValidation?: TemplateRef<any>;
  skills?: TemplateRef<any>;
  addToCatalogDupsHelp?: TemplateRef<any>;
  addToCatalogDupsHelpCatalog?: TemplateRef<any>;
  advancedExpander?: TemplateRef<any>;
  groups?: TemplateRef<any>;
  contentUploader?: TemplateRef<any>;
  creator?: TemplateRef<any>;
  spinner?: TemplateRef<any>;
  errorMessage?: TemplateRef<any>;
  // event
  // TODO: These select templates can be removed in favor of the SelectFieldComponent foreign field now
  eventInvolvement?: TemplateRef<any>;
  eventLength?: TemplateRef<any>;
  // assessments
  assessmentContentCatalogInitialView?: TemplateRef<any>;
  assessmentManageCredSpark?: TemplateRef<any>;
  assessmentQuestionsCorrect?: TemplateRef<any>;
  institutionSelection?: TemplateRef<any>;
  // Course
  countrySearch?: TemplateRef<any>;
  courseLevel?: TemplateRef<any>;
  courseGrade?: TemplateRef<any>;
  institutionSearch?: TemplateRef<any>;
  institutionResult?: TemplateRef<any>;
  courseTitleSearch?: TemplateRef<any>;
  countrySearchResult?: TemplateRef<any>;
  courseTitleResult?: TemplateRef<any>;
  providerSearch?: TemplateRef<any>;
  providerResult?: TemplateRef<any>;
  // article/video
  urlCustomHelp?: TemplateRef<any>;
  readonlyField?: TemplateRef<any>;
  imageSize?: TemplateRef<any>;
  videoRecordButton?: TemplateRef<any>;
  videoCompatibleAndDupsHelp?: TemplateRef<any>;
  // episode
  episodeInitialization?: TemplateRef<any>;
  episodeSelection?: TemplateRef<any>;
  // books
  bookTitleSuggest?: TemplateRef<any>;
  bookInfo?: TemplateRef<any>;
  horizontalDivider?: TemplateRef<any>;
}

export interface InputSubmissionResult {
  entity: AnyInputApiEntity;
  creationFeedback?: AnyCreationFeedback; // only valid for "add" submissions; undefined for "edit".
  /** keep track if the user changed any scraped data (title, description) */
  overrideScrapedData?: boolean;
}

export const enum LoadStatus {
  Empty,
  Loading,
  Loaded,
}

export interface FormUiState<T> {
  viewModel: T;
  fields: DfFormFieldConfig[];
}

/** Base class for all Input modal interaction services, which provides common modal lifecyle behaviors.
 * Note that a modal cannot be submitted until it is marked as loaded. Setting that status is the
 * responsibility of the derived classes.
 */
export abstract class InputsFacadeBase<
  TViewModel extends InputFormModel = InputFormModel,
  TApiPayload extends AnyInputApiEntity = AnyInputApiEntity
> extends SubscriptionManagerService {
  /* eslint-disable @typescript-eslint/member-ordering */
  /** Must be set by the modal after its templates are created */
  private viewModel$ = new BehaviorSubject<TViewModel>(undefined);
  protected fields$ = new BehaviorSubject<DfFormFieldConfig[]>([]);
  private _submissionStatus$ = new BehaviorSubject(SubmissionStatus.None);
  private _formLoadStatus$ = new BehaviorSubject(LoadStatus.Empty);
  protected duplicates: InputDetails[] = [];

  /** Save the scraped data for later comparison */
  public scrapedData;

  /** Must be set by the modal after its templates are created */
  protected templates: InputModalTemplateMap;

  /** Provides status monitoring for content and hosted content uploads for forms that support it */
  protected contentUploadStatus$ = new BehaviorSubject<SubmissionStatus>(
    SubmissionStatus.None
  );

  /** Provides notifications to the attached form that either the form view model, the the form UI configuration
   * or both have changed
   */
  public formUiState$: Observable<FormUiState<TViewModel>>;

  /** Provides notifications indicating the load status of the form data
   */
  public readonly formLoadStatus$: Observable<LoadStatus> =
    this._formLoadStatus$.asObservable();
  /** Provides notifications indicating the submission status of the form data
   */
  public readonly submissionStatus$: Observable<SubmissionStatus> =
    this._submissionStatus$.asObservable();

  public inputType: string;

  constructor(
    protected readonly inputContext: InputContext,
    protected readonly initialModel: TApiPayload,
    protected readonly contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    protected readonly builder: DfFormFieldBuilder,
    protected readonly authService: AuthService,
    protected readonly repositoryFactory: RepositoryFactoryService,
    protected readonly mapperFactory: MapperFactoryService,
    protected readonly translate: TranslateService,
    protected readonly tracker: TrackerService,
    protected readonly inputsService: InputsService,
    private readonly commentsApiService: CommentsApiService,
    private readonly orgInternalContentService: OrgInternalContentService,
    private readonly inputNotificationService: InputNotificationService,
    private readonly inputTrackingService: InputTrackingService,
    private readonly tipService: TipService
  ) {
    super();

    this.formUiState$ = combineLatest([this.viewModel$, this.fields$]).pipe(
      map(
        ([viewModel, fields]) =>
          ({ viewModel, fields } as FormUiState<TViewModel>)
      )
    );
  }

  protected initializePages(action?: FormRenderAction): void {}

  public get title(): string {
    const entity = this.viewModel as AllInputApiEntities;
    return entity.title || entity.name || '';
  }

  public get isEditing(): boolean {
    return this.inputContext.isEditing;
  }

  public get isCompleting(): boolean {
    return this.inputContext.renderMode === RenderMode.UserProfile;
  }

  public get isAuthoring(): boolean {
    return !this.initialModel?.inputId || !this.isCompleting;
  }

  /** A convenience accessor for determining if the {@link formLoadStatus} is {@link FormLoadStatus.Loaded} */
  protected get isFormLoaded() {
    return this.formLoadStatus === LoadStatus.Loaded;
  }

  /** Gets an observable that blocks submission of a validated form until completed. The default
   * implementation returns undefined which will cause the observable to emit a void value then
   * complete immediately. Derived classes can override to impose submission prerequisites, such as loading
   * additional initialization data, then asynchronously continue completion once conditions are met.
   */
  protected get beforeSubmit(): Observable<void> {
    // the difference between of(void 0) and EMPTY is that a void item is emitted before completing,
    // which allows piped side effects (i.e., our submit method) to run
    return of(void 0);
  }

  protected get orgId() {
    return this.inputContext.renderMode === RenderMode.Pathways
      ? !this.isEditing
        ? this.inputContext.organizationId
        : this.authService.authUser.defaultOrgId
      : this.authService.authUser.defaultOrgId;
  }

  protected get defaultOrgId() {
    return this.authService.authUser.defaultOrgId;
  }

  protected get canManageContent() {
    return !!this.authService.authUser.defaultOrgInfo?.permissions
      ?.manageContent;
  }

  /** Gets the latest view model */
  protected get viewModel(): TViewModel {
    return this.viewModel$.value;
  }

  /** Causes the {@link formUiState$} Observable to emit a new view model value */
  protected set viewModel(viewModel: TViewModel) {
    this.viewModel$.next(viewModel);
  }

  /** Gets the latest form load status. The load status could have unique implications on different
   * forms. For example, it may not be set to {@link FormLoadStatus.Loaded} until the user provides
   * an initial valid entry in a "starter" field, causing additional data to be side-loaded. The
   * commonality is that when Loaded, any dependent data fundamental to the Input to be added/edited
   * has been loaded and the entirety of the form is thus ready to accept user entry.
   */
  protected get formLoadStatus(): LoadStatus {
    return this._formLoadStatus$.value;
  }

  /** Causes the {@link loadStatus$} Observable to emit a new value */
  protected set formLoadStatus(status: LoadStatus) {
    this._formLoadStatus$.next(status);
  }

  /** The modal should call this for any keydown events received. Derived services should override
   * to perform any specialized key handling. By default no key handling is performed.
   */
  public onKeyDown(ev: KeyboardEvent) {}

  /** The modal must call this to kick off form initialization and provide any custom templates
   *  required to to populate
   * */
  public onModalInit(templates: InputModalTemplateMap) {
    this.templates = templates;
    this.viewModel = this.defaultViewModel as TViewModel;
    this.updateUIConfiguration();
    this.formLoadStatus = LoadStatus.Loading;
    if (
      this.isEditing ||
      /**
       * Opportunity-specific Experiences will always have a defined view model
       * *and* editing set to false. @see {ExperienceFacade.getEditViewModel}
       * for overridden method.
       */
      (this.isCompleting && !!this.initialModel)
    ) {
      this.getEditViewModel(this.initialModel as TApiPayload).subscribe(
        (viewModel) => {
          this.formLoadStatus = LoadStatus.Loaded;
          this.viewModel = viewModel;
          this.updateUIConfiguration();
        }
      );
    } else {
      this.formLoadStatus = LoadStatus.Loaded;
    }
  }

  /** Called when the form is submitted. Note that the form submit action is performed whether the
   * submit button is used as a Next button or a true Submit button. Override this to perform
   * custom behaviors like wizard-style navigation.
   */
  public onSubmit(): Observable<InputSubmissionResult> {
    if (this.isFormLoaded && this.viewModel.duplicateCount === 0) {
      this._submissionStatus$.next(SubmissionStatus.Pending);

      // Queue a submission, blocking until observable completes
      return this.beforeSubmit.pipe(
        switchMap(() => this.submit()),
        this.takeUntilDestroyed()
      );
    }
    return EMPTY;
  }

  /** Shows duplicate inputs already in the catalog */
  public viewDuplicates() {
    this.inputsService.viewDuplicates(
      this.duplicates,
      undefined,
      this.inputContext.pathwayId // pathway ID will be undefined in catalog context
    );
  }

  /** A helper method that sets the value of a custom field, marks it as dirty and, optionally, as touched. */
  public setCustomFieldValue(
    formControl: FormControl,
    value: any,
    touch = false
  ) {
    formControl.setValue(value);
    formControl.markAsDirty();
    if (touch) {
      formControl.markAsTouched();
    }
  }

  /**
   * Generates a new UI configuration based on rendering mode (Global-Add, Content-Catalog, Pathway)
   * and causes the {@link formUiState$} Observable to emit a new fields value.
   */
  public updateUIConfiguration(action?: FormRenderAction) {
    this.fields$.next(this.buildUIConfiguration(action));
  }

  /** Notifies any listeners that an input was modified or created */
  protected notifyInputModifiedListeners(contentType) {
    this.inputsService.notifyInputModified(contentType);
  }

  protected onContentFileChange(file: File) {
    this.viewModel.isSubmitButtonDisabled = true;
    this.contentUploadStatus$.next(
      file ? SubmissionStatus.Submitting : SubmissionStatus.None
    );
  }

  protected onContentUploadSuccess(formControl: FormControl, response: any) {
    this.contentUploadStatus$.next(SubmissionStatus.Succeeded);
  }

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
    this.contentUploadStatus$.next(SubmissionStatus.Failed);
  }

  /** Derived classes must implement to provide a partial view model representing the extended properties
   * required for a particular Input type modal. This data will be merged into the @{link defaultViewModel}
   * to create a fully initialized view model.
   */
  protected abstract get extendedDefaultViewModel(): Partial<TViewModel>;

  /**
   * Requests the Input entity for editing from the server by ID and maps it to a view model that is combined
   * with the existing (default) one.
   */
  protected getEditViewModel(
    source: Partial<TApiPayload> = {}
  ): Observable<TViewModel> {
    const useNewImageUploader = true;
    const mapper = this.getMapper();

    if (!source?.inputId) {
      throw new Error('inputId required when editing');
    }

    // Only when in edit mode we should run fetchOne
    return this.getRepository()
      .fetchOne(
        this.inputContext.renderMode === RenderMode.UserProfile
          ? source.userInputId
          : source.inputId,
        useNewImageUploader
      )
      .pipe(
        switchMap((model) =>
          of({ ...this.viewModel, ...mapper.toViewModel(model) })
        )
      );
  }

  // Check if course being added was a pre-existing course from the Global Add
  private isAddExisting(entity) {
    return (
      this.inputContext.renderMode === RenderMode.UserProfile &&
      !!entity.inputId
    );
  }

  /** Gets a view model representing the default form UI state
   */
  private get defaultViewModel(): Partial<TViewModel> {
    const expandAdvanced$ = new BehaviorSubject(false);
    const canManageContent =
      !!this.authService.authUser?.defaultOrgInfo?.permissions?.manageContent;

    // Initialize common view model properties
    const baseModel: InputFormModel = {
      duplicateCount: 0,
      isEditing: this.isEditing,
      isCompleting: this.isCompleting,
      isVisibleToOrg: true,
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      /** Shows animated results for contexts where animated status are presented to users. As a side effect hides form fields */
      shouldShowResults$: this.submissionStatus$.pipe(
        map(
          (status) =>
            // when editing while submitting we want to show form fields instead of the completion animation
            !this.isEditing &&
            this.isCompleting &&
            status >= SubmissionStatus.Submitting
        )
      ),
      // For whatever reason, this is coming out as NULL when it updates on the form, instead of true.
      shouldSpinSubmitButton$: this.submissionStatus$.pipe(
        map(
          (value) =>
            value === SubmissionStatus.Pending ||
            value === SubmissionStatus.Submitting
        )
      ),
      isSubmitButtonDisabled: false,
      contentUploadStatus$: this.contentUploadStatus$,
      submissionStatus$: this.submissionStatus$,
      shouldShowDelete:
        canManageContent &&
        this.isEditing &&
        this.inputContext.renderMode === RenderMode.ContentCatalog,
      shouldShowCatalogEditWarning:
        this.inputContext.renderMode === RenderMode.Pathways && this.isEditing,
      expandAdvanced$,
      toggleAdvanced: () => expandAdvanced$.next(!expandAdvanced$.value),
      shouldShowSubmitButton$: new BehaviorSubject(true),
      useAnimatedSubmit:
        (this.inputContext.renderMode === RenderMode.UserProfile ||
          this.inputContext.renderMode === RenderMode.Pathways) &&
        !this.inputContext.isEditing,
      canManageContent,
      canRestrictContent: !!this.authService.authUser?.canRestrictContent,
      canComment: !!this.authService.authUser?.canComment,
      topTags: this.authService.authUser?.viewerInterests,
      organizationId:
        this.inputContext.renderMode === RenderMode.Pathways &&
        !this.inputContext.organizationId
          ? null
          : this.orgId,
      isUserAuthored: false,
      // User-provided
      addToCatalog: this.inputContext.renderMode === RenderMode.ContentCatalog,
      comment: '',
      tags: [],
      groups: [],
      isPathway: this.inputContext.renderMode === RenderMode.Pathways,
    };
    return {
      ...baseModel, // build the base, then spread with extended; otherwise type checking is broken
      ...this.extendedDefaultViewModel, // include extended input-specific properties from derived classes
    };
  }

  /** Creates the API parameters result to send the the repository  */
  protected createResult(model: TViewModel): TApiPayload {
    const apiParams = this.getMapper().toApiParameters(model);
    return apiParams;
  }

  /** Builds the form's array of field configurations via fresco/formly */
  protected abstract buildUIConfiguration(
    action?: FormRenderAction
  ): DfFormFieldConfig[];

  /** Performs any side effects required following successful creation of an Input */
  protected performCreationSideEffects(
    entity: AllInputApiEntities,
    feedback?: InputCreationFeedback
  ) {
    if (
      this.inputContext.renderMode === RenderMode.UserProfile &&
      this.viewModel.comment
    ) {
      this.updateComment(
        this.inputContext.inputType,
        entity.inputId ?? feedback?.result.inputId,
        this.viewModel.comment,
        (entity as AllInputApiEntities).title ||
          (entity as AllInputApiEntities).name
      ).subscribe();
    }
    this.inputNotificationService.notifyInputCreated(
      this.inputContext.renderMode,
      entity
    );
    this.inputTrackingService.trackInputCreated(
      this.inputContext.renderMode,
      entity,
      this.inputContext.trackingArea
    );

    // should only happen when doing a completion?
    if (
      this.inputContext.renderMode === RenderMode.UserProfile &&
      !this.inputContext.isEditing &&
      this.tipService.onboardHistory.indexOf('firstinput') === -1
    ) {
      this.tipService.setOnboardHistory('firstinput');
    }
  }

  /** Performs any side effects required following failed creation of an Input */
  protected performCreationFailureSideEffects(entity: AllInputApiEntities) {
    this.inputNotificationService.notifyInputCreateFailed(
      this.inputContext.renderMode,
      entity
    );
  }

  /** Performs any side effects required following successful update of an edited Input */
  protected performUpdateSideEffects(entity: AllInputApiEntities) {
    if (
      this.inputContext.renderMode === RenderMode.UserProfile &&
      this.viewModel.comment
    ) {
      this.updateComment(
        this.inputContext.inputType,
        entity.inputId,
        this.viewModel.comment,
        (entity as AllInputApiEntities).title ||
          (entity as AllInputApiEntities).name
      ).subscribe();
    }

    let currentInputUrl: string;
    let isBrokenLinkUpdated: boolean;
    switch (this.viewModel.inputType) {
      case 'Article':
      case 'Episode':
      case 'Video':
        currentInputUrl = this.viewModel.mediaUrl;
        break;
      case 'Assessment':
        currentInputUrl = this.viewModel.url;
        break;
      case 'Course':
        currentInputUrl = this.viewModel.courseUrl;
        break;
      case 'Event':
        currentInputUrl = this.viewModel.eventUrl;
        break;
    }

    if (
      (this.initialModel as any).hasBrokenUrl &&
      this.initialModel.url !== currentInputUrl
    ) {
      isBrokenLinkUpdated = true;
    }

    this.inputNotificationService.notifyInputUpdated(
      this.inputContext.renderMode,
      entity,
      isBrokenLinkUpdated
    );
    this.inputTrackingService.trackInputUpdated(
      this.inputContext.renderMode,
      entity
    );
  }

  /** Performs any side effects required following failed update of an edited Input */
  protected performUpdateFailureSideEffects(
    entity: AllInputApiEntities,
    error?: HttpErrorResponse
  ) {
    this.inputNotificationService.notifyInputUpdateFailed(
      this.inputContext.renderMode,
      entity,
      error
    );
  }

  /** Performs any side effects required following successful deletion of an Input */
  protected performDeletionSideEffects(entity: AllInputApiEntities) {
    this.inputNotificationService.notifyInputDeleted(
      this.inputContext.renderMode,
      entity
    );
    this.inputTrackingService.trackInputDeleted(
      this.inputContext.renderMode,
      entity
    );
  }

  protected performDeletionFailureSideEffects(entity: AllInputApiEntities) {
    this.inputNotificationService.notifyInputDeleteFailed(
      this.inputContext.renderMode,
      entity
    );
  }

  protected updateComment(
    inputType: any,
    inputId: number,
    comment: string,
    inputTitle: string
  ): Observable<CommentFeedItemModel | void> {
    if (comment) {
      const resource = {
        resourceId:
          inputId /** Here we need to map the resourceId from the Resource interface  {@link comments.d.ts } to the old referenceId */,
        resourceType: inputType,
        title: inputTitle,
      };
      return this.commentsApiService.addComment(resource, comment);
    }
    return of(void 0);
  }

  /** Gets the appropriate viewmodel/entity mapper for the Input type and render mode  */
  protected getMapper() {
    return this.mapperFactory.getMapper<TViewModel, TApiPayload>(
      this.inputContext.inputType,
      this.inputContext.renderMode
    );
  }

  /** Gets the appropriate repository for the INput type and render mode */
  protected getRepository() {
    return this.repositoryFactory.getRepository<TApiPayload>(
      this.inputContext.inputType,
      this.inputContext.renderMode
    );
  }

  /* Fetches any inputs for the org which have the same url */
  protected fetchDuplicates(
    shouldAdd: boolean,
    orgId: number,
    url: string,
    inputIdentifier?: InputIdentifier
  ) {
    if (shouldAdd) {
      return this.inputsService
        .getCmsInputsByUrl(orgId, url, inputIdentifier)
        .pipe(
          tap((response: LearningInputModel) => {
            if (response.inputs) {
              this.duplicates = response.inputs;
              this.viewModel = {
                ...this.viewModel,
                duplicateCount: response.inputs.length,
              };
            }
          })
        );
    } else {
      this.duplicates = [];
      this.viewModel = {
        ...this.viewModel,
        duplicateCount: 0,
      };
      return of();
    }
  }

  protected getInputTypeFormats(): Observable<string[]> {
    return this.orgInternalContentService.getInputTypeFormats(
      this.inputContext.inputType
    );
  }

  /**
   * Submits new or modified Input data via the repository
   */
  private submit(): Observable<InputSubmissionResult> {
    const mapper = this.mapperFactory.getMapper<TViewModel, TApiPayload>(
      this.inputContext.inputType,
      this.inputContext.renderMode
    );
    const entity = mapper.toApiParameters(this.viewModel);

    const repo = this.getRepository();
    const action = !this.inputContext.isEditing
      ? this.isAddExisting(entity)
        ? repo.addExisting(entity)
        : repo.add(entity)
      : repo.update(entity);

    this._submissionStatus$.next(SubmissionStatus.Submitting);

    return action.pipe(
      tap((creationFeedback?: AnyCreationFeedback) => {
        if (this.isEditing) {
          this.performUpdateSideEffects(entity as AllInputApiEntities);
        } else {
          this.performCreationSideEffects(
            entity as AllInputApiEntities,
            creationFeedback as InputCreationFeedback
          );
        }
        // TODO: Side effects complete async so this is happening too early!
        this._submissionStatus$.next(SubmissionStatus.Succeeded);
        this.notifyInputModifiedListeners(this.inputContext.inputType);
      }),
      catchError((err) => {
        if (this.isEditing) {
          this.performUpdateFailureSideEffects(
            entity as AllInputApiEntities,
            err.innerError
          );
        } else {
          this.performCreationFailureSideEffects(entity as AllInputApiEntities);
        }
        this._submissionStatus$.next(SubmissionStatus.Failed);
        return EMPTY;
      }),
      // include both the API feedback (for new items) and the updated entity as the result
      map((creationFeedback: AnyCreationFeedback) => {
        // create the final entity from the mapped entity, and the feedback for created items only
        const mappedEntity = {
          ...entity,
          inputId: creationFeedback?.result.inputId ?? entity.inputId,
          userInputId:
            creationFeedback?.result.userInputId ?? entity.userInputId, // only valid for user input completions
          masteryPoints: creationFeedback?.result.masteryPoints, // only used by certain content types
        };
        return {
          creationFeedback,
          entity: {
            ...mappedEntity,
          },
          overrideScrapedData:
            this.scrapedData?.title !== mappedEntity.title ||
            this.scrapedData?.description !== mappedEntity.description,
        };
      })
    );
  }

  /** Called during edit when the delete is selected. */
  public delete(): Observable<void> {
    const mapper = this.mapperFactory.getMapper<TViewModel, TApiPayload>(
      this.inputContext.inputType,
      this.inputContext.renderMode
    );

    const entity = mapper.toApiParameters(this.viewModel);
    const params = {
      cascadeDelete: false,
      inputs: [entity],
      organizationId: this.orgId,
    };

    return this.getRepository()
      .remove(params)
      .pipe(
        tap(
          () => this.performDeletionSideEffects(entity as AllInputApiEntities),
          catchError(() => {
            this.performDeletionFailureSideEffects(
              entity as AllInputApiEntities
            );
            return EMPTY;
          })
        )
      );
  }
}
