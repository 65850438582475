<div
  class="image-layout rel"
  data-dgprop-item-clicked="Image"
  [ngClass]="getImageLayoutClass"
>
  <div
    *ngIf="resourceIsVideo && format !== 'list' && !showSearchLayoutRedesign"
    class="image-layout__play"
  >
    <df-icon
      class="color-ebony-a61 nudge-right"
      icon="play"
      [a11yText]="'Core_Play' | translate"
    ></df-icon>
  </div>

  <div class="rel image-layout__wrapper">
    <div
      class="image-layout__container m-l_flex l_flex l_flex-justify-center"
      [ngClass]="{
        'round bg-color-ebony-a08 image-layout__container--icon l_flex-middle':
          icon,
        'image-layout__container--pattern': fallbackPatternSrc
      }"
    >
      <!-- Main img or icon -->
      <ng-container *ngIf="!imageHasError; else imageErrors" class="rel">
        <df-icon
          *ngIf="icon"
          [icon]="icon"
          size="medium"
          class="color-ebony-a45"
        ></df-icon>
        <img
          *ngIf="resourceSrc"
          class="image-layout__resource"
          [alt]="altText || ''"
          [title]="altText || ''"
          role="img"
          [src]="resourceSrc.retina"
          [attr.srcset]="
            resourceSrc.orig + ' 1x, ' + resourceSrc.retina + ' 2x'
          "
          [ngStyle]="{ 'max-height': imageSize.h / 12 + 'rem' }"
          [class]="
            shouldPadCropping ? 'image-layout__resource--padded' : undefined
          "
          (error)="errorLoadingImage()"
        />
        <div *ngIf="providerLogo" class="logo-container">
          <img [src]="providerLogo" class="provider-logo" />
        </div>
      </ng-container>

      <!-- fallbacks -->
      <ng-template #imageErrors>
        <div
          *ngIf="
            showSearchLayoutRedesign && !fallbackLogoSrc;
            else fallbackpattern
          "
          class="
            bg-color-forest-light
            image-layout__pattern
            l_flex
            m-l_flex
            l_flex-middle l_flex-justify-center
          "
        >
          <df-icon
            [icon]="resourceType | resourceTypeIcon"
            class="color-forest-dark"
            [size]="fallbackIconSize"
          ></df-icon>
        </div>
        <img
          *ngIf="fallbackLogoSrc"
          class="image-layout__logo"
          alt=""
          [src]="fallbackLogoSrc"
        />
        <div *ngIf="providerLogo" class="logo-container">
          <img [src]="providerLogo" class="provider-logo" />
        </div>
      </ng-template>
    </div>

    <!-- Include ?brandlogo here to assist the Degreed Live folks in targetting this image for logo replacement (AB#20558) -->
    <img
      *ngIf="endorsedSrc"
      class="image-layout__endorsed"
      [src]="endorsedSrc + '?brandlogo'"
      [alt]="'Core_Endorsed' | translate"
      [ngbTooltip]="'Core_EndorsedTooltip' | translate"
    />
  </div>
</div>

<ng-template #fallbackpattern>
  <div
    *ngIf="fallbackPatternSrc"
    class="image-layout__pattern"
    [ngStyle]="{ 'background-image': 'url(' + fallbackPatternSrc + ')' }"
  ></div>
</ng-template>
