<div class="comment__container">
  <dgx-profile-pic
    [profile]="{ name: comment.name, picture: comment.profileImage }"
    size="tiny"
  ></dgx-profile-pic>
  <div class="comment__content">
    <div class="comment__details">
      <a
        [href]="'/profile/' + comment.userUrl + '/overview'"
        class="comment__name"
        data-dgat="comment-4c5"
        >{{ comment.name }}</a
      >
      <span class="comment__edited-flag" *ngIf="comment.edited">{{
        i18n.Pathways_Edited
      }}</span>
      <span>{{ comment.dateAdded }}</span>
    </div>
    <div #commentDiv class="comment__comment" [hidden]="isEditing"></div>
    <div *ngIf="isEditing">
      <dgx-comment-field
        [initialValue]="comment.comment"
        [showControls]="false"
        [groupId]="groupId"
        [groupPrivacy]="groupPrivacy"
        (submitComment)="updateComment()"
      ></dgx-comment-field>
      <div>
        <dgx-action-button
          class="guts-m-r-1"
          (click)="updateComment()"
          [autoToggleState]="false"
          [design]="'square'"
          [isActive]="true"
          dgatInput="comment-761"
          >{{ i18n.Core_SaveChanges }}</dgx-action-button
        >
        <dgx-action-button
          (click)="cancelEdit()"
          design="square"
          dgatInput="comment-a63"
          >{{ i18n.Core_Cancel }}</dgx-action-button
        >
      </div>
    </div>
    <!-- TODO: PD-50252 only show rely , like , if isBrowserExtension is false,
    Backend work required: as of today all backend response of /extension/comment do not provide an 
    accurate response therefore preventing mapping of the comment feed to update 
    the GUI on push/unshift -->
    <ng-container *ngIf="!isBrowserExtension">
      <div *ngIf="!isEditing && !isPreview" class="comment__actions">
        <button (click)="toggleLike()" data-dgat="comment-120">
          {{
            comment.favoritedByUser
              ? i18n.CommentsCtrl_Unlike
              : i18n.CommentsCtrl_Like
          }}
          <span *ngIf="comment.favoritedCount > 0">
            ({{ comment.favoritedCount }})</span
          >
        </button>
        <button
          *ngIf="comment.hasOwnProperty('replies')"
          (click)="toggleReply()"
          data-dgat="comment-698"
        >
          <span>{{ i18n.Core_Reply }}</span>
        </button>
        <button
          *ngIf="isViewerComment"
          (click)="editComment()"
          data-dgat="comment-568"
        >
          <span>{{ i18n.Core_Edit }}</span>
        </button>
        <dgx-menu
          [menuConfig]="menuConfig"
          [placementAdjustLeftRem]="-0.75"
          class="comment__menu"
          placement="bottom-left"
        >
          <button
            type="button"
            class="comment__menu-button"
            data-dgat="comment-f3d"
          >
            <df-icon icon="dots" class="color-ebony-a61"></df-icon>
          </button>
        </dgx-menu>
      </div>
    </ng-container>
    <dgx-comment-field
      *ngIf="isReplying"
      [isSubmitting]="isSubmitting"
      [groupId]="groupId"
      [groupPrivacy]="groupPrivacy"
      (submitComment)="submitReply()"
    ></dgx-comment-field>
  </div>
</div>
