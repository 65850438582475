import {
  SearchExecuted,
  SearchOrigin,
  SearchResultView,
} from '@app/search/services/search-tracker.service';
import { SearchState } from '../models/search.model';

export interface RelatedTotals {
  opportunitiesTotal: number;
  skillsTotal: number;
  peopleTotal: number;
  groupsTotal: number;
}

// ************************************************************************************************
// Search Analytics Events
// ************************************************************************************************

/**
 * Transform current search state into a search analytics event;
 * to be reported to the search tracker service
 */
export function searchViewChangedEvent(
  state: SearchState,
  { skillsTotal, peopleTotal, groupsTotal, opportunitiesTotal }: RelatedTotals
): SearchResultView {
  const {
    term,
    orgId,
    filters,
    pagination,
    isCatalogSearch,
    isMarketplaceCat,
  } = state;
  return {
    searchTerm: term,
    isExternal: !orgId,
    isMarketplace: !!isMarketplaceCat,
    filters: filters,
    origin: getSearchOrigin(isCatalogSearch, isMarketplaceCat),
    contentTotal: pagination.total,
    skillsTotal,
    peopleTotal,
    groupsTotal,
    opportunitiesTotal,
    pageNumber: pagination.currentPage,
  };
}

/**
 * Call to server to get search results has been executed/completed
 */
export function searchExecutedEvent(
  state: SearchState,
  { skillsTotal, peopleTotal, groupsTotal, opportunitiesTotal }: RelatedTotals
): SearchExecuted {
  const {
    term,
    orgId,
    filters,
    pagination,
    isCatalogSearch,
    suggestedTerm,
    isMarketplaceCat,
  } = state;

  return {
    searchTerm: term,
    isExternal: !orgId,
    isMarketplace: !!isMarketplaceCat,
    filters: filters,
    suggestedTerm: suggestedTerm,
    pageNumber: pagination.currentPage,
    origin: getSearchOrigin(isCatalogSearch, isMarketplaceCat),
    contentTotal: pagination.total,
    skillsTotal,
    peopleTotal,
    groupsTotal,
    opportunitiesTotal,
  };
}

export function loadMoreEvent(state: SearchState): SearchResultView {
  const { term, orgId, filters, isCatalogSearch, isMarketplaceCat } = state;
  return {
    searchTerm: term,
    isExternal: !orgId,
    isMarketplace: !!isMarketplaceCat,
    filters,
    origin: getSearchOrigin(isCatalogSearch, isMarketplaceCat),
  };
}

function getSearchOrigin(
  isCatalogSearch: boolean,
  isMarketplaceCat: boolean
): SearchOrigin {
  return isCatalogSearch
    ? SearchOrigin.search
    : isMarketplaceCat
    ? SearchOrigin.marketplace
    : null;
}
