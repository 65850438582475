import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  public TOKEN_KEY = 'degreed_bearer_access';

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem(this.TOKEN_KEY);

    // Bypass interceptor for non-extension requests
    if(window.location.href.indexOf('degreed-button/app') === -1) {
      return next.handle(request);
    }

    if (token && this.isBearerTokenUrl(request)) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    // TODO: camelCaseKeys all response event.body
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event;
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        // handle case where the cookie from web is not present
        // and we need to force a re-login
        if (
          err.status === 401 &&
          request.headers.get('Dg-Skip-Intercept') &&
          window.location.href.indexOf('/account/login') < 0
        ) {
          localStorage.setItem('forceEbbLogout', 'true');
        }
        return throwError(err);
      })
    );
  }

  // determine if url can have BearerToken attached
  public isBearerTokenUrl(request: HttpRequest<any>) {
    const fromExtApiRequest =
      request.url.indexOf('/api/extension') > -1 ||
      request.url.indexOf('/api/tag/') > -1;
    return fromExtApiRequest;
  }
}
