<!-- groups typeahead input -->
<fieldset class="form-inline">
  <div class="form-group">
    <input
      #groupSearchInput
      id="group-search-input"
      type="text"
      [(ngModel)]="model"
      [ngbTypeahead]="search"
      [resultTemplate]="groupsearchitem"
      [placeholder]="placeholderText"
      [editable]="false"
      [attr.aria-label]="placeholderText"
      (blur)="onBlur()"
      (selectItem)="selection($event)"
      class="group-search-input"
      data-dgat="group-search-input-838"
    />

    <!-- Not found -->
    <div
      class="dropdown dropdown-menu--borders pos-init open"
      *ngIf="isNotFound"
      [@dfCollapseExpand]
    >
      <div
        class="dropdown-menu typeahead-popup dropdown-menu__arrow guts-m-t-1"
        role="menu"
        a11y-focus-first="false"
      >
        <p class="guts-p-l-1 guts-p-v-1 color-ebony-a61 par--small font-medium">
          {{ groupNotFoundText }}
        </p>
      </div>
    </div>
  </div>
</fieldset>

<!-- template for search results -->
<ng-template #groupsearchitem let-group="result" let-term="term">
  <span class="listbox--option" (click)="onItemClick($event)">
    <ngb-highlight
      [result]="group.name"
      [term]="term"
      [highlightClass]="'font-semibold'"
    >
    </ngb-highlight>
  </span>
</ng-template>
