<!-- hasAltDisplay is true when displayed as a card, false when displayed as a tile -->

<!--
  Linter error
    [class.opportunity-card--alt]="hasAltDisplay"
    Property 'hasAltDisplay' does not exist on type 'OpportunityCardComponent'
-->
<article
  *ngIf="!isBrowsePreviewView; else opportunityListItem"
  class="hyphenate opportunity-card tile"
  data-dgprop-item-clicked="Opportunity Tile"
  data-dgprop-item-type="Opportunity"
>
  <section class="tile__wrapper l_flex l_flex-column guts-p-full-1">
    <header>
      <div class="l_flex l_flex-justify">
        <a
          [attr.aria-label]="cardModel.title"
          [href]="'/opportunities/' + cardModel.opportunityId"
          data-dgat="opportunityCard-df3"
          class="opportunity-card__primary-link block"
          target="{{ openInNewtab ? '_blank' : '_self' }}"
          (click)="onTitleClick($event)"
        >
          <h2
            class="h3 break clamp guts-m-b-half opportunity-card__title__update"
            data-dgprop-item-clicked="Title"
            [innerHTML]="cardModel.title | stripMarkup"
          ></h2>
        </a>
        <ng-container *ngTemplateOutlet="selectForBulkAction"></ng-container>
      </div>

      <div class="l_flex l_flex-middle l_flex-wrap">
        <div
          *ngFor="let meta of cardModel.meta"
          class="
            opportunity-card__meta
            par--light_a
            guts-p-b-1 guts-p-r-1
            l_flexbar
          "
          data-dgprop-item-clicked="Meta"
          data-dgat="opportunityCard-594"
        >
          <df-icon
            [icon]="meta.icon"
            class="guts-m-r-half"
            size="small"
          ></df-icon>
          <div
            [innerHTML]="meta.label | stripMarkup | ellipsis: metaLimit"
          ></div>
        </div>
        <button
          *ngIf="showMatchBadge"
          (click)="showSkillsDetailModal()"
          class="guts-p-b-1"
          data-dgat="opportunity-card-2bb"
        >
          <dgx-opportunity-match-badge
            [includeFullLabel]="true"
            [totalSkills]="cardModel.skillMatchData.total"
            [matchedSkills]="cardModel.skillMatchData.matched"
            data-dgprop-item-clicked="Match Badge"
          >
          </dgx-opportunity-match-badge>
        </button>
      </div>
    </header>
    <div
      class="opportunity-card__description"
      data-dgprop-item-clicked="Description"
      [innerHTML]="
        cardModel.description | stripMarkup | ellipsis: descriptionLimit
      "
    ></div>
    <footer
      class="opportunity-card__footer l_flexbar"
      data-dgat="opportunity-card-footer"
    >
      <div
        class="l_flex l_flex-grow l_flex-wrap l_flex-center-children guts-m-r-1"
      >
        <a
          *ngIf="!isUserSelected; else selectedActions"
          class="
            action-link
            bg-color-ebony-a08
            color-ebony
            font-semibold
            guts-m-r-half
            btn btn-sm
          "
          [href]="'/opportunities/' + cardModel.opportunityId"
          (click)="onTitleClick($event)"
          target="{{ openInNewtab ? '_blank' : '_self' }}"
          data-dgat="opportunity-card-e10"
        >
          {{ i18n.Opportunities_ViewOpportunity }}
        </a>
        <dgx-action-button
          *ngIf="canShare"
          [autoToggleState]="false"
          [isActive]="false"
          [isIconOnly]="true"
          [tooltip]="i18n.Core_Share"
          (click)="showShareModal()"
          design="none"
          icon="arrow-forward"
          size="md"
          data-dgprop-item-clicked="Share"
          class="opportunity-card__share-button"
          dgatInput="opportunity-card-34b"
        ></dgx-action-button>
      </div>
      <dgx-menu
        [appendToBody]="true"
        [menuConfig]="menuConfig"
        placement="bottom-right"
        [placementAdjustLeftRem]="1"
      >
        <button
          type="button"
          df-button
          df-button-square
          dfButtonType="clear"
          class="opportunity-card__menu-button"
          data-dgat="opportunity-card-817"
        >
          <df-icon
            [a11yText]="i18n.Core_MoreOptions"
            icon="dots"
            class="color-ebony-a61"
          ></df-icon>
        </button>
      </dgx-menu>
    </footer>
  </section>
</article>

<ng-template #opportunityListItem>
  <article
    #rla="routerLinkActive"
    routerLinkActive="opportunity-list-item--selected"
    class="hyphenate opportunity-list-item"
    data-dgprop-item-clicked="Opportunity List Item"
    data-dgprop-item-type="Opportunity"
    [class.opportunity-list-item--selected]="isViewing"
  >
    <section class="guts-p-full-2 rel">
      <h2 class="a11y-hide" [innerHTML]="cardModel.title | stripMarkup"></h2>
      <button
        *ngIf="showMatchBadge && cardModel.skillMatchData.total"
        (click)="showSkillsDetailModal()"
        class="guts-p-b-1"
        data-dgat="opportunity-card-2bb"
      >
        <dgx-opportunity-match-badge
          [includeFullLabel]="true"
          [totalSkills]="cardModel.skillMatchData.total"
          [matchedSkills]="cardModel.skillMatchData.matched"
          badgeSize="compact"
          data-dgprop-item-clicked="Match Badge"
        >
        </dgx-opportunity-match-badge>
      </button>
      <a
        [attr.aria-label]="cardModel.title"
        [routerLink]="'/opportunities/' + cardModel.opportunityId"
        data-dgat="opportunityCard-df3"
        class="opportunity-card__primary-link"
        (click)="onTitleClick($event)"
      >
        <h2
          class="h3 opportunity-card__title break clamp guts-m-b-half"
          data-dgprop-item-clicked="Title"
          [innerHTML]="cardModel.title | stripMarkup"
        ></h2>
      </a>
      <ng-container *ngTemplateOutlet="selectForBulkAction"></ng-container>
      <ul
        *ngIf="cardModel.meta"
        class="
          opportunity-list-item__meta
          l_flexbar
          m-l_flex
          font-medium
          par par--light_a par--small
        "
      >
        <li
          *ngFor="let meta of cardModel.meta"
          class="opportunity-list-item__meta-item"
          data-dgat="opportunity-card-c46"
        >
          <span [innerHTML]="meta.label | stripMarkup"></span>
        </li>
      </ul>
    </section>
  </article>
</ng-template>

<ng-template #selectedActions>
  <dgx-action-button
    [autoToggleState]="false"
    [icon]="opportunity.masteryPoints ? 'thumbs-up' : ''"
    [isActive]="false"
    (click)="
      opportunity.masteryPoints ? undefined : showAddExperienceModal($event)
    "
    size="sm"
    class="guts-m-r-half"
    dgatInput="opportunity-card-b7a"
    >{{
      opportunity.masteryPoints
        ? ('dgProfileCollectionItem_MasteryPointsFormat'
          | translate
            : {
                masteryPoints: opportunity.masteryPoints | dgxPoints
              })
        : ('Core_AddExperience' | translate)
    }}</dgx-action-button
  >
</ng-template>
<ng-template #selectForBulkAction>
  <div *ngIf="canSelect" class="opportunity-card__submeta par--light_a">
    <input
      (change)="toggleCardSelection()"
      class="block opportunity-card__submeta-checkbox"
      [class.opportunity-card__submeta-checkbox--show]="showCheckboxes"
      type="checkbox"
      data-dgat="opportunity-card-20c"
    />
  </div>
</ng-template>
