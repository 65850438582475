<!-- Modal Header -->
<dgx-modal-header (dismiss)="dismiss()">
  {{ headerText }}
</dgx-modal-header>

<!-- Modal Body -->
<div
  *ngIf="vm$ | async as vm; else resourcesLoading"
  class="guts-p-h-2 guts-p-b-2 guts-p-t-1"
>
  <!-- Rating buttons -->
  <dgx-tag-rating-button
    class="guts-m-t-1"
    *ngFor="let type of vm.availableRatingTypes"
    [type]="type"
    [tag]="tag"
    [tagRatingDetails]="vm.tagRatingDetails"
    data-dgat="tag-public-rating-modal-3db"
  ></dgx-tag-rating-button>
</div>

<!-- Resources loading -->
<ng-template #resourcesLoading>
  <div class="guts-p-full-2 guts-m-b-1">
    <dgx-skeleton type="ratingButton"></dgx-skeleton>
  </div>
</ng-template>
