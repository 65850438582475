import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';

export interface FlexEdCard {
  cardNumber: string;
  status: string;
}

export interface FlexEdDetails {
  availableBalance: number;
  card: FlexEdCard;
  firstName: string;
  lastName: string;
  status: string;
}

@Injectable({ providedIn: 'root' })
export class FlexedService {
  constructor(private http: NgxHttpClient, private authService: AuthService) {}

  public getCardDetails(userKey: number, doCache: boolean): Observable<any> {
    if (this.authService.authUser.defaultOrgInfo?.hasPex) {
      return this.http
        .get(`/organizations/pexdetails?id=${userKey}&v=ngx`)
        .pipe(catchError((val) => of(this.processErrorRequest(val))));
    } else {
      const response = {
        isPexUser: false,
        isPexOrg: false,
      };
      return of(response);
    }
  }

  public processErrorRequest(response) {
    if (response.status === 404 || response.status === 412) {
      // pex detail but no data, or user missing pex info
      response.isPexUser = false;
      response.isPexOrg = true;
      throw response;
    } else if (response.status === 406) {
      // org doesn't have pex
      response.isPexOrg = false;
      throw response;
    }
  }
}
