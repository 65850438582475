<form [formGroup]="form" class="form-wrap" (ngSubmit)="onShare()" role="form">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="i18n.Mentoring_ShareFeedback"
    [useDefaultForm]="false"
    [isSubmitDisabled]="!model.feedbackMessage"
    headerClasses="h3"
    headerWrapperClasses="guts-p-h-2 guts-p-v-1-half"
    bodyClasses="guts-p-h-2 guts-p-v-1"
  >
    <ng-container class="modal-header">
      {{ i18n.Mentoring_GiveFeedback }}
    </ng-container>

    <ng-container class="modal-body">
      <df-form-root
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="fields"
      >
      </df-form-root>
    </ng-container>
  </dgx-modal>
</form>
