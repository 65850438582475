import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, lastValueFrom, map } from 'rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import {
  InputsFacadeBaseV2,
  MediaMetadataStatus,
} from '@app/user-content/user-input-v2/services/inputs-facade-base';
import { AuthService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';

// misc
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import {
  ArticleApiInputEdit,
  ArticleFormDataModel,
  ArticleModel,
} from '../article.model';
import { FormGroup } from '@angular/forms';
import { ArticleMapperService } from './article-mapper.service';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { ArticleService } from './article.service';
import { PathwayNode, PathwayStep } from '@dg/pathways-rsm';

/**
 * Base Article service to define shared functionality, with the ability to overwrite if it is different. For example
 * only the content in the catalog should be checked for duplicates.
 * - only catalog can upload articles
 *
 */
@Injectable()
export abstract class ArticleFacadeBase extends InputsFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<ArticleModel>(undefined);

  /**
   * Provides notifications to the attached form that either the form view model, the the form UI configuration
   * or both have changed
   */
  constructor(
    public inputsService: InputsService,
    public authService: AuthService,
    public translate: TranslateService,
    public articleMapperService: ArticleMapperService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public articleService: ArticleService
  ) {
    // if we have other states we want to send out
    super(inputsService);
  }

  protected get orgId(): number {
    return this.authService.authUser.defaultOrgId;
  }

  /** Gets the latest view model */
  protected get viewModel(): ArticleModel {
    return this.viewModel$.value as ArticleModel;
  }

  protected set viewModel(viewModel: ArticleModel) {
    this.viewModel$.next(viewModel);
  }

  public async onSubmit(
    form: FormGroup,
    isUserMediaEntry: boolean = false
  ): Promise<void> {
    const formData = form.value;
    // Step 1 update the View model with the form data
    this.updateViewWithFormData(formData);
    // step 2 get API Params mapper
    const apiParameters = this.articleMapperService.toApiParameters(
      this.viewModel as ArticleModel
    );
    this.submissionStatus$.next(SubmissionStatus.Submitting);
    try {
      if (this.viewModel.inputContext.isEditing) {
        await this.articleService.updateMedia(apiParameters);
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      } else {
        const addMedia = isUserMediaEntry
          ? this.articleService.addUserMedia.bind(this.articleService)
          : this.articleService.addMedia.bind(this.articleService);
        const { result } = await addMedia(apiParameters, null);
        this.viewModel = {
          ...this.viewModel,
          inputId: result?.inputId ?? this.viewModel.inputId,
        };
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      }
      return;
    } catch {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in ArticleFacadeBase');
    }
  }

  /********************************************************
   * View Model
   ********************************************************/

  public async initializeEdit(): Promise<void> {
    // Update viewModel
    const editEntry: ArticleApiInputEdit =
      (await this.inputsService.getMediaEntryAsPromise(
        this.viewModel.inputContext.inputId
      )) as ArticleApiInputEdit;
    // Map response to view model
    const updatedView = this.articleMapperService.toViewModel(
      editEntry,
      this.viewModel
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isInitialForm: false,
      organizationId: this.orgId,
    };
    return;
  }

  /**
   * This
   * @param inputContext
   */
  protected async initializeViewModel(
    inputContext: InputContext
  ): Promise<void> {
    // Listen for changes on media parsing status TODO submission Status
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
      this.mediaMetadataStatus$,
    ]).pipe(
      map(
        ([s, m]) =>
          s === SubmissionStatus.Submitting || m === MediaMetadataStatus.Parsing
      )
    );

    // initialize context
    super.initializeContextViewModel(inputContext);
    const canManageContent =
      !!this.authService.authUser?.defaultOrgInfo?.permissions?.manageContent;

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      canManageContent,
      canRestrictContent: !!this.authService.authUser?.canRestrictContent,
      duplicateCount: 0,
      entryUrl: '',
      tags: [],
      title: '',
      sourceName: '',
      summary: '',
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
      imageUploadAdapter: this.inputImageUploadAdapterService.getAdapter(
        inputContext.inputType,
        undefined
      ),
    };
    this.getInputTypeFormats();
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: ArticleFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      entryUrl: formData.entryUrl,
      title: formData.title,
      sourceName: formData.sourceName,
      format: formData.format,
      summary: formData.description,
      comment: formData.comment,
      imageUrl: formData.image,
      durationMinutes: formData.durationForm.durationMinutes,
      durationHours: formData.durationForm.durationHours,
      tags: formData.skills,
      // These are for advanced Settings only
      orgContentMetadata: {
        hideFromCatalog: formData.addToCatalog
          ? !formData.addToCatalog
          : this.viewModel.orgContentMetadata?.hideFromCatalog,
        groupIds: formData.advancedSettings?.visibility.groups,
      },
      externalId: formData.advancedSettings?.internalItemId,
      language: formData.advancedSettings?.language?.id,
      publishDate: formData.advancedSettings?.publishedDate,
    };
  }

  private async getInputTypeFormats(): Promise<void> {
    const response = await lastValueFrom(
      this.orgInternalContentService.getInputTypeFormats(
        this.viewModel.inputContext.inputType
      )
    );

    this.viewModel = {
      ...this.viewModel,
      inputTypeFormats: response.map((f) => ({ title: f })),
    };
  }
}
