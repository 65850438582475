<form class="form-wrap">
  <dgx-modal
    [bodyStyle]="{ 'max-height': '420px' }"
    [isLoading]="isLoading"
    [isFooterless]="true"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="false"
    bodyClasses="dg-modal__body dg-modal__body-scrollable-content guts-p-h-0 guts-p-t-0"
    headerClasses="h3"
  >
    <ng-container class="modal-header">
      <dgx-profile-pic
        *ngIf="!isLoading"
        [profile]="peer"
        size="smaller"
        class="guts-m-r-1-half ib ib-v-middle"
      ></dgx-profile-pic>
      {{ peer?.name }}
    </ng-container>

    <ng-container class="modal-body">
      <div class="guts-m-t-2">
        <div *ngIf="mentoringSkills?.length" class="guts-p-b-half">
          <h3 class="par par--light par--small font-bold guts-p-l-2 guts-p-b-1">
            {{ i18n.Opportunities_MentorSkills }}
          </h3>
          <ng-template
            *ngTemplateOutlet="
              skillsTemplate;
              context: { $implicit: mentoringSkills }
            "
          >
          </ng-template>
        </div>

        <div *ngIf="usersOtherSkills?.length" class="guts-p-b-half">
          <div *ngIf="mentoringSkills?.length">
            <h3
              class="
                par par--light par--small
                font-bold
                guts-p-l-2 guts-p-b-1 guts-p-t-1-half
                skills-title
              "
            >
              {{ i18n.Opportunities_OtherSkills }}
            </h3>
          </div>
          <ng-template
            *ngTemplateOutlet="
              skillsTemplate;
              context: { $implicit: usersOtherSkills }
            "
          >
          </ng-template>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #skillsTemplate let-skills>
  <div class="guts-p-b-half">
    <div
      *ngFor="let skill of skills; index as index"
      [id]="'user-skill-' + index"
      tabindex="0"
      data-dgat="marketplace-skills-modal-048"
    >
      <div class="user-skills-modal__item">
        <div class="guts-p-h-2 guts-p-v-1 l_flexbar">
          <dgx-tag-rating-donut
            *ngIf="!getHasExternalSkillRating(skill); else externalDonut"
            class="block l_flex-none"
            size="sm"
            [certified]="getIsCertified(skill)"
            [evaluated]="getIsEvaluated(skill)"
            [highlight]="false"
            [level]="getCurrentSkillLevel(skill).toString()"
            [showDefaultGlasses]="true"
          ></dgx-tag-rating-donut>
          <ng-template #externalDonut>
            <dgx-tag-rating-donut
              class="block l_flex-none"
              size="sm"
              [highlight]="false"
              [tag]="skill"
            ></dgx-tag-rating-donut>
          </ng-template>
          <div class="guts-m-h-1-half l_flex-grow">
            <h3 class="par par--small font-medium">{{ skill.title }}</h3>
          </div>
          <div class="l_flex-none">
            <div class="badge badge-pill badge-neutral">
              {{ getSkillSignalsLabel(skill) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
