<form class="form-wrap">
  <dgx-modal
    [bodyStyle]="{ 'max-height': '420px' }"
    [canCancel]="true"
    [isLoading]="isLoading"
    [submitButtonText]="'Core_Close' | translate"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="false"
    bodyClasses="dg-modal__body dg-modal__body-scrollable-content guts-p-h-0 guts-p-t-0"
    headerClasses="h3"
  >
    <!-- Modal header -->
    <ng-container class="modal-header">
      <dgx-profile-pic
        *ngIf="!isLoading"
        [profile]="profile"
        size="smaller"
        class="guts-m-r-1-half ib ib-v-middle"
      ></dgx-profile-pic>
      {{ profile?.name }}
    </ng-container>
    <!-- Modal body -->
    <ng-container class="modal-body">
      <!-- Skills list -->
      <div class="guts-m-t-2">
        <div *ngIf="skills.length; else notice" class="guts-p-b-half">
          <div
            *ngFor="let skill of skills; index as index"
            [id]="'user-skill-' + index"
            data-dgat="user-skills-modal-641"
          >
            <div
              class="user-skills-modal__item"
              [ngClass]="{ 'user-skills-modal__item--opened': skill.opened }"
            >
              <div
                class="
                  guts-p-h-2 guts-p-v-1
                  l_flexbar
                  user-skills-modal__item__header
                "
              >
                <!-- Skill Rating Donut (For this view, we deliberately only include *completed* properties)-->
                <dgx-tag-rating-donut
                  *ngIf="
                    !opportunitySkillsModalService.hasExternalSkillRating(
                      skill
                    );
                    else externalDonut
                  "
                  class="block l_flex-none"
                  size="sm"
                  [certified]="opportunitySkillsModalService.isCertified(skill)"
                  [evaluated]="opportunitySkillsModalService.isEvaluated(skill)"
                  [highlight]="false"
                  [level]="
                    opportunitySkillsModalService
                      .getCurrentSkillLevel(skill)
                      .toString()
                  "
                  [showDefaultGlasses]="true"
                ></dgx-tag-rating-donut>
                <ng-template #externalDonut>
                  <dgx-tag-rating-donut
                    class="block l_flex-none"
                    size="sm"
                    [highlight]="false"
                    [tag]="asTag(skill)"
                  ></dgx-tag-rating-donut>
                </ng-template>
                <div class="guts-m-h-1-half l_flex-grow">
                  <!-- Skill Title -->
                  <h3 class="par par--small font-medium" tabindex="0">
                    {{ skill.title }}
                  </h3>
                  <!-- Skill Level on opportunity -->
                  <p
                    class="par par--small font-smaller"
                    [ngClass]="
                      isSkillLevelOnOpportunityMet(skill)
                        ? 'color-success'
                        : 'color-ebony-a61'
                    "
                    *ngIf="hasSkillLevelOnOpportunity(skill)"
                  >
                    {{
                      'Target_TargetLevelFormat'
                        | translate
                          : { level: getSkillLevelOnOpportunity(skill) }
                    }}
                  </p>
                  <button
                    [hidden]="hideRatingButton(skill)"
                    (click)="showUserRateSkillModal(skill)"
                    #addTargetLevelTrigger
                    class="par par--small font-medium link"
                    type="button"
                    data-dgat="user-skills-modal-029"
                  >
                    {{ i18n.Opportunities_Skills_AddSkillRating }}
                  </button>
                </div>
                <div class="l_flex-none">
                  <div
                    *ngIf="!skill.opened && skill.isFollowing"
                    class="badge badge-pill badge-neutral"
                  >
                    {{
                      opportunitySkillsModalService.getSkillSignalsLabel(
                        skill.signalCount || 0
                      )
                    }}
                  </div>
                  <button
                    [attr.aria-expanded]="skill.opened"
                    [attr.aria-controls]="
                      'js-animate-toggle-permission-panel-' + index
                    "
                    [id]="'js-animate-toggle-permission-trigger-' + index"
                    tabindex="0"
                    data-dgat="user-skills-modal-da6"
                    (click)="toggleSkillDetail(skill)"
                  >
                    <df-icon
                      [icon]="skill.opened ? 'chevron-up' : 'chevron-down'"
                      size="medium"
                      class="color-ebony-a61 guts-m-l-quart"
                    ></df-icon>
                  </button>
                </div>
              </div>
              <div *ngIf="skill.opened" [@dfCollapseExpand]>
                <div
                  class="
                    color-ebony-a61
                    guts-p-h-2 guts-p-v-1
                    par--small
                    user-skills-modal__item__body
                  "
                >
                  <!-- Ratings in detail -->
                  <div
                    *ngFor="let rating of skill.ratings"
                    class="l_flex l_flex-justify"
                    data-dgat="user-skills-modal-359"
                  >
                    <dgx-tag-rating-provider-label
                      class="l_flex-grow guts-m-r-2 left-text ib"
                      [rating]="rating"
                    ></dgx-tag-rating-provider-label>
                    <dgx-tag-rating-provider-level
                      class="l_flex-grow right-text ib"
                      [rating]="rating"
                    ></dgx-tag-rating-provider-level>
                    <div class="guts-p-l-1" *ngIf="showMoreOption(rating)">
                      <dgx-menu
                        [menuConfig]="getMenuConfig(skill)"
                        placement="bottom-right"
                        [allowMenuGrowth]="true"
                      >
                        <button
                          type="button"
                          #ratingsMenuButton
                          data-dgat="user-skills-modal-0a2"
                        >
                          <df-icon
                            [a11yText]="i18n.Core_MoreOptions"
                            icon="dots"
                            class="color-ebony-a61"
                          ></df-icon>
                        </button>
                      </dgx-menu>
                    </div>
                  </div>
                  <div *ngIf="!skill.ratings?.length" class="font-semibold">
                    {{
                      (skill.userInterestId || skill.newlyAdded
                        ? 'Opportunities_Skills_Following_But_No_Rating'
                        : 'dgTagRating_NoRatings'
                      ) | translate
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #notice>
          <ng-container *ngIf="state === 'RequestedUserMissingOptIn'">
            <h2 class="center-text color-ebony-a45-hue h3">
              {{ 'Core_AccessDenied' | translate }}
            </h2>
            <p class="center-text color-ebony-a45-hue">
              {{ 'Opportunities_Candidates_UserHasNotOptedIn' | translate }}
            </p>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
    <!-- Modal Footer -->
    <ng-container class="modal-footer">
      <button
        df-button
        dfButtonType="primary"
        data-dgat="user-skills-modal-272"
        (click)="onClose()"
      >
        {{ i18n.Core_Close }}
      </button>
    </ng-container>
  </dgx-modal>
</form>
