<dgx-modal-header (dismiss)="close()">
  {{ orgNewGroupSettingsInfo.createGroupTitle || '' }}
</dgx-modal-header>
<dgx-group-settings
  [groupId]="orgNewGroupSettingsInfo.groupId"
  [newGroupOrg]="orgNewGroupSettingsInfo.orgId"
  [canCreateOpenGroups]="orgNewGroupSettingsInfo.canCreateOpenGroups"
  [canCreateClosedGroups]="orgNewGroupSettingsInfo.canCreateClosedGroups"
  [canCreatePrivateGroups]="orgNewGroupSettingsInfo.canCreatePrivateGroups"
  [canCreateAdministrativeGroups]="orgNewGroupSettingsInfo.canCreateAdministrativeGroups"
  [isRegulated]="orgNewGroupSettingsInfo.isRestrictedProfile"
  (saveCallback)="save($event)"
></dgx-group-settings>
