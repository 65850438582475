import { Entity, PaginationProps } from '@dg/shared-rsm';
import { SearchFilter, SearchRelatedVM } from '../models';
import {
  QueryUrlParams,
  SearchLearningOptions,
  SearchState,
  ServiceUrlParams,
  SearchChangeTrigger,
  AppliedSearchFacet,
  SearchViewModel,
} from '../models/search.model';
import { EndorsedPagination } from '../search-endorsed.facade';

export const PAGINATION_LIMIT = 20;
export const ENDORSED_FACET = {
  id: 'Endorsed',
  name: 'Endorsed',
  values: ['true'],
};

export function initSearchState(): SearchState {
  return {
    term: undefined,
    sort: 'relevant',
    filters: [],
    appliedFacets: [],
    results: [],
    pagination: {
      currentPage: 0,
      perPage: 0,
      total: 0,
      lastPage: 0,
      start: 0,
      end: 0,
    } as PaginationProps,
    isExternalCat: false,
    isMarketplaceCat: false,
    viewLearningId: undefined,
    defaultOrgId: undefined,
    orgId: undefined,
    suggestedTerm: '',
    typoAutoSearched: false,
    previousTerm: '',
    isCatalogSearch: false,
    changeTrigger: SearchChangeTrigger.Term,
  };
}

/**
 * Map store state to Proxy API Search options
 */
export function stateToLearningOptions(
  state: SearchState
): SearchLearningOptions {
  const {
    term,
    orgId,
    pagination,
    sort,
    appliedFacets,
    isExternalCat,
    isMarketplaceCat,
  } = state;
  const { currentPage, perPage } = pagination;

  const pageNum = currentPage ? (currentPage > 0 ? currentPage : 1) : 1;

  return {
    orgId: isExternalCat || isMarketplaceCat ? undefined : orgId,
    term,
    options: {
      pageNum,
      boostRecent: sort?.toLowerCase() === 'recent',
      boostPopular: sort?.toLowerCase() === 'popular',
      numResultsToSkip: (pageNum - 1) * perPage,
      appliedFacets,
      isMarketplace: isMarketplaceCat,
    },
  };
}

/**
 * Special partial application to easily modify SearchState with partial overrides.
 *
 * Note: this is called with `this._store.getState(mergeOverrides(modified))`
 */
export function mergeOverrides({
  pagination,
  ...others
}: Partial<SearchState>) {
  return (state: SearchState): SearchState => {
    const updated = { ...state, ...others };
    updated.pagination = { ...updated.pagination, ...pagination };

    return updated;
  };
}

/**
 * Strip default values so only override values are displayed in URL
 */
export function stripDefaultParams(
  params: Partial<QueryUrlParams & ServiceUrlParams>
): Partial<QueryUrlParams & ServiceUrlParams> {
  if (params.pageNum < 2) {
    delete params.pageNum;
  }
  if (params.ignorePreferredLanguage === false) {
    delete params.ignorePreferredLanguage;
  }
  if (params.is_msteams === false) {
    delete params.is_msteams;
  }
  if (params.isExternalCat === false) {
    delete params.isExternalCat;
  }
  if (params.isMarketplaceCat === false) {
    delete params.isMarketplaceCat;
  }
  if (params.sort === 'relevant') {
    delete params.sort;
  }
  return params;
}

/**
 * When criteria updates occur, reset to view results for page 1
 */
export function atFirstPage(state: SearchState) {
  const pagination = {
    ...state.pagination,
    currentPage: 1,
  };
  return {
    ...state,
    pagination,
  };
}

export function applyEndorsedFacet(
  facets: AppliedSearchFacet[] = []
): AppliedSearchFacet[] {
  const alreadyApplied = facetIsIncluded(facets, 'Endorsed');

  return alreadyApplied ? facets : [...facets, ENDORSED_FACET];
}

export function facetIsIncluded(
  facets: AppliedSearchFacet[],
  facetId: string
): boolean {
  return facets.some(({ id }) => id === facetId);
}

export function mayLoadEndorsedContent({
  totalCount,
  list,
}: SearchRelatedVM): boolean {
  return totalCount > list.length;
}

export function showEndorsedContent([
  { pagination, isLoading, endorsedFilterSelected },
  { totalCount },
]: [SearchViewModel, SearchRelatedVM]): boolean {
  return (
    totalCount > 0 &&
    pagination.currentPage === 1 &&
    !isLoading &&
    !endorsedFilterSelected
  );
}

export function getEndorsedPagination([learnings, reset]: [
  Entity[],
  boolean
]): EndorsedPagination {
  const skip = reset ? 0 : learnings.length;
  const count = reset ? 2 : 6;
  return { count, skip };
}

export function getSelectedFilterCount(filters: SearchFilter[]) {
  return (
    filters?.reduce(
      (total, filter) =>
        total + filter.subitems.filter(({ isSelected }) => isSelected).length,
      0
    ) || 0
  );
}

export function formatSearchFilterContentCount(count: number) {
  return count < 50 ? count : '50+';
}
