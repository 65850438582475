<button
  *ngIf="hasAction"
  (click)="action()"
  type="button"
  class="btn btn-primary no-wrap action-button"
  attr.aria-label="{{ actionLabel }}"
  data-dgat="toast-aab"
>
  {{ actionLabel }}
</button>

<button
  *ngIf="options.closeButton"
  (click)="remove()"
  type="button"
  class="toast-close-button"
  aria-label="Close"
  data-dgat="toast-444"
>
  <span aria-hidden="true">&times;</span>
</button>

<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }}
  <ng-container *ngIf="duplicatesCount"
    >[{{ duplicatesCount + 1 }}]</ng-container
  >
</div>
<div
  *ngIf="message && options.enableHtml"
  role="alertdialog"
  aria-live="polite"
  [class]="options.messageClass"
  [innerHTML]="message"
></div>
<div
  *ngIf="message && !options.enableHtml"
  role="alertdialog"
  aria-live="polite"
  [class]="options.messageClass"
>
  {{ message | decodeHtml }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
