import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'dgx-toggle-switch',
  templateUrl: './toggle-switch.component.html',
})
export class ToggleSwitchComponent implements AfterViewInit {
  @Input() public isChecked: boolean;
  @Input() public id: string;
  // Deprecated: please use ariaDisabled and in-page notifications to explain why toggle isn't active
  @Input() public disabled: boolean = false;

  // Set `preventAutoToggle` to true to only update the toggle value through the `isChecked` input
  @Input() public preventAutoToggle: boolean = false;

  // ID of label for toggle; use this over a for-id pair to meet discernible text requirements
  @Input() public ariaLabelledBy: string;

  // label for toggle; use this to meet discernible text requirements
  @Input() public ariaLabel: string;

  // Use instead of disabled for assistive-tech friendly disabled state; use with preventAutoToggle.
  // NOTE: you WILL have to manage preventing clicks when in aria-disabled=true state in calling component
  @Input() public ariaDisabled: boolean = false;

  @Output() public toggled = new EventEmitter<boolean>();
  @ViewChild('toggleSwitch') public toggleSwitch: ElementRef;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    // For a11y: remove the id from the original element, and add it to the button in this component to maintain label to input relationship
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
    this.renderer.setAttribute(this.toggleSwitch.nativeElement, 'id', this.id);
  }

  public toggleInput() {
    if (!this.preventAutoToggle) {
      this.isChecked = !this.isChecked;
      this.toggled.emit(this.isChecked);
    } else {
      this.toggled.emit(!this.isChecked);
    }
  }
}
