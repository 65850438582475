<dgx-modal
  [useDefaultSubmitButton]="false"
  [isHeaderBorderless]="false"
  bodyStyle="padding: 24px"
  data-dgprop-initiation-location="Peer Rating Overview Modal"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <span [textContent]="i18n.dgTagRating_PeerRatings"></span>
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div class="l_flexbar guts-p-b-2" *ngIf="canRequestPeerRating">
      <df-icon icon="plus-circle" class="guts-p-r-half color-blue"></df-icon>
      <button
        type="button"
        class="par par--small font-semibold color-blue"
        [textContent]="i18n.dgTagRating_RequestPeerRating"
        data-dgat="peer-rating-overview-modal-47e"
        (click)="handleClick($event)"
      ></button>
    </div>

    <!-- Pending -->
    <div
      *ngFor="let pendingRating of pendingRatings"
      data-dgat="peer-rating-overview-modal-f26"
    >
      <div class="l_flexbar border-top guts-p-v-1" tabindex="0">
        <dgx-profile-pic
          class="l_flex-top"
          [profile]="pendingRating.rater"
          tabIndex="-1"
        ></dgx-profile-pic>
        <div class="guts-p-h-1" role="presentation">
          <p
            class="font-semibold par--small"
            [textContent]="getRaterName(pendingRating)"
          ></p>
          <div class="l_flexbar">
            <p
              class="par--small par--light guts-p-r-half"
              [textContent]="
                requestedAgeString(
                  pendingRating.dateRequested | prettyTimeElapsed: 'minutes'
                )
              "
            ></p>
            <button
              *ngIf="isOwner"
              type="button"
              class="par par--small color-blue"
              [attr.aria-label]="cancelPendingRatingLabel"
              [textContent]="i18n.Core_Cancel"
              (click)="cancelPendingRating($event, pendingRating)"
              data-dgat="peer-rating-overview-modal-76a"
            ></button>
          </div>
        </div>
        <div class="l_flex-top l_flex--align-right guts-p-t-half">
          <h4
            class="no-wrap badge badge-pill badge-attention"
            [textContent]="i18n.Core_Pending"
          ></h4>
        </div>
      </div>
    </div>

    <!-- Complete -->
    <div
      *ngFor="let completedRating of completedRatings"
      data-dgat="peer-rating-overview-modal-f26"
    >
      <div
        class="l_flexbar border-top guts-p-v-1"
        [ngClass]="{
          'peer-rating-overview-modal__completed-rating':
            isOwner && completedRating.rater
        }"
        (click)="openCompletedRatingModal($event, completedRating)"
        (keydown)="keydownHandler($event, completedRating)"
        tabindex="0"
      >
        <dgx-profile-pic
          class="l_flex-top"
          [profile]="completedRating.rater"
        ></dgx-profile-pic>
        <div class="par--small guts-p-h-1">
          <p
            class="font-semibold"
            [textContent]="getRaterName(completedRating)"
          ></p>
          <div class="peer-rating-overview-modal__comment">
            <dgx-text-expander
              *ngIf="completedRating.comment"
              [content]="completedRating.comment"
              [maxCollapsedLines]="2"
              [isHtml]="true"
            ></dgx-text-expander>
            <p
              class="par--light"
              [textContent]="
                completedRating.dateCompleted | prettyTimeElapsed: 'minutes'
              "
            ></p>
          </div>
        </div>
        <div class="l_flex-top l_flex--align-right guts-p-t-half">
          <h4
            class="no-wrap badge badge-pill badge-neutral"
            [textContent]="ratingLevelString(completedRating.level)"
          ></h4>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      [textContent]="ctaText"
      (click)="handleClick($event)"
      data-dgat="peer-rating-overview-modal-e22"
    ></button>
  </ng-container>
</dgx-modal>
