<span class="field-group" [class.field-group--focus]="hasFocus">
  <span #content class="field-group__input">
    <ng-content select="input, textarea"></ng-content>
  </span>
  <span class="field-group--character-counter">
    <div class="par par--small par--light" [class.l_flex-bottom]="isTextarea">
      {{ text?.length || 0 }} / <b>{{ limit }}</b>
    </div>
  </span>
</span>
