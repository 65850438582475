import { Injectable } from '@angular/core';
import { EventApiEntity } from '../repository/user-event.entity.model';
import { Mapper } from '../../mapper.model';
import { EventFormModel } from '../event-modal.facade';
import { UserEvent } from '@app/inputs/inputs-api.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalAddEventMapper
  implements Mapper<EventFormModel, EventApiEntity>
{
  /**
   * from api to initFromModel
   */
  public toViewModel(source: Partial<UserEvent>): Partial<EventFormModel> {
    return {
      extent: {
        length: source.length,
        involvementLevel: source.involvementLevel,
        dateAttended: source.dateAttended
          ? new Date(source.dateAttended)
          : undefined,
      },
      title: source.name ?? '',
      eventUrl: source.eventUrl ?? '',
      tags: source.tags,
    };
  }

  /**
   * From createResult to Api
   */
  public toApiParameters(source: EventFormModel): EventApiEntity {
    return {
      inputId: source.inputId ? source.inputId : source.eventId,
      eventId: source.inputId ? source.inputId : source.eventId,
      userEventId: source.userEventId,
      imageUrl: source.imageUrl,
      organizationId: source.organizationId,
      involvementLevel: source.involvementLevel,
      inputType: source.inputType,
      length: source.extent?.length,
      title: source.title,
      name: source.title,
      eventUrl: source.eventUrl,
      dateAttended: source.extent.dateAttended?.toISOString(),
      tags: source.tags,
      summary: source.summary,
      orgContentMetadata: {
        hideFromCatalog: true, // Default conversion
        groupIds: [],
      },
    };
  }
}
