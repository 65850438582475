import { InjectionToken } from '@angular/core';
import { LDClient } from 'launchdarkly-js-client-sdk';

/**
 * @description An `@Inject` token for the LDClientSDK. Documentation:
 * https://docs.launchdarkly.com/sdk/client-side/javascript
 *
 * **Warning:** If making or using a new LD flag, be sure to set the
 * `Make this flag available to client-side SDKs` property to *true*
 * via the web client; otherwise, the SDKClient won't be able to
 * actually read the settings, and you will only ever get the default
 * value back!
 *
 * @example
 * ```
 * import { LDClientSDK } from '＠app/shared/services/ldclientsdk.token';
 * import { LDClient } from 'launchdarkly-js-client-sdk';
 *
 * constructor(＠Inject(LDClientSDK) public ldClientSDK: LDClient) {}
 *
 * const LDFlag: boolean = this.ldClientSDK.variation(
 *   // name of the flag
 *   'name-of-the-ld-flag',
 *   // default value
 *   false
 * );
 * ```
 */
export const LDClientSDK = new InjectionToken<LDClient>('ldclientsdk');
export { LDClient };
