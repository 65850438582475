<dgx-modal-header (dismiss)="dismiss()" [canCancel]="true">
  {{ headerTitle }}
</dgx-modal-header>

<div class="guts-p-full-2">
  <p class="par">
    {{ instructions }}
  </p>
</div>

<form
  class="guts-p-h-2 guts-p-b-2"
  (ngSubmit)="submit()"
  *ngIf="!isLoading; else loading"
>
  <div *ngIf="!sectionsToFollow.length" class="border-top guts-p-v-1">
    <p class="par par--small par--light_a">
      {{
        'followTargetForm_NoResources' | translate: { targetType: targetType }
      }}
    </p>
  </div>

  <ngb-accordion
    data-dgat="follow-target-form-modal-e0a"
    *ngIf="sectionsToFollow.length"
  >
    <ng-container
      *ngFor="let section of sectionsToFollow; trackBy: trackById"
      data-dgat="follow-target-form-modal-064"
    >
      <ngb-panel
        *ngIf="section.resources.length > 0"
        data-dgat="followTargetForm-0e7"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="l_flexbar guts-p-full-1">
            <label class="l_flexbar l_flex-grow">
              <input
                type="checkbox"
                name="{{ section.resourceType }}"
                [attr.aria-label]="getSectionHeaderName(section, true)"
                [(ngModel)]="section.selected"
                (change)="sectionSelected(section)"
                data-dgat="followTargetForm-d78"
              />

              <span class="par par--small guts-p-l-1-half l_flex-one">
                <span class="l_flexbar font-medium">{{
                  getSectionName(section.resourceType, section.name)
                }}</span>

                <span>{{ getSectionHeaderName(section) }}</span>
              </span>
            </label>

            <button
              ngbPanelToggle
              type="button"
              class="expander guts-p-full-1"
              [attr.aria-expanded]="opened"
              [attr.aria-label]="
                (opened ? 'Core_Collapse' : 'Core_Expand') | translate
              "
              data-dgat="followTargetForm-0b6"
            >
              <df-icon
                *ngIf="!opened"
                icon="chevron-down"
                size="medium"
              ></df-icon>
              <df-icon *ngIf="opened" icon="chevron-up" size="medium"></df-icon>
            </button>
          </div>
        </ng-template>

        <ng-template ngbPanelContent>
          <ul class="border-left guts-m-l-1-quart guts-p-l-2">
            <li
              *ngFor="let resource of section.resources"
              class="guts-p-v-quart"
              data-dgat="followTargetForm-163"
            >
              <label class="l_flexbar">
                <input
                  type="checkbox"
                  name="{{ resource.reference.title }}"
                  class="guts-p-r-half"
                  [(ngModel)]="resource.selected"
                  [attr.aria-label]="
                    'followTargetForm_selectTitleFormat'
                      | translate: { title: resource.reference.title }
                  "
                  (change)="resourceSelected(section, resource)"
                  data-dgat="followTargetForm-b98"
                />
                <span class="par par--small guts-p-l-half">{{
                  resource.reference.title
                }}</span>
              </label>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</form>

<ng-template #loading>
  <div class="guts-p-t-4 guts-p-b-6 rel">
    <df-spinner [isSpinning]="true"></df-spinner>
  </div>
</ng-template>

<dgx-modal-footer
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="dismiss()"
>
  <button
    type="submit"
    df-button
    dfButtonType="primary"
    class="guts-m-r-quart guts-p-h-2"
    [disabled]="isLoading"
    (click)="submit()"
    data-dgat="followTargetForm-17c"
  >
    <df-spinner-button [isSpinning]="isSaving">
      {{ 'followTargetForm_Submit' | translate: { targetType: targetType } }}
    </df-spinner-button>
  </button>
</dgx-modal-footer>
