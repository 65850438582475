import { Injectable, Type } from '@angular/core';
import { UserMediaService } from '@app/learner-home/services/user-media.service';
import { InputType } from '@app/shared/models/core-api.model';
import { ModalOptions, ModalService } from '@app/shared/services/modal.service';
import { UserCourseModalService } from '@app/user-content/user-input/course-form/user-course-modal.service';
import { UserEpisodeService } from '@app/user-content/user-input/episode-form/user-episode.service';
import { UserEventService } from '@app/user-content/user-input/event-modal/user-event.service';

import { map, tap } from 'rxjs/operators';
import {
  AnyInputOrUserInput,
  InlineInputSubmitter,
  InputAddType,
  InputManager,
  InputShowFormParams,
} from '../inputs.model';
import {
  InputDetails,
  UserInputCreationFeedback,
  UserInputIdentifier,
} from '../inputs-api.model';
import { InputsService } from './inputs.service';
import { UserAssessmentService } from './user-assessment.service';
import { UserBookService } from './user-book.service';
import { UserExperienceService } from './user-experience.service';
import { AddTagsModal } from '@app/inputs/components/input-details/add-tags-modal/add-tags-modal.component';
import { TagsApi } from '@app/tags/tag-api.model';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { FocusStackService } from '@app/shared/services/focus-stack.service';

export interface InputModalConfig {
  // TODO: fix types
  component?: Type<any>;
  load?: (
    identifier: Partial<UserInputIdentifier>
  ) => Observable<AnyInputOrUserInput>;

  inputTypeService: InputManager;
}

export interface InlineSubmissionArgs<T> {
  resultModel: T;
  resultProvider: Subject<T>;
}

@Injectable({
  providedIn: 'root',
})
export class UserInputModalService {
  private static readonly trackingArea = 'GlobalAdd';
  private readonly modalComponentConfig: Partial<
    Record<InputType, InputModalConfig>
  > = {
    Event: {
      load: (
        identifier: Partial<UserInputIdentifier>
      ): Observable<AnyInputOrUserInput> =>
        identifier.userInputId
          ? this.userEventService.getInput(identifier.userInputId)
          : this.inputsService.getEvent(identifier.inputId),
      inputTypeService: this.userEventService,
    },
    Episode: {
      load: (identifier: Partial<UserInputIdentifier>) =>
        identifier.userInputId
          ? this.userEpisodeService.getInput(identifier.userInputId)
          : this.inputsService.getEpisode(identifier.inputId),
      inputTypeService: this.userEpisodeService,
    },
    Assessment: {
      load: (
        identifier: Partial<UserInputIdentifier>
      ): Observable<AnyInputOrUserInput> =>
        identifier.userInputId
          ? this.userAssessmentService.getInput(identifier.userInputId)
          : this.inputsService.getAssessment(identifier.inputId),
      inputTypeService: this.userAssessmentService,
    },
    Position: {
      inputTypeService: this.userExperienceService,
    },
    Article: {
      load: (identifier: Partial<UserInputIdentifier>) =>
        identifier.userInputId
          ? this.userMediaService.getInput(identifier.userInputId)
          : this.inputsService.getMediaEntry(identifier.inputId),
      inputTypeService: this.userMediaService,
    },
    Video: {
      load: (identifier: Partial<UserInputIdentifier>) =>
        identifier.userInputId
          ? this.userMediaService.getInput(identifier.userInputId)
          : this.inputsService.getMediaEntry(identifier.inputId),
      inputTypeService: this.userMediaService,
    },
    Book: {
      load: (identifier: Partial<UserInputIdentifier>) =>
        identifier.userInputId
          ? this.userBookService.getInput(identifier.userInputId)
          : this.inputsService.getBook(identifier.inputId),
      inputTypeService: this.userBookService,
    },
    // TODO: Update Posts to use load and add/update functions when we add integrate to work with other pathway components
    // Currently all load, add, update logic is handled within the Postform Component
    Post: {
      load: () => of(),
      inputTypeService: this.inputsService,
    },
    Course: {
      load: (identifier: Partial<UserInputIdentifier>) =>
        identifier.userInputId
          ? this.userCourseModalService.getInput(identifier.userInputId)
          : this.inputsService.getCourse(identifier.inputId),
      inputTypeService: this.userCourseModalService,
    },
  };

  constructor(
    private modalService: ModalService,
    private focusStackService: FocusStackService,
    private userAssessmentService: UserAssessmentService,
    private userExperienceService: UserExperienceService,
    private userEpisodeService: UserEpisodeService,
    private userEventService: UserEventService,
    private userMediaService: UserMediaService,
    private userBookService: UserBookService,
    private inputsService: InputsService,
    private userCourseModalService: UserCourseModalService,
    private translate: TranslateService
  ) {}

  // TODO:
  // THIS IS BEING USED BY OPPORTUNITY THROUGH profile-overview.service just for experience modals
  // Opportunity service should call the new modal dispatcher directly
  // UPDATE: no longer in use by opportunities as of PD-80322.

  /**
   * completing = is being added to, or edited from, user profile (not a pathway)
   * @param {InputShowFormParams} options
   * @deprecated
   * @returns {Observable<unknown>}
   */
  public complete({
    inputType,
    inputId,
    initialModel: initialViewModel,
    sourceTarget,
    submit,
    modalOptions,
    trackingAction,
    trackingArea,
    // FOR OLD MODALS. Remove when no longer allowing the old modal flag.
    cb,
  }: Partial<InputShowFormParams>) {
    return this.showForm({
      inputType,
      inputId,
      isCompleting: true,
      // TODO: It appears that some of our modals expect `initialModel`,
      // while some of them expect `initialViewModel`. Passing both to
      // fix PD-77686.
      initialModel: initialViewModel,
      initialViewModel,
      sourceTarget,
      submit,
      modalOptions,
      trackingAction,
      trackingArea,
      // FOR OLD MODALS. Remove when no longer allowing the old modal flag.
      cb,
    });
  }

  /**
   *
   * @param {InputShowFormParams} options
   * @returns {Observable<unknown>}
   */
  public showForm(options: InputShowFormParams) {
    const { inputType, showDeprecated, pathwayId } = options;
    const modalComponentConfig = this.getModalComponentConfig(inputType);

    const submit: InlineInputSubmitter = options.isCompleting
      ? modalComponentConfig.inputTypeService.addNewInput.bind(
          modalComponentConfig.inputTypeService
        )
      : modalComponentConfig.inputTypeService.updateInput.bind(
          modalComponentConfig.inputTypeService
        );
    const { modalOptions, sourceTarget, ...providedInputs } = options;

    const inputs = {
      ...providedInputs,
    };

    const submitFn = providedInputs.submit ?? submit; // don't let undefined provided submit stomp on our default one
    const { trackingAction, trackingArea } = providedInputs;

    inputs.submit = (input) => {
      return submitFn(
        input,
        trackingArea || UserInputModalService.trackingArea,
        trackingAction
      ).pipe(
        tap((feedback: UserInputCreationFeedback) => {
          // Add the user comment after successfully adding the user input
          if (input.comment) {
            this.inputsService
              .updateInputComment(
                inputType,
                options.inputId ?? feedback?.result.inputId,
                input.comment,
                input.title || input.name
              )
              .subscribe();
          }
        })
      );
    };
    this.focusStackService.push(sourceTarget);

    return this.showFormComponent(modalComponentConfig.component, {
      ...modalOptions, // restructure options for modal service
      inputs,
    });
  }

  // TODO: Move this tag management somewhere else, it's not specific to modal and we can get rid of this service

  public addTags({
    inputType,
    inputId,
    userInputId,
  }: Partial<InputShowFormParams>) {
    const modalComponentConfig = this.getModalComponentConfig(inputType);
    const saveInput: InlineInputSubmitter =
      modalComponentConfig.inputTypeService.updateInput.bind(
        modalComponentConfig.inputTypeService
      );
    const inputs = {
      inputIdentifier: {
        inputId,
        userInputId,
        contentCategory: 'Input',
        contentType: inputType,
      },
      config: modalComponentConfig,
      saveInput,
    };

    return this.modalService.show(AddTagsModal, {
      inputs,
    });
  }

  // Ideally this should be moved out of this service. It was easier to include it here at this time due to the `load`
  // functions in `modalComponentConfig` being required to handle both user and non-user inputs
  public getUserInputTags({
    inputType,
    userInputId,
    inputId,
  }): Observable<TagsApi.Tag[]> {
    const modalComponentConfig = this.getModalComponentConfig(inputType);
    return modalComponentConfig?.load({ userInputId, inputId }).pipe(
      map((input: AnyInputOrUserInput) => {
        if (!input?.tags) {
          return [];
        }
        return (input as any as InputDetails).tags;
      }),
      catchAndSurfaceError(
        this.translate.instant('UserProfileSvc_ProblemAccessingCollection')
      )
    );
  }

  /**
   * Gets the assigned component type for a particular type of input
   * @param {string} type
   * @returns Type<any>
   */
  private getModalComponentConfig(type: InputAddType): InputModalConfig {
    return this.modalComponentConfig[type];
  }

  /**
   * Uses the ngx version of ModalService to open a modal using a component
   * components should be defined by the `ModalComponent` mapping at the top of this file.
   *
   * @param {Type<any>} modalComponent
   * @param {ModalOptions} modalOptions
   * @returns Observable<void>
   */
  private showFormComponent(
    modalComponent: Type<any>,
    modalOptions: ModalOptions
  ) {
    const size =
      modalOptions.inputs.inputType === 'Post' ? 'xlg-modal' : 'lg-modal';
    modalOptions = { windowClass: size, ...modalOptions };

    return this.modalService.show<void>(modalComponent, modalOptions);
  }
}
