import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, of } from 'rxjs';

// types
import {
  CaptionConfig,
  DataColumn,
  DataColumnDate,
  DataColumnHighlight,
  DataColumnList,
  DataColumnPerson,
  DataColumnProgressBar,
  MenuSettings,
} from './data-table.d';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { PerPageOptions } from '@app/shared/components/pagination/pagination.model';

// services
import { TranslateService } from '@ngx-translate/core';

/**
 * A flexible component for displaying various kinds of data.
 *
 * @example
 * ```
 * // Data table definition (paginated, with sorting, using new menu)
 * <dgx-data-table
 *   [columns]="columns"
 *   [hasNoItems]="hasNoItems"
 *   [hasNoResults]="hasNoResults"
 *   [items]="searchData.results"
 *   [isLoading]="isLoading"
 *   [isDescending]="isDescending"
 *   [isSorting]="isSorting"
 *   [menuSettings]="menuSettings"
 *   [noItemsAdded]="Core_NoItemsAdded"
 *   [numResultsPerPage]="20"
 *   [orderBy]="orderBy"
 *   [propTrackingKey]="propTrackingKey"
 *   [totalNumResults]="searchData.total"
 *   (pageChange)="pageChange($event)"
 *   (perPageChange)="perPageChange($event)"
 *   (updateSort)="updateSort($event)"
 * ></dgx-data-table>
 *
 * // A custom column template, which can be passed as part of
 * // the column definition for any column without a `type`.
 * // The below template can be added to your parent component via:
 * // ＠ViewChild('someTemplateName', { static: true })
 * //   public someTemplateName: any;
 * // and added to your column definition in ngOnInit.
 * <ng-template #someTemplateName let-item>
 *   <div>
 *     <span>{{ item.firstName }} {{ item.lastName }}</span>
 *   </div>
 * </ng-template>
 *
 * // If defining `prop` for the column, only that prop will
 * // be passed to the template.
 * <ng-template #someTemplateName let-title>
 *   <div>
 *     <span>{{ title }}</span>
 *   </div>
 * </ng-template>
 * ```
 */
@Component({
  selector: 'dgx-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnInit {
  // Bindings
  // - Styling
  /** Outermost wrapper class. Default value: `grid`. */
  @Input() public outerWrapperClass? = 'grid';
  /** Wrapper class for whole component */
  @Input() public componentWrapperClass? = 'grid__col-12 guts-p-t-0';
  /** Wrapper class for data table */
  @Input() public tableWrapperClass? = 'data-table--sortable-wrapper';
  // - Configure Columns and Rows
  /** Column definitions for the table. Control sorting, display of content, etc. */
  @Input() public columns: DataColumn[] = [];
  /** Caption for table header or footer */
  @Input() public captionConfig?: CaptionConfig;
  /** Items to display. */
  @Input() public items: any[] = [];
  /** Uniquely-identifying property to track items by. */
  @Input() public propTrackingKey: string;

  // - Optional Features
  /** Display settings for the new meatball menu. */
  @Input() public menuSettings?: MenuSettings;
  /** Whether to show a column of bulk-select checkboxes. */
  @Input() public canSelect? = false;
  /** Whether to show a skeleton when loading or just spinners. */
  @Input() public useSkeleton? = false;
  /** If data is grouped (with parentId, and parent properties), show or hide children on initial load  */
  @Input() public collapseGroups? = false;

  /** **Used by selectable tables only.** Tell the parent component that a selection was made. */
  @Output() public select: EventEmitter<{
    isSelected: boolean;
    item: any;
  }> = new EventEmitter();
  /** **Used by selectable tables only.** Tell the parent component that the bulk select toggle was toggled. */
  @Output() public bulkSelect: EventEmitter<{
    isSelected: boolean;
    items: any[];
  }> = new EventEmitter();

  // - Translated Strings
  /** i18n string to display when there are no items on the server at all. */
  @Input() public noItemsAdded?: string;
  /** i18n *header* string to display when there are no items on the server at all. */
  @Input() public noItemsAddedActionHeadline?: string;
  /** i18n call to action string to display when there are no items on the server at all. */
  @Input() public noItemsAddedActionDescription?: string;
  /** i18n string for call-to-action button text when there are no items on the server at all. */
  @Input() public noItemsAddedActionButtonText?: string;
  /** i18n string for when there are no items in the filtered results, but also no search term. */
  @Input() public noItemsCustomMessage?: string;
  /** A string to be passed to the `translate` pipe in the template with the variable `searchTerm`. Be sure your replacement text also uses `searchTerm` as its variable! */
  @Input() public noSearchResultText = 'Core_NoResultsFor';
  /** image path for no items */
  @Input() public noItemsImage?: string;
  /** i18n string to for alt text of no items image */
  @Input() public emptyImageAlt?: string;

  // - States passed between components, used in all scenarios
  /** Whether there are no items on the server at all. */
  @Input() public hasNoItems = false;
  /** No search results for that term, but items exist */
  @Input() public hasNoResults = false;
  /** Available to the parent to signal the current page should reset back to 1 */
  @Input() public forceResetPaging? = false;
  /** Available to aid forceResetPaging with resetting the current page back to 1 */
  @Output() public resetPaging? = new EventEmitter<null>();
  @Input() public searchTerm: string;
  /** Whether the items are currently loading. */
  @Input() public isLoading = false;

  // - Sorting options, used by sortable tables
  /** Whether any of the columns are sortable. *Defaults to true.* */
  @Input() public canSort? = true;
  /** **Used by sortable tables only.** Whether the current sort is descending or not. *Defaults to false.* */
  @Input() public isDescending = false;
  /** **Used by sortable tables only.** Whether the items are currently sorting. *Defaults to false.* */
  @Input() public isSorting = false;
  /** **Used by sortable tables only.** Column the items are currently being sorted by. *Defaults to first column if not set.* */
  @Input() public orderBy?: string;
  /** **Used by sortable tables only.** Tell the parent component to change the sort. */
  @Output() public updateSort: EventEmitter<string> = new EventEmitter();

  // - Pagination options, used by paginated tables
  /** **Used by paginated tables only.** The current page number. */
  @Input() public pageNum? = 1;
  @Input() public perPageOptions?: PerPageOptions[];
  /**
   * Used by paginated tables or skeleton loading.
   * The number of items to display per page or the rows the skeletons.
   */
  @Input() public numResultsPerPage?: number;
  /** **Used by paginated tables only.** The total number of items. */
  @Input() public totalNumResults?: number;
  /** **Used by paginated tables only.** Show the per page sorting. */
  @Input() public showPerPage?: boolean;
  /** **Used by paginated tables only.** Tell the parent component to change pages. */
  @Output() public pageChange?: EventEmitter<number> = new EventEmitter();
  /** **Used by paginated tables only.** Tell the parent component to change the per page limit. */
  @Output() public perPageChange?: EventEmitter<number> = new EventEmitter();

  // - Infinite scroll options, used by infinite tables
  /** Whether to use infinite scroll or pagination. *Defaults to false.* */
  @Input() public useInfiniteScroll = false;
  /** **Used by infinite tables only.** Whether there are more items on the server that can be loaded in. */
  @Input() public hasMoreItems?: boolean;
  /** **Used by infinite tables only.** Tell the parent component to load more items. */
  @Output() public loadMoreItems?: EventEmitter<void> = new EventEmitter();

  // - Used for button navigation when no items added
  @Output() public noItemsAddedAction?: EventEmitter<ElementRef<HTMLElement>> =
    new EventEmitter();

  // - Used for tables that will contain rows that are immutable, and should not be modified directly by the data table component
  @Input() public readOnlyItems = false;

  // - Used for progress bar clicks
  @Output() public progressBarClick?: EventEmitter<{
    event: Event;
    item: any;
  }> = new EventEmitter();

  // - Capture clicks on table rows
  /** Row click handler */
  /** For Accessibility Purposes: Only use if row does not have nested clickable elements - https://dequeuniversity.com/rules/axe/4.3/nested-interactive?application=AxeChrome  */
  @Output() public rowClick?: EventEmitter<{
    event: Event;
    item: any;
  }> = new EventEmitter();
  /** Optionally determine if row is clickable */
  @Input() public isRowClickableFn?: (item: any) => boolean;

  // - View
  @ViewChild('noItemsAddedActionTrigger')
  public noItemsAddedActionTrigger: ElementRef<HTMLElement>;

  // - Local
  public collapsed = new Set<string>();

  public constructor(private translate: TranslateService) {}

  // Methods
  // - getters

  /**
   * Determine whether *all items* are selected.
   * Depends on: `items` having *all* children that are selectable in `isSelected` state.
   */
  public get allSelected(): boolean {
    if (!this.useSelection) return false;

    const allSelected = this.items.filter((item) => !item.hideSelect);
    if (!allSelected.length) return false; // every returns true on empty arrays, if no items are selectable we need to return false

    return allSelected.every((item) => item.isSelected);
  }

  /**
   * Determine whether to disable infinite scroll.
   * Depends on: `hasNoItems` (no items on the server)
   *           : `hasNoResults` (no search results for that term, but items exist)
   *           : `hasMoreItems` (more pages of items exist)
   *           : `isSorting` (currently sorting column, so don't load in more items)
   *           : `isLoading` (all ready loading items, so don't load anymore items)
   */
  public get disableInfiniteScroll() {
    return (
      this.hasNoItems ||
      this.hasNoResults ||
      !this.hasMoreItems ||
      this.isSorting ||
      this.isLoading
    );
  }

  /**
   * Determine whether *no items* are selected.
   * Depends on: `items` having *no* children in `isSelected` state.
   */
  public get noneSelected(): boolean {
    return this.useSelection
      ? !this.items.some((item) => item.isSelected)
      : true;
  }

  /**
   * Determine whether to set Select-All checkbox to 'indeterminate' state.
   * Depends on: `allSelected` *and* `noneSelected` are both false.
   */
  public get someSelected(): boolean {
    return this.useSelection ? !this.allSelected && !this.noneSelected : false;
  }

  /**
   * Determine whether to show pagination.
   * Depends on: `totalNumResults > numResultsPerPage` (more items to display than a single page)
   *           : `isLoading` (currently loading, so don't allow page to be changed)
   */
  public get showPagination() {
    return this.totalNumResults > this.numResultsPerPage && !this.isLoading;
  }

  /**
   * Determine whether any `Selected` props can possibly be true.
   * Depends on: `canSelect` being true and `items.length` being
   * greater than 0.
   */
  public get useSelection(): boolean {
    return this.canSelect && this.items?.length > 0;
  }

  /**
   * Determine whether table rows are clickable. Will return
   * true if the (rowClick) binding is set.
   */
  public get hasClickableRows(): boolean {
    return this.rowClick.observers.length > 0;
  }

  // Angular methods
  public ngOnInit(): void {
    // for sortable columns, fallback to `prop` if `sortName` not set
    this.columns = this.columns?.map((column) => {
      if (column.canSort && !column.sortName) {
        column.sortName = column.prop;
      }
      return column;
    });
    // if not set, default to the first column's sort name
    this.orderBy =
      this?.orderBy || (this.columns && this.columns[0]?.sortName) || '';

    this.noItemsCustomMessage ??= '';
    this.noSearchResultText ??= 'Core_NoResultsFor';
  }

  public ngOnChanges({
    columns,
    items,
    forceResetPaging,
  }: SimpleChanges): void {
    if (columns?.previousValue !== columns?.currentValue) {
      this.columns = columns.currentValue.filter(
        (column: DataColumn) => !column.hide
      );
    }
    if (items?.currentValue && this.collapseGroups) {
      this.collapseAllGroups();
    }
    if (forceResetPaging?.currentValue) {
      this.pageNum = 1;
      setTimeout(() => {
        this.resetPaging.emit();
      });
    }
  }

  public translateProperty(property: string, value: any) {
    return property === 'inputType'
      ? this.translate.instant(`Core_${value}`)
      : value;
  }

  /** Cycle through all items and collapse all groups */
  public collapseAllGroups() {
    this.items.forEach((item) => {
      if (item.parentId) {
        this.collapsed.add(item.parentId);
      }
    });
  }

  /** translate progressbar tooltip */
  public getTranslatedProgressTooltip(item: any, column: any) {
    return this.translate.instant('Core_ProgressBar_Tooltip', {
      percent: item[column.fromValueProp],
      total: item[column.toValueProp] + item[column.fromValueProp],
    });
  }

  /** disable progress bar tooltip */
  public disableProgressBarToolTip(column: any) {
    return column.disableTooltip || false;
  }

  // - public

  /**
   * Handle clicks on table rows
   *
   * NOTE: If items in a table row have their own click handlers, make sure they
   * use `event.stopPropogation()` to prevent the `rowClick` handler from firing.
   * @param event
   * @param item
   */
  public clickHandler(
    event: MouseEvent,
    item: any,
    itemClicked?: string,
    column?: any
  ): void {
    if (this.getIsClickable(item)) {
      this.rowClick.emit({ event, item });
    }
    if (itemClicked === 'progressBar' && column?.propActionOnBarClick) {
      this.progressBarClick.emit({ event, item });
    }
  }

  /**
   * Handle "clicks" on table rows for keyboard users
   *
   * @param event
   * @param item
   */
  public keydownHandler(
    event: KeyboardEvent,
    item: any,
    itemClicked?: string
  ): void {
    const { target, keyCode } = event;
    const isSpaceOrEnter = keyCode === 32 || keyCode === 13;
    const isTableRow = (target as Element).nodeName === 'TR';
    // Ignore keyboard events on child elements (TR is the cilckable element via `role` of `button`)
    if (this.getIsClickable(item) && isTableRow && isSpaceOrEnter) {
      // Prevent scrolling if user hits `space` key
      event.preventDefault();
      this.rowClick.emit({ event, item });
    }
    if (itemClicked === 'progressBar') {
      event.preventDefault();
      this.progressBarClick.emit({ event, item });
    }
  }

  /**
   * Determine whether an individual row is clickable
   *
   * @param item - The current row's item.
   */
  public getIsClickable(item: any): boolean {
    if (this.isRowClickableFn) {
      return this.hasClickableRows && this.isRowClickableFn(item);
    }
    return this.hasClickableRows;
  }

  /**
   * Determine whether column is descending or ascending.
   *
   * @param sortName Current column to sort on.
   */
  public getIsDescending(sortName: string) {
    // 'null' value creates the grayed-out arrows of columns not being sorted on.
    return sortName === this.orderBy ? this.isDescending : null;
  }

  // TODO: There should be a better way of adding the attribute to the parent TH.
  // Perhaps thSort should be an attribute directive instead of a component?
  /**
   * Update the value of aria-sort attribute.
   *
   * @param sortName Current column to check against isDescending.
   */
  public getAriaSort(sortName: string) {
    if (!sortName) {
      return 'none';
    }
    let state: string;
    switch (this.getIsDescending(sortName)) {
      case true:
        state = 'Descending';
        break;
      case false:
        state = 'Ascending';
        break;
      default:
        state = 'none';
    }
    return state;
  }

  /**
   * Get a custom menu config. Replaces `actions`-related props.
   *
   * @param item - The current row's item.
   * @param index - The current item's place in the result set.
   */
  public getMenuConfig(
    item: any,
    index: number
  ): Observable<readonly MenuViewModel[]> {
    return this.menuSettings?.getMenuConfig(item, index) || of([]);
  }

  /**
   * Tells parent to change pages.
   *
   * @param page The page to change to.
   */
  public onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  /**
   * Tells parent to change per page limit.
   *
   * @param perPage How many per page
   */
  public onPerPageChange(perPage: number) {
    this.perPageChange.emit(perPage);
  }

  /**
   * Tells parent to load more items.
   */
  public onScroll() {
    this.loadMoreItems.emit();
  }

  /**
   * Sets the `isSelected` property on all selected items.
   *
   * @param selected Whether to set all checkboxes as selected or unselected.
   */
  public selectAll(event: Event) {
    const isSelected = (<HTMLInputElement>event.target).checked;
    if (!this.readOnlyItems) {
      for (const i of this.items) {
        // if the item is supposed to be hidden, it should not be selecting it here.
        if (!i.hideSelect) {
          i.isSelected = isSelected;
        }
      }
    }
    this.bulkSelect.emit({ items: this.items, isSelected: isSelected });
  }

  /**
   * Sets the `isSelected` property on one selected item.
   *
   * @param item The item of concern.
   * @param selected Whether to set the item is selected or unselected.
   */
  public selectOne(item: any, event: Event) {
    const isSelected = (<HTMLInputElement>event.target).checked;
    const selectedItem = this.items.find(
      (i) => i[this.propTrackingKey] === item[this.propTrackingKey]
    );

    if (!this.readOnlyItems) {
      selectedItem.isSelected = isSelected;
    }

    this.select.emit({ item: selectedItem, isSelected: isSelected });
  }

  /**
   * Tells parent to update sort.
   *
   * @param sortName String representing column name.
   */
  public sort(sortName: string): void {
    this.updateSort.emit(sortName);
  }

  /**
   * Allows track-by on any property, or none.
   *
   * @param index Unused, but passed in by trackBy.
   * @param item Item being tracked.
   */
  public trackByIdentity(index: number, item: any): any {
    return this.propTrackingKey ? item[this.propTrackingKey] : item;
  }

  /**
   * Tells parent the no-items-at-all call-to-action button has been clicked.
   */
  public onNoItemsAddedAction() {
    this.noItemsAddedAction.emit(this.noItemsAddedActionTrigger);
  }

  public addRemoveCollapsed(action, id) {
    if (action === 'add') {
      this.collapsed.add(id);
    } else {
      this.collapsed.delete(id);
    }
  }

  // casting columns to any to avoid type error
  public asBarColumn = (column: DataColumn) => column as DataColumnProgressBar;
  public asDateColumn = (column: DataColumn) => column as DataColumnDate;
  // prettier-ignore
  public asHighlightColumn = (column: DataColumn) => column as DataColumnHighlight;
  public asListColumn = (column: DataColumn) => column as DataColumnList;
  public asPersonColumn = (column: DataColumn) => column as DataColumnPerson;

  public getColumns(item) {
    // only return the first column for groupedDescription
    return item.type === 'groupedDescription'
      ? [this.columns[0]]
      : this.columns;
  }
}
