<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!experienceGlobalAddForm"
    class="reactive-forms"
    [formGroup]="experienceGlobalAddForm"
    (ngSubmit)="onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="i18n.PositionFormCtrl_SaveExperience"
      [canCancel]="true"
      [modalHeaderTitle]="heading"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      bodyClasses="guts-p-full-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <!-- isCurrent       -->
        <div class="reactive-forms__control">
          <label for="isCurrent" class="reactive-forms__label-checkbox">
            <input
              id="isCurrent"
              df-checkbox
              [checked]="vm.isCurrent"
              type="checkbox"
              name="isCurrent"
              formControlName="isCurrent"
              data-dgat="experience-global-add-8b0"
            />
            <span class="guts-m-l-half">{{
              i18n.PositionFormCtrl_CurrentPosition
            }}</span></label
          >
        </div>

        <!-- Experience Type-->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="experienceType" [isRequired]="false">
            {{ i18n.Core_Type }}
          </dgx-reactive-forms-label>
          <dgx-select
            id="experienceType"
            class="full-width"
            trackBy="id"
            labelKey="id"
            [ariaLabel]="i18n.Core_ExperienceType"
            [options]="vm.experienceTypeOptions"
            [labelKey]="'inputSubType'"
            [selectedItem]="vm.experienceType"
            (selection)="onFormControlUpdate('experienceType', $event.id)"
            [placeholder]="i18n.PositionFormCtrl_SelectType"
            dgatInput="experience-global-add-117"
          >
          </dgx-select>
        </div>

        <!-- Title -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="title" [isRequired]="true">
            {{ i18n.Core_Title }}
          </dgx-reactive-forms-label>
          <input
            type="text"
            id="title"
            formControlName="title"
            [placeholder]="i18n.PositionFormCtrl_ExperienceTitle"
            (blur)="loadInferredSkills()"
            data-dgat="experience-global-add-0d1"
          />
          <dgx-validate-field
            class="block"
            *ngIf="
              experienceGlobalAddForm.get('title').touched &&
              experienceGlobalAddForm.get('title').invalid
            "
            [message]="i18n.MediaFormCtrl_TitleRequired"
          ></dgx-validate-field>
        </div>

        <!-- Description -->
        <div
          *ngIf="experienceGlobalAddForm.get('description')"
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label for="description">
            {{ i18n.Core_Description }}
          </dgx-reactive-forms-label>
          <textarea
            id="description"
            formControlName="description"
            [attr.aria-label]="i18n.Core_Description"
            [placeholder]="i18n.PositionFormCtrl_DescribeExperience"
            (blur)="loadInferredSkills()"
            data-dgat="experience-global-add-cfb"
          ></textarea>
        </div>

        <!-- Organization -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="orgName" [isRequired]="true">
            {{ i18n.PositionFormCtrl_CompanyOrg }}
          </dgx-reactive-forms-label>
          <input
            type="text"
            id="orgName"
            formControlName="orgName"
            [placeholder]="i18n.Core_Name"
            data-dgat="experience-global-add-c8e"
          />
          <dgx-validate-field
            class="block"
            *ngIf="
              experienceGlobalAddForm.get('orgName').touched &&
              experienceGlobalAddForm.get('orgName').invalid
            "
            [message]="
              'Core_FieldRequiredFormat'
                | translate: { fieldName: i18n.PositionFormCtrl_CompanyOrg }
            "
          ></dgx-validate-field>
        </div>
        <!-- Mentee-->
        <div
          *ngIf="
            experienceGlobalAddForm.get('experienceType').value ===
            experienceTypes.Mentorship
          "
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label for="menteeName">
            {{ i18n.Core_Mentee }}
          </dgx-reactive-forms-label>
          <input
            type="text"
            id="menteeName"
            formControlName="menteeName"
            [placeholder]="i18n.PositionFormCtrl_MenteeName"
            data-dgat="experience-global-add-9e0"
          />
        </div>

        <!--Mentor -->
        <div
          *ngIf="
            experienceGlobalAddForm.get('experienceType').value ===
            experienceTypes.Menteeship
          "
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label for="mentorName">
            {{ i18n.Core_Mentor }}
          </dgx-reactive-forms-label>
          <input
            type="text"
            id="mentorName"
            formControlName="mentorName"
            [placeholder]="i18n.PositionFormCtrl_MentorName"
            data-dgat="experience-global-add-023"
          />
        </div>

        <!-- Seniority        -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="level" [isRequired]="false">
            {{ i18n.PositionFormCtrl_Seniority }}
          </dgx-reactive-forms-label>
          <dgx-select
            id="level"
            class="full-width"
            trackBy="id"
            labelKey="id"
            [ariaLabel]="i18n.PositionFormCtrl_Seniority"
            [selectedItem]="vm.level"
            [options]="vm.levelOptions"
            (selection)="onFormControlUpdate('level', $event.id)"
            dgatInput="experience-global-add-610"
          >
          </dgx-select>
        </div>

        <!-- Date Range        -->
        <div class="reactive-forms__control">
          <dgx-date-range
            [formModel]="{
              startDate: vm.dateRangeForm.startDate,
              endDate: vm.dateRangeForm.endDate
            }"
            [isTouched]="markSubFormsAsTouched"
            [showEndDate]="!experienceGlobalAddForm.get('isCurrent').value"
            [startDateRequired]="true"
            [endDateRequired]="true"
          ></dgx-date-range>
        </div>

        <!-- Hours Per Week        -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="hoursPerWeek" [isRequired]="true">
            {{ i18n.PositionFormCtrl_HoursPerWeek }}
          </dgx-reactive-forms-label>
          <input
            type="text"
            id="hoursPerWeek"
            formControlName="hoursPerWeek"
            data-dgat="experience-global-add-f2a"
          />
          <dgx-validate-field
            class="block"
            *ngIf="
              experienceGlobalAddForm.get('hoursPerWeek').touched &&
              experienceGlobalAddForm.get('hoursPerWeek').invalid
            "
            [message]="getHoursPerWeekValidationMessage()"
          ></dgx-validate-field>
        </div>

        <!-- Skills -->
        <div class="reactive-forms__control">
          <dgx-tags-edit
            [hideHint]="true"
            [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
            [customTitle]="i18n.Core_Skills"
            [topTags]="vm.inferredSkills$ | async"
            [hideTopTags]="!(vm.inferredSkills$ | async).length"
            [hideTooltip]="false"
            [tooltipIcon]="'info'"
            [placeholder]="i18n.dgOrgInternalContent_SkillsPlaceholderText"
            [allowExistingOnly]="false"
            [tags]="experienceGlobalAddForm.get('skills').value || []"
            [showFullWidthLabel]="true"
            [useNewTagsDesign]="true"
            (tagsChange)="onFormControlUpdate('skills', $event)"
          ></dgx-tags-edit>
          <dgx-validate-field
            class="block"
            *ngIf="
              experienceGlobalAddForm.get('skills').touched &&
              experienceGlobalAddForm.get('skills').invalid
            "
            [message]="i18n.dgOrgInternalContent_SkillsMaxError"
          ></dgx-validate-field>
        </div>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer"></ng-container>
    </dgx-modal>
  </form>
</ng-container>
