<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  bodyStyle="padding: 24px"
  *ngIf="viewModel | async as vm"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <span [textContent]="i18n.dgTagRating_RequestPeerRating"></span>
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <!--Peer search-->
    <dgx-tag-peer-search
      (selectedChanged)="selectedChanged($event)"
      [peerProfileKeysToExclude]="peerProfileKeysToExclude"
    ></dgx-tag-peer-search>

    <!--Peer count warning-->
    <df-local-notification
      class="guts-m-t-2 block"
      *ngIf="vm.showPeerCountWarning"
      [type]="NotificationType.warning"
      [text]="vm.peerCountWarning"
      size="small"
    ></df-local-notification>

    <!--Message-->
    <div class="l_flexbar guts-m-t-2">
      <label for="comment" [textContent]="i18n.Core_Message"></label>
      <span
        class="l_flex--align-right par par--light_a par--small"
        [textContent]="i18n.Core_Optional"
      ></span>
    </div>
    <textarea
      id="comment"
      [(ngModel)]="comment"
      [attr.maxlength]="commentMaxLength"
      [placeholder]="i18n.Core_OptionalMessage_Request"
      data-dgat="tag-peer-search-da1"
    ></textarea>

    <!--Cancel requests-->
    <div class="l_flex l_flex-top guts-m-t-2">
      <div class="l_flex-grow guts-p-r-2">
        <label
          for="cancelPendingRequest"
          [textContent]="i18n.dgTagRating_CancelPeerRequests"
        ></label>
        <div
          class="par par--light_a par--small"
          [textContent]="i18n.dgTagRating_CancelPeerRequestsDesc"
        ></div>
      </div>
      <div class="l_flex-shrink">
        <dgx-toggle-switch
          id="cancelPendingRequest"
          (toggled)="toggleCancelRequests($event)"
          [isChecked]="cancelPendingRequest"
        ></dgx-toggle-switch>
      </div>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      [disabled]="vm.disableForm"
      [class.is_disabled]="vm.disableForm"
      [textContent]="i18n.RequestRating_SendRequest"
      (click)="requestRatings($event)"
      data-dgat="peer-rating-overview-modal-e22"
    ></button>
  </ng-container>
</dgx-modal>
