import { Component, OnInit } from '@angular/core';

import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';

import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import { SessionParameters } from '@app/inputs/inputs-api.model';
import { SessionedInputType } from '@app/shared/models/core-api.model';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

/** Parameters passed to drive the {@link SelectFieldComponent} properties and its child {@link SelectComponent} inputs */
export interface InputSessionFieldParams {
  /** True when the input session is related to an integration */
  isFromIntegrations?: boolean;
  inputType: SessionedInputType;
}

@Component({
  selector: 'dgx-input-session-field',
  templateUrl: './input-session-field.component.html',
  providers: [SubscriptionManagerService],
})
export class InputSessionFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static REGISTERED_FIELD_TYPE = 'dgx-input-session-field';
  private static defaultSession: SessionParameters = {
    startDateTime: '',
    isRegistrationUrlInputUrl: false,
    isRegistrationAvailable: false,
  };

  public session = InputSessionFieldComponent.defaultSession;

  constructor(private subscriptionManager: SubscriptionManagerService) {
    super();
  }

  public get params(): InputSessionFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  public ngOnInit() {
    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        distinctUntilChanged(),
        this.subscriptionManager.takeUntilDestroyed()
      )
      .subscribe(() => {
        this.updateSessionFromControl();
      });
  }

  public handleSessionChange(session: SessionParameters) {
    this.session = session;
    this.formControl.setValue(session);
    this.formControl.markAsDirty();
  }

  public handleValidateChange(isValid: boolean) {
    this.formControl.setErrors(isValid ? null : { invalid: true });
  }

  public handleFocusout() {
    this.formControl.markAsTouched();
  }

  private updateSessionFromControl() {
    this.session = this.formControl.value as SessionParameters;
  }
}
