import { Injectable } from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  ExperienceMappingToAPI,
  ExperienceModel,
} from '@app/user-content/user-input-v2/inputs/experience/experience.model';
import { DatePipe } from '@angular/common';

@Injectable()
export class ExperienceMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(source: ExperienceModel): ExperienceMappingToAPI {
    const {
      isCurrent,
      experienceType,
      title,
      orgName,
      hoursPerWeek,
      level,
      description,
      skills,
      menteeName,
      mentorName,
      dateRangeForm,
    } = source;

    return {
      title,
      startDate: this.datePipe.transform(dateRangeForm.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(dateRangeForm.endDate, 'yyyy-MM-dd'),
      hoursPerWeek,
      isCurrent,
      level,
      inputSubType: experienceType,
      organizationName: orgName,
      inputType: 'Position',
      tags: skills,
      description,
      inputSubTypePeerName: menteeName || mentorName || null,
    };
  }

  /**
   * Convert the inferred string[] into a partial TagsApi.Tag object.
   * BE is only passing name, this is needed for the tag-rendered. This is later mapped back to name as the BE needs
   * at least name to construct a full Tag object once received.
   * @param skills
   * @returns
   */
  public getMappedInferredSkills(skills: string[]): TagsApi.Tag[] {
    if (!skills?.length) return [];
    return skills.map(
      (skillName) => ({ title: skillName, name: skillName } as TagsApi.Tag)
    );
  }
}
