import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { Mentorship } from '@app/mentoring/mentoring-api.model';
import {
  MentorshipStatusEnum,
  MentorshipTypeEnum,
} from '@app/mentoring/mentoring.enums';
import { MentorshipType } from '@app/mentoring/mentoring-api.model';
import { MentoringTrackingService } from '@app/mentoring/services/mentoring-tracking.service';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { OpportunityFlagsService } from '@app/opportunities/services/opportunity-flags.service';
import { AuthService } from '@app/shared/services/auth.service';
import { UserService } from '@app/user/services/user.service';
import { UserProfileSummary } from '@app/user/user-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { NotificationType } from '@lib/fresco';

@Component({
  selector: 'dgx-more-details-modal',
  templateUrl: './more-details-modal.component.html',
  styleUrls: ['./more-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreDetailsMenteeModalComponent implements OnInit {
  public i18n = this.translate.instant([
    'Core_Decline',
    'Core_Accept',
    'PersonalSkillView_FocusSkills',
    'Core_Matched_Skills',
    'Core_Skills',
    'Opportunities_MoreDetailsMentorshipModal_Info',
    'Opportunities_MoreDetailsMentorshipModal_Warning',
    'Mentoring_FeedbackReceived',
    'Mentoring_FeedbackGiven',
  ]);

  public readonly NotificationType = NotificationType;

  public isLoading: boolean = true;
  public hasRequestNote: boolean;
  public hasMatchedTags: boolean;
  public hasFocusTags: boolean;
  public hasTags: boolean;
  public matchedTags: Skill[];
  public focusTags: Skill[];
  public tags: Skill[];
  public useCompleteMentorshipEnhancements =
    this.opportunityFlagsService.useCompleteMentorshipEnhancements;
  public authUser: AuthUser;
  public toMentorFeedback: string;
  public toMentorFeedbackHeader: string;
  public peer: UserProfileSummary;
  public toMenteeFeedback: string;
  public toMenteeFeedbackHeader: string;
  public showActionButtons: boolean = false;

  @Input() public mentorship: Mentorship;
  @Input() public mentorshipType: MentorshipType;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private opportunityFlagsService: OpportunityFlagsService,
    private mentoringTrackingService: MentoringTrackingService,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.mentoringTrackingService.moreDetailsOpened(
      this.mentorship,
      this.mentorshipType
    );

    this.authUser = this.authService.authUser;
    this.hasRequestNote = !!this.mentorship.mentorshipRequestNote;

    switch (this.mentorshipType) {
      case MentorshipTypeEnum.Mentor:
        this.peer = this.mentorship.mentor;
        this.toMentorFeedbackHeader = this.i18n.Mentoring_FeedbackGiven;
        this.toMenteeFeedbackHeader = this.i18n.Mentoring_FeedbackReceived;
        break;
      case MentorshipTypeEnum.Mentee:
        this.peer = this.mentorship.mentee;
        this.toMentorFeedbackHeader = this.i18n.Mentoring_FeedbackReceived;
        this.toMenteeFeedbackHeader = this.i18n.Mentoring_FeedbackGiven;
        this.showActionButtons =
          this.mentorship.mentorshipStatusName ===
          MentorshipStatusEnum.Requested;
        break;
    }

    if (this.useCompleteMentorshipEnhancements) {
      this.toMentorFeedback = this.mentorship.toMentorFeedbackMessage;
      this.toMenteeFeedback = this.mentorship.toMenteeFeedbackMessage;
    }

    this.loadUserSkills();
    this.cdr.markForCheck();
  }

  public onAccept() {
    return this.activeModal.close(true);
  }

  public onDecline() {
    return this.activeModal.close(false);
  }

  private loadUserSkills() {
    this.userService
      .getUserSkillsWithSignals(this.peer)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(({ userOtherSkills, userFocusSkills }) => {
        this.tags = userOtherSkills;
        this.focusTags = userFocusSkills;

        this.hasTags = !!this.tags.length;
        this.hasFocusTags = !!this.focusTags.length;
        this.matchedTags = userOtherSkills.filter((peerTags) =>
          this.authUser?.viewerInterests?.some(
            (userTags) => peerTags.name === userTags.name
          )
        );
        this.hasMatchedTags = !!this.matchedTags.length;

        this.cdr.markForCheck();
      });
  }
}
