import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TrackingEventArgs } from '@app/shared/services/tracking.model';
import { UserInterest } from '@app/user/user-api.model';
import { AcceptMenteeModel } from '../components/modals/mentee-modals/accept-mentee-modal/accept-mentee-modal.component';
import { Mentorship, MentorshipType } from '../mentoring-api.model';
import { MentorshipTypeEnum } from '../mentoring.enums';

export interface MentorshipTrackingProps {
  mentorshipRelation: MentorshipType;
  mentorshipUserId?: number | number;
  mentorshipSkillsCount?: number;
  mentorshipSkills?: string[];
  focusSkillsCount?: number; // confusing naming, actual: the focus skills of a mentor (we wont know a mentees focus skills atm)
  mentorshipFocusSkills?: string[]; // confusing naming, actual: the matching focus skills between mentor/mentee
}

@Injectable({
  providedIn: 'root',
})
export class MentoringTrackingService {
  constructor(
    private authService: AuthService,
    private trackerService: TrackerService
  ) {}

  /**
   * Tracking for viewing the mentorship page
   * @menteesCount count of the current mentees
   * @mentorsCount count of the current mentors
   */
  public mentoringImpressed(menteesCount: number, mentorsCount: number): void {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentoring Viewed',
      properties: {
        menteesCount,
        mentorsCount,
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for when the user opens request mentorship modal
   * @param mentorUserId the userId of the mentor
   * @param focusSkillsCount matching focus skill count
   * @param skillsAddedCount number of skills a user has if any
   */
  public mentorshipRequestInitiated(
    mentorUserId: string | number,
    focusSkillsCount: number, // looks like this will be undefined always TODO: We should add focus skills to the mentor API response
    skillsAddedCount: number
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentor Request Initiated',
      properties: {
        mentorUserId,
        focusSkillsCount,
        skillsAddedCount,
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   *  Tracking for when a mentorship request is sent
   * @param mentorshipSkillsCount
   * @param mentorSkills
   * @param hasDescription
   * @param mentorUserId
   */
  public mentorshipRequested(
    mentorshipSkillsCount: number,
    mentorSkills: string[],
    hasDescription: boolean,
    mentorUserId: string | number
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentor Requested',
      properties: {
        mentorshipSkillsCount,
        mentorSkills,
        hasDescription,
        mentorUserId,
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for when a mentor accepts a mentee
   *
   * @param mentorship the updated mentorship from accepting a mentorship
   * @param modalResponse all the response data from the accept modal
   */
  public mentorshipAccepted(
    mentorship: Mentorship,
    modalResponse: AcceptMenteeModel
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentee Accepted',
      properties: {
        connectionType: modalResponse.contact,
        otherConnectionType: modalResponse.description,
        menteeUserId: mentorship.mentee.userProfileKey,
        ...this.getMentorshipTrackingProps(
          mentorship,
          MentorshipTypeEnum.Mentee
        ),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for a user archived a mentorship
   * This will hide it from the UI, but remain in the DB since
   * the archived state was not implemented fully on the FE.
   *
   * @mentorship the current mentorship
   * @hasDescription if the mentor declined with a note
   */
  public mentorshipArchived(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Archived',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for a mentor declines a mentorship request
   *
   * @mentorship the current mentorship
   * @hasDescription if the mentor declined with a note
   */
  public mentorshipDeclined(mentorship: Mentorship, hasDescription: boolean) {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentee Declined',
      properties: {
        hasDescription,
        menteeUserId: mentorship.mentee.userProfileKey,
        ...this.getMentorshipTrackingProps(
          mentorship,
          MentorshipTypeEnum.Mentee
        ),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for completing a mentorship
   * Can be from either mentee or mentor
   *
   * @param mentorship the current mentorship
   * @param mentorshipType
   */
  public mentorshipCompleted(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Completed',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for opening the more details menu option in either mentorship tables
   *
   * @param mentorship the current mentorship
   * @param mentorshipType
   */
  public moreDetailsOpened(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship More Details Opened',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * When a user requests a peer rating from a completed mentorship
   * @param mentorship
   * @param mentorshipType
   * @param modal the modal response, cant type it here because of circular dependencies!
   */
  public peerRatingRequested(
    mentorship: Mentorship,
    mentorshipType: MentorshipType,
    modal /*PeerRatingModal*/
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Peer Rating Requested',
      properties: {
        tagsCount: modal.tagIds.length,
        hasComment: !!modal.comment,
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * When a user submitted feedback to the other on a completed mentorship.
   *
   * @param mentorship
   * @param mentorshipType
   */
  public feedbackShared(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Feedback Shared',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * When a user requests feedback from a completed mentorship
   *
   * @param mentorship
   * @param mentorshipType
   */
  public feedbackRequested(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Feedback Requested',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for opening the review mentorship modal
   *
   * @param mentorship
   * @param mentorshipType
   */
  public reviewModalOpened(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Mentorship Review Modal Opened',
      properties: {
        ...this.getMentorshipTrackingProps(mentorship, mentorshipType),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  /**
   * Tracking for updating the users mentorship status
   *
   * @param mentorship
   * @param isMentoring
   */
  public mentorshipPreferenceUpdated(
    mentorship: Mentorship,
    isMentoring: boolean
  ) {
    const trackingData: TrackingEventArgs = {
      action: 'Opportunity Mentorship Preference Updated',
      properties: {
        isMentor: isMentoring,
        ...this.getMentorshipTrackingProps(
          mentorship,
          MentorshipTypeEnum.Mentee
        ),
      },
    };
    this.trackerService.trackEventData(trackingData);
  }

  private getMentorshipTrackingProps(
    mentorship: Mentorship,
    viewerRelation: MentorshipType
  ): MentorshipTrackingProps {
    switch (viewerRelation) {
      case MentorshipTypeEnum.Mentee:
        return {
          mentorshipRelation: MentorshipTypeEnum.Mentee,
          mentorshipUserId: mentorship.menteeUserProfileKey,
          mentorshipSkillsCount: mentorship.mentee.tagNames?.length || 0,
          mentorshipSkills: mentorship.mentee.tagNames,
          // mentorshipFocusSkills - Mentorship doesn't contain focus skills
          // focusSkillsCount - Mentorship doesn't contain focus skills
        };

      case MentorshipTypeEnum.Mentor:
        const authUser = this.authService.authUser;
        const focusSkillNames = this.getFocusSkillsNames(
          authUser.viewerInterests
        );
        return {
          mentorshipRelation: MentorshipTypeEnum.Mentor,
          mentorshipUserId: mentorship.mentorUserProfileKey,
          mentorshipSkillsCount: mentorship.mentor.tagNames?.length || 0,
          mentorshipSkills: mentorship.mentor.tagNames,
          mentorshipFocusSkills: this.getMatchingFocusSkills(
            focusSkillNames,
            mentorship.mentor.tagNames
          ),
          focusSkillsCount: focusSkillNames.length,
        };
    }
  }

  /**
   * Return an array of matching skill strings
   * @param baseSkillSet the first set of skills to match
   * @param matchingSkillSet the array of skills to match with
   */
  private getMatchingFocusSkills(
    baseSkillSet: string[],
    matchingSkillSet: string[]
  ): string[] {
    return baseSkillSet.filter((name) => matchingSkillSet.includes(name));
  }

  /**
   * Get an array with the users focus skills as strings
   * @param viewerInterests
   */
  private getFocusSkillsNames(viewerInterests: UserInterest[]): string[] {
    const tagNames = [];
    for (let i = viewerInterests.length - 1; i >= 0; i--) {
      if (!!viewerInterests[i].isFocused) {
        tagNames.push(viewerInterests[i].name);
      }
    }
    return tagNames;
  }
}
