<form class="__input-container">
  <div
    #input
    [id]="isReadOnly ? '' : 'comment-textarea-for-mentions'"
    (input)="onInput($event)"
    dgxMention
    dgxPasteAsPlainText
    [mentionEvent]="mentionEvent"
    [groupId]="groupId"
    [groupPrivacy]="groupPrivacy"
    [attr.contenteditable]="!(isReadOnly || isSubmitting)"
    class="__input contenteditable markdown"
    [attr.placeholder]="i18n.dgComments_ShareSomethingAbout"
    [attr.data-dgat]="dataDgat ? dataDgat : 'comment-field-74c'"
  ></div>
  <button
    *ngIf="showControls"
    type="submit"
    class="__submit"
    [disabled]="value.length === 0 || isSubmitting"
    data-dgat="comment-field-f63"
  >
    <df-icon *ngIf="!isSubmitting" icon="arrow-open-right"></df-icon>
    <df-spinner
      *ngIf="isSubmitting"
      class="guts-m-r-1"
      spinnerContext="button"
    ></df-spinner>
  </button>
</form>
