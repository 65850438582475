<div
  class="guts-m-b-2 m-guts-m-h-1 user-recommendation-header-container"
  data-dgprop-item-clicked="Recommendation Header"
>
  <div class="l_flexbar">
    <div
      class="guts-m-r-1 required-icon new-assignments l_flex-top"
      [ngClass]="{
        'required-icon--warning': isDueSoon,
        'required-icon--error': isOverdue,
        'required-icon--success': isComplete,
        'required-icon--default': !isDueSoon && !isOverdue && !isComplete
      }"
      *ngIf="(!isRequired || isComplete) && item.isAssignment"
    >
      <df-icon
        icon="{{ !isComplete ? 'flag' : 'checkmark' }}"
        [ngClass]="{
          'color-warning-dark': isDueSoon,
          'color-error-dark': isOverdue,
          'color-success-dark': isComplete
        }"
      ></df-icon>
    </div>

    <div
      class="guts-m-r-1 required-icon new-assignments"
      *ngIf="isRequired"
      [ngClass]="{
        'required-icon--warning': isDueSoon,
        'required-icon--error': isOverdue,
        'required-icon--success': isComplete,
        'required-icon--default': !isDueSoon && !isOverdue && !isComplete
      }"
    >
      <df-icon
        icon="{{ !isComplete ? 'flag' : 'checkmark' }}"
        [ngClass]="{
          'color-warning-dark': isDueSoon,
          'color-error-dark': isOverdue,
          'color-success-dark': isComplete
        }"
      ></df-icon>
    </div>
    <div>
      <p
        *ngIf="maxLines && translatedString"
        class="par par--small par--light"
        [ngClass]="{ 'guts-p-b-0': !!item.comment }"
        [dgxLineClampHtml]="translatedString"
        [clampLinesMax]="maxLines"
        (clamped)="onTrim($event)"
        [ngbTooltip]="tooltipContent"
        [disableTooltip]="disableTooltip"
      ></p>
      <p
        *ngIf="!maxLines && translatedString"
        class="par par--small par--light"
        [ngClass]="{ 'guts-p-b-0': !!item.comment }"
        [innerHTML]="translatedString"
      ></p>
      <div
        *ngIf="showComment && item.comment"
        class="par par--small par--light"
      >
        {{ item.comment }}
      </div>
      <ng-template #tooltipContent>
        <div
          *ngIf="translatedString"
          class="tooltip-content"
          [innerHTML]="translatedString"
        ></div>
      </ng-template>
    </div>
  </div>
</div>
