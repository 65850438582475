<!-- Shared with Degreed button -->
<dgx-modal-header (dismiss)="dismiss()">{{ header }}</dgx-modal-header>
<ngb-accordion>
  <ngb-panel
    *ngFor="let duplicate of duplicates"
    class="panel--simple border-bottom guts-m-h-2"
    data-dgat="contentCatalogDuplicatesModal-548"
  >
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="l_flexbar guts-p-h-0">
        <a
          href="{{ duplicate.url }}"
          target="_blank"
          class="block"
          data-dgat="contentCatalogDuplicatesModal-d80"
        >
          <dgx-image-layout
            format="list"
            [imageSrc]="duplicate?.imageUrl"
            [resourceId]="duplicate._ContentId || duplicate.inputId"
            [resourceType]="duplicate._ContentType || duplicate.inputType"
            [videoUrl]="duplicate.inputType === 'Video' ? duplicate.url : ''"
            [providerImage]="duplicate.providerImageInfo?.svg"
          >
          </dgx-image-layout>
        </a>
        <div class="l_flex-grow guts-p-l-1-half">
          <div class="par par--small">
            <a
              href="{{ duplicate.url }}"
              target="_blank"
              class="font-bold"
              data-dgat="contentCatalogDuplicatesModal-9e7"
              >{{ duplicate.title }}</a
            >
          </div>
          <div class="par par--small par--light">
            <span *ngIf="duplicate.groupIds?.length > 0">{{
              groupsText(duplicate.groupIds)
            }}</span
            ><span
              *ngIf="
                duplicate.groupIds?.length > 0 && duplicate.tags?.length > 0
              "
              >,
            </span>
            <span *ngIf="duplicate.tags?.length > 0">{{
              topicsText(duplicate.tags)
            }}</span>
          </div>
        </div>
        <button ngbPanelToggle data-dgat="content-catalog-duplicates-29a">
          <df-icon
            [icon]="opened ? 'chevron-up' : 'chevron-down'"
            class="color-ebony-a61 header-icon guts-p-r-2 panel__dropdown-icon"
            size="medium"
          ></df-icon>
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="guts-p-r-4 guts-p-b-half guts-m-l-7 m-guts-m-r-1">
        <div *ngIf="duplicate.summary?.length > 0">
          <div class="par par--small font-bold">
            {{ i18n.OrgInternalContentModalCtrl_DuplicatesDescriptionLabel }}
          </div>
          <div class="par par--small par--light">{{ duplicate.summary }}</div>
        </div>
        <div *ngIf="duplicate.groupIds?.length > 0" class="guts-m-b-1">
          <div class="par par--small font-bold">
            {{ i18n.OrgInternalContentModalCtrl_DuplicatesGroupsLabel }}
          </div>
          <span
            *ngFor="let group of duplicate.groupIds; let last = last"
            class="par par--small par--light"
            data-dgat="contentCatalogDuplicatesModal-3d3"
          >
            {{ group.name }}{{ last ? '' : ', ' }}
          </span>
        </div>
        <div *ngIf="duplicate.tags?.length > 0" class="guts-m-b-1">
          <div class="par par--small font-bold">
            {{ i18n.OrgInternalContentModalCtrl_DuplicatesTopicsLabel }}
          </div>
          <span
            *ngFor="let topic of duplicate.tags.split(','); let last = last"
            class="par par--small par--light"
            data-dgat="contentCatalogDuplicatesModal-e0c"
          >
            <a
              [href]="getSearchURL(topic)"
              data-dgat="contentCatalogDuplicatesModal-665"
              >#{{ topic }}</a
            >{{ last ? '' : ', ' }}
          </span>
        </div>

        <button
          *ngIf="addToBin"
          (click)="itemAdded(duplicate)"
          class="guts-m-b-1"
          df-button
          dfButtonType="passive"
          data-dgat="contentCatalogDuplicatesModal-643"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="plus"></df-icon>
            <div>{{ i18n.Pathways_HoldForLater }}</div>
          </div>
        </button>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
