import { Inject, Injectable } from '@angular/core';
import { LDClientSDK } from '@app/shared/services/ldclientsdk.token';
import { LDClient } from 'launchdarkly-js-client-sdk';

/**
 * This service exposes tag related LD flags via a cache to avoid
 * excessive requests to the LD service
 */
@Injectable({
  providedIn: 'root',
})
export class TagFlagsService {
  constructor(@Inject(LDClientSDK) public ldClientSDK: LDClient) {}

  /** LD flag for displaying peer ratings */
  public get showPeerRatings() {
    return this.getFlag('peer-ratings-phase-1', false);
  }

  /** LD flag for displaying checkpoints */
  public get showCheckpoints() {
    return this.getFlag('degreed-checkpoints', false);
  }

  private getFlag(ldFlag: string, defaultValue: boolean): boolean {
    // Get the LD flag via the client API if not already cached
    if (this[ldFlag] === undefined) {
      return (this[ldFlag] = this.ldClientSDK.variation(ldFlag, defaultValue));
    }
    // Else return the cached value
    return this[ldFlag];
  }
}
