import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { ColorService } from '@app/shared/services/color.service';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { loadWith } from '@dg/shared-rxjs';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CropperCoordinates } from '../image-cropper-modal/image-cropper-modal.model';
import { ImageCropperService } from '../image-cropper-modal/services/image-cropper.service';
import {
  DgxUploadErrorMessages,
  DgxUploadSectionLabels,
} from './upload-section.model';
import {
  getFileExtension,
  isCompletionEvent,
  isProgressEvent,
  isValidFileExtension,
  isValidFileSize,
  Uploader,
} from '../uploader';
import {
  FileUploadSetting,
  ImagePosition,
  VideoUploadSettings,
} from '../uploader-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { ImageSourceSet } from '@app/thumbnails/thumbnail.model';
import { NotificationType } from '@lib/fresco';
import { CHUploadService } from '@app/content-hosting/data-access/services/upload.service';

interface PreviousImageData {
  imageUrl: string;
  cropperCoordinates: CropperCoordinates | null;
  selectedFile: File | null;
  croppedDisplayImage?: string;
  position?: ImagePosition;
  altText?: string;
}

@Component({
  selector: 'dgx-upload-section',
  templateUrl: './upload-section.component.html',
  styleUrls: ['./upload-section.component.scss'],
})
export class UploadSectionComponent
  extends SubscriberBaseDirective
  implements OnInit, OnChanges
{
  /** Disable uploads if the user is editing content, but they don't have content upload permissions */
  @Input() public shouldDisableContentUploader?: boolean;
  /** The type of input that a file is being uploaded for */
  @Input() public inputType: InputType;
  @Input() public setFocusManually?: boolean = false;
  /** If the uploader will be used to upload an image, pass the current image url here. Can be a raw URL to be converted to blob resource URL. */
  @Input() public imageUrl?: string;
  /** FileInfo is used to display file info in the ui when there is no imageUrl */
  @Input() public fileInfo?: File;
  /** When true, the URL will be interpreted as an image on the current domain. When false, will be transformed to a Blob URL. Default: false
   * TODO: remove once  is implemented
   */
  @Input() public isHostImageUrl?: boolean = false;
  /** Custom adapter to handle API requests for upload limits and saving the file. Required */
  @Input() public uploadAdapter: Uploader<unknown>;
  /** Override default labels (pre-translated), see @{DgxImageUploadSectionLabels} for options. */
  @Input() public labels?: DgxUploadSectionLabels;
  /** Override default error messages, see @{DgxUploadErrorMessages} for options. */
  @Input() public errorMessages?: Partial<DgxUploadErrorMessages>;
  /** User saved alt text for the image. */
  @Input() public imageAltText?: string;
  /** Optional: hides the alt text input in the cropper modal. */
  @Input() public hideAltTextInput?: string;
  /** Override the icon shown when the uploader is empty. This icon should exist in `df-icon`. Default: "image-plus" */
  @Input() public icon?: string = 'image-plus';
  /** aspect ratio used for the cropper. Required when useCropper is true */
  @Input() public aspectRatio?: number;
  /** When true, the aspect ratio will be unrestrained */
  @Input() public maintainAspectRatio?: boolean = true;
  /** secondary aspect ratio used for the cropper. Optional when useCropper is true */
  @Input() public secondaryAspectRatio?: number;
  /** When true, the cropper will show after an image is selected. Default: false */
  @Input() public useCropper?: boolean = false;
  /** Existing cropper coordinates for the image, if available */
  @Input() public cropperCoordinates?: CropperCoordinates;
  /**
   * Temporary resolution to an issue we had using this wrapped in formly, remove when resolved
   * See PD-65347 for more information.
   *
   *  @deprecated
   */
  @Input() public useBrowserReplace?: boolean;
  /**
   * Set true when the image passed in is a fallback image, this will show the upload-section in the empty state
   */
  @Input() public isImageUrlFallbackImage?: boolean = false;
  /** Allow fileUpload settings to be passed in */
  @Input() public fileRestrictions?: FileUploadSetting | VideoUploadSettings;
  /** Display the selector to choose image position (in the current context) */
  @Input() public showImagePosition?: boolean = false;
  /** Current image position, if any is set */
  @Input() public imagePosition?: ImagePosition;
  /** Helper text for the cropper modal, if replacing the default */
  @Input() public modalInstructions?: string;
  /** Override for showing the replace button, in some forms, eg the badge form, we don't want to be able to replace the image  */
  @Input() public showReplaceButton?: boolean = true;
  /** Override for showing the delete button, in some forms, eg the article catalog form we want to be able to delete upload  */
  @Input() public showDeleteButton?: boolean = false;
  /** Display the documentation link for files */
  @Input() public displayfileDocumentation?: boolean = false;
  /** Emits a `File` when a file is selected, before the file is uploaded. */
  @Output() public fileChangedEvent: EventEmitter<File> = new EventEmitter();
  /** Emits the API response when a file is successfully saved.  */
  @Output()
  public uploadSuccessEvent: EventEmitter<any> = new EventEmitter();
  /** Emits when the edit button is clicked. This button will only be available if the cropper is in use. */
  @Output() public editEvent: EventEmitter<void> = new EventEmitter();
  /** Emits when the delete button is clicked. */
  @Output() public deleteEvent: EventEmitter<void> = new EventEmitter();
  /** Emits when the retry button is clicked. */
  @Output() public retryEvent: EventEmitter<void> = new EventEmitter();
  /** Emits when there is an error uploading the file. */
  @Output() public errorEvent: EventEmitter<string> = new EventEmitter();
  /** When uploading an image, this will emit the parsed imageURl passed in { @see ResourceImage }  */
  @Output() public imageParsedEvent = new EventEmitter<ResourceImage>();
  @Output() public isCropperOpenChange = new EventEmitter<Boolean>();
  @ViewChild('inputRef') public inputRef: ElementRef;
  public notificationType: NotificationType;
  public localCropperCoordinates?: CropperCoordinates;
  public altText?: string;
  public maxSizeMB: number = 0;
  public allowedFileTypes: string[] = [];
  public isFocused: boolean = false;
  public isSettingsLoading: boolean = true;
  public isLoading: boolean = false;
  public errorMessage: string = null;
  public selectedFile: File;
  /** keep track of the last image url and crop coordinates so we can restore it if necessary */
  public prevImageData: PreviousImageData | null;
  public thumbnailImage: string;
  public progress: number;
  public canCancel: boolean;
  public fileToReplace: File;
  public i18n = this.translateService.instant([
    'Core_Edit',
    'Core_Delete',
    'Core_Retry',
    'Core_FileTypes_FirstOrOnly',
    'Core_FileTypes_Middle',
    'Core_FileTypes_OrLast',
    'dgImageUpload_ImagePreview',
    'dgImageUpload_SectionDragAndDrop',
    'dgImageUpload_SectionRestrictions',
    'dgImageUpload_BadgeFileTypeError',
    'ImageEditSvc_ImageUploadError',
    'dgFileUploadButton_UploadErrorMessage',
    'dgImageEdit_ChangePhoto',
    'Core_Replace',
    'Core_Cancel',
    'dgImageEdit_DocumentationLabel',
    'dgFileUploadButton_ViewFileDocumentation',
  ]);
  public defaultLabels: DgxUploadSectionLabels = {
    header: this.i18n.dgImageUpload_SectionDragAndDrop,
    deleteButton: this.i18n.Core_Delete,
    editButton: this.i18n.Core_Edit,
    retryButton: this.i18n.Core_Retry,
    replaceButton: this.i18n.Core_Replace,
  };
  public readonly defaultErrorMessages = {
    invalidFileType: this.i18n.dgImageUpload_BadgeFileTypeError,
    imgUploadError: this.i18n.ImageEditSvc_ImageUploadError,
    uploadError: this.i18n.dgFileUploadButton_UploadErrorMessage,
  };
  public progressCircleInProgress = this.colorService.getColor('pending');
  public progressCircleTrailColor = this.colorService.getColor('ebony-a08');
  public readonly imageDocumentationLink: string =
    this.webEnvironmentService.getZendeskUrl('/articles/4913873996572');
  public readonly fileDocumentationLink: string =
    this.webEnvironmentService.getZendeskUrl('articles/8239387842076');
  public storageLimitWarning: string;
  // This is separate from the errorMessage property in order to distinguish
  // this error from the errorMessages that are retrieved from the renderer
  public storageLimitError: string;

  private nonVideoFileRestrictions: FileUploadSetting;

  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private imageCropperService: ImageCropperService,
    private resourceImageService: ResourceImageService,
    private thumbnailService: ThumbnailService,
    private colorService: ColorService,
    private webEnvironmentService: WebEnvironmentService,
    private chUploadService: CHUploadService
  ) {
    super();
  }

  public get hasData() {
    return !!this.imageUrl || !!this.selectedFile;
  }

  public get isUploadingImage() {
    return !!this.aspectRatio;
  }

  public get showEditButton() {
    return (
      this.useCropper &&
      this.imageUrl &&
      (this.imageUrl.startsWith('data') || // this is an image that just got selected.
        this.thumbnailService.isClientUploadedImg(this.imageUrl))
    );
  }

  public ngOnInit(): void {
    this.setLabels();
    this.checkComponentSetup();
    this.setErrorMessages();

    // Set the selected file for displaying fileInfo when there is no imageUrl
    if (this.fileInfo) {
      this.selectedFile = this.fileInfo;
    }

    if (this.inputType !== 'Video') {
      this.nonVideoFileRestrictions = this
        .fileRestrictions as FileUploadSetting;
    }
    this.setFileRestrictions();

    if (this.setFocusManually) {
      setTimeout(() => {
        this.setFocusToUploadElement();
      });
    }
  }

  public ngOnChanges({
    imageUrl,
    imageAltText,
    labels,
    isImageUrlFallbackImage,
    cropperCoordinates,
    fileRestrictions,
  }: SimpleChanges) {
    if (fileRestrictions?.currentValue?.usedDataPercent) {
      this.handleStorageLimitMessages(fileRestrictions.currentValue);
    }
    if (cropperCoordinates?.currentValue) {
      this.localCropperCoordinates = this.cropperCoordinates;
    }
    if (imageAltText?.currentValue) {
      this.altText = this.imageAltText;
    }

    if (imageUrl && this.imageUrl) {
      if (imageUrl.currentValue.pictureUrl) {
        this.imageUrl = imageUrl.currentValue.pictureUrl;
      }

      const resourceImage = this.resourceImageService.parseImageUrl(
        this.imageUrl,
        this.isHostImageUrl
      );

      if (!this.altText) {
        this.altText = resourceImage.altText;
      }
      this.imageUrl = resourceImage.imageUrl;

      if (resourceImage.cropperCoordinates) {
        this.localCropperCoordinates = resourceImage.cropperCoordinates;
      }

      if (
        resourceImage.imageUrl &&
        !this.thumbnailImage &&
        this.localCropperCoordinates
      ) {
        this.thumbnailImage = this.getCroppedThumbnail(resourceImage);
      }

      this.prevImageData = {
        imageUrl: this.imageUrl,
        cropperCoordinates: this.localCropperCoordinates,
        croppedDisplayImage: this.thumbnailImage,
        selectedFile: null,
        position: this.imagePosition,
        altText: this.altText,
      };

      if (resourceImage) {
        this.imageParsedEvent.emit(resourceImage);
      }
    }

    if (labels) {
      this.setLabels();
    }

    if (isImageUrlFallbackImage && this.isImageUrlFallbackImage) {
      // when the image is a fallback, show the empty state
      this.imageUrl = '';
    }
  }

  public onFocus(): void {
    this.isFocused = true;
  }

  public onBlur(): void {
    this.isFocused = false;
  }

  public onChangeDragState($event: string) {
    switch ($event) {
      case 'dragover':
        this.isFocused = true;
        break;
      default:
        this.isFocused = false;
    }
  }

  public edit($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.editEvent.emit();
    // when the image is client uploaded, use cloudinary to fetch the image to ensure the
    // domain will match and allow the cropper to work correctly
    const imageUrl = this.thumbnailService.isClientUploadedImg(this.imageUrl)
      ? `https://img.degreed.com/image/fetch/${encodeURIComponent(
          this.imageUrl
        )}`
      : this.imageUrl;
    this.openCropper(imageUrl);
  }

  public retry($event: Event) {
    $event.stopPropagation();
    this.retryEvent.emit();
    this.resetSection(this.prevImageData);
  }

  public delete($event: Event) {
    $event.stopPropagation();
    this.deleteEvent.emit();
    this.prevImageData = null;
    this.resetSection();
  }

  // Allow a user to replace a file but user can also cancel during the process and go back to original file
  public replace($event: Event) {
    // TODO: follow up for PD-67084 and remove this
    if (!this.useBrowserReplace) {
      $event.stopPropagation();
    }
    this.canCancel = true;
    this.fileToReplace = this.selectedFile;
    this.selectedFile = undefined;
    this.setFocusToUploadElement();
  }

  // Allows a user to cancel a replace request action and go back to original file uploaded
  public cancelReplace($event: Event) {
    $event.stopPropagation();
    this.canCancel = false;
    this.selectedFile = this.fileToReplace;
    this.fileToReplace = undefined;
    this.setFocusToUploadElement();
  }

  public onFileSelected(event) {
    const files = event.target ? event.target.files : event;
    const file = files.item(0);
    if (!file || !this.isValid(file)) {
      return;
    }

    this.selectedFile = file;
    this.fileChangedEvent.emit(this.selectedFile);

    if (!this.isUploadingImage) {
      // save immediately when an image is not being uploaded
      if (this.uploadAdapter?.upload) {
        this.save(this.selectedFile);
      }
    } else {
      // loadWith will modify the isLoading property
      loadWith(this.setImagePreview(this.selectedFile), this).subscribe(
        (imageUrl) => {
          this.imageUrl = imageUrl;
          this.useCropper
            ? this.openCropper(this.imageUrl)
            : this.save(this.selectedFile);
          this.cdr.markForCheck();
        }
      );
    }
  }

  public getFileMetadataDisplay(file: File) {
    // Bytes -> KB
    const fileSizeInKB = Math.round(file.size / 1000);
    const fileExtension = getFileExtension(file);
    if (fileSizeInKB && fileExtension) {
      const fileSizeInKbStr = this.translateService.instant(
        'dgImageUpload_KB',
        { fileSize: fileSizeInKB }
      );
      return `${fileSizeInKbStr} · ${fileExtension
        .replace('.', '')
        .toUpperCase()}`;
    }
  }

  public onPositionChanged(position: ImagePosition) {
    this.imagePosition = position;
    this.cdr.markForCheck();

    if (this.hasData) {
      this.save(this.selectedFile || this.fileInfo);
    }
  }

  public save(file: File) {
    // If we're showing the position option, set that here!
    if (this.showImagePosition) {
      this.imagePosition = this.imagePosition ?? 'Top';
    }

    loadWith(
      this.uploadAdapter
        .upload(file, {
          ...this.localCropperCoordinates,
          position: this.imagePosition,
          inputType: this.inputType,
          altText: this.altText,
        })
        .pipe(
          finalize(() => {
            this.cdr.markForCheck();
            this.setFocusToUploadElement();
          })
        ),
      this
    ).subscribe(
      (event) => {
        // TODO: Add image cropper error handling to the input-image-upload-adapter
        if (isProgressEvent(event)) {
          this.progress = Math.round(event.progress * 100);
          this.cdr.markForCheck();
        } else if (isCompletionEvent(event)) {
          this.uploadSuccessEvent.emit(event.response);
          if (this.imageUrl) {
            this.prevImageData = {
              imageUrl: this.imageUrl,
              cropperCoordinates: this.localCropperCoordinates,
              selectedFile: this.selectedFile,
              croppedDisplayImage: this.thumbnailImage,
              position: this.imagePosition,
            };
          }
        }
      },
      (error) => {
        this.onError(error?.message, error?.overrideDefaultErrorMessage);
      }
    );
  }

  public openCropper(imageUrl: string) {
    let cropperCanceled = true;
    this.isCropperOpenChange.emit(true);
    this.imageCropperService
      .showCropperModal({
        imageUrl,
        cropperCoordinates: this.localCropperCoordinates,
        aspectRatio: this.aspectRatio,
        secondaryAspectRatio: this.secondaryAspectRatio,
        maintainAspectRatio: this.maintainAspectRatio,
        modalInstructions: this.modalInstructions,
        altText: this.altText,
      })
      .subscribe({
        next: (data) => {
          if (data) {
            this.thumbnailImage = data.base64;
            this.localCropperCoordinates = data.cropperCoordinates;
            this.altText = data.altText;
            cropperCanceled = false;
            this.save(this.selectedFile);
          }
        },
        complete: () => {
          this.isCropperOpenChange.emit(false);
          if (cropperCanceled) {
            if (!this.prevImageData?.imageUrl) {
              // when the cropper was canceled and there was no
              // previous image, clear the section
              this.resetSection();
            } else if (this.imageUrl !== this.prevImageData?.imageUrl) {
              // when the cropper was canceled and there was a previous image,
              // set the image data to the previous image
              this.resetSection(this.prevImageData);
            }
            this.cdr.detectChanges();
          }
        },
      });
  }

  private handleStorageLimitMessages(
    fileRestrictions: FileUploadSetting | VideoUploadSettings
  ) {
    if (fileRestrictions.usedDataPercent >= 100) {
      this.storageLimitError =
        this.chUploadService.getStorageLimitErrorMessage(fileRestrictions);
    } else if (fileRestrictions.usedDataPercent >= 90) {
      this.storageLimitWarning =
        this.chUploadService.getStorageLimitWarningMessage(fileRestrictions);
      this.notificationType =
        fileRestrictions.usedDataPercent >= 98
          ? NotificationType.error
          : NotificationType.warning;
    }
    this.cdr.markForCheck();
  }

  private checkComponentSetup() {
    if (!this.uploadAdapter) {
      throw new Error(
        `'uploadAdapter' is a required input to use the upload-section.component.`
      );
    } else if (this.useCropper && !this.aspectRatio) {
      throw new Error(`'aspectRatio' is required when using the cropper`);
    }
  }

  // sometimes we want the error message from the adapter to be shown, for example when an invalid badge is uploaded
  // in this case throw an error with overrideDefaultErrorMessage: true and the error from the adapter will be displayed.
  private onError(error: string, overrideDefaultErrorMessage: boolean = false) {
    this.errorEvent.emit(error);
    if (overrideDefaultErrorMessage) {
      this.errorMessage = error;
    } else {
      this.errorMessage = this.isUploadingImage
        ? this.errorMessages.imgUploadError
        : this.errorMessages.uploadError;
    }
  }

  private setImagePreview(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  private setLabels() {
    this.labels = { ...this.defaultLabels, ...this.labels };
  }

  private setErrorMessages() {
    this.errorMessages = {
      ...this.defaultErrorMessages,
      ...this.errorMessages,
    };
  }

  private resetSection(prevImageData: PreviousImageData = null) {
    this.errorMessage = null;
    this.thumbnailImage = prevImageData?.croppedDisplayImage;
    this.selectedFile = prevImageData?.selectedFile;
    this.localCropperCoordinates = prevImageData?.cropperCoordinates;
    this.imageUrl = prevImageData?.imageUrl;
    this.inputRef.nativeElement.value = '';
    this.prevImageData = prevImageData;
    this.setFocusToUploadElement();
  }

  /**
   * Get a cropped image to display in the preview section from
   * the resourceImage
   */
  private getCroppedThumbnail(resourceImage: ResourceImage) {
    let srcSet: ImageSourceSet;
    let options: string;
    // if we want to maintain aspect ratio, then also fetch a accurate
    // representation of the thumbnail inside the thumbnail constraints.
    // "c_pad" crops the image without stretching inside the width & height constraints.
    if (!this.maintainAspectRatio) {
      options = ',c_pad';
    }
    srcSet = this.thumbnailService.fetchProxyImageSrcset({
      imageSrc: resourceImage.imageUrl,
      imageWidth: 76, // this should match the .__image-container:first-child width
      imageHeight: 44, // this should match the .__image-container:first-child height
      method: 'fetch',
      crop: 'crop',
      gravity: null,
      options,
      cropCoordinates: this.localCropperCoordinates,
    });

    return srcSet?.orig;
  }

  private isValid(file: File) {
    const isValidExtension = isValidFileExtension(file, this.allowedFileTypes);
    const isValidSize = isValidFileSize(file, this.maxSizeMB);
    let isValid = true;
    if (!isValidExtension) {
      this.errorMessage = this.errorMessages.invalidFileType;
      isValid = false;
    } else if (!isValidSize) {
      this.errorMessage = this.translateService.instant(
        'dgImageUpload_SectionInvalidFileSize',
        { fileSize: this.maxSizeMB }
      );
      isValid = false;
    } else {
      this.errorMessage = null;
    }

    return isValid;
  }

  private buildAllowedFileTypesDescription() {
    // remove extension period and upper case for display
    const fileTypes = this.allowedFileTypes.map((t) =>
      t.slice(1).toLocaleUpperCase()
    );
    const translatedFileTypes = [
      // First or only file type
      this.translateService.instant('Core_FileTypes_FirstOrOnly', {
        fileType: fileTypes[0],
      }),
      // Middle file types if 3 or more present
      ...fileTypes.slice(1, -1).map((fileType) =>
        this.translateService.instant('Core_FileTypes_Middle', {
          fileType,
        })
      ),
      // Last file type if two or more present
      ...(fileTypes.length > 1
        ? [
            this.translateService.instant('Core_FileTypes_OrLast', {
              fileType: fileTypes[fileTypes.length - 1],
            }),
          ]
        : []),
    ].join('');
    const description = this.translateService.instant(
      'dgImageUpload_SectionRestrictionsVar',
      { translatedFileTypes, maxSize: this.maxSizeMB }
    );
    return description;
  }

  private setFocusToUploadElement() {
    this.inputRef?.nativeElement?.focus();
    this.onFocus();
  }

  private hasNonVideoFileRestrictions() {
    return (
      this.nonVideoFileRestrictions &&
      this.nonVideoFileRestrictions.allowedFileTypes.length > 0 &&
      this.nonVideoFileRestrictions.maxSizeMB > 0
    );
  }

  private handleFileLabels() {
    this.defaultLabels = {
      ...this.defaultLabels,
      allowedDescription: this.buildAllowedFileTypesDescription(),
    };
    this.setLabels();
    this.cdr.markForCheck();
  }

  private setFileRestrictions() {
    if (this.hasNonVideoFileRestrictions()) {
      this.allowedFileTypes = this.nonVideoFileRestrictions.allowedFileTypes;
      this.maxSizeMB = this.nonVideoFileRestrictions.maxSizeMB;

      this.handleFileLabels();
      this.isSettingsLoading = false;
    } else {
      this.uploadAdapter
        .getUploadSettings()
        .pipe(
          finalize(() => (this.isSettingsLoading = false)),
          this.takeUntilDestroyed()
        )
        .subscribe(({ maxSizeMB, allowedFileTypes }) => {
          this.maxSizeMB = maxSizeMB;
          this.allowedFileTypes = allowedFileTypes;
          this.handleFileLabels();
        });
    }
  }
}
