import { Injectable } from '@angular/core';

import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TypeaheadSearchTerm } from '@app/shared/shared-api.model';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  UserInterest,
  UserProfileSummary,
  UserSearchItem,
} from '@app/user/user-api.model';
import { UserListFormComponent } from '@app/user/components/user-list-form/user-list-form.component';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { TagsApi } from '@app/tags/tag-api.model';

/**
 * The Typeahead search function type for user searches.
 *
 * @param term - An observable that emits characters as they are typed.
 * @param excludeSelf - Whether to exclude the current user from the results.
 * @param count - How many results to return.
 * @param includePrivateUsers - Whether to include private users in the results.
 * @returns An observable collection of results.
 */
export type TypeaheadUserSearchFunction<TTerm, TResult> = (
  term: TypeaheadSearchTerm<TTerm>,
  params?: {
    excludeSelf?: boolean;
    count?: number;
    includePrivateUsers?: boolean;
  }
) => Observable<readonly TResult[]>;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private showUsersListModalVisible;
  constructor(
    private focusStackService: FocusStackService,
    private http: NgxHttpClient,
    private modalService: ModalService,
    private translate: TranslateService
  ) {}

  /**
   * Search for users
   *
   * - This is used primarily by the user-search.component to provide search results.
   * - Searches are uniquely debounced and length limited to two characters or more.
   * - Errors are swallowed and an empty result set is returned instead.
   *
   * This function is intentionally defined as a function property to support ngb-typeahead
   * searching where `this` is undefined.
   *
   * @param term - The string term, as an Observable.
   */
  public search: TypeaheadUserSearchFunction<string, UserSearchItem> = (
    term: Observable<string>,
    { excludeSelf = false, count = 20, includePrivateUsers = false } = {}
  ): Observable<readonly UserSearchItem[]> => {
    return term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length >= 0),
      // don't exclude self so we can add ourselves back to the
      // list of collaborators
      switchMap((term: string) =>
        this.findNetworkMembers(term, excludeSelf, count, includePrivateUsers)
      ),
      // convert to search items
      map((results: UserProfileSummary[]) =>
        results.map((ups) => this.toUserSearchItem(ups))
      ),
      // for now, swallow and return no results
      catchError((_) => of([] as UserSearchItem[]))
    );
  };

  /**
   * The Typeahead search function type for user searches.
   *
   * @param nameFilter - The name to search for.
   * @param excludeSelf - Whether to exclude the current user from the results.
   * @param count - How many results to return.
   * @param includePrivateUsers - Whether to include private users in the results.
   * @returns An observable array of UserProfileSummaries.
   */
  public findNetworkMembers(
    nameFilter: string,
    excludeSelf = true,
    count = 20,
    includePrivateUsers = true
  ): Observable<UserProfileSummary[]> {
    if (nameFilter.length === 0) {
      return of([]);
    }
    return this.http.get<UserProfileSummary[]>('/users/findnetworkmembers', {
      params: {
        nameFilter,
        excludeSelf,
        count,
        includePrivateUsers,
      },
    });
  }

  /**
   * Get a single user for a given `userProfileKey`
   *
   * @param {number} userKey
   * @returns {Observable} Observable<UserProfileSummary>
   * @memberof UserService
   */
  public getUserByKey(userKey: number): Observable<UserProfileSummary> {
    return this.http
      .get<UserProfileSummary>('/users/getuser', {
        params: {
          userKey,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TagsSvc_GetTagRatingError'),
              error
            )
          )
        )
      );
  }

  /**
   * Get the skills with the ratings for a user and map those
   *
   * @param UserProfileSummary the user to fetch skills of
   *
   * @return
   * @userProfile  UserProfileSummary;
   * @userMentoringSkills The skills the user is offering mentoring in
   * @userOtherSkills The other/remaining skills of the user
   * @userFocusSkills The users focus skills
   */
  public getUserSkillsWithSignals(userProfile: UserProfileSummary): Observable<{
    userProfile: UserProfileSummary;
    userMentoringSkills: Skill[];
    userOtherSkills: Skill[];
    userFocusSkills: Skill[];
  }> {
    const userKey = userProfile.userProfileKey;
    return this.http
      .get<{ userProfile: UserProfileSummary; tags: TagsApi.TagDetails[] }>(
        `/user/getUserSkillsWithSignals`,
        {
          params: { userKey },
        }
      )
      .pipe(
        map(({ userProfile, tags }) => {
          const userMentoringSkills = [];
          const userFocusSkills = [];
          let userOtherSkills = [];

          if (!userProfile.isMentoring) {
            userOtherSkills = tags;
          } else {
            tags.forEach((tag) => {
              // TODO: clean this up when properties have been cleaned out PD-77933
              if (tag.isMentoring || tag.userKeyIsMentoring) {
                userMentoringSkills.push(tag);
              } else if (tag.isFocused || tag.userKeyIsFocused) {
                userFocusSkills.push(tag);
              } else {
                userOtherSkills.push(tag);
              }
            });
          }

          return {
            userProfile,
            userMentoringSkills,
            userOtherSkills,
            userFocusSkills,
          };
        }),
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('TagsSvc_GetTagError'), error)
          )
        )
      );
  }

  public showUsersList(
    title,
    users,
    event?,
    disableLink?,
    showFollowBtns?,
    showAnonLearners?,
    userAttribute?
  ): void {
    // Don't create multiple instances of this
    if (this.showUsersListModalVisible) {
      return;
    }

    // Keep focus on graph bar clicked
    if (event) {
      this.focusStackService.push(event.target);
      this.focusStackService.pop();
    }

    const inputs = {
      title: title,
      users: users,
      disableLink: disableLink,
      showFollowBtns: showFollowBtns,
      showAnonLearners: showAnonLearners,
      userAttribute: userAttribute,
    };
    this.showUsersListModalVisible = this.modalService
      .show(UserListFormComponent, { inputs })
      .subscribe({
        complete: () => {
          this.showUsersListModalVisible = null;
        },
      });
  }

  private toUserSearchItem({
    userProfileKey,
    name,
    organizationEmail,
    email,
    picture,
    isEngaged,
    vanityUrl,
  }: Partial<UserProfileSummary>): UserSearchItem {
    return {
      userProfileKey,
      name,
      email: organizationEmail || email,
      picture,
      isEngaged,
      vanityUrl,
    };
  }
}
