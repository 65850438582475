import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DfIconCross16, DfIconRegistry } from '@lib/fresco';

export interface HeadingMetaOption {
  class: string;
  label: string;
}

/**
 * Used to add a header to any modal. Pass in header text as content.
 *
 * ```
 * // the simplest implementation, textless, with cancel button by default
 * // (**dismiss** must be defined in order for the cancel button to show up.)
 * <dgx-modal-header (dismiss)="onDismiss($event)"></dgx-modal-header>
 *
 * // with explicitly no cancel button
 * <dgx-modal-header [canCancel]="false"></dgx-modal-header>
 *
 * // pass in some header content
 * <dgx-modal-header (dismiss)="onDismiss($event)">
 *   {{ 'Core_SomeString' | translate }}
 * </dgx-modal-header>
 *
 * // pass in HTML or components, and change the headerClass
 * <dgx-modal-header
 *   headerClasses="h2"
 *   (dismiss)="handleDismiss($event)"
 * >
 *   <df-icon
 *     icon="article"
 *     class="guts-m-r-1 ib ib-v-middle"
 *   ></df-icon>
 *   {{ 'Core_SomeString' | translate }}
 * </dgx-modal-header>
 * ```
 */
@Component({
  selector: 'dgx-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent implements AfterViewChecked, OnInit {
  // Bindings - Children
  @ViewChild('headerContent') public headerContent: ElementRef;

  // - Input
  /** Set to false to remove 'Cancel' button (x) from header. Otherwise, `dismiss` *must be defined*. */
  @Input() public canCancel = true;
  /** Set to true to remove default padding. *Used by extension.* */
  @Input() public ebbRemoveModalPadding = false;
  /** Value of ngClass on the header's <h1> element. *Can be string or expression.* */
  @Input() public headerClasses: any = 'h3';
  /** Value of ngClass on the wrapping <header> element. *Can be string or expression.* */
  @Input() public headerWrapperClasses: any;
  /** If set, display meta in the header below the <h1> element. */
  @Input() public headingMetaConfig?: HeadingMetaOption[];
  /** Set to true to remove bottom border from header. */
  @Input() public isBorderless: boolean;
  /** Set to true to center header content. */
  @Input() public isCentered = false;
  /** Pass in to delay showing <ng-content> until it is false. */
  @Input() public isLoading = false;
  /** Shows overlay when submit pending. *Default `false`, only works when isSubmitPending set to true. * */
  @Input() public isPendingWithOverlay = false;
  /** Shows a loading state on the submit button until true. */
  @Input() public isSubmitPending = false;

  // - Output
  /** If using 'Cancel' button, this *must* be passed in and modal dismissal must be handled by the parent. */
  @Output() public dismiss = new EventEmitter<Event>();

  // - Local
  public hideBorder = true;
  public showHeaderContent = false;

  // Constructor
  constructor(
    private cdr: ChangeDetectorRef,
    private iconRegistry: DfIconRegistry
  ) {
    this.iconRegistry.registerIcons([DfIconCross16]);
  }

  // Angular methods
  public ngOnInit(): void {
    if (!this.headerWrapperClasses) {
      // TODO: Future default value, per our new designs: guts-p-h-2 guts-p-v-1
      // this.headerWrapperClasses = 'guts-p-h-2 guts-p-v-' + (this.ebbRemoveModalPadding ? : '0' : '1');
      this.headerWrapperClasses = this.ebbRemoveModalPadding
        ? 'guts-v-p-0'
        : '';
    }
  }

  // ngAfterViewChecked works for both components using `isLoading`
  // and those that aren't, as AfterViewChecked is triggered both
  // after the initialization of the component *and* after any and
  // all forms of change-detection to its bindings.
  public ngAfterViewChecked(): void {
    // Do nothing if loading.
    if (this.isLoading) {
      return;
    }
    // Determine if we should show the header content.
    this.showHeaderContent = this.headerHasContent(
      this.headerContent.nativeElement
    );
    // `showBorder` should be the *opposite* of `showHeaderContent`,
    // unless `isBorderless` has been set, in which case it should match.
    this.hideBorder = this.isBorderless ?? !this.showHeaderContent;
    // Detect changes.
    this.cdr.detectChanges();
  }

  // Public methods

  /**
   * Pass Event to parent for logging.
   *
   * @param event - Mouse or keyboard event that triggered the method.
   */
  public onDismiss(event: Event): void {
    this.dismiss.emit(event);
  }

  // determine whether or not we should show the header
  private headerHasContent(element: HTMLElement): boolean {
    // if no element at all, return false
    if (!element) {
      return false;
    }
    if (
      // check for one or more HTML nodes
      element.children.length > 0 ||
      // check for plain text, trimmed, to avoid false positives
      // either for Angular comments or for white space
      element.innerText?.trim().length > 0
    ) {
      return true;
    }
    // otherwise...
    return false;
  }
}
