import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { AuthUser } from '@app/account/account-api.model';
import { MentorshipStatus } from '@app/mentoring/mentoring-api.model';
import { UserProfileSummary } from '@app/user/user-api.model';
import { DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export interface BaseMenteeModel {
  availability: boolean;
  mentorshipStatusName: MentorshipStatus;
}

// TODO: Extract this into Fresco.
export function CustomMaxLengthValidator(
  control: AbstractControl,
  maxLength: number
): ValidationErrors {
  return (control.value || '').trim().length <= maxLength
    ? null
    : { max: true };
}

@Component({
  selector: 'dgx-base-mentee-modal',
  templateUrl: './base-mentee-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseMenteeModalComponent implements OnInit {
  // TODO: Extract this into Fresco.
  @ViewChild('formlySelect', { static: true })
  public formlySelectRef: TemplateRef<any>;
  public i18n: Record<string, string>;
  public descriptionText: string;
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public headerText: string;
  public mentee: UserProfileSummary;
  public mentor: AuthUser;
  public model: BaseMenteeModel = {
    availability: false,
    mentorshipStatusName: null,
  };
  public options: FormlyFormOptions = {};
  public submitButtonText: string;

  constructor(
    protected activeModal: NgbActiveModal,
    protected cdr: ChangeDetectorRef,
    protected translate: TranslateService
  ) {}

  public ngOnInit(): void {
    // set the availability
    this.model.availability = !!this.mentor.viewerProfile.isMentoring;

    // set the fields
    this.fields = [
      {
        key: 'availability',
        type: 'toggle-switch',
        templateOptions: {
          dfLabel: 'Opportunities_AcceptMenteeModal_AvailabilityLabel',
          layout: 'horizontal-normal',
          dfDescription:
            'Opportunities_AcceptMenteeModal_AvailabilityDescription',
        },
      },
    ];
    // update view
    this.cdr.markForCheck();
  }

  /**
   * Base onSubmit, checks form for validation and then marks all
   * form fields as touched so that errors will show up immediately.
   *
   * @param event - The submission event.
   */
  public onSubmit(event: Event): void {
    // prevent click event bubbling
    event.preventDefault();
    // Close modal and submit the model if the form is valid.
    if (this.form.valid) {
      return this.activeModal.close(this.model);
    }
    // Mark all fields as touched so that their validaiton messages
    // will show. This is absolutely necessary for the custom select
    // field on our AcceptMenteeModalComponent, but its "other" field
    // is also a little buggy in terms of whether or not it gets
    // marked as errored when the form is submitted.
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  /**
   * Whether or not a form control should show its error signifiers.
   *
   * @param formControl - The current form control.
   */
  public showErrors(formControl: FormControl) {
    return formControl.invalid && (formControl.touched || formControl.dirty);
  }
}
