import { Injectable } from '@angular/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExperienceNotificationService {
  constructor(
    private translate: TranslateService,
    private notifier: NotifierService
  ) {}

  public notifyExperienceCreated(title: string) {
    this.notifier.showSuccess(
      this.translate.instant('OrgInternalContentCtrl_SuccessfulAddContent', {
        title,
      })
    );
  }

  public notifyExperienceCreateFailed() {
    this.notifier.showError(
      this.translate.instant('OrgInternalContentCtrl_GenericError')
    );
  }
}
