<ngb-accordion #a="ngbAccordion" class="expanding-title-section" type="light">
  <ngb-panel>
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="grid guts-p-full-0 guts-m-full-0">
        <div class="grid__col-md-6 guts-p-full-0 guts-m-full-0">
          <h5 class="par par--small font-medium guts-p-full-0 guts-m-full-0">
            {{ title }}
          </h5>
        </div>
        <div
          class="grid__col-md-6 guts-p-full-0 guts-m-full-0 right-text text-right"
        >
          <p class="right-text par par--small par--light">
            <span *ngIf="rightText">{{ rightText }}</span>
            <button
              ngbPanelToggle
              class="btn-link right-text guts-p-l-half"
              data-dgat="expanding-title-section-bcd"
            >
              <span>
                <df-icon
                  [icon]="opened ? 'chevron-up' : 'chevron-down'"
                  class="color-ebony-a61 header-icon"
                  size="small"
                ></df-icon>
              </span>
            </button>
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <ng-content></ng-content>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
