import { UserProfileSummary } from '@app/user/user-api.model';
import { MentorshipStatusEnum } from '../mentoring.enums';

// public/exported

/**
 * Return an object containing the button type and label.
 *
 * @param mentor - The mentor to check.
 */
export function getConnectWithMentorButtonData(mentor: UserProfileSummary): {
  type: 'primary' | 'secondary' | 'passive';
  label:
    | 'Opportunities_ConnectWithButton_Status_Accepted'
    | 'Opportunities_ConnectWithButton_Status_None'
    | 'Opportunities_ConnectWithButton_Status_Requested';
} {
  switch (mentor.mentorshipStatus) {
    case MentorshipStatusEnum.Requested:
      return {
        type: 'primary',
        label: 'Opportunities_ConnectWithButton_Status_Requested',
      };
    case MentorshipStatusEnum.Accepted:
      return {
        type: 'secondary',
        label: 'Opportunities_ConnectWithButton_Status_Accepted',
      };
    default:
      return {
        type: 'passive',
        label: 'Opportunities_ConnectWithButton_Status_None',
      };
  }
}
