import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentTitleService {
  constructor(
    private router: Router,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.reload();
  }

  /**
   * Add a string to the beginning of the current document title,
   * e.g. `<Plan Name> | Plan | Degreed`.
   *
   * @param title
   */
  public prependTitle(title = '') {
    this.reload();
    if (title) {
      this.titleService.setTitle(`${title} | ${this.titleService.getTitle()}`);
    }
  }

  /**
   * Set the document title value after an Angular Router navigation ends successfully.
   * @note Assumption is a data i18nTitle attribute is assigned to a given route
   * @example data: { i18nTitle: 'User_Profile' }
   */
  public register(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Set the page title
        this.reload();
      });
  }

  public reload(): void {
    const titles = this.getChildTitles();
    if (titles.length === 0) {
      return;
    }
    this.titleService.setTitle(`${titles.join(' | ')} | Degreed`);
  }

  /**
   * Replace the current document title entirely.
   *
   * @param title
   */
  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  /**
   * Get a list of nested child route titles
   * @returns String array of nested titles
   */
  private getChildTitles(): string[] {
    let currentRoute = this.router.routerState?.root?.firstChild;
    const titles: string[] = [];
    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.i18nTitle) {
        const translated = this.translate.instant(
          currentRoute.snapshot.routeConfig.data.i18nTitle
        );
        titles.push(translated);
      } else if (currentRoute.snapshot.routeConfig.data?.title) {
        titles.push(currentRoute.snapshot.routeConfig.data.title);
      }
      currentRoute = currentRoute.firstChild;
    }
    titles.reverse();
    return titles;
  }
}
