import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { getDeepCopy } from '@app/shared/utils/property';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  DfIconArrowForward16,
  DfIconCheckmark16,
  DfIconPlus16,
  DfIconRegistry,
  DfIconStar16,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dgx-tag-tile-footer',
  templateUrl: './tag-tile-footer.component.html',
  styleUrls: ['./tag-tile-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTileFooterComponent {
  public i18n = this.translateService.instant([
    'Core_Add',
    'Core_FocusSkill',
    'dgTagRating_AddA11y',
    'dgTagRating_RemoveA11y',
    'dgTagRating_Added',
    'dgTagRating_AddToProfileTooltip',
    'dgTagRating_RemoveFromProfileTooltip',
    'Core_FollowingButtonLabel',
    'dgTagRating_AddFocusSkill',
    'dgTagRating_RemoveFocusSkill',
    'dgTagRating_SelfAssessment',
    'dgTagRating_Evaluation',
    'dgTagRating_Credential',
    'dgTagRating_SelectRating',
    'dgTagRating_RatingType',
    'dgTagRating_RecommendSkill',
    'Core_FindContent',
    'Core_MoreOptions',
  ]);

  @Output() public tagEditedEmitter = new EventEmitter<TagsApi.Tag>();
  @Output() public removeFromPlan = new EventEmitter<number>();

  @Input() public actionsContext?: any;
  @Input() public targetAuthoring?: boolean;
  @Input() public hideFollowing?: boolean;
  @Input() public tag: TagsApi.Tag;
  @Input() public isSkillStandard?: boolean = false;
  @Input() public modifyOptionsFn?: ModifyOptionsFnType<any>;
  public loading = false;

  constructor(
    private iconRegistry: DfIconRegistry,
    private translateService: TranslateService,
    private authService: AuthService,
    private tagsService: TagsService,
    private cdr: ChangeDetectorRef,
    private tagActionOptionsService: TagActionOptionsService,
    private route: ActivatedRoute
  ) {
    this.iconRegistry.registerIcons([
      DfIconArrowForward16,
      DfIconCheckmark16,
      DfIconPlus16,
      DfIconStar16,
    ]);
  }

  public get menuConfig(): MenuViewModel[] {
    if (this.isSkillStandard) {
      return this.modifyOptionsFn(this.tag, []);
    } else {
      return [
        this.tagActionOptionsService.getViewSkillSignalsMenuOption(
          this.tag,
          this.route
        ),
        this.tagActionOptionsService.getAddToPlanMenuOption(this.tag),
        this.tagActionOptionsService.getRemoveFromPlanMenuOption(
          this.tag,
          this.targetAuthoring,
          this.removeFromPlanFn
        ),
        this.tagActionOptionsService.getFindContentMenuOption(this.tag),
        this.tagActionOptionsService.getCancelManagerRatingRequestMenuOption(
          this.tag
        ),
        this.tagActionOptionsService.getCancelSkillReviewMenuOption(this.tag),
      ];
    }
  }

  public get addActionText(): string {
    return this.tag.isFollowing
      ? this.i18n.dgTagRating_Added
      : this.i18n.Core_Add;
  }

  public get addActionIcon(): string {
    return this.tag.isFollowing ? 'checkmark' : 'plus';
  }

  public get addActionTooltip(): string {
    return this.tag.isFollowing
      ? this.i18n.dgTagRating_RemoveFromProfileTooltip
      : this.i18n.dgTagRating_AddToProfileTooltip;
  }

  public get addActionA11y(): string {
    const followText = this.tag.isFollowing
      ? this.i18n.dgTagRating_RemoveA11y
      : this.i18n.dgTagRating_AddA11y;
    return `${followText}: ${this.tag.title}`;
  }

  public get focusActionTooltip(): string {
    return this.tag.requestingUserIsFocused || this.tag.isFocused
      ? this.i18n.dgTagRating_RemoveFocusSkill
      : this.i18n.dgTagRating_AddFocusSkill;
  }

  public get focusActionA11y(): string {
    return `${this.focusActionTooltip}: ${this.tag.title}`;
  }

  public get recommendActionTooltip(): string {
    return this.i18n.dgTagRating_RecommendSkill;
  }

  public get recommendActionA11y(): string {
    return `${this.recommendActionTooltip}: ${this.tag.title}`;
  }

  public get isSkillInventoryClient(): boolean {
    return this.authUser ? this.authUser.isSkillInventoryClient : false;
  }

  public get showRecommendAction(): boolean {
    return (
      !!this.authUser?.canRecommendRatings && !this.authService.isConsumerUser
    );
  }

  public get userLoggedIn(): boolean {
    return !!this.authUser;
  }

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public removeFromPlanFn = (id: number): void => {
    this.removeFromPlan.emit(id);
  };

  /**
   * Add/remove skill from user profile
   *
   * NOTE: Skills with in-progress/completed ratings will be unfollowed but will remain in the users profile
   *
   * @param event
   */
  public toggleFollow(event: Event): void {
    event?.stopImmediatePropagation();

    this.tag.isFollowing ? this.handleSkillRemoval() : this.addUserTag();
  }

  private updateButtonStyles = () => {
    this.tag.isFollowing = !this.tag.isFollowing;
    // when viewing another user's profile, prevent adding of the skill to current user's profile as focused initially
    this.tag.isFocused = false;
  };

  private addUserTag() {
    this.updateButtonStyles();
    return this.tagsService.addUserTag(this.tag).subscribe(
      () => {
        this.tagEditedEmitter.emit(this.tag);
      },
      () => {
        // reset styles if the confirmation modal is cancelled
        this.updateButtonStyles();
        // detect changes to ensure the correct tooltip text is shown after cancelling
        this.cdr.detectChanges();
      }
    );
  }

  private removeUserTag() {
    this.updateButtonStyles();
    this.tagsService.removeUserTag(this.tag).subscribe(
      () => {
        this.tagEditedEmitter.emit(this.tag);
      },
      () => {
        // reset styles if the confirmation modal is cancelled
        this.updateButtonStyles();
        // detect changes to ensure the correct tooltip text is shown after cancelling
        this.cdr.detectChanges();
      }
    );
  }

  private handleSkillRemoval = () => {
    //If it's a Workday skill we need to display an additional modal
    const isWorkdaySkill =
      (this.tag as any).provider?.providerName?.toLowerCase() === 'workday';
    return isWorkdaySkill
      ? this.removeWorkdaySkillModal(this.tag)
      : this.removeUserTag();
  };

  private removeWorkdaySkillModal(item) {
    return this.tagsService.removeWorkdayUserTagModal(item).subscribe({
      next: () => {
        this.removeUserTag();
      },
    });
  }

  /**
   * Recommend a skill
   *
   * @param event event
   */
  public recommend(event: Event): void {
    event?.stopImmediatePropagation();
    this.tagsService.recommendTag(this.tag).subscribe();
  }

  /**
   * Toggle tag focus
   *
   * @param event event
   */
  public toggleFocused(event: Event): void {
    event?.stopImmediatePropagation();

    if (this.loading) {
      return;
    }

    this.loading = true;
    let tag = getDeepCopy(this.tag);
    tag.requestingUserIsFocused = tag.isFocused;
    this.tagsService.toggleUserInterestFocus(tag).subscribe(() => {
      this.loading = false;
      this.cdr.markForCheck();
    });
  }
}
