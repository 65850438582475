<dgx-modal
  [canCancel]="true"
  bodyClasses="guts-p-full-0"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    <div class="l_flexbar guts-p-full-half">
      <dgx-profile-pic [profile]="peer" size="tiny"></dgx-profile-pic>
      <div class="guts-m-l-3-quart">
        {{ peer?.name }}
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-body">
    <ng-container *ngIf="!isLoading">
      <div class="guts-p-full-2">
        <ng-container *ngIf="hasRequestNote">
          <p
            class="
              guts-p-full-1
              rounded
              more-details__description
              par par--small
              guts-m-b-2
            "
          >
            {{ mentorship.mentorshipRequestNote }}
          </p>
        </ng-container>
        <ng-container *ngIf="!hasRequestNote">
          <df-local-notification
            [type]="NotificationType.info"
            size="small"
            [text]="i18n.Opportunities_MoreDetailsMentorshipModal_Info"
            class="block guts-m-b-2"
          ></df-local-notification>
        </ng-container>
        <ng-container *ngIf="hasMatchedTags">
          <h3 class="h3 modal-body__header">
            {{ this.i18n.Core_Matched_Skills }}
          </h3>
          <dgx-tag-pill
            *ngFor="let tag of matchedTags"
            [disableTooltip]="true"
            [tag]="tag"
            data-dgat="connect-with-mentor-modal-2ec"
          ></dgx-tag-pill>
        </ng-container>
        <ng-container *ngIf="!hasMatchedTags && hasFocusTags">
          <h3 class="h3 modal-body__header">
            {{ this.i18n.PersonalSkillView_FocusSkills }}
          </h3>
          <dgx-tag-pill
            *ngFor="let tag of focusTags"
            [disableTooltip]="true"
            [tag]="tag"
            data-dgat="connect-with-mentor-modal-2ec"
          ></dgx-tag-pill>
        </ng-container>
        <ng-container *ngIf="!hasMatchedTags && !hasFocusTags && hasTags">
          <h3 class="h3 modal-body__header">{{ this.i18n.Core_Skills }}</h3>
          <dgx-tag-pill
            *ngFor="let tag of tags"
            [disableTooltip]="true"
            [tag]="tag"
            data-dgat="connect-with-mentor-modal-2ec"
          ></dgx-tag-pill>
        </ng-container>
        <ng-container
          *ngIf="
            !hasRequestNote && !hasMatchedTags && !hasFocusTags && !hasTags
          "
        >
          <df-local-notification
            [type]="NotificationType.warning"
            size="small"
            [text]="i18n.Opportunities_MoreDetailsMentorshipModal_Warning"
            class="block guts-m-b-2"
          ></df-local-notification>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="guts-p-full-4">
        <df-spinner class="l_flexbar"></df-spinner>
      </div>
    </ng-container>

    <section *ngIf="!isLoading && useCompleteMentorshipEnhancements">
      <div
        *ngIf="!!toMenteeFeedback || !!toMentorFeedback"
        class="mentoring-feedback"
      >
        <div class="guts-p-v-1">
          <div *ngIf="!!toMenteeFeedback" class="guts-p-h-2 guts-p-v-1">
            <h3 class="h3 mentoring-feedback__header">
              {{ toMenteeFeedbackHeader }}
            </h3>
            <p class="guts-p-full-1 mentoring-feedback__message par par--small">
              {{ toMenteeFeedback }}
            </p>
          </div>
          <div *ngIf="!!toMentorFeedback" class="guts-p-h-2 guts-p-v-1">
            <h3 class="h3 mentoring-feedback__header">
              {{ toMentorFeedbackHeader }}
            </h3>
            <p class="guts-p-full-1 mentoring-feedback__message par par--small">
              {{ toMentorFeedback }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container class="modal-footer">
    <ng-container *ngIf="showActionButtons && !isLoading">
      <button
        df-button
        dfButtonType="destructive"
        (click)="onDecline()"
        class="guts-m-r-quart"
        data-dgat="more-details-mentee-modal-a3d"
      >
        {{ i18n.Core_Decline }}
      </button>
      <button
        df-button
        dfButtonType="primary"
        (click)="onAccept()"
        data-dgat="more-details-mentee-modal-d89"
      >
        {{ i18n.Core_Accept }}
      </button>
    </ng-container>
  </ng-container>
</dgx-modal>
