import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, lastValueFrom, map } from 'rxjs';

// Services
import { InputsService } from '@app/inputs/services/inputs.service';
import {
  InputsFacadeBaseV2,
  MediaMetadataStatus,
} from '@app/user-content/user-input-v2/services/inputs-facade-base';
import { AuthService } from '@dg/shared-services';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { VideoMapperService } from './video-mapper.service';
import { VideoService } from '@app//user-content/user-input-v2/inputs/video/services/video.service';

// Misc
import {
  VideoApiInputEdit,
  VideoFormDataModel,
  VideoModel,
} from '@app/user-content/user-input-v2/inputs/video/video.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { FormGroup } from '@angular/forms';
import { PathwayNode, PathwayStep } from '@dg/pathways-rsm';
/*
 * Base Video service to define shared functionality, with the ability to overwrite if it is different. For example
 * only the content in the catalog should be checked for duplicates.
 * - only catalog can upload videos
 * - only catalog can record videos
 *
 */
@Injectable({
  providedIn: 'root',
})
export abstract class VideoFacadeBase extends InputsFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<VideoModel>(undefined);

  constructor(
    protected inputsService: InputsService,
    protected authService: AuthService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected orgInternalContentService: OrgInternalContentService,
    protected videoMapperService: VideoMapperService,
    protected videoService: VideoService
  ) {
    super(inputsService);
  }

  /** Gets the latest view model */
  protected get viewModel(): VideoModel {
    return this.viewModel$.value as VideoModel;
  }

  protected set viewModel(viewModel: VideoModel) {
    this.viewModel$.next(viewModel);
  }

  protected get orgId(): number {
    return this.authService.authUser.defaultOrgId;
  }

  /**************************************
   * View Updates
   **************************************/

  public async initializeEdit(): Promise<void> {
    // Update viewModel
    const editEntry = (await this.inputsService.getMediaEntryAsPromise(
      this.viewModel.inputContext.inputId
    )) as VideoApiInputEdit;

    // Map response to view model
    const updatedView = this.videoMapperService.toViewModel(
      editEntry,
      this.viewModel
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isInitialForm: false,
      organizationId: this.orgId,
    };
    return;
  }

  public async onSubmit(
    form: FormGroup,
    isUserMediaEntry: boolean = false
  ): Promise<void> {
    const formData = form.value;
    // Step 1 update the View model with the form data
    this.updateViewWithFormData(formData);
    // step 2 get API Params mapper
    const apiParameters = this.videoMapperService.toApiParameters(
      this.viewModel as VideoModel
    );
    this.submissionStatus$.next(SubmissionStatus.Submitting);
    try {
      if (this.viewModel.inputContext.isEditing) {
        await this.videoService.updateMedia(apiParameters);
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      } else {
        const addMedia = isUserMediaEntry
          ? this.videoService.addUserMedia.bind(this.videoService)
          : this.videoService.addMedia.bind(this.videoService);
        const { result } = await addMedia(apiParameters, null);

        this.viewModel = {
          ...this.viewModel,
          inputId: result?.inputId ?? this.viewModel.inputId,
        };
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      }
      return;
    } catch {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in VideoFacadeBase');
    }
  }

  /**
   * This
   * @param inputContext
   */
  protected async initializeViewModel(
    inputContext: InputContext
  ): Promise<void> {
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
      this.mediaMetadataStatus$,
    ]).pipe(
      map(
        ([s, m]) =>
          s === SubmissionStatus.Submitting || m === MediaMetadataStatus.Parsing
      )
    );

    // initialize context
    super.initializeContextViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: false,
      canManageContent:
        !!this.authService.authUser?.defaultOrgInfo?.permissions?.manageContent,
      canRestrictContent: !!this.authService.authUser?.canRestrictContent,
      duplicateCount: 0,
      entryUrl: '',
      groups: [],
      tags: [],
      title: '',
      sourceName: '',
      summary: '',
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
      imageUploadAdapter: this.inputImageUploadAdapterService.getAdapter(
        inputContext.inputType,
        undefined
      ),
    };
    this.getInputTypeFormats();
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: VideoFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      entryUrl: formData.entryUrl,
      title: formData.title,
      sourceName: formData.sourceName,
      format: formData.format,
      summary: formData.description,
      imageUrl: formData.image,
      durationMinutes: formData.durationForm.durationMinutes,
      durationHours: formData.durationForm.durationHours,
      tags: formData.skills,
      // These are for advanced Settings only
      orgContentMetadata: {
        hideFromCatalog: formData.addToCatalog
          ? !formData.addToCatalog
          : this.viewModel.orgContentMetadata?.hideFromCatalog,
        groupIds: formData.advancedSettings?.visibility.groups,
      },
      externalId: formData.advancedSettings?.internalItemId,
      language: formData.advancedSettings?.language?.id,
      publishDate: formData.advancedSettings?.publishedDate,
      comment: formData.comment,
    };
  }

  private async getInputTypeFormats(): Promise<void> {
    const response = await lastValueFrom(
      this.orgInternalContentService.getInputTypeFormats(
        this.viewModel.inputContext.inputType
      )
    );

    this.viewModel = {
      ...this.viewModel,
      inputTypeFormats: response.map((f) => ({ title: f })),
    };
  }
}
