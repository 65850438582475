import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { fadeInAndOut } from '@app/shared/animations/animations';
import { LocalityViewModel } from '@app/shared/components/address-suggest/address-suggest.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { SessionedInputType } from '@app/shared/models/core-api.model';
import { TimeZoneService } from '@app/shared/services/time-zone.service';
import { DF_COLLAPSE_EXPAND, NotificationType } from '@lib/fresco';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { catchError, tap, throwError } from 'rxjs';
import { SessionParameters } from '../inputs-api.model';
import { UserInputsService } from '../services/user-inputs.service';
import { InputSessionModel } from './input-session-model';

@Component({
  selector: 'dgx-input-session-form-partial',
  templateUrl: './input-session-form-partial.component.html',
  styleUrls: ['./input-session-form-partial.component.scss'],
  animations: [DF_COLLAPSE_EXPAND, fadeInAndOut],
})
export class InputSessionFormPartialComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('sessionForm') public sessionForm: NgForm;
  @ViewChild('endTime') public endTimeElement: NgModel;
  @ViewChild('startTime') public startTimeElement: NgModel;
  @Input() public session: SessionParameters;
  @Input() public inputType: SessionedInputType | '' = '';
  @Input() public isFromIntegrations: boolean = false;
  @Output() public sessionChange = new EventEmitter<SessionParameters>();
  @Output() public validateChange = new EventEmitter<boolean>();

  public readonly NotificationType = NotificationType;

  public isEnabled: boolean = false;
  public isOnline: boolean = false;
  public isInPerson: boolean = true;
  public isEditing: boolean = false;

  public sessionStartDate: NgbDateStruct;
  public sessionEndDate: NgbDateStruct;
  public sessionStartTime: string = '09:00'; // setting a default to act like a placeholder
  public sessionEndTime: string = '17:00'; // setting a default to act like a placeholder
  public minEndDate: NgbDateStruct;
  public bounds = {
    minEndTime: undefined,
    maxStartTime: undefined,
  };

  /**
   * backupSession is used to preserve
   * values typed in by the user in case fields
   * are toggled off so when toggled on there
   * isn't lost data.
   */
  public backupSession: SessionParameters;

  public readonly urlValidatorRegEx = new RegExp(
    '^(https?://){1}([a-zA-Z0-9.-]{1,256})\\.([a-z.]{2,6})[/\\w .-]*/?'
  );

  constructor(
    private datehandler: NgbDateParserFormatter,
    private timeZoneService: TimeZoneService,
    private cdr: ChangeDetectorRef,
    private userInputsService: UserInputsService
  ) {
    super();
  }

  public ngOnInit(): void {
    // listen for calls to 'mark all as touched' from the parent.
    this.userInputsService.childFormsMarkedAsTouched$
      .pipe(
        tap(() => {
          this.sessionForm.form.markAllAsTouched();
          this.cdr.markForCheck();
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();

    const sessionDefaults: SessionParameters = new InputSessionModel();
    if (!!this.session && !this.isEmptyObject(this.session)) {
      this.session = { ...sessionDefaults, ...this.session };
      this.isEnabled = true;
      this.isEditing = true;
      this.isInPerson =
        !!this.session.locationAddress || !this.session.locationUrl;
      this.isOnline = !!this.session.locationUrl;
    } else {
      this.session = { ...sessionDefaults };
    }
    this.backupSession = { ...this.session };

    this.initDateTimes();
  }

  public ngAfterViewInit(): void {
    // custom validator for checking whether both location types are off
    this.sessionForm?.control.setValidators(() => {
      return !this.isOnline && !this.isInPerson
        ? { locationType: true }
        : undefined;
    });

    // bind this for parent form validation purposes
    this.sessionForm?.statusChanges.subscribe((result) =>
      this.validateChange.emit(result === 'VALID')
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.session.isFirstChange() === false) {
      this.session = changes.session.currentValue as SessionParameters;
      this.isInPerson =
        !!this.session.locationAddress || !this.session.locationUrl;
      this.isOnline = !!this.session.locationUrl;
      this.initDateTimes();
    }
  }

  public onToggleForm(isEnabled: boolean): void {
    /* restore/store/clear session data */
    if (isEnabled) {
      this.session = { ...this.backupSession };
      this.sessionChange.emit(this.session);
    } else {
      this.backupSession = { ...this.session };
      this.sessionChange.emit({} as any);
    }
    this.isEnabled = isEnabled;
  }

  public onAddressSelect($event: LocalityViewModel) {
    if (!$event?.locationAddress) {
      return;
    }
    this.session = { ...this.session, ...$event };
    this.setTimeZoneFromLocation($event.latitude, $event.longitude);
  }

  private;

  public onFormFieldChange(event: Event = null): void {
    const modelName = (event?.target as HTMLInputElement)?.name;

    if (!!modelName) {
      switch (modelName) {
        case 'isRegistrationAvailable':
          /* set isRegistrationUrlInputUrl based on UI state */
          if (!this.session.isRegistrationAvailable) {
            this.session.isRegistrationUrlInputUrl = null;
          }
          this.handleRegistration();
          break;
        case 'isRegistrationUrlInputUrl':
          this.handleRegistration();
          break;

        case 'isInPerson':
          const geolocationProperties = [
            'locationAddress',
            'city',
            'country',
            'countryCode',
            'latitude',
            'longitude',
          ];
          this.isInPerson
            ? this.restoreSessionProperties(geolocationProperties)
            : this.backupClearSessionProperties(geolocationProperties);
          break;

        case 'isOnline':
          this.isOnline
            ? this.restoreSessionProperties(['locationUrl'])
            : this.backupClearSessionProperties(['locationUrl']);
          break;

        case 'startDate':
        case 'startTime':
          this.session.startDateTime = this.handleDateTime(
            this.sessionStartDate,
            this.sessionStartTime
          );
          this.minEndDate = this.sessionStartDate;
          if (
            this.datehandler.format(this.sessionEndDate) ===
            this.datehandler.format(this.sessionStartDate)
          ) {
            this.bounds.minEndTime = this.sessionStartTime;
            this.bounds.maxStartTime = this.sessionEndTime;

            // Check both time input values
            this.cdr.detectChanges();
            this.endTimeElement?.control.updateValueAndValidity();
          }
          break;

        case 'endDate':
        case 'endTime':
          this.session.endDateTime = this.handleDateTime(
            this.sessionEndDate,
            this.sessionEndTime
          );
          if (
            this.datehandler.format(this.sessionEndDate) ===
            this.datehandler.format(this.sessionStartDate)
          ) {
            this.bounds.minEndTime = this.sessionStartTime;
            this.bounds.maxStartTime = this.sessionEndTime;

            // Check both time input values
            this.cdr.detectChanges();
            this.startTimeElement?.control.updateValueAndValidity();
          }
          break;
        default:
          console.warn('unknown model name', modelName);
      }
    }

    // inform the parent form of the change
    this.sessionChange.emit(this.session);
  }

  public clearAddress() {
    const properties = [
      'locationAddress',
      'city',
      'country',
      'countryCode',
      'latitude',
      'longitude',
      'timeZoneId',
    ];

    for (const property of properties) {
      if (!!this.session[property]) {
        this.session[property] = null;
      }
    }

    // Reset the time zone back to default
    this.session.timeZoneId = this.timeZoneService.getBrowserTimeZone();
  }

  private isEmptyObject(o: any): boolean {
    return JSON.stringify(o) === '{}';
  }

  private backupClearSessionProperties = (properties: string[]): void => {
    for (const property of properties) {
      if (!!this.session[property]) {
        this.backupSession[property] = this.session[property];
        this.session[property] = null;
      }
    }
  };

  private restoreSessionProperties = (properties: string[]): void => {
    for (const property of properties) {
      if (!!this.backupSession[property]) {
        this.session[property] = this.backupSession[property];
      }
    }
  };

  private handleDateTime = (date: NgbDateStruct, time: string): string => {
    if (!date || typeof date === 'string' || !time) {
      return null;
    }
    const UTCDate = this.datehandler.format(date);
    return `${UTCDate}T${time}`;
  };

  private initDateTimes = (): void => {
    if (!!this.session.startDateTime) {
      const dp = this.session.startDateTime.split('T');
      const tp = dp[1].split(':');
      this.sessionStartDate = this.datehandler.parse(dp[0]);
      this.sessionStartTime = `${tp[0]}:${tp[1]}`;
      this.minEndDate = this.sessionStartDate;
    }
    if (!!this.session.endDateTime) {
      const dp = this.session.endDateTime.split('T');
      const tp = dp[1].split(':');
      this.sessionEndDate = this.datehandler.parse(dp[0]);
      this.sessionEndTime = `${tp[0]}:${tp[1]}`;
    }
  };

  private setTimeZoneFromLocation = (
    latitude: number,
    longitude: number
  ): void => {
    this.timeZoneService
      .getTimeZoneFromCoordinates(latitude, longitude)
      .pipe(catchError((e) => throwError(() => new Error(e))))
      .subscribe((response) => {
        this.session.timeZoneId = response.timeZoneId;
      });
  };

  private handleRegistration() {
    if (this.session.isRegistrationAvailable) {
      this.restoreSessionProperties(['registrationUrl']);
      if (this.session.isRegistrationUrlInputUrl === null) {
        this.session.isRegistrationUrlInputUrl = !this.session.registrationUrl;
      }
    }
    if (
      !this.session.isRegistrationAvailable ||
      this.session.isRegistrationUrlInputUrl
    ) {
      this.backupClearSessionProperties(['registrationUrl']);
    }
  }
}
