import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgsInternalContentVisibilityComponent } from './orgs-internal-content-visibility.component';
import { GroupsSharedModule } from '@app/groups/groups-shared.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [OrgsInternalContentVisibilityComponent],
  imports: [CommonModule, SharedModule, NgbAccordionModule, GroupsSharedModule],
  exports: [OrgsInternalContentVisibilityComponent],
})
export class OrgsInternalContentVisibilityModule {}
