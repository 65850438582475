import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';

// misc
import { InputContext } from '@app/user-content/user-input-v2/input.model';

import { FormGroup } from '@angular/forms';

import { ExperienceFacadeBase } from '@app/user-content/user-input-v2/inputs/experience/services/experience-facade-base.service';
import {
  ExperienceMapperService,
  ExperienceNotificationService,
  ExperienceTrackerService,
  ExperienceService,
} from '../';

import { ExperienceModel, ExperienceTypes } from '../../experience.model';

@Injectable()
export class ExperienceGlobalAddInputFacade extends ExperienceFacadeBase {
  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public experienceMapperService: ExperienceMapperService,
    public experienceService: ExperienceService,
    public experienceTrackerService: ExperienceTrackerService,
    public experienceNotificationService: ExperienceNotificationService
  ) {
    super(inputsService, translate, experienceMapperService, experienceService);
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ExperienceModel
   * ...
   */
  public get snapshot(): ExperienceModel {
    return readFirst(this.viewModel$);
  }

  // not used, but required to implement the abstract class
  public async onNext(url: string): Promise<void> {}

  // *******************************************************
  // Overwrites
  // *******************************************************

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      await super.onSubmit(form);
      this.performSuccessSideEffects();
    } catch {
      this.performFailureSideEffects();
    }
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
    };
  }

  public async initializeViewModelWithExperienceType(
    inputContext: InputContext,
    experienceType: ExperienceTypes
  ): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      experienceType,
    };
  }

  /** Performs any side effects required following successful creation of an Experience */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.experienceNotificationService.notifyExperienceCreated(
        this.viewModel.title
      );
      const apiParameters = this.experienceMapperService.toApiParameters(
        this.viewModel
      );
      this.experienceTrackerService.trackContentCompleted(apiParameters);

      // this could change, keeping for now to maintain compatability
      this.inputsService.notifyInputModified('Position');
    }
  }

  /** Performs any side effects required following failed creation of an Experience */
  protected performFailureSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.experienceNotificationService.notifyExperienceCreateFailed();
    }
  }
}
