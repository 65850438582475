import {
  APP_BASE_HREF,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
} from '@angular/common';

import { ErrorHandler, NgModule } from '@angular/core';

// third-party
import { DfIconRegistry } from '@lib/fresco';

// misc

import { AVATAR_FONT_FAMILY } from './components/profile-pic/profile-pic.component';
import {
  NUM_RESULTS_PER_PAGE,
  NUM_TILE_RESULTS_PER_PAGE,
} from './search.token';
import {
  highchartsLazySingleton,
  HIGHCHARTS_LAZY,
} from './services/highcharts-lazy.token';
import { hlsLazySingleton, HLS_LAZY } from './services/hls-lazy.token';
import { LDClientSDK } from './services/ldclientsdk.token';
import {
  localesLazySingleton,
  LOCALES_LAZY,
} from './services/locales-lazy.token';
import { getCurrentOrigin } from './utils/get-current-origin';
import { windowProvider, WindowToken } from './window.token';

// pipes
import { ContentActionPipe } from './pipes/content-action.pipe';
import { DgxCurrencyPipe } from './pipes/currency.pipe';
import { DecodeHtmlPipe } from './pipes/decode-html.pipe';
import { DisplayTypePipe } from './pipes/display-type.pipe';
import { EncodeToEntitiesPipe } from './pipes/encode-to-entities.pipe';
import { EscapeCurlysPipe } from './pipes/escape-curlys.pipe';
import { HtmlTranslatedPipe } from './pipes/html-translated.pipe';
import { HtmlPipe } from './pipes/html.pipe';
import { HtmlToPlaintextPipe } from './pipes/htmlToPlaintext.pipe';
import { InputToLearningResourcePipe } from './pipes/input-to-learning-resource.pipe';
import { IsIframePipe } from './pipes/is-iframe.pipe';
import { PointsPipe } from './pipes/points.pipe';
import { StripMarkupPipe } from './pipes/strip-markup.pipe';

// services
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { QueueService } from '@app/shared/services/queue.service';
import { SubscriptionQueueService } from '@app/shared/services/subscription-queue.service';
import { AnchorifyPipe } from './pipes/anchorify.pipe';
import { BlobifyUrlPipe } from './pipes/blobify-url.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { ChunkedPipe } from './pipes/chunked.pipe';
import { ClosestWordLimitPipe } from './pipes/closest-word-limit.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { GetSkillTypePipe } from './pipes/get-skill-type.pipe';
import { HighlightSearchTextPipe } from './pipes/highlight-search-text.pipe';
import { PrettyDurationPipe } from './pipes/pretty-duration.pipe';
import { PrettyTimeElapsedPipe } from './pipes/pretty-time-elapsed.pipe';
import { CONSOLE, consoleProvider } from './services/console.token';
import { EngagedLearnerService } from './services/engaged-learner.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { NAVIGATOR, navigatorProvider } from './services/navigator.token';
import { SearchUrlService } from './services/search-url.service';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { ScrollService } from './services';
import { UserGroupListService } from './services/content/user-group-list.service';

/*********************************************************************************
 * NOTE: This is a providers only shared module intended to be loaded in the
 *       `app.module`.  Do not lazy-load this module.
 *********************************************************************************/

/*********************************************************************************
 * NOTE: Only list providers that cannot be marked with:
 *       `Injectable({ providedIn: 'root'})` here
 *********************************************************************************/

// variables
const bodyFontFamily = window.getComputedStyle(
  document.querySelector('body')
).fontFamily;

const NUM_RESULTS_PER_PAGE_VALUE = 20;
const NUM_TILE_RESULTS_PER_PAGE_VALUE = 12;

@NgModule({
  imports: [InterceptorsModule],
  providers: [
    SubscriptionQueueService,
    AnchorifyPipe,
    BlobifyUrlPipe,
    ChunkedPipe,
    ClosestWordLimitPipe,
    EllipsisPipe,
    GetSkillTypePipe,
    HighlightSearchTextPipe,
    CamelCasePipe,
    PrettyDurationPipe,
    PrettyTimeElapsedPipe,
    // angular pipe
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    StripMarkupPipe,
    // fresco
    DfIconRegistry,
    // pipes declared in providers so they can be used in services
    ContentActionPipe,
    DisplayTypePipe,
    DgxCurrencyPipe,
    DecodeHtmlPipe,
    EscapeCurlysPipe,
    HtmlPipe,
    HtmlToPlaintextPipe,
    HtmlTranslatedPipe,
    InputToLearningResourcePipe,
    PointsPipe,
    EncodeToEntitiesPipe,
    IsIframePipe,
    ScrollService,
    UserGroupListService,
    {
      provide: APP_BASE_HREF,
      useValue: getCurrentOrigin(),
    },
    { provide: HIGHCHARTS_LAZY, useFactory: highchartsLazySingleton },
    { provide: LOCALES_LAZY, useFactory: localesLazySingleton },
    { provide: HLS_LAZY, useFactory: hlsLazySingleton },
    { provide: CONSOLE, useFactory: consoleProvider },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    { provide: LDClientSDK, useValue: (window as any).ldclientsdk },
    { provide: WindowToken, useFactory: windowProvider },
    { provide: NAVIGATOR, useFactory: navigatorProvider },
    { provide: AVATAR_FONT_FAMILY, useValue: bodyFontFamily },
    { provide: NUM_RESULTS_PER_PAGE, useValue: NUM_RESULTS_PER_PAGE_VALUE },
    {
      provide: NUM_TILE_RESULTS_PER_PAGE,
      useValue: NUM_TILE_RESULTS_PER_PAGE_VALUE,
    },
  ],
})
export class SharedProvidersModule {}
